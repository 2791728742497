Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPatchMethod =  "PATCH";


exports.updateNotificationAPIEndPoint = "notifsettings/notification_settings/";
exports.updateNotificationGroupAPIEndPoint = "notifsettings/groups/";
exports.updateNotificationSubGroupAPIEndPoint = "notifsettings/subgroups/";

exports.notificationApiContentType = "application/json";

exports.titleMessage ="Notification Settings"
exports.titleMessagePush ="Push Notifications"
exports.titleMessageEmail = "Email Notifications"
exports.getNotificationlistURL ="account_block/accounts/get_notification_settings"
exports.dataOfNotification={"notification": {
  "push_notification": {
      "order_invoices": true,
      "order_confirmations": true,
      "delivery_confirmation": true,
      "reviews_and_feedback_requests": true,
      "refund_or_payment_complete": true,
      "marketing_emails": true,
      "product_stock_updates": true
  },
  "email_notification": {
      "order_invoices": true,
      "order_confirmations": true,
      "delivery_confirmation": true,
      "reviews_and_feedback_requests": true,
      "refund_or_payment_complete": true,
      "marketing_emails": true,
      "product_stock_updates": true
  }
}}
exports.updateNotificationUrl="account_block/accounts/update_notification_settings"
exports.updateNotificationUrlPut="PUT"
exports.getNotificationAPIEndPoint="account_block/accounts/get_notification_settings"
// Customizable Area End