import React from "react";
// more core import statements

// Customizable Area Start
// custom import statements
import { Typography, Box, Grid, Card} from "@material-ui/core";
import Header from "../../../../components/src/Header";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Footer from "../../../../components/src/Footer";
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

import BuyerStoreController, {
  Props,
} from "./BuyerStoreController";
import { noImage } from "../../../landingpage/src/assets";
import StylishBuyerHeader from "../../../../components/src/StylishBuyerHeader";


export default class BuyerStore extends BuyerStoreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      {this.state.buyerStylish ? <StylishBuyerHeader navigation={this.props.navigation} /> : <Header navigation={this.props.navigation}/> } 
        <Snackbar
                    open={this.state.isAlert} 
                     autoHideDuration={3000}
                    anchorOrigin={{ horizontal:  'right', vertical: 'top' }}       
                     onClose={this.oncloseAlert} 
                    data-test-id="alertTestId" 
                    >
                      <Alert severity={this.state.alertType}>
                        {this.state.alertMsg}</Alert> 
                    </Snackbar>
                    <Box>
                    <Box style={{
                        maxHeight: "680px",
                        overflowY: "scroll"
                    }} data-test-id="handleScroll" id="scrollableDiv">
                    <Grid container
                    spacing={0} style={webStyles.heraderText}>
                        <Grid container spacing={1} style={webStyles.gridConter}>
                            {this.state.storeList &&
                                this.state.storeList.map((cardDetail, index) => (
                                    <Grid item xl={3} lg={4} md={6} sm={6} xs={12}  style={webStyles.trendingGrid}>
                                        <Card data-testId="trendingCart" style={webStyles.cardContainer}>
                                            <Box style={{ position: 'relative' }}>
                                                <img
                                                    src={ cardDetail.attributes?.image ? cardDetail.attributes?.image : noImage }
                                                    alt={`Image ${1}`}
                                                    style={webStyles.imgTag}
                                                    onClick={this.navigateToDeatils.bind(this,{ id: Number(cardDetail.id), screenName: "StoreProduct" })}
                                                    data-test-id = {`navigateToDetail-${index}`}
                                                />
                                            </Box>
                                            <Box style={{ padding: '24px' }}>
                                                <Box style={webStyles.productData1}>
                                                    <Box style={webStyles.productValue1}>
                                                        <Typography style={webStyles.productName1} >{cardDetail.attributes?.store_name}</Typography>
                                                        <Typography style={webStyles.productDescription1} >{cardDetail.attributes?.description}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))} 
                                        {this.state.circularProgress && <Box style={{ width: '100%', textAlign: 'center', paddingBottom: '20px' }}><CircularProgress style={{ color: '#375280' }} /></Box>}

                        </Grid>
                    </Grid>
                        
                        </Box> 
                    <Box>
                           <Footer navigation={this.props.navigation}/>
                        </Box>
                    </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  homeBox: { display: 'flex', alignItems: 'center', flexDirection: "column" as "column", fontFamily: "Lato", padding: '17px 0px' },
    productIcon1: { top: '20px', left: '20px', display: "flex", justifyContent: 'space-between', right: '0',position: 'absolute' as 'absolute' },
    productFavorite1: { color: "#375280", marginRight: '30px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
    productDiscount1: { color: "#375280", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 0px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
    productData1: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', 
    textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' ,whiteSpace: 'nowrap' as 'nowrap', flexDirection: 'row' as 'row' },
    productValue1: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto',whiteSpace: 'nowrap' as 'nowrap', },
    productDescription1: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500,whiteSpace: 'nowrap' as 'nowrap', textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
    productName1: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", whiteSpace: 'nowrap' as 'nowrap',overflow: "hidden", width: '90%', fontFamily: "Lato" },
    acountMrp1: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', border: "1px solid #D5D5D5", padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
    contentText: {
        color: "#375280",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "30px",
        textAlign: "center" as "center",
        padding: "20px 0 0",
        fontFamily: "Lato"
    },
    text2: {
        textAlign: "center" as "center", fontFamily: "Lato", color: "#375280", marginTop: "20px"
    },
    heraderText:{  background: "#FFF", justifyContent: "center", alignItems: "center" },
    gridConter:{ padding: "30px", alignItems: "center",width:"85%"},
    imgTag:{
        width: "340px",
        height: "370px",
        objectFit: "cover" as "cover",
        position: 'relative' as 'relative', display: 'block', margin: 'auto'
    },
    cardContainer: {boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "340px", height: "455px",backgroundColor:"#f2efef" },
    trendingGrid:{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }
};
// Customizable Area End

