import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { MyStoreAPIResponse, MyStoreList } from "../MySellerStoreController";
import { ErrorMessage } from "../CreateSellerStoreController";
import { ErrorMessageResponse } from "../SellerStoreController";
import { logoutSellerNavigation } from "../../../../components/src/Seller/logOut";
import { apiCall } from "../../../../components/src/APICall";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";
interface IStoreStatusResponse {
  id: number;
  path: string;
  name: string;
  is_open: boolean;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  selectedStoreImg: string;
  trnsSeller: (keyS: string) => string;
  handleClickStoreEvent?: ( id: number | string) => void
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  acceptedStore: IStoreStatusResponse[];
  isAllAccepted: boolean;
  searchStoreStatusQuery: string;
  isLoader: boolean;
  isAlert: boolean;
  severity: "success" | "error";
  message: string;
  storeName: string;
  storeImg: string;
  anchorElStore: HTMLElement | null;
  isUpdating: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerStoreStatusUpdateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  storeStatusAPICallId: string = "";
  storeStatusUpdateAPICallId: string = "";
  private debounceTimeouts: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      acceptedStore: [],
      isAllAccepted: false,
      searchStoreStatusQuery: "",
      isLoader: true,
      isAlert: false,
      severity: "success",
      message: "",
      storeName: this.props.trnsSeller("All Stores"),
      storeImg: this.props.selectedStoreImg,
      anchorElStore: null,
      isUpdating: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseJson &&
        !responseJson.errors &&
        !responseJson.error &&
        !responseJson.message
      ) {
        this.apiStoreStatusSuccessCalled(apiRequestCallId, responseJson);
      } else if (
        responseJson &&
        (responseJson.error || responseJson.errors || responseJson.message)
      ) {
        this.apiStoreStatusFailureCalled(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiStoreStatusSuccessCalled = async (
    apiRequestCallId: string,
    responseJson: MyStoreAPIResponse
  ) => {
    if (apiRequestCallId === this.storeStatusAPICallId) {
      if (responseJson.data) {
        this.handleStoreStatusResp(responseJson);
      }
    }
    if (apiRequestCallId === this.storeStatusUpdateAPICallId) {
      if (responseJson.data) {
        this.setState((prevState) => ({
          ...prevState,
          severity: "success",
          message: this.tranStoreStatusUpdate("Store status Update Successfully"),
          isAlert: true,
        }));
        this.getMyStoreApi(this.state.searchStoreStatusQuery);
      }
    }
  };

  apiStoreStatusFailureCalled = (
    responseStoreStatusJson: ErrorMessage &
      ErrorMessageResponse & { message: string }
  ) => {
    if (
      responseStoreStatusJson.errors ===
        "Only seller can assign catalogue variants to stores" ||
      responseStoreStatusJson.message ===
        "You are not an authorized user or proper role basic" ||
      responseStoreStatusJson.message ===
        "You do not have permission to perform this action on the bussiness."
    ) {
      this.handleRedirectToHome(
        this.tranStoreStatusUpdate("You are not an authorized person, Please Login with valid User Credential")
      );
    } else if (responseStoreStatusJson.error) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseStoreStatusJson.error,
      }));
    } else if (responseStoreStatusJson.errors[0].token) {
      this.handleRedirectToHome(responseStoreStatusJson.errors[0].token);
    } else if (responseStoreStatusJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseStoreStatusJson.errors as string,
      }));
    }
  };

  onCloseSSAlert = () =>{
    this.setState((prevState) => ({
      ...prevState,
      isAlert: false,
    }));
  }

  handleRedirectToHome = (message: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        isAlert: true,
        severity: "error",
        message: message,
      }),
      () => {
        setTimeout(() => {
          this.handleRedirectBack("Home");
          logoutSellerNavigation();
        }, 2000);
      }
    );
  };

  handleRedirectBack = (redirect: string) => {
    const userNavigateMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    userNavigateMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      redirect
    );
    this.send(userNavigateMsg);
  };

  handleClickStore = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElStore: event.currentTarget });
};

handleCloseStore = () => {
    this.setState({ anchorElStore: null });
};

selectAllStore = () => {
  this.handleStoreSelect({
    name: this.props.trnsSeller("All Stores"),
    path: this.props.selectedStoreImg,
    id: -1,
    is_open: false
  })
}

  handleStoreSelect = (selectedStoreData: IStoreStatusResponse) =>{
    this.setState(
      (prevState) => ({
        ...prevState,
        storeName: selectedStoreData.name,
        storeImg: selectedStoreData.path
      }), async () => {
        this.props.handleClickStoreEvent && this.props.handleClickStoreEvent(selectedStoreData.id)
        this.handleCloseStore();
        const pathurl = this.props.navigation.history.location.pathname.toLowerCase()
        if(configJSON.availableAllStore.includes(pathurl)){
          await setStorageData("selectedSellerStore", JSON.stringify(selectedStoreData))
        }
      });
  }
  async componentDidMount() {
    // Customizable Area Start
    const selectedStore = await getStorageData("selectedSellerStore", true);
    const userData = await getStorageData("userRole", true);
    if (userData.userType === "seller") {
      await this.getMyStoreApi("");
      const path = this.props.navigation.history.location.pathname.toLowerCase()
      if(selectedStore && configJSON.availableAllStore.includes(path)){
        this.setState({
          storeName: selectedStore.name,
          storeImg: selectedStore.path
        })
      }
    } else {
      history.back();
    }
    // Customizable Area End
  }

  handleStoreStatusUpdate = async (id: number, status: boolean) => {
    this.setState((prevState) => ({ ...prevState, isUpdating: true }));
    let endPoint = `${configJSON.storeStatusUpdateEndPoint}status=${!status}`;
    if (id !== 0) {
      endPoint += `&id=${id}`;
    }
    this.storeStatusUpdateAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: endPoint,
      token: await this.getLocalTokenData(),
    });
  };

  debounceTime(func: (...args: any[]) => void, delay: number) {
    return (...args: any[]) => {
      if (this.debounceTimeouts) {
        clearTimeout(this.debounceTimeouts);
      }
      this.debounceTimeouts = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleSellerStoreSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      searchStoreStatusQuery: query,
    }));
    this.debounceTime(() => this.getMyStoreApi(query), 800)();
  };

  getMyStoreApi = async (search: string) => {
    this.setState((prevState) => ({ ...prevState, isUpdating: true }));
    this.storeStatusAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.sellerMyStoreEndPoint}?approved=true&search=${search}`,
      token: await this.getLocalTokenData(),
    });
  };

  getLocalTokenData = async () => {
    return await getStorageData("auth-token");
  };

  handleStoreStatusResp = async (responseJson: MyStoreAPIResponse) => {
    if (responseJson.data) {
      this.setState((prevState) => ({
        ...prevState,
        acceptedStore: this.extractStoreStatusData(responseJson.data),
        isLoader: false,
        isUpdating: false,
        isAllAccepted: responseJson.meta.all_businesses_open,
      }));
    }
  };

  extractStoreStatusData = (items: MyStoreList[]): IStoreStatusResponse[] => {
    return items.map((item) => {
      const { id, attributes } = item;
      return {
        id: +id,
        path: attributes.image,
        name: attributes.store_name,
        is_open: attributes.is_open,
      };
    });
  };

  tranStoreStatusUpdate = (transKey: string) => {
    return i18n.t(transKey, {ns: "inventoryManagement"});
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
