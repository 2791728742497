import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
//@ts-ignore
import CustomCheckBox from "../../../components/src/CustomCheckBox";
// Customizable Area End

import NotificationsettingsController, {
  Props
} from "./NotificationsettingsController";

export default class Notificationsettings extends NotificationsettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#f7f9fa"
  },
  innerView: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 5,
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#ffffffff"
  },
  subInnerView: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 10,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#ffffffff"
  },
  title: {
    // marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 4
  },
  subTitle: {
    // marginBottom: 32,
    fontSize: 14,
    textAlign: "left",
    marginVertical: 4
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
