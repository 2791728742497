import React from "react";

// Customizable Area Start
import { Grid, withStyles,Button,Popover,Radio,Modal,Divider,CircularProgress, Tab,Fade,FormControlLabel,WithStyles, Tabs,Backdrop, Box, capitalize, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import { downloadIcon, filterIcon } from "../../customform/src/assets";
import MomentUtils from '@date-io/date-fns';
import TwoLineChart from "../../../components/src/Chart/LineChart";
import clsx from "clsx";
import BarChart from "../../../components/src/Chart/BarChart";
import { button_close, calendar } from "./assets";
import { Noimageavailablepng } from "../../dashboard/src/assets";
import { Product } from "./AnalyticsController.web"

export const analyticsSaleCount = [
  { label: "Total Sales", value: 0 },
  { label: "Impression", value: 0 },
  { label: "Total Orders", value: 0 },
  { label: "Visitors", value: 0 }
]

const analyticsLineChartData = [{
  label: "Impression",
  color: "#F59E0B",
},
{
  label: "Visitors",
  color: "#34D399",
}
]

const RepeatCustomerChartData = [{
  label: "First Time",
  color: "#375280",
},
{
  label: "Returning",
  color: "#F59E0B",
}
]

const analyticsMonthLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

const repeatCustomerTimeLabel = [
  "8am",
  "10am",
  "12am",
  "2pm",
  "4pm",
  "6pm",
  "8pm",
  "10pm",
  "12pm"
]
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            item
            xl={2}
            md={3}
            lg={2}
            sm={3}
            xs={3}
          >
            <SellerSidebar
              navigation={this.props.navigation}
            />
          </Grid>
          <Grid
            item
            xl={10}
            md={9}
            lg={10}
            sm={9}
            xs={9}
          >
            <SellerHeader navigation={this.props.navigation} />
            {this.state.isLoadingAnalytics ?  <Box style={{ ...webStyle.tabsBoxAnalytics }}>
        <CircularProgress style={webStyle.CircularProgressColor} />
      </Box> : <SellerStoreAnalyticsContainer>
              <SellerStoreHeader>
                <Box className="analytics-title-container">
                  <Typography className="title">
                    Analytics Dashboard
                  </Typography>
                  <Typography className="second-title">
                    [Past 1 Month]
                  </Typography>
                </Box>
                <Box className="btn-container">
                  <img src={downloadIcon} alt="down-load-icon" onClick={this.handleOpenPdf} />
                  <img src={filterIcon} data-test-id="handlePopoverOpen" onClick={this.handlePopoverOpen} alt="filter-icon" />
                </Box>
              </SellerStoreHeader>
              <Box style={webStyle.tabsContainer}>
                <Tabs value={this.state.tabIndex}
                  onChange={this.handleTabChange} data-test-id="handleTabChange" TabIndicatorProps={{ style: { ...webStyle.tabsBackground } }} aria-label="basic tabs example">
                  <CustomTab style={webStyle.tabsStyle} data-test-id="StoresReach" label={capitalize("Stores Reach")} />
                  <CustomTab style={webStyle.tabsStyle} data-test-id="ProductsReach" label={capitalize("Products Reach")} />
                </Tabs>
              </Box>
              {this.state.tabIndex === 0 && (
                <> 
                    <SellerCountContainer>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography className="seller-count-title">Sales Count</Typography>
                        </Grid>
                        {this.state.salesCountValue.map((item: { label: string; value: number }) => (
                          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                            <SalesCountContainer>
                              <Typography className="seller-count-show-div-label">{item.label}</Typography>
                              <Typography className="seller-count-show-div-value">{item.value}</Typography>
                            </SalesCountContainer>
                          </Grid>
                        ))}
                      </Grid>
                    </SellerCountContainer>
                  <ChartContainer>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                        <Box className="chart-outer-container">
                          <Typography className="chart-title">Impression vs Visitors</Typography>
                          <Box className="line-chart-labels-container">
                            {analyticsLineChartData.map((item: { label: string; color: string }) => (
                              <Box className="line-chart-label">
                                <Box style={{ backgroundColor: item.color }} className="color-picker" />
                                <Typography>{item.label}</Typography>
                              </Box>
                            ))}
                          </Box>
                          <Box className="line-chart-container">
                            <TwoLineChart
                              labels={analyticsMonthLabel}
                              datasets={[
                                {
                                  data: [this.state.allStoreAnalyticsReport.graph_data.impressions.January, this.state.allStoreAnalyticsReport.graph_data.impressions.February, this.state.allStoreAnalyticsReport.graph_data.impressions.March, this.state.allStoreAnalyticsReport.graph_data.impressions.April, this.state.allStoreAnalyticsReport.graph_data.impressions.May, this.state.allStoreAnalyticsReport.graph_data.impressions.June, this.state.allStoreAnalyticsReport.graph_data.impressions.July, this.state.allStoreAnalyticsReport.graph_data.impressions.August, this.state.allStoreAnalyticsReport.graph_data.impressions.September,this.state.allStoreAnalyticsReport.graph_data.impressions.October, this.state.allStoreAnalyticsReport.graph_data.impressions.November, this.state.allStoreAnalyticsReport.graph_data.impressions.December],
                                  borderColor: "#F59E0B",
                                  backgroundColor: "#F59E0B",
                                  borderWidth: 2,
                                  fill: false,
                                },
                                {
                                  data: [this.state.allStoreAnalyticsReport.graph_data.visitors_count.January, this.state.allStoreAnalyticsReport.graph_data.visitors_count.February, this.state.allStoreAnalyticsReport.graph_data.visitors_count.March, this.state.allStoreAnalyticsReport.graph_data.visitors_count.April, this.state.allStoreAnalyticsReport.graph_data.visitors_count.May, this.state.allStoreAnalyticsReport.graph_data.visitors_count.June, this.state.allStoreAnalyticsReport.graph_data.visitors_count.July, this.state.allStoreAnalyticsReport.graph_data.visitors_count.August, this.state.allStoreAnalyticsReport.graph_data.visitors_count.September,this.state.allStoreAnalyticsReport.graph_data.visitors_count.October, this.state.allStoreAnalyticsReport.graph_data.visitors_count.November, this.state.allStoreAnalyticsReport.graph_data.visitors_count.December],
                                  borderColor: "#34D399",
                                  backgroundColor: "#34D399",
                                  borderWidth: 2,
                                  fill: false,
                                },
                              ]}
                              xTickColor="#375280"
                              yTickColor="#375280"
                              highlightLastTick
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                        <Box className="chart-outer-container">
                          <Typography className="chart-title">Repeat Customer Rate</Typography>
                          <Box className="line-chart-labels-container">
                            {RepeatCustomerChartData.map((item: { label: string; color: string }) => (
                              <Box className="line-chart-label">
                                <Box style={{ backgroundColor: item.color }} className="color-picker" />
                                <Typography>{item.label}</Typography>
                              </Box>
                            ))}
                          </Box>
                          <BarChart
                            labels={repeatCustomerTimeLabel}
                            datasets={[
                              {
                                label: "First Time",
                                data: [100, 300, 400, 250, 150, 450, 250, 350, 200],
                                backgroundColor: "#375280",
                              },
                              {
                                label: "Returning",
                                data: [50, 200, 300, 200, 100, 300, 200, 300, 300],
                                backgroundColor: "#F59E0B",
                              },
                            ]}
                            barThickness={18}
                            xTickColor="#375280"
                            yTickColor="#375280"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </ChartContainer>
                </>
              )}
              {this.state.tabIndex === 1 && (
                <>
                    <SellerProductReachContainer> 
                      <SellerCountContainer> 
                        <Grid container spacing={3}> 
                          <Grid item xs={12}> 
                            <Typography className="seller-count-title">Sales Count</Typography> 
                          </Grid> 
                          {this.state.salesCountValue.map((item: { label: string; value: number }) => ( 
                            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}> 
                              <SalesCountContainer> 
                                <Typography className="seller-count-show-div-label">{item.label}</Typography> 
                                <Typography className="seller-count-show-div-value">{item.value}</Typography> 
                              </SalesCountContainer> 
                            </Grid> 
                          ))} 
                        </Grid> 
                      </SellerCountContainer>
                      <Box className="popular-product-container">
                        <Box className="popular-product-title-container">
                          <Typography className="popular-product-title">
                            Products Overview
                          </Typography>
                        </Box>
                        <Box className="products-container">
                          {this.state.allStoreProductReach.products.map((item: Product, index: number) => (
                            <Box className="product-container" key={index}>
                              <Box className="product-detail-container" data-test-id="navigateToProductOverview" onClick={() => this.navigateToProductOverview({ productOverviewId: item.product_id, bussinessInformationId: item.product.bussiness_information_id, props: this.props, screenName: "SellerProductOverview" })}>
                                <img width="80px" height="80px" src={item.image_url ? item.image_url : Noimageavailablepng} alt="" />
                                <Box className="product-detail">
                                  <Typography className="product-name">
                                    {item.name}
                                  </Typography>
                                  <Box className="product-detail-btn-container">
                                    <Box className="product-btn-sold">{item.sold_units} Sold Units</Box>
                                    <Box className="product-btn-unit">{item.returned_units} Returned Units</Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Typography className="product-price">
                                {item.total_earnings}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </SellerProductReachContainer>
                </>
              )}
            </SellerStoreAnalyticsContainer>}
            <Popover
                        id="mouse-over-popover"
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={this.handlePopoverClose}
                        disableRestoreFocus
                    >
                        <div style={webStyle.filetrContainer}>
                            <Box style={webStyle.filterBox}><Box style={webStyle.filterName}>Filters</Box><img width="35.36px" height="35.36px" src={button_close} data-test-id="handlePopoverClose" onClick={this.handlePopoverClose} /></Box>
                            <Divider style={webStyle.filterDividerColor} />
                            <Box style={webStyle.filterBoxPd}>
                                {this.state.downloadOptionsActive.map((option, index) => (
                                    <>
                                        <Box key={index} style={webStyle.filterTypeAlign}>
                                            <Box>
                                                <FormControlLabel
                                                    control={<StyledRadioWithStyles />}
                                                    label=""
                                                    value={option.type}
                                                    checked={this.state.accountFilter === option.type}
                                                    onChange={(event) => this.handlefilterChange(event as React.ChangeEvent<HTMLInputElement>)}
                                                    style={webStyle.radioName}
                                                    data-test-id='handlefilterChange'
                                                />
                                            </Box>
                                            <Box style={{ ...webStyle.acountData}}>
                                                {option.label}
                                            </Box>
                                        </Box>
                                    </>
                                ))}
                            </Box>
                            <Box style={webStyle.datePickPadding} >
                                <MuiPickersUtilsProvider utils={MomentUtils} >
                                    <Box style={webStyle.datePickJustify}>
                                        <StartDateCalendar
                                            autoOk
                                            data-test-id="handleDateChangeInput2"
                                            disableToolbar
                                            variant="inline"
                                            format="dd-MM-yyyy"
                                            margin="normal"
                                            style={webStyle.dateSelectWidth}
                                            value={this.state.startDate}
                                            onChange={this.handlesatrtDateChange}
                                            inputProps={{
                                                'data-test-id': 'handleDateChangeInput',
                                            }}
                                            InputProps={{
                                                id: "teststart",
                                                disableUnderline: true,
                                                style: webStyle.pickerFields,
                                                placeholder: 'From'
                                            }}
                                            keyboardIcon={<img src={calendar} style={webStyle.pickerIcon} alt="calendar_startdate" />}
                                        />
                                        <StartDateCalendar
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            format="dd-MM-yyyy"
                                            margin="normal"
                                            data-test-id="handleEndDateChange2"
                                            style={webStyle.dateSelectWidth}
                                            value={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            inputProps={{
                                                'data-test-id': 'handleEndDateChange',
                                            }}
                                            InputProps={{
                                                id: "teststart",
                                                disableUnderline: true,
                                                style: webStyle.pickerFields,
                                                placeholder: 'To'
                                            }}
                                            keyboardIcon={<img src={calendar} style={webStyle.pickerIcon} alt="calendar_startdate" />}
                                        />
                                    </Box>
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Box style={webStyle.buttonBox}>
                                <ClearButton onClick={this.handlePopoverClose}>Clear All</ClearButton>
                                <Button style={webStyle.applayButton as React.CSSProperties} data-test-id="filterApply" onClick={this.filterSellerEarnings}>Apply</Button>
                            </Box>
                        </div>
                    </Popover>
          </Grid>
        </Grid>
        <StyledDrawerOrder
          aria-labelledby="transition-modal-title"
          onClose={this.handleClosePdf}
          BackdropProps={{
            timeout: 500,
          }}
          closeAfterTransition
          open={this.state.pdfOpen}
          aria-describedby="transition-modal-description"
          BackdropComponent={Backdrop}
        >
          <Fade in={this.state.pdfOpen} style={webStyle.febBox}>
            <Box>
              <Box style={webStyle.pdfCloseBtn}><img src={button_close} onClick={this.handleClosePdf} /></Box>
              <Box style={webStyle.exportReject as React.CSSProperties}>Export Report</Box>
              <Box style={webStyle.exportData as React.CSSProperties}>Export Analytics report as:</Box>
              <Box>
                {this.state.downloadOptions.map((option, index) => (
                  <>
                    <Box key={index} style={webStyle.pdfDwonBox}>
                      <Box style={webStyle.contentContainer}>
                        <img width="60px" height="60px" src={option.src} />
                        <Box style={webStyle.pdfData}>
                          {option.label}
                        </Box>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={<StyledRadioWithStyles />}
                          label=""
                          value={option.type}
                          checked={this.state.accountPdf === option.type}
                          onChange={this.handlePdfChange}
                          style={webStyle.radioName2}
                        />
                      </Box>
                    </Box>
                    {this.state.downloadOptions.length - 1 !== index && <Divider style={webStyle.divayder} />}
                  </>
                ))}
              </Box>
              <Box style={webStyle.btnModelContainer}>
                <StyledButtonModelNewOrder onClick={this.handleClosePdf}>Close</StyledButtonModelNewOrder>
                <StyledButton1ModelNewOrder >Export</StyledButton1ModelNewOrder>
              </Box>
            </Box>
          </Fade>
        </StyledDrawerOrder>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const webStyle = {
  buttonBox: { 
    padding: '20px', 
    marginTop: '10px', 
    display: 'flex', 
    justifyContent: 'space-between' 
  },
  filetrContainer: {
    width: '435px',
    borderRadius: '5px',
  },
  filterDividerColor: {
    color: "#F1F5F9"
  },
  filterBoxPd: {
    padding: '20px'
  },
  filterTypeAlign: {
    display: "flex", 
    alignItems: 'center'
  },
  datePickPadding: {
    padding: '20px'
  },
  datePickJustify: {
    display: 'flex', 
    justifyContent: 'space-between'
  },
  dateSelectWidth: {
    width: '190px'
  },
  applayButton: {
    backgroundColor: '#CCBEB1',
    padding: '10px 16px', width: '185px',
    height: '54px', color: '#FFFFFF',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'capitalize'
  },
  tabsBoxInProcess: {
    width: '100%',
    height: '75%',
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterBox: {
    display: 'flex',
    padding: '20px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pickerIcon: {
    width: '20px',
    height: '20px'
  },
  filterName: {
    fontSize: '20px',
    fontFamily: 'Lato',
    fontWeight: 800,
    color: '#375280'
  },
  pickerFields: {
    fontFamily: "Lato",
    fontWeight: 500,
    fontSize: "18px",
    color: "#94A3B8",
    paddingRight: "5px",
    backgroundColor: "#F8F8F8",
    padding: '16px 18px',
  },
  acountData: {
    color: "#375280",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Lato',
    marginLeft: "8px"
  },
  tabsContainer: {
    borderBottom: '1px solid',
    color: "rgb(226, 232, 240)",
    marginTop: 20,
    width: '25%'
  },
  CircularProgressColor: {
    color: "#375280"
  },
  tabsBoxAnalytics: {
    width: '100%',
    paddingBottom: '20px',
    height: '75%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  tabsBackground: {
   backgroundColor: "#375280"
  },
  tabsStyle: {
    color: '#375280',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 600,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  febBox: { 
    borderRadius: '10px', 
    width: "500px", 
    backgroundColor: "white",
    padding: "10px 30px 30px 30px"
  },
  pdfCloseBtn: {
    width: '100%', 
    display: 'flex', 
    justifyContent: 'end'
  },
  btnModelContainer: { 
    display: "flex", 
    gap: "20px", 
    justifyContent: "space-between", 
    marginTop: '30px' 
  },
  pdfData: { 
    color: "#375280", 
    fontSize: "22px", 
    fontWeight: 700, 
    marginLeft: '20px',
    fontFamily: 'Lato' 
  },
  exportData: { 
    fontSize: '18px', 
    fontFamily: 'Lato', 
    fontWeight: 700, 
    color: '#375280', 
    textAlign: "center",
    marginBottom: '20px' 
  },
  contentContainer: { 
    display: 'flex', 
    alignItems: 'center' 
  },
  exportReject: { 
    fontSize: '30px', 
    fontFamily: 'Lato', 
    fontWeight: 500, 
    color: '#375280', 
    textAlign: "center",
    marginBottom: '20px'
   },
  pdfDwonBox: { 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between' 
  },
  divayder: { 
    color: '#e2e8f0', 
    marginTop: '24px', 
    marginBottom: '24px' 
  },
  radioName: { 
    color: "rgba(55, 82, 128, 1)", 
    fontSize: "16px", 
    fontWeight: 700, 
    fontFamily: "Lato",
    marginRight: '0px'
  },
  radioName2: { 
    color: "rgba(55, 82, 128, 1)", 
    fontSize: "16px", 
    fontWeight: 700, 
    fontFamily: "Lato",
  },
}

const SellerStoreAnalyticsContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
});

const StartDateCalendar = styled(KeyboardDatePicker)({
  width: "112px",
  height: "32px",
  borderRadius: '26px',
  fontWeight: 500,
  fontFamily: "Raleway",
  color: "#334155",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  margin: 0,
});

const CustomTab = styled(Tab)({
  textTransform: 'capitalize'
});

const styles = {
  root: {
      '&:hover': {
          backgroundColor: 'transparent',
      },
  },
  icon: {
      borderRadius: '50%',
      width: 20,
      height: 20,
      border: '1px solid #CCBEB1',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
      },
      'input:disabled ~ &': {
          background: 'rgba(206,217,224,.5)',
      },
  },
  checkedIcon: {
      backgroundColor: '#cbbeb2',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
          display: 'block',
          width: 20,
          height: 20,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
      },
  },
};

const StyledRadioWithStyles = withStyles(styles)((props: WithStyles<typeof styles>) => (
  <Radio
      className={props.classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(props.classes.icon, props.classes.checkedIcon)} />}
      icon={<span className={props.classes.icon} />}
      {...props}
  />
));

const ClearButton = styled(Button)((theme) => ({
  border: '1px solid #CCBEB1', padding: '10px 16px', width: '185px', height: '54px', color: '#375280', fontFamily: 'Lato', fontSize: '18px', fontWeight: 500, textTransform: 'capitalize',
  '&:hover': {
      backgroundColor: "#ffff"
  },
}));

const SellerStoreHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: 44,
  "& .analytics-title-container": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 20,
    height: "100%",
    "& .title": {
      fontFamily: "Lato, sans-serif",
      fontWeight: 700,
      fontSize: 24,
      color: "#375280",
    },
    "& .second-title": {
      fontFamily: "Lato, sans-serif",
      fontWeight: 400,
      fontSize: 16,
      marginTop: 3,
      color: "#375280",
    },
  },
  "& .btn-container": {
    display: "flex",
    gap: 24,
    height: "100%",
  },
  "@media(max-width:600px)": {
    height: "max-content",
    "& .analytics-title-container": {
      "& .title": {
        fontWeight: 600,
        fontSize: "18px !important",
      },
    },
    "& .btn-container": {
      flexDirection: "column",
      gap: 4,
      height: "max-content",
    },
  },
});

const StyledButtonModelNewOrder = styled(Button)(() => ({
  textTransform: "none",
  fontWeight: 500,
  borderRadius: "2px",
  border: "1px solid #CCBEB1",
  width: "252px !important",
  color: "#375280",
  fontSize: "18px", height: '54px',
  '&:hover': {
      backgroundColor: "white",
  },
}))
const StyledButton1ModelNewOrder = styled(Button)(() => ({
  fontWeight: 500,
  fontSize: "18px", height: '54px',
  backgroundColor: '#CCBEB1',
  width: "252px",
  textTransform: "none",
  color: "#fff",
  '&:hover': {
      backgroundColor: "#CCBEB1"
  },
}))

const SellerCountContainer = styled(Box)({
  padding: 20,
  border: "1px solid #E2E8F0",
  marginTop: 30,
  "& .MuiGrid-root.MuiGrid-container": {
    "& .MuiGrid-root.MuiGrid-item": {
      "& .seller-count-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 20,
        color: "#375280",
      },
    },
  },
});

const SalesCountContainer = styled(Box)({
  width: "100%",
  height: 140,
  display: "flex",
  flexDirection: "column",
  gap: 15,
  backgroundColor: "#F8F8F8",
  alignItems: "center",
  justifyContent: "center",
  "& .seller-count-show-div-label": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 22,
    color: "#334155",
  },
  "& .seller-count-show-div-value": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 36,
    color: "#375280",
  },
});

const StyledDrawerOrder = styled(Modal)((theme) => ({
  alignItems: 'center',
  display: 'flex',
  backgroundColor: "none",
  justifyContent: 'center',
}));

const ChartContainer = styled(Box)({
  width: "100%",
  "& .MuiGrid-root.MuiGrid-container": {
    height: "max-content",
    "& .MuiGrid-root.MuiGrid-item": {
      "& .chart-outer-container": {
        padding: 20,
        border: "1px solid #E2E8F0",
        marginTop: 30,
        height: "90%",
        "& .chart-title": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 700,
          fontSize: 20,
          color: "#375280",
        },
        "& .line-chart-container": {
          width: "100%",
        },
        "& .revenue-chart-container": {
          width: "100%",
          marginTop: 30
        },
        "& .pie-chart-container": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          "& .pie-chart": {
            width: "60%",
          },
          "& .pie-chart-label-container": {
            display: "flex",
            flexDirection: "column",
            gap: 30,
            width: "40%",
          },
          "& .pie-chart-label": {
            display: "flex",
            gap: 12,
            width: "100%",
            color: "#375280",
            fontSize: 16,
            "& .label-name": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 2,
            },
            "& .color-picker": {
              width: 20,
              borderRadius: 2,
              height: 24,
            },
          },
          "@media(max-width:800px)": {
            flexDirection: "column",
            gap: 20,
            "& .pie-chart": {
              width: "100%",
            },
            "& .pie-chart-label-container": {
              width: 320,
              alignItems: "center",
            },
          },
        },
        "& .line-chart-labels-container": {
          display: "flex",
          gap: 20,
          padding: "20px 0px",
          "& .line-chart-label": {
            display: "flex",
            gap: 12,
            justifyContent: "center",
            color: "#375280",
            fontSize: 16,
            "& .color-picker": {
              width: 20,
              height: 24,
              borderRadius: 2,
            },
          },
        },
        "@media(max-width:600px)": {
          "& .line-chart-container": {
            width: "100%",
            overflowX: "auto",
          },
        },
      },
    },
  },
});

const SellerProductReachContainer = styled(Box)({
  minHeight: "calc(100vh - 85px)",
  "& .popular-product-container": {
    padding: 20,
    border: "1px solid #E2E8F0",
    marginTop: 40,
    maxHeight: 480,
    overflowY: "auto",
    "& .popular-product-title-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
      "& .popular-product-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 20,
        color: "#375280",
      },
    },
    "& .products-container": {
      "& .product-container": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "16px 0px",
        borderBottom: "1px solid #E2E8F0",
        "& .product-detail-container": {
          display: "flex",
          gap: 20,
          "& .product-detail": {
            display: "flex",
            flexDirection: "column",
            gap: 15,
            fontFamily: "Lato, sans-serif",
            fontSize: 18,
            color: "#375280",
            "& .product-name": {
              fontWeight: 500,
            },
            "& .product-order": {
              fontWeight: 700,
            },
          },
          "& .product-detail-btn-container":{
            display: "flex",
            "& .product-btn-sold": {
              padding: "10px 25px",
              borderRadius: "2px 0px 0px 0px",
              backgroundColor: "#E2E8F0",
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: 500,
              textAlign: "center",
              display: "flex",
              color: "#375280",
              alignItems: "center",
              justifyContent: "center",
            },
            "& .product-btn-unit": {
              padding: "10px 25px",
              borderRadius: "2px 0px 0px 0px",
              backgroundColor: "#FEE2E2",
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: 500,
              textAlign: "center",
              color: "#DC2626",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "15px"
            },
          }
        },
        "& .product-price": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 700,
          fontSize: 18,
          color: "#059669",
        },
      },
    },
  },
});
// Customizable Area End
