import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
interface StoreCatalogueVariant {
    id: string;
    type: string;
    attributes: {
        id: number;
        bussiness_information_id: number;
        catalogue_variant_id: number;
        catalogue_variant: {
            id: number;
            catalogue_id: number;
            catalogue_variant_color_id: number;
            catalogue_variant_size_id: number;
            price: string;
            stock_qty: number;
            on_sale: number | null;
            sale_price: number | null;
            discount_price: number | null;
            length: number | null;
            breadth: number | null;
            height: number | null;
            created_at: string;
            updated_at: string;
            block_qty: number | null;
            sku: string;
            low_stock_threshold: number;
            is_listed: boolean;
            deactivate: boolean;
            remove_front_image: string | undefined;
            remove_back_image: string | null;
            remove_side_image: string | null;
        };
        catalogue: {
            id: number;
            category_id: number;
            sub_category_id: number;
            brand_id: number | null;
            name: string;
            sku: string | null;
            description: string;
            manufacture_date: Date | null;
            length: number | null;
            breadth: number | null;
            height: number | null;
            availability: boolean | null;
            stock_qty: number | null;
            weight: number | null;
            price: number | null;
            recommended: boolean | null;
            on_sale: boolean | null;
            sale_price: number | null;
            discount: number | null;
            created_at: Date;
            updated_at: Date;
            block_qty: number | null;
            account_id: number;
            product_number: string | null;
            service_information_id: number | null;
            sub_sub_category_id: number;
            share_token: string | null;
            gender: "male" | "female" | "unisex";
            brand_name: string;
            material: string;
            fit: string;
            prodcut_care: string;
            list_the_product: string;
            fit_discription: string;
            appeared_in_search: number;
            filter_range: string;
            is_published: boolean;
            start_date: Date;
            end_date: Date;
        },
        has_coupon: boolean;
    };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profileName: string,
    value: number,
    orderStatus: string | undefined;
    userStats: { title: string, percentage: string }[],
    storeIdResp: boolean,
    anchor: null | string,
    tabeLoder: boolean,
    page: number;
    tableAllOrder: StoreCatalogueVariant[],
    currentPage: number,
    itemsPerPage: number,
    selectedIndices: number[],
    metaData: {
        total_pages: number,
        current_page: number,
        total_records: number,
        prev_page: number,
        next_page: number
    },
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerSelectProductswebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getMy_OrdersCallId: string = "";
    getCancelOrderCallId: string = "";
    getVariantsListId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            profileName: "",
            page: 1,
            storeIdResp: false,
            selectedIndices: [],
            value: 0,
            orderStatus: "all_orders",
            anchor: null,
            tabeLoder: true,
            tableAllOrder: [],
            userStats: [
                { title: 'All users', percentage: '10%' },
                { title: 'All users', percentage: '20%' },
                { title: 'All users', percentage: '30%' },
                { title: 'All users', percentage: '50%' },
            ],
            currentPage: 1,
            itemsPerPage: 11,
            metaData: {
                total_pages: 0,
                current_page: 0,
                total_records: 0,
                prev_page: 0,
                next_page: 0
            },
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getVariantsListId !== null &&
            this.getVariantsListId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonSize = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ tableAllOrder: responseJsonSize && responseJsonSize.data, metaData: responseJsonSize && responseJsonSize.meta })
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        this.toggleVariantsListOffer()
        const selectedIndicesId = await getStorageData("selectedIndices") || []
        this.setState({ selectedIndices: selectedIndicesId.split(',').map(Number) })
    }
    sellerOffersDiscounts = async () => {
        await setStorageData("selectedIndices", this.state.selectedIndices)
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellerOffersStoreFormWebPage");
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { tabIndex: 2 });
        toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(toNavigate);
    }

    handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const isSelected = event.target.checked;
        const updatedSelectedIndices = isSelected
            ? this.state.tableAllOrder.map((product) => product.attributes.catalogue_variant_id)
            : [];
        this.setState({
            selectedIndices: updatedSelectedIndices,
        });
    };
    handleRowCheckboxChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event.target;
        const { selectedIndices, tableAllOrder } = this.state;
        const catalogueVariantId = tableAllOrder[index].attributes.catalogue_variant_id;
        const newSelectedIndices = checked
            ? [...selectedIndices, catalogueVariantId]
            : selectedIndices.filter((id) => id !== catalogueVariantId);

        this.setState({
            selectedIndices: newSelectedIndices,
        });
    };

    handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        event.preventDefault()
        this.setState({ page: newPage });
        this.toggleVariantsListOffer()
    };
    toggleVariantsListOffer = async () => {
        const storeId = await getStorageData("store-id")
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getVariantsListId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getStoreIdDate}/${storeId}/store_catalouge_variants_list?page=${this.state.page}&per_page=5`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    tranSellerSelectProducts = (transKey: string) => {
        return i18n.t( transKey, {ns: "promoCode"});
      }
    // Customizable Area End
}
