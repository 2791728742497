import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    styled,
    TextField,
    Button,
    Modal, Backdrop, Fade
} from "@material-ui/core";
import { button_arrowrit, button_arrow, group_error, calendar, view_Icon } from "./assets";
import SellerSideBar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/date-fns';
import { enUS, arSA } from 'date-fns/locale';
import SellerOffersStoreFormWebController, {
    Props
} from "./SellerOffersStoreFormwebController";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export default class SellerOffersStoreFormWebPage extends SellerOffersStoreFormWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleSOSFImgCSS = () =>{
        return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
    }

    datePickerAlignCSS = () => {
        return i18n.dir() === 'rtl' ? 'right' : 'left'
    }

    handleLocal = () => {
        return i18n.dir() === 'rtl' ? arSA : enUS
    }

    selectedPrduct = () => {
        return (
            <Box>
                {(this.state.value === 2 || this.state.discountType === "Products") &&
                    <>
                        <Box style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 2px 8px 0px #00000014', cursor: 'pointer', padding: '12px 16px', border: this.state.indicateError ? '1px solid red' : 'none' }}>
                            <Box onClick={this.sellerSelectProductsCreate} data-testid="sellerSelectProductsCreate" style={{ ...webStyle.createText, display: 'flex', alignItems: 'center', gap: 14 }}>{this.tranSellerStoreForm("Select Products")} <img style={{width: '20px', transform: this.handleSOSFImgCSS()}} src={button_arrowrit} /></Box>
                        </Box>
                        <Box style={{ color: '#f44336', fontSize: '12px', fontFamily: 'Lato', fontWeight: 400, marginTop: '5px' }}>{this.state.indicateError && `*${this.tranSellerStoreForm("Product not selected")}`}</Box>
                    </>
                }
            </Box>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Grid container dir={i18n.dir()} data-test-id="seller-offer-store-form-test-id">
                    <Grid data-testid="capitalize" item xl={2} md={2} lg={2} sm={2} xs={2} >
                        <SellerSideBar navigation={this.props.navigation} />
                    </Grid>
                    <Grid item xl={10} lg={10} className="seller-offer-store-form-main-item" sm={10} md={10} xs={10}  >
                        <SellerHeader navigation={this.props.navigation} />
                        <Box style={{ margin: "30px" }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box style={{ display: 'flex' }}>
                                    <img data-testid="sellerOffersDiscountsCreate" onClick={this.sellerOffersDiscountsCreate} width="32px" height="32px" src={button_arrow} style={{transform: this.handleSOSFImgCSS()}} />&nbsp;&nbsp;&nbsp;<Typography style={webStyle.lableText}>{this.tranSellerStoreForm("Create new offer")}</Typography>
                                </Box>
                                <Box>
                                    {this.selectedPrduct()}
                                </Box>
                            </Box>
                            <EditSellerDetailContainer>
                                {this.state.errors && this.state.errors.createPromoCode?.length > 0 &&
                                    <Box style={{ backgroundColor: "#FEE2E24D", border: '1px solid #DC26264D', marginTop: '28px', padding: '12px 8px 12px 16px' }}>
                                        <Box style={{ display: 'flex' }}>
                                            <img src={group_error} width="27px" style={{ marginRight: '16px' }} />
                                            <Box>
                                                <Box style={{ color: "#DC2626", fontSize: '18px', fontFamily: 'Lato', fontWeight: 600 }}>{this.tranSellerStoreForm("Failed to create the promotion")}</Box>
                                                <Box style={{ color: "#DC2626", fontSize: '16px', fontFamily: 'Lato', fontWeight: 400 }}>{this.tranSellerStoreForm("Please try again.")}</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                <form onSubmit={this.handleSubmit}>
                                    <Box className="edit-store-profile-container">
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Typography className="inputHeading">{`${this.tranSellerStoreForm("Discount Percentage")}*`}</Typography>
                                                <TextField
                                                    name="discountPercentage"
                                                    value={this.state.discountPercentage}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    placeholder={this.tranSellerStoreForm("Enter discount percentage")}
                                                    variant="outlined"
                                                    className="inputFeild2"
                                                    inputProps={{ maxLength: 120 }}
                                                    error={Boolean(this.state.errors.discountPercentage && this.state.touched.discountPercentage)}
                                                    helperText={this.state.errors.discountPercentage}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography className="inputHeading">{`${this.tranSellerStoreForm("Max Cap")}*`}</Typography>
                                                <TextField
                                                    name="maxCap"
                                                    value={this.state.maxCap}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    placeholder={this.tranSellerStoreForm("Enter max cap")}
                                                    variant="outlined"
                                                    className="inputFeild2"
                                                    inputProps={{ maxLength: 120 }}
                                                    error={Boolean(this.state.errors.maxCap && this.state.touched.maxCap)}
                                                    helperText={this.state.errors.maxCap}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Typography className={"inputHeading"}>
                                                    {this.tranSellerStoreForm("Discount applicable for")}
                                                </Typography>
                                                <Box style={{ display: 'flex', marginTop: '20px', gap: 16}}>
                                                    <Typography data-testid="handleTabChange" onClick={() => this.handleTabChange('all_users')} style={{ ...webStyle.allUserBox, textAlign: 'center', backgroundColor: this.state.activeTab === 'all_users' ? '#375280' : '#E2E8F0', color: this.state.activeTab === 'all_users' ? '#FFFFFF' : '#375280', }}>
                                                        {this.tranSellerStoreForm("All Users")}
                                                    </Typography>
                                                    <Typography onClick={() => this.handleTabChange('new_users')} data-testid="handleTabChange1" style={{ ...webStyle.allUserBox, textAlign: 'center', backgroundColor: this.state.activeTab === 'new_users' ? '#375280' : '#E2E8F0', color: this.state.activeTab === 'new_users' ? '#FFFFFF' : '#375280', }}>
                                                        {this.tranSellerStoreForm("New Users")}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Typography className={"inputHeading"}>
                                                    {`${this.tranSellerStoreForm("Create promo code")}*`}
                                                </Typography>
                                                <TextField
                                                    name="createPromoCode"
                                                    value={this.state.createPromoCode}
                                                    data-test-id="store-name"
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder={this.tranSellerStoreForm("Enter promo code")}
                                                    className="inputFeild2"
                                                    inputProps={{ maxLength: 120 }}
                                                    onChange={this.handleChange}
                                                    error={Boolean(this.state.errors.createPromoCode && this.state.touched.createPromoCode)}
                                                    helperText={this.state.errors.createPromoCode}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography className={"inputHeading"}>
                                                    {`${this.tranSellerStoreForm("Minimum order value")}*`}
                                                </Typography>
                                                <TextField
                                                    name="minimumOrderValue"
                                                    value={this.state.minimumOrderValue}
                                                    data-test-id="store-name"
                                                    fullWidth
                                                    variant="outlined"
                                                    className="inputFeild2"
                                                    placeholder={this.tranSellerStoreForm("Enter minimum order value")}
                                                    inputProps={{ maxLength: 120 }}
                                                    onChange={this.handleChange}
                                                    error={Boolean(this.state.errors.minimumOrderValue && this.state.touched.minimumOrderValue)}
                                                    helperText={this.state.errors.minimumOrderValue}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Typography className="inputHeading">{`${this.tranSellerStoreForm("Campaign start date")}*`}</Typography>
                                                <Box style={{ ...webStyle.pickerFields, border: this.state.errors.startDate ? "1px solid red" : undefined }}>
                                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={this.handleLocal()}>
                                                        <StartDateCalendar
                                                            autoOk
                                                            disableToolbar
                                                            disablePast
                                                            format="EEE, dd MMM yyyy"
                                                            margin="normal"
                                                            KeyboardButtonProps={{
                                                                'aria-label': this.tranSellerStoreForm('Change Date'),
                                                              }}
                                                            okLabel={this.tranSellerStoreForm('OK')}
                                                            cancelLabel={this.tranSellerStoreForm('Cancel')}
                                                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'initial', width: '100%' }}
                                                            value={this.state.startDate}
                                                            onChange={this.handlesatrtDateChange}
                                                            inputProps={{ 'data-testid': 'handleDateChangeInput' }}
                                                            InputProps={{
                                                                id: "teststart",
                                                                disableUnderline: true,
                                                                placeholder: this.tranSellerStoreForm('Select start date'),
                                                                onClick: this.handlePickerOpen1,
                                                            }}
                                                            data-testid="handlePickerOpen1"
                                                            open={this.state.isPickerOpen1}
                                                            InputLabelProps={{
                                                                style: { textAlign: this.datePickerAlignCSS() },
                                                            }}
                                                            onClose={this.handlePickerClose1}
                                                            keyboardIcon={<img src={calendar} style={webStyle.pickerIcon} alt="calendar_startdate" />}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Box>
                                                <Typography variant="caption" color="error">{this.state.errors.startDate}</Typography>
                                                <Typography style={webStyle.startEndDate}>{this.tranSellerStoreForm("Your campaign will start on")}&nbsp;<Box style={{ fontWeight: 700 }}>{this.state.startNewDate}</Box>. {this.tranSellerStoreForm("You can stop this campaign at any time.")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography className="inputHeading">{`${this.tranSellerStoreForm("Campaign end date")}*`}</Typography>
                                                <Box style={{ ...webStyle.pickerFields, border: this.state.errors.endDate ? "1px solid red" : undefined }}>
                                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={this.handleLocal()}>
                                                        <StartDateCalendar
                                                            autoOk
                                                            disableToolbar
                                                            disablePast
                                                            format="EEE, dd MMM yyyy"
                                                            margin="normal"
                                                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'initial', width: '100%' }}
                                                            value={this.state.endDate}
                                                            onChange={this.handleEndDateChange}
                                                            inputProps={{ 'data-testid': 'handleEndDateChange' }}
                                                            InputProps={{
                                                                id: "testend",
                                                                disableUnderline: true,
                                                                placeholder: this.tranSellerStoreForm('Select end date'),
                                                                onClick: this.handlePickerOpen,
                                                            }}
                                                            InputLabelProps={{
                                                                style: { textAlign: this.datePickerAlignCSS() },
                                                            }}
                                                            open={this.state.isPickerOpen}
                                                            onClose={this.handlePickerClose}
                                                            keyboardIcon={<img src={calendar} style={webStyle.pickerIcon} alt="calendar_enddate" />}
                                                            KeyboardButtonProps={{
                                                                'aria-label': this.tranSellerStoreForm('Change Date'),
                                                              }}
                                                            okLabel={this.tranSellerStoreForm('OK')}
                                                            cancelLabel={this.tranSellerStoreForm('Cancel')}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Box>
                                                <Typography variant="caption" color="error">{this.state.errors.endDate}</Typography>
                                                <Typography style={webStyle.startEndDate}>{this.tranSellerStoreForm("Your campaign will end on")}&nbsp;<Box style={{ fontWeight: 700 }}>{this.state.endNewDate}</Box>. {this.tranSellerStoreForm("You can stop this campaign at any time.")}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography style={webStyle.offerDetils}>{this.tranSellerStoreForm("Offer details")}</Typography>
                                        <Typography style={webStyle.foreBox}>{this.tranSellerStoreForm("Offer applicable for")}&nbsp;<Box style={webStyle.multipleBox}>{this.state.activeTab === 'all_users' ? this.tranSellerStoreForm("All Users") : this.tranSellerStoreForm("New Users")}</Box>&nbsp;{this.tranSellerStoreForm("on all stores")}.</Typography>
                                        <Typography style={{ ...webStyle.foreBox, margin: '12px 0px' }}>{this.tranSellerStoreForm("Valid on minimum order value of $50")}. &nbsp;<Box style={webStyle.multipleBox}></Box>&nbsp;</Typography>
                                        <Typography style={{ ...webStyle.foreBox, margin: '12px 0px' }}>{this.tranSellerStoreForm("Valid for")}&nbsp;<Box style={webStyle.multipleBox}>{this.state.value === 2 ? this.tranSellerStoreForm("Selected products") : this.tranSellerStoreForm("Selected stores")}.</Box>&nbsp;</Typography>
                                        <Typography style={{ ...webStyle.foreBox, margin: '12px 0px' }}>{this.tranSellerStoreForm("By clicking create offer, you agree to our")}&nbsp;<Box style={{ ...webStyle.multipleBox, cursor: 'pointer' }} onClick={this.sellerTermsConditions} data-testid="sellerTermsConditions">{this.tranSellerStoreForm("Terms & Conditions")}.</Box>&nbsp;</Typography>
                                        <Button
                                            style={{ ...webStyle.createData, textTransform: 'capitalize' }}
                                            type="submit" data-testid="handleSubmit"
                                        >
                                            {this.tranSellerStoreForm("Create")}
                                        </Button>
                                    </Box>
                                </form>
                            </EditSellerDetailContainer>
                        </Box>
                    </Grid>
                </Grid>
                <StyledDrawer
                    open={this.state.visible}
                    aria-describedby="transition-modal-description"
                    closeAfterTransition
                    aria-labelledby="transition-modal-title"
                    data-testid="handleCancel"
                    onClose={this.handleCancel}
                    dir={i18n.dir()}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    BackdropComponent={Backdrop}
                >
                    <Fade in={this.state.visible} style={webStyle.febBox}>
                        <div style={{ padding: "50px 60px 50px 60px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><img width="80px" height="80px" style={{ marginBottom: '30px' }} src={view_Icon} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectNameDetails, textAlign: "center", margin: '0px' }}>{this.tranSellerStoreForm("Offer successfully created!")}</h2>
                        </div>
                    </Fade>
                </StyledDrawer>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    febBox: { borderRadius: '10px', backgroundColor: "white" },
    rejectNameDetails: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    createData: {
        marginTop: '30px',
        backgroundColor: '#CCBEB1',
        padding: '14px 72px',
        color: '#FFFFFF',
        fontSize: '18px',
        fontFamily: 'Lato',
        fontWeight: 700
    },
    multipleBox: { fontSize: '16px', fontFamily: 'Lato', fontWeight: 700, color: "#375280" },
    foreBox: { display: 'flex', fontSize: '16px', fontFamily: 'Lato', fontWeight: 700, color: "#94A3B8" },
    offerDetils: { fontSize: '18px', fontFamily: 'Lato', fontWeight: 700, color: "#375280", marginTop: '30px', marginBottom: '16px' },
    pickerIcon: { width: '20px', height: '20px' },
    pickerFields: { fontFamily: "Lato", fontWeight: 500, fontSize: "18px", color: "#375280", paddingRight: "5px", backgroundColor: "#F0F0F0", padding: '13px 18px', },
    allUserBox: { padding: '12px', fontSize: '18px', fontFamily: 'Lato', fontWeight: 500 },
    twoButton: { width: '180px', height: '48px', backgroundColor: '#CCBEB1', padding: '11px 27px', fontSize: '18px', fontFamily: 'Lato', fontWeight: 700, color: '#FFFFFF' },
    haderBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    createText: { fontSize: "16px", fontFamily: "Lato", fontWeight: 500, color: "#375280" },
    numberBox: { fontSize: "22px", fontFamily: "Lato", fontWeight: 700, color: "#375280", marginBottom: '10px' },
    lableText: { fontSize: "24px", fontFamily: "Lato", fontWeight: 700, color: "#375280" },
    container: { display: "flex", justifyContent: "space-between" },
    startEndDate: { color: '#375280', fontFamily: 'Lato', fontWeight: 400, fontSize: '14px', display: 'flex' },
};
const StartDateCalendar = styled(KeyboardDatePicker)({
    width: "112px",
    height: "32px",
    borderRadius: '26px',
    fontWeight: 500,
    fontFamily: "Raleway",
    color: "#375280",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    margin: 0,
    "& .MuiInputBase-input": {
        color: "#375280",
    },
    "& .MuiPickersToolbar-toolbar": {
        display: 'none !important'
    }
});
const EditSellerDetailContainer = styled(Box)({
    minHeight: "calc(100vh - 85px)",
    "& .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error":{
        display: "flex"
    },
    "& .header-container": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: 44,
        "& .title-container": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 20,
            height: "100%",
            "& .title": {
                fontFamily: "Lato, sans-serif",
                fontWeight: 700,
                fontSize: 24,
                color: "#375280",
            },
        },
        "& .next-btn": {
            textTransform: "capitalize",
            color: "white",
            gap: "8px",
            backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
            height: "100%",
            borderRadius: "2px",
            "& .MuiButton-label": {
                fontSize: "18px",
                fontWeight: 600,
            },
        },
        "@media(max-width:800px)": {
            height: "max-content",
            "& .title-container": {
                "& .title": {
                    fontWeight: 600,
                    fontSize: "18px !important",
                },
            },
            "& .next-btn": {
                height: "44px !important",
                "& .MuiButton-label": {
                    fontSize: "16px !important",
                },
            },
        },
    },
    "& .loader-container": {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .edit-store-profile-container": {
        padding: 20,
        border: "1px solid #E2E8F0",
        marginTop: 20,
        "& .MuiGrid-root.MuiGrid-container": {
            paddingTop: 20,
            "& .MuiGrid-root.MuiGrid-item": {
                "& .store-profile-image": {
                    display: "flex",
                    gap: 20,
                    position: "relative",
                    width: 100,
                    "& .store-profile-img": {
                        borderRadius: "50%",
                        width: 100,
                        aspectRatio: 1,
                    },
                    "& .store-profile-edit-img": {
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        width: 30,
                        aspectRatio: 1,
                    },
                },
                "& .inputHeading": {
                    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
                    textOverflow: "ellipsis",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "26px",
                },
                "& .MuiFormControl-root": {
                    "& .MuiInputBase-root.MuiOutlinedInput-root.inputFeild2.Mui-error": {
                        border: "1px solid #f44336",
                    },
                },
                "& .inputFeild2": {
                    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error": {
                        border: "1px solid #f44336",
                        "& .MuiInputBase-input.MuiOutlinedInput-input": {
                            color: "#375280 !important",
                            borderWidth: '1px !important',
                            border: "1px solid #f44336",
                        },
                    },
                    "& .MuiTextField-root": {
                        "&:hover": {
                            border: "1px solid #F0F0F0 !important",
                        },
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#F0F0F0",
                        borderWidth: '1px !important',
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                        borderColor: "#F0F0F0",
                    },
                    "& .MuiOutlinedInput-root": {
                        backgroundColor: "#F0F0F0",
                        borderRadius: "2px",
                    },
                    "& .MuiFormHelperText-contained": {
                        margin: "0 !important",
                    },
                    "& .MuiInputBase-input": {
                        color: "#375280",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#F0F0F0",
                    },
                },
                "& .phone-num-container": {
                    display: "flex",
                    gap: 14,
                    "& .phone-code-container": {
                        width: "35%",
                    },
                    "@media(max-width:600px)": {
                        flexDirection: "column",
                        "& .phone-code-container": { width: "100%" },
                    },
                },
            },
        },
    },
});
const StyledDrawer = styled(Modal)((theme) => ({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: "none",
    justifyContent: 'center',
}));
// Customizable Area End