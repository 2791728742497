import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { doc, jpg, pdf } from "./assets";
import { productOverviewAnalyticsSaleCount } from "./SellerProductOverview.web";
import i18n from "../../../components/src/i18next/i18n";
import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import { apiCall } from "../../../components/src/APICall";
interface GraphData {
  impressions: {
    June: number,
    July: number,
    August: number,
    September: number,
    October: number,
    November: number,
      January: number,
      February: number,
      March: number,
      April: number,
      May: number,
      December: number
  };
  visitors_count: {
      August: number,
      September: number,
      October: number,
      November: number,
      January: number,
      February: number,
      March: number,
      April: number,
      May: number,
      June: number,
      July: number,
      December: number
  };
}

interface Product {
  manage_placed_status: boolean;
    refunded_at: string | null;
    seller_id: number;
    manage_cancelled_status: boolean;
    reason_of_rejection: string;
    seller_order_id: number;
    rejected_at: string;
    accept_order_upload_time: string | null;
    bussiness_information_id: number;
    item_return_date: string | null;
    process_at: string | null;
    item_return_time: string | null;
    shipped_at: string | null;
    return_cancel_at: string | null;
    return_at: string | null;
    return_placed_at: string | null;
    return_confirmed_at: string | null;
    return_pick_at: string | null;
    return_reject_at: string | null;
    reason_of_return: string | null;
    returned_assign_at: string | null;
    reason_refunded_cancel: string | null;
    refunded_cancel_at: string | null;
    id: number;
    quantity: number;
    order_management_order_id: number;
    total_price: string;
    unit_price: string;
    old_unit_price: string | null;
    status: string;
    updated_at: string;
    created_at: string;
    catalogue_variant_id: number;
    catalogue_id: number;
    placed_at: string;
    order_status_id: number;
    confirmed_at: string | null;
    delivered_at: string | null;
    cancelled_at: string | null;
    in_transit_at: string | null;
  }
    
    interface Data {
      graph_data: GraphData;
      product: Product;
      sold_units: number,
      returned_units: number,
      filter_range: string;
      total_sales: number;
      wishlisted: number;
      impressions: number;
      total_orders: number;
      visitors_count: number;
      total_earnings: string,
      image_url: string,
      name: string
    }
    interface IGraphType{
      [key: string]: number
    }

    interface ImpressionAndSales {
      sold_units: { [key: string]: number };
      appeared_in_search: { [key: string]: number };
    }
    const weekDaysMap: { [key: string]: string } = {
      "Monday": "Mon", "Tuesday": "Tue", "Wednesday": "Wed", 
      "Thursday": "Thu", "Friday": "Fri", "Saturday": "Sat", "Sunday": "Sun",
    };
    
    const monthsMap: { [key: string]: string } = {
      "January": "Jan", "February": "Feb", "March": "Mar", "April": "Apr", 
      "May": "May", "June": "Jun", "July": "Jul", "August": "Aug", 
      "September": "Sep", "October": "Oct", "November": "Nov", "December": "Dec"
    };

    const monthsCurrentMap: { [key: string]: string } = {
      "01": "Jan", "02": "Feb", "03": "Mar", "04": "Apr", "05": "May", 
      "06": "Jun", "07": "Jul", "08": "Aug", "09": "Sep", "10": "Oct", 
      "11": "Nov", "12": "Dec"
    };

    interface ImpressionAndSales {
      sold_units: { [key: string]: number };
      appeared_in_search: { [key: string]: number };
    }

    interface IFilterResp {
      data: {
          filter_range: string,
          catalogue_name: string,
          sold_units: number,
          returned_units: number,
          appeared_in_search: number,
          total_revenue: number,
          currency_logo: string,
          impression_and_sales: ImpressionAndSales
      }
    }

    type NullableDate = Date | string | null | undefined;
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    accountPdf: string;
    pdfOpen: boolean;
    isLoadingProductOverviewsales: boolean;
    downloadOptions: { type: string, src: string, label: string }[];
    productOverviewsalesCountValue: { label: string, value: number }[]
    allStoreProductOverview: Data;
    soldUnit: IGraphType;
    appearInSearch: IGraphType;
    filterType: string;
    accountFilter: string;
    anchorEl: HTMLElement | null,
    isPickerOpen:boolean;
    endDate: NullableDate;
    startDate: NullableDate;
    downloadOptionsActive: { type: string, label: string }[];
    error: string;
    isAlert: boolean; 
          alertMsg: string; 
          alertType: 'success' | 'error';
          isChartLoading: boolean;
          roleDataType: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SellerProductOverviewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProductOverviewApiCallId: string = ""
    getDownloadProductCallID: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        this.state = {
            accountPdf: "pdf",
            pdfOpen: false,
            downloadOptions: [
              { type: 'pdf', src: pdf, label: 'PDF' },
              { type: 'doc', src: doc, label: 'Doc File' },
              { type: 'jpg', src: jpg, label: 'JPG' },
          ],
          error: "",
          isPickerOpen: false,
          downloadOptionsActive: [
            { type: 'week', label: 'This Week' },
            { type: 'month', label: 'This Month' },
            { type: 'year', label: 'This Year' },
            { type: 'manually', label: 'Select Date Range' },
        ],
        accountFilter: "month",
          startDate: null,
          roleDataType: "",
          endDate: null,
        productOverviewsalesCountValue: productOverviewAnalyticsSaleCount,
          isLoadingProductOverviewsales: true,
          isAlert: false, 
          alertMsg: "", 
          alertType: 'success',
          isChartLoading: true,
          allStoreProductOverview: {
            "filter_range": "this month",
            "total_sales": 92700,
            "wishlisted": 0,
            "impressions": 86,
            "total_orders": 4,
            "graph_data": {
              "impressions": {
                "February": 0,
                "January": 0,
                "March": 0,
                "May": 0,
                "April": 0,
                "August": 0,
                "June": 0,
                "July": 82,
                "October": 0,
                "September": 0,
                "December": 0,
                "November": 0,
              },
              "visitors_count": {
                "February": 0,
                "January": 0,
                "June": 0,
                "August": 0,
                "July": 9,
                "September": 0,
                "November": 0,
                "December": 0,
                "October": 0,
                "April": 0,
                "March": 0,
                "May": 0,
              }
            },
            "visitors_count": 9,
            "product": {
              "id": 1259,
              "quantity": 1,
              "order_management_order_id": 923,
              "total_price": "100.0",
              "unit_price": "100.0",
              "old_unit_price": null,
              "status": "rejected",
              "created_at": "2024-07-13T04:18:48.552Z",
              "updated_at": "2024-07-13T04:20:38.227Z",
              "catalogue_variant_id": 470,
              "catalogue_id": 302,
              "order_status_id": 2,
              "confirmed_at": null,
              "placed_at": "2024-07-13T04:20:21.373Z",
              "delivered_at": null,
              "in_transit_at": null,
              "refunded_at": null,
              "cancelled_at": null,
              "manage_placed_status": false,
              "manage_cancelled_status": false,
              "seller_order_id": 661,
              "seller_id": 501,
              "reason_of_rejection": "Order Processing Error",
              "bussiness_information_id": 246,
              "rejected_at": "2024-07-13T04:20:38.217Z",
              "accept_order_upload_time": null,
              "item_return_time": null,
              "item_return_date": null,
              "process_at": null,
              "shipped_at": null,
              "return_at": null,
              "return_cancel_at": null,
              "return_placed_at": null,
              "return_pick_at": null,
              "return_confirmed_at": null,
              "return_reject_at": null,
              "reason_of_return": null,
              "refunded_cancel_at": null,
              "returned_assign_at": null,
              "reason_refunded_cancel": null
            },
            "sold_units": 0,
            "total_earnings": "100.0",
            "returned_units": 0,
            "image_url": "active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdVFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a24a3d5f493e7f383d926f6b5e1beb33c4173838/20181202_170304.png",
            "name": "Brand 246"
          },
          soldUnit: {},
          appearInSearch: {},
          filterType: "month",
          anchorEl: null,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const userData = await getStorageData("userRole",true)
        this.setState({ roleDataType: userData.userType }, ()=> this.getSellerProductOverviewApi());
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );

          if(responseJson && !responseJson.errors){
            this.apiSuccess(responseJson, apiRequestCallId)
          } else if (responseJson && responseJson.errors) {
            this.handleInvalidToken(responseJson.errors[0].token);
          }
        // Customizable Area End
    }

    // Customizable Area Start
apiSuccess = (responseJson: IFilterResp & { url: string}, apiRequestCallId: string)=>{
  if(apiRequestCallId === this.getProductOverviewApiCallId){
    const type = this.state.filterType;
    const chartData = this.transformData(responseJson.data.impression_and_sales, type)
    
    this.setState({  isLoadingProductOverviewsales: false, productOverviewsalesCountValue: [
      { label: "Sold Units", value: responseJson.data.sold_units },
      { label: "Return Units", value: responseJson.data.returned_units },
      { label: "Appeared in Search", value: responseJson.data.appeared_in_search },
      { label: "Total Revenue", value: responseJson.data.total_revenue }
    ],appearInSearch: chartData.appeared_in_search, soldUnit: chartData.sold_units, isChartLoading: false })
  } else if(apiRequestCallId === this.getDownloadProductCallID){
    this.openInNewTab(responseJson.url);
    this.handleClosePdf();
  }
}

    handleInvalidToken = (messageText: string) =>{
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true, alertMsg: messageText, alertType: 'error', isLoadingProductOverviewsales: false, isChartLoading: false
      }))
    }

    openInNewTab = (url: string) => {
      if (url.includes('doc')) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'download_report_sales_product.doc';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    };

    extractKeysAndValues = (data: { [key: string]: number }): { keys: string[]; values: number[] } => {
      const keys = Object.keys(data);
      const values = Object.values(data);
      return { keys, values };
    };
    

     getCurrentMonthPrefix = (): string =>{
      const date = new Date();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      return monthsCurrentMap[month];
    }

    transformData = (
      inputData: ImpressionAndSales,
      type: string
    ): ImpressionAndSales => {
      const transformedData: ImpressionAndSales = { sold_units: {}, appeared_in_search: {} };
    
      const mapKeys = (
        data: { [key: string]: number },
        map: { [key: string]: string },
        prefix: boolean = false
      ): { [key: string]: number } => {
        const result: { [key: string]: number } = {};
        Object.entries(data).forEach(([key, value]) => {
          const prefixValue = prefix ? `${key.padStart(2, '0')} - ${this.transEarnings(map[key])}` : this.transEarnings(map[key])
          const newKey = map[key] ? prefixValue : this.transEarnings(key);
          result[newKey] = value;
        });
        
        return result;
      };
    
      if (type === "week") {
        transformedData.sold_units = mapKeys(inputData.sold_units, weekDaysMap);
        transformedData.appeared_in_search = mapKeys(inputData.appeared_in_search, weekDaysMap);
    
      } else if (type === "month") {
        const addMonthAbbreviation = (key: string, currentMonth: string): string => {
          return `${key.padStart(2, '0')} ${currentMonth}`;
        };
        
        const currentMonth = this.transEarnings(this.getCurrentMonthPrefix());  
        
        transformedData.sold_units = Object.keys(inputData.sold_units)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .reduce((acc, key) => {
            acc[addMonthAbbreviation(key, currentMonth)] = inputData.sold_units[key];
            return acc;
          }, {} as { [key: string]: number });
        
        transformedData.appeared_in_search = Object.keys(inputData.appeared_in_search)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .reduce((acc, key) => {
            acc[addMonthAbbreviation(key, currentMonth)] = inputData.appeared_in_search[key];
            return acc;
          }, {} as { [key: string]: number });
      } else if (type === "year") {
        transformedData.sold_units = mapKeys(inputData.sold_units, monthsMap);
        transformedData.appeared_in_search = mapKeys(inputData.appeared_in_search, monthsMap);
      } else {
        const addMonthAbbreviation = (key: string): string => {
          const [day, month] = key.split(' ');
          const abbreviatedMonth = month.slice(0, 3);
          return `${day.padStart(2, '0')} ${this.transEarnings(abbreviatedMonth)}`;
        };
        
        const transformData = (data: { [key: string]: number }): { [key: string]: number } => {
          const transformed: { [key: string]: number } = {};
          Object.keys(data).forEach((key) => {
            const formattedKey = addMonthAbbreviation(key);
            transformed[formattedKey] = data[key];
          });
          return transformed;
        };

        transformedData.sold_units = transformData(inputData.sold_units);
        transformedData.appeared_in_search = transformData(inputData.appeared_in_search);
      }
    
      return transformedData;
    };

    handleDateShow = (date: NullableDate) => {
      const locale = i18n.dir() === 'rtl' ? 'ar' : 'en';
      const momentDate = date ? moment(date) : moment();
      return momentDate.locale(locale).format('DD MMM YYYY');
    };
    
    proceedToAnalyticsInsightsPage = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDetail");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
      handleOpenPdf = () => {
        this.setState({ pdfOpen: true });
    };
    handleClosePdf = () => {
        this.setState({ pdfOpen: false });
    }

    getSellerProductOverviewApi = async () => {  
      const token = await getStorageData("auth-token");
      const selectProductId = await getStorageData("productId");
      const startDateNew = moment(this.state.startDate).format('DD-MM-YYYY');
      const startDateNewEnd = moment(this.state.endDate).format('DD-MM-YYYY');
      let endPoint = `bx_block_catalogue/catalogues/${selectProductId}/get_analytical_report?filter_range=`;
      if(this.state.filterType === "manually"){
        endPoint += `${this.state.filterType}&start_date=${startDateNew}&end_date=${startDateNewEnd}`
      } else{
        endPoint += `this ${this.state.filterType}`
      }
      
      this.getProductOverviewApiCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: endPoint,
        token: token,
      });
    
    }
    
    handlePdfChange = (event: React.ChangeEvent<{}>) => {
        const value = (event.target as HTMLInputElement).value;
        this.setState({ accountPdf: value });
    }  

    handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null});
};

handleClearFilter = () => {
  this.setState({ anchorEl: null, filterType: "month", accountFilter: "month"}, ()=>this.getSellerProductOverviewApi());
}

handlefilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  this.setState({
      filterType: value,
      error: "", 
      startDate: null,
      endDate: null
  });
}

handlesatrtDateChange = (date: Date | null) => {
  if (this.state.endDate && date && date > this.state.endDate) {
    this.setState({ error: this.transEarnings('Start date should be before the end date.') });
  } else {
    this.setState({ error: '', startDate: date });
  }
};

handleEndDateChange = (date: Date | null) => {
  if (this.state.startDate && date && date < this.state.startDate) {
    this.setState({ error: this.transEarnings('End date should be after the start date.') });
  } else {
    this.setState({ error: '', endDate: date });
  }
};

filterSellerEarnings = () => {
  const { startDate, endDate, error, filterType } = this.state;
  if(filterType==="manually"){
    let errorState = "";
    if(startDate===null){
      errorState = this.transEarnings("*Start date is required for Filter Please select start date.")
    } else if(endDate===null){
      errorState = this.transEarnings("*End date is required for Filter Please select end date.")
    } else if (error !== ""){
      errorState = error;
    }
    if(errorState !==""){
      this.setState({error: errorState});
      return;
    }
    
  }
  this.setState({ anchorEl: null, isChartLoading: true, accountFilter: filterType }, () => {
      this.getSellerProductOverviewApi()
  });
};

transEarnings = (earKey: string) => {
  return i18n.t(earKey, {ns: "analytics"})
}

handleExportPdf = async () => {
  const selectProductId = await getStorageData("productId");
  const userID = await getStorageData("user_id");
  const token = await getStorageData("auth-token");
  this.getDownloadProductCallID = await apiCall({
    contentType: "application/json",
    method: "GET",
    endPoint: `catalogues/${selectProductId}/download_report/${userID}?type=${this.state.accountPdf}`,
    token: token,
  });

}
    // Customizable Area End
}