import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

interface S {
  // Customizable Area Start
  isNewAddressActive: boolean;
  selectedPaymentMethod: string;
  name: string;
  cardNumber: string;
  showCard: boolean;
  month: number;
  year: number;
  cvv: string;
  zip: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderPaymentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiAddAddressCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isNewAddressActive: false,
      selectedPaymentMethod: "",
      name: "",
      cardNumber: "",
      showCard: true,
      month: 0,
      year: 0,
      cvv: "",
      zip: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    console.log("Payment page loaded")
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    //   let responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );
    //   let apiRequestCallId = message.getData(
    //     getName(MessageEnum.RestAPIResponceDataMessage)
    //   );
    //   if (responseJson && !responseJson.errors) {
    //   }
    // }
    // Customizable Area End
  }
  // Customizable Area Start
  onNewAddressClick = () => this.setState({ isNewAddressActive: true });

  setSelectedPaymentMethod = (method: string) =>
    this.setState({ selectedPaymentMethod: method });

  creditCardInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = "";
    if (e.target.value.length === 20) return;
    if (
      e.target.value.length < this.state.cardNumber.length &&
      e.target.value.slice(-1) === "-"
    ) {
      value = e.target.value.slice(0, -1);
    } else if (
      !/^\d+$/.test(e.target.value.slice(-1)) &&
      e.target.value.length !== 0
    ) {
      return;
    } else if (
      e.target.value.length === 4 ||
      e.target.value.length === 9 ||
      e.target.value.length === 14
    ) {
      value = e.target.value + "-";
    } else value = e.target.value;
    this.setState({ cardNumber: value });
  };

  changeShowCard = () =>
    this.setState((prev) => ({ showCard: !prev.showCard }));

  monthOnChange = (month: number) => this.setState({ month });

  yearOnChange = (year: number) => this.setState({ year });

  digitsValidation = (value: string, isCvv: boolean) => {
    if (!/^\d+$/.test(value.slice(-1)) && value.length !== 0) return;
    isCvv ? this.setState({ cvv: value }) : this.setState({ zip: value });
  };
  // Customizable Area End
}
