import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerAnalyticsInsightsController, {
  lineChartData,
  monthLabel,
  pieChartData,
  Props,
  saleCount,
} from "./SellerAnalyticsInsightsController";
import { Grid, styled, Box, Typography } from "@material-ui/core";
import SellerSidebar from "../../../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../../../components/src/Seller/SellerHeader";
import {
  backBtnIcon,
  downloadIcon,
  filterIcon,
  product1,
  product2,
  product3,
  product4,
} from "../../assets";
import PieChart from "../../../../../components/src/Chart/PieChart";
import TwoLineChart from "../../../../../components/src/Chart/LineChart";

// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class SellerAnalyticsInsights extends SellerAnalyticsInsightsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  rearrangedPieChartData = [...pieChartData.slice(1), pieChartData[0]];
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container className="grid-seller-store-profile">
          <Grid
            item
            xl={2}
            md={3}
            className="sidebar-seller-store-analytics"
            lg={2}
            sm={3}
            xs={3}
          >
            <SellerSidebar
              className="sidebar-seller-store-analytics"
              navigation={this.props.navigation}
            />
          </Grid>
          <Grid
            item
            xl={10}
            md={9}
            className="header-seller-store-analytics"
            lg={10}
            sm={9}
            xs={9}
          >
            <SellerHeader navigation={this.props.navigation} />
            <SellerStoreAnalyticsContainer>
              <SellerStoreHeader>
                <Box className="title-container">
                  <img
                    src={backBtnIcon}
                    data-test-id="back-btn-test-id"
                    alt="backIcon"
                    onClick={() => this.handleRedirect("SellerStoreProfile")}
                  />
                  <Typography className="title">
                    Analytics & Insights
                  </Typography>
                  <Typography className="second-title">
                    [Past 1 Month]
                  </Typography>
                </Box>
                <Box className="btn-container">
                  <img src={downloadIcon} alt="down-load-icon" />
                  <img src={filterIcon} alt="filter-icon" />
                </Box>
              </SellerStoreHeader>
              <SellerCountContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography className="seller-count-title">
                      Sales Count
                    </Typography>
                  </Grid>
                  {saleCount.map((item: { label: string; value: string }) => (
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <SalesCountContainer>
                        <Typography className="seller-count-show-div-label">
                          {item.label}
                        </Typography>
                        <Typography className="seller-count-show-div-value">
                          {item.value}
                        </Typography>
                      </SalesCountContainer>
                    </Grid>
                  ))}
                </Grid>
              </SellerCountContainer>
              <ChartContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                    <Box className="chart-outer-container">
                      <Typography className="chart-title">
                        Impression vs Visitors
                      </Typography>
                      <Box className="line-chart-labels-container">
                        {lineChartData.map(
                          (item: { label: string; color: string }) => (
                            <Box className="line-chart-label">
                              <Box
                                style={{ backgroundColor: item.color }}
                                className="color-picker"
                              />
                              <Typography>{item.label}</Typography>
                            </Box>
                          )
                        )}
                      </Box>
                      <Box className="line-chart-container">
                        <TwoLineChart
                          labels={monthLabel}
                          datasets={[
                            {
                              data: [
                                50, 50, 75, 125, 100, 150, 200, 150, 175, 200,
                                100, 50,
                              ],
                              borderColor: "#F59E0B",
                              backgroundColor: "#F59E0B",
                              borderWidth: 2,
                              fill: false,
                            },
                            {
                              data: [
                                50, 100, 100, 125, 150, 175, 150, 200, 175, 225,
                                125, 50,
                              ],
                              borderColor: "#34D399",
                              backgroundColor: "#34D399",
                              borderWidth: 2,
                              fill: false,
                            },
                          ]}
                          xTickColor="#375280"
                          yTickColor="#375280"
                          highlightLastTick
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                    <Box className="chart-outer-container">
                      <Typography className="chart-title">
                        Order Insights
                      </Typography>
                      <div className="pie-chart-container">
                        <div className="pie-chart">
                          <PieChart
                            labels={this.rearrangedPieChartData.map((item) => item.label)}
                            dataSetsVal={this.rearrangedPieChartData.map((item) => item.value)}
                            backgroundColor={this.rearrangedPieChartData.map((item)=>item.color)}
                          />
                        </div>
                        <div className="pie-chart-label-container">
                          {pieChartData.map(
                            (item: {
                              label: string;
                              color: string;
                              value: number;
                            }) => (
                              <Box className="pie-chart-label">
                                <Box
                                  style={{ backgroundColor: item.color }}
                                  className="color-picker"
                                />
                                <Box className="label-name">
                                  <Typography>{item.label}</Typography>
                                  <Typography>{`${item.value} %`}</Typography>
                                </Box>
                              </Box>
                            )
                          )}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </ChartContainer>
              <Box className="popular-product-container">
                <Box className="popular-product-title-container">
                  <Typography className="popular-product-title">
                    Popular Products
                  </Typography>
                  <Typography className="popular-product-second-title">
                    [Total popular products 1k]
                  </Typography>
                </Box>
                <Box className="products-container">
                  {popularProduct.map((item) => (
                    <Box className="product-container" key={item.orderNo}>
                      <Box className="product-detail-container">
                        <img src={item.image} alt={item.name} />
                        <Box className="product-detail">
                          <Typography className="product-name">
                            {item.name}
                          </Typography>
                          <Typography className="product-order">
                            {item.orderNo}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography className="product-price">
                        {item.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </SellerStoreAnalyticsContainer>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const popularProduct = [
  {
    image: product1,
    name: "Black Leather Boots (Women)",
    value: "$24,000",
    orderNo: "#4564-4512",
  },
  {
    image: product2,
    name: "Pink Midi Skirt with Pockets (Women)",
    value: "$20,000",
    orderNo: "#4564-4513",
  },
  {
    image: product3,
    name: "Oversized Brown T-shirt (Both)",
    value: "$4,000",
    orderNo: "#4564-4514",
  },
  {
    image: product4,
    name: "Leather Watch with round Dial (Men)",
    value: "$12,000",
    orderNo: "#4564-4515",
  },
];

const SellerStoreAnalyticsContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .popular-product-container": {
    padding: 20,
    border: "1px solid #E2E8F0",
    marginTop: 40,
    maxHeight: 480,
    overflowY: "auto",
    "& .popular-product-title-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
      "& .popular-product-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 20,
        color: "#375280",
      },
      "& .popular-product-second-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 16,
        marginTop: 3,
        color: "#375280",
      },
    },
    "& .products-container": {
      "& .product-container": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "16px 0px",
        borderBottom: "1px solid #E2E8F0",
        "& .product-detail-container": {
          display: "flex",
          gap: 20,
          "& .product-detail": {
            display: "flex",
            flexDirection: "column",
            gap: 15,
            fontFamily: "Lato, sans-serif",
            fontSize: 18,
            color: "#375280",
            "& .product-name": {
              fontWeight: 500,
            },
            "& .product-order": {
              fontWeight: 700,
            },
          },
        },
        "& .product-price": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 700,
          fontSize: 18,
          color: "#059669",
        },
      },
    },
  },
});

export const SellerStoreHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 44,
    "& .title-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
      height: "100%",
      "& .title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 24,
        color: "#375280",
      },
      "& .second-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 16,
        marginTop: 3,
        color: "#375280",
      },
    },
    "& .btn-container": {
      display: "flex",
      gap: 24,
      height: "100%",
    },
    "@media(max-width:600px)": {
      height: "max-content",
      "& .title-container": {
        "& .title": {
          fontWeight: 600,
          fontSize: "18px !important",
        },
      },
      "& .btn-container": {
        flexDirection: "column",
        gap: 4,
        height: "max-content",
      },
    },
});

export const SellerCountContainer = styled(Box)({
    padding: 20,
    border: "1px solid #E2E8F0",
    marginTop: 30,
    "& .MuiGrid-root.MuiGrid-container": {
      "& .MuiGrid-root.MuiGrid-item": {
        "& .seller-count-title": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 700,
          fontSize: 20,
          color: "#375280",
        },
      },
    },
});

export const SalesCountContainer = styled(Box)({
  width: "100%",
  height: 140,
  display: "flex",
  flexDirection: "column",
  gap: 15,
  backgroundColor: "#F8F8F8",
  alignItems: "center",
  justifyContent: "center",
  "& .seller-count-show-div-label": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 22,
    color: "#334155",
  },
  "& .seller-count-show-div-value": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 36,
    color: "#375280",
  },
});

export const ChartContainer = styled(Box)({
  width: "100%",
  "& .MuiGrid-root.MuiGrid-container": {
    height: "max-content",
    "& .MuiGrid-root.MuiGrid-item": {
      "& .chart-outer-container": {
        padding: 20,
        border: "1px solid #E2E8F0",
        marginTop: 30,
        height: "90%",
        "& .chart-title": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 700,
          fontSize: 20,
          color: "#375280",
        },
        "& .line-chart-container": {
          width: "100%",
        },
        "& .revenue-chart-container":{
          width: "100%",
          marginTop: 30
        },
        "& .pie-chart-container": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          "& .pie-chart": {
            width: "60%",
          },
          "& .pie-chart-label-container": {
            display: "flex",
            flexDirection: "column",
            gap: 30,
            width: "40%",
          },
          "& .pie-chart-label": {
            display: "flex",
            gap: 12,
            width: "100%",
            color: "#375280",
            fontSize: 16,
            "& .label-name": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 2,
            },
            "& .color-picker": {
              width: 20,
              borderRadius: 2,
              height: 24,
            },
          },
          "@media(max-width:800px)": {
            flexDirection: "column",
            gap: 20,
            "& .pie-chart": {
              width: "100%",
            },
            "& .pie-chart-label-container": {
              width: 320,
              alignItems: "center",
            },
          },
        },
        "& .line-chart-labels-container": {
          display: "flex",
          gap: 20,
          padding: "20px 0px",
          "& .line-chart-label": {
            display: "flex",
            gap: 12,
            justifyContent: "center",
            color: "#375280",
            fontSize: 16,
            "& .color-picker": {
              width: 20,
              height: 24,
              borderRadius: 2,
            },
          },
        },
        "@media(max-width:600px)": {
          "& .line-chart-container": {
            width: "100%",
            overflowX: "auto",
          },
        },
      },
    },
  },
});
// Customizable Area End
