import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { availableResources } from "./resource";
import { getStorageData } from "../../../framework/src/Utilities";

i18n
  .use(initReactI18next)
  .init({
    resources: availableResources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const i18nUpdate = async () => {
    const currentLang = await getStorageData('lang');
    if (currentLang) {
        i18n.changeLanguage(currentLang);
    }
}
