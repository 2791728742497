import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import {
  inventoryTotalProductIcon,
  inventoryOutOfStockIcon,
  inventoryLowOnStockIcon,
  inventoryUnlistedIcon,
} from "../../assets";
import { MyStoreAPIResponse, MyStoreList } from "../../MySellerStoreController";
import { ErrorMessage } from "../../CreateSellerStoreController";
import { apiCall } from "../../../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import { StoreOperatingHours } from "../../SellerStoreProfileController";
import {
  CatalogueItems,
  IInventoryResponse,
} from "./UpdateInventory/UpdateInventoryController";
import { ErrorMessageResponse } from "../../SellerStoreController";
import { logoutSellerNavigation } from "../../../../../components/src/Seller/logOut";
import i18n from "../../../../../components/src/i18next/i18n";
interface IAssignStoreResponse {
  id: number;
  path: string;
  name: string;
  isSelected: boolean;
}

interface ITabsData {
  label: string;
  testId: string;
  value: number;
}

interface FilterState {
  lowOnStock: boolean;
  unlisted: boolean;
  listed: boolean;
  outOfStock: boolean;
}

interface CatalogueVariants {
  id: string;
  type: string;
  attributes: {
    id: number;
    catalogue_id: number;
    product_name: string;
    product_description: string;
    sku: string;
    stock_qty: number;
    low_stock_threshold: number;
    is_listed: boolean;
    price: string;
    size: string;
    colour: string;
    gender: string;
    front_image: string;
    brand_name: string;
    pair_it_with: string[];
  };
}

interface IAssignStoreData {
  id: string;
  type: string;
  attributes: {
    store_name: string;
    description: string;
    address: string;
    area: string;
    block: string;
    mall_name: string;
    floor: string;
    unit_number: number;
    city: string;
    zipcode: string;
    driver_instruction: string;
    average_shipping_time: string;
    payment_mode: string[];
    store_operating_hours: StoreOperatingHours;
    status: string;
    latitude: number;
    longitude: number;
    is_open: boolean;
    available_variants: CatalogueVariants[];
    image: string;
    email: string;
    contact_number: {
      country_code: string;
      phone_number: string;
    };
    expected_delivery_time: string;
  };
}

export interface IUpdateAssignStoreResponse {
  data: IAssignStoreData[];
  meta: {
    message: string;
  };
}

interface IinventoryCount {
  total_products: number;
  out_of_stock: number;
  low_stock: number;
  unlisted: number;
}

interface IProductCountData {
  path: string;
  name: string;
  value: string;
}

interface CatalogueVariantSize {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  };
}

interface ICatalogueVariantSizeResp {
  data: CatalogueVariantSize[];
}

interface ICategoryResp {
  data: Category[];
}
interface Category {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}

interface SubCategory {
  id: string | number;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}

interface ISubCategoryResp {
  data: SubCategory[];
}

export interface IAssignFilterState{
  filterSizes: number[],
  filterCategory: number[],
  filterSubCategory: number[],
  value: { min: string, max: string },
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  filterPopup: {
    openInventoryUpdate: boolean;
    openAssignStore: boolean;
  };
  filterState: {
    updateInventory: FilterState;
    setPrice: {
      name: string;
    }[];
    selectedSetPrice: string;
  };
  variantIds: number[];
  isAssignStoreDialogOpen: boolean;
  activeTab: number;
  searchAssignStoreQuery: string;
  assignStoreDataResp: IAssignStoreResponse[];
  selectedAssignData: IAssignStoreResponse[];
  selectedStoreIds: number[];
  isAllSelectedStore: boolean;
  filter: {
    stock_status: string;
    listing?: boolean;
  };
  assignStoreError: {
    error: boolean;
    errorMessage: string;
  };
  assignStoreFilterError: {
    priceError: string;
    emptyError: string;
  };
  inventoryFilter: {
    error: boolean;
    errorMessage: string;
  };
  updatedInventory: CatalogueItems[];
  search: string;
  searchValue: string;
  isInParentAPICalled: boolean;
  severity: "error" | "success";
  message: string;
  isAlert: boolean;
  storeId: number;
  inventoryCount: IProductCountData[];
  roleData: string;
  inventoryTabData: ITabsData[];
  sizes: CatalogueVariantSize[];
  category: Category[];
  subCategory: SubCategory[];
  filterSizes: number[];
  filterCategory: number[];
  filterSubCategory: number[];
  value: { min: string; max: string };
  apiDoneCategoryIds: number[];
  assignFilterState: IAssignFilterState;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerInventoryManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private debounceTimeout: NodeJS.Timeout | null = null;
  assignStoreAPICallId: string = "";
  assignStoreUpdateAPICallId: string = "";
  getInventoryCountAPICallId: string = "";
  updateInventoryAPICallId: string = "";
  getSizeCallId: string = "";
  getCategoryCallId: string = "";
  getSubCategoriesCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      filterPopup: {
        openInventoryUpdate: false,
        openAssignStore: false,
      },
      filterState: {
        updateInventory: initialFilter12,
        setPrice: assignStoreFilters,
        selectedSetPrice: "Size",
      },
      assignStoreFilterError: {
        priceError: "",
        emptyError: "",
      },
      variantIds: [],
      activeTab: 1,
      isAssignStoreDialogOpen: false,
      searchAssignStoreQuery: "",
      inventoryTabData: [],
      assignStoreDataResp: [],
      selectedAssignData: [],
      selectedStoreIds: [],
      isAllSelectedStore: false,
      filter: initialFilter,
      assignStoreError: {
        error: false,
        errorMessage: "",
      },
      inventoryFilter: {
        error: false,
        errorMessage: "",
      },
      updatedInventory: [],
      search: "",
      searchValue: "",
      isInParentAPICalled: false,
      severity: "success",
      message: "",
      isAlert: false,
      storeId: 0,
      inventoryCount: inventoryTotalData,
      roleData: "",
      sizes: [],
      category: [],
      subCategory: [],
      filterSizes: [],
      filterCategory: [],
      filterSubCategory: [],
      value: { min: "", max: "" },
      apiDoneCategoryIds: [],
      assignFilterState: initialAssignStoreFilter
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleAssignStoreSearch = this.handleAssignStoreSearch.bind(this);
    this.filterAssignSearchData = this.filterAssignSearchData.bind(this);
    this.propsHandleSearch = this.propsHandleSearch.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const tabValue = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState((prevState) => ({
        ...prevState,
        activeTab: tabValue.sellerInventoryManagePageTabValue,
      }));
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseJson &&
        !responseJson.errors &&
        !responseJson.error &&
        !responseJson.message
      ) {
        this.apiSuccessCalled(apiRequestCallId, responseJson);
      } else if (
        responseJson &&
        (responseJson.error || responseJson.errors || responseJson.message)
      ) {
        this.apiFailureCalled(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiSuccessCalled = async (
    apiRequestCallId: string,
    responseJson: MyStoreAPIResponse &
      IUpdateAssignStoreResponse &
      IinventoryCount &
      IInventoryResponse &
      ICatalogueVariantSizeResp &
      ICategoryResp &
      ISubCategoryResp
  ) => {
    switch (apiRequestCallId) {
      case this.assignStoreAPICallId:
        await this.handleAssignStoreResp(responseJson);
        break;
      case this.assignStoreUpdateAPICallId:
        await this.handleAssignStoreUpdateResp(responseJson);
        break;
      case this.getInventoryCountAPICallId:
        if (responseJson) this.handleInventoryCountResp(responseJson);
        break;
      case this.updateInventoryAPICallId:
        await this.handleUpdateInventoryResp(responseJson);
        break;
      case this.getCategoryCallId:
        await this.handleCategoryResp(responseJson);
        break;
      case this.getSizeCallId:
        await this.handleSizeResp(responseJson);
        break;
      case this.getSubCategoriesCallId:
        await this.handleSubCategoryResp(responseJson);
        break;
    }
  };

  handleAssignStoreResp = async (responseJson: MyStoreAPIResponse) => {
    if (responseJson) {
      this.setState((prevState) => ({
        ...prevState,
        assignStoreDataResp: this.extractAssignStoreData(responseJson.data),
        selectedAssignData: this.extractAssignStoreData(responseJson.data),
        isLoader: false,
      }));
    }
  };

  handleAssignStoreUpdateResp = async (
    responseJson: IUpdateAssignStoreResponse
  ) => {
    this.setState((prevState) => ({
      ...prevState,
      isAllSelectedStore: false,
      selectedStoreIds: [],
      variantIds: [],
      isAlert: true,
      message: responseJson.meta.message,
      isInParentAPICalled: !prevState.isInParentAPICalled,
      selectedAssignData: prevState.assignStoreDataResp,
    }));
    await this.getInventoryProductCount(
      this.state.roleData,
      this.state.storeId
    );
  };

  handleUpdateInventoryResp = async (responseJson: IInventoryResponse) => {
    if (responseJson.data) {
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true,
        message: this.tranInventoryManagement("Your entries has Been Updated"),
        updatedInventory: [],
        isInParentAPICalled: !prevState.isInParentAPICalled,
      }));
      await this.getInventoryProductCount(
        this.state.roleData,
        this.state.storeId
      );
    }
  };

  handleCategoryResp = async (responseJson: ICategoryResp) => {
    if (responseJson.data) {
      this.setState((prevState) => ({
        ...prevState,
        category: responseJson.data,
      }));
    }
  };

  handleSizeResp = async (responseJson: ICatalogueVariantSizeResp) => {
    if (responseJson.data) {
      this.setState((prevState) => ({
        ...prevState,
        sizes: responseJson.data,
      }));
    }
  };

  handleSubCategoryResp = async (responseJson: ISubCategoryResp) => {
    if (responseJson.data) {
      this.setState((prevState) => ({
        ...prevState,
        subCategory: [...prevState.subCategory, ...responseJson.data],
      }));
    }
  }

  apiFailureCalled = (
    responseJson: ErrorMessage & ErrorMessageResponse & { message: string }
  ) => {
    if (
      responseJson.errors ===
        "Only seller can assign catalogue variants to stores" ||
      responseJson.message ===
        "You are not an authorized user or proper role basic" ||
      responseJson.message ===
        "You do not have permission to perform this action on the bussiness."
    ) {
      this.handleRedirectToHome(
        this.tranInventoryManagement("You are not an authorized person, Please Login with valid User Credential")
      );
    } else if (responseJson.error) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseJson.error,
      }));
    } else if (responseJson.errors[0].token) {
      this.handleRedirectToHome(responseJson.errors[0].token);
    } else if (responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseJson.errors as string,
      }));
    }
  };

  handleRedirectToHome = (message: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        isAlert: true,
        severity: "error",
        message: message,
      }),
      () => {
        setTimeout(() => {
          this.handleRedirectBack("Home");
          logoutSellerNavigation();
        }, 2000);
      }
    );
  };

  onAlertIsMSnackClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: false,
    }));
  };

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    const storeId = +(await this.getDataFromStorage("store-id"));
    this.setState((prevState) => ({
      ...prevState,
      storeId: storeId,
      roleData: userData.userType,
      inventoryTabData:
        userData.userType === "seller"
          ? stylishTab
          : stylishTab.filter((item) => item.testId !== "assignStore"),
    }));

    if (userData.userType === "buyer") history.back();
    else this.getInventoryProductCount(userData.userType, storeId);

    if (userData.userType === "seller") {
      await this.getMyStoreApi();
      await this.categoryTole();
      await this.sizeTole();
    }

    // Customizable Area End
  }

  getMyStoreApi = async () => {
    this.assignStoreAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.sellerMyStoreEndPoint}?approved=true`,
      token: await this.getLocalTokenData(),
    });
  };

  categoryTole = async () => {
    this.getCategoryCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getCategoriesEndPoint,
      token: await this.getLocalTokenData(),
    });
  };

  subCategoryTole = async (categoryId: number) => {
    this.getSubCategoriesCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getSubCategoriesEndPoint}${categoryId}`,
      token: await this.getLocalTokenData(),
    });
  };

  sizeTole = async () => {
    this.getSizeCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getCataloguesVariantsSizesEndPoint,
      token: await this.getLocalTokenData(),
    });
  };

  apiHandleAssignStore = async () => {
    const { selectedStoreIds, variantIds } = this.state;
    const body = {
      catalogue_variant_ids: variantIds,
      store_ids: selectedStoreIds,
    };
    this.assignStoreUpdateAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.assignStoreEndPoint,
      token: await this.getLocalTokenData(),
      body,
      isJsonStringify: true,
    });
  };

  getLocalTokenData = async () => {
    return await getStorageData("auth-token");
  };

  handleInventoryCountResp = (responseData: IinventoryCount) => {
    const updateInventoryData = [
      {
        path: inventoryTotalProductIcon,
        name: this.tranInventoryManagement("Total Products"),
        value: responseData.total_products.toString(),
      },
      {
        path: inventoryOutOfStockIcon,
        name: this.tranInventoryManagement("Out of Stock"),
        value: responseData.out_of_stock.toString(),
      },
      {
        path: inventoryLowOnStockIcon,
        name: this.tranInventoryManagement("Low Stock"),
        value: responseData.low_stock.toString(),
      },
      {
        path: inventoryUnlistedIcon,
        name: this.tranInventoryManagement("Unlisted"),
        value: responseData.unlisted.toString(),
      },
    ];

    this.setState((prevState) => ({
      ...prevState,
      inventoryCount: updateInventoryData,
    }));
  };

  getDataFromStorage = async (value: string) => {
    return await getStorageData(value);
  };

  handleUpdateInventoryFilterClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterPopup: {
        ...prevState.filterPopup,
        openInventoryUpdate: true,
      },
    }));
  };

  handleCloseUpdateInventoryFilter = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterPopup: {
        ...prevState.filterPopup,
        anchorElInventoryUpdate: false,
        openInventoryUpdate: false,
      },
    }));
  };

  handleAssignStoreFilterClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterPopup: {
        ...prevState.filterPopup,
        openAssignStore: true,
      },
    }));
  };

  handleCloseAssignStoreFilter = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterPopup: {
        ...prevState.filterPopup,
        openAssignStore: false,
      },
    }));
  };

  handleCheckboxFilterInventory = (value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      inventoryFilter: {
        ...prevState.inventoryFilter,
        error: false,
        errorMessage: "",
      },
    }));
    switch (value) {
      case "lowOnStock":
        this.setState((prevState) => ({
          filterState: {
            ...prevState.filterState,
            updateInventory: {
              ...prevState.filterState.updateInventory,
              lowOnStock: !prevState.filterState.updateInventory.lowOnStock,
            },
          },
        }));
        break;
      case "unlisted":
        this.setState((prevState) => ({
          filterState: {
            ...prevState.filterState,
            updateInventory: {
              ...prevState.filterState.updateInventory,
              unlisted: !prevState.filterState.updateInventory.unlisted,
            },
          },
        }));
        break;
      case "listed":
        this.setState((prevState) => ({
          filterState: {
            ...prevState.filterState,
            updateInventory: {
              ...prevState.filterState.updateInventory,
              listed: !prevState.filterState.updateInventory.listed,
            },
          },
        }));
        break;
      case "outOfStock":
        this.setState((prevState) => ({
          filterState: {
            ...prevState.filterState,
            updateInventory: {
              ...prevState.filterState.updateInventory,
              outOfStock: !prevState.filterState.updateInventory.outOfStock,
            },
          },
        }));
        break;
      default:
        break;
    }
  };

  handleClearAll = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterState: {
        ...prevState.filterState,
        updateInventory: initialFilter12,
      },
      filterPopup: {
        ...prevState.filterPopup,
        openInventoryUpdate: false,
      },
      inventoryFilter: {
        error: false,
        errorMessage: "",
      },
      filter: {
        stock_status: "",
      },
    }));
  };

  handleAssignStoreClearAll = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterPopup: {
        ...prevState.filterPopup,
        openAssignStore: false,
      },
      assignStoreFilterError: {
        priceError: "",
        emptyError: "",
      },
      filterSizes: [],
      filterCategory: [],
      filterSubCategory: [],
      value: { min: "", max: "" },
      assignFilterState: initialAssignStoreFilter
    }));
  }

  areAllFiltersFalse = (filterState: FilterState): boolean => {
    return (
      !filterState.lowOnStock &&
      !filterState.unlisted &&
      !filterState.listed &&
      !filterState.outOfStock
    );
  };

  handleFilterApply = () => {
    const FilterData = this.state.filterState.updateInventory;
    if (this.areAllFiltersFalse(FilterData)) {
      this.setState((prevState) => ({
        ...prevState,
        inventoryFilter: {
          ...prevState.inventoryFilter,
          error: true,
          errorMessage: this.tranInventoryManagement("Please Select any one for Filter. This is required for Filter"),
        },
      }));
      return;
    }
    const filterStatus = this.getFilterValue(FilterData);
    const filterListing = this.getListingFilterValue(FilterData);
    this.setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        stock_status: filterStatus,
        listing: filterListing,
      },
      filterPopup: {
        ...prevState.filterPopup,
        openInventoryUpdate: false,
      },
    }));
  };

  areNotValueForAssignStoreFilter = () => {
    const {filterSizes, filterCategory, filterSubCategory, value} = this.state;
    return ((filterCategory.length === 0 && filterSubCategory.length === 0 && filterSizes.length === 0) && (value.max==="" && value.min===""))
  }

  handleAssignStoreFilterApply = () => {
    const {value, filterSizes, filterCategory, filterSubCategory, assignStoreFilterError} = this.state;
    if(assignStoreFilterError.priceError!=="") return;
    else if (this.areNotValueForAssignStoreFilter()) {
      this.setState((prevState) => ({
        ...prevState,
        assignStoreFilterError: {
          ...prevState.assignStoreFilterError,
          emptyError: this.tranInventoryManagement("Please Select any one for Filter. This is required for Filter"),
        },
      }));
      return;
    }
    else{
      this.setState((prevState) => ({
        ...prevState,
        assignFilterState: {
          ...prevState.assignFilterState,
          value, filterSizes, filterCategory, filterSubCategory,
        },
        filterPopup: {...prevState.filterPopup, openAssignStore: false}
      }));
    }
  }

  getFilterValue = (filter: FilterState): string => {
    switch (true) {
      case filter.lowOnStock && filter.outOfStock:
        return "both";
      case filter.lowOnStock && !filter.outOfStock:
        return "low_on_stock";
      case filter.outOfStock && !filter.lowOnStock:
        return "out_of_stock";
      default:
        return "";
    }
  };

  getListingFilterValue = (filter: FilterState): boolean | undefined => {
    if (filter.unlisted && !filter.listed) {
      return false;
    } else if (filter.listed && !filter.unlisted) {
      return true;
    } else {
      return undefined;
    }
  };

  handleInventoryTabChange = async (newValue: number) => {
    this.setState((prevState) => ({
      ...prevState,
      activeTab: newValue,
      search: "",
      searchValue: "",
      filter: initialFilter,
      filterState: {
        ...prevState.filterState,
        updateInventory: initialFilter12,
      },
      updatedInventory: [],
      selectedAssignData: prevState.assignStoreDataResp,
      selectedStoreIds: [],
      variantIds: [],
      assignFilterState: initialAssignStoreFilter
    }));
  };

  handleRedirectBack = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    this.send(userNavMsg);
  };

  handleUpdateStore = (updatedInventory: CatalogueItems[]) => {
    this.setState((prevState) => ({
      ...prevState,
      updatedInventory: updatedInventory,
    }));
  };

  handleConfirmUpdateInventory = async () => {
    const { updatedInventory } = this.state;
    const body = { catalogue_variants: updatedInventory };
    this.updateInventoryAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPatchMethod,
      endPoint: configJSON.updateInventoryEndPoint,
      token: await this.getDataFromStorage("auth-token"),
      body: body,
      isJsonStringify: true,
    });
  };

  getInventoryProductCount = async (userType: string, storeId: number) => {
    this.getInventoryCountAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint:
        userType === "stylist"
          ? configJSON.getStylishInventoryCountEndPoint
          : `${configJSON.getSellerInventoryCountEndPoint}${storeId}/store_variant_count`,
      token: await this.getDataFromStorage("auth-token"),
    });
  };

  handleSelectedSetPricesFilter = (item: string) => {
    if(this.state.assignStoreFilterError.priceError) return;
    this.setState((prevState) => ({
      filterState: {
        ...prevState.filterState,
        selectedSetPrice: item,
      },
    }));
  };

  isSizeChecked = (id: number): boolean => {
    const { filterSizes } = this.state;
    return filterSizes.includes(id);
  };

  isCategoryChecked = (id: number): boolean => {
    const { filterCategory } = this.state;
    return filterCategory.includes(id);
  };

  isSubCategoryChecked = (id: number): boolean => {
    const { filterSubCategory } = this.state;
    return filterSubCategory.includes(id);
  };

  handleAsFilterPriceChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    const newData = { ...this.state.value };
    let parsedValue = event.target.value.replace(/[^0-9.]/g, "");

    if (
      (parsedValue.startsWith("0") && parsedValue.length === 1) ||
      parsedValue.startsWith(".")
    ) {
      parsedValue = "";
    }

    if (index === 0) {
      newData.min = parsedValue;
    } else if (index === 1) {
      newData.max = parsedValue;
    }

    this.setState((prevState) => ({
      ...prevState,
      value: newData,
      assignStoreFilterError: {
        ...prevState.assignStoreFilterError,
        priceError: "",
        emptyError: "",
      },
    }));
  };

  handlePriceBlur = () => {
    const { min, max } = this.state.value;

    const minPrice = parseFloat(min) || 0;
    const maxPrice = parseFloat(max) || 0;

    if (maxPrice < minPrice && maxPrice !== 0 && minPrice !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        assignStoreFilterError: {
          ...prevState.assignStoreFilterError,
          priceError: this.tranInventoryManagement("Max price cannot be less than min price"),
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        assignStoreFilterError: {
          ...prevState.assignStoreFilterError,
          priceError: "",
        },
      }));
    }
  };

  handleSizeCheckboxChange = (id: number) => {
    this.setState((prevState) => {
      const { filterSizes } = prevState;

      const newFilterSizes = filterSizes.includes(id)
        ? filterSizes.filter((sizeId) => sizeId !== id)
        : [...filterSizes, id];

      return { filterSizes: newFilterSizes, assignStoreFilterError: {
        ...prevState.assignStoreFilterError,
        emptyError: "",
      }};
    });
  };

  handleCategoryCheckboxChange = (id: number) => {
    this.setState((prevState) => {
      const { filterCategory, apiDoneCategoryIds } = prevState;
  
      const newFilterCategories = filterCategory.includes(id)
        ? filterCategory.filter((categoryId) => categoryId !== id)
        : [...filterCategory, id];
  
      let updatedApiDoneCategoryIds = apiDoneCategoryIds;
  
      if (!apiDoneCategoryIds.includes(id)) {
        this.subCategoryTole(id);
  
        updatedApiDoneCategoryIds = [...apiDoneCategoryIds, id];
      }
  
      return {
        filterCategory: newFilterCategories,
        apiDoneCategoryIds: updatedApiDoneCategoryIds,
        assignStoreFilterError: {
          ...prevState.assignStoreFilterError,
          emptyError: "",
        }
      };
    });
  };

  handleSubCategoryCheckboxChange = (id: number) => {
    this.setState((prevState) => {
      const { filterSubCategory } = prevState;

      const newFilterSubCategory = filterSubCategory.includes(id)
        ? filterSubCategory.filter((subCategoryId) => subCategoryId !== id)
        : [...filterSubCategory, id];

      return { filterSubCategory: newFilterSubCategory, assignStoreFilterError: {
        ...prevState.assignStoreFilterError,
        emptyError: "",
      } };
    });
  }

  handleAssignStore = (variantIds: number[]) => {
    this.setState((prevState) => ({
      ...prevState,
      variantIds,
    }));
  };

  handleAssignStoreDialogClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAssignStoreDialogOpen: !prevState.isAssignStoreDialogOpen,
      assignStoreError: {
        ...prevState.assignStoreError,
        error: false,
        errorMessage: "",
      },
    }));
  };

  handleAssignSubmitStore = async () => {
    if (this.state.selectedStoreIds.length === 0) {
      this.setState((prevState) => ({
        ...prevState,
        assignStoreError: {
          ...prevState.assignStoreError,
          error: true,
          errorMessage: this.tranInventoryManagement("Please Select any one store. This is required For Assign Store"),
        },
      }));
      return;
    }
    await this.apiHandleAssignStore();
    this.handleAssignStoreDialogClose();
  };

  debounceTime(func: (...args: any[]) => void, delay: number) {
    return (...args: any[]) => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleAssignStoreSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    this.setState((prevState) => ({
      ...prevState,
      searchAssignStoreQuery: query,
      selectedStoreIds: [],
      isAllSelectedStore: false,
    }));
    this.debounceTime(this.filterAssignSearchData, 300)();
  };

  handleSearchInventory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    this.setState((prevState) => ({
      ...prevState,
      search: query,
    }));
    this.debounceTime(() => this.propsHandleSearch(query), 800)();
  };

  propsHandleSearch = (value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      searchValue: value,
    }));
  };

  filterAssignSearchData = () => {
    const {
      assignStoreDataResp,
      searchAssignStoreQuery,
      isAllSelectedStore,
      selectedStoreIds,
    } = this.state;
    if (!searchAssignStoreQuery) {
      const updatedFilteredData = assignStoreDataResp.map((assignStore) => ({
        ...assignStore,
        isSelected: selectedStoreIds.includes(assignStore.id),
      }));

      const selectedCount = updatedFilteredData.filter(
        (assignStore) => assignStore.isSelected
      ).length;

      this.setState((prevState) => ({
        ...prevState,
        selectedAssignData: updatedFilteredData,
        isAllSelectedStore:
          assignStoreDataResp.length === selectedCount && selectedCount !== 0,
      }));
      return;
    }

    const filteredData = assignStoreDataResp.filter((assignStore) =>
      assignStore.name.toLowerCase().includes(searchAssignStoreQuery)
    );

    const updatedFilteredData = filteredData.map((assignStore) => ({
      ...assignStore,
      isSelected: selectedStoreIds.includes(assignStore.id),
    }));

    const selectedCount = updatedFilteredData.filter(
      (assignStore) => assignStore.isSelected
    ).length;

    this.setState((prevState) => ({
      ...prevState,
      selectedAssignData: updatedFilteredData,
      selectedStoreIds: filteredData.map((item) => item.id),
      isAllSelectedStore:
        updatedFilteredData.length === selectedCount
          ? true
          : isAllSelectedStore,
    }));
  };

  handleCheckboxSelectStore = (id: number, isSelected: boolean) => {
    this.setState((prevState) => {
      const updatedStoreStatusData = prevState.selectedAssignData.map((store) =>
        store.id === id ? { ...store, isSelected } : store
      );

      const updatedSelectedStoreIds = isSelected
        ? [...prevState.selectedStoreIds, id]
        : prevState.selectedStoreIds.filter((storeId) => storeId !== id);

      return {
        selectedAssignData: updatedStoreStatusData,
        selectedStoreIds: updatedSelectedStoreIds,
        isAllSelectedStore:
          updatedStoreStatusData.length === updatedSelectedStoreIds.length,
        assignStoreError: {
          ...prevState.assignStoreError,
          error: false,
          errorMessage: "",
        },
      };
    });
  };

  handleCheckboxAllSelectStore = () => {
    this.setState((prevState) => {
      const isAllSelected = !prevState.isAllSelectedStore;
      const updatedSelectedStoreIds = isAllSelected
        ? this.getAllStoreIds()
        : [];

      const updatedStoreStatusData = prevState.selectedAssignData.map(
        (item) => ({
          ...item,
          isSelected: isAllSelected,
        })
      );

      return {
        ...prevState,
        isAllSelectedStore: isAllSelected,
        selectedStoreIds: updatedSelectedStoreIds,
        selectedAssignData: updatedStoreStatusData,
        assignStoreError: {
          ...prevState.assignStoreError,
          error: false,
          errorMessage: "",
        },
      };
    });
  };

  getAllStoreIds = () => {
    return this.state.selectedAssignData.map((store) => store.id);
  };

  extractAssignStoreData = (items: MyStoreList[]): IAssignStoreResponse[] => {
    return items
      .filter((item) => +item.id !== this.state.storeId)
      .map((item) => {
        const { id, attributes } = item;
        return {
          id: +id,
          path: attributes.image,
          name: attributes.store_name,
          isSelected: false,
        };
      });
  };

  determineVariantStyle = (condition: boolean) => {
    return {
      variant: condition ? "contained" as "contained" : "outlined" as "outlined",
      className: condition ? "contained-btn-confirm" : "outlined-btn-confirm",
    };
  };

  tranInventoryManagement = (transKey: string) => {
    return i18n.t(transKey, {ns: "inventoryManagement"});
  }
  // Customizable Area End
}
// Customizable Area Start

const initialFilter = {
  stock_status: "",
};

const initialFilter12 = {
  lowOnStock: false,
  unlisted: false,
  listed: false,
  outOfStock: false,
};

const initialAssignStoreFilter = {
  filterSizes: [],
  filterCategory: [],
  filterSubCategory: [],
  value: { min: "", max: "" },
}

export const inventoryTotalData = [
  { path: inventoryTotalProductIcon, name: "Total Products", value: "" },
  { path: inventoryOutOfStockIcon, name: "Out of Stock", value: "" },
  { path: inventoryLowOnStockIcon, name: "Low Stock", value: "" },
  { path: inventoryUnlistedIcon, name: "Unlisted", value: "" },
];

const stylishTab = [
  { label: "Update Inventory", value: 1, testId: "updateInventory" },
  { label: "Set Prices", value: 2, testId: "setPrices" },
  { label: "Assign Store", value: 3, testId: "assignStore" },
  { label: "Pair the Products", value: 4, testId: "pairTheProducts" },
  { label: "Bulk Actions", value: 5, testId: "bulkActions" },
  { label: "Download Templates", value: 6, testId: "downloadTemplates" },
];

const assignStoreFilters = [
  {
    name: "Size",
  },
  {
    name: "Price",
  },
  {
    name: "Category",
  },
  {
    name: "Sub Category",
  },
];
// Customizable Area End
