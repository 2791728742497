import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
interface INavigateTo {
    props: unknown;
    productId: string | undefined | number;
    raiseMessage?: Message;
    screenName: string;
}
export interface TermsConds {
    description: string;
    id: string;
    created_at: string;
    is_accepted: boolean;
}
// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userToken: string;
    hiredStylist: string | number;
    termsConds: {
        id: string;
        description: string;
        is_accepted: boolean;
        created_at: string;
    };
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getTermsCondsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            userToken: "",
            hiredStylist: "",
            termsConds: {
                id: "",
                description: "",
                is_accepted: false,
                created_at: "",
            },
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getTermsCondsCallId !== null &&
            this.getTermsCondsCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonCategory = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ termsConds: responseJsonCategory })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const hiredStylistId = await getStorageData("hiredStylistId")
        const userToken = await getStorageData('auth-token');
        this.setState({ userToken: userToken, hiredStylist: hiredStylistId });
        this.termsCondsStylist()
    }

    termsCondsStylist = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.userToken
        };
        const requestMessageList = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTermsCondsCallId = requestMessageList.messageId;
        requestMessageList.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessageList.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_terms_and_conditions/terms_and_condition_for_buyer");
        requestMessageList.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    navigateToStylist = ({
        productId,
        props,
        screenName,
    }: INavigateTo) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
        runEngine.sendMessage(message.id, message);
    }
    // Customizable Area End
}
