import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Divider
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { vectorImg, scrollImg, backArrowImg, callBtn, msgBtn } from "./assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { withScriptjs } from 'react-google-maps';
import TrackOrderController, {
  Props,
} from "./TrackOrderController.web";
import TrackMap from "../../../components/src/TrackMap";
import moment from "moment";
import i18n from "../../../components/src/i18next/i18n";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      color: "#375280",
      fontFamily: "Lato, sans-serif",
    },
  },
});

export default class TrackOrder extends TrackOrderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // istanbul ignore next
  parseEstimatedDeliveryTime = (time: moment.MomentInput) => {
    if (typeof time === 'string' && (time.includes('day') || time.includes('hour') || time.includes('minute'))) {
      return time;
    } else {
      const deliveryTime = moment(time).minutes();
      return `${deliveryTime} ${this.transTrackOrder("Minutes")}`;
    }
  }

  handleImgTOVectorIcon = ()=>(
    <img src={vectorImg} style={{transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
)

handleTrackOrderImgCSS = () =>{
  return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
}

navTrackOrderHeaderMargin = () =>{
  return i18n.dir() === "ltr" ? "20px 0px 0px 40px" : "20px 40px 0px 0px"
}

navAddAddressNameHeaderMargin = () =>{
  return i18n.dir() === "ltr" ? "0px 0px 31px 40px" : "0px 40px 31px 0px"
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    const MapLoader = withScriptjs(TrackMap);
    const origin = { lat: this.state.TrackOrderDetailsList.attributes.order_items[0].attributes.driver_latitude, lng: this.state.TrackOrderDetailsList.attributes.order_items[0].attributes.driver_longitude };
    const destination = { lat: this.state.TrackOrderDetailsList.attributes.delivery_addresses.attributes.latitude, lng: this.state.TrackOrderDetailsList.attributes.delivery_addresses.attributes.longitude };
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Header navigation={this.props.navigation} />
        <MainBox data-test-id="mainGrid" dir={i18n.dir()}>
          <Box className="nav-header" style={{margin: this.navTrackOrderHeaderMargin()}}>
            <Typography onClick={this.proceedToTrackHomePage} className="nav-steps" data-test-id="proceedToTrackHomePage">{this.transTrackOrder("Home")}</Typography>
            {this.handleImgTOVectorIcon()}
            <Typography className="nav-steps">
            {this.transTrackOrder("My Account")}
            </Typography>
            {this.handleImgTOVectorIcon()}
            <Typography className="nav-steps" data-test-id="proceedToTrackPage" onClick={this.proceedToTrackPage}>{this.transTrackOrder("My Order")}</Typography>
            {this.handleImgTOVectorIcon()}
            <Typography className="nav-steps-active">
            {this.transTrackOrder("Return Status")}
            </Typography>
          </Box>
          <Grid className="content-block" container>
            <Grid item md={3} lg={3}>
              <Box style={{margin: this.navAddAddressNameHeaderMargin()}}>
              <Box>
                <Typography className="user-name">
                  <img src={scrollImg} />

                  {this.state.profileName.split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}

                </Typography>
              </Box>
              <Typography className="welcome-text">{this.transTrackOrder("Welcome to your Account")}</Typography>
              </Box>
              <Sidebar navigation={this.props.navigation}/>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              xl={8}
              lg={8}
              md={8}
            >
              <Box className="details-header" >
                 <Box className="header-left-block">
                  <img src={backArrowImg} className="back-arrow" style={{transform: this.handleTrackOrderImgCSS()}} data-test-id="navigateToOrderDetail" onClick={() => this.navigateToOrderDetail({ props: this.props, screenName: "OrderDetails" })}/>
                  <Typography className="section-name">{this.transTrackOrder("Track Order")}</Typography>
                </Box>
              </Box>
              <Box className="TrackOrderContainer">
              <Grid container spacing={8}>
                  <Grid item xs={12} md={6}>
                    <MapLoader
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC74_7QnkOgBJRb2SecLdMvVrDL57anZzw"
                      loadingElement={<div className="MapComponentHeight" />}
                      origin={origin}
                      destination={destination}/>
                  </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="trackDetailContainer">
                      <Typography className="trackDetailTitle">
                        {this.state.TrackOrderDetailsList.attributes.order_items[0].attributes.driver_name} {this.transTrackOrder("is your delivery partner.")}
                      </Typography>
                      <Grid container>
                      <Grid item xs={12} md={6}>
                      <Box className="OtpContainer">
                        <span className="OtpTitle">{this.transTrackOrder("OTP")} - {this.state.TrackOrderDetailsList.attributes.order_items[0].attributes.otp}</span>
                      </Box>
                      <Typography className="arrivingTitle">
                      {this.transTrackOrder("Arriving in")} {this.parseEstimatedDeliveryTime(this.state.TrackOrderDetailsList.attributes.estimated_delivery_time)}
                      </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box className="msgCallBtnContainer">
                          <Box className="msgBtnAlign">
                            <img src={msgBtn} alt="" />
                          </Box>
                          <Box className="callBtnAlign">
                          <img src={callBtn} alt="" />
                          </Box>
                      </Box>
                      <Typography className="contactTitle">
                      {this.transTrackOrder("Contact")} {this.state.TrackOrderDetailsList.attributes.order_items[0].attributes.driver_name}
                      </Typography>
                      </Grid>
                      </Grid>
                      <Divider className="TRackDividerMargin"/>
                      <Typography className="orderTitle">
                      {this.transTrackOrder("Order is out for delivery")}
                      </Typography>
                      <Typography className="orderDescription">
                      {this.transTrackOrder("Your order is expected to arrive at")} {this.state.TrackOrderDetailsList.attributes.expected_time}
                      </Typography>
                  </Box>
                </Grid>
              </Grid>
              </Box>
            </Grid> 
          </Grid> 
        </MainBox> 
        <Footer /> 
      </ThemeProvider> 
    ); 
    // Customizable Area End
  }
}
// Customizable Area Start
const MainBox = styled(Box)({
  background: "#F8F8F8",
  fontFamily: "Lato",
  paddingTop: "27px",
  "& .MuiCardContent-root": {
    padding: "40px"
  },
  "& .nav-header": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  "& .MapComponentHeight": {
    height: "100%"
  },
  "& .containerElementHeight": {
    height: "800px"
  },
  "& .nav-steps": {
    fontStyle: "normal",
    lineHeight: "22px",
    fontSize: "14px",
    color: "#475569",
    padding: "4px 6px 4px 6px",
  },
  "& .nav-steps-active": {
    fontStyle: "normal",
    fontSize: "14px",
    padding: "4px 6px 4px 6px",
    lineHeight: "22px",
    color: "#475569",
    fontWeight: 1000,
  },
  "& .user-name": {
    alignItems: "center",
    display: "flex",
    gap: "15px",
    fontWeight: 800,
    fontSize: "28px",
    fontStyle: "normal",
  },
  "& .welcome-text": {
    color: "#807D7E",
    fontStyle: "normal",
    fontSize: "14px",
  },
  "& .content-block": {
    marginTop: "2rem",
  },
  "& .details-header": {
    justifyContent: "space-between",
    display: "flex",
  },
  "& .header-left-block": {
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
  },
  "& .back-arrow": {
    borderRadius: "100px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px #00000014",
    cursor: "pointer",
    padding: "0.5rem",
  },
  "& .section-name": {
    fontWeight: 800,
    fontSize: "1.25rem",
  },
  "& .TrackOrderContainer": {
    margin: "2.5rem 0"
  },
  "& .trackDetailContainer": {
    padding: "1.5rem 2.5rem",
    backgroundColor: "#FFF",
    borderRadius: "2px",
  },
  "& .trackDetailTitle": {
    textAlign: "center",
    fontSize: "24px",
    color: "#375280",
    fontWeight: 500,
  },
  "& .orderTitle": {
    fontSize: "24px",
    color: "#375280",
    fontWeight: 500,
  },
  "& .orderDescription": {
    fontSize: "20px",
    color: "#94A3B8",
    fontWeight: 500,
  },
  "& .OtpContainer": {
    padding: "19px 15px",
    borderRadius: "2px",
    border: "1px solid #CCBEB1",
    textAlign: "center",
    width: "120px",
    marginTop: "25px"
  },
  "& .OtpTitle": {
    fontSize: "18px",
    color: "#375280",
    fontWeight: 800,
    lineHeight: "24px"
  },
  "& .arrivingTitle": {
    textAlign: "left",
    fontSize: "18px",
    color: "#375280",
    fontWeight: 500,
    marginTop: "20px"
  },
  "& .msgCallBtnContainer": {
    display: "flex",
    justifyContent: "end",
    marginTop: "25px",
    gap: 20
  },
  "& .callBtnAlign": {
    borderRadius: "3px",
    backgroundColor: "#CCBEB1",
    padding: "16px",
  },
  "& .msgBtnAlign": {
    backgroundColor: "#CCBEB1",
    padding: "16px",
    borderRadius: "3px",
  },
  "& .contactTitle": {
    textAlign: "end",
    fontSize: "18px",
    color: "#375280",
    fontWeight: 500,
    marginTop: "20px"
  },
  "& .TRackDividerMargin": {
    margin: '24px 0',
  }
});

// Customizable Area End
