import React from "react"
// Customizable Area Start
import {
    Box,
    Grid,
    FormControl,
    withStyles,
    Divider,
    FormControlLabel,
    WithStyles,
    Button,
    Modal, Backdrop, Fade,
    MenuItem,
    Popover,
    CircularProgress
} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/date-fns';
import { button_filter, group_group, group, button_close, calendar } from "./assets"
import { styled, createTheme } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import EarningsAnalyticsPageControllerWeb, { Props } from './EarningsAnalyticsPageWebController';
import SellerSideBar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import clsx from "clsx";
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LineController,
} from 'chart.js';
import i18n from "../../../components/src/i18next/i18n";
import { enUS, arSA } from 'date-fns/locale';
import { FormHelperTextContainer } from "./SellerProductOverview.web";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LineController
);
export const localeMap: { en: Locale; ar: Locale } = {
    en: enUS,
    ar: arSA,
  };
// Customizable Area End

export default class EarningsAnalyticsWebPage extends EarningsAnalyticsPageControllerWeb {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }
    // Customizable Area Start
    getFilterLabel = () => {
        const { tempFilter } = this.state;
        switch (tempFilter) {
            case 'this_week':
                return this.transEarnings('Week');
            case 'this_month':
                return this.transEarnings('Month');
            case 'this_year':
                return this.transEarnings('Year');
            case 'select_date_range':
                return "";
        }
    };
    renderEarningDetails = () => {
        const { earningDetails } = this.state;

        if (earningDetails?.length > 0) {
            return earningDetails.map((detail, index) => (
                <Box key={index} style={{ marginBottom: '24px' }}>
                    <Box style={{ ...webStyle.flexBox }}>
                        <Box style={webStyle.boxWrap}>
                            <img
                                src={detail.status === 'credit' ? group : group_group}
                                style={{ marginRight: '10px' }}
                                alt="Order"
                            />
                            <Box>
                                <Box style={webStyle.orderIde}>
                                    {this.transEarnings("Order")} #{detail.order_number}
                                </Box>
                                <Box style={webStyle.orderData}>
                                    {new Date(detail.created_at).toLocaleDateString(i18n.language, {
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric',
                                    })}{' '}
                                    | {detail.catalogue_name}
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            style={
                                detail.status === 'credit'
                                    ? webStyle.orderAccept
                                    : webStyle.orderReject
                            }
                        >
                            ${detail.amount}
                        </Box>
                    </Box>
                </Box>
            ));
        } else {
            return (
                <Box style={{ ...webStyle.tabeLableRejected, textAlign: 'center' }}>
                    {this.transEarnings("No Transactions found")}
                </Box>
            );
        }
    }

    earningAnalyticsHeader = () => {
        const { roleData } = this.state;
        switch (roleData) {
          case "seller":
            return <SellerHeader navigation={this.props.navigation} data-test-id="ea-seller-header" />;
          case "stylist":
            return <StylishHeader navigation={this.props.navigation} data-test-id="ea-stylist-header" />;
          default:
            return <></>;
        }
    };
    
    earningAnalyticsSidebar = () => {
        const { roleData } = this.state;
        switch (roleData) {
          case "seller":
            return (
              <SellerSideBar
                navigation={this.props.navigation}
                data-test-id="ea-seller-sidebar"
              />
            );
          case "stylist":
            return (
              <StylishSidebar
                navigation={this.props.navigation}
                data-test-id="ea-stylist-sidebar"
              />
            );
          default:
            return <></>;
        }
    };

    getTitleLabel = () => (
        <Box style={webStyle.earningsMothe}>
        [ {this.state.tempFilter === "select_date_range" ? `${this.handleDateShow(this.state.startDate)} - ${this.handleDateShow(this.state.endDate)}` : this.transEarnings("Past 1")} {this.getFilterLabel()} ]
        </Box>)

    // Customizable Area End
    render() {
        // Customizable Area Start
        const open = Boolean(this.state.anchorEl);
        const labels = Object.keys(this.state.dataChart || {});
        const data = Object.values(this.state.dataChart || {}).map(item => item.total_earnings);
        const minDate = Math.min(...data);
        const currentDate = new Date();
        const maxDate = Math.max(...data);
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <div id="pdfContent" dir={i18n.dir()}>
                    <Grid container>
                        <Grid item xl={2} md={2} lg={2} sm={2} xs={2} >
                            {this.earningAnalyticsSidebar()}
                        </Grid>
                        <Grid item xl={10} md={10} lg={10} sm={10} xs={10}  >
                            {this.earningAnalyticsHeader()}
                            <Box style={{ margin: "30px" }}>
                                <Box style={webStyle.flexBox}>
                                    <Box style={webStyle.earningsName}>{this.transEarnings("Earnings")}&nbsp;&nbsp;
                                        {this.getTitleLabel()}
                                    </Box>
                                    <Box style={webStyle.downloadImg}>
                                        <Box>
                                            <img width="30px" height="30px" src={button_filter} data-testid="handlePopoverOpen" onClick={this.handlePopoverOpen} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{ marginTop: '30px' }}>
                                    <Box style={{ marginBottom: '30px' }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <Box style={webStyle.amountDetail} >
                                                    <Box style={webStyle.monthIncom} data-testid="monthlyDate">{this.transEarnings("Monthly Income Data")}</Box>
                                                    <Box style={webStyle.monthData}>{this.transEarnings("X-Axis - Month Dates")}&nbsp;&nbsp;&nbsp;{this.transEarnings("Y-Axis - USD")}</Box>
                                                    <div data-testid="chart-container">
                                                        {this.state.isChartLoading ? <Box
                                                            data-testid="loader"
                                                            style={{
                                                                ...webStyle.tabsBoxInProcess,
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <CircularProgress style={{ color: '#375280' }} />
                                                        </Box> :
                                                            <Line data-testid="mock-chart" width={600}
                                                                height={150}
                                                                data={{
                                                                    labels: labels.map(label=> this.transEarnings(label)),
                                                                    datasets: [
                                                                        {
                                                                            data,
                                                                            fill: false,
                                                                            borderColor: "#F59E0B",
                                                                            pointRadius: 0,
                                                                            tension: 0.4
                                                                        },
                                                                    ],
                                                                }}
                                                                options={{
                                                                    responsive: true,
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                            position: 'top',
                                                                        },
                                                                    },
                                                                    scales: {
                                                                        x: {
                                                                            grid: {
                                                                                drawOnChartArea: true,
                                                                                display: true,
                                                                                drawBorder: true,
                                                                                drawTicks: true,
                                                                                color: (context) => {
                                                                                    if (context.tick.value === 0) {
                                                                                        return "lightgray";
                                                                                    }
                                                                                    if (context.tick.value === labels.length - 1) {
                                                                                        return "lightgray";
                                                                                    }
                                                                                    return "transparent";
                                                                                },
                                                                            },
                                                                            reverse:i18n.dir() === "rtl",
                                                                            type: 'category',
                                                                        },
                                                                        y: {
                                                                            grid: {
                                                                                drawOnChartArea: true,
                                                                                drawBorder: false,
                                                                            },
                                                                            position: i18n.dir() === "rtl" ? "right": "left",
                                                                            beginAtZero: true,
                                                                            min: minDate,
                                                                            max: maxDate,
                                                                        },
                                                                    },
                                                                }} />
                                                        }
                                                    </div>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box style={webStyle.amountDetail}>
                                                <Box style={{ ...webStyle.flexBox, marginBottom: '16px' }}>
                                                    <Box style={webStyle.withdrawAmount}>{this.transEarnings("Transactions")}</Box>
                                                    <Box>
                                                        <CustomSelect variant="outlined" style={webStyle.selectBox}>
                                                            <MuiSelectSelect
                                                                style={webStyle.reminderName}
                                                                value={this.state.numberData}
                                                                onChange={(event) => this.handleSingleChangeNumber(event)}
                                                                inputProps={{ 'aria-label': this.transEarnings('Select Option'), "data-testid": "select-id", }}
                                                                data-testid='sub-categories-selection'
                                                                MenuProps={{
                                                                    getContentAnchorEl: null,
                                                                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                                                }}
                                                            >
                                                                <MenuItemData value="all" ><Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.transEarnings("All")}</Box></MenuItemData>
                                                                <Divider />
                                                                <MenuItemData value="refunded" ><Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.transEarnings("Refunded")}</Box></MenuItemData>
                                                                <Divider />
                                                                <MenuItemData value="sold" ><Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.transEarnings("Sold")}</Box></MenuItemData>
                                                            </MuiSelectSelect>
                                                        </CustomSelect >
                                                    </Box>
                                                </Box>
                                                {this.state.loading ?
                                                    <Box data-testid="loader" style={{ ...webStyle.tabsBoxInProcess, textAlign: 'center', textAlignLast: 'center' }}>
                                                        <CircularProgress style={{ color: '#375280' }} />
                                                    </Box>
                                                    : this.renderEarningDetails()
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                            dir={i18n.dir()}
                        >
                            <div style={{
                                width: '435px',
                                borderRadius: '5px',
                            }}>
                                <Box style={webStyle.filterBox}><Box style={webStyle.filterName}>{this.transEarnings("Filters")}</Box><img width="35.36px" height="35.36px" src={button_close} data-testid="handlePopoverClose" onClick={this.handlePopoverClose} /></Box>
                                <Divider style={{ color: "#F1F5F9" }} />
                                <Box style={{ padding: '20px' }}>
                                    {this.state.downloadOptionsActive.map((option, index) => (
                                        <>
                                            <Box key={index} style={{ display: "flex", alignItems: 'center' }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label=""
                                                        value={option.type}
                                                        checked={this.state.accountFilter === option.type}
                                                        onChange={(event) => this.handlefilterChange(event as React.ChangeEvent<HTMLInputElement>)}
                                                        style={{ ...webStyle.radioName, marginRight: '0px' }}
                                                        data-testId={`DownloadOption-${option.type}`}
                                                    />
                                                </Box>
                                                <Box style={{ ...webStyle.acountData, margin: '0 8px' }}>
                                                    {this.transEarnings(option.label)}
                                                </Box>
                                            </Box>
                                        </>
                                    ))}
                                </Box>
                                {this.state.accountFilter === "select_date_range" && <Box style={{ padding: '20px' }}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={localeMap[i18n.language as "en" | "ar"]}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <StartDateCalendar
                                                autoOk
                                                disableToolbar
                                                variant="inline"
                                                format="dd-MM-yyyy"
                                                disablePast
                                                margin="normal"
                                                maxDate={currentDate}
                                                dir={i18n.dir()}
                                                data-testid= "handleStartDate"
                                                style={{ width: '190px' }}
                                                value={this.state.startDate}
                                                onChange={this.handlesatrtDateChange}
                                                inputProps={{
                                                    'data-testid': 'handleDateChangeInput',
                                                }}
                                                InputProps={{
                                                    id: "teststart",
                                                    disableUnderline: true,
                                                    style: webStyle.pickerFields,
                                                    placeholder: this.transEarnings('From')
                                                }}
                                                keyboardIcon={<img src={calendar} style={webStyle.pickerIcon} alt="calendar_startdate" />}
                                            />
                                            <StartDateCalendar
                                                autoOk
                                                disableToolbar
                                                disablePast
                                                dir={i18n.dir()}
                                                variant="inline"
                                                data-testid= "handleEndDate"
                                                format="dd-MM-yyyy"
                                                margin="normal"
                                                style={{ width: '190px' }}
                                                value={this.state.endDate}
                                                maxDate={currentDate}
                                                onChange={this.handleEndDateChange}
                                                inputProps={{
                                                    'data-testid': 'handleEndDateChange',
                                                }}
                                                InputProps={{
                                                    id: "teststart",
                                                    disableUnderline: true,
                                                    style: webStyle.pickerFields,
                                                    placeholder: this.transEarnings('To')
                                                }}
                                                keyboardIcon={<img src={calendar} style={webStyle.pickerIcon} alt="calendar_startdate" />}
                                            />
                                        </Box>
                                    </MuiPickersUtilsProvider>
                                </Box>}
                                {this.state.error && (
                                  <FormHelperTextContainer data-test-id="date-error">
                                    {this.state.error}
                                  </FormHelperTextContainer>
                                )}
                                <Box style={webStyle.buttonBox}>
                                    <ClearButton onClick={this.handlePopoverClose}>{this.transEarnings("Clear All")}</ClearButton>
                                    <Button style={{ ...webStyle.applayButton, textTransform: 'capitalize' }} data-testid="filterApply" onClick={this.filterSellerEarnings}>{this.transEarnings("Apply")}</Button>
                                </Box>
                            </div>
                        </Popover>
                    </Grid>
                    <StyledDrawerOrder
                        aria-labelledby="transition-modal-title"
                        onClose={this.handleClosePdf}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        closeAfterTransition
                        dir={i18n.dir()}
                        open={this.state.pdfOpen}
                        aria-describedby="transition-modal-description"
                        BackdropComponent={Backdrop}
                    >
                        <Fade in={this.state.pdfOpen} style={webStyle.febBox}>
                            <Box style={{ padding: "10px 30px 30px 30px" }}>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleClosePdf} /></Box>
                                <Box style={{ ...webStyle.exportReject, textAlign: 'center' }}>{this.transEarnings("Export Report")}</Box>
                                <Box style={{ ...webStyle.exportData, textAlign: 'center' }}>{this.transEarnings("Export Analytics report as:")}</Box>
                                <Box>
                                    {this.state.downloadOptions.map((option, index) => (
                                        <>
                                            <Box key={index} style={webStyle.pdfDwonBox}>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img width="60px" height="60px" src={option.src} />
                                                    <Box style={{ ...webStyle.pdfData, marginLeft: '20px' }}>
                                                        {this.transEarnings(option.label)}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label=""
                                                        value={option.type}
                                                        checked={this.state.accountPdf === option.type}
                                                        onChange={(event) => this.handlePdfChange(event as React.ChangeEvent<HTMLInputElement>)}
                                                        style={webStyle.radioName}
                                                        data-testId={`DownloadOptionRadio-${option.type}`}
                                                    />
                                                </Box>
                                            </Box>
                                            {this.state.downloadOptions.length - 1 !== index && <Divider style={webStyle.divayder} />}
                                        </>
                                    ))}
                                </Box>
                                <Box style={{ display: "flex", gap: "20px", justifyContent: "space-between", marginTop: '30px' }}>
                                    <StyledButtonModelNewOrder data-testid="handleClosePdf" onClick={this.handleClosePdf}>{this.transEarnings("Close")}</StyledButtonModelNewOrder>
                                    <StyledButton1ModelNewOrder data-testid="ExportPdf" onClick={() => this.sellerEarningsDownload()}>{this.transEarnings("Export")}</StyledButton1ModelNewOrder>
                                </Box>
                            </Box>
                        </Fade>
                    </StyledDrawerOrder>
                </div>
            </>
            // Customizable Area End
        )
    }

}
// Customizable Area Start
const webStyle = {
    buttonBox: { padding: '20px', marginTop: '10px', display: 'flex', justifyContent: 'space-between' },
    applayButton: { backgroundColor: '#CCBEB1', padding: '10px 16px', width: '185px', height: '54px', color: '#FFFFFF', fontFamily: 'Lato', fontSize: '18px', fontWeight: 700 },
    tabeLableRejected: {
        color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', display: 'flex', justifyContent: 'center'
    },
    tabsBoxInProcess: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    filterBox: { display: 'flex', padding: '20px', justifyContent: 'space-between', alignItems: 'center' },
    pickerIcon: { width: '20px', height: '20px' },
    filterName: { fontSize: '20px', fontFamily: 'Lato', fontWeight: 800, color: '#375280' },
    pickerFields: { fontFamily: "Lato", fontWeight: 500, fontSize: "18px", color: "#94A3B8", paddingRight: "5px", backgroundColor: "#F8F8F8", padding: '16px 18px', },
    pdfDwonBox: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    divayder: { color: '#e2e8f0', marginTop: '24px', marginBottom: '24px' },
    selectBox: { minWidth: '58px', backgroundColor: '#ffffff', border: '1px solid #CCBEB1', borderRadius: '2px', padding: '1px' },
    pdfData: { color: "#375280", fontSize: "22px", fontWeight: 700, fontFamily: 'Lato' },
    acountData: { color: "#375280", fontSize: "16px", fontWeight: 400, fontFamily: 'Lato' },
    exportData: { fontSize: '18px', fontFamily: 'Lato', fontWeight: 700, color: '#375280', marginBottom: '20px' },
    exportReject: { fontSize: '30px', fontFamily: 'Lato', fontWeight: 500, color: '#375280', marginBottom: '20px' },
    febBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    radioName: { color: "rgba(55, 82, 128, 1)", fontSize: "16px", fontWeight: 700, fontFamily: "Lato" },
    boxWrap: { display: 'flex', width: '100%' },
    orderReject: { color: "#DC2626", fontSize: "16px", fontFamily: 'Lato', fontWeight: 700 },
    orderAccept: { color: "#059669", fontSize: "16px", fontFamily: 'Lato', fontWeight: 700 },
    orderData: { color: "#94A3B8", fontSize: "16px", fontFamily: 'Lato', fontWeight: 500 },
    orderIde: { color: "#375280", fontSize: "18px", fontFamily: 'Lato', fontWeight: 500, marginBottom: '6px' },
    flexBox: { display: 'flex', justifyContent: 'space-between' },
    earningsName: { color: "#375280", fontSize: "24px", fontFamily: 'Lato', fontWeight: 700, display: 'flex', alignItems: 'center' },
    earningsMothe: { color: "#375280", fontSize: "16px", fontFamily: 'Lato', fontWeight: 400 },
    downloadImg: { display: 'flex', alignItems: 'center' },
    amountDetail: { border: '1px solid #E2E8F0', padding: '20px', borderRadius: '4px', height: "90%" },
    withdrawAmount: { color: "#375280", fontSize: "20px", fontFamily: 'Lato', fontWeight: 700 },
    amount: { color: "#059669", fontSize: "24px", fontFamily: 'Lato', fontWeight: 700, marginBottom: '18px' },
    reminderName: { backgroundColor: '#FFFFFF', height: '45px' },
    newShort: { color: '#375280', fontSize: '16px', fontWeight: 400, fontFamily: "Lato" },
    monthIncom: { fontSize: "20px", color: "#375280", fontFamily: "Lato", fontWeight: 700, marginBottom: '16px' },
    monthData: { fontSize: "16px", color: "#375280", fontFamily: "Lato", fontWeight: 500, marginBottom: '20px' },
}
export const StartDateCalendar = styled(KeyboardDatePicker)({
    width: "112px",
    height: "32px",
    borderRadius: '26px',
    fontWeight: 500,
    fontFamily: "Raleway",
    color: "#334155",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    margin: 0,
});
const theme = createTheme({
    palette: {
        primary: {
            main: "#13689E",
            contrastText: "#fff"
        }
    },
    overrides: {
        MuiSelect: {
            select: {
                paddingRight: "4px !important",
                "&:focus": {
                    backgroundColor: 'none'
                }
            }
        },
        MuiListItem: {
            root: {
                ":hover, &.Mui-selected": {
                    backgroundColor: "transparent !important",
                    fontWeight: 600,
                },
            },
        },
        MuiMenuItem: {
            root: {
                "&:hover, &.Mui-selected ": {
                    backgroundColor: "transparent !important",
                },
            },
        },
        MuiGrid: {
            root: {
                "&.MuiGrid-spacing-xs-2": {
                    "@media (max-width: 960px)": {
                        marginTop: "20px"
                    },
                },
            },
        },
        MuiMenu: {
            paper: {
                borderRadius: "12px",
                background: "#F8FAFC",
                position: "absolute",
                top: "266px",
                left: "732px",
            },
            list: {
                color: "#13689E",
                fontFamily: 'Raleway',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '22px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            },
        },
        MuiPickersDay: {
            daySelected: {
                "&:hover": {
                    backgroundColor: "#D1EDFF !important",
                },
                backgroundColor: "#D1EDFF !important",
                color: "#13689E !important",
                borderRadius: "50%",
                border: "0.8px solid #68B9EC"
            },
            current: { color: "none !important" },
        },
        MuiPickersCalendarHeader: {
            transitionContainer: {
                "& p": {
                    color: "#0F172A",
                    fontSize: "11.13px",
                    fontWeight: 700,
                    textAlign: "center",
                    cursor: "context-menu",
                    fontFamily: "Raleway !important",
                },
            },
            dayLabel: {
                fontSize: "11.13px !important",
                color: "#64748B",
                fontWeight: 500,
                cursor: "context-menu",
                fontFamily: "Raleway !important",
            },
        },
        MuiPopover: {
            paper: {
                border: "0.8px solid #E2E8F0",
                borderRadius: "12.73px",
                top: "95px !important",
            },
        },
        MuiTypography: {
            body2: {
                fontFamily: "Raleway !important",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "transparent !important",
                },
            },
        },
    } as {
        MuiPickersDay?: {
            daySelected?: {
                backgroundColor: string;
                color: string;
                borderRadius: string;
            };
        };
        MuiIconButton?: {
            root?: {
                "&:hover"?: {
                    backgroundColor: string;
                };
            };
        };
    },
});
const styles = {
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        border: '1px solid #CCBEB1',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:disabled ~ &': {
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#cbbeb2',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
    },
};

const MuiSelectSelect = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
})
const MenuItemData = styled(MenuItem)({
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
        backgroundColor: 'transparent !important',
    },
    '& .MuiList-root': {
        padding: '20px'
    }
})
const StyledButtonModelNewOrder = styled(Button)(() => ({
    textTransform: "none",
    fontWeight: 500,
    borderRadius: "2px",
    border: "1px solid #CCBEB1",
    width: "252px !important",
    color: "#375280",
    fontSize: "18px", height: '54px',
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1ModelNewOrder = styled(Button)(() => ({
    fontWeight: 500,
    fontSize: "18px", height: '54px',
    backgroundColor: '#CCBEB1',
    width: "252px",
    textTransform: "none",
    color: "#fff",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomSelect = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "#00800000"
            },
        },
    },
}))(FormControl);
const StyledDrawerOrder = styled(Modal)((theme) => ({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: "none",
    justifyContent: 'center',
}));
export const StyledRadioWithStyles = withStyles(styles)((props: WithStyles<typeof styles>) => (
    <Radio
        className={props.classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(props.classes.icon, props.classes.checkedIcon)} />}
        icon={<span className={props.classes.icon} />}
        {...props}
    />
));

export const ClearButton = styled(Button)((theme) => ({
    border: '1px solid #CCBEB1', padding: '10px 16px', width: '185px', height: '54px', color: '#375280', fontFamily: 'Lato', fontSize: '18px', fontWeight: 500, textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: "#ffff"
    },
}));
// Customizable Area End
