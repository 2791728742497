
const ArrowGender = (arrowHandle:boolean) => {

  return arrowHandle ? 'rotate(180deg)' : 'rotate(0deg)'
};

export default ArrowGender;

export const FontListed = (data:boolean) => {

    return data?700:400
};

export const LinkStatusFirst = (linkStatus:number,lnk:number) => {

    return linkStatus>=lnk?"#FFFFFF":"#375280"
};

export const CircleDiv = (linkStatus:number,link:number,circleDivActive: { border: string; height: number; width: number; borderRadius: string; },circleDiv: { border: string; height: number; width: number; borderRadius: string; })=> {

    return linkStatus==link?circleDivActive:circleDiv
};

export const FontWight = (linkStatus:number,weight:number) => {

    return linkStatus>=weight?700:400
};
  
export const HrTag=(linkStatus:number,hrNum:number,hrTagFill: { width: number; height: number; margin: number; border: string; },hrTag: { width: number; height: number; margin: number; border: string; })=>{
    return linkStatus>=hrNum?hrTagFill:hrTag
};

export const CircleDIvFIlledFunc=(linkStatus:number,item:number,circleNum:number,circleDivFilled: { border: string; height: number; width: number; borderRadius: string; background: string; },circleDiv: { (link: number): { border: string; height: number; width: number; borderRadius: string; };})=>{
    return linkStatus>=item?circleDivFilled:circleDiv(circleNum)
}
  
