export const enPromoCode = {
    "Track Offer Update Successfully": "Track Offer Update Successfully",
    "Delete Update Successfully": "Delete Update Successfully",
    "Jan": "Jan", 
    "Feb": "Feb", 
    "Mar": "Mar", 
    "Apr": "Apr", 
    "May": "May", 
    "Jun": "Jun", 
    "Jul": "Jul", 
    "Aug": "Aug", 
    "Sep": "Sep", 
    "Oct": "Oct", 
    "Nov": "Nov", 
    "Dec": "Dec",
    "Offers and Discounts": "Offers and Discounts",
    "Offers & Discounts": "Offers & Discounts",
    "Create Offer": "Create Offer",
    "Track Offer": "Track Offer",
    "Create your offer for stores": "Create your offer for stores",
    "Create best offer to get more orders": "Create best offer to get more orders",
    "Create your offer for products": "Create your offer for products",
    "Edit Offer": "Edit Offer",
    "Delete Offer": "Delete Offer",
    "Minimum Order Value": "Minimum Order Value",
    "Campaign Start Date": "Campaign Start Date",
    "Yes": "Yes",
    "No": "No",
    "This action cannot be undone.": "This action cannot be undone.",
    "Are you sure you want to delete this item?": "Are you sure you want to delete this item?",
    "Explore More": "Explore More",
    "Create new offers & Discount coupons": "Create new offers & Discount coupons",
    "Advertisements": "Advertisements",
    "More product reach, get more orders": "More product reach, get more orders",
    "Create Ads": "Create Ads",
    "Please enter discount percentage":"Please enter discount percentage",
    "Please enter max cap":"Please enter max cap",
    "Please enter min order value":"Please enter min order value",
    "Please select start date":"Please select start date",
    "Please select end date":"Please select end date",
    "Select Products":"Select Products",
    "Product not selected":"Product not selected",
    "Create new offer":"Create new offer",
    "Failed to create the promotion":"Failed to create the promotion",
    "Please try again.":"Please try again.",
    "Discount Percentage":"Discount Percentage",
    "Enter discount percentage":"Enter discount percentage",
    "Max Cap":"Max Cap",
    "Enter max cap":"Enter max cap",
    "Discount applicable for":"Discount applicable for",
    "All Users":"All Users",
    "New Users":"New Users",
    "Create promo code":"Create promo code",
    "Enter promo code":"Enter promo code",
    "Minimum order value":"Minimum order value",
    "Enter minimum order value":"Enter minimum order value",
    "Campaign start date":"Campaign start date",
    "Select start date":"Select start date",
    "Campaign end date":"Campaign end date",
    "Select end date":"Select end date",
    "Your campaign will start on":"Your campaign will start on",
    "Your campaign will end on":"Your campaign will end on",
    "You can stop this campaign at any time.":"You can stop this campaign at any time.",
    "Offer details":"Offer details",
    "Create":"Create",
    "Offer successfully created!":"Offer successfully created!",
    "Valid on minimum order value of $50":"Valid on minimum order value of $50",
    "on all stores":"on all stores",
    "Selected stores":"Selected stores",
    "Selected products":"Selected products",
    "Valid for":"Valid for",
    "By clicking create offer, you agree to our":"By clicking create offer, you agree to our",
    "Terms & Conditions":"Terms & Conditions",
    "Offer applicable for": "Offer applicable for",
    "Product Name": "Product Name",
    "SKU": "SKU",
    "Select the products for creating an offer": "Select the products for creating an offer",
    "Confirm": "Confirm",
    "results": "results",
    "of": "of",
    "Change Date": "Change Date",
    "OK": "OK",
    "Cancel": "Cancel"
}

export const arPromoCode = {
    "Track Offer Update Successfully": "تتبع تحديث العرض بنجاح",
    "Delete Update Successfully": "تم حذف التحديث بنجاح",
    "Jan": "يناير", 
    "Feb": "فبراير", 
    "Mar": "يمشي", 
    "Apr": "أبريل", 
    "May": "يمكن", 
    "Jun": "يونيو", 
    "Jul": "يوليو", 
    "Aug": "أغسطس", 
    "Sep": "سبتمبر", 
    "Oct": "أكتوبر", 
    "Nov": "نوفمبر", 
    "Dec": "ديسمبر",
    "Offers and Discounts": "العروض والخصومات",
    "Offers & Discounts": "العروض والخصومات",
    "Create Offer": "إنشاء عرض",
    "Track Offer": "تتبع العرض",
    "Create your offer for stores": "إنشاء عرض للمتاجر",
    "Create best offer to get more orders": "أنشئ أفضل العروض وأحصل على المزید من الطلبات",
    "Create your offer for products": "إنشاء عرض للمنتجات",
    "Edit Offer": "تعديل العرض",
    "Delete Offer": "حذف العرض",
    "Minimum Order Value": "الحد الأدنى لقيمة الطلب",
    "Campaign Start Date": "تاريخ بداية الحملة",
    "No": "لا",
    "Yes": "نعم",
    "This action cannot be undone.": "لا يمكن التراجع عن هذا الإجراء.",
    "Are you sure you want to delete this item?": "هل أنت متأكد أنك تريد حذف هذا العنصر؟",
    "Explore More": "استكشاف المزيد",
    "Create new offers & Discount coupons": "إنشاء عروض وكوبونات تخفيض جديدة",
    "Advertisements": "الإعلانات",
    "More product reach, get more orders": "تحصل على المزيد من الطلبات عبر انتشار المنتجات الأوسع",
    "Create Ads": "إنشاء إعلانات",
    "Please enter discount percentage":"الرجاء إدخال نسبة الخصم",
    "Please enter max cap":"الرجاء إدخال الحد الأقصى",
    "Please enter min order value":"الرجاء إدخال الحد الأدنى لقيمة الطلب",
    "Please select start date":"الرجاء تحديد تاريخ البدء",
    "Please select end date":"الرجاء تحديد تاريخ الانتهاء",
    "Select Products":"تحديد المتاجر",
    "Product not selected":"لم يتم تحديد المنتج",
    "Create new offer":"إنشاء عرض جديد",
    "Failed to create the promotion":"تعذر إنشاء العرض الترويجي",
    "Please try again.":"تُرجى المحاولة مرة أخرى.",
    "Discount Percentage":"نسبة التخفيض",
    "Enter discount percentage":"أدخل نسبة الخصم",
    "Max Cap":"الحد الأقصى",
    "Enter max cap":"أدخل الحد الأقصى",
    "Discount applicable for":"ينطبق التخفيض على",
    "All Users":"جميع المستخدمين",
    "New Users":"المستخدمين الجدد",
    "Create promo code":"إنشاء رمز ترويجي",
    "Enter promo code":"أدخل الرمز الترويجي",
    "Minimum order value":"القيمة الأدنى للطلبية",
    "Enter minimum order value":"أدخل الحد الأدنى لقيمة الطلب",
    "Campaign start date":"تاريخ بداية الحملة",
    "Select start date":"حدد تاريخ البدء",
    "Campaign end date":"تاريخ نهاية الحملة",
    "Select end date":"حدد تاريخ الانتهاء",
    "Your campaign will start on":"ستبدأ حملتك في ",
    "Your campaign will end on":"ستنتهي حملتك في ",
    "You can stop this campaign at any time.":"يمكنك إيقاف هذه الحملة في أي وقت.",
    "Offer details":"تفاصيل العرض",
    "Create":"إنشاء",
    "Offer successfully created!":"تم إنشاء العرض بنجاح!",
    "Valid on minimum order value of $50": "يسري العرض على الطلبات ذات القيمة التي لا تقل عن 50 دولار",
    "on all stores":"في جميع المتاجر",
    "Selected stores":"متاجر مختارة",
    "Selected products":"تحديد المتاجر",
    "Valid for":"صالحة ل",
    "By clicking create offer, you agree to our":"بالنقر فوق إنشاء عرض، فإنك توافق على لدينا",
    "Terms & Conditions":"الشروط والأحكام",
    "Offer applicable for": "العرض ينطبق على",
    "Product Name": "اسم المنتج",
    "SKU": "رمز تعريف البضاعة ",
    "Select the products for creating an offer": "حدد المنتجات التي تود حذفها دفعة واحدة من الجدول أدناه",
    "Confirm": "التأكيد",
    "results": "نتيجة",
    "of": "من أصل",
    "Change Date": "تغيير التاريخ",
    "OK": "موافق",
    "Cancel": "الإلغاء"
}