import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  
  error: string;
  faqListData:{
    id: string;
    type: string;
    attributes: {
      question : string;
      answer : string;
      status: boolean;
    }
  }[];
  expanded:string | number | boolean;
  token: string;
  userType: string;
  stylishBuyer: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class  FaqController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
   fetchFaqDataApi: string = "";
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      error: "",
      expanded: false,
      faqListData: [],
      token: '', 
      userType: '',
      stylishBuyer: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      if (apiRequestCallId === this.fetchFaqDataApi) {
        if (responseJson?.errors) {
          this.setState({
            error: "An error occurred, please try again"
          });
        }
        this.setState({
          faqListData: responseJson?.data,
        })
      }
    }else{
      this.setState({
        error: "An error occurred, please try again"
      });
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    const userToken = await getStorageData('auth-token');
    const userRole = await getStorageData('userRole', true);
    const stylishBuyer = await getStorageData('exploreBuyer', true);
    if(stylishBuyer) this.setState({stylishBuyer})
    this.getFaqList();
    this.setState({
      token: userToken,
      userType: userRole.userType
    });
  };

  handleCondition () {
    if((this.state.userType === "seller" || this.state.userType === "stylist") && !this.state.stylishBuyer ){
      return true;
    }
    return false
  }

  handleChange = (panel: number | string) => (event: any, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel.toString() : false });
  };

  handleLandingPageFromFaq = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'LandingPage');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  
  getFaqList = async () => {
    const userRole = await getStorageData("userRole")
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchFaqDataApi = requestMessage.messageId;    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqListalluserApi + (JSON.parse(userRole)?.userType || 'buyer')
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
 
  transFaqs = (transKey: string) => {
    return i18n.t(transKey, {ns: "contactUs"})
  }
  // Customizable Area End
}
