import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import {
  IInventoryResponse,
  InventoryState,
  IInventoryPaginationResponse
} from "../UpdateInventory/UpdateInventoryController";
import { ValueType, ActionMeta } from "react-select";
import { apiCall } from "../../../../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import { ErrorMessage } from "../../../CreateSellerStoreController";
import { ErrorMessageResponse } from "../../../SellerStoreController";
import { MetaResponse } from "../../Catalogues/SellerCataloguesController";
import { IUpdateAssignStoreResponse } from "../SellerInventoryManagementController";
import { logoutSellerNavigation } from "../../../../../../components/src/Seller/logOut";
import i18n from "../../../../../../components/src/i18next/i18n";
interface ITableBody extends ExtractedInventoryItem {
  pair_it_with: string[];
}

export interface OptionType {
  value: string;
  label: string;
}

interface ExtractedInventoryItem {
  id: number;
  product_name: string;
  front_image: string;
  sku: string;
  pair_it_with: string[];
}

interface ISkuSelect {
  isOpen: boolean;
  value: OptionType | null;
}
// Customizable Area End

export const configJSON = require("../../../config.js");
export interface Props {
  navigation: any;
  // Customizable Area Start
  searchValue: string;
  handleRedirect: (redirect: string) => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tableHeadName: string[];
  tableBodyData: ITableBody[];
  isDrawerOpen: boolean;
  pairSelect: {
    sku1: ISkuSelect;
    sku2: ISkuSelect;
    sku3: ISkuSelect;
    sku4: ISkuSelect;
    sku5: ISkuSelect;
  };
  page: number;
  error: boolean;
  errorMessage: string;
  isAlert: boolean;
  pairProductInventoryMeta: MetaResponse;
  variantId: number;
  severity: "error" | "success";
  catalogueResp: OptionType[];
  catalogueFiltered: OptionType[];
  selectedFiltered: OptionType[];
  selectedVariantIds: number[];
  product:{
    name: string;
    image: string;
  };
  roleData: string;
  message: string;
  isLoading: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InventoryPairTheProductsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListInventoryAPICallId: string = "";
  getCataloguesAPICallId: string = "";
  getVariantsPairProductAPICallId: string = "";
  putPairWithAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tableHeadName: inventoryPairProductsTableHead,
      tableBodyData: [],
      pairSelect: {
        sku1: defaultSku,
        sku2: defaultSku,
        sku3: defaultSku,
        sku4: defaultSku,
        sku5: defaultSku,
      },
      page: 1,
      isDrawerOpen: false,
      catalogueResp: [],
      catalogueFiltered: [],
      selectedFiltered: [],
      selectedVariantIds: [],
      error: false,
      errorMessage: "",
      isAlert: false,
      product:{
        name: "",
        image: "",
      },
      roleData: "",
      severity: "success",
      pairProductInventoryMeta: {} as MetaResponse,
      message: "",
      isLoading: true,
      variantId: 0,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseData && !responseData.errors && !responseData.error) {
        this.apiPairProductSuccess(apiRequestCallId, responseData);
      } else if (responseData && (responseData.error || responseData.errors)) {
        this.apiPairProductFailer(responseData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if(userData.userType==="buyer") history.back();
    else{
      this.setState((prevState)=>({...prevState, roleData: userData.userType}), ()=>{
        this.fetchDataBySearchValue("", 1);
        this.fetchCataloguesData();
      });
    }
    
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.fetchDataBySearchValue(this.props.searchValue, 1);
    }
  }

  apiPairProductSuccess = async (
    apiRequestCallId: string,
    responseJson: IInventoryPaginationResponse &
      IInventoryResponse &
      IUpdateAssignStoreResponse
  ) => {
    if (apiRequestCallId === this.getListInventoryAPICallId) {
      if (responseJson) this.handlePairInventoryResp(responseJson);
    } else if (apiRequestCallId === this.getCataloguesAPICallId) {
      if (responseJson) this.handleCataloguesResp(responseJson);
    } else if (apiRequestCallId === this.getVariantsPairProductAPICallId) {
      if (responseJson) this.handleVariantResp(responseJson);
    } else if (apiRequestCallId === this.putPairWithAPICallId) {
      if (responseJson){
        this.setState((prevState) => ({
          ...prevState,
          severity: "success",
          isAlert: true,
          message: responseJson.meta.message,
        }));
        await this.fetchDataBySearchValue(this.props.searchValue, this.state.page);
      }
    }
  };

  handlePairProductPageChange = async (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      page: newPage,
    }));
    await this.fetchDataBySearchValue(this.props.searchValue, newPage);
  };

  apiPairProductFailer = (
    responseJson: ErrorMessage & ErrorMessageResponse
  ) => {
    if(responseJson.error === "You are not an authorized person, Only seller and Stylist can filter variants." || responseJson.error === "Only seller  and Stylist can search catalogues." || responseJson.errors === "Only seller  and Stylist can search catalogues.")
    {
      this.handleRedirectToHome(this.tranPairTheProducts("You are not an authorized person, Please Login with valid User Credential"));
    } else if (responseJson.error) {
      this.setState((prevState) => ({
        ...prevState,
        tableBodyData: [],
        pairProductInventoryMeta: {} as MetaResponse,
        isLoading: false,
      }));
    } else if (responseJson.errors[0].token) {
      this.handleRedirectToHome(responseJson.errors[0].token);
    }
  };

  handleRedirectToHome = (message: string) =>{
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      message: message,
      isLoading: false,
      severity: "error",
    }), () => {
      setTimeout(() => {
        this.props.handleRedirect("Home");
        logoutSellerNavigation();
      }, 2000);
    });
  }

  onAlertPPSnackClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: !prevState.isAlert,
    }));
  };

  handlePairInventoryResp = (response: IInventoryPaginationResponse) => {
    this.setState((prevState) => ({
      ...prevState,
      tableBodyData: this.extractPairProductData(response.data),
      pairProductInventoryMeta: response.meta,
      isLoading: false,
    }));
  };

  handleCataloguesResp = (response: IInventoryResponse) => {
    this.setState((prevState) => ({
      ...prevState,
      catalogueResp: this.extractCataloguesData(response.data),
    }));
  };

  handleVariantResp = (response: IInventoryResponse) => {
    const editPair = this.extractCataloguesData(response.data);
    const updatedPairSelect = this.state.pairSelect;

    editPair.forEach((option, index) => {
      const key = `sku${index + 1}` as keyof S["pairSelect"];
      if (index < 5) {
        updatedPairSelect[key] = {
          ...updatedPairSelect[key],
          value: option,
        };
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      selectedFiltered: editPair,
      selectedVariantIds: editPair
        .map((option) => Number(option.value))
        .filter((value) => !isNaN(value)),
    }));
  };

  fetchDataBySearchValue = async (search: string, page: number) => {
    this.setState((prevState) => ({ ...prevState, page, isLoading: true }));
    let endPoint = `${configJSON.getFilterVariantInventoryEndPoint}search=${search}&per_page=10&page=${page}`;
    
    if(this.state.roleData==="seller"){
      const storeId = +(await this.getStoreId());
      endPoint = `${endPoint}&store_id=${storeId}`
    }
    
    this.getListInventoryAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: endPoint,
      token: await this.getTokenFromStorage(),
    });
  };

  fetchCataloguesData = async () => {
    let endPoint = `${configJSON.getCataloguesVariantsOfStoreEndPoint}`;
    
    if(this.state.roleData==="seller"){
      const storeId = +(await this.getStoreId());
      endPoint = `${endPoint}?store_id=${storeId}`
    }
    this.getCataloguesAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: endPoint,
      token: await this.getTokenFromStorage(),
    });
  };

  fetchVariantData = async (id: number) => {
    this.getVariantsPairProductAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getVariantsOfProductEndPoint}${id}/paired_variants`,
      token: await this.getTokenFromStorage(),
    });
  };

  putVariantData = async (variants_id: number[]) => {
    this.putPairWithAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.putPairDataEndPoint}${this.state.variantId}/pair_with`,
      body: {pair_variant_ids: variants_id},
      isJsonStringify: true,
      token: await this.getTokenFromStorage(),
    });
  };

  getStoreId = async () => {
    return await getStorageData("store-id");
  };

  getTokenFromStorage = async () => {
    return await getStorageData("auth-token");
  };

  extractPairProductData = (
    items: InventoryState[]
  ): ExtractedInventoryItem[] => {
    return items.map((item) => {
      const { id, product_name, sku, front_image, pair_it_with } =
        item.attributes;
      return {
        id,
        product_name,
        pair_it_with,
        front_image,
        sku,
      };
    });
  };

  extractCataloguesData = (items: InventoryState[]): OptionType[] => {
    return items.map((item) => {
      const { id, sku } = item.attributes;
      return {
        value: id.toString(),
        label: sku,
      };
    });
  };

  toggleDrawer = () => {
    this.setState((prevState) => ({
      ...prevState,
      isDrawerOpen: !prevState.isDrawerOpen,
      errorMessage: "",
      pairSelect: {
        ...prevState.pairSelect,
        sku1: defaultSku,
        sku2: defaultSku,
        sku3: defaultSku,
        sku4: defaultSku,
        sku5: defaultSku,
      },
      error: false,
    }));
  };

  openDrawer = async (type: string, id: number, product:{
    name: string,
    image: string,
  }) => {
    if (type === "edit") {
      await this.fetchVariantData(id);
    }
    this.setState((prevState) => ({
      ...prevState,
      variantId: id,
      catalogueFiltered: prevState.catalogueResp.filter(
        (item) => item.value !== id.toString()
      ),
      isDrawerOpen: !prevState.isDrawerOpen,
      product
    }));
  };

  toggle1Open = () => {
    this.setState((prevState) => ({
      ...prevState,
      pairSelect: {
        ...prevState.pairSelect,
        sku1: {
          ...prevState.pairSelect.sku1,
          isOpen: !prevState.pairSelect?.sku1?.isOpen,
        },
      },
    }));
  };

  onSelect1Change = (
    value: ValueType<OptionType, false>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    this.toggle1Open();
    if (value && "value" in value) {
      this.setState((prevState) => ({
        ...prevState,
        error: false,
        pairSelect: {
          ...prevState.pairSelect,
          sku1: { ...prevState.pairSelect.sku1, value: value },
        },
        errorMessage: "",
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        pairSelect: {
          ...prevState.pairSelect,
          sku1: { ...prevState.pairSelect.sku1, value: null },
        },
      }));
    }
  };

  toggle2Open = () => {
    this.setState((prevState) => ({
      ...prevState,
      pairSelect: {
        ...prevState.pairSelect,
        sku2: {
          ...prevState.pairSelect.sku2,
          isOpen: !prevState.pairSelect?.sku2?.isOpen,
        },
      },
    }));
  };

  getFilteredOptions = (skuKey: keyof S["pairSelect"]) => {
    const selectedValues = Object.values(this.state.pairSelect)
      .map((sku) => sku.value?.value)
      .filter((id): id is string => id !== null && id !== undefined);

    switch (skuKey) {
      case "sku1":
        return this.filterOptionsForSKU(skuKey, selectedValues);
      case "sku2":
        return this.filterOptionsForSKU(skuKey, selectedValues);
      case "sku3":
        return this.filterOptionsForSKU(skuKey, selectedValues);
      case "sku4":
        return this.filterOptionsForSKU(skuKey, selectedValues);
      case "sku5":
        return this.filterOptionsForSKU(skuKey, selectedValues);
      default:
        return this.state?.catalogueFiltered;
    }
  };

  filterOptionsForSKU = (
    skuKey: keyof S["pairSelect"],
    selectedValues: string[]
  ) => {
    return this.state?.catalogueFiltered?.filter(
      (option) =>
        option.value === this.state.pairSelect[skuKey].value?.value ||
        !selectedValues.includes(option.value)
    );
  };

  onSelect2Change = (
    value: ValueType<OptionType, false>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    this.toggle2Open();
    if (value && "value" in value) {
      this.setState((prevState) => ({
        ...prevState,
        error: false,
        pairSelect: {
          ...prevState.pairSelect,
          sku2: { ...prevState.pairSelect.sku2, value: value },
        },
        errorMessage: "",
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        pairSelect: {
          ...prevState.pairSelect,
          sku2: { ...prevState.pairSelect.sku2, value: null },
        },
      }));
    }
  };

  toggle3Open = () => {
    this.setState((prevState) => ({
      ...prevState,
      pairSelect: {
        ...prevState.pairSelect,
        error: false,
        sku3: {
          ...prevState.pairSelect.sku3,
          isOpen: !prevState.pairSelect?.sku3?.isOpen,
        },
        errorMessage: "",
      },
    }));
  };

  onSelect3Change = (
    value: ValueType<OptionType, false>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    this.toggle3Open();
    if (value && "value" in value) {
      this.setState((prevState) => ({
        ...prevState,
        error: false,
        pairSelect: {
          ...prevState.pairSelect,
          sku3: { ...prevState.pairSelect.sku3, value: value },
        },
        errorMessage: "",
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        pairSelect: {
          ...prevState.pairSelect,
          sku3: { ...prevState.pairSelect.sku3, value: null },
        },
      }));
    }
  };

  toggle4Open = () => {
    this.setState((prevState) => ({
      ...prevState,
      pairSelect: {
        ...prevState.pairSelect,
        sku4: {
          ...prevState.pairSelect.sku4,
          isOpen: !prevState.pairSelect?.sku4?.isOpen,
        },
      },
    }));
  };

  onSelect4Change = (
    value: ValueType<OptionType, false>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    this.toggle4Open();
    if (value && "value" in value) {
      this.setState((prevState) => ({
        ...prevState,
        error: false,
        pairSelect: {
          ...prevState.pairSelect,
          sku4: { ...prevState.pairSelect.sku4, value: value },
        },
        errorMessage: "",
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        pairSelect: {
          ...prevState.pairSelect,
          sku4: { ...prevState.pairSelect.sku4, value: null },
        },
      }));
    }
  };

  toggle5Open = () => {
    this.setState((prevState) => ({
      ...prevState,
      pairSelect: {
        ...prevState.pairSelect,
        sku5: {
          ...prevState.pairSelect.sku5,
          isOpen: !prevState.pairSelect?.sku5?.isOpen,
        },
      },
    }));
  };

  onSelect5Change = (
    value: ValueType<OptionType, false>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    this.toggle5Open();
    if (value && "value" in value) {
      this.setState((prevState) => ({
        ...prevState,
        error: false,
        pairSelect: {
          ...prevState.pairSelect,
          sku5: { ...prevState.pairSelect.sku5, value: value },
        },
        errorMessage: "",
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        pairSelect: {
          ...prevState.pairSelect,
          sku5: { ...prevState.pairSelect.sku5, value: null },
        },
      }));
    }
  };

  handlePairProductSave = async () => {
    const hasError = Object.values(this.state.pairSelect).every(
      (sku) => sku.value === null
    );
    if (hasError) {
      this.setState((prevState) => ({
        ...prevState,
        error: true,
        errorMessage: this.tranPairTheProducts("AnyOne field must be selected"),
      }));
    } else {
      const variant_ids = Object.values(this.state.pairSelect)
      .filter(sku => sku.value !== null)
      .map(sku => Number((sku.value as OptionType).value));
      await this.putVariantData(variant_ids);
      this.toggleDrawer();
    }
  };

  tranPairTheProducts = (transKey: string) => {
    return i18n.t(transKey, {ns: "inventoryManagement"});
  }
  // Customizable Area End
}
// Customizable Area Start
const inventoryPairProductsTableHead = ["Product Name", "SKU", "Pair Products"];

const defaultSku = { isOpen: false, value: null };
// Customizable Area End
