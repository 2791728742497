import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import storage from '../../../framework/src/StorageProvider';
import { ApiKey } from "../../../components/src/APICall";

import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
}
export interface AddressDataType {
  latitude: number;
  longitude: number;
  address: string;
  address_type: string;
}
export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}
export interface AddressType {
  id: string;
  type: string;
  attributes: AddressDataType;
}

export interface AddressValueType {
  value: string;
}
interface AddressAttributes {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zipcode: string | null;
  area: string;
  block: string;
  city: string | null;
  house_or_building_number: string;
  floor: string | null;
  address_name: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}

interface Address {
  id: string;
  type: string;
  attributes: AddressAttributes;
}

interface Meta {
  message: string;
}

interface ResponseJson {
  data: Address[];
  meta: Meta;
  errors?: {
    contact_number?: string;
    address?: string;
  }[];
}

export const AdressTypeData = [
  {
    value: "Home", label: 'Home'
  },
  {
    value: "Work", label: 'Work'
  },
  {
    value: "Other", label: 'Other'
  },
];
type AlertType = 'success' | 'error' | 'warning' | 'info';

// Customizable Area End

interface S {
  // Customizable Area Start
  countrySuccess:Array<CountrySuccess>;

  txtInputAddressValue: string;
  txtInputLatValue: string;
  txtInputLngValue: string;
  token: string;
  addressTypeValue: string;
  addressList: Array<AddressType> | null;
  showDropdown2:boolean;
  isChecked: boolean;
  selectedCountry:CountrySuccess | null;
  isCountryData:string;
  formData: {
    name: string,
    area: string,
    block: string,
    street: string,
    house_or_building_number: string,
    zipcode: string,
    city: string,
    country_code: string,
    phone_number: string,
    latitude: number | null,
    longitude:number | null,
    address_name: string,
    is_default: any 
  }
  AddressResponce:any,
  isAlert: boolean;
  alertMsg: string;
  alertType: AlertType;
  errors:any;
  fullName:string;
  mapVisible: boolean;
  selectedLocation: { lat: number; lng: number } | null;
  currentLocation: { lat: number; lng: number } | null;
  mapsLoaded: boolean;
  searchQuery: string; 
  exploreBuyer: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddressManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiAddAddressCallId: string = "";
  apiGetAllAddressCallId: string = "";
  countryCodeAPICallId:string='';
  AddressApiCallId:string='';
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputAddressValue: "",
      errors: {},
      isAlert: false,
      alertMsg: '',
      alertType: 'success' as AlertType,
      AddressResponce:"",
      txtInputLatValue: "",
      txtInputLngValue: "",
      addressTypeValue: AdressTypeData[0].value,
      addressList: null,
      token: "",
      showDropdown2:false,
      isChecked: false,
      selectedCountry:null,
      countrySuccess:[{
        numeric_code:"",
        country_full_name:"",
        country_code:"",
        country_flag:""
    }],
    isCountryData:"+965",
    exploreBuyer: false,
    fullName:'',
    formData: {
      name: "",
      area: "",
      block: "",
      street: "",
      house_or_building_number: "",
      zipcode: "",
      city: "",
      country_code: "+965",
      phone_number: "",
      latitude: null,
      longitude: null,
      address_name: "",
      is_default: false 
    },
    searchQuery: "",
    mapVisible: false,
      selectedLocation: null,
      currentLocation: null,
      mapsLoaded: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getCountryCodeApi();
    this.getToken();
    const exploreBuyer= await getStorageData('exploreBuyer', true);
    if(exploreBuyer) this.setState({exploreBuyer})
    if (!document.getElementById("google-maps-script")) {
      const script = document.createElement("script");
      script.id = "google-maps-script";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey()}&libraries=places`;
      script.async = true;
      script.onload = this.handleScriptLoad;
      document.body.appendChild(script);
    } else {
      this.handleScriptLoad();
    }
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const name = await getStorageData('user_data', true);
    this.setState({
      fullName: name.full_name
    });
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
          this.apiSucessCall(apiRequestCallId, responseJson);
     } 
     this.manageUpdateDefaultResponce(apiRequestCallId,responseJson)

     
    }
    // Customizable Area End
  }

   // Customizable Area Start


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start

  apiSucessCall = async (apiRequestCallId: string, responseJson: CountrySuccess[]) => {
    if (apiRequestCallId === this.countryCodeAPICallId) {
        this.setState({
            countrySuccess: responseJson,
        });
    }
   
};

handleSuccessAddressPhoneCodeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  const selectedCode = event.target.value as string;
  const selectedCountry = this.state.countrySuccess.find(
    (country) => country.numeric_code === selectedCode
  );

  this.setState({ selectedCountry: selectedCountry || null,  isCountryData:selectedCode});
};

 

handleScriptLoad = () => {
  this.setState({ mapsLoaded: true });

  const input = document.getElementById("autocomplete") as HTMLInputElement;

  if (input) {
    const autocomplete = new window.google.maps.places.Autocomplete(input);
    autocomplete.setFields(["geometry", "formatted_address"]); // Add formatted_address

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (place.geometry && place.geometry.location) {
        const { lat, lng } = place.geometry.location;

        // Use formatted_address to fill the street input
        const fullAddress = place.formatted_address || ""; // Get the formatted address

        this.setState((prevState) => ({
          selectedLocation: { lat: lat(), lng: lng() },
          currentLocation: { lat: lat(), lng: lng() },
          formData: {
            ...prevState.formData,
            latitude: lat(),
            longitude: lng(),
            street: fullAddress, // Set the full address in formData
          },
          mapVisible: true, // Show the map after selecting a location
        }));
      } else {
        console.error("No geometry found for this place.");
      }
    });
  }
};
getCountryCodeApi = async () => {
  this.countryCodeAPICallId = await this.apiCallForgotPass({
      contentType: configJSON.countryApiContentType,
      method: configJSON.httpMethodType,
      endPoint: configJSON.countryCodeEndPoint
  });
};
apiCallForgotPass = async (data: APIPayloadType) => {
  const { contentType, method, endPoint, body } = data;
  const header = {
      "Content-Type": contentType,
  };
  const forgotRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  forgotRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );
  forgotRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

  body &&
  forgotRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
  );
  
  forgotRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  runEngine.sendMessage(forgotRequestMessage.id, forgotRequestMessage);
  return forgotRequestMessage.messageId;
};
getNavigationAddAddress = () => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    'AddAddress'
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
   
  });
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}



AddressAdded=async ()=>{
  const token = await getStorageData("auth-token");
  const header = {
      token: token,
  };
    const formData = new FormData();
      formData.append("name", this.state.formData.name);
      formData.append("area", this.state.formData.area);
      formData.append("block", this.state.formData.block);
      formData.append("street",this.state.formData.street);
      formData.append("house_or_building_number", this.state.formData.house_or_building_number);
      formData.append("zipcode", this.state.formData.zipcode);
      formData.append("city", this.state.formData.city);
      formData.append("country_code", this.state.formData.country_code);
      formData.append("phone_number", this.state.formData.phone_number);
      formData.append("latitude", this.state.formData.latitude ? String(this.state.formData.latitude) : "");
      formData.append("longitude", this.state.formData.longitude ? String(this.state.formData.longitude) : "");
      formData.append("address_name", this.state.formData.address_name);
      formData.append("is_default", this.state.formData.is_default);
     
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.AddressApiCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_address/addresses`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `POST`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmit = async () => {

    const isValid = this.validateForm();
    if (isValid) {
      await this.AddressAdded();
    }
  };

  validateForm = () => {
    const { formData } = this.state;
    const errors: { [key: string]: string } = {};

    // List of required fields
    const requiredFields = [
      { name: "name", message: this.tranSuccessAddress("*Please enter a valid full name") },
      { name: "area", message: this.tranSuccessAddress("*Please enter a valid Area") },
      { name: "block", message: this.tranSuccessAddress("*Please enter a valid Block") },
      { name: "street", message: this.tranSuccessAddress("*Please enter a valid Street") },
      { name: "house_or_building_number", message: this.tranSuccessAddress("*Please enter a valid House number") },
      { name: "zipcode", message: this.tranSuccessAddress("*Please enter a valid Zipcode") },
      { name: "city", message: this.tranSuccessAddress("*Please enter a valid City") },
      { name: "country_code", message: this.tranSuccessAddress("*Please enter a valid Country code") },
      { name: "phone_number", message: this.tranSuccessAddress("*Please enter a valid Phone number") },
      { name: "latitude", message: this.tranSuccessAddress("*Please enter a valid Latitude") },
      { name: "longitude", message: this.tranSuccessAddress("*Please enter a valid Longitude") },
      { name: "address_name", message: this.tranSuccessAddress("*Please enter a valid Address name") }
    ];

    requiredFields.forEach(field => {
      const value = formData[field.name as keyof typeof formData];
      if (typeof value === "string" && value.trim() === "") {
        errors[field.name] = field.message;
      }
    });

    this.setState({ errors });
    const zipcode = formData.zipcode;
    if (zipcode && !/^\d+$/.test(zipcode)) {
      errors.zipcode = this.tranSuccessAddress("*Please enter a valid numeric Zipcode");
    }
    const city = formData.city;
    if (city && /\d/.test(city)) { 
      errors.city = this.tranSuccessAddress("*Please enter valid city name");
    }
  
    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };
  
 
  manageUpdateDefaultResponce = (apiRequestCallId: string, responseJson: ResponseJson) => {
    if (apiRequestCallId === this.AddressApiCallId) {
     
      if (responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
        const firstError = responseJson.errors[0]; 
  
        if (firstError.contact_number) {
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              phone_number: "*" + firstError.contact_number,
            },
            isAlert: false, 
          }));
        }
  
        if (firstError.address) {
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              address_name: "*" + firstError.address,
            },
            isAlert: false, 
          }));
        }
  
        return; 
      }
  
      
      if (responseJson.meta?.message) {
        this.setState({
          AddressResponce: responseJson.meta.message,
          errors: {}, 
          isAlert: true,
          alertMsg: this.tranSuccessAddress(responseJson.meta.message),
          alertType: "success",
        });
  
        storage.set("isAlert", true);
        storage.set("isAlertMsg", responseJson.meta.message);
        this.getNavigationAddAddress();
      }
    }
  };
  
  
  
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  };
  oncloseAlert = () => {
    this.setState({ isAlert: false });
};
handleToggle = () => {
  this.setState((prevState) => ({
    isChecked: !prevState.isChecked,
    formData: {
      ...prevState.formData,
      is_default: !prevState.isChecked 
    }
  }));
};

tranSuccessAddress = (transKey: string) => {
  return i18n.t( transKey, {ns: "address"});
}

    // Customizable Area End
}
