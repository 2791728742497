import React from "react";
// Customizable Area Start
import { TextField, styled, Box, Button, Typography, Paper } from "@material-ui/core";
import { back_button, lowerBody } from "./assets";
// Customizable Area End

import MeasurementController, {
    Props,
    configJSON,
} from "./MeasurementController.web";

export default class LowerBodyMeasurement extends MeasurementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { lowhip, upperthigh, lowerthigh, knee, calf } = this.state;
        const buttonDisabled = !lowhip || !upperthigh || !lowerthigh || !knee || !calf;
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainWrapper >
                <Box className="mainBox">
                    <HeadingWrapper>
                        <Paper data-test-id="navigateBack" onClick={() => this.handleNavigation('UpperBodyMeasurement')} elevation={1} className="back_icon_Paper">
                            <img src={back_button} alt="back_button" />
                        </Paper>
                        <Typography className="headingText">{configJSON.MeasurementHeading}</Typography>
                        <Button data-test-id="nextButton" onClick={this.handleLowerBodyFormSubmit} disabled={buttonDisabled} className={!buttonDisabled ? "headingButtonEnable" : "headingButton"}>Next</Button>
                    </HeadingWrapper>
                    <ImageWrapper>
                        <img width={'100%'} src={lowerBody} alt="lower_body" />
                    </ImageWrapper>
                    <FieldsWrapper>
                        <Typography className="contentHeading">Lower Body Measurements (In cms.) </Typography>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Low Hip </Typography>
                            <TextField
                                data-test-id="lowhip"
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Low Hip"
                                name="lowhip"
                                value={lowhip}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Upper Thigh</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Upper Thigh"
                                name="upperthigh"
                                value={upperthigh}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Lower Thigh</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Lower Thigh"
                                name='lowerthigh'
                                value={lowerthigh}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Knee</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Knee"
                                name='knee'
                                value={knee}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Calf</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Calf"
                                name='calf'
                                value={calf}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                    </FieldsWrapper>
                </Box>
            </MainWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    "& .mainBox": {
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        maxWidth: '400px',
        flexDirection: 'column',
        paddingInline: '48px',
        gap: '26px',
        marginBottom: '50px',
    },
    "& .avatar_heading": {
        marginBlock: "30px 14px",
        color: '#375280',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '26px',

    },
});

const HeadingWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '48px',
    "& .back_icon_Paper": {
        borderRadius: '50%',
        padding: '8px'
    },
    "& .headingText": {
        fontFamily: 'Avenir,sans-serif',
        color: '#375280',
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: '26px',

    },
    "& .headingButton": {
        fontFamily: 'Lato,sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '26px',
        textTransform: 'none',
        textDecoration: 'underline',
        color: '#94A3B8',
        minWidth: '0px',
        padding: '0px',

        "&.MuiButton-root:hover": {
            backgroundColor: '#fff'
        }


    },
    "& .headingButtonEnable": {
        fontFamily: 'Lato,sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '26px',
        textTransform: 'none',
        textDecoration: 'underline',
        color: '#375280',
        minWidth: '0px',
        padding: '0px',

        "&.MuiButton-root:hover": {
            backgroundColor: '#fff'
        }


    },
});

const ImageWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '12px'
});

const FieldsWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    flexDirection: 'column',
    alignItems: 'center',

    "& .contentHeading": {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Lato, sans-serif',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '26px',
        color: '#375280',
    },

    "& .contentBox": {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '6px',
    },

    "& .inputHeading": {
        color: "#375280",
        textOverflow: "ellipsis",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "24px",
    },

    "& .inputFeild": {
        "& .MuiTextField-root": {
            "&:hover": {
                border: "1px solid #F0F0F0 !important",
            },
        },
        "& .MuiOutlinedInput-root:hover": {
            border: "none !important",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",

        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        }
    },
});

// Customizable Area End
