import React from "react";
// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { TextField, Button, styled, Grid, Breadcrumbs, IconButton, Typography, Box, Snackbar, MenuItem, Select } from "@material-ui/core";
import SourceProductController, {
  Props,
} from "./SourceProductController.web";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import { uploadIcon } from "./assets";
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import { selectEndIcon } from "../../customform/src/assets";
import DeleteIcon from '@material-ui/icons/Delete';
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End
export default class SourceProduct extends SourceProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  selectIconRender = () => (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{
        paddingRight: 10,
        position: "absolute",
        zIndex: 1,
        right: 0,
        pointerEvents: "none",
      }}
      data-test-id="select-drop-down-img"
    />
  );

  topPart = () => {
    return (<BreadcrumbStyle>
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={i18n.dir() === "ltr" ? <KeyboardArrowRightRoundedIcon /> : <KeyboardArrowLeftRoundedIcon />}>
        <Typography color="inherit" data-testid="breadcrumb" onClick={() => this.handleNavigation("HiredStylist")}>{this.transSourceProduct("Stylist")} </Typography>
        <Typography color="inherit">{this.transSourceProduct("Source Product")} </Typography>
      </Breadcrumbs>
      <Box className="pageTitle">

        <IconButton className="iconButton" data-testid="iconButton" onClick={() => this.handleNavigation("HiredStylist")}>
        {i18n.dir() === "ltr" ? <KeyboardArrowLeftRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
        </IconButton>
        <Typography className={i18n.dir() === "ltr" ? "text" : "text2"}>{this.transSourceProduct("Source Product")} </Typography>
      </Box>
    </BreadcrumbStyle>)
  }

  // Customizable Area End
  render() {
    // Customizable Area Start 
    const { navigation } = this.props;
    const { isAlert, severity, message, selectedFiles } = this.state;
    const initialValues = {
      productName: '',
      productDescription: '',
      productColor: '',
      productSizes: '',
      minPrice: '',
      maxPrice: '',
      attachment: null,
      gender: '', 
      quantity: ''
    };

    const validationSchema = Yup.object({
      productName: Yup.string().required(this.transSourceProduct('Product name is required')),
      productDescription: Yup.string().required(this.transSourceProduct('Product description is required')),
      productColor: Yup.string().required(this.transSourceProduct('Product color is required')),
      productSizes: Yup.string().required(this.transSourceProduct('Product size is required')),
      minPrice: Yup.number().required(this.transSourceProduct('Minimum price is required')).positive().integer(),
      maxPrice: Yup.number().required(this.transSourceProduct('Maximum price is required')).positive().integer(),
      gender: Yup.string().required(this.transSourceProduct('Gender is required')),
      quantity: Yup.string().required(this.transSourceProduct('Quantity is required')),
    });

    return (
      <>
        <Header navigation={navigation} />
        <Snackbar
          open={isAlert}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={3000}
          onClose={this.oncloseAlert}>
          <Alert variant="filled" severity={severity}>{message}</Alert>
        </Snackbar>
        <Grid container dir={i18n.dir()} style={{ maxWidth: "1440px", margin: "40px auto", width: "100%" }}>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            {this.topPart()}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.favStylistProfile}
            >
              {({ setFieldValue, errors, touched, getFieldProps }) => (
                <ClientNewOrderRequestContainer>
                  <Grid container spacing={3} className="requestFormContainer">

                    <Grid className="text23" item xs={12}>
                      <Typography className="inputNameTxnOrder">{this.transSourceProduct("Product Name")}*</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("productName")}
                        id="product-name"
                        data-testid="product-name"
                        placeholder={this.transSourceProduct("Product name here")}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(errors.productName && touched.productName)}
                      />
                      {errors.productName && touched.productName && (
                        <Typography className="errorText">{errors.productName}</Typography>
                      )}
                    </Grid>

                    <Grid className="text23" item xs={12}>
                      <Typography className="inputNameTxnOrder">{this.transSourceProduct("Product Description")}*</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("productDescription")}
                        id="product-description"
                        placeholder={this.transSourceProduct("Product description here")}
                        variant="standard"
                        multiline
                        minRows={4}
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(errors.productDescription && touched.productDescription)}
                      />
                      {errors.productDescription && touched.productDescription && (
                        <Typography className="errorText">{errors.productDescription}</Typography>
                      )}
                    </Grid>

                    <Grid className="text23" item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">{this.transSourceProduct("Product Colour")}*</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("productColor")}
                        id="product-color"
                        placeholder={this.transSourceProduct("Product Colour")}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(errors.productColor && touched.productColor)}
                      />
                      {errors.productColor && touched.productColor && (
                        <Typography className="errorText">{errors.productColor}</Typography>
                      )}
                    </Grid>

                    <Grid className="text23" item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">{this.transSourceProduct("Product Sizes")}*</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("productSizes")}
                        id="product-sizes"
                        placeholder={this.transSourceProduct("Product Sizes")}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(errors.productSizes && touched.productSizes)}
                      />
                      {errors.productSizes && touched.productSizes && (
                        <Typography className="errorText">{errors.productSizes}</Typography>
                      )}
                    </Grid>
                    <Grid className="text23" item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">
                      {this.transSourceProduct("Gender")}*
                      </Typography>
                      <OrderReqSelectCustom
                        {...getFieldProps("gender")}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        disableUnderline={true}
                        IconComponent={this.selectIconRender}
                        data-test-id="gender-test-id"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="" disabled>
                        {this.transSourceProduct("Select Gender")}
                        </MenuItem>
                        <MenuItem value={"male"}>{this.transSourceProduct("Male")}</MenuItem>
                        <MenuItem value={"female"}>{this.transSourceProduct("Female")}</MenuItem>
                        <MenuItem value={"kids"}>{this.transSourceProduct("Kids")}</MenuItem>
                      </OrderReqSelectCustom>
                      {errors.gender && touched.gender && (
                        <Typography className="errorText">{errors.gender}</Typography>
                      )}
                    </Grid>
                    <Grid className="text23" item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">{this.transSourceProduct("Quantity")}*</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("quantity")}
                        id="product-sizes"
                        placeholder={this.transSourceProduct("Quantity")}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(errors.quantity && touched.quantity)}
                      />
                      {errors.quantity && touched.quantity && (
                        <Typography className="errorText">{errors.quantity}</Typography>
                      )}
                    </Grid>
                    <Grid className="text23" item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">{this.transSourceProduct("Minimum Price")}*</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("minPrice")}
                        id="min-price"
                        placeholder={this.transSourceProduct("Amount")}
                        type="number"
                        variant="standard"
                        error={Boolean(errors.minPrice && touched.minPrice)}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: <InputAdornment position="start" className="customAdornment">$</InputAdornment>,
                        }}
                      />
                      {errors.minPrice && touched.minPrice && (
                        <Typography className="errorText">{errors.minPrice}</Typography>
                      )}
                    </Grid>

                    <Grid className="text23" item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">{this.transSourceProduct("Maximum Price")}*</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("maxPrice")}
                        id="max-price"
                        placeholder={this.transSourceProduct("Amount")}
                        type="number"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: <InputAdornment position="start" className="customAdornment">$</InputAdornment>,
                        }}
                        error={Boolean(errors.maxPrice && touched.maxPrice)}
                      />
                      {errors.maxPrice && touched.maxPrice && (
                        <Typography className="errorText">{errors.maxPrice}</Typography>
                      )}
                    </Grid>

                    <Grid className="text23" item xs={12}>
                      <Typography className="inputNameTxnOrder">{this.transSourceProduct("Attachments")}*</Typography>
                      <Box className="uploadAfterContainer">
                        <input
                          id="fileUpload"
                          name="attachment"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          multiple
                          style={{ display: 'none' }}
                          data-testid="fileUpload"
                          onChange={(e) => this.handleFileChange(e, setFieldValue)}
                        />
                        <label htmlFor="fileUpload">
                          <IconButton component="span">
                            <img src={uploadIcon} className="uploadBeforeUploadIcon" alt="Upload Icon" />
                          </IconButton>
                          <Typography style={{ color: "#47628a", marginTop: "8px" }}>
                          <b>{this.transSourceProduct("Upload")}</b> png, jpg, jpeg
                          </Typography>
                        </label>
                      </Box>
                      
                      <Box className="fileListContainer" display="flex" flexWrap="wrap" marginTop="16px">
                      {selectedFiles.map((file, index) => (
                        <Box 
                          key={index}
                          position="relative" 
                          margin="8px"
                          width="100px" 
                          height="100px"
                        >
                          <img 
                            src={URL.createObjectURL(file)} 
                            alt={file.name} 
                            style={{ 
                              width: '100%', 
                              height: '100%', 
                              objectFit: 'cover', 
                              borderRadius: '4px' 
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={() => this.removeFile(index, setFieldValue)}
                            style={{
                              position: 'absolute',
                              top: '3px',
                              right: '3px',
                              backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            }}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </Box>
                      ))}
                      </Box>
                    </Grid>

                    <Grid className="text23" item xs={12}>
                      <Box className="inputSendContainer">
                        <Button className="sendBtn" data-testid="sendBtn" type="submit">
                        {this.transSourceProduct("Submit")}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </ClientNewOrderRequestContainer>
              )}
            </Formik>
          </Grid>
        </Grid>
        <Footer navigation={navigation} />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const BreadcrumbStyle = styled(Box)({
  margin: "20px",
  "& .pageTitle": {
    alignItems: "center",
    display: "flex",
    margin: "20px 0"
  },
  "& .iconButton": {
    backgroundColor: '#fff',
    padding: '8px',
    marginRight: '8px',
    borderRadius: '50%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  },
  "& .text": {
    marginLeft: "5px",
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 600,
    color: '#375280',
  },
  "& .text2": {
    marginRight: "5px",
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 600,
    color: '#375280',
  },
  "& .breadcrumb": {
    lineHeight: '22px',
    textAlign: 'left',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 400,
    textDecoration: 'none',
    color: '#475569',
    cursor: "pointer"
  }
})

const ClientNewOrderRequestContainer = styled(Form)({
  "& .text23": {
    position: "relative"
  },
  "& .errorText": {
    color: "red",
    fontSize: "14px",
    position: "absolute",
  },
  "& .requestFormContainer": {
    marginTop: 20, padding: 20, boxShadow: "0px 2px 8px 0px #00000014",
    width: "968px", margin: "0 auto"
  },
  "& .uploadAfterContainer": {
    width: '100%', height: "130px", 
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
    background: "#F8F8F8",
    cursor: "pointer",
  },
  "& .uploadAfterBoxContainer": {
    position: 'relative',
    borderRadius: 2,
    height: "184px",
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f1f1f1',
  },
  "& .uploadAfterImgContainer": {
    width: '100%', height: '184px'
  },
  "& .uploadAfterReplaceBtn": {
    color: "#3f51b5",
    width: "480px",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    height: "54px",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "inherit",
    backgroundColor: "#fff",
    position: "absolute",
    bottom: "6%",
    left: "15%",
    textTransform: "none"
  },
  "& .uploadAfterDeleteBtn": {
    position: 'absolute',
    top: 16,
    right: 16,
    backgroundColor: '#ffffff',
    color: '#f44336',
    boxShadow: "0px 2px 8px 0px #00000014"
  },
  "& .uploadBeforeBoxContainer": {
    borderRadius: 2,
    padding: 4,
    height: "184px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f1f1f1',
  },
  "& .uploadBeforeUploadIcon": {
    width: "40px", height: "40px"
  },
  "& .uploadBeforeUploadText": {
    marginTop: 2, fontFamily: "Lato", fontSize: "18px", fontWeight: 700, color: '#375280'
  },
  "& .inputCancelContainer": {
    padding: "10px 0",
    display: "flex",
    justifyContent: "end"
  },
  "& .inputSendContainer": {
    padding: "10px 0",
    display: "flex",
    justifyContent: "center"
  },
  "& .submitBtn": {
    width: "100%",
    height: "53px",
    fontWeight: 800,
    fontSize: "20px",
    textTransform: "none",
    background: "#CCBEB1",
    color: "white",
    borderRadius: "4px"
  },
  "& .cancelBtn": {
    width: "50%",
    height: "64px",
    fontWeight: 500,
    color: "#375280",
    fontSize: "20px",
    textTransform: "none",
    border: "1px solid #CCBEB1",
    borderRadius: "2px"
  },
  "& .sendBtn": {
    width: "50%",
    height: "64px",
    fontWeight: 800,
    fontSize: "20px",
    textTransform: "none",
    background: "#CCBEB1",
    color: "white",
    borderRadius: "2px", 
    fontFamily: 'Lato',
  },
  "& .inputNameTxnOrder": {
    color: "#375280",
    fontFamily: "Lato",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: "10px"
  }
});

const ClientNewRequestCustomInput = styled(TextField)({
  background: "#f1f1f1",
  border: "1px solid #F8F8F8",
  borderRadius: "4px",
  width: "-webkit-fill-available",
  fontStyle: "normal !important",
  fontFamily: "Lato",
  padding: "8px 10px",
  marginBottom: 10,
  position: "relative",
  "& .customAdornment p": {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '18px',
    color: '#375280',
    padding: '4px 0px', 
  },
  "& .MuiInputBase-input": {
    padding: "6px 3px 7px"
  },
  '& ::placeholder': {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    opacity: 1,
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato",
  },
  '& .MuiInputBase-root': {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato",
  }
});

const OrderReqSelectCustom = styled(Select)({
  paddingLeft: "20px",
  width: "100%",
  paddingRight: "15px",
  background: "#f1f1f1",
  border: "1px solid #F8F8F8",
  borderRadius: "4px",
  height: "53px",
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: 400,
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  color: "#375280",
  "& .MuiSelect-select.MuiSelect-select": {
    background: "#f1f1f1",
  },
});
// Customizable Area End
