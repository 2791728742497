Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "promocodes";
exports.labelBodyText = "promocodes Body";
exports.btnExampleTitle = "CLICK ME";
exports.getPromocodesEndPoint = "promo_codes/promo_codes?city_id=";
exports.getPromocodesAPIMethod = "GET";
exports.numberMsg="number";
exports.generatorTrack = "bx_block_coupon_cg/coupon_code_generator";
exports.validationApiMethodPatchType = "PATCH";
exports.toggleActiveTrack = "bx_block_coupon_cg/coupon_code_generator";
exports.toggleDeleteTrack = "bx_block_coupon_cg/coupon_code_generator";
exports.validationApiMethodDeleteType = "DELETE";
exports.getStoreIdDate = "bx_block_coupon_cg/coupon_code_generator"
// Customizable Area End
