import React from "react";
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCDxwMekbQ6o1JAmnycULD2m8G2YdEl2UQ",
  authDomain: "fashionaggregator-639a6.firebaseapp.com",
  projectId: "fashionaggregator-639a6",
  storageBucket: "fashionaggregator-639a6.firebasestorage.app",
  messagingSenderId: "462024028556",
  appId: "1:462024028556:web:4ed47ec9c91744b3c7e6a9",
  measurementId: "G-YJC14DJP9M"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const getFirebaseToken = async () => {
  try {
    const res = await getToken(messaging, {
      vapidKey:
        "BJ2hyk37XKlkXkeIemPfEWetczQ6WEnBAHYDNtdn6rteQNqoPYEec8mNRKHRj3UKaTGZSuRzQ8yuJUcFabvfjlw",
    });
    return res;
  } catch (e) {
    console.log(e);
    return "";
  }
};

const displayToast = (title, body) => {
  return (
    <div>
      <strong>{title}</strong>
      <div style={{ "paddingTop": "10px" }}>{body}</div>
    </div>
  )
};
export { firebaseApp, getFirebaseToken, onMessageListener, displayToast };