import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
interface NotificationList {
  notification: {
    push_notification: {
        "order_invoices": boolean;
        "order_confirmations": boolean;
        "delivery_confirmation": boolean;
        "reviews_and_feedback_requests": boolean;
        "refund_or_payment_complete": boolean;
        "marketing_emails": boolean;
        "product_stock_updates": boolean
    }
    email_notification: {
        "order_invoices": boolean;
        "order_confirmations": boolean;
        "delivery_confirmation": boolean;
        "reviews_and_feedback_requests": boolean;
        "refund_or_payment_complete": boolean;
        "marketing_emails": boolean;
        "product_stock_updates": boolean
    }
}
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  checkedActionBar: boolean;
  checkedActionBarText: string;
  notificationData: any;
  token: string;
  notificationList:NotificationList;
  orderInvoicePush:boolean;
  orderConfirmPush:boolean;
  deliveryPush:boolean;
  reviewPush:boolean;
  refundPush:boolean;
  marketPush:boolean;
  productPush:boolean;
  orderInvoice:boolean;
  orderConfirm:boolean;
  delivery:boolean;
  review:boolean;
  refund:boolean;
  market:boolean;
  product:boolean;
  roleData:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NotificationsettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  notificationAPICallId: any;
  setNotificationAPICall: any;
  setNotificationGroupAPICall: any;
  setNotificationSubGroupAPICall: any;
  
  // Customizable Area Start
  notificationSettingAPICallId:string;
  setNotificationUpdateAPICall: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      txtInputValue: "",
      roleData:"",
      txtSavedValue: "A",
      enableField: false,
      checkedActionBar: false,
      checkedActionBarText: configJSON.notificationGroup1,
      notificationData: [],
      token: "",
      notificationList:configJSON.dataOfNotification,
      orderInvoicePush:false,
      orderConfirmPush:false,
      deliveryPush:false,
      reviewPush:false,
      refundPush:false,
      marketPush:false,
      productPush:false,
      orderInvoice:false,
      orderConfirm:false,
      delivery:false,
      review:false,
      refund:false,
      market:false,
      product:false,
    };
    this.notificationSettingAPICallId="";
    this.setNotificationUpdateAPICall="";
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const userData = await getStorageData("userRole", true)
    this.setState({ roleData: userData.userType })
    this.getNotificationSettingList()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson) {
        this.handleNotificationAPICallId(apiRequestCallId,responseJson)
        this.handleSetNotificationAPICall(apiRequestCallId)
        this.hadleSetNotificationGroupAPICall(apiRequestCallId)
        this.handleSetNotificationSubGroupAPICall(apiRequestCallId)
        this.handleNotificationSettingAPICallId(apiRequestCallId,responseJson)
        this.handleSetNotificationUpdateAPICall(apiRequestCallId)
        runEngine.debugLog("API Recived", responseJson.data);
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ notificationData: this.state.notificationData });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  getNotificationDataRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area Start

  setMainCheckBox(item: any) {
    var data = {
      title: item.attributes.title,
      description: item.attributes.description,
      state: item.attributes.state === "active" ? "inactive" : "active"
    };
    this.postNotificationDataRequest(
      configJSON.updateNotificationAPIEndPoint + `${item.id}`,
      data,
      "setNotification"
    );
  }

  setGroupCheckBox(index: number, groupIndex: number, groupitem: any) {
    var data = {
      group_type: groupitem.attributes.group_type,
      group_name: groupitem.attributes.group_name,
      state: groupitem.attributes.state === "active" ? "inactive" : "active",
      notification_setting_id: groupitem.attributes.notification_setting_id
    };
    this.postNotificationDataRequest(
      configJSON.updateNotificationGroupAPIEndPoint + `${groupitem.id}`,
      data,
      "setGroupNotification"
    );
  }

  setSubGroupCheckBox = (subGroupItem: any) => {
    let data = {
      subgroup_type: subGroupItem.attributes.subgroup_type,
      subgroup_name: subGroupItem.attributes.subgroup_name,
      state: subGroupItem.attributes.state === "active" ? "inactive" : "active",
      notification_group_id: subGroupItem.attributes.notification_group_id
    };

    this.postNotificationDataRequest(
      configJSON.updateNotificationSubGroupAPIEndPoint + `${subGroupItem.id}`,
      data,
      "setSubGroupNotification"
    );
  };

  postNotificationDataRequest = (
    endPointURL: string,
    data: any,
    type: string
  ): boolean => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (type === "setNotification") {
      this.setNotificationAPICall = requestMessage.messageId;
    } else if (type === "setGroupNotification") {
      this.setNotificationGroupAPICall = requestMessage.messageId;
    } else if (type === "setSubGroupNotification") {
      this.setNotificationSubGroupAPICall = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getNotificationSettingList = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationSettingAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationlistURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  putNotificationSettingUpdate = async(notiType:string,notiKey:string,value:boolean) => {
    const token = await getStorageData("auth-token");
    const header = {
      token:token
    };
    
    const formData = new FormData();
    formData.append("notification_type",notiType);
    formData.append("notification_key",notiKey);
    formData.append("notification_value",JSON.stringify(value))
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

      this.setNotificationUpdateAPICall = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateNotificationUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateNotificationUrlPut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNotificationAPICallId=(apiRequestCallId:string,responseJson: { data: string; })=>{
    if (apiRequestCallId === this.notificationAPICallId) {
      this.setState({ notificationData: responseJson.data });
    }
  }

  handleSetNotificationAPICall=(apiRequestCallId:string)=>{
    if (apiRequestCallId === this.setNotificationAPICall) {
      this.setState({ notificationData: [] });
    }
  }

  hadleSetNotificationGroupAPICall =(apiRequestCallId:string) =>{
    if (apiRequestCallId === this.setNotificationGroupAPICall) {
      this.setState({ notificationData: [] });
    }
  }

  handleSetNotificationSubGroupAPICall =(apiRequestCallId:string)=>{
    if (apiRequestCallId === this.setNotificationSubGroupAPICall) {
      this.setState({ notificationData: [] });
    }
  }

  handleNotificationSettingAPICallId=(apiRequestCallId:string,responseJson: { data: { attributes: { notification: { push_notification: { order_invoices: boolean; order_confirmations: boolean; delivery_confirmation: boolean; reviews_and_feedback_requests: boolean; refund_or_payment_complete: boolean; marketing_emails: boolean; product_stock_updates: boolean; }; email_notification: { order_invoices: boolean; order_confirmations: boolean; delivery_confirmation: boolean; reviews_and_feedback_requests: boolean; refund_or_payment_complete: boolean; marketing_emails: boolean; product_stock_updates: boolean; }; }; }; }; })=>{
    if(apiRequestCallId=== this.notificationSettingAPICallId){
      this.setState({notificationList:{notification:responseJson?.data?.attributes?.notification}})

      this.setState({orderInvoicePush:responseJson?.data?.attributes?.notification?.push_notification?.order_invoices})
      this.setState({orderConfirmPush:responseJson?.data?.attributes?.notification?.push_notification?.order_confirmations})
      this.setState({deliveryPush:responseJson?.data?.attributes?.notification?.push_notification?.delivery_confirmation})
      this.setState({reviewPush:responseJson?.data?.attributes?.notification?.push_notification?.reviews_and_feedback_requests})
      this.setState({refundPush:responseJson?.data?.attributes?.notification?.push_notification?.refund_or_payment_complete})
      this.setState({marketPush:responseJson?.data?.attributes?.notification?.push_notification?.marketing_emails})
      this.setState({productPush:responseJson?.data?.attributes?.notification?.push_notification?.product_stock_updates})

      this.setState({orderInvoice:responseJson?.data?.attributes?.notification?.email_notification?.order_invoices})
      this.setState({orderConfirm:responseJson?.data?.attributes?.notification?.email_notification?.order_confirmations})
      this.setState({delivery:responseJson?.data?.attributes?.notification?.email_notification?.delivery_confirmation})
      this.setState({review:responseJson?.data?.attributes?.notification?.email_notification?.reviews_and_feedback_requests})
      this.setState({refund:responseJson?.data?.attributes?.notification?.email_notification?.refund_or_payment_complete})
      this.setState({market:responseJson?.data?.attributes?.notification?.email_notification?.marketing_emails})
      this.setState({product:responseJson?.data?.attributes?.notification?.email_notification?.product_stock_updates})
      
    }
  }

  handleSetNotificationUpdateAPICall=(apiRequestCallId:string)=>{
    if(apiRequestCallId=== this.setNotificationUpdateAPICall){
      this.getNotificationSettingList()
     }
  }
  // Customizable Area End
}
