import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Grid, Typography, Box, styled } from "@material-ui/core";
import StylishClientDetailsController, {
  Props,
} from "./StylishClientDetailsController";
import { ThemeProvider } from "react-native-elements";
import { backBtnIcon, request1, request2, request3 } from "../../assets";
import StylishSidebar from "../../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../../components/src/Stylish/StylishHeader";
import {
  StylishClientContainer,
  StylishClientTitleContainer,
} from "./StylingClientWishlist.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylishClientDetails extends StylishClientDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            item
            xl={2}
            md={2}
            id="stylish-client-details-sidebar-catalogue"
            lg={2}
            sm={2}
            xs={2}
          >
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            md={10}
            id="stylish-client-details-header-catalogue"
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader navigation={this.props.navigation} />
            <StylishClientContainer>
              <StylishClientTitleContainer>
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  onClick={() =>
                    this.handleStylishClientDetailsRedirect("StylishMyClients")
                  }
                />
                <Typography className="main-title">Ralph Edwards</Typography>
              </StylishClientTitleContainer>
              <MainDivContainer>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
                    <LeftDivContainer>
                      <Box className="client-request-title">
                        <Typography className="client-request-number">
                          #3476-9867 | 03:30 PM
                        </Typography>
                        <Box className="client-status client-status-Current">
                          Current
                        </Box>
                      </Box>
                      <Typography className="request-description">
                        I want a red couloured dress with shinny embroidry over
                        it. I usually wear the ‘Medium Size’ and ‘Slim’ fit. I
                        want to wear that dress at Gala Dinner at my company’s
                        meetup. The location is Maldives so i want something
                        according to that location.{" "}
                      </Typography>
                      <Box className="request-details-div">
                        <Box className="request-detail-div">
                          <Typography className="request-name">
                            Budget
                          </Typography>
                          <Typography className="request-value">
                            $50 - $150
                          </Typography>
                        </Box>
                        <Box className="request-detail-div">
                          <Typography className="request-name">
                            Gender
                          </Typography>
                          <Typography className="request-value">
                            Male
                          </Typography>
                        </Box>
                        <Box className="request-detail-div">
                          <Typography className="request-name">
                            Plan Type
                          </Typography>
                          <Typography className="request-value">
                            Weekly
                          </Typography>
                        </Box>
                        <Box className="request-detail-div">
                          <Typography className="request-name">
                            Start Date
                          </Typography>
                          <Typography className="request-value">
                            19 Feb 2024
                          </Typography>
                        </Box>
                        <Box className="request-detail-div">
                          <Typography className="request-name">
                            End Date
                          </Typography>
                          <Typography className="request-value">
                            25 Feb 2024
                          </Typography>
                        </Box>
                        <Box className="request-detail-div">
                          <Typography className="request-name">
                            Payment
                          </Typography>
                          <Typography className="request-value">
                            Completed
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="wish-list-btn" data-test-id="client-wishlist-id" onClick={()=>this.handleStylishClientDetailsRedirect("StylingClientWishlist")}>Ralph’s Wishlist</Box>
                    </LeftDivContainer>
                  </Grid>
                  <Grid
                    item
                    className="second-grid"
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    xl={6}
                  >
                    <Grid container spacing={3}>
                      {requestImages.map((img) => (
                        <Grid key={img.id} item lg={6} md={6} xl={6}>
                          <ImgBoxContainer>
                            <img
                              className="img-div"
                              src={img.url}
                              alt="image"
                            />
                          </ImgBoxContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </MainDivContainer>
            </StylishClientContainer>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const requestImages = [
  {
    id: 1,
    url: request1,
  },
  {
    id: 2,
    url: request3,
  },
  {
    id: 3,
    url: request2,
  },
];

const MainDivContainer = styled(Box)({
  marginTop: 42,
  "& .second-grid": {
    padding: "36px",
    "& .MuiGrid-root.MuiGrid-container": {
      boxShadow: "0px 2px 8px 0px #00000014",
      padding: "6px 12px 32px 12px",
    },
    "@media(max-width:959px)": {
      padding: 15,
      margin: 12,
      "& .MuiGrid-root.MuiGrid-container": {
        paddingBottom: 12,
      },
    },
  },
});

const ImgBoxContainer = styled(Box)({
  width: "100%",
  height: "16vw",
  objectFit: "fill",
  borderRadius: 2,
  "& .img-div": {
    width: "inherit",
    height: "inherit",
  },
});

const LeftDivContainer = styled(Box)({
  display: "flex",
  gap: 20,
  flexDirection: "column",
  "& .client-request-title": {
    display: "flex",
    gap: 20,
    alignItems: "center",
    justifyContent: "space-between",
    "& .client-request-number": {
      fontFamily: "Lato, sans-serif",
      fontWeight: 700,
      fontSize: 18,
      color: "#375280",
      textAlign: "center",
    },
    "& .client-status": {
      borderRadius: 2,
      padding: "6px 10px 6px 10px",
      width: "max-content",
      fontFamily: "Lato, sans-serif",
      fontWeight: 700,
      fontSize: 14,
    },
    "& .client-status-Completed": {
      color: "#059669",
      backgroundColor: "#D1FAE5",
    },
    "& .client-status-Current": {
      color: "#BE5B00",
      backgroundColor: "#FFE7D0",
    },
  },
  "& .request-description": {
    fontFamily: "Lato, sans-serif",
    fontSize: 18,
    fontWeight: 400,
    color: "#375280",
    marginBottom: 12,
  },
  "& .request-details-div": {
    boxShadow: "0px 2px 8px 0px #00000014",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: "24px 16px",
    "& .request-detail-div": {
      display: "flex",
      gap: 40,
      justifyContent: "space-between",
      alignItems: "center",
      "& .request-name": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 900,
        fontSize: 16,
        color: "#375280",
      },
      "& .request-value": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 18,
        color: "#375280",
      },
    },
  },
  "& .wish-list-btn": {
    padding: "13px 0px",
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontWeight: 800,
    fontSize: 20,
    width: "100%",
    backgroundColor: "#CCBEB1",
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
    borderRadius: 2,
    border: "1px solid #CCBEB1",
    marginTop: 4,
  },
});
// Customizable Area End
