import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";

interface OrderItem {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}

interface OrderItemAttributes {
  confirmed_at: string;
  status: string;
  placed_at: string;
  in_transit_at: string | null;
  cancelled_at: string | null;
  delivered_at: string | null;
  shipped_at: string | null;
  process_at: string;
  return_pick_at: string | null;
  rejected_at: string | null;
  return_at: string | null;
  reason_of_rejection: string | null;
  return_cancel_at: string | null;
  quantity: number;
  catalogue_variant_sku: string;
  catalogue_variant_color: string;
  unit_price: string;
  total_price: string;
  catalogue_variant_side_image: string;
  brand_name: string;
  catalogue_name: string;
  store_name: string;
  catalogue_variant_front_image: string;
  catalogue_variant_back_image: string;
  catalogue_variant_size: string;
  driver_name: string | null;
  driver_phone_number: string | null;
  driver_latitude: number | null;
  otp: string | null;
  driver_longitude: number | null;
}

interface OrderManagementOrderAttributes {
  account: string;
  placed_at: string;
  order_number: string;
  total: string;
  sub_total: string;
  confirmed_at: string;
  status: string;
  in_transit_at: string | null;
  returned_at: string | null;
  refunded_at: string | null;
  cancelled_at: string | null;
  order_deliver_date: string | null;
  delivered_at: string | null;
  order_status_id: number;
  created_at: string;
  deliver_by: string | null;
  order_return_date: string | null;
  order_deliver_time: string | null;
  updated_at: string;
  payment_detail: PaymentDetail;
  order_return_time: string | null;
  delivery_addresses: DeliveryAddress;
}

interface DeliveryAddress {
  type: string;
  id: string;
  attributes: DeliveryAddressAttributes;
}

interface DeliveryAddressAttributes {
  phone_number: string;
  name: string;
  contact_number: string;
  country_code: string;
  zip_code: string;
  street: string;
  city: string;
  block: string;
  area: string;
  house_or_building_number: string;
  longitude: number;
  is_default: boolean;
  address_name: string;
  latitude: number;
}

interface PaymentDetail {
  updated_at: string;
  status: string;
  order_id: string;
  created_at: string;
  merchant_id: string | null;
  id: number;
  charge_id: string;
  currency: string;
  customer_id: string;
  reason: string;
  amount: number;
  order_management_order_id: number;
  account_id: number;
  refund_amount: string | null;
  refund_id: string | null;
  seller_order_id: string | null;
  refund_reason: string | null;
  last_four_card_digit: string;
  payment_type: string;
  deleted: boolean;
}

interface OrderManagementOrder {
  id: string;
  type: string;
  attributes: OrderManagementOrderAttributes;
}
interface SellerNewOrderAttributes {
  order_items: OrderItem[];
  id: number;
  accept_order_upload_time: string | null;
  order_management_order: OrderManagementOrder;
  status: string;
}

export interface SellerNewOrder {
  id: string;
  type: string;
  attributes: SellerNewOrderAttributes;
}

export interface TotalOrder {
  total_orders: number,
  total_shipped_orders: number,
  total_delivered_orders: number,
  total_rejected_orders: number,
  total_process_orders: number,
  total_in_process_orders: number,
  total_new_orders: number,
  total_return_refund_orders: number
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  newOrderData: SellerNewOrder[];
  totalOrderData: TotalOrder;
  page: number;
  metaDataNewOrder: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  };
  noDataErrorMsg: string;
  rejectDialogOpen: boolean;
  rejectId: number;
  rejectSelectOption: string[];
  rejectReason: string;
  acceptOrderOpen: boolean;
  reloadTime: number;
  confirmOrderId: number;
  selectedStoreId: number | string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getNewOrderListCallId: string = "";
  getCountApiCallID: string = "";
  getTimeOrderCallId: string = "";
  getRejectNewOrderCallId: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      selectedStoreId: "-1",
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false, 
      newOrderData: [],
      totalOrderData: {
        total_orders: 0,
        total_shipped_orders: 0,
        total_delivered_orders: 0,
        total_rejected_orders: 0,
        total_process_orders: 0,
        total_in_process_orders: 0,
        total_new_orders: 0,
        total_return_refund_orders: 0
      },
      metaDataNewOrder: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      },
      page:1,
      noDataErrorMsg: "",
      rejectDialogOpen: false,
      rejectId: 0,
      rejectSelectOption: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      rejectReason: '',
      acceptOrderOpen: false,
      confirmOrderId: 0,
      reloadTime: 45,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const selectedStore = await getStorageData("selectedSellerStore", true);
    this.setState({
      selectedStoreId: selectedStore ? selectedStore.id.toString() : "-1"
    }, () => {
      this.getNewOrderApi();
      this.getCount();
    })
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

     if(apiRequestCallId === this.getNewOrderListCallId){
       if(responseJson.error){
        this.setState({
          loading: false,
          noDataErrorMsg: this.transSellerDashboard("There are no orders")
        })
      }
      this.setState({
        newOrderData: responseJson?.data,
        metaDataNewOrder: responseJson?.meta,
        loading: false
      });
    }else if(apiRequestCallId === this.getCountApiCallID){
      this.setState({
        totalOrderData: responseJson
      })
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleAcceptDialogClose = () => {
    this.setState({ acceptOrderOpen: false });
  };

  incrementTime = () => {
    this.setState((prevState) => ({ reloadTime: prevState.reloadTime + 1 }));
  };

  decrementTime = () => {
    this.setState((prevState) => ({ reloadTime: prevState.reloadTime - 1 }));
  };

  timeData = () => {
    this.setState({ acceptOrderOpen: false })
    this.timeOrderData()
    this.getNewOrderApi()
  };

  timeOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListNewOrderTime = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTimeOrderCallId = requestMessageListNewOrderTime.messageId;

    requestMessageListNewOrderTime.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListNewOrderTime.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.confirmOrderId}&type=accept&accept_order_upload_time=${this.state.reloadTime}`
    );
    requestMessageListNewOrderTime.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListNewOrderTime.id, requestMessageListNewOrderTime);
  }

  handleCloseDialog = () => {
    this.setState({ rejectDialogOpen: false })
  };

  handleRejectReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ rejectReason: event.target.value as string });
  };

  handleAcceptOrder = (confirmid: number) => {
    this.setState({ acceptOrderOpen: true, confirmOrderId: confirmid });
  };

  handleRejectOpen = (rejectid: number) => {
    this.setState({ rejectDialogOpen: true, rejectId: rejectid })
  };

  rejectDataApiCall = () => {
    this.setState({ rejectDialogOpen: false })
    this.rejectOrderData()
    this.getNewOrderApi();
  };

  rejectOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageNewList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectNewOrderCallId = requestMessageNewList.messageId;
    requestMessageNewList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageNewList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.rejectId}&type=reject&reason_of_rejection=${this.state.rejectReason}`
    );
    requestMessageNewList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageNewList.id, requestMessageNewList);
  }

  dateFormetTime = (created_at: string) => {
    if (!created_at) return "";
    const orderDate = new Date(created_at);
    let hoursOrder = orderDate.getHours();
    const minutes = orderDate.getMinutes();
    const ampm = hoursOrder >= 12 ? 'PM' : 'AM';
    hoursOrder = hoursOrder % 12;
    hoursOrder = hoursOrder ? hoursOrder : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hoursOrder}:${minutesStr} ${ampm}`;
  }

  headerNavigation = async (pageLink: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  getCount = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    const countEndPoint = this.state.selectedStoreId.toString() === "-1" ? configJSON.getAllOrderDetails : `${configJSON.getAllOrderDetails}?store_ids[]=${this.state.selectedStoreId}`

    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      countEndPoint
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getNewOrderApi = async () => {
    const token = await getStorageData("auth-token");
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newOrderEndPoint = this.state.selectedStoreId.toString() === "-1" ? `${configJSON.getNewOrderApiCallEndPoint}&page=${this.state.page}&per_page=10` : `${configJSON.getNewOrderApiCallEndPoint}&page=${this.state.page}&per_page=10&store_ids[]=${this.state.selectedStoreId}`

    this.getNewOrderListCallId = requestMessageList.messageId;

    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      newOrderEndPoint
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    event.preventDefault()
    this.setState({ page: newPage }, () => this.getNewOrderApi());
};

  transSellerDashboard = (dashSellerKey: string) => {
    return i18n.t( dashSellerKey, {ns: "dashboard"});
  }

  handleStoreChange = (store_id: string | number) => {
    this.setState({
      selectedStoreId: store_id.toString()
    }, () => {
      this.getNewOrderApi();
      this.getCount();
    })
  }
  // Customizable Area End

}
