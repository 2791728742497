export const enCategoryFilter = {
    "Filter": "Filter",
    "Gender": "Gender",
    "Men": "Men",
    "Women": "Women",
    "Kids": "Kids",
    "Size": "Size",
    "number1": "number1",
    "number2": "number2",
    "Price Range": "Price Range",
    "Colors": "Colors",
    "Category": "Category",
    "Home": "Home",
    "Sort by:": "Sort by:",
    "What’s New": "What’s New",
    "% Off": "% Off",
    "Load More": "Load More"
}

export const arCategoryFilter = {
    "Filter": "فلتر",
    "Gender": "جنس",
    "Men": "الرجال",
    "Women": "النساء",
    "Kids": "الأطفال",
    "Size": "المقاس",
    "number1": "رقم 1",
    "number2": "رقم2",
    "Price Range": "نطاق السعر",
    "Colors": "الألوان",
    "Category": "فئة",
    "Home": "الصفحة الرئيسية",
    "Sort by:": "الترتيب حسب:",
    "What’s New": "ما هو الجديد",
    "% Off": "خصم %",
    "Load More": "تحميل المزيد"
}