import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { logoutSellerNavigation } from "../../../../components/src/Seller/logOut";
import { apiCall } from "../../../../components/src/APICall";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";

export interface IImgResp {
  id: number;
  url: string;
}
export interface IMyOfferDataResp {
  id: string;
  type: string;
  attributes: {
    id: number;
    request_id: number;
    buyer_id?: number | null;
    chat_id?: number | null;
    account_id: number;
    quote_price: number;
    product_description: string;
    currency_logo: string;
    request_status: string;
    created_at: string;
    updated_at: string;
    full_name: string;
    images: IImgResp[];
    product_sourcing_request: {
      id: number;
      product_name: string;
      sizes: string[];
      colours: string[];
      gender: string;
      account_id: number | string;
      min_price: string;
      max_price: string;
      quantity: number;
      request_status: string;
      description: string;
      created_at: string;
      updated_at: string;
      images: IImgResp[];
    };
  };
}

export interface MetaResponse {
  total_pages: number;
  current_page: number;
  total_record: number;
  prev_page: number | null;
  next_page: number | null;
}

interface IStylistMyOfferResp {
  data: IMyOfferDataResp[];
  meta: MetaResponse;
}

export interface ErrorMessage {
  errors: [
    {
      token?: string;
      quote_price?: string;
      product_description?: string;
      images?: string;
    }
  ];
}

export interface ErrorResp {
  error: string;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  listData: IMyOfferDataResp[];
  metaResp: MetaResponse;
  isAlert: boolean;
  message: string;
  isLoading: boolean;
  page: number;
  currencySign: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylishMyOfferController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMyOfferAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      listData: [],
      metaResp: {} as MetaResponse,
      isAlert: false,
      message: "",
      isLoading: true,
      page: 1,
      currencySign: "$"
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseJsonData &&
        !responseJsonData.errors &&
        !responseJsonData.error
      ) {
        this.apiStylistMyOfferSuccess(apiRequestCallId, responseJsonData);
      } else if (
        responseJsonData &&
        (responseJsonData.errors || responseJsonData.error)
      ) {
        this.apiStylistMyOfferfailer(responseJsonData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiStylistMyOfferSuccess = async (
    apiRequestCallId: string,
    responseJson: IStylistMyOfferResp
  ) => {
    if (apiRequestCallId === this.getMyOfferAPICallId) {
      if (responseJson){
        this.setState((prevState) => ({
          ...prevState,
          listData: responseJson.data,
          metaResp: responseJson.meta,
          isLoading: !prevState.isLoading,
        }));
      if(responseJson?.data?.length > 0){
        this.setState({
          currencySign: responseJson.data[0]?.attributes.currency_logo
        })
      }
      }
    }
  };

  apiStylistMyOfferfailer = async (
    responseJsonError: ErrorMessage & ErrorResp
  ) => {
    if (responseJsonError.error === "you're not an authorized person!") {
      await this.handleHome(responseJsonError.error);
    } else if (responseJsonError.errors[0].token) {
      await this.handleHome(responseJsonError.errors[0].token);
    }
  };

  handleHome = async (message: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        isAlert: true,
        message: message,
        isLoading: false,
        severity: "error",
      }),
      () => {
        setTimeout(() => {
          this.handleStylishMyOfferRedirect("Home");
          logoutSellerNavigation();
        }, 2000);
      }
    );
  };

  handleStylishMyOfferRedirect = (redir: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redir);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  async componentDidMount() {
    // Customizable Area Start
    this.getMyOfferAPICalled();
    // Customizable Area End
  }

  onAlertMOSnackClose = () => {
    this.setState((prevState) => ({ ...prevState, isAlert: false }));
  };

  getMyOfferAPICalled = async () => {
    const { page } = this.state;
    this.getMyOfferAPICallId = await apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getMyOfferAPIEndpoint}?page=${page}&per_page=10`,
      token: await this.getDataStorage("auth-token"),
    });
  };

  getDataStorage = async (value: string) => {
    return await getStorageData(value);
  };

  handleMyOfferPageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    event.preventDefault();
    this.setState(
      (prevState) => ({
        ...prevState,
        metaResp: { ...prevState.metaResp, current_page: newPage },
        page: newPage,
        isLoading: !prevState.isLoading,
      }),
      () => this.getMyOfferAPICalled()
    );
  };

  handleProductRequestPage = async (bidRequestId: number) => {
    await setStorageData("bid-request-id", bidRequestId);
    this.handleStylishMyOfferRedirect("StylishProductSourcingRequest");
  }

  transStylishMyOffer = (transKey: string) => {
    return i18n.t( transKey, {ns: "catalogue"})
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
