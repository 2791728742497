export const enProfile = {
  "My Profile": "My Profile",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Enter Your First Name": "Enter Your First Name",
  "Enter Your Last Name": "Enter Your Last Name",
  "Email": "Email",
  "Enter Your E-mail": "Enter Your E-mail",
  "Phone Number": "Phone Number",
  "Enter Phone Number": "Enter Phone Number",
  "Save Changes": "Save Changes",
  "Change Password": "Change Password",
  "Old Password": "Old Password",
  "Enter Your Old Password": "Enter Your Old Password",
  "New Password": "New Password",
  "Enter Your New Password": "Enter Your New Password",
  "Repeat New Password": "Repeat New Password",
  "Re-Enter Your Password": "Re-Enter Your Password",
  "OTP Verification": "OTP Verification",
  "Please enter the 4 digit code sent to":
    "Please enter the 4 digit code sent to",
  "your email id ": "your email id ",
  "your mobile number id ": "your mobile number id ",
  "Didn't Received Code?": "Didn't Received Code?",
  "Resend Code": "Resend Code",
  "Verify Account": "Verify Account",
  "Resent OTP Successfully": "Resent OTP Successfully",
  "* Enter 4 digit OTP": "* Enter 4 digit OTP",
  "* Please re-enter your password": "* Please re-enter your password",
  "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric":
    "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric",
  "*Please enter your password": "*Please enter your password",
  "*Please enter a valid email address": "*Please enter a valid email address",
  "*Please enter your email address": "*Please enter your email address",
  "*Please enter a valid first name": "*Please enter a valid first name",
  "*Please enter a valid last name": "*Please enter a valid last name",
  "*Please enter your phone number": "*Please enter your phone number",
  "*Please enter a valid phone number": "*Please enter a valid phone number",
  "User Profile Updated Successfully": "User Profile Updated Successfully",
  "Change Password Updated Successfully": "Change Password Updated Successfully",
  "Edit Profile": "Edit Profile",
  "User Details": "User Details",
  "Password": "Password",
  "Enter Your Password": "Enter Your Password",
  "Edit Profile Picture": "Edit Profile Picture",
  "Upload Image": "Upload Image",
  "Replace Image": "Replace Image",
  "Upload a png, jpg or jpeg file": "Upload a png, jpg or jpeg file",
  "Welcome!": "Welcome!",
  "Choose your user type": "Choose your user type",
  "Continue as": "Continue as",
  "Buyer": "Buyer",
  "Seller": "Seller",
  "Stylist": "Stylist",
  "Select Language": "Select Language",
  "English": "English",
  "Select Currency": "Select Currency",
  "Dollar": "Dollar",
  "Dinar": "Dinar",
  "Continue": "Continue",
  "Arabic - عربي": "Arabic - عربي",
  "Admin Request": "Admin Request",
  "Bank Details":"Bank Details",
  "Your bank information is saved to receive payments of your orders.":"Your bank information is saved to receive payments of your orders.",
  "IBAN Number":"IBAN Number",
  "Account Number":"Account Number",
  "An error occurred, please try again":"An error occurred, please try again",
};

export const arProfile = {
  "My Profile": "حسابي",
  "First Name": "الاسم الأول",
  "Last Name": "اسم العائلة",
  "Enter Your First Name": "أدخل اسمك الأول",
  "Enter Your Last Name": "أدخل اسمك الأخير",
  "Email": " البريد الإلكتروني",
  "Enter Your E-mail": "أدخل بريدك الإلكتروني",
  "Phone Number": " رقم الهاتف",
  "Enter Phone Number": "أدخل رقم الهاتف",
  "Save Changes": "حفظ التغيرات",
  "Change Password": "تغيير كلمة المرور",
  "Old Password": "كلمة المرور القديمة",
  "Enter Your Old Password": "أدخل كلمة المرور القديمة الخاصة بك",
  "New Password": "كلمة المرور الجديدة",
  "Enter Your New Password": "أدخل كلمة المرور الجديدة",
  "Repeat New Password": "أعد كتابة كلمة المرور الجديدة",
  "Re-Enter Your Password": "أعد إدخال كلمة المرور الخاصة بك",
  "OTP Verification": "التحقق من OTP",
  "Please enter the 4 digit code sent to":
    "الرجاء إدخال الرمز المكون من 4 أرقام المرسل إليه",
  "your email id ": "معرف البريد الإلكتروني الخاص بك ",
  "your mobile number id ": "معرف رقم هاتفك المحمول ",
  "Didn't Received Code?": "لم تتلق الرمز؟",
  "Resend Code": "إعادة إرسال الرمز",
  "Verify Account": "التحقق من الحساب",
  "Resent OTP Successfully":
    "تمت إعادة إرسال كلمة المرور لمرة واحدة (OTP) بنجاح",
  "* Enter 4 digit OTP": "* أدخل كلمة مرور لمرة واحدة مكونة من 4 أرقام",
  "* Please re-enter your password":
    "* الرجاء إعادة إدخال كلمة المرور الخاصة بك",
  "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric":
    "*أدخل كلمة مرور صالحة تحتوي على 8 أرقام على الأقل مع حرف خاص واحد على الأقل وحرف كبير وأبجدي رقمي",
  "*Please enter your password": "*الرجاء إدخال كلمة المرور الخاصة بك",
  "*Please re-enter your password": "*الرجاء إعادة إدخال كلمة المرور الخاصة بك",
  "*Please enter a valid email address": "*يرجى إدخال عنوان بريد إلكتروني صالح",
  "*Please enter your email address": "*الرجاء إدخال عنوان بريدك الإلكتروني",
  "*Please enter a valid first name": "*الرجاء إدخال اسم أول صالح",
  "*Please enter a valid last name": "*الرجاء إدخال اسم عائلة صالح",
  "*Please enter your phone number": "*الرجاء إدخال رقم هاتفك",
  "*Please enter a valid phone number": "*الرجاء إدخال رقم هاتف صالح",
  "User Profile Updated Successfully": "تم تحديث ملف تعريف المستخدم بنجاح",
  "Change Password Updated Successfully": "تم تحديث تغيير كلمة المرور بنجاح",
  "Edit Profile": "تعديل صورة الملف الشخصي",
  "User Details": "تفاصيل المستخدم",
  "Password": "كلمة المرور",
  "Enter Your Password": "أدخل كلمة المرور الخاصة بك",
  "Edit Profile Picture": "تعديل صورة الملف الشخصي",
  "Upload Image":"رفع الصورة ",
  "Replace Image": "استبدال الصورة",
  "Upload a png, jpg or jpeg file": "رفع ملف png أو jpg أو jpeg أ",
  "Welcome!": "أهلا بكم!",
  "Choose your user type": "اختر نوع العضوية",
  "Continue as": "الاستمرار ك",
  "Buyer": "مشتري",
  "Seller": "بائع",
  "Stylist": "مصمم",
  "Select Language": "اختر اللغة",
  "English": "الإنجليزية",
  "Select Currency": "حدد العملة",
  "Dollar": "الدولار",
  "Dinar": "الدينار",
  "Continue": "المتابعة",
  "Arabic - عربي": "عربي - Arabic",
  "Admin Request": "طلب المشرف",
  "Bank Details":"تفاصيل البنك",
  "Your bank information is saved to receive payments of your orders.":"يتم حفظ معلوماتك المصرفية لتلقي دفعات طلباتك.",
  "IBAN Number":"رقم الآيبان",
  "Account Number":"رقم الحساب",
  "An error occurred, please try again":"حدث خطأ، يرجى المحاولة مرة أخرى",
};
