// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";

interface INavigateDetailsPageTo {
  id: number | undefined;
  screenName: string;
  raiseMessage?: Message;
}
// Customizable Area End


export const configJSON = require("../../src/config.js");


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  storeList: any;
  circularProgress: boolean;
  token: string;
  isAlert:boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  variantsData: any;
  tokenApi: string;
  buyerStylish: boolean;
  mondayWeek: {
    start: string,
    end: string,
  },
  satWeek: {
    start: string,
    end: string,
  }, currencySign: string;
  categoryName: string;
  categoryType: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BuyerStoreProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchStoreDetailsCallID: string = "";
  fetchStoreVariantsDetailsCallID: string = "";
  fetchStoreCatalogueCallId: string = "";
  postFavouriteApiCalledId: string  = "";
  deleteFavouriteApiCalledId: string = "";
  fetchStoreDetailsSearchCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      storeList: [],
      circularProgress: false,
      token: "",
      isAlert: false,
      alertType: 'success',
      alertMsg: "",
      variantsData: [],
      buyerStylish: false,
      tokenApi: "",
      mondayWeek: {
        start: "",
        end: "",
      },
      satWeek: {
        start: "",
        end: "",
      },
      currencySign: "$",  
      categoryName: "",
      categoryType: ""
    
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId === this.postFavouriteApiCalledId){
      if(responseJson?.error){
        this.setState({
          isAlert: true,
          alertType: "error",
          alertMsg: responseJson?.error
        })
      }else{
        this.setState({
          isAlert: true,
          alertType: "success",
          alertMsg: `${configJSON.addFavTitle}`
        }, () => this.getbuyerStoreVariants())
      }
    }else if (apiRequestCallId === this.fetchStoreDetailsCallID) {
      if (responseJson?.errors) {
        this.setState({
          isAlert: true,
          alertType: "error",
          alertMsg: Array.isArray(responseJson.errors) && responseJson.errors?.length > 0 ? responseJson.errors[0]?.token : responseJson.errors ,
          circularProgress: false
        });
      }else{
        this.setState(({
          storeList: responseJson?.data,
            circularProgress: false,
        }))
        const mondayweekData = responseJson?.data?.attributes?.store_operating_hours['monday'] && Object.values(this.state.storeList.attributes.store_operating_hours['monday']);
        const satweekData = responseJson?.data?.attributes?.store_operating_hours['saturday'] && Object.values(this.state.storeList.attributes.store_operating_hours['saturday']);
        this.setState({
          mondayWeek: {
            start: mondayweekData[0],
            end: mondayweekData[1]
          },
          satWeek: {
            start: satweekData[0],
            end: satweekData[1]
          }
        })
      }
    } else{
      this.handleResponse(apiRequestCallId, message);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleApiResponse = (apiRequestCallId: string, message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if((apiRequestCallId === this.fetchStoreVariantsDetailsCallID) || (apiRequestCallId === this.fetchStoreDetailsSearchCallID)){
      if(responseJson?.data){
        if(responseJson?.data?.length > 0 && this.state.token){
          this.setState({
            variantsData: responseJson.data, currencySign: responseJson.data[0]?.attributes?.currency_logo
          })
        }
        this.setState({
          variantsData: responseJson.data
        })
      }else if(responseJson?.errors){
        if(Array.isArray(responseJson.errors) && responseJson.errors.length > 0){
          this.setState({
            tokenApi: ""
          }, () => this.getbuyerStoreVariants())
        }
      }
    }
  }
handleResponse = (apiRequestCallId: string, message: Message) => {
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
   if(apiRequestCallId === this.deleteFavouriteApiCalledId){
    if(responseJson?.errors){
      this.setState({
        isAlert: true,
        alertType: "error",
        alertMsg: `${responseJson.errors}`
      }, ()=> this.getbuyerStoreVariants());
    }else{
      this.setState({
        isAlert: true,
        alertType: "success",
        alertMsg: `${configJSON.removeFavTitle}`
      }, ()=> this.getbuyerStoreVariants());
    }
}else if(apiRequestCallId === this.fetchStoreCatalogueCallId){
  const last =  responseJson.data.attributes.sub_category.attributes.name;
  const categoryName = responseJson.data.attributes.category.attributes.name;
  const match = categoryName.match(/^[^\s']+/);
  const firstWord = match ? match[0] : '';
  this.setState({
    categoryName: firstWord,
    categoryType: last
  })
  }else {
    this.handleApiResponse(apiRequestCallId, message);
  }
}

  async componentDidMount() {
    // Customizable Area Start
    const authToken = await getStorageData('auth-token')
    const userDetails = await getStorageData('userRole', true)
    const stylishBuyer = await  getStorageData('exploreBuyer', true);
    if(stylishBuyer){
      this.setState({buyerStylish: stylishBuyer})
    }
    if(userDetails?.currencyLogo){
      this.setState({
        currencySign: userDetails.currencyLogo
      });
    }
    this.getbuyerStores();
    this.setState({
      token: authToken,
      tokenApi: authToken
    }, ()=>{this.getbuyerStoreVariants();
    })

    // Customizable Area End
  }

  navigateToDeatils = ({id,screenName,raiseMessage}: INavigateDetailsPageTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && 
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
};

handleFavouriteApi = (favouriteId: string, fav: boolean) => {
  if (fav) {
    this.handleDeleteWishlist(favouriteId);
  } else {
    this.handleAddWishlistApiCall(favouriteId);
  }
}

headerNavigation = async (pageLink: string, name?: string) => {
  await setStorageData("navigationGender", name)
  const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
  toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
  toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(toNavigate);
};

handleDeleteWishlist = async (favouriteId?: string) => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteFavouriteApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteAPiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deleteFavouriteApi}?favouriteable_id=${favouriteId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

navigateToHome = (navigateLink: string) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), navigateLink);
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}

oncloseAlert = () => {
  this.setState({
    isAlert: false
  })
}
getCurrency = (currency: string) => {
  if(currency === "KWD") return "dinar";
  else  return "dollar"
}
handleAddWishlistApiCall = async (favouriteId?: string) => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.state.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const httpBody = {
    data: {
      favouriteable_id: favouriteId
    }
  };

  this.postFavouriteApiCalledId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.postFavouriteApi
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

  getbuyerStores = async () => {
    const storeId = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({
      circularProgress: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchStoreDetailsCallID = requestMessage.messageId;
    
    const apiRoute =  `${configJSON.getParticularStore}/${storeId}`

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChangeSearch(searchvalue: string) {
    this.getbuyerStoresSearchApi(searchvalue);
  }
  getbuyerStoreVariants = async () => {
    const storeId = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({
      circularProgress: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchStoreVariantsDetailsCallID = requestMessage.messageId;

    const baseApiUrl = this.state.tokenApi 
    ? configJSON.getCatalogueWithTokenApiEndPoint : `${configJSON.getCatalogueWithOutToken}?currency=${this.getCurrency(this.state.currencySign)}`;

const apiRoute = this.state.tokenApi ? `${baseApiUrl}?store_ids[]=${storeId}` : `${baseApiUrl}&store_ids[]=${storeId}`;

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getbuyerStoresSearchApi = async (searchWord: string) => {
    const storeId = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({
      circularProgress: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchStoreDetailsSearchCallID = requestMessage.messageId;
    
    const apiRoute =  `${this.state.tokenApi ? configJSON.getCatalogueWithTokenApiEndPoint : configJSON.getCatalogueWithOutToken}?store_ids[]=${storeId}&search=${searchWord}`

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
