import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  Modal,
  Fade,
  Backdrop
} from "@material-ui/core";
import { createTheme,styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {backgroundImage,callBlue,facebookBlue,appleBlue,googleBlue, view1} from "../src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Visibility from "@material-ui/icons/Visibility";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { configJSON } from "./EmailAccountLoginController.web";
import i18n from "../../../components/src/i18next/i18n";
import GoogleButton from "../../../components/src/Googlelogin/GoogleButton";
import OtpInput from "react-otp-input";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      // Required for all blocks
      <>
        <Snackbar
                  open={this.state.login_page}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  data-test-id="alertTestId"
                  dir={i18n.dir()}
                >
                  <Alert severity={this.state.alertTyperoute}>{'Please sign in first'}</Alert>
                </Snackbar>
      <Header navigation={this.props.navigation}/>
      <MainGrid data-test-id="mainGridContainer" container dir={i18n.dir()}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
          <Box className="sideImageBox">
            <img src={backgroundImage} alt="" className="sideImage" />
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
          <Box className={"formBox"}>
            <form data-test-id="formSubmitted" onSubmit={this.handleSumitlogin} style={{width:"80%"}}>
             <Box width={"100%"}>
              <Box style={{display: "flex", gap: "10px"}}>
                <Box style={{height: "24px", width: "24px"}} data-test-id="backID" onClick={this.getnavigationToASplashScreen}>
                  <img src={view1} alt="viewIcon" style={{height: "100%", width: "100%", transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'}}/>
                </Box>
              <Typography className={"welcomeText"}>{this.transLogin(configJSON.welcomeBack)}</Typography>
              </Box>
              <Typography className={"welcomeSubText"}>
                {this.transLogin(configJSON.loginStyle)}
              </Typography>
              <Typography className={"inputHeading"}>{this.transLogin(configJSON.emailAddress)}</Typography>
                <TextField
                  data-test-id="emailTextInput"
                  fullWidth
                  variant="outlined"
                  className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                  placeholder={this.transLogin(configJSON.emailPlace)}
                  value={this.state.isEmail}
                  onChange={this.handleEmail}
                  error={this.state.errorsMessage.errorEmail}
                  helperText={this.state.errorsMessage.errorEmail ? this.state.errorsMessage.emailMessage : ""}
                />

              <Typography className={"inputHeading"}>{this.transLogin(configJSON.placeHolderPassword)}</Typography>
              <TextField
                data-test-id="passwordTextInput"
                type={this.state.showPasswordValue ? 'text' : 'password'}
                className={this.state.passError?"inputFeild2Error2":"inputFeild2"}
                value={this.state.passwords}
                onChange={this.handlePassword}
                placeholder={this.transLogin(configJSON.passPlace)}
                error={this.state.errorsMessage.errorPassword}
                helperText={this.state.errorsMessage.errorPassword ? this.state.errorsMessage.passwordMessage : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleTogglePassword}
                        edge="end"
                      >
                        {this.state.showPasswordValue ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
              />
              <Typography align="right"  data-test-id="forgotPassNavigation" onClick={this.opeForgotPasswordScreen}  className="forgotPassword">
                {this.transLogin(configJSON.forgotPassword)}
              </Typography>
              <Button
                variant="contained"
                className={"loginUserButton"}
                disableElevation
                type="submit"
              >
                {this.transLogin(configJSON.labelTitle)}
              </Button>
                <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  data-test-id="alertTestingId"
                  dir={i18n.dir()}
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
              </Box>
              <Typography className={"continueText"} align="center">
              {this.transLogin(configJSON.continueWith)}
            </Typography>
            </form>
            <Box className="socialLogin">
            {(this.state.userType == "1" || this.state.userType === "seller" || this.state.userType == "3" || this.state.userType === "stylist" ) ? (
                <Button
                variant="contained"
                className={"loginUserButton1"}
                disableElevation
                data-test-id="mobileNavigation" onClick={this.openMobileScreen}
              >
                <Box style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 10}} >
                <img src={callBlue} alt="call"  style={{width: "24px", height: "24px"}} />
               <Typography style={{color: "#375280", fontFamily: "Lato", fontSize: "16px", fontWeight: 600}}>{this.transLogin("Log In with Phone")}</Typography>
                </Box>
              </Button>
              ): (
                <>
              <img src={callBlue} alt="call" data-test-id="mobileNavigation" onClick={this.openMobileScreen} className="socialLoginImage" />
              <img src={facebookBlue} alt="Facebook" className="socialLoginImage" />
              <img src={appleBlue} alt="Apple" className="socialLoginImage" />
              <GoogleButton data-test-id="google_login"  onSuccess={this.googleLoginApi}  />
                </>
              )}
            </Box>
            <Box className={"Register"}>
              <Typography className={"bottomText"} align="center">
                {this.transLogin(configJSON.msgAlert)}
              </Typography>
              <Typography  data-test-id="userNext" className={"registerButton"} onClick={this.openRegistrationScreen}>
                {this.transLogin(configJSON.registerHere)}
              </Typography>
            </Box>
          </Box>
        </Grid>
          <StyledModal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.emailAndOtpVerify}
            onClose={this.handleEmailAndOtpVerifyClose}
            closeAfterTransition
            data-test-id="transition"
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            dir={i18n.dir()}
          >
            <Fade in={this.state.emailAndOtpVerify} style={{ borderRadius: '10px', maxWidth: "390px", backgroundColor: "white" }}>
              <MainContainer  >
                <Box className={"formBox"}>
                  <Box data-test-id="formSubmit" style={{ width: "100%" }}>
                    <Box className={"headBox"}>
                      <Typography dir={i18n.dir() === 'rtl' ? 'ltr' : 'rtl'} className={"headText"}>
                        {this.transLogin(configJSON.otpVerification)}</Typography>
                    </Box>
                    <Typography dir={i18n.dir() === 'rtl' ? 'ltr' : 'rtl'} data-test-id="maskEmail" className={"headSubText"}>
                      {this.transLogin(configJSON.sentTo)} <br />
                      {this.transLogin(configJSON.smsText)}
                      {this.maskPhone()}
                    </Typography>
                    <Box dir={i18n.dir()} className="otpBox">
                      <OtpMainContainer  >
                        <OtpInput
                          data-test-id="otpInputID"
                          onChange={this.handleOTPChange}
                          value={this.state.otpInput}
                          numInputs={4}
                          containerStyle={{ gap: "15px" }}
                          inputStyle={{
                            width: "50px",
                            height: "50px",
                            border: "none",
                            background: "#F0F0F0",
                            margin: "0px 3px",
                            color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)",
                            fontSize: "25px",
                            textAlign: "center",
                            outline: "none"
                          }}
                          renderInput={(props) => (
                            <input
                              {...props}
                              type="text"
                              onKeyDown={(event) => { this.handleKeyDown(event) }}
                            />
                          )}
                        />
                        {this.state.errorOtpMsg && (
                          <Box className={"otpMsgBox"}>
                            {this.state.errorOtpMsg}
                          </Box>
                        )}
                      </OtpMainContainer>
                    </Box>
                    <Box dir={i18n.dir()} className={"textHeading"}>
                      <Typography className={"bottomText"} >
                        <Typography className={"reciveText"}>{this.transLogin(configJSON.reciveCodeText)} </Typography>
                        <Typography data-test-id="resend-otp"
                          onClick={()=>this.handleResendSMSOTP()}
                        >
                          &nbsp;{this.transLogin(configJSON.resendCode)}
                        </Typography>
                      </Typography>
                      <Button
                        variant="contained"
                        className={"LoginUserButton"}
                        disableElevation
                        data-test-id="handleSaveSubmit"
                        onClick={() => this.handleSubmitOtp()}
                      >
                        {this.transLogin(configJSON.verifyAccount)}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </MainContainer>

            </Fade>
          </StyledModal>
      </MainGrid>
      <Footer navigation={this.props.navigation}/>
    </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const MainGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  "& .sideImageBox": {
    width: "100%",
    height: "100%",
  },
  "& .sideImage": {
    width: "100%",
    height: "100%",
  },
  "& .imageGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .welcomeSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px",
    alignSelf: "flex-start",
    margin:"10px 0px 0px 0px"
  },
  "& .inputFeild": {
    gap: "4px",
    border: "none",
    borderRadius: "0px"
  },
  "& .formGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding:"7%"
  },
  "& .phoneInput": { display: "flex", width: "520px" },
  "& .formBox": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width:"100%",
    marginTop: "30px"
  },
  "& .welcomeText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir,sans-serif",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    alignSelf: "flex-start",
  },
  "& .inputHeading": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "20px 10px 10px 0px",
    textOverflow: "ellipsis",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },
  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px"
  },
  "& .loginUserButton": {
    textTransform:"capitalize",
    width: "100% !important",
    borderRadius: "0px !important",
    color: "white !important",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    gap: "8px",
    height: "56px !important",
    "& .MuiButton-label":{
      fontWeight:600,
      fontSize:"16px"
  }
  },
  "& .loginUserButton1": {
    textTransform:"capitalize",
    width: "100% !important",
    borderRadius: "0px !important",
    color: "white !important",
    backgroundColor: "#FFFFFF !important",
    gap: "8px",
    border: "1px solid #CCBEB1",
    height: "56px !important",
    "& .MuiButton-label":{
      fontWeight:600,
      fontSize:"16px"
  }},
  "& .terms": {
    color: "#979797 !important",
    margin: "10px 10px 10px 10px !important",
    width: "520px !important",

    fontFamily: "Lato ,sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },
  "& .condition": {
    color: "var(--Neutrals-Cool-gray-500, #375280)",
    cursor: "pointer",
  },
  "& .inputFeild2Error2":{
    "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
        border:"none"
    },
    "& .MuiFormHelperText-contained":{
        margin:"0 !important",
        textAlign: i18n.dir() === "rtl" ? "right": "left"
    },
    '& .MuiInputBase-input': {
        color: "#375280"
    },
},
"& .inputFeild2": {
    "& .MuiTextField-root":{
        "&:hover":{
            border: "1px solid #F0F0F0 !important",
        },
    },
    "& .MuiOutlinedInput-root:hover" :{
        border: "none !important",
      },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      
  },
    "& .MuiFormHelperText-contained":{
        margin:"0 !important"
    },
    '& .MuiInputBase-input': {
        color: "#375280"
    },
       "& .MuiOutlinedInput-notchedOutline":{
        borderColor: "#F0F0F0",
    }
},
  "& .Register": {
    padding: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .socialLogin": {
    padding: "20px",
    width: "80%",
    display: "flex",
    gap:"35px",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .registerButton": {
    cursor: "pointer",
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    margin: "10px 10px 0 10px",

    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 700,
    lineHeight: "24px !important",
  },
  "& .bottomText": {
    color: "#375280 !important",

    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },
  "& .continueText": {
    width:"100%",
    color: "#375280 !important",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    display: "flex",
    marginTop:"15px",
    flexDirection: "row",
    "&:before, &:after":{
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto"
    },
    "&:before":{
        marginRight: "25px"
    },
    "&:after": {
        marginLeft: "25px"
    }
},
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px", flexWrap: "wrap" },
    "& .Register": {
      width: "350px !important",
    },
    "& .socialLogin": {
      width: "80% !important",
    },
    "& .terms": {
      width: "350px !important",
    },
    "& .loginUserButton": {
      width: "100% !important",
      "& .MuiButton-label":{
        fontWeight:600,
        fontSize:"16px"
    }
    },
    "& .phoneInput": { width: "370px" },
  "& .continueText": {
    width:"100%",
    color: "#375280 !important",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    display: "flex",
    marginTop:"15px",
    flexDirection: "row",
    "&:before, &:after":{
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto"
    },
    "&:before":{
        marginRight: "25px"
    },
    "&:after": {
        marginLeft: "25px"
    }
},
  },
});

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: "none",
}));

const MainContainer = styled(Box)({
  "& .formBox": {
      padding: "20px", alignItems: "center", justifyContent: "center",
      display: "flex",
      gap: 20, flexDirection: "column",
  },
  "& .headBox": {
      textAlign: "center",
      width: "100%",
  },
  "& .LoginUserButton": {
      color: "white ", backgroundColor: "var(--Primary-Purple-500, #CCBEB1) ",
      gap: "8px",
      width: "100% ", borderRadius: "0px",
      height: "56px ", textTransform: 'none',
      fontSize: "19px",
      fontWeight: 600, margin: "15px 0px 0px 0px"
  },

  "& .headText": {
      textOverflow: "ellipsis", src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
      color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
      fontFamily: "Avenir,sans-serif",
      lineHeight: "26px", alignSelf: "center",
      fontSize: "22px",
      fontStyle: "normal", fontWeight: 800,
  },
  "& .headSubText": {
      src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
      fontFamily: "Avenir,sans-serif", fontSize: "16px",
      color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))", textOverflow: "ellipsis",
      lineHeight: "26px",
      fontStyle: "normal", fontWeight: 700,
      alignSelf: "center",
  },
  "& .otpBox": {
      alignItems: "center",
      padding: "10px 0px",
      display: "flex",
      justifyContent: "center",
      gap: 15,
  },
  "& .reciveText": {
      color: "var(--Primary-Purple-500, #CCBEB1)"
  },
  "& .bottomText": {
      display: "flex", 
      justifyContent:'center',
      color: "#375280 ",
      cursor: "pointer", fontFamily: "Lato,sans-serif", fontSize: "16px", fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
  },
  "& .otpMsgBox": {
      color: "red", margin: "11px 12% 0px 0px",
      fontWeight: 300, fontFamily: "Avenir, sans-serif",
      width: "100%",
  },
  "& .textHeading": {
      textAlign: "center"
  },



});

const OtpMainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 5px 10px",
});

// Customizable Area End
