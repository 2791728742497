import React from "react";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { complate, isActive, notActivs } from "../../blocks/ordermanagement/src/assets";
import { makeStyles } from "@material-ui/core/styles";

const useColorlibStepIconStyles = makeStyles({
    root: {
      zIndex: 1,
      color: "#fff",
      width: 25,
      height: 25,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${notActivs})`,
      backgroundPosition:"center",
      backgroundRepeat:"no-repeat",
      padding: 1,
    },
    active: {
      backgroundImage: `url(${isActive})`,
      backgroundPosition:"center",
      backgroundRepeat:"no-repeat",
      padding: 1,
    },
    completed: {
      backgroundImage: `url(${complate})`,
      backgroundPosition:"center",
      backgroundRepeat:"no-repeat"
    },
  });

export const ColorlibStepIcon = (props: StepIconProps) => {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
  
    let className = classes.root;
  
    if (active) {
      className += ` ${classes.active}`;
    }
    if (completed) {
      className += ` ${classes.completed}`;
    }
  
    return (
      <div className={className}>
      </div>
    );
  }