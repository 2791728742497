import React from "react";
// Customizable Area Start
import {
  Typography, Box, Grid, TextField, InputAdornment, Card,  Divider,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  FormControlLabel,
  withStyles,
  WithStyles,
  InputBase,
	styled,
	Checkbox
} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import Header from "../../../components/src/Header";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { noImage } from "../../landingpage/src/assets";
import { search } from "../../email-account-registration/src/assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import clsx from 'clsx';
import Footer from "../../../components/src/Footer";
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
// Customizable Area End

import ProductSearchController, { Props, configJSON } from "./ProductSearchController";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";

export default class ProductSearch extends ProductSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderDiscountedDiv = (discounted: number) =>(
    <Box style={{...webStyles.productDiscount1, backgroundColor : discounted > 0 ?  "#FFFFFF" : "transparent"}}>
        {discounted > 0 && 
        `${discounted}% Off`}
    </Box>)

  render() {
    // Customizable Area Start
    return (
        <>
        {this.state.buyerStylish ? <StylishBuyerHeader navigation={this.props.navigation} />: <Header navigation={this.props.navigation} />}
        <Snackbar
                    open={this.state.isAlert} 
                     autoHideDuration={3000}
                    anchorOrigin={{ horizontal:  'right', vertical: 'top' }}       
                     onClose={this.oncloseAlert} 
                    data-test-id="alertTestId" 
                    >
                      <Alert severity={this.state.alertType}>
                        {this.state.alertMsg}</Alert> 
                    </Snackbar>
                    <Box>
                    <Box style={{
                        maxHeight: "680px",
                        overflowY: "scroll"
                    }} data-testid="handleScroll" id="scrollableDiv">
                    <Grid container
                    spacing={0}
                        style={webStyles.heraderText}>	
                        <FullBoxWidth container >
                        <Grid container spacing={4}>
                         
                             <Grid item xs={12} sm={3} style={{ height: 'fit-content' }} id="scrollId">
                            <Box style={{ background: '#ffffff', height: '100%' }}>
															<ClearBox>
                                <Box
                                    sx={{ px: 1, py: 2 }}
                                    style={webStyles.filterData}
                                >
                                    <Typography variant="body2" style={webStyles.allFilterFont}>
                                        Filter
                                    </Typography>
                                </Box>
															 		<Box>
																		<Box style={{padding: "10px", backgroundColor: "#CCBEB1", borderRadius: "4px"}} data-test-id="handleClear" onClick={this.handleClearAll}>
																			<Typography style={{ color: '#FFFFFF', cursor: "pointer", fontSize: '18px', fontWeight: 700, fontFamily: "Lato"}}>Clear All</Typography></Box>
																	</Box>
															</ClearBox>
                                <Divider style={webStyles.allDivider} />
                                <Box>
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '0px 15px' }} id="genderCat1">
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyles.expandMoreIcon} />}>
                                                    <Typography id="genderCatTypo1" style={webStyles.allFilterFont}>Category</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider id="genderCatDevider1"  />
                                            <AccordionDetails id="genderCatAccoDetails1" >
                                                <Box style={{ flexDirection: 'column', padding: '0px 15px' }}>
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label="Men"
                                                        value={"Men"}
                                                        checked={this.state.gender === 'Men'}
                                                        onChange={this.handleGenderChange}
                                                        style={webStyles.radioName}
                                                        data-testid="MenRadio"
                                                    />
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label="Women"
                                                        value={"Women"}
                                                        checked={this.state.gender === 'Women'}
                                                        onChange={this.handleGenderChange}
                                                        style={webStyles.radioName}
                                                        data-testid="WomenRadio"

                                                    />
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label="Kids"
                                                        value={"Kids"}
                                                        checked={this.state.gender === 'Kids'}
                                                        onChange={this.handleGenderChange}
                                                        style={webStyles.radioName}
                                                    />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
                                <Divider style={webStyles.allDivider} />

																<Box>
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '0px 15px' }} id="genderCat1">
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyles.expandMoreIcon} />}>
                                                    <Typography id="genderCatTypo1" style={webStyles.allFilterFont}>Sort</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider id="genderCatDevider1"  />
                                            <AccordionDetails id="genderCatAccoDetails1" >
                                                <Box style={{ display: "flex", flexDirection: 'column', padding: '0px 15px', justifyContent: "center" }}>
																									{[{label: "Most Recent", value: "most_recent"}, {label: "Oldest First", value: "oldest_first"}, {label: "Price Low to High", value: "price_asc"}, {label: "Price High to Low", value: "price_desc"}, {label: "Popularity", value: "popularity"}].map((element)=> (
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label={element.label}
                                                        value={element.value}
                                                        checked={this.state.filters.sort === element.value}
                                                        onChange={() => this.handleSortRadioChange(element.value)}
                                                        style={webStyles.radioName}
                                                        data-test-id={element.value}
                                                    />
																									))}

                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
                                <Divider style={webStyles.allDivider} />
                                <Box id="genderCatSize1" >
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box id="genderCatBoxText1" style={{ padding: '0px 15px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyles.expandMoreIcon} />}>
                                                    <Typography id="genderCatsize1" style={webStyles.allFilterFont}>Size</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider />
                                            <AccordionDetails id="genderCatAccoDetails2">
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    style={{ ...webStyles.dummeyData, flexWrap: "wrap" }}
                                                >
                                                    {this.state.dummySizes.map((size, index: number) => {
                                                        return (
                                                            <Box key={index} data-testid={`handleSizeClick-${size.attributes.id}`} 
                                                            onClick={() => this.handleSizeClick(size.attributes.id)}
                                                             style={{ ...webStyles.dummeySize, border: this.handleCondition(!this.state.dummySizesPush.includes(size.attributes.id) , "1px solid rgba(190, 188, 189, 1)" , "3px solid #375280"), fontWeight: this.handleCondition(!this.state.dummySizesPush.includes(size.attributes.id) , 500 , 700) }}>
                                                                {size.attributes.name}</Box>
                                                        )
                                                    })}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
                                <Divider style={webStyles.allDivider} />
                                <Box>
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '0px 15px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyles.expandMoreIcon} />}>
                                                    <Typography style={webStyles.allFilterFont}>Price Range</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider />
                                            <AccordionDetails>
                                                <Box style={{ gap: "15px", width: '100%', padding: '0px 15px', display: "flex", justifyContent: 'space-between' }}>
                                                    <InputBase
                                                        style={webStyles.priceBox}
                                                        value={`${this.state.currencySign} ${this.state.value[0]}`}
                                                        placeholder="Min Price"
                                                        onChange={(event) => this.handleChange(event, 0)}
                                                        inputComponent={(props) => <input {...props} style={{ textAlign: 'center', width: '100%' }} />}
                                                        data-testid
                                                        ="number1"
                                                    />
                                                    <InputBase
                                                        style={webStyles.priceBox}
                                                        value={`${this.state.currencySign} ${this.state.value[1]}`}
                                                        placeholder="Max Price"
                                                        inputComponent={(props) => <input {...props} style={{ textAlign: 'center', width: '100%' }} />}
                                                        onChange={(event) => this.handleChange(event, 1)}
                                                        data-testid
                                                        ="number2"
                                                    />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
                                <Divider style={webStyles.allDivider} />
                                <Box className="colore">
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '0px 15px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyles.expandMoreIcon} />}>
                                                    <Typography style={webStyles.allFilterFont}>Colors</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider />
                                            <AccordionDetails>
                                                <Box style={{ ...webStyles.colorBox, flexWrap: "wrap", gap: "14px", }}>
                                                    {this.state.filterColor?.length > 0 && this.state.filterColor.map((color, index: number) => {
                                                        return (
                                                            <>
                                                                <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }} data-testid={`handleColorClick-${color.attributes.id}`} 
                                                                onClick={() => this.handleColorClick(color.attributes.id)}
                                                                >
                                                                    <Box style={{ width: "36.73px", height: "36.73px", backgroundColor: color.attributes.name, borderRadius: "3px", marginBottom: '18px', border: this.handleCondition(this.state.colorePush.includes(color.attributes.id) , "3px solid #375280" ,"1px solid #375280") }} />
                                                                    <Typography key={index} style={{ width: "80px", textAlign: 'center', color: "#375280" }} data-testid="colorname">{color.attributes.name}</Typography>
                                                                </Box>
                                                            </>
                                                        );
                                                    })}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
																<Divider style={webStyles.allDivider} />
                                <Box className="colore">
                                    <Box style={{ overflow: "hidden" }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '0px 15px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon style={webStyles.expandMoreIcon} />}>
                                                    <Typography style={webStyles.allFilterFont}>Stores</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <Divider />
                                            <AccordionDetails>
                                                <Box style={{ ...webStyles.colorBox, flexWrap: "wrap", gap: "14px", height: "350px", overflowY: "auto"}}>
																								{this.state.storesData?.length > 0 && this.state.storesData.map((store, index) => {
                                                        return (
                                                            <Box style={{width: "100%", display: "flex", alignItems: "center", gap: 10}}>
																															<StyledCheckbox
    																													  checked={this.state.storeCheck.includes(store?.id)}
    																													  data-test-id={`selectAllClick-${index}`}
    																													  style={{color: this.handleCondition(this.state.storeCheck.includes(store?.id) , "#CCBEB1" ,""), padding: "0px"}}
    																													  onChange={() => this.handleSelectAll(store.id)}
    																													  inputProps={{ 'aria-label': 'checkbox' }}
    																													  icon={<CheckBoxOutlineBlankSharpIcon fontSize="large" style={{color: "#CCBEB1"}} />}
    																													/>
																															<Typography style={{fontFamily: "Lato", fontSize: "18px", fontWeight: 700, color: "#375280", textTransform: "uppercase"}}>{store?.attributes?.store_name}</Typography>
																														</Box>
                                                        );
                                                    })}
																										{this.state.buyerStoreLoading && (
																											<Box style={{ width: '100%', textAlign: "center",
																				                paddingBottom: '20px'}}><CircularProgress style={{ color: '#375280' }} /></Box>
																										)}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
												<Grid item xs={12} sm={9} style={{marginTop: "12px"}}>
												<Grid container>
												<TextField
                               variant="outlined"
                               fullWidth
                               placeholder={"Search"}
                               className="searchBox"
                               data-testid="searchBox"
                               value={this.state.search}
                               onChange={(event) => this.changeSearch(event.target.value)}
                               InputProps={{
                                   startAdornment: (
                                       <InputAdornment position="start">
                                           <img src={search} className="searchBoxIcon" />
                                       </InputAdornment>
                                   ),
                                   style: {
                                       display: "flex",
                                       width: "100%",
                                       height: "60px",
                                       justifyContent: "flex-start",
                                       borderRadius: "2px",
                                       marginBottom: "20px"
                                   },
                               }}
                           />
                            {this.state.advancedSearchData &&  this.state.advancedSearchData?.length >0 && 
                                this.state.advancedSearchData.map((cardDetail, index) => {
                                    return(
                                    <Grid item xl={4} lg={4} md={12} sm={12} key={index} style={webStyles.trendingGrid}>
                                        <StyledCard data-testId="trendingCart" style={{cursor:'pointer'}} >
                                            <Box style={{ position: 'relative' }} data-test-id="navigateToProductdes" onClick={() => this.navigateTo({ id: cardDetail.id, props: this.props, screenName: "Productdescription3" })}>
                                                <img
                                                    src={cardDetail.attributes.primary_image === null ? noImage : cardDetail.attributes.primary_image}
                                                    alt={`Image ${index + 1}`}
                                                    style={webStyles.imgTag}
                                                    data-test-id = {`navigateToDetail-${index}`}
                                                />
                                                <Box style={webStyles.productIcon1 }>
                                                    {this.renderDiscountedDiv(cardDetail.attributes.primary_discounted_percentage)}
                                                    <Box style={{...webStyles.productFavorite1,cursor:'pointer'}} data-test-id={`handleLikeClick-${index}`} 
                                                    onClick={(event)=>this.handleFavouriteApi(event,cardDetail.id, cardDetail.attributes.is_wishlist)}
                                                     >
                                                        {cardDetail.attributes.is_wishlist ? (
                                                   <FavoriteIcon width="18px" height="18px" style={{ fill: 'blue' }}/>
                                                   ):(<FavoriteBorderIcon color="secondary"  style={{ fill: 'blue' }}/>)
                                                       }</Box>

                                                </Box>
                                            </Box>
                                            <Box style={{ padding: '24px' }}>
                                                <Box style={webStyles.productData1}>
                                                    <Box style={webStyles.productValue1}>
                                                        <Typography style={webStyles.productName1} >{cardDetail.attributes.name}</Typography>
                                                        <Typography style={webStyles.productDescription1} >{cardDetail.attributes.description}</Typography>
                                                    </Box>
                                                    <Box style={{ ...webStyles.acountMrp1, textAlign: 'center', width: "85px" }}>{cardDetail.attributes.primary_discounted_percentage > 0 ? (
                                                        <>
                                                            <Typography id="actual-prod-search-price">
                                                            <del>{`${this.state.currencySign} ${cardDetail.attributes.primary_main_price}`}</del>
                                                            </Typography>
                                                            <Typography id="discounted-prod-search-list-price" style={webStyles.actualPrice}>{`${this.state.currencySign} ${cardDetail.attributes.primary_price}`}</Typography>
                                                        </>
                                                        ) : (
                                                            <Typography id="primary-prod-search-list-price" style={webStyles.actualPrice}>{`${this.state.currencySign} ${cardDetail.attributes.primary_main_price}`}</Typography>
                                                        )}</Box>
                                                </Box>
                                            </Box>
                                        </StyledCard>
                                    </Grid>
                                )})}

																{this.state.advancedSearchData?.length === 0 && !this.state.circularProgress && (
																	<Box style={webStyles.errorMessageCotainerTxt as React.CSSProperties}>
																		<Typography style={{fontFamily: "Lato", fontSize: "24px", fontWeight: 700, color: "#375280"}}>No Products Found</Typography>
																		<Typography style={{fontFamily: "Lato", fontSize: "20px", fontWeight: 500, color: "#375280"}}>We couldn't find any products matching your search criteria. Please try adjusting your filters or browse our categories to find what you're looking for.</Typography>
																	</Box>
																)}
																	</Grid>

                                        {this.state.circularProgress && <Box style={{ width: '100%', textAlign: "center",
																				 paddingBottom: '20px'}}><CircularProgress style={{ color: '#375280' }} /></Box>}

														{ this.state.favouriteLoading && <Box style={{ width: '100%', textAlign: "center", position: "absolute", top: "50%",left: "5%",
																			 paddingBottom: '20px'}}><CircularProgress style={{ color: '#375280' }} /></Box>}
												</Grid>
                        </Grid>
                        </FullBoxWidth>
                    </Grid>
                        
                        </Box> 
                    <Box>
                           <Footer navigation={this.props.navigation}/>
                        </Box>
                    </Box>
            {this.state.modalOpen && this.renderDialog()}

        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
    productName1: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", whiteSpace: 'nowrap' as 'nowrap',overflow: "hidden", width: '90%', fontFamily: "Lato" },
    filterData: { display: 'flex', justifyContent: 'space-between', padding: '21px 30px' },
    allSelectColor: { backgroundColor: '#ffffff', borderRadius: '0px' },
    colorBox: { display: "flex", gap: "15px", width: "100%", padding: '0px 15px' },
    acountMrp1: { fontFamily: "Lato", display: "flex", alignItems: "center", flexDirection: "column-reverse" as "column-reverse", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', border: "1px solid #D5D5D5", padding: '10px 10.5px', borderRadius: '8px', width: '88px' },
    actualPrice: { fontFamily: "Lato", color: '#375280', fontSize: '18px', fontWeight: 700},
    sortedBox: { display: "flex", width: "100%" },
    heraderText:{  background: "#FFF", justifyContent: "center", alignItems: "center" },
    productValue1: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto',whiteSpace: 'nowrap' as 'nowrap', },
      productDescription1: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500,whiteSpace: 'nowrap' as 'nowrap', textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
      dummeySize: { cursor: "pointer", color: "#375280", borderRadius: "4px", padding: "6px", width: "65px", height: "32px", marginRight: "20px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", fontWeight: 400, fontFamily: "Lato" },
    gridConter:{ padding: "30px",width:"85%"},
    trendingGrid:{ display: 'flex', justifyContent: 'center', marginBottom: '20px', flexDirection: "column" as "column" },
    productDiscount1: { color: "#375280", fontSize: '12px', padding: '2px 0px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
    productIcon1: { top: '20px', left: '20px', display: "flex", justifyContent: 'space-between', right: '0',position: 'absolute' as 'absolute' },
    allDivider: { color: '#D5D5D5' },
    dummeyData: { display: "flex", alignItems: "center", justifyContent: "center", gap: "15px", width: "100%" },
    priceBox: { border: "1px solid #cbc9ca", color: "#375280", borderRadius: "8px", padding: "5px 15px", width: "102px", height: "32px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "15px", fontWeight: 400, fontFamily: "Lato" },
    imgTag:{
        width: "100%",
        height: "82%",
        position: 'relative' as 'relative', display: 'block', margin: 'auto'
    },
    radioName: { color: "rgba(55, 82, 128, 1)", fontSize: "16px", fontWeight: 700, fontFamily: "Lato" },
    productData1: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', 
    textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' ,whiteSpace: 'nowrap' as 'nowrap', flexDirection: 'row' as 'row' },
    cardContainer: {boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", maxWidth: "340px", height: "455px",backgroundColor:"#f2efef" },
    productFavorite1: { color: "#375280", marginRight: '30px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
    allFilterFont: { color: '#375280', cursor: "pointer", fontSize: '22px', fontWeight: 700, fontFamily: "Lato" },
    expandMoreIcon: { color: "#807D7E", fontSize: '30px' },
    errorMessageCotainerTxt: {
        display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center", width: "100%", paddingTop: "100px"
    }
  };

  const StyledCard = styled(Card)({
    boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", maxWidth: "340px", height: "455px",backgroundColor:"#f2efef",

    '@media  (min-width: 1540px) and (max-width: 1750px)': {
        width: "300px"
    },
    '@media  (min-width: 1270px) and (max-width: 1538px)': {
        width: "250px",
    }
  });

	const StyledCheckbox = styled(Checkbox)({
		"& .MuiCheckbox-root":{
			color:"#CCBEB1",
		},
	
		'& .MuiSvgIcon-root': {
			width: "28px",
			height: "28px",
		}
	});
    const FullBoxWidth = styled(Grid)({
        padding: "0px 100px", 
        '@media  (min-width: 700px) and (max-width: 1200px)': {
            padding: "0px 10px !important"
        },
        '@media (max-width: 600px)': {
            padding: "0px 10px !important"
    
        },
        '@media (max-width: 1380px)': {
            padding: "0px 10px !important"
        },
    })
    const ClearBox = styled(Box)({
        display: "flex", justifyContent: "space-between", alignItems: "center", 
        '@media (max-width: 1000px)': {
            display: "grid",
            marginBottom: "27px",
            justifyContent: "center"
    
        },
    })
  const styles = {
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 20,
         height: 20,
        border: '1px solid #CCBEB1',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:disabled ~ &': {
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#cbbeb2',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
    },
};
  const StyledRadioWithStyles = withStyles(styles)((props: WithStyles<typeof styles>) => (
    <Radio
        className={props.classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(props.classes.icon, props.classes.checkedIcon)} />}
        icon={<span className={props.classes.icon} />}
        {...props}
    />
));
// Customizable Area End
