import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import dayjs from "dayjs";
import { FormError, FormErrorTouched } from "../../customform/src/CreateSellerStoreController";
import { getStorageData } from "framework/src/Utilities";
interface ActivityType {
  mainHeading: string,
  spanHeading: string,
  name: string,
  date: string
};

export const step3Schema = Yup.object().shape({
  monFrom: Yup.date()
    .required("Mon From Time is required")
    .typeError("Invalid time format").nullable(),
  monTo: Yup.string()
    .required("Mon To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Mon To Time must be after Mon Time From",
      function (value) {
        const { monFrom } = this.parent;
        if (!monFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(monFrom));
      }
    ).nullable(),
});

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  myActivityData: ActivityType[];
  isFilter: boolean;
  filter: string;
  step3: {
    monFrom: null | string,
    monTo: null | string,
  },
  step3Response: {
    monFrom: null | string,
    monTo: null | string,
  };
  userRole: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyActivityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];
    
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      myActivityData: [{
        mainHeading: "Order #4564-4512",
        spanHeading: " was accepted",
        name: "Black Leather Boots (Women)",
        date: "Jan 10, 2023 "
      }, {
        mainHeading: "Order #4564-4512",
        spanHeading: " was deleted",
        name: "White Oversized T-shirt (Women)",
        date: "Feb 20,2023 "
      }, {
        mainHeading: "Order #4564-4512",
        spanHeading: " was edited",
        name: "Fossil Analog Watch With Steal Belt (Men)",
        date: "Mar 11, 2023"
      }],
      isFilter: false,
      filter: "week",
      step3: {
        monFrom: null,
        monTo: null,
      },
      step3Response: {
        monFrom: null ,
        monTo: null ,
      },
      userRole: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 

  componentDidMount = async () => {
    const userData = await getStorageData('userRole', true);
    this.setState({
      userRole: userData.userType
    });
  };
  handleFilterClick = () => {
    this.setState({
      isFilter: !this.state.isFilter
    });
  };

  parseValidFormate = (timeString: string | null) => {
    return dayjs(timeString).format('HH:MM')
  }

  valueStep3 = (timeValue: string | null) =>{
    return timeValue ? timeValue : null
  }

  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const helperText: string = isError ? errors[field] ?? "" : "";
    return { isError, helperText };
  };

  handleSubmit3 = (values: {
    monFrom: string| null,
    monTo: string| null
  }) => {
    this.setState((prevState) => ({
      ...prevState,
      step3Response:{
        ...prevState.step3Response,
        monFrom: this.parseValidFormate(values.monFrom),
        monTo: this.parseValidFormate(values.monTo),
      },
      step3:{
        ...prevState.step3,
        monFrom: values.monFrom,
        monTo: values.monTo,
      }
    }));
  };

  handleChange = (value: string) => {
    this.setState({
      filter: value
    });
  }
  // Customizable Area End
}
