import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  Plugin,
} from "chart.js";
import { ChartData } from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface BarChartProps {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
  barThickness?: number;
  xYTick?: string;
  xTickColor: string;
  yTickColor: string;
}

const BarChart: React.FC<BarChartProps> = ({
  labels,
  datasets,
  barThickness,
  xYTick,
  xTickColor,
  yTickColor,
}) => {
  const data: ChartData<"bar"> = {
    labels: labels,
    datasets: datasets.map((dataset) => ({
      ...dataset,
      barThickness: barThickness || "flex",
    })),
  };

  const customPlugin: Plugin<"bar"> = {
    id: "customUSDLabel",
    afterDraw: (chart) => {
      const { ctx, chartArea } = chart;
      ctx.save();
      ctx.font = "13px";
      ctx.fillStyle = "#375280";
      const xPosition = chartArea.left - 35; // Adjust for desired position
      const yPosition = chartArea.bottom + 22; // Adjust for desired position
      ctx.translate(xPosition, yPosition);
      ctx.fillText(xYTick ? xYTick : "", 0, 0);
      ctx.restore();
    },
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Bar Chart",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += context.raw;
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: xTickColor,
          font: {
            size: 13,
            weight: "bold",
          },
          maxRotation: 0,
          minRotation: 0,
          autoSkip: window.innerWidth < 768,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: yTickColor,
          font: {
            size: 13,
            weight: "bold",
          },
          precision: 0,
        },
      },
    },
  };

  return (
    <div style={{ overflowX: "auto", minWidth: "360px" }}>
      <Bar data={data} options={options} plugins={[customPlugin]} />
    </div>
  );
};

export default BarChart;
