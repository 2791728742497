export const enPayment = {
    "Home": "Home",
    "Payment History": "Payment History",
    "Welcome to your Account": "Welcome to your Account",
    "Completed": "Completed",
    "Refunded": "Refunded",
    "Pending": "Pending",
    "VIEW": "VIEW",
    "DELETE": "DELETE",
    "Download Receipt": "Download Receipt",
    "Transaction": "Transaction",
    "Transaction ID": "Transaction ID",
    "Price": "Price",
    "Payment Method": "Payment Method",
    "Payment Status": "Payment Status",
    "Order Confirmation Number": "Order Confirmation Number",
    "Date": "Date",
    "Time": "Time",
    "AM": "AM",
    "PM": "PM",
    "Jan": "Jan", 
    "Feb": "Feb", 
    "Mar": "Mar", 
    "Apr": "Apr", 
    "May": "May", 
    "Jun": "Jun", 
    "Jul": "Jul", 
    "Aug": "Aug", 
    "Sep": "Sep", 
    "Oct": "Oct", 
    "Nov": "Nov", 
    "Dec": "Dec",
    "No Payment history found": "No Payment history found",
    "No": "No",
    "Yes": "Yes",
    "Delete Popup Text": "Are you sure you want to delete this payment ?"
}

export const arPayment = {
    "Home": "الصفحة الرئيسية",
    "Payment History": "سجل الدفع",
    "Welcome to your Account": "مرحبا بك في حسابك",
    "Completed": "تم الإكمال",
    "Pending": "قيد الانتظار",
    "Refunded": "تم رد المبلغ",
    "VIEW": "عرض",
    "DELETE": "حذف",
    "Download Receipt": "تنزيل الإيصال",
    "Transaction": "المعاملات",
    "Transaction ID": "معرف المعاملة",
    "Price": "السعر",
    "Payment Method": "طريقة الدفع",
    "Payment Status": "حالة الدفع",
    "Order Confirmation Number": "رقم تأكيد الطلب",
    "Date": "التاريخ",
    "Time": "البدء",
    "AM": "صباحًا",
    "PM": "مساءً",
    "Jan": "يناير", 
    "Feb": "فبراير", 
    "Mar": "يمشي", 
    "Apr": "أبريل", 
    "May": "يمكن", 
    "Jun": "يونيو", 
    "Jul": "يوليو", 
    "Aug": "أغسطس", 
    "Sep": "سبتمبر", 
    "Oct": "أكتوبر", 
    "Nov": "نوفمبر", 
    "Dec": "ديسمبر",
    "No Payment history found": "لم يتم العثور على سجل الدفع",
    "No": "لا",
    "Yes": "نعم",
    "Delete Popup Text": "هل أنت متأكد أنك تريد حذف هذه الدفعة؟"
}