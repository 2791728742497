export const enOrderDetail = {
  "Welcome to your Account": "Welcome to your Account",
  "Home": "Home",
  "My Account": "My Account",
  "My Order": "My Order",
  "Order ID": "Order ID",
  "Total": "Total",
  "Order Details": "Order Details",
  "Track Order": "Track Order",
  "Purchase date": "Purchase date",
  "Order Confirmation Text": "Your order has been confirmed.",
  "Order Placed": "Order Placed",
  "Order confirmed": "Order confirmed",
  "Order processing": "Order processing",
  "Order shipped": "Order shipped",
  "Out for delivery": "Out for delivery",
  "Order Delivered": "Order Delivered",
  "Order Placed Text": "We have received your order on ",
  "Order confirmed Text": "Your order has been confirmed. ",
  "Order processing Text": "Your order is processing. ",
  "Order shipped Text": "Your order is shipped. ",
  "Out for delivery Text": "Your order is out for delivery. ",
  "Order Delivered Text": "Your order has been delivered. ",
  "Color":"Color",
  "Size":"Size",
  "Qty":"Qty",
  "Return Status": "Return Status",
  "Return placed": "Return placed",
  "Return placed Text": "We have received your order on ",
  "Return confirmed":"Return confirmed",
  "Return confirmed Text": "Your order has been confirmed on ",
  "Out For Pickup": "Out For Pickup",
  "Out For Pickup Text": "Delivery partner is on the way to collect the order on ",
  "Order Picked Up": "Order Picked Up",
  "Order Picked Up Text": "Your order has been picked on ",
  "Return Completed": "Return Completed",
  "Return Completed Text": "Delivery partner has returned the order on ",
  "Return Order": "Return Order",
  "Pickup Time": "Pickup Time",
  "Store": "Store",
  "Total Refund": "Total Refund",
  "Pickup Charge": "Pickup Charge",
  "Return Products": "Return Products",
  "Out of Stock": "Out of Stock", 
  "Pricing Error": "Pricing Error", 
  "Order Processing Error": "Order Processing Error", 
  "Payment Processing Issue": "Payment Processing Issue", 
  "Order Limit Exceeded": "Order Limit Exceeded", 
  "Violation of Purchase Policy": "Violation of Purchase Policy",
  "Product": "Product", 
  "Brand": "Brand", 
  "Product Code": "Product Code", 
  "Price": "Price",
  "Worn or Used Items": "Worn or Used Items", 
  "Damaged Items": "Damaged Items", 
  "Altered Items": "Altered Items", 
  "Sale Items": "Sale Items", 
  "Past Return Window": "Past Return Window", 
  "Hygiene Concerns": "Hygiene Concerns",
  "Return Reject Successfully.": "Return Reject Successfully.",
  "Order Accepted": "Order Accepted",
  "Ready to Ship": "Ready to Ship",
  "Reject": "Reject",
  "Initiate Refund": "Initiate Refund",
  "Order Status": "Order Status",
  "Track Driver": "Track Driver",
  "Accept": "Accept",
  "Delivered": "Delivered",
  "Out for Delivery": "Out for Delivery",
  "Ready for Collection": "Ready for Collection",
  "Return Request": "Return Request",
  "In Process": "In Process",
  "Rejected": "Rejected",
  "Return In Process": "Return In Process",
  "New Order": "New Order",
  "Refund In Process": "Refund In Process",
  "Total Products": "Total Products",
  "Accept Order": "Accept Order",
  "Store Name": "Store Name",
  "Customer Name": "Customer Name",
  "Shipping Address": "Shipping Address",
  "Coupon Applied": "Coupon Applied",
  "Fash": "Fash",
  "Payment Method": "Payment Method",
  "Change Status": "Change Status",
  "Are you sure you want to change the order status to ready for collection?": "Are you sure you want to change the order status to ready for collection?",
  "Delivery partner will be assigned soon for the order pickup.": "Delivery partner will be assigned soon for the order pickup.",
  "Close": "Close",
  "Confirm": "Confirm",
  "Refund Amount": "Refund Amount",
  "Are you sure you want to initiate a refund for the returned product?": "Are you sure you want to initiate a refund for the returned product?",
  "Reject Refund": "Reject Refund",
  "Reason of Rejection": "Reason of Rejection",
  "Select Reason": "Select Reason",
  "Upload the item image for rejecting the refund": "Upload the item image for rejecting the refund",
  "Upload Image (PNG or JPG)": "Upload Image (PNG or JPG)",
  "Only PNG or JPG files are accepted": "Only PNG or JPG files are accepted",
  "Delivering in": "Delivering in",
  "OTP": "OTP",
  "Accept Return?": "Accept Return?",
  "Are you sure you want to accept the return order?": "Are you sure you want to accept the return order?",
  "Reject Return Order": "Reject Return Order",
  "Are you sure you want to reject the return order?": "Are you sure you want to reject the return order?",
  "Rejection Details": "Rejection Details",
  "Enter Rejection Details": "Enter Rejection Details", 

  "PM": "PM",
  "AM": "AM",
  "Order Time": "Order Time", 
  "Products": "Products", 
  "Shop": "Shop", 
  "Total Price": "Total Price", 
  "Status": "Status", 
  "Action": "Action",
  "There are no orders": "There are no orders",
  "results": "results",
  "of": "of",

  "Jan": "Jan", 
  "Feb": "Feb", 
  "Mar": "Mar", 
  "Apr": "Apr", 
  "May": "May", 
  "Jun": "Jun", 
  "Jul": "Jul", 
  "Aug": "Aug", 
  "Sep": "Sep", 
  "Oct": "Oct", 
  "Nov": "Nov", 
  "Dec": "Dec",
  "Ready To Ship": "Ready To Ship",
  "Order Rejected": "Order Rejected",
  "Reject Order": "Reject Order",
  "Are you sure you want to reject the order?": "Are you sure you want to reject the order?",
  "mins": "mins",
  "Set order reupload time": "Set order reupload time",
  "Are you sure you want to accept the order?": "Are you sure you want to accept the order?",

  "Debit Card ending": "Debit Card ending",
  "Order has been successfully Accepted": "Order has been successfully Accepted",
  "Delivery Date": "Delivery Date",

  "Reason of product rejection": "Reason of product rejection",

  "Refund Under Process": "Refund Under Process",
  "Refunded": "Refunded",
  "Purchase Date": "Purchase Date",

  "Arriving in": "Arriving in",
  "Anderson": "Anderson",

  "Pickup OTP": "Pickup OTP",
  "Upload Photo": "Upload Photo",

  "Order Summary": "Order Summary",
  "Search Order": "Search Order",
  "Shipped": "Shipped",
  "All Orders": "All Orders",
  "New Orders": "New Orders",
  "Return & Refund": "Return & Refund",
  "Minutes": "Minutes",

  "is your delivery partner.": "is your delivery partner.",
  "Contact": "Contact",
  "Order is out for delivery": "Order is out for delivery",
  "Your order is expected to arrive at": "Your order is expected to arrive at"
};

export const arOrderDetail = {
  "Welcome to your Account": "مرحبا بك في حسابك",
  "Home": "الصفحة الرئيسية",
  "My Account": "حسابي",
  "My Order": "طلباتي",
  "Order ID": "رقم الطلب",
  "Total": "الإجمالي",
  "Order Details": "تفاصيل الطلب",
  "Track Order": "تتبع الطلب",
  "Purchase date": "تاريخ الشراء",
  "Order Confirmation Text": "تم تأكيد طلبك.",
  "Order Placed": "تم تقديم الطلب",
  "Order confirmed": "تم تأكيد طلبك",
  "Order processing": "معالجة الطلب",
  "Order shipped": "تم شحن الطلب",
  "Out for delivery": "في طريق التوصيل",
  "Order Delivered": "تم توصيل الطلب",
  "Order Placed Text": "لقد تلقينا طلبك في ",
  "Order confirmed Text": "تم تأكيد طلبك في",
  "Order processing Text": "جاري معالجة طلبك. ",
  "Order shipped Text": "جاري معالجة طلبك. ",
  "Out for delivery Text": "طلبك في طريق التوصيل. ",
  "Order Delivered Text": "تم توصيل طلبك. ",
  "Color":"اللـون",
  "Size":"الحجم",
  "Qty":"الكمية",
  "Return Status": "حالة الإرجاع",
  "Return placed": "تم تقديم أمر الإرجاع",
  "Return placed Text": "لقد تلقينا طلبك في ",
  "Return confirmed":"تم تأكيد الإرجاع",
  "Return confirmed Text": "تم التأكيد في ",
  "Out For Pickup": "خارج للبيك اب ",
  "Out For Pickup Text": "شريك التوصيل في طريقه لاستلام الطلب ",
  "Order Picked Up": "تم استلام الطلب",
  "Order Picked Up Text": "لقد تم اختيار طلبك ",
  "Return Completed": "تم الإرجاع ",
  "Return Completed Text": "لقد أعاد شريك التوصيل الطلب بتاريخ ",
  "Return Order": "أمر الإرجاع",
  "Pickup Time": "وقت الاستلام",
  "Store": "محل",
  "Total Refund": "إجمالي المبلغ المسترد",
  "Pickup Charge": "رسوم الالتقاط",
  "Return Products": "إرجاع المنتجات",
  "Out of Stock": "غير متوفر حاليًا",
  "Pricing Error": "خطأ في التسعير", 
  "Order Processing Error": "خطأ في معالجة الطلب", 
  "Payment Processing Issue": "مشكلة في معالجة الدفع", 
  "Order Limit Exceeded": "تم تجاوز حد الطلب", 
  "Violation of Purchase Policy": "انتهاك سياسة الشراء",
  "Product":"منتجات",
  "Brand": "الماركة", 
  "Product Code": "رمز المنتج", 
  "Price": "سعر",
  "Worn or Used Items":"العناصر البالية أو المستعملة",
  "Damaged Items":"العناصر التالفة",
  "Altered Items":"العناصر المعدلة",
  "Sale Items":"عناصر البيع",
  "Past Return Window":"نافذة العودة الماضية",
  "Hygiene Concerns":"مخاوف النظافة",
  "Return Reject Successfully.": "تم رفض الإرجاع بنجاح.",
  "Order Accepted": "تم قبول الطلب",
  "Ready to Ship":"جاهز للشحن",
  "Reject":"يرفض",
  "Initiate Refund":"بدء استرداد الأموال",
  "Order Status":"حالة الطلب",
  "Track Driver":"سائق المسار",
  "Accept":"يقبل",
  "Delivered":"تم التسلیم",
  "Out for Delivery":"خارج للتسليم",
  "Ready for Collection": "للاستلام جاھز",
  "Return Request":"طلب العودة",
  "In Process":"قید التنفیذ",
  "Rejected":"تم الرفض",
  "Return In Process":"العودة في العملية",
  "New Order":"طلب جديد",
  "Refund In Process":"استرداد الأموال قيد المعالجة",
  "Total Products":"إجمالي المنتجات",
  "Accept Order":"قبول الطلب",
  "Store Name":"اسم المتجر",
  "Customer Name":"اسم العميل",
  "Shipping Address":"عنوان الشحن",
  "Coupon Applied":"تم تطبيق القسيمة",
  "Fash":"فاش",
  "Payment Method":"طريقة الدفع",
  "Change Status":"تغيير الحالة",
  "Are you sure you want to change the order status to ready for collection?":"هل أنت متأكد أنك تريد تغيير حالة الطلب إلى جاهز للاستلام؟",
  "Delivery partner will be assigned soon for the order pickup.":"سيتم تعيين شريك التوصيل قريبًا لاستلام الطلب.",
  "Close":"يغلق",
  "Confirm":"يتأكد",
  "Refund Amount":"مبلغ الاسترداد",
  "Are you sure you want to initiate a refund for the returned product?":"هل أنت متأكد أنك تريد استرداد أموال المنتج الذي تم إرجاعه؟",
  "Reject Refund":"رفض استرداد الأموال",
  "Reason of Rejection":"سبب الرفض",
  "Select Reason":"حدد السبب",
  "Upload the item image for rejecting the refund":"قم بتحميل صورة العنصر لرفض استرداد الأموال",
  "Upload Image (PNG or JPG)":"تحميل الصورة (PNG أو JPG)",
  "Only PNG or JPG files are accepted":"يتم قبول ملفات PNG أو JPG فقط",
  "Delivering in":"التسليم في",
  "OTP":"مكتب المدعي العام",
  "Accept Return?":"قبول العودة؟",
  "Are you sure you want to accept the return order?":"هل أنت متأكد أنك تريد قبول أمر الإرجاع؟",
  "Reject Return Order":"رفض أمر الإرجاع",
  "Are you sure you want to reject the return order?":"هل أنت متأكد أنك تريد رفض أمر الإرجاع؟",
  "Rejection Details":"تفاصيل الرفض",
  "Enter Rejection Details":"أدخل تفاصيل الرفض",
  "AM": "صباحًا",
  "PM": "مساءً",
  "Order Time": "وقت الطلب",
  "Products": "المنتجات",
  "Total Price": "السعر الإجمالي",
  "Shop": "التسوق",
  "Status": "الحالة",
  "Action": "الإجراء",
  "There are no orders": "لا توجد طلبات",
  "results": "نتيجة",
  "of": "من أصل",
  "Jan": "يناير", 
  "Feb": "فبراير", 
  "Mar": "يمشي", 
  "Apr": "أبريل", 
  "May": "يمكن", 
  "Jun": "يونيو", 
  "Jul": "يوليو", 
  "Aug": "أغسطس", 
  "Sep": "سبتمبر", 
  "Oct": "أكتوبر", 
  "Nov": "نوفمبر", 
  "Dec": "ديسمبر",
  "Ready To Ship":"جاهز للشحن",
  "Order Rejected": "تم رفض الطلب",
  "Reject Order": "رفض الطلب",
  "Are you sure you want to reject the order?": "هل أنت متأكد أنك تريد رفض الطلب؟",
  "mins": "دقيقة",
  "Set order reupload time": "ضبط وقت إعادة تحميل الطلب",
  "Are you sure you want to accept the order?": "هل أنت متأكد أنك تريد قبول الطلب؟",
  "Debit Card ending": "بطاقة خصم تنتھي ب",
  "Order has been successfully Accepted": "تم قبول الطلب بنجاح",
  "Delivery Date": "تاریخ التسلیم",
  "Reason of product rejection": "مشكلة مرتبطة بالحجم",
  "Refund Under Process": "إرجاع قید المعالجة",
  "Refunded": "طلب إرجاع",
  "Purchase Date": "تاريخ الشراء",
  "Arriving in": "الوصول خلال",
  "Anderson": "أندرسون",
  "Pickup OTP": "رمز التحقق الخاص بالاستلام",
  "Upload Photo": "تحميل الصورة",
  "Order Summary": "ملخص الطلب",
  "Search Order": "ترتيب البحث",
  "Shipped": "تم الشحن",
  "All Orders": "جمیع الطلبات",
  "New Orders": "الطلبات الجدیدة", 
  "Return & Refund": "الإرجاع ورد الأموال",
  "Minutes": "دقيقة",
  "is your delivery partner.": "شريك خدمات التوصيل الخاص بك هو.",
  "Contact": "تواصل مع",
  "Order is out for delivery": "الطلب في طريق التوصيل",
  "Your order is expected to arrive at": "انتهى تحضير طلبك ومن المتوقع أن يصل في"
};
