import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { StylistForm } from "./StylishClientsController";
import { getStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../../components/src/APICall";
import { MetaResponse } from "../../Seller/Catalogues/SellerCataloguesController";
import { logoutSellerNavigation } from "../../../../../components/src/Seller/logOut";
import { ErrorMessage } from "../../CreateSellerStoreController";
import { ErrorMessageResponse } from "../../SellerStoreController";
import i18n from "../../../../../components/src/i18next/i18n";

interface IStylishRequestResp {
  data: StylistForm[];
  meta: MetaResponse;
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tableBody: StylistForm[];
  isSROpen: boolean;
  isAccepted: boolean;
  stylistRequestMeta: MetaResponse;
  isRequestOpen: boolean;
  isLoading: boolean;
  page: number;
  popUpData: StylistForm;
  isAlert: boolean;
  message: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylingRequestsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLatestRequestsId: string = "";
  updateLatestAcceptRejectsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tableBody: [],
      isSROpen: false,
      isAccepted: true,
      isRequestOpen: false,
      stylistRequestMeta: {} as MetaResponse,
      isLoading: true,
      page: 1,
      isAlert: false,
      message: "",
      popUpData: {} as StylistForm,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseSRJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseSRJson && !responseSRJson.errors) {
        this.apiSRSuccessCalled(apiRequestCallId, responseSRJson);
      } else if (responseSRJson && responseSRJson.errors) {
        this.apiSRFailureCalled(responseSRJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if (userData.userType === "stylist") this.fetchStylishClientRequestData();
    else history.back();
    // Customizable Area End
  }

  apiSRSuccessCalled = (
    apiRequestCallId: string,
    responseSCJson: IStylishRequestResp & { data: StylistForm }
  ) => {
    if (apiRequestCallId === this.getLatestRequestsId) {
      if (responseSCJson.data) {
        this.setState((prevState) => ({
          ...prevState,
          tableBody: responseSCJson.data,
          stylistRequestMeta: responseSCJson.meta,
          isLoading: false,
        }));
      }
    } else if (apiRequestCallId === this.updateLatestAcceptRejectsId) {
      if (responseSCJson.data) {
        this.fetchStylishClientRequestData();
        this.setState(
          (prevState) => ({ ...prevState, isSROpen: true, isLoading: true  }),
          () => {
            setTimeout(() => this.handleClose(), 2000);
          }
        );
      }
    }
  };

  apiSRFailureCalled = (
    responseSRJson: ErrorMessage & ErrorMessageResponse
  ) => {
    if (responseSRJson.errors && !responseSRJson.errors[0].token) {
      this.handleRedirectToHome(responseSRJson.errors);
    } else if (responseSRJson.errors[0].token) {
      this.handleRedirectToHome(responseSRJson.errors[0].token);
    }
  };

  handleRedirectToHome = (message: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        isAlert: true,
        isLoading: false,
        message: message,
      }),
      () => {
        setTimeout(() => {
          this.handleStylishRequestsRedirect("Home");
          logoutSellerNavigation();
        }, 2000);
      }
    );
  };

  onCloseSRAlert = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: false,
    }));
  }

  handleStylishRequestsRedirect = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  handleStylishClientReqPageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    event.preventDefault();
    this.setState(
      (prevState) => ({
        ...prevState,
        stylistRequestMeta: {
          ...prevState.stylistRequestMeta,
          current_page: newPage,
        },
        page: newPage,
        isLoading: !prevState.isLoading,
      }),
      () => this.fetchStylishClientRequestData()
    );
  };

  fetchStylishClientRequestData = async () => {
    const { page } = this.state;
    this.getLatestRequestsId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.latestRequestsGet}?page=${page}&per_page=10`,
      token: await getStorageData("auth-token"),
    });
  };

  handleRequestClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isRequestOpen: !prevState.isRequestOpen,
    }));
  };

  handleRequestView = (requestId: string) => {
    const requestData: StylistForm =
      this.state.tableBody.find((req) => req.id === requestId) ||
      ({} as StylistForm);
    this.setState((prevState) => ({
      ...prevState,
      isRequestOpen: !prevState.isRequestOpen,
      popUpData: requestData,
    }));
  };

  handleAcceptReject = async (status: string, requestId: number) => {
    this.setState((prevState) => ({
      ...prevState,
      isAccepted: status === 'accepted' ? true: false,
    }));
    const formData = new FormData();
    formData.append("status", status);
    this.updateLatestAcceptRejectsId = await apiCall({
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.customForms}/${requestId}/update_status`,
      token: await getStorageData("auth-token"),
      body: formData,
      isJsonStringify: false,
    });
  };

  handleClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isSROpen: false,
      isRequestOpen: false,
    }));
  };
  tranStylingRequest = (transKey: string) => {
    return i18n.t(transKey, {ns: "stylishClient"});
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
