import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    TableContainer, Table, TableBody, TableRow, TableCell, TableHead, withStyles, Checkbox, CheckboxProps,
    Button
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { button_arrow } from "./assets";
import { styled } from "@material-ui/core/styles";
import SellerSideBar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSelectProductswebController, {
    Props,
} from "./SellerSelectProductswebController";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export default class SellerSelectProductsWebPage extends SellerSelectProductswebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Star
    // Customizable Area End

    render() {
        // Customizable Area Start
        const ROWS_PER_PAGE = 10;
        const startIndex = (this.state.metaData && this.state.metaData.current_page - 1) * ROWS_PER_PAGE + 1;
        const endIndex = Math.min(this.state.metaData && this.state.metaData.current_page * ROWS_PER_PAGE, this.state.metaData && this.state.metaData.total_records);
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Grid container dir={i18n.dir()}>
                    <Grid item md={2} sm={2} xs={2} lg={2} xl={2} data-testid="navigation">
                        <SellerSideBar navigation={this.props.navigation} />
                    </Grid>
                    <Grid data-testid="pointer" item md={10} xl={10} xs={10} sm={10} lg={10}  >
                        <SellerHeader navigation={this.props.navigation} />
                        <Box style={{ margin: "30px" }}>
                            <Box style={{ ...webStyle.haderBox, marginBottom: '30px' }}>
                                <Box style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}><img data-testid="sellerOffersDiscounts" width="32px" height="32px" src={button_arrow} onClick={this.sellerOffersDiscounts} style={{transform: handleSelectProductsBackCSS(i18n.dir())}}/>&nbsp;&nbsp;&nbsp;<Typography style={webStyle.lableText}>{this.tranSellerSelectProducts("Select Products")}</Typography>&nbsp;<Typography style={webStyle.topText}>[{this.tranSellerSelectProducts("Select the products for creating an offer")}]</Typography></Box>
                                <Button style={{ ...webStyle.twoButton, textTransform: 'capitalize' }} onClick={this.sellerOffersDiscounts}>{this.tranSellerSelectProducts("Confirm")}</Button>
                            </Box>
                            <Box>
                                <CustomStyledTable className="container">
                                    <Table stickyHeader size="small" className="custom-table">
                                        <TableHeadAllOrder>
                                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                                <TableCell style={webStyle.tableHader}><Typography variant="h6">{this.tranSellerSelectProducts("Product Name")}</Typography></TableCell>
                                                <TableCell style={webStyle.tableHader}><Typography variant="h6">{this.tranSellerSelectProducts("SKU")}</Typography></TableCell>
                                                <TableCell style={webStyle.tableHader}>
                                                    <Typography variant="h6">
                                                        <CustomCheckbox
                                                            inputProps={{ 'data-testid': 'setpricechecked' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                                                'aria-label'?: string;
                                                                'data-testid'?: string;
                                                            }}
                                                            classes={{
                                                                root: `
                                                            & .MuiSvgIcon-root {
                                                              strokeWidth: 1;
                                                              fontSize: 30;
                                                              borderRadius: 60;
                                                            }
                                                            '&:hover': {
                                                              backgroundColor: 'transparent',
                                                            },
                                                            border:"none"
                                                            borderRadius: 20px !important;
                                                            color: gray;
                                                            &.Mui-checked {
                                                              color: #0E6C9D;
                                                            }
                                                            ` }}
                                                            checked={this.state.selectedIndices.length === this.state.tableAllOrder.length}
                                                            indeterminate={this.state.selectedIndices.length > 0 && this.state.selectedIndices.length < this.state.tableAllOrder.length}
                                                            onChange={this.handleSelectAllClick}
                                                        />
                                                        &nbsp;&nbsp;{this.tranSellerSelectProducts("Select Products")}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHeadAllOrder>
                                        <TableBody>
                                            {this.state.tableAllOrder && this.state.tableAllOrder.map((orderData, index) => (
                                                <TableRow key={index} className="hover">
                                                    <TableCell>
                                                        <Box style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                            <Box><img src={orderData.attributes.catalogue_variant && orderData.attributes.catalogue_variant.remove_front_image} style={{ width: '34px', height: '34px', borderRadius: '2px' }} /></Box>
                                                            <Box style={{ ...webStyle.tableHaderrow }}>{orderData.attributes.catalogue && orderData.attributes.catalogue.name}</Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell><Box data-testid="orderDataSku" style={{ ...webStyle.tableHaderrow }}>{orderData.attributes.catalogue_variant && orderData.attributes.catalogue_variant.sku}</Box></TableCell>
                                                    <TableCell>
                                                        <Box style={{display: "flex"}}>
                                                        <CustomCheckbox
                                                            inputProps={{ 'data-testid': `set-price-checked-${index}` } as React.InputHTMLAttributes<HTMLInputElement> & {
                                                                'aria-label'?: string;
                                                                'data-testid'?: string;
                                                            }}
                                                            checked={this.state.selectedIndices.includes(orderData.attributes.catalogue_variant_id)}
                                                            onChange={this.handleRowCheckboxChange(index)}
                                                        />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </CustomStyledTable>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "23px 0px" }}>
                                    <Box style={{ color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: 'Lato' }}>
                                        {startIndex} - {endIndex} {this.tranSellerSelectProducts("of")} {this.state.metaData.total_records || 0} {this.tranSellerSelectProducts("results")}
                                    </Box>
                                    <CustomStyledPagination
                                        data-testid="CustomStyledPagination"
                                        page={this.state.page}
                                        count={this.state.metaData.total_pages || 0}
                                        shape="rounded"
                                        color="primary"
                                        onChange={this.handlePageChange}
                                        dir={i18n.dir()}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    topText: { color: '#375280', fontSize: '16px', fontFamily: 'Lato', fontWeight: 400 },
    twoButton: { width: '150px', color: '#FFFFFF', fontSize: '16px', fontFamily: 'Lato', fontWeight: 800, padding: '10px 16px', backgroundColor: '#CCBEB1' },
    haderBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    lableText: { fontSize: "24px", fontFamily: "Lato", fontWeight: 700, color: "#375280" },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700,
    },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400, display: "flex"
    },
};

const handleSelectProductsBackCSS = (dir: "ltr" | "rtl") => {
    return dir ==='ltr'? "rotate(0deg)" : "rotate(180deg)";
}

const CustomStyledPagination = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) => ({
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    '& .Mui-selected': {
        color: 'white',
    },
    "& li:first-of-type .MuiPaginationItem-icon, li:last-of-type .MuiPaginationItem-icon": {
        transform: handleSelectProductsBackCSS(dir),
    },
}));

const TableHeadAllOrder = styled(TableHead)({
    "& .tableHeaderTxt":{
        fontFamily: "Lato"
    },
    '& .MuiTableCell-stickyHeader': {
        backgroundColor: 'white !important'
    },
    '& .MuiTypography-root': {
        display: "flex",
        alignItems: "center"
    }
})
const CustomStyledTable = styled(TableContainer)({
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px"
    },

    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 30px 15px 30px"
    }
})

const CustomCheckbox = withStyles({
    root: {
        color: '#CCBEB1',
        '&$checked': {
            color: '#CCBEB1',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg, hsla(0,0%,100%,.8), hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checked: {
        color: '#CCBEB1',
    },
    checkedIcon: {
        backgroundColor: '#CCBEB1',
    },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

// Customizable Area End