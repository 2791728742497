import React from "react";
// Customizable Area Start
import { Box, Typography, RadioGroup, FormControlLabel } from "@material-ui/core";
import RadioButton from "../../../components/src/RadioButton";
// Customizable Area End

import CurrencylanguageController, {
    Props
} from "./CurrencylanguageController";

export default class CurrencyLanguage extends CurrencylanguageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
      this.getLanguageCurrency();
    }

    languageOptions = [
        { value: "0", label: "English",selectedValue:"English" },
        {
          value: "1",
          label: (
            <Typography style={{ fontFamily: "Lato, sans-serif" }}>
              Arabic - <b>عربي</b>
            </Typography>
          ),selectedValue:"Arabic"
        },
      ];
    render() {
        return (
            <Box>
                {this.props.showLanguage && (
                    <Box style={{
                        width: '260px',
                        height: '129px',
                        backgroundColor: "#FFFFFF",
                        position: "absolute",
                        top: "50px",right: "3rem", zIndex: 10,
                        boxShadow: "0 0px 0px 2px rgba(0, 0, 0, 0.06)"
                    }}>
                         <RadioGroup
                      row
                      aria-labelledby="language-selection"
                      name="language-selection"
                      style={{ justifyContent: "space-between", width: "220px" ,margin:'25px', display: "flex", flexDirection: "column" }}
                      value={this.state.language}
                      data-test-id="group-language"
                      onChange={(event, value) =>
                        this.onChangeLanguage(value)
                      }
                    >
                      {this.languageOptions.map((lang, index) => (
                        <Box>
                            <FormControlLabel
                              key={lang.value}
                              value={lang.selectedValue}
                              control={<RadioButton variant="outlined" />}
                              label={lang.label}
                              data-test-id={`radio-${lang.value}`}
                              className={`radioButton ${
                                lang.label === this.state.language
                                  ? "activeRadioButton"
                                  : ""
                              }`}
                              style={{
                                justifyContent: 'space-between', 
                                display: 'flex', 
                                flexDirection: 'row-reverse', 
                                alignItems: 'center' ,
                                color:"#375280", marginLeft: "0px", marginRight: "0px"
                              }}
                            />
                            {index == 0 && <hr style={{
                                color: "#D9D9D9"
                            }}/>}
                        </Box>
                      ))}
                    </RadioGroup>
                    </Box>
                )}
            </Box>
        );
    }
}
// Customizable Area End


// Customizable Area Start
// Customizable Area End
