export const enInventoryManagement = {
    "Min Price": "Min Price",
    "Max Price": "Max Price",
    "Please Select Any one Category For Filter": "Please Select Any one Category For Filter",
    "Search": "Search",
    "Confirm": "Confirm",
    "Filters": "Filters",
    "Low On Stock": "Low On Stock",
    "Unlisted": "Unlisted",
    "Listed": "Listed",
    "Out Of Stock": "Out Of Stock",
    "Clear All": "Clear All",
    "Apply": "Apply",
    "Assign Store": "Assign Store",
    "Inventory Management": "Inventory Management",
    "Assign Stores": "Assign Stores",
    "Search Store": "Search Store",
    "Select All Products": "Select All Products",
    "Back": "Back",
    "Your entries has Been Updated": "Your entries has Been Updated",
    "You are not an authorized person, Please Login with valid User Credential": "You are not an authorized person, Please Login with valid User Credential",
    "Total Products": "Total Products",
    "Low Stock": "Low Stock",
    "Please Select any one for Filter. This is required for Filter": "Please Select any one for Filter. This is required for Filter",
    "Max price cannot be less than min price": "Max price cannot be less than min price",
    "Please Select any one store. This is required For Assign Store": "Please Select any one store. This is required For Assign Store",
    "Out of Stock": "Out of Stock",
    "Update Inventory": "Update Inventory",
    "Set Prices": "Set Prices",
    "Pair the Products": "Pair the Products",
    "Bulk Actions": "Bulk Actions",
    "Download Templates": "Download Templates",
    "Size": "Size",
    "Price": "Price",
    "Category": "Category",
    "Sub Category": "Sub Category",
    "Product Name": "Product Name",
    "SKU": "SKU",
    "Current Stock": "Current Stock",
    "Low Stock Threshold": "Low Stock Threshold",
    "List/Unlist": "List/Unlist",
    "No Records Found": "No Records Found",
    "results": "results",
    "of": "of",
    "You have unsaved changes.": "You have unsaved changes.",
    "Are you sure you want to leave this page?": "Are you sure you want to leave this page?",
    "Yes": "Yes",
    "No": "No",
    "Cancel": "Cancel",
    "Please click on Confirm Button Otherwise": "Please click on Confirm Button Otherwise",
    "If": "If",
    "Discounted Percentage":"Discounted Percentage",
    "Discounted Price": "Discounted Price",
    "Select Products": "Select Products",
    "Store status Update Successfully": "Store status Update Successfully",
    "Approved Store Not Found": "Approved Store Not Found",
    "Open/Close All Stores": "Open/Close All Stores",
    "AnyOne field must be selected": "Any One field must be selected",
    "Pair Products": "Pair Products",
    "Pair It With": "Pair It With",
    "Save": "Save",
    "Pair It With Description": "Enter the SKU if the product you want to suggest for pair to the buyer. (This allows the seller to pair clothing together by referencing the SKU number of the product they want to pair with.)",
    "Add SKU’s (Up to 5)": "Add SKU’s (Up to 5)",
    "No Templates Found": "No Templates Found",
    "Select the products from the below table to delete in bulk": "Select the products from the below table to delete in bulk",
    "Delete Products": "Delete Products",
    "All the selected products will be deleted by using bulk actions.": "All the selected products will be deleted by using bulk actions.",
    "Are you sure you want to delete all the products?": "Are you sure you want to delete all the products?",
    "Delete": "Delete",
    "Please click on Delete Products to Delete Products Otherwise": "Please click on Delete Products to Delete Products Otherwise",
    "Products added successfully": "Products added successfully",
    "Products edited successfully": "Products edited successfully",
    "Store Assigned successfully": "Store Assigned successfully",
    "Inventory is successfully updated": "Inventory is successfully updated",
    "Please upload a CSV file.": "Please upload a CSV file.",
    "CSV File size should be less than 5 MB.": "CSV File size should be less than 5 MB.",
    "CSV File is Required. Please upload a CSV file.": "CSV File is Required. Please upload a CSV file.",
    "Please hold on CSV file is uploading....": "Please hold on CSV file is uploading....",
    "Add Products": "Add Products",
    "Edit Products": "Edit Products",
    "Only CSV files are accepted": "Only CSV files are accepted",
    "Reupload": "Reupload",
    "Completed": "Completed",
    "Your CSV file is uploaded completely": "Your CSV file is uploaded completely",
    "Upload Products": "Upload Products",
    "Upload Stores": "Upload Stores"
}

export const arInventoryManagement = {
    "Min Price": "الحد الأدنى للسعر",
    "Max Price": "ماكس السعر",
    "Please Select Any one Category For Filter": "الرجاء تحديد أي فئة واحدة للتصفية",
    "Search": "البحث",
    "Confirm": "التأكيد",
    "Filters": "الفلاتر",
    "Low On Stock": "المخزون منخفض",
    "Unlisted": "غير مدرجة",
    "Listed": "مدرجة",
    "Out Of Stock": "غير متوفر حاليًا",
    "Clear All": "مسح الكل",
    "Apply": "التطبيق",
    "Assign Store": "تعيين المتجر",
    "Inventory Management": "إدارة المخزون",
    "Assign Stores": "تعيين المتاجر",
    "Search Store": "البحث عن متجر",
    "Select All Products": "حدد كافة المنتجات",
    "Back": "خلف",
    "Your entries has Been Updated": "لقد تم تحديث إدخالاتك",
    "You are not an authorized person, Please Login with valid User Credential": "أنت لست شخصًا مفوضًا، يرجى تسجيل الدخول باستخدام بيانات اعتماد مستخدم صالحة",
    "Total Products": "إجمالي المنتجات",
    "Low Stock": "المخزون منخفض",
    "Please Select any one for Filter. This is required for Filter": "الرجاء تحديد أي واحد للتصفية. هذا مطلوب للتصفية",
    "Max price cannot be less than min price": "لا يمكن أن يكون السعر الأقصى أقل من السعر الأدنى",
    "Please Select any one store. This is required For Assign Store": "الرجاء اختيار أي متجر واحد. هذا مطلوب لتعيين المتجر",
    "Out of Stock": "غير متوفر حاليًا",
    "Update Inventory": "تحدیث المخزون",
    "Set Prices": "تعیین الأسعار",
    "Pair the Products": "الجمع بین المنتجات",
    "Bulk Actions": "الإجراءات الجماعیة",
    "Download Templates": "تنزیل القوالب",
    "Size": "مقاس",
    "Price": "السعر",
    "Category": "فئة",
    "Sub Category": "الفئة الفرعية",
    "Product Name": "اسم المنتج",
    "SKU": "رمز تعريف البضاعة ",
    "Current Stock": "المخزون الحالي",
    "Low Stock Threshold": "عتبة المخزون المنخفض",
    "List/Unlist": "الإدراج/ الإزالة",
    "No Records Found": "لم يتم العثور على سجلات",
    "results": "نتيجة",
    "of": "من أصل",
    "You have unsaved changes.": "لديك تغييرات غير محفوظة.",
    "Are you sure you want to leave this page?": "هل أنت متأكد أنك تريد مغادرة هذه الصفحة؟",
    "No": "لا",
    "Yes": "نعم",
    "Cancel":"الإلغاء",
    "Please click on Confirm Button Otherwise": "الرجاء الضغط على زر التأكيد بخلاف ذلك",
    "If" : "لو",
    "Discounted Percentage":"نسبة التخفيض",
    "Discounted Price": "السعر بعد التخفيض",
    "Select Products": "تحديد المنتجات",
    "Store status Update Successfully": "تم تحديث حالة المتجر بنجاح",
    "Approved Store Not Found": "لم يتم العثور على المتجر المعتمد",
    "Open/Close All Stores": "فتح/إغلاق كافة المتاجر",
    "AnyOne field must be selected": "يجب تحديد أي حقل واحد",
    "Pair Products": "الجمع بين المنتجات",
    "Pair It With": "إجمعه مع",
    "Save": "حفظ",
    "Pair It With Description": "أدخل رمز تعريف البضاعة الخاص بالمنتج الذي تود اقتراحه للاقتران للمشتري. (يسمح هذا للبائع بالجمع بين الملابس سوية من خلال الإشارة إلى رمز تعريف البضاعة الخاص بالمنتج الذي يريد الإقران به.)",
    "Add SKU’s (Up to 5)": "إضافة رموز تعريف البضاعة (لا تتجاوز 5)",
    "No Templates Found": "لم يتم العثور على قوالب",
    "Select the products from the below table to delete in bulk": "حدد المنتجات التي تود حذفها دفعة واحدة من الجدول أدناه",
    "Delete Products": "حذف المنتجات",
    "All the selected products will be deleted by using bulk actions.": "ستحذف جميع المنتجات المحددة عن طريق الإجراءات الجماعية.",
    "Are you sure you want to delete all the products?": "هل أنت متأكد من رغبتك في حذف جميع المنتجات؟",
    "Delete": "الحذف",
    "Please click on Delete Products to Delete Products Otherwise": "الرجاء الضغط على حذف المنتجات لحذف المنتجات بخلاف ذلك",
    "Products added successfully": "تمت إضافة المنتجات بنجاح ",
    "Products edited successfully": "تم تعديل المنتجات بنجاح",
    "Store Assigned successfully": "تم تعيين المتجر بنجاح",
    "Inventory is successfully updated": "تم تحديث المخزون بنجاح",
    "Please upload a CSV file.": "يرجى تحميل ملف CSV.",
    "CSV File size should be less than 5 MB.": "يجب أن يكون حجم ملف CSV أقل من 5 ميغابايت.",
    "CSV File is Required. Please upload a CSV file.": "مطلوب ملف CSV. يرجى تحميل ملف CSV.",
    "Please hold on CSV file is uploading....": "يرجى الانتظار حتى يتم تحميل ملف CSV....",
    "Add Products": "إضافة منتجات",
    "Edit Products": "تعديل المنتجات",
    "Only CSV files are accepted": "يتم قبول ملفات CSV فقط",
    "Reupload": "أعد الرفع",
    "Completed": "تم إكمال",
    "Your CSV file is uploaded completely": "تم رفع ملف CSV الخاص بك بالكامل",
    "Upload Products": "رفع المنتجات",
    "Upload Stores": "تحميل المتاجر"
}