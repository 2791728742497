Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "productdescription3";
exports.labelBodyText = "productdescription3 Body";

exports.btnExampleTitle = "CLICK ME";
exports.product_cataloge = "GET";
exports.cataloge = "bx_block_catalogue/catalogues";
exports.product_addCart = "POST";
exports.product_addCartUrlWithOutToken = "bx_block_order_management/orders/create_cart?unique_token";
exports.product_addCartUrlWithToken = "bx_block_order_management/orders";
exports.product_cataloge = "GET";
exports.getCatalogues = "bx_block_catalogue/catalogues/catalogue_buyer?category_ids[]";
exports.getUnique_token = "bx_block_order_management/orders/get_active_cart?unique_token";
exports.getActiveCartView = "bx_block_order_management/orders/get_active_cart_view?unique_token";
exports.getAddAddress = "bx_block_order_management/orders/add_address_to_order";
exports.charges = "bx_block_tappaymentsintegration/tappayment/charges";
exports.product_catalogeput = "PUT"
exports.getUnique_tokenPut = "bx_block_order_management/orders/update_cart_item_quantity";
exports.getRemove_order_items = "bx_block_order_management/orders/remove_order_items"
exports.favouritesLink = "bx_block_favourites/favourites";
exports.favouritesremoved = "bx_block_favourites/favourites/destroy_by_favouritable?favouriteable_id";
exports.exampleAPiRemoved = "DELETE";
exports.getCataloguesToken = "bx_block_catalogue/catalogues?category_ids[]";
exports.catalogeToken = "bx_block_catalogue/catalogues/show_catalogues?id"
exports.retrieve_charge = "bx_block_tappaymentsintegration/tappayment/retrieve_charge?charge_id"
exports.applyCoupon = "bx_block_order_management/orders/apply_coupon";
exports.removedCode = "bx_block_order_management/orders/remove_coupon?order_id";
exports.removedApiMethod = "DELETE";

exports.directOrderAPIEndPoint = "bx_block_order_management/orders/buy_now";
// Customizable Area End