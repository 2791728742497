import React from "react";

// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { configJSON } from "./OrderPaymentsController.web";

interface Props {
  activeTab: "shipping" | "payments" | "complete";
}

const MainBox = styled(Box)({
  display: "flex",
  margin: "3.5rem auto",
  width: "50vw",
  "& .head-circle": {
    borderBottom: "1px solid rgba(55,82,128, 0.5)",
    position: "relative",
    width: "11rem",
    bottom: "12px",
  },
  "& .head-circle:before, & .head-circle:after": {
    position: "absolute",
    bottom: "-2px",
    left: 0,
    height: "3px",
    width: "3px",
    background: "#375280",
    content: "''",
    borderRadius: "5px",
  },
  "& .head-circle:after": {
    right: 0,
    left: "auto",
  },
  "& .head-text": {
    fontSize: "1.5rem",
    margin: "0 1rem",
    color: "#375280",
  },
  "& .active-tab": {
    textDecoration: "underline",
    textUnderlinePosition: "under",
    fontWeight: 800,
  },
});
// Customizable Area End

const OrderSections: React.FC<Props> = (props) => {
  // Customizable Area Start
  const { activeTab } = props;
  const tabs = [
    { value: "shipping", label: configJSON.shipping },
    { value: "payments", label: configJSON.payments },
    { value: "complete", label: configJSON.complete },
  ];
  // Customizable Area End

  // Customizable Area Start
  return (
    <MainBox>
      {tabs.map((tab, i) => (
        <React.Fragment key={tab.value}>
          <Typography
            className={`head-text ${
              activeTab === tab.value ? "active-tab" : ""
            }`}
          >
            {tab.label}
          </Typography>
          {i !== tabs.length - 1 && <Box className="head-circle" />}
        </React.Fragment>
      ))}
    </MainBox>
  );
  // Customizable Area End
};

export default OrderSections;
