import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField
} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { sideImage } from "../src/assets";
import Visibility from "@material-ui/icons/Visibility";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

import ResetPasswordController, {
  Props, configJSON,
} from "./ResetPasswordController.web";

export default class ResetPassword extends ResetPasswordController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  render() {
    return (
      <>
        <Header navigation={this.props.navigation}/>
        <MainGridContainer data-test-id="mainGridContainer" container dir={i18n.dir()}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
            <Box className="sideImageBox">
              <img src={sideImage} alt="sideImage" className="sideImage" />
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
            <Box className={"formBox"}>
              <form data-test-id="formSubmit" onSubmit={this.handleSubmit} style={{ width: "80%" }}>
                <Box width={"100%"}>
                  <Typography className={"welcomeText"}>{this.transResetOtp(configJSON.resetPassword)}</Typography>
                  <Typography className={"inputHeading"}>
                    {this.transResetOtp(configJSON.headnewPass)}
                  </Typography>
                  <TextField
                    data-test-id="newTextInput"
                    type={this.state.showNewPassword ? 'text' : 'password'}
                    className={this.state.errorNewPass ? "inputFeildPasswordError":"inputFeildPassword"}
                    value={this.state.newPassword}
                    onChange={this.handleNewPassword}
                    placeholder={this.transResetOtp(configJSON.placePAss)}
                    error={this.state.errorMsg.newError}
                    helperText={this.state.errorMsg.newError ? this.state.errorMsg.errorNewMsg : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleToggleNewPassword}
                            edge="end"
                          >
                            {this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                  />

                  <Typography className={"inputHeading"}>{this.transResetOtp(configJSON.confirPAss)}</Typography>
                  <TextField
                    data-test-id="condirmTextInput"
                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                    className={this.state.errorConfirmPass?"inputFeild2Error2":"inputFeild2"}
                    value={this.state.confirmPassword}
                    placeholder={this.transResetOtp(configJSON.placeConfirmPass)}
                    onChange={this.handleConfirmPassword}
                    error={this.state.errorMsg.confirmError}
                    helperText={this.state.errorMsg.confirmError ? this.state.errorMsg.errorConfirm : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleToggleCondirmPassword}
                            edge="end"
                          >
                            {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    className={"loginUserButton"}
                    disableElevation
                    type="submit"
                  >
                    {this.transResetOtp(configJSON.resetPassword)}
                  </Button>
                  <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={5000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  data-test-id="buttonTestId"
                  dir={i18n.dir()}
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
                </Box>
              </form>
            </Box>
          </Grid>
        </MainGridContainer>
        <Footer navigation={this.props.navigation}/>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const MainGridContainer = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  "& .sideImage": {
    width: "100%",
    height: "100%",
  },
  "& .imageGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .sideImageBox": {
    width: "100%",
    height: "100%",
  },
  "& .inputFeild": {
    gap: "4px",
    border: "none",
    borderRadius: "0px"
  },
  "& .formGrid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "7%"
  },
  "& .phoneInput": { display: "flex", width: "520px" },
  "& .welcomeText": {
    color: "rgba(55, 82, 128, 1)",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "26px",
    alignSelf: "flex-start",
  },
  "& .formBox": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    marginTop: "30px"
  },
  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px"
  },
  "& .loginUserButton": {
    textTransform: "capitalize",
    width: "100% !important",
    borderRadius: "0px !important",
    color: "white !important",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    gap: "8px",
    marginTop: "20px",
    fontFamily:'Lato',
    height: "56px !important",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
  "& .inputHeading": {
    color: "rgba(55, 82, 128, 1)",
    margin: "20px 10px 10px 0px",
    textOverflow: "ellipsis",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },
  "& .terms": {
    color: "#979797 !important",
    margin: "10px 10px 10px 10px !important",
    width: "520px !important",
    fontFamily: "Lato ,sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },
  "& .condition": {
    color: "var(--Neutrals-Cool-gray-500, #375280)",
    cursor: "pointer",
  },
  "& .inputFeildPassword": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    '& .MuiFormHelperText-root': {
      color: "#375280"
    },
    "& .MuiOutlinedInput-root:focus": {
      borderColor: "transparent"
    }
  },
  "& .inputFeildPasswordError": {
    "& .MuiOutlinedInput-adornedEnd": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
  },
  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-adornedEnd": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    '& .MuiInputBase-input': {
      color: "#375280",
      textAlign: i18n.dir() === "ltr" ? "right": "left"
    },
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent,!important", 
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent,!important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent,!important", 
    },
  },
  "& .Register": {
    padding: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .socialLogin": {
    padding: "20px",
    width: "100%",
    display: "flex",
    gap: "35px",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .continueText": {
    width: "100%",
    color: "#375280 !important",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    display: "flex",
    marginTop: "15px",
    flexDirection: "row",
    "&:before, &:after": {
      content: "''",
      flex: "1 1",
      borderBottom: "1px solid",
      margin: "auto"
    },
    "&:before": {
      marginRight: "25px"
    },
    "&:after": {
      marginLeft: "25px"
    }
  },
  "& .registerButton": {
    cursor: "pointer",
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    margin: "10px 10px 0 10px",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 700,
    lineHeight: "24px !important",
  },
  "& .bottomText": {
    color: "#375280 !important",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px", flexWrap: "wrap" },
    "& .Register": {
      width: "350px !important",
    },
    "& .socialLogin": {
      width: "350px !important",
    },
    "& .terms": {
      width: "350px !important",
    },
    "& .loginUserButton": {
      width: "100% !important",
      "& .MuiButton-label": {
        fontWeight: 600,
        fontSize: "16px"
      }
    },
    "& .phoneInput": { width: "370px" },
    "& .continueText": {
      width: "100%",
      color: "#375280 !important",
      fontFamily: "Lato, sans-serif",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      fontWeight: 400,
      lineHeight: "24px !important",
      display: "flex",
      marginTop: "15px",
      flexDirection: "row",
      "&:before, &:after": {
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto"
      },
      "&:before": {
        marginRight: "25px"
      },
      "&:after": {
        marginLeft: "25px"
      }
    },
  },
});

// Customizable Area End
