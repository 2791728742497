export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const fastpng = require("../assets/fast.svg");
export const paymentpng = require("../assets/payment.svg");
export const shippingpng = require("../assets/shipping.svg");
export const sizepng = require("../assets/size.svg");
export const cartpng = require("../assets/cart.svg");
export const sherpng = require("../assets/sher.png");
export const image_Likepng = require("../assets/image_Like.svg");
export const palritpng = require("../assets/palrit.png");
export const tryitout = require("../assets/Vector.png");
export const cartemptypng = require("../assets/cartempty.png");
export const standardDeliveryIcon = require("../assets/standard-delivery.png")
export const expressDeliveryIcon = require("../assets/express-delivery.png")
export const Noimageavailable = require("../assets/Noimageavailable.png")
export const mastercardIcon = require("../assets/mastercard_icon.png")
export const googlePayIcon = require("../assets/google_pay_icon.png")
export const applePayIcon = require("../assets/apple_pay_icon.png")
export const debitCardIcon = require("../assets/debit_card_icon.png")
export const modalSuccessImg = require("../assets/modal_success.png")
export const loyaltyBadgeIcon = require("../assets/loyalty_badge_icon.png")

export const selectDote = require("../assets/selectDote.svg")
export const view_Dotesvg = require("../assets/view_Dote.svg")
export const Deletesvg = require("../assets/Delete.svg")
export const viewLike = require("../assets/view_.svg")