export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const imageFilter = require("../assets/image_.png");
export const Loding = require("../assets/Loding.png");
export const image_Like = require("../assets/image_Like.png")
export const Noimageavailable = require("../assets/Noimageavailable.png")
export const viewLike = require("../assets/view_.svg")

