import React from "react";

// Customizable Area Start
import {
  styled,
  Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Typography, CircularProgress,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import OutOfDeliveryController, {
  Props,
  OrderOfOrderSeller,
  OrderOfItemSeller
} from "./OutOfDeliveryController.web";
import { Noimageavailablepng } from "./assets";
import moment from "moment";
import i18n from "../../../components/src/i18next/i18n";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class OutOfDelivery extends OutOfDeliveryController { 
  constructor(props: Props) { 
    super(props); 
    // Customizable Area Start 
    // Customizable Area End 
  } 
 
  // Customizable Area Start 
  handlePageChangeReadyFor = (event: React.ChangeEvent<unknown>, newPage: number) => { 
    event.preventDefault() 
    this.setState({ readyForPage: newPage }); 
    this.getOutForDeliveryApi() 
}; 
  // Customizable Area End 
 
  render() { 
    // Customizable Area Start  
 
    if (this.state.SellerOrdersProcessedView === undefined && this.state.isAlertReadyForCollection) {
      return (
          <Box style={{ ...webStyle.tabeLableReadyForCollection as React.CSSProperties }}>
              {this.tranOutForDelivery("There are no orders")}
          </Box>
      );
  }
  if (this.state.isLoadingReady) {
    return (
      <Box style={{ ...webStyle.tabsBoxReady }}>
      <CircularProgress style={webStyle.CircularProgressColor} />
    </Box>
    );
}
const ROWS_PER_PAGE = 10;
const endIndexInProcess = Math.min(this.state.metaReadyForData?.current_page * ROWS_PER_PAGE, this.state.metaReadyForData?.total_record);
const startIndexInProcess = (this.state.metaReadyForData?.current_page - 1) * ROWS_PER_PAGE + 1;
    return (
      <ThemeProvider theme={theme}> 
            <Box style={webStyle.readyForCollectionTableContainer} dir={i18n.dir()} > 
                <CustomOutStyledTable className="container"> 
                <Box style={{ position: 'relative', ...webStyle.menBox, flexDirection: 'column' }}>
                    <Table stickyHeader size="small" className="custom-table"> 
                        <TableHead> 
                            <TableRow style={webStyle.tableHaderOutOfDeliveryBg}> 
                                <TableCell style={webStyle.tableHaderOutOfDelivery}><Typography variant="h6" style={webStyle.tableHaderOutOfDeliveryTypo}>{this.tranOutForDelivery("Order ID")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderOutOfDelivery}><Typography variant="h6" style={webStyle.tableHaderOutOfDeliveryTypo}>{this.tranOutForDelivery("Order Time")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderOutOfDelivery}><Typography variant="h6" style={webStyle.tableHaderOutOfDeliveryTypo}>{this.tranOutForDelivery("Products")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderOutOfDelivery}><Typography variant="h6" style={webStyle.tableHaderOutOfDeliveryTypo}>{this.tranOutForDelivery("Shop")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderOutOfDelivery}><Typography variant="h6" style={webStyle.tableHaderOutOfDeliveryTypo}>{this.tranOutForDelivery("Total Price")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderOutOfDelivery}><Typography variant="h6" style={webStyle.tableHaderOutOfDeliveryTypo}>{this.tranOutForDelivery("Status")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderOutOfDelivery}><Typography variant="h6" style={webStyle.tableHaderOutOfDeliveryTypo}>{this.tranOutForDelivery("Action")}</Typography></TableCell>
                            </TableRow> 
                        </TableHead> 
                        <TableBody style={{ height: '100px', position: 'relative' }}>
                                {this.state.isLoading1 ?
                                    <Box data-testid="loader" style={{position: 'absolute',...webStyle.loderBox}}>
                                        <CircularProgress style={{ color: '#375280' }} />
                                    </Box> :this.state.SellerOrdersProcessedView?.map((sellerData : OrderOfOrderSeller, index: number) => ( 
                              <React.Fragment key={index}> 
                                <CustomTableRow> 
                                  <TableCell colSpan={7}> 
                                  <Box style={webStyle.ArrivingOutCell}> 
                                      {sellerData.attributes.order_items.map((items: OrderOfItemSeller) => (
                                        <>
                                          <Typography variant="body1" style={webStyle.ArrivingTextOut as React.CSSProperties}>{items.attributes.driver_name}</Typography>
                                        </>
                                      ))}
                                    <Typography variant="body1" style={webStyle.ArrivingTextOut as React.CSSProperties}>{this.tranOutForDelivery("Delivering in")}: <span style={webStyle.ArrivingTextOutValue}>14 {this.tranOutForDelivery("mins")}</span></Typography>
                                 </Box>
                                  </TableCell>
                                </CustomTableRow>
                                <TableRow key={sellerData.id}>
                                    <TableCell style={{ ...webStyle.tableHaderrowOut }}><Typography style={{...webStyle.tableHaderrowOutTypo}}>#{sellerData.attributes.order_management_order.attributes.order_number}</Typography></TableCell> 
                                    <TableCell style={{ ...webStyle.tableHaderrowOut }}><Typography style={{...webStyle.tableHaderrowOutTypo}}>{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</Typography></TableCell> 
                                  <TableCell data-testid="navigateToOutForDelivery" onClick={() => this.navigateToOutForDelivery({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "OutForDeliveryDetail" })} 
>
                                    {sellerData.attributes.order_items.map((items : OrderOfItemSeller) => ( 
                                      <Box style={webStyle.productDflex}> 
                                      <Box><img src={items.attributes.catalogue_variant_front_image ? items.attributes.catalogue_variant_front_image : Noimageavailablepng} style={webStyle.productIMg} /></Box> 
                                      <Box style={{ ...webStyle.tableHaderrowOut }}>{items.attributes.catalogue_name} x {items.attributes.quantity}</Box> 
                                    </Box> 
                                    ))} 
                                  </TableCell> 
                                    <TableCell style={{ ...webStyle.tableHaderrowOut }}><Typography style={{...webStyle.tableHaderrowOutTypo}}>{sellerData.attributes.order_items[0].attributes.store_name}</Typography></TableCell> 
                                    <TableCell style={{ ...webStyle.tableHaderrowOut }}><Typography style={{...webStyle.tableHaderrowOutTypo}}>{sellerData.attributes.order_items[0].attributes.currency_logo} {sellerData.attributes.order_items[0].attributes.total_price}</Typography></TableCell> 
                                    <TableCell style={{ ...webStyle.tableHaderrowOut }}><button className="outForDeliveryStatusBtn" >{this.tranOutForDelivery("Out for Delivery")}</button></TableCell> 
                                    <TableCell style={{ ...webStyle.tableHaderrowOut }}> 
                                        <button className="radyToOrderStatusBtn" >{this.tranOutForDelivery("Order Status")}</button> 
                                    </TableCell> 
                                </TableRow> 
                                </React.Fragment> 
                            ))} 
                        </TableBody> 
                    </Table> 
                    </Box>
                </CustomOutStyledTable> 
          <Box style={webStyle.paginationMainBox}> 
            <Box style={webStyle.paginationSecBox}> 
              {startIndexInProcess} - {endIndexInProcess} {this.tranOutForDelivery("of")} {this.state.metaReadyForData?.total_record} {this.tranOutForDelivery("results")}  
            </Box> 
            <CustomStyledPagination 
              page={this.state.readyForPage} 
              count={this.state.metaReadyForData?.total_pages} 
              onChange={this.handlePageChangeReadyFor} 
              shape="rounded" 
              color="primary"
              dir={i18n.dir()}
            /> 
          </Box> 
            </Box> 
      </ThemeProvider>  
    );  
    // Customizable Area End
  } 
} 
// Customizable Area Start
const webStyle = { 
  loderBox:{top: '50%',left: '50%',transform: 'translate(-50%, -50%)',display: 'flex',justifyContent: 'center',alignItems: 'center'},
  tableHaderOutOfDelivery: { 
    color: "#375280",
    fontSize: '16px', 
    fontFamily: "Lato", 
    fontWeight: 700, 
    WebkitTextStrokeWidth: "0.5px", 
    letterSpacing: "1px",
  },
  tableHaderOutOfDeliveryTypo: { 
    color: "#375280",
    fontSize: '16px', 
    fontFamily: "Lato", 
    fontWeight: 700, 
    WebkitTextStrokeWidth: "0.5px", 
    letterSpacing: "1px",
    display: 'flex'
  },
  tabeLableReadyForCollection: {
    color: "rgb(55, 82, 128)", 
    fontSize: '20px',
    height: '50vh', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    fontWeight: 800, 
    fontFamily: 'Lato', 
},
tableHaderOutOfDeliveryBg: {
  backgroundColor: "#f0f0f0"
},
menBox:{height: 'auto ', display: 'flex'},
  tableHaderrowOut: {
    color: "#375280", 
    fontSize: '16px', 
    fontWeight: 400,
    fontFamily: "Lato", 
    verticalAlign: 'top',
  },
  tableHaderrowOutTypo: {
    color: "#375280", 
    fontSize: '16px', 
    fontWeight: 400,
    fontFamily: "Lato", 
    verticalAlign: 'top',
    display: 'flex'
  },
  productDflex: {
    display: "flex", 
    gap: 10
  },
  productIMg: {
    width: '34px',
    borderRadius:'2px',
    height:'34px',
    marginBottom:"12px",
  },
  ArrivingOutCell: {
    backgroundColor: '#F4F4F4',
    padding: '12px 16px',
    justifyContent: "space-between",
    display: "flex",
  },
  ArrivingTextOutValue: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "14px",
    marginLeft: "8px"
  },
  ArrivingTextOut: {
    color: "#375280",
    fontWeight: 400,
    fontSize: "14px",
  },
  readyForCollectionTableContainer: {
    marginTop: "20px"
  },
  tabsBoxReady: {
    paddingBottom: '20px',
    width: '100%',
    alignItems: 'center',
    height: '75%',
    justifyContent: 'center',
    display: 'flex',
  },
  paginationMainBox: {
    justifyContent: 'space-between',
    display: 'flex',
    margin: "23px 0px",
    alignItems: 'center',
  },
  CircularProgressColor: {
    color: "#375280"
  },
  paginationSecBox: {
    fontSize: "14px", 
    color: "#475569", 
    fontFamily: 'Lato',
    fontWeight: 500, 
  }
}
const CustomTableRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    borderBottom: "none"
  }
})
const CustomOutStyledTable = styled(TableContainer)({
  '& .cell': {
    backgroundColor: '#ffffff',
    borderRadius: "5px 5px 0px 0px"
  },
  '& .MuiTableCell-sizeSmall': {
    padding: "15px 30px 15px 30px"
  },
  '& .custom-table': {
    border: '1px solid #E2E8F0',
    borderRadius: "5px 5px 0px 0px"
  },
  "& .radyToOrderStatusBtn": {
    width: "147px",
    color: "rgb(55, 82, 128)",
    height: "34px",
    border: "1px solid rgb(204, 190, 177)",
    fontWeight: 700,
    borderRadius: "2px",
    fontSize: "12px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  "& .outForDeliveryStatusBtn": {
    width: "147px",
    color: "#6200EA",
    border: "none",
    fontWeight: 500,
    height: "34px",
    borderRadius: "2px",
    fontSize: "12px",
    textTransform: "none",
    backgroundColor: "#F0E5FF",
  },
  "& .radyToArrivingBox": {
    width: "100%",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    borderRadius: "2px",
    backgroundColor: "#F4F4F4",
    textTransform: "none",
  }
})
const CustomStyledPagination = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  '& .MuiPaginationItem-textPrimary.Mui-selected': {
    backgroundColor: "#375280"
  },
  "& li:last-of-type .MuiPaginationItem-icon": {
    transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
},
  '& .MuiPaginationItem-root': {
      color: '#375280',
  },
  '& .Mui-selected': {
      color: 'white',
  },
  "& li:first-of-type .MuiPaginationItem-icon": {
    transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
},
}));
// Customizable Area End
