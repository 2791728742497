import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Box,
  styled,
  Dialog,
  Button,
} from "@material-ui/core";
import InventoryAssignStoreController, {
  Props,
} from "./InventoryAssignStoreController";
import {
  CustomStyledPagination,
  CustomStyledTableUpdateInventory,
  PaginationContainer,
  TableHeaderCell,
} from "../UpdateInventory/UpdateInventory.web";
import { CheckboxContainer } from "../SellerInventoryManagement.web";
import ReusableSnackbar from "../../../../../../components/src/AlertContainer";
import Loader from "../../../../../../components/src/LoaderContainer";
import i18n from "../../../../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class InventoryAssignStore extends InventoryAssignStoreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderAssignStorePagination = () => (
    <>
      {this.state.assignInventoryMeta !== undefined ? (
        <PaginationContainer>
          <Box className="pagination-data-container">
            {`${(this.state.assignInventoryMeta?.current_page - 1) * 10 + 1} - 
              ${Math.min(
                this.state.assignInventoryMeta?.current_page * 10,
                this.state.assignInventoryMeta?.total_record
              )}
            ${this.tranAssignStore("of")} ${this.state.assignInventoryMeta?.total_record} ${this.tranAssignStore("results")}`}
          </Box>
          <CustomStyledPagination
            data-test-id="as-pagination-test-id"
            page={this.state.page}
            count={this.state.assignInventoryMeta?.total_pages}
            shape="rounded"
            color="primary"
            onChange={this.handleAssignInventoryPageChange}
            dir={i18n.dir()}
          />
        </PaginationContainer>
      ) : (
        <></>
      )}
    </>
  );

  renderAssignStoreBody = () => (
    <>
      {this.state.tableBodyData.length === 0 ? (
        <TableRow>
          <TableCell colSpan={3}>
            <NoRecordTypoContainer>{this.tranAssignStore("No Records Found")}</NoRecordTypoContainer>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {this.state.tableBodyData.map((assignStoreInventoryData) => (
            <TableRow key={assignStoreInventoryData.id} className="hover">
              <TableCell data-test-id="product-name">
                <Box className="product-name-container">
                  <img
                    src={assignStoreInventoryData.front_image}
                    className="img-container"
                    alt={assignStoreInventoryData.product_name}
                  />
                  <Typography className="product-title">
                    {assignStoreInventoryData.product_name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography className="product-sku">
                  {assignStoreInventoryData.sku}
                </Typography>
              </TableCell>
              <TableCell>
                <Box className="check-box-container"><CheckboxContainer
                  color="secondary"
                  data-test-id={`set-price-checked-${assignStoreInventoryData.id}`}
                  checked={this.handleCheckedValue(
                    assignStoreInventoryData.isSelected
                  )}
                  onChange={() =>
                    this.handleProductCheckboxChange(
                      assignStoreInventoryData.id,
                      !assignStoreInventoryData.isSelected
                    )
                  }
                />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <CustomStyledTableUpdateInventory data-test-id="as-table">
          <Table stickyHeader size="small" className="custom-table">
            <TableHead>
              <TableRow
                style={{ backgroundColor: "#f0f0f0" }}
                className="as-table-head-row"
              >
                {this.state.tableHeadName.map(
                  (header: string, index: number) => (
                    <TableHeaderCell key={index} className="as-table-head-cell">
                      {header === "Select Products" ? (
                        <ASCheckTableHeadBox>
                          {this.state.tableBodyData.length!==0 && <CheckboxContainer
                            color="secondary"
                            data-test-id={`select-all-product`}
                            checked={this.state.isAllSelectedProduct}
                            onChange={() =>
                              this.handleAllProductCheckboxChange()
                            }
                          />}
                          <Typography className="table-head-title">
                            {this.tranAssignStore(header)}
                          </Typography>
                        </ASCheckTableHeadBox>
                      ) : (
                        <Typography className="table-head-title">{this.tranAssignStore(header)}</Typography>
                      )}
                    </TableHeaderCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Loader
                      id="step-3-loader"
                      dataTestId="step-2-loader-test"
                    />
                  </TableCell>
                </TableRow>
              ) : (
                this.renderAssignStoreBody()
              )}
            </TableBody>
          </Table>
        </CustomStyledTableUpdateInventory>
        {this.state.assignInventoryMeta.total_record > 10 &&
          this.renderAssignStorePagination()}
        <WarningDialogContainer
          data-test-id="as-warning-dialog"
          open={this.state.warningOpen}
          dir={i18n.dir()}
        >
          <Box className="content-container">
            <Typography className="text-container">
              {this.tranAssignStore("You have unsaved changes.")}
            </Typography>
            <Typography className="text-container">
            {this.tranAssignStore("Are you sure you want to leave this page?")}
            </Typography>
            <Typography className="text-container">
              {this.tranAssignStore("If")} <span className="success-color">{this.tranAssignStore("No")}</span> 
              {this.tranAssignStore("Please click on Assign Store to Assign store Otherwise")}{" "}
              <span className="error-color">{this.tranAssignStore("Yes")}</span>
            </Typography>
          </Box>
          <BtnContainer className="btn-container">
            <Button
              variant="outlined"
              onClick={() => this.handleNo()}
              className={"no-btn"}
              disableElevation
              data-test-id="as-warning-no-test-id"
            >
              {this.tranAssignStore("No")}
            </Button>
            <Button
              variant="contained"
              className={"yes-btn"}
              disableElevation
              onClick={() => this.handleYes()}
              data-test-id="as-warning-yes-test-id"
            >
              {this.tranAssignStore("Yes")}
            </Button>
          </BtnContainer>
        </WarningDialogContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertSnackClose}
          severity={"error"}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const ASCheckTableHeadBox = styled(Box)({
  display: "flex",
  gap: 10,
  alignItems: "center"
});

export const NoRecordTypoContainer = styled(Typography)({
  textAlign: "center",
  fontFamily: "Lato, sans-serif",
  fontWeight: 500,
  fontSize: 20,
  color: "#375280",
});

export const WarningDialogContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      padding: "50px 30px",
      "& .text-container": {
        textAlign: "center",
        fontFamily: "Lato, sans-serif",
        fontWeight: 500,
        fontSize: 20,
        color: "#375280",
        paddingBottom: 15,
        "& .error-color": {
          color: "#d32f2f",
          fontWeight: 700,
        },
        "& .success-color": {
          color: "#388e3c",
          fontWeight: 700,
        },
      },
    },
  },
});

export const BtnContainer = styled(Box)({
  display: "flex",
  gap: 16,
  height: "100%",
  "& .no-btn": {
    textTransform: "capitalize",
    width: "50%",
    color: "#375280",
    borderColor: "#CCBEB1",
    height: "100%",
    "& .MuiButton-label": {
      fontSize: "18px",
      fontWeight: 500,
    },
  },
  "& .yes-btn": {
    textTransform: "capitalize",
    color: "white",
    gap: "8px",
    width: "50%",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
    height: "100%",
    borderRadius: "2px",
    "& .MuiButton-label": {
      fontSize: "18px",
      fontWeight: 600,
    },
  },
});
// Customizable Area End
