Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "savedcards";
exports.labelBodyText = "savedcards Body";

exports.btnExampleTitle = "CLICK ME";
exports.publicKey = "pk_test_Aa92YiMZVx6mI0ro3cndLWXK"
exports.authorizePost = "POST"
exports.authorizeCard = "bx_block_tappaymentsintegration/tappayment/authorize";
exports.authorizeGet = "GET";
exports.authorizeCardGet = "bx_block_tappaymentsintegration/tappayment/authorize?authorize";
exports.authorizeAllCardGet = "bx_block_tappaymentsintegration/tappayment/list_card";
exports.authorizeCardDelete = "DELETE"
exports.authorizeAllCardDelete = "bx_block_tappaymentsintegration/tappayment/delete_card"
// Customizable Area End