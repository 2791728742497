/*global google*/
import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,withScriptjs
} from "react-google-maps";

interface MapComponentProps {
  googleMapURL: string;
  loadingElement: JSX.Element;
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
  travelMode?: google.maps.TravelMode;
}
interface MapComponentState {
  directions: google.maps.DirectionsResult | null;
}

class TrackMap extends Component<MapComponentProps,MapComponentState> {
  state: MapComponentState = {
    directions: null,
  };
  private directionsService: google.maps.DirectionsService;
  constructor(props: MapComponentProps) {
    super(props);
    this.directionsService = new google.maps.DirectionsService();
  }

  componentDidMount() {
    this.calculateRoute();
  }

  componentDidUpdate(prevProps: MapComponentProps) {
    if (
      prevProps.origin !== this.props.origin ||
      prevProps.destination !== this.props.destination
    ) {
      this.calculateRoute();
    }
  }

  calculateRoute = () => {
    const { origin, destination, travelMode } = this.props;

    this.directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode || google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({ directions: result });
        } else {
          console.error("Error fetching directions:", status);
          this.setState({ directions: null });
        }
      }
    );
  }

  render() {
    const { origin } = this.props;
    const { directions } = this.state;
    const GoogleMapExample = withGoogleMap(() => (
      <GoogleMap
        defaultCenter={origin}
        defaultZoom={13}
      >
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                strokeColor: "#375280",
                strokeOpacity: 0.7,
                strokeWeight: 5,
              },
              suppressMarkers: false,
            }}
          />
        )}
      </GoogleMap>
    ));

    return (
      <div>
        <GoogleMapExample containerElement={<div className="containerElementHeight" />}
          mapElement={<div style={{ height: '100%' }} />} />
      </div>
    );
  }
}

export default TrackMap;