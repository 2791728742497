import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type AlertType = 'success' | 'error' | 'warning' | 'info';
export const configJSON = require("./config");
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";

export interface CartOrder {
  id: number;
  type: string;
  attributes: {
  id: number;
  order_number: string;
  title: string;
  amount: number | null;
  order_item_count: number;
  account_id: number;
  coupon_code_id: number | null;
  delivery_address_id: number;
  sub_total: string;
  total: string;
  status: string;
  custom_label: string | null;
  applied_discount: string;
  cancellation_reason: string | null;
  order_date: string | null;
  is_gift: boolean;
  placed_at: string | null;
  confirmed_at: string | null;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  source: string | null;
  shipment_id: string | null;
  delivery_charges: number | null | string;
  tracking_url: string | null;
  schedule_time: string | null;
  payment_failed_at: string | null;
  payment_pending_at: string | null;
  returned_at: string | null;
  tax_charges: string;
  deliver_by: string | null;
  tracking_number: string | null;
  is_error: boolean;
  delivery_error_message: string | null;
  order_status_id: number;
  is_group: boolean;
  is_availability_checked: boolean;
  shipping_charge: string;
  shipping_discount: string;
  shipping_net_amt: string;
  applied_copon_code: string | null,
  shipping_total: string;
  total_tax: number;
  created_at: string;
  updated_at: string;
  order_deliver_date: string | null;
  order_deliver_time: string | null;
  delivery_addresses: DeliveryAddress;
  order_return_date: string | null;
  order_return_time: string | null;
  razorpay_order_id: string | null;
  charged: number | null;
  invoice_id: string | null;
  invoiced: boolean | null;
  order_items: OrderItem[];
  payment_detail: null;
  }
}

interface DeliveryAddress {
  id: string;
  type: string;
  attributes: {
      name: string;
      country_code: string;
      phone_number: string;
      contact_number: string;
      street: string;
      zip_code: string;
      area: string;
      block: string;
      city: string;
      house_or_building_number: string;
      address_name: string;
      is_default: boolean;
      latitude: number;
      longitude: number;
  };
}

interface OrderItem {
  id: string;
  type: string;
  attributes: {
      status: string;
      placed_at: string | null;
      confirmed_at: string | null;
      in_transit_at: string | null;
      delivered_at: string | null;
      cancelled_at: string | null;
      rejected_at: string | null;
      process_at: string | null;
      shipped_at: string | null;
      return_at: string | null;
      return_cancel_at: string | null;
      return_pick_at: string | null;
      quantity: number;
      unit_price: string;
      total_price: string;
      reason_of_rejection: string | null;
      catalogue_name: string;
      brand_name: string;
      catalogue_variant_color: string;
      catalogue_variant_sku: string;
      store_name: string;
      catalogue_variant_size: string;
      catalogue_variant_front_image: string;
      catalogue_variant_back_image: string;
      catalogue_variant_side_image: string;
      driver_name: string | null;
      driver_latitude: number | null;
      driver_longitude: number | null;
      driver_phone_number: string | null;
      otp: string | null;
  };
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  shippingChoice: string;
  shippingMethod: string;
  paymentMethod: string;
  stylishBuyer: boolean;
  saveCard: boolean;
  activeAddCardForm: string;
  cardNumber: string;
  cvv: string;

  isModalOpen: boolean;
  baseUrl: string;
  ActiveCartView: CartOrder;
  AddAddressData: CartOrder;
  inputPromrValue:string;
  appliedCoponCode: string | null;
  isAlert:boolean;
  alertType: AlertType,
  circularProgress: boolean;
  errorLabale: string,
  formattedNumber:null | number | string;
  currencySign: string;
  isBackDrop: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getActiveCartViewApiCallId: string = ""
  getPlaceOrderApiId: string = ""
  getAddAddressOrderApiCallId: string = ""
  getPlaceretRieveChargeOrderApiId:string = ""
  getpromoCodeId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];
    this.state = {
      shippingChoice: "same",
      currencySign: "$",
      shippingMethod: "standard",
      paymentMethod: "",
      saveCard: true,
      activeAddCardForm: "creditCard",
      cardNumber: "",
      cvv: "",
      stylishBuyer: false,
      isModalOpen: false,
      ActiveCartView: {} as CartOrder,
      baseUrl: "",
      AddAddressData: {} as CartOrder,
      inputPromrValue:"",
      appliedCoponCode: "",
      isAlert:false,
      alertType: 'success' as AlertType,
      circularProgress: false,
      errorLabale: "",
      formattedNumber:null,
      isBackDrop: true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const buyerStylish = await getStorageData('exploreBuyer', true);
    if(buyerStylish) this.setState({stylishBuyer: buyerStylish})
    await this.componentRender();
    const basePath = window.location.origin
    this.setState({ baseUrl : basePath, 
      isBackDrop: true})
    const getTapId = await getStorageData("tapId")
    this.retrieveChargeOrderApi(getTapId);
    // Customizable Area End
  }

  componentRender = async () =>{
    const paymentType = await getStorageData("payment-details");
    if(paymentType==="Direct-Order"){
      this.getAddAddressOrderApi()
    } else if(paymentType==="CheckOut"){
      this.getActiveCartViewApi();
      this.getAddAddressOrderApi();
    }
  }

  // Customizable Area Start
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getActiveCartViewApiCallId){
      const formattedNumbers = parseFloat(responseJson.data.attributes.delivery_charges).toFixed(2);
      this.setState({ ActiveCartView: responseJson.data,formattedNumber:formattedNumbers,appliedCoponCode: responseJson.data.attributes.applied_copon_code,inputPromrValue:responseJson.data.attributes.applied_copon_code, currencySign: responseJson.data.attributes.currency_logo })
      setStorageData("selectOrderId", responseJson.data.id)
    }
    if(apiRequestCallId === this.getPlaceOrderApiId){
      setStorageData("tapId", responseJson.id)
     window.location.href = responseJson.transaction.url
    }
    if(apiRequestCallId === this.getAddAddressOrderApiCallId){
      if (responseJson.message === "Address not exists or does not belong to the user." || responseJson.message === "Address not exits or not belongs to user.") {
        this.setState(
          {
            isAlert: true,
            alertType: "error",
            errorLabale: responseJson.message,
            inputPromrValue: ""
          },
          () => {
            setTimeout(() => {
              this.proceedToShippingAddressPage();
            }, 2000);
          }
        );
      }
      else{
        const userDetails = await getStorageData('userRole', true)
        if(userDetails?.currencyLogo){
          this.setState({
            currencySign: userDetails?.currencyLogo
          })
        }
        const formattedNumbers = parseFloat(responseJson.data.attributes.delivery_charges).toFixed(2);
        this.setState({ AddAddressData: responseJson.data, ActiveCartView: responseJson.data, formattedNumber:formattedNumbers,appliedCoponCode: responseJson.data.attributes.applied_copon_code,inputPromrValue:responseJson.data.attributes.applied_copon_code});
        setStorageData("selectOrderId", responseJson.data.id);
      }
    }
    if (apiRequestCallId === this.getPlaceretRieveChargeOrderApiId) {
      await this.handlePaymentResp(responseJson.error)
    }
    this.coupenCodeRespons(message)
    // Customizable Area End
  }
  // Customizable Area Start
  onChangeHandler = (stateKey: any, value: string) => {
    this.setState({ [stateKey]: value } as Pick<S, keyof S>);
  };

  handlePaymentResp = async (error: string) => {
    const getTapId = await getStorageData("tapId")
      if (error === "Payment not captured." && window.location.href.includes(getTapId)) {
        this.setState({ isModalOpen: false, 
          isBackDrop: false, isAlert: true, errorLabale: error, alertType: "error"})
      } else if (error === "Payment not captured." ) {
        this.setState({ isModalOpen: false, 
          isBackDrop: false})
      } else {
        if (window.location.href.includes(getTapId)) {
          removeStorageData("orderNumber")
          this.setState({ isModalOpen: true, isBackDrop: false })
        }
      }
  }
  creditCardInputOnChange = (value: string) => {
    let formattedValue = "";
    if (value.length === 20) return;
    if (
      value.length < this.state.cardNumber.length &&
      value.slice(-1) === "-"
    ) {
      formattedValue = value.slice(0, -1);
    } else if (!/^\d+$/.test(value.slice(-1)) && value.length !== 0) {
      return;
    } else if (
      value.length === 4 ||
      value.length === 9 ||
      value.length === 14
    ) {
      formattedValue = value + "-";
    } else formattedValue = value;
    this.setState({ cardNumber: formattedValue });
  };

  digitsValidation = (value: string) => {
    if (!/^\d+$/.test(value.slice(-1)) && value.length !== 0) return;
    this.setState({ cvv: value });
  };

  getActiveCartViewApi = async () => {
    const unique_token = await getStorageData("unique_token");
    const token = await getStorageData("auth-token");
    
    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActiveCartViewApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUnique_token}=${unique_token}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  proceedToTrackOrderPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "OrderManagementPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  proceedToShippingAddressPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Shippingaddressvalidation2");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getAddAddressOrderApi = async () => {
    const selectedAddressID = await getStorageData("selectedAddressID");
    const selectedOrderID = await getStorageData("selectOrderId");    
    const token = await getStorageData("auth-token");
    
    const headers = {
      "token": token
    };
    const formData = new FormData();    
    formData.append("order_id", selectedOrderID)
    formData.append("address_id", selectedAddressID)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddAddressOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAddAddress}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  placeOrderApi = async () => {
    this.setState({isBackDrop: true})
    const authToken = await getStorageData("auth-token");
    const placeOrderData = {
      "data": {
        "temp_order_id": this.state.ActiveCartView.id,
        "redirect_url": `${this.state.baseUrl}/PaymentDetails`
      }
    }
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPlaceOrderApiId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.charges}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(placeOrderData)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  retrieveChargeOrderApi = async (getTapId:string) => {
    const authToken = await getStorageData("auth-token");
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPlaceretRieveChargeOrderApiId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.retrieve_charge}=${getTapId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  saveCardChange = () =>
    this.setState((prev) => ({ saveCard: !prev.saveCard }));

  changeActiveAddCardForm = (activeForm: string) =>
    this.setState({ activeAddCardForm: activeForm });
  
  handleInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ inputPromrValue: event.target.value as string });
  };

  promoCodeButton = async () => {
    if (this.state.appliedCoponCode === null) {
      this.promoCode()
    } else {
      this.removedPromoCode()
    }
  }
  promoCode = async () => {
    const token = await getStorageData("auth-token");
    const body = {
      "order_id": this.state.ActiveCartView.attributes.id,
      "code": this.state.inputPromrValue,
      "cart_value": this.state.ActiveCartView.attributes.total
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getpromoCodeId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_addCart
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.applyCoupon}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  removedPromoCode = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getpromoCodeId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.removedApiMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removedCode}=${this.state.ActiveCartView.attributes.id}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  coupenCodeRespons = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getpromoCodeId !== null &&
      this.getpromoCodeId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        if (responseJson.message) {
          this.setState({ isAlert: true, alertType: responseJson.message === "Coupon removed successfully" ? "success" : "error", errorLabale: responseJson.message ,inputPromrValue:""},()=>this.componentRender())
        } else {
          this.setState({ circularProgress: false, isAlert: true, alertType: "success", errorLabale: responseJson.data.message, },()=> this.componentRender())
        }
      }
    }
  }
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  transPay = (payKey: string) => {
    return i18n.t(payKey, { ns: "shoppingCart" })
}
  // Customizable Area End
}
