import React from "react";
// Customizable Area Start
import { TextField, styled, Box, Button, Typography, Paper } from "@material-ui/core";
import { back_button, upperBody } from "./assets";
// Customizable Area End

import MeasurementController, {
    Props,
    configJSON,
} from "./MeasurementController.web";

export default class UpperBodyMeasurement extends MeasurementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { neckbase, shoulderfront, shoulderback, chest, bicep, waist, highhip } = this.state;
        const buttonDisabled = !neckbase || !shoulderfront || !shoulderback || !chest || !bicep || !waist || !highhip;
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainWrapper >
                <Box className="mainBox">
                    <HeadingWrapper>
                        <Paper data-test-id="navigateBack" onClick={() => this.handleNavigation('BasicDetails')} elevation={1} className="back_icon_Paper">
                            <img src={back_button} alt="back_button" />
                        </Paper>
                        <Typography className="headingText">{configJSON.MeasurementHeading}</Typography>
                        <Button data-test-id="nextButton" onClick={this.handleUpperBodyFormSubmit} disabled={buttonDisabled} className={buttonDisabled ? "headingButton" : "headingButtonEnable" }>Next</Button>
                    </HeadingWrapper>
                    <ImageWrapper>
                        <img width={'100%'} src={upperBody} alt="upper_body" />
                    </ImageWrapper>
                    <FieldsWrapper>
                        <Typography className="contentHeading">Upper Body Measurements (In cms.) </Typography>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Neck Base</Typography>
                            <TextField
                                data-test-id="neckbase"
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Neck Base"
                                name="neckbase"
                                value={neckbase}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Across Shoulder Front</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter shouler Front"
                                name="shoulderfront"
                                value={shoulderfront}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Across Shoulder Back</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter shoulder back"
                                name='shoulderback'
                                value={shoulderback}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Chest</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Chest"
                                name='chest'
                                value={chest}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Bicep</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Bicep"
                                name='bicep'
                                value={bicep}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Waist</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Waist"
                                name='waist'
                                value={waist}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">High Hip</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter High Hip"
                                name='highhip'
                                value={highhip}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                    </FieldsWrapper>
                </Box>
            </MainWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    "& .mainBox": {
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        maxWidth: '400px',
        flexDirection: 'column',
        paddingInline: '48px',
        gap: '20px',
        marginBottom: '50px',
    },
    "& .avatar_heading": {
        marginBlock: "30px 14px",
        color: '#375280',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '26px',

    },
});

const HeadingWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '48px',
    "& .back_icon_Paper": {
        borderRadius: '50%',
        padding: '8px'
    },
    "& .headingText": {
        fontFamily: 'Avenir,sans-serif',
        color: '#375280',
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: '26px',

    },
    "& .headingButton": {
        fontFamily: 'Lato,sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '26px',
        textTransform: 'none',
        textDecoration: 'underline',
        color: '#94A3B8',
        minWidth: '0px',
        padding: '0px',

        "&.MuiButton-root:hover": {
            backgroundColor: '#fff'
        }


    },
    "& .headingButtonEnable": {
        fontFamily: 'Lato,sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '26px',
        textTransform: 'none',
        textDecoration: 'underline',
        color: '#375280',
        minWidth: '0px',
        padding: '0px',

        "&.MuiButton-root:hover": {
            backgroundColor: '#fff'
        }


    },
});

const ImageWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const FieldsWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '6px',

    "& .contentHeading": {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Lato, sans-serif',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '26px',
        color: '#375280',
    },

    "& .contentBox": {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '6px',
    },

    "& .inputHeading": {
        color: "#375280",
        textOverflow: "ellipsis",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "24px",
    },

    "& .inputFeild": {
        "& .MuiTextField-root": {
            "&:hover": {
                border: "1px solid #F0F0F0 !important",
            },
        },
        "& .MuiOutlinedInput-root:hover": {
            border: "none !important",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",

        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        }
    },
});

// Customizable Area End
