export const enStylistPortfolio = {
 "Delete":"Delete",
 "Delete Selected":"Delete Selected",
 "Delete Gallary":"Delete Gallary",
 "Save":"Save",
 "Close":"Close",
 "Select Image":"Select Image",
 "Show Image":"Show Image",
 "Add New Image":"Add New Image",
 "Show Images":"Show Images",
 "Please select an image":"Please select an image",
 "Share":"Share",
 "Account ID":"Account ID",
 "Account ID cannot be empty":"Account ID cannot be empty",
 "Select All Images":"Select All Images",
 "No Data Found":"No Data Found",
 "Upload Image":"Upload Image",
 "Upload Images": "Upload Images",
 "Only png or Jpg files are accepted":"Only png or Jpg files are accepted",
 "Or":"Or",
 "Upload Multiple Images":"Upload Multiple Images",
 "Description":"Description",
 "Replace Image":"Replace Image",
 "Cancel":"Cancel",
 "Submit":"Submit",
 "Images":"Images",
 "Portfolio":"Portfolio",
 "Edit Portfolio":"Edit Portfolio",
 "Upload Portfolio":"Upload Portfolio",
 "of":"of",
 "results":"results",
 "Delete Images?":"Delete Images?",
 "All the selected posts will be deleted. Are you sure you want to delete all the Images?":"All the selected posts will be deleted. Are you sure you want to delete all the Images?",
 "Replace Photo":"Replace Photo",
 "Your images has been successfully uploaded.":"Your images has been successfully uploaded.",
 "Update": "Update",
 "Enter Description": "Enter Description",
 "upload ui": "upload ui",
 "Delete Image?": "Delete Image?",
 "Are you sure you want to delete the particular portfolio?": "Are you sure you want to delete the particular portfolio?"
}

export const arStylistPortfolio = {
 "Delete":"الحذف",
 "Delete Selected":"حذف المحدد",
 "Delete Gallary":"حذف جاليري",
 "Save":"يحفظ",
 "Close":"يغلق",
 "Select Image":"حدد الصورة",
 "Show Image":"إظهار الصورة",
 "Add New Image":"إضافة صورة جديدة",
 "Show Images":"عرض الصور",
 "Please select an image":"الرجاء اختيار صورة",
 "Share":"يشارك",
 "Account ID":"معرف الحساب",
 "Account ID cannot be empty":"لا يمكن أن يكون معرف الحساب فارغًا",
 "Select All Images":"حدد كافة الصور",
 "No Data Found":"لم يتم العثور على بيانات",
 "Upload Image": "رفع صورة",
 "Upload Images": "رفع صورة",
 "Only png or Jpg files are accepted":"یتم قبول ملفات png وjpg فقط",
 "Or":"أو",
 "Upload Multiple Images": "رفع أكثر من صورة",
 "Description":"الوصف",
 "Replace Image":"استبدال الصورة",
 "Cancel":"الإلغاء",
 "Submit":"إرسال",
 "Images":"الصور",
 "Portfolio":"المعرض",
 "Edit Portfolio": "تعديل المعرض",
 "Upload Portfolio": "رفع عناصر المعرض",
 "of":"ل",
 "results":"نتائج",
 "Delete Images?":"هل تريد حذف الصور؟",
 "All the selected posts will be deleted. Are you sure you want to delete all the Images?":"سيتم حذف كافة المشاركات المحددة. هل أنت متأكد أنك تريد حذف جميع الصور؟",
 "Replace Photo":"استبدال الصورة",
 "Your images has been successfully uploaded.":"لقد تم تحميل الصور الخاصة بك بنجاح.",
 "Update": "تحذيث",
 "Enter Description": "أدخل الوصف",
 "upload ui": "رفع واجهة المستخدم",
 "Delete Image?": "هل تريد حذف الصورة؟",
 "Are you sure you want to delete the particular portfolio?": "هل أنت متأكد أنك تريد حذفمَلَفّ  المحددة؟"
}