import React from "react";
// Customizable Area Start

import {  styled, Box, Button, Typography, Paper } from "@material-ui/core";
import { back_button, lucy_avatar, tom_avatar } from "./assets";
// Customizable Area End

import Cfvirtualmannequin3Controller, {
  Props,
  configJSON,
} from "./Cfvirtualmannequin3Controller";

export default class Cfvirtualmannequin3 extends Cfvirtualmannequin3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedAvatar } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainWrapper >
        <Box className="mainBox">
          <HeadingWrapper>
            <Paper elevation={1} className="back_icon_Paper">
              <img src={back_button} alt="back_button" />
            </Paper>
            <Typography className="headingText">{configJSON.selectAvatarHeading}</Typography>
            <Button data-test-id="nextButton" onClick={this.handleAvatarSubmit} disabled={!selectedAvatar} className={selectedAvatar ? "headingButtonEnable" : "headingButton"}>Next</Button>
          </HeadingWrapper>
          <Typography className="avatar_heading">
            Your Avatar
          </Typography>
          <ContentWrapper>
            <Box className="avatarWrapper" >
              <Paper data-test-id="tom_avatar" onClick={() => this.handleAvatarSelect('tom')} elevation={1} className={selectedAvatar === 'tom' ? "avatorWrapperPaperSelected" : "avatorWrapperPaper"} >
                <img src={tom_avatar} alt="tom_avatar" />
              </Paper>
              <Typography className="avatorNameStyle">
                Tom’s Avatar
              </Typography>
            </Box>
            <Box className="avatarWrapper" >
              <Paper data-test-id="lucy_avatar" onClick={() => this.handleAvatarSelect('lucy')} elevation={1} className={selectedAvatar === 'lucy' ? "avatorWrapperPaperSelected" : "avatorWrapperPaper"}>
                <img src={lucy_avatar} alt="lucy_avatar" />
              </Paper>
              <Typography className="avatorNameStyle">
                Lucy’s Avatar
              </Typography>
            </Box>
          </ContentWrapper>
          <AvatarButtonWrapper>
            <Button className="useStockAvatarButton" >Use Stock Avatar </Button>
            <Typography className="buttonDividerText">Or</Typography>
            <Button className="createNewAvatarButton">Create New Avatar</Button>
          </AvatarButtonWrapper>
        </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  "& .mainBox": {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    maxWidth: '400px',
    flexDirection: 'column',
    paddingInline: '48px',
  },
  "& .avatar_heading": {
    marginBlock: "30px 14px",
    color: '#375280',
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',

  },
});

const HeadingWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '48px',
  "& .back_icon_Paper": {
    borderRadius: '50%',
    padding: '8px'
  },
  "& .headingText": {
    fontFamily: 'Avenir, sans-serif',
    color: '#375280',
    fontSize: '20px',
    fontWeight: 800,
    lineHeight: '26px',

  },
  "& .headingButton": {
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '26px',
    textTransform: 'none',
    textDecoration: 'underline',
    color: '#94A3B8',
    minWidth:'0px',
    padding:'0px',

    "&.MuiButton-root:hover": {
      backgroundColor: '#fff'
    }


  },
  "& .headingButtonEnable": {
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '26px',
    textTransform: 'none',
    textDecoration: 'underline',
    color: '#375280',
    minWidth:'0px',
    padding:'0px',

    "&.MuiButton-root:hover": {
      backgroundColor: '#fff'
    }


  },
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '24px',
  "& .avatarWrapper": {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },
  "& .avatorWrapperPaper": {
    padding: '28px 20px',
    border: '1px solid #E2E8F0',
  },
  "& .avatorWrapperPaperSelected": {
    padding: '28px 20px',
    border: '2px solid #B2A69B',
  },
  "& .avatorNameStyle": {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '28px',
    color: '#375280',
  },
});

const AvatarButtonWrapper = styled(Box)({
  marginTop: 'auto',
  marginBottom: '56px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  "& .useStockAvatarButton": {
    textTransform: "none",
    color: "#375280 !important",
    backgroundColor: "#FFFFFF !important",
    gap: "8px",
    width: "100% !important",
    border: '1px solid #CCBEB1 !important',
    borderRadius: "2px !important",
    height: "64px !important",
    "& .MuiButton-label": {
      fontWeight: 500,
      fontSize: "18px",
      fontfamily: 'Lato !important',

    }
  },
  "& .buttonDividerText": {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'Lato',
    color: '#375280'
  },
  "& .createNewAvatarButton": {
    textTransform: "capitalize",
    color: "white !important",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    width: "100% !important",
    borderRadius: "2px !important",
    height: "56px !important",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },

});
// Customizable Area End
