import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { ErrorMessage } from "../../../CreateSellerStoreController";
import { ErrorMessageResponse } from "../../../SellerStoreController";
import { apiCall } from "../../../../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import { MetaResponse } from "../../Catalogues/SellerCataloguesController";
import { logoutSellerNavigation } from "../../../../../../components/src/Seller/logOut";
import i18n from "../../../../../../components/src/i18next/i18n";

interface IUpdateInventoryAttributes {
  id: number;
  catalogue_id: number;
  product_name: string;
  product_description: string;
  sku: string;
  stock_qty: number;
  low_stock_threshold: number;
  is_listed: boolean;
  price: string;
  size: string;
  colour: string;
  discounted_price: string;
	discounted_percentage: string;
  gender: string;
  front_image: string;
  brand_name: string;
  pair_it_with: string[];
}
export interface IInventoryResponse {
  data: InventoryState[];
}

export interface IInventoryPaginationResponse extends IInventoryResponse {
  meta: MetaResponse;
}

export interface InventoryState {
  id: string;
  type: string;
  attributes: IUpdateInventoryAttributes;
}

export interface ExtractedInventoryItem {
  id: number;
  product_name: string;
  is_listed: boolean;
  front_image: string;
  sku: string;
  price: string;
  low_stock_threshold: number;
  stock_qty: number;
  pair_it_with: string[];
  discounted_percentage: string;
  discounted_price: string;
}

export interface CatalogueItems {
  id: number;
  stock_qty: number;
  low_stock_threshold: number;
  is_listed: boolean;
  price: string;
  discounted_percentage: string;
  discounted_price: string;
}
// Customizable Area End

export const configJSON = require("../../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  filter: {
    stock_status: string;
    listing?: boolean;
  };
  searchValue: string;
  handleUpdateStore: (updatedData: CatalogueItems[]) => void;
  confirmData: () => void;
  removeData: boolean;
  handleRedirect: (redirect: string) => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tableHeadName: string[];
  responseInventoryData: InventoryState[];
  responseBodyData: ExtractedInventoryItem[];
  tableBodyData: ExtractedInventoryItem[];
  updatedCatalogues: CatalogueItems[];
  updateInventoryMeta: MetaResponse;
  page: number;
  roleData: string;
  isAlert: boolean;
  message: string;
  severity: "success" | "error";
  isLoading: boolean;
  warningOpen: boolean;
  newPage: number;
  update: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerUpdateInventoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFilterListInventoryAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tableHeadName: updateInventoryTableHead,
      responseInventoryData: [],
      responseBodyData: [],
      tableBodyData: [],
      updatedCatalogues: [],
      updateInventoryMeta: {} as MetaResponse,
      page: 1,
      newPage: 1,
      roleData: "",
      isAlert: false,
      message: "",
      severity: "success",
      isLoading: true,
      warningOpen: false,
      update: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseData && !responseData.errors && !responseData.error) {
        this.apiUpdateInventorySuccess(apiRequestCallId, responseData);
      } else if (responseData && (responseData.error || responseData.errors)) {
        this.apiUpdateInventoryFailer(responseData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleConfirm = async () => {
    await this.handleConfirmCancel();
    this.props.confirmData();
  };

  handleCancel = async () => {
    await this.handleConfirmCancel();
    this.setState((prevState)=>({...prevState, updatedCatalogues:[]}));
    this.props.handleUpdateStore([]);
  };

  handleConfirmCancel = async () =>{
    if(this.state.update === "page"){
      await this.fetchInventoryData(
        this.props.searchValue,
        this.props.filter,
        this.state.newPage
      );
      this.setState((prevState)=> ({...prevState,page:prevState.newPage}))
    }
    else if(this.state.update === "search-filter"){
      await this.fetchInventoryData(
        this.props.searchValue,
        this.props.filter,
        this.state.page
      );
    }
    this.setState((prevState) => ({
      ...prevState,
      warningOpen: !prevState.warningOpen,
      update: ""
    }));
  }

  onAlertUISnackClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: !prevState.isAlert,
    }));
  };

  apiUpdateInventorySuccess = async (
    apiRequestCallId: string,
    responseJson: IInventoryPaginationResponse
  ) => {
    if (
      apiRequestCallId === this.getFilterListInventoryAPICallId
    ) {
      if (responseJson) this.handleUpdateInventoryResp(responseJson);
    }
  };

  handleUpdateInventoryResp = (response: IInventoryPaginationResponse) => {
    this.setState((prevState) => ({
      ...prevState,
      responseInventoryData: response.data,
      responseBodyData: this.extractInventoryData(response.data),
      tableBodyData: this.extractInventoryData(response.data),
      isLoading: false,
      updateInventoryMeta: response.meta,
    }));
  };

  apiUpdateInventoryFailer = (
    responseJson: ErrorMessage & ErrorMessageResponse
  ) => {
    if(responseJson.error === "You are not an authorized person, Only seller and Stylist can filter variants.")
      {
        this.handleRedirectToHome(this.tranUpdateInventory("You are not an authorized person, Please Login with valid User Credential"));
      } else if (responseJson.error) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseJson.error,
        responseBodyData: [],
        isLoading: false,
        tableBodyData: [],
        updateInventoryMeta: {} as MetaResponse,
      }));
    } else if (responseJson.errors[0].token) {
      this.handleRedirectToHome(responseJson.errors[0].token)
    } else if (responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseJson.errors as string,
        responseBodyData: [],
        tableBodyData: [],
        isLoading: false,
        updateInventoryMeta: {} as MetaResponse,
      }));
    }
  };

  handleRedirectToHome = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      isLoading: false,
      message: message,
      severity: "error",
    }), () => {
      setTimeout(() => {
        this.props.handleRedirect("Home");
        logoutSellerNavigation();
      }, 2000);
    });
  }

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if(userData.userType==="buyer") history.back();
    else this.setState((prevState)=>({...prevState, roleData: userData.userType}), ()=>this.fetchInventoryData(this.props.searchValue, this.props.filter, 1));
    // Customizable Area End
  }

  handleInventoryListStatusUpdate = (id: number, status: boolean) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        tableBodyData: prevState.tableBodyData.map((item) =>
          item.id === id ? { ...item, is_listed: !status } : item
        ),
      }),
      () => {
        this.checkAndStoreDifferentItem(id);
      }
    );
  };

  handleCurrentStockChange = (id: number, value: string) => {
    const currentStock = this.handleCheckNumber(value);
    this.setState(
      (prevState) => ({
        tableBodyData: prevState.tableBodyData.map((item) =>
          item.id === id ? { ...item, stock_qty: +currentStock } : item
        ),
      }),
      () => {
        this.checkAndStoreDifferentItem(id);
      }
    );
  };

  handleLowStockThresholdChange = (id: number, value: string) => {
    const lowStock = this.handleCheckNumber(value);
    this.setState(
      (prevState) => ({
        tableBodyData: prevState.tableBodyData.map((item) =>
          item.id === id ? { ...item, low_stock_threshold: +lowStock } : item
        ),
      }),
      () => {
        this.checkAndStoreDifferentItem(id);
      }
    );
  };

  checkAndStoreDifferentItem = (id: number) => {
    const tableItem = this.state.tableBodyData.find((item) => item.id === id);
    const responseItem = this.state.responseBodyData.find(
      (item) => item.id === id
    );

    if (tableItem && responseItem) {
      const itemsAreDifferent =
        tableItem.stock_qty !== responseItem.stock_qty ||
        tableItem.low_stock_threshold !== responseItem.low_stock_threshold ||
        tableItem.is_listed !== responseItem.is_listed ||
        tableItem.price !== responseItem.price;

      if (itemsAreDifferent) {
        const updatedItem: CatalogueItems = {
          id: tableItem.id,
          stock_qty: tableItem.stock_qty,
          low_stock_threshold: tableItem.low_stock_threshold,
          is_listed: tableItem.is_listed,
          price: tableItem.price,
          discounted_percentage: tableItem.discounted_percentage,
          discounted_price: tableItem.discounted_price
        };

        this.setState(
          (prevState) => {
            const existingCatalogueItemIndex =
              prevState.updatedCatalogues.findIndex((item) => item.id === id);
            let updatedCatalogues = [...prevState.updatedCatalogues];

            if (existingCatalogueItemIndex !== -1) {
              updatedCatalogues[existingCatalogueItemIndex] = updatedItem;
            } else {
              updatedCatalogues.push(updatedItem);
            }

            return { updatedCatalogues: updatedCatalogues };
          },
          () => this.props.handleUpdateStore(this.state.updatedCatalogues)
        );
      } else {
        this.setState(
          (prevState) => ({
            updatedCatalogues: prevState.updatedCatalogues.filter(
              (item) => item.id !== id
            ),
          }),
          () => this.props.handleUpdateStore(this.state.updatedCatalogues)
        );
      }
    }
  };

  handleCheckNumber = (value: string) => {
    const numeric = value.replace(/\D/g, "");
    return numeric ? parseInt(numeric, 10) : 0;
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.searchValue !== this.props.searchValue ||
      prevProps.filter !== this.props.filter
    ) {
      this.state.updatedCatalogues.length === 0
        ? this.fetchInventoryData(this.props.searchValue, this.props.filter, 1)
        : this.setState((prevState) => ({
            ...prevState,
            warningOpen: !prevState.warningOpen,
            update: "search-filter"
          }));
      ;
    }
    if (prevProps.removeData !== this.props.removeData) {
      this.setState((prevState) => ({
        ...prevState,
        updatedCatalogues: [],
        responseBodyData: prevState.tableBodyData
      }));
    }
  }

  handleUpdateInventoryPageChange = async (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    event.preventDefault();
    if (this.state.updatedCatalogues.length !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        newPage: newPage,
        warningOpen: !prevState.warningOpen,
        update: prevState.update === "" ? "page" : prevState.update
      }));
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      page: newPage,
      newPage,
      update: ""
    }));
   
    await this.fetchInventoryData(this.props.searchValue, this.props.filter, newPage);
  };

  fetchInventoryData = async (
    search: string,
    filter: {
      stock_status: string;
      listing?: boolean;
    },
    page: number
  ) => {
    this.setState((prevState)=>({...prevState, isLoading: true, page}));
    
    let endPoint = `${configJSON.getFilterVariantInventoryEndPoint}search=${search}&stock_status=${filter.stock_status}&per_page=10&page=${page}`;

    if (filter.listing !== undefined) {
      endPoint += `&listing=${filter.listing}`;
    }
    if (this.state.roleData==="seller"){const storeId = +(await this.getStoreId()); endPoint += `&store_id=${storeId}`;}

    this.getFilterListInventoryAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: endPoint,
      token: await this.getTokenDataStorage(),
    });
  };

  getTokenDataStorage = async () => {
    return await getStorageData("auth-token");
  };

  getStoreId = async () => {
    return await getStorageData("store-id");
  };

  extractInventoryData = (
    items: InventoryState[]
  ): ExtractedInventoryItem[] => {
    return items.map((item) => {
      const {
        id,
        product_name,
        is_listed,
        front_image,
        sku,
        price,
        low_stock_threshold,
        stock_qty,
        pair_it_with,
        discounted_price,
        discounted_percentage
      } = item.attributes;
      return {
        id,
        product_name,
        is_listed,
        front_image,
        sku,
        price,
        low_stock_threshold,
        stock_qty,
        pair_it_with,
        discounted_percentage,
        discounted_price
      };
    });
  };

  tranUpdateInventory = (transKey: string) => {
    return i18n.t(transKey, {ns: "inventoryManagement"});
  }
  // Customizable Area End
}
// Customizable Area Start

const updateInventoryTableHead = [
  "Product Name",
  "SKU",
  "Current Stock",
  "Low Stock Threshold",
  "List/Unlist",
];
// Customizable Area End
