import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  styled,
  Box,
  Typography,
  Tooltip,
  Button,
  Dialog,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import StylishProductSourcingController, {
  Props,
} from "./StylishProductSourcingController";
import StylishSidebar from "../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../components/src/Stylish/StylishHeader";
import { ThemeProvider } from "react-native-elements";
import {
  backBtnIcon,
  crossIcon,
  rightArrowIcon,
  uploadIcon,
} from "../../../customform/src/assets";
import { btnRemoveIcon } from "../assets";
import Loader from "../../../../components/src/LoaderContainer";
import { NoTypography } from "./StylishMyOffer.web";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
import i18n from "../../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylishProductSourcing extends StylishProductSourcingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSPSPagination = () => (
    <>
      {this.state.metaSPSResp !== undefined ? (
        <StylishPaginationContainer>
          <Box className="pagination-data-container">
            {this.state.metaSPSResp.current_page !== null
              ? (this.state.metaSPSResp.current_page - 1) * 10 + 1
              : ""}{" "}
            -{" "}
            {this.state.metaSPSResp.current_page !== null &&
            this.state.metaSPSResp.total_record
              ? Math.min(
                  this.state.metaSPSResp.current_page * 10,
                  this.state.metaSPSResp?.total_record
                )
              : ""}{" "}
            {this.transStylishProductSourcing("of")} {this.state.metaSPSResp.total_record} {this.transStylishProductSourcing("results")}
          </Box>
          <CustomStyledStylishPagination
            page={
              this.state.metaSPSResp.current_page !== null
                ? this.state.metaSPSResp.current_page
                : 1
            }
            count={
              this.state.metaSPSResp.total_pages !== null
                ? this.state.metaSPSResp.total_pages
                : 1
            }
            shape="rounded"
            color="primary"
            data-test-id="sps-page-test-id"
            dir={i18n.dir()}
            onChange={this.handlePSPageChange}
          />
        </StylishPaginationContainer>
      ) : (
        <></>
      )}
    </>
  );

  listProductSourcingBody = () =>
    this.state.listData.length === 0 ? (
      <Box className="loader-container">
        <NoTypography>{this.transStylishProductSourcing("No requests available")}</NoTypography>
      </Box>
    ) : (
      <Grid container spacing={3} dir={i18n.dir()}>
        {this.state.listData.map((item) => (
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <ListContainer>
              <Box className="img-container">
                <img
                  className="image"
                  src={item.attributes.images[0].url}
                  alt={`img-${item.id}`}
                />
              </Box>
              <Box className="list-right-part">
                <Box>
                  <Tooltip title={item.attributes.product_name}>
                    <Typography className="text-title">
                      {item.attributes.product_name}
                    </Typography>
                  </Tooltip>
                  <Typography className="text-title">
                    {this.state.currencySign} {+item.attributes.min_price} - {this.state.currencySign} {+item.attributes.max_price}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  className={"view-btn"}
                  disableElevation
                  onClick={() => this.handleViewClick(item.id)}
                  data-test-id={`view-btn-test-${item.id}`}
                >
                  {this.transStylishProductSourcing("View")}
                </Button>
              </Box>
            </ListContainer>
          </Grid>
        ))}
      </Grid>
    );

  handleBackSPSCSS = () => {
    return i18n.dir()==='ltr' ? "rotate(0deg)": "rotate(180deg)";
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container dir={i18n.dir()}>
          <Grid item xl={2} id="stylish-sidebar-ps" md={2} lg={2} sm={2} xs={2}>
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            id="stylish-header-ps"
            md={10}
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader navigation={this.props.navigation} />
            <StylishProductSourcingContainer>
              <TitleContainer>
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  onClick={() =>
                    this.handleProductSourcingRedirect("StylishCatalogue")
                  }
                  style={{transform: this.handleBackSPSCSS()}}
                />
                <Typography className="title">{this.transStylishProductSourcing("Product Sourcing")}</Typography>
              </TitleContainer>
              <Box
                data-test-id={`offer-test-id`}
                className={`btn-container`}
                onClick={() =>
                  this.handleProductSourcingRedirect("StylishMyOffer")
                }
              >
                <Typography className={`btn-name-title`}>{this.transStylishProductSourcing("My Bids")}</Typography>
                <img src={rightArrowIcon} style={{transform: this.handleBackSPSCSS()}} alt="right-icon" />
              </Box>
              <Typography className="title-product-sourcing">
                {this.transStylishProductSourcing("Product Sourcing Requests")}
              </Typography>
              {this.state.isLoading ? (
                <Box className="loader-container">
                  <Loader
                    id="my-offer-loader"
                    dataTestId="my-offer-loader-test"
                  />
                </Box>
              ) : (
                this.listProductSourcingBody()
              )}
            </StylishProductSourcingContainer>
            {this.state.metaSPSResp.total_record > 10 &&
              this.renderSPSPagination()}
          </Grid>
        </Grid>
        <ListDialogContainer
          open={this.state.isListPopup}
          onClose={this.handleListPopupClose}
          dir={i18n.dir()}
          data-test-id="list-popup-test-id"
        >
          {this.state.popUpData.attributes && (
            <ListPopUpContainer>
              <Box className="title-display">
                <Box className="img-container">
                  {this.state.popUpData.attributes.images[0].url && <img
                    className="image"
                    src={this.state.popUpData.attributes.images[0].url}
                    alt={`img-${this.state.popUpData.id}`}
                  />}
                </Box>
                <Box className="list-right-part">
                  <Tooltip title={this.state.popUpData.attributes.product_name}>
                    <Typography className="text-title">
                      {this.state.popUpData.attributes.product_name}
                    </Typography>
                  </Tooltip>
                  <Typography
                    className="text-title"
                    style={{ fontWeight: 700 }}
                  >
                    {this.state.currencySign} {+this.state.popUpData.attributes.min_price} - {this.state.currencySign} {+this.state.popUpData.attributes.max_price}
                  </Typography>
                </Box>
              </Box>
              <Box className="list-display">
                <Box className="row-container">
                  <Box className="description-data">
                    <Typography className="description-name">
                      {this.transStylishProductSourcing("Sizes")} -{" "}
                    </Typography>
                    <Typography className="description-value">
                      {this.state.popUpData.attributes.sizes.join(", ")}
                    </Typography>
                  </Box>
                  <Box className="description-data">
                    <Typography className="description-name">
                    {this.transStylishProductSourcing("Quantity")} -{" "}
                    </Typography>
                    <Typography className="description-value">
                      {this.state.popUpData.attributes.quantity} {this.transStylishProductSourcing("Units")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="row-container">
                  <Box className="description-data">
                    <Typography className="description-name">
                    {this.transStylishProductSourcing("Colours")} -{" "}
                    </Typography>
                    <Typography className="description-value">
                      {this.state.popUpData.attributes.colours.join(", ")}
                    </Typography>
                  </Box>
                  <Box className="description-data">
                    <Typography className="description-name">
                    {this.transStylishProductSourcing("Gender")} -{" "}
                    </Typography>
                    <Typography className="description-value">
                      {this.state.popUpData.attributes.gender}
                    </Typography>
                  </Box>
                </Box>
                <Box className="row-container">
                  <Box className="description-data">
                    <Typography className="description-name">
                      {this.state.popUpData.attributes.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Button
                variant="contained"
                className={"place-offer-btn"}
                disableElevation
                onClick={() => this.handlePlaceOfferPopupOpen()}
                data-test-id="place-offer-test-id"
              >
                {this.transStylishProductSourcing("Bid Your Quote")}
              </Button>
            </ListPopUpContainer>
          )}
        </ListDialogContainer>
        <PlaceOfferContainer
          open={this.state.isPlaceOfferPopup}
          dir={i18n.dir()}
          data-test-id="place-offer-popup-test-id"
        >
          <Box className="cross-icon-container">
            <img
              src={crossIcon}
              onClick={() => this.handlePlaceOfferPopupClose()}
              alt="cross-icon"
              data-test-id="cross-icon-test"
            />
          </Box>
          <Box className="main-field">
            <Typography className="label-text">{this.transStylishProductSourcing("Product Description")} *</Typography>
            <TextField
              data-test-id="product-description"
              fullWidth
              variant="outlined"
              className={`inputFeild2 ${
                this.state.errorState.isProductDescError ? "Mui-error" : ""
              }`}
              placeholder={this.transStylishProductSourcing("Write product description here")}
              multiline
              inputProps={{ maxLength: 582 }}
              rows={4}
              value={this.state.productDescription}
              onChange={(event) => this.handleProductDesc(event.target.value)}
            />
            {this.state.errorState.isProductDescError && (
              <FormHelperTextContainer data-test-id="product-decs-error">
                {this.state.errorState.productDescErrorMessage}
              </FormHelperTextContainer>
            )}
          </Box>
          <Box className="main-field">
            <Typography className="label-text">{this.transStylishProductSourcing("Quote Price")} *</Typography>
            <TextField
              data-test-id="quote-price"
              fullWidth
              variant="outlined"
              className={`inputFeild2 ${
                this.state.errorState.isQuotePriceError ? "Mui-error" : ""
              }`}
              placeholder={`${this.state.currencySign}0`}
              value={this.state.quotePrice}
              onChange={(event) => this.handleQuotePrice(event.target.value)}
            />
            {this.state.errorState.isQuotePriceError && (
              <FormHelperTextContainer data-test-id="quote-price-error">
                {this.state.errorState.quotePriceErrorMessage}
              </FormHelperTextContainer>
            )}
          </Box>
          <Box className="main-field">
            <Typography className="label-text">{this.transStylishProductSourcing("Attachments")} *</Typography>
            <Button
              variant="contained"
              component="label"
              className="upload-btn"
            >
              <img src={uploadIcon} alt="upload" />
              <Typography
                className={`upload-title ${
                  this.state.selectedFiles.length >= 5 ? "disabled" : ""
                }`}
              >
                {this.transStylishProductSourcing("Upload png, jpg, jpeg")}
              </Typography>
              <Typography className="upload-csv">
                {this.transStylishProductSourcing("You can attach up to 5 images.")}
              </Typography>
              <input
                type="file"
                hidden
                data-test-id="upload-id-test"
                multiple
                onChange={
                  this.state.selectedFiles.length <= 5
                    ? (event) => this.handleUploadChange(event)
                    : undefined
                }
                disabled={this.state.selectedFiles.length >= 5}
                accept=".png, .jpg, .jpeg"
              />
            </Button>
            {this.state.errorState.isAttachmentError && (
              <FormHelperTextContainer data-test-id="upload-error">
                {this.state.errorState.attachmentErrorMessage}
              </FormHelperTextContainer>
            )}
          </Box>
          <Box className="preview-container">
            {this.state.selectedFiles.map((file, index) => (
              <Box key={index} className="file-preview">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`preview ${index}`}
                  className="preview-image"
                />
                <img
                  onClick={() => this.handleRemoveFile(index)}
                  data-test-id={`remove-test-${index}`}
                  className="remove-btn"
                  src={btnRemoveIcon}
                  alt="remove-icon"
                />
              </Box>
            ))}
          </Box>
          <Button
            variant="contained"
            className={"place-offer-btn-2"}
            disableElevation
            onClick={() => this.handlePlaceOfferSubmit()}
            data-test-id="submit-place-offer-test-id"
          >
            {this.transStylishProductSourcing("Submit")}
          </Button>
        </PlaceOfferContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertPSSnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StylishProductSourcingContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .loader-container":{
    width: "100%",
    minHeight: 375,
    justifyContent: "center",
    display: "flex",
    alignItems: "center"
  },
  "& .btn-container": {
    border: "1px solid #E2E8F0",
    borderRadius: 2,
    padding: "38px 26px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: 40,
    "& .btn-name-title": {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontWeight: 700,
      fontSize: 22,
    },
    "&:hover": {
      backgroundColor: "#F1F5F9",
      "& .btn-name-title": {
        fontSize: 24,
      },
    },
  },
  "& .title-product-sourcing": {
    fontFamily: "Lato, sans-serif",
    color: "#375280",
    fontSize: 24,
    fontWeight: 700,
    width: "100%",
    paddingBottom: 20,
  },
});

const ListContainer = styled(Box)({
  display: "flex",
  gap: 20,
  "& .img-container": {
    width: 140,
    height: 140,
    objectFit: "cover",
    borderRadius: 2,
    "& .image": {
      width: "inherit",
      height: "inherit",
    },
  },
  "& .list-right-part": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "& .text-title": {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontSize: 18,
      fontWeight: 700,
      width: "100%",
      paddingBottom: 4,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      whiteSpace: "normal",
    },
    "& .view-btn": {
      textTransform: "capitalize",
      color: "white",
      gap: "8px",
      backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
      height: 42,
      borderRadius: "1px",
      width: "100%",
      "& .MuiButton-label": {
        fontSize: "18px",
        fontWeight: 600,
        fontFamily: "Lato"
      },
    },
  },
});

const ListPopUpContainer = styled(Box)({
  "& .title-display": {
    display: "flex",
    gap: 20,
    "& .img-container": {
      width: 98,
      height: 104,
      objectFit: "cover",
      borderRadius: 2,
      "& .image": {
        width: "inherit",
        height: "inherit",
      },
    },
    "& .list-right-part": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      "& .text-title": {
        fontFamily: "Lato, sans-serif",
        color: "#375280",
        fontSize: 18,
        fontWeight: 500,
        width: "100%",
        paddingBottom: 4,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        whiteSpace: "normal",
      },
      "& .view-btn": {
        textTransform: "capitalize",
        color: "white",
        gap: "8px",
        backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
        height: 42,
        borderRadius: "1px",
        width: "100%",
        "& .MuiButton-label": {
          fontSize: "18px",
          fontWeight: 700,
        },
      },
    },
  },
  "& .list-display": {
    display: "flex",
    gap: 12,
    flexDirection: "column",
    paddingTop: 16,
    "& .row-container": {
      display: "flex",
      justifyContent: "space-between",
      "@media(max-width:540px)": {
        flexDirection: "column",
      },
      "& .description-data": {
        display: "flex",
        "& .description-name": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 400,
          fontSize: 16,
          color: "#375280",
        },
        "& .description-value": {
          fontFamily: "Lato, sans-serif",
          fontSize: 16,
          fontWeight: 900,
          color: "#375280",
          textTransform: "capitalize",
          paddingLeft: 4,
        },
      },
    },
  },
  "& .place-offer-btn": {
    marginTop: 28,
    textTransform: "capitalize",
    color: "white",
    gap: "8px",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
    height: 56,
    borderRadius: "1px",
    width: "100%",
    "& .MuiButton-label": {
      fontSize: 20,
      fontWeight: 800,
      fontFamily: "Lato"
    },
  },
});

export const TitleContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 20,
  paddingBottom: 47,
  "& .title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 24,
    color: "#375280",
  },
});

export const StylishPaginationContainer = styled(Box)({
  padding: "23px 32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderTop: "1px solid #E2E8F0",
  "@media(max-width:700px)": {
    flexDirection: "column-reverse",
    gap: 20,
  },
  "& .pagination-data-container": {
    color: "#475569",
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 500,
  },
});

export const CustomStyledStylishPagination = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  "& .MuiPaginationItem-root": {
    color: "#375280",
  },
  "& li:first-of-type .MuiPaginationItem-icon": {
    transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
  },
  "& .Mui-selected": {
    color: "white",
  },
  "& .MuiPaginationItem-textPrimary.Mui-selected": {
    backgroundColor: "#375280",
  },
  "& li:last-of-type .MuiPaginationItem-icon": {
    transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
  },
}));

const ListDialogContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      padding: 40,
      width: 520,
    },
  },
});

export const PlaceOfferContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      padding: 40,
      width: 756,
      "& .cross-icon-container": {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
      },
      "& .main-field": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 12,
        marginBottom: 20,
        "& .label-text": {
          fontFamily: "Lato, sans-serif",
          color: "#375280",
          fontWeight: 700,
          fontSize: 18,
        },
        "& .MuiFormControl-root.Mui-error": {
          border: "1px solid #f44336",
          borderRadius: 4,
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              color: "#f44336",
            },
          },
        },
        "& .inputFeild2": {
          "& .MuiInputBase-root.MuiOutlinedInput-root:hover": {
            border: "1px solid #F0F0F0 !important",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
          },
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
          },
          "& .MuiFormHelperText-contained": {
            margin: "0 !important",
          },
          "& .MuiInputBase-input": {
            color: "#375280",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
          },
        },
        "& .upload-btn": {
          boxShadow: "none",
          backgroundColor: "#F8F8F8 !important",
          width: "100%",
          height: 185,
          "& .MuiButton-label": {
            display: "flex",
            flexDirection: "column",
          },
          "& .upload-title": {
            fontFamily: "Lato, sans-serif",
            fontSize: 18,
            fontWeight: 700,
            color: "#375280",
            textTransform: "capitalize",
            marginTop: 20,
          },
          "& .upload-title.disabled": {
            color: "#94A3B8",
          },
          "& .upload-csv": {
            fontFamily: "Lato, sans-serif",
            fontSize: 16,
            fontWeight: 500,
            color: "#94A3B8",
            marginTop: 5,
            textTransform: "capitalize",
          },
        },
      },
      "& .preview-container": {
        display: "flex",
        gap: 12,
        marginTop: 10,
        flexWrap: "wrap",
        "& .file-preview": {
          position: "relative",
          "& .preview-image": {
            width: 80,
            height: 80,
            objectFit: "cover",
            borderRadius: 2,
          },
          "& .remove-btn": {
            position: "absolute",
            right: 0,
          },
        },
      },
      "& .place-offer-btn-2": {
        marginTop: 28,
        textTransform: "capitalize",
        color: "white",
        gap: "8px",
        "& .MuiButton-label": {
          fontSize: 20,
          fontWeight: 800,
          fontFamily: "Lato"
        },
        backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
        height: 56,
        borderRadius: "1px",
        width: "100%",
      },
    },
  },
});

export const FormHelperTextContainer = styled(FormHelperText)({
  color: "f44336",
  display: "flex"
});
// Customizable Area End
