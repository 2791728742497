Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.postApiMethod = "POST";
exports.postSplashScreenEndPoint = "";
exports.welcome = "Welcome!";
exports.chooseType = "Choose your user type";
exports.typeHeader = "Continue as";
exports.languageHeader = "Select Language";
exports.currencyHeader = "Select Currency";
exports.continue = "Continue";
exports.aleadyAccount = "Already have an account?";
exports.loginHere = "Login Here";
exports.timeout = 2000;
// Customizable Area End