Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfvirtualmannequin3";
exports.labelBodyText = "cfvirtualmannequin3 Body";

exports.btnExampleTitle = "CLICK ME";
exports.selectAvatarHeading= "Select Avatar";
exports.basicDetailsHeading= "Basic Details";
exports.MeasurementHeading= "Measurements";

// Customizable Area End