import React from "react";

// Customizable Area Start
import { Box, Typography, Divider } from "@material-ui/core";
import { styled, ThemeProvider, createTheme } from "@material-ui/core/styles";

import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import OrderSections from "./OrderSections.web";

import OrderSuccessController, {
  configJSON,
  Props,
} from "./OrderSuccessController.web";
import { imgOrderSuccess } from "./assets";

const MainBox = styled(Box)({
  padding: "4rem 4.5rem 6rem",
  textAlign: "center",
  "& .success-text": {
    fontSize: "2rem",
  },
  "& .success-title": {
    fontSize: "2.25rem",
    fontWeight: 700,
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
    },
  },
});
// Customizable Area End

export default class OrderSuccess extends OrderSuccessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Header navigation={this.props.navigation}/>
        <OrderSections activeTab="complete" />
        <MainBox>
          <Divider />
          <img src={imgOrderSuccess} />
          <Typography className="success-title">{configJSON.great}!</Typography>
          <Typography className="success-text">
            {configJSON.successText}.
          </Typography>
        </MainBox>
        <Footer navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
