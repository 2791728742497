import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { signUpLogo } from "../src/assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

import EmailAccountRegistrationController, {
    Props, configJSON
} from "./EmailAccountRegistrationController.web";
import CountrySelect from "../../../components/src/CountryDropDown.web";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const passErrorMessage=this.state.errorsMessage.errorPassword ? this.state.errorsMessage.passwordMessage : this.transRegister(configJSON.eightPass);
        const phoneErrorMessage=this.state.errorsMessage.errorPhoneNumber ? this.state.errorsMessage.phoneNumberMessage : "";
        return (
            // Customizable Area Start
            <>
                <Header navigation={this.props.navigation}/>
                <MainGrid className="RegistrationFormMainGrid" container dir={i18n.dir()}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
                        <Box className="sideImageBox">
                            <img src={signUpLogo} alt="" className="sideImage" />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
                        <Box className={"formBox"}>
                            <form data-test-id="formSubmit" onSubmit={this.handleSubmit} style={{width:"100%"}}>
                                <Box>
                                    <Typography className={"signUpText"}>{this.transRegister(configJSON.btnTextSignUp)}</Typography>
                                    <Typography className={"inputHeadingBox"}>{this.transRegister(configJSON.firstName)}</Typography>
                                    <TextField
                                        data-test-id="fullnameTextInput"
                                        fullWidth
                                        variant="outlined"
                                        className={this.generateClassName(this.state.firstNameError, "inputFeild2", "inputFeild2Error2")}
                                        placeholder={this.transRegister(configJSON.firstNamePlace)}
                                        value={this.state.firstName}
                                        onChange={this.handleFirstName}
                                        error={this.state.errorsMessage.errorFirstName}
                                        helperText={this.generateErrorMessage('firstNameMessage',this.transRegister(configJSON.defaultFirstNameErrorMessage))}
                                    />
                                    <Typography className={"inputHeadingBox"}>{this.transRegister(configJSON.lastName)}</Typography>
                                    <TextField
                                        data-test-id="lastnameTextInput"
                                        fullWidth
                                        variant="outlined"
                                        className={this.generateClassName(this.state.lastNameError, "inputFeild2", "inputFeild2Error2")}
                                        placeholder={this.transRegister(configJSON.lastNamePlace)}
                                        value={this.state.lastName}
                                        onChange={this.handleLastName}
                                        error={this.state.errorsMessage.errorLastName}
                                        helperText={this.generateErrorMessage('lastNameMessage', this.transRegister(configJSON.defaultLastNameErrorMessage))}
                                    />

                                    <Typography className={"inputHeadingBox"}>{this.transRegister(configJSON.emailAddress)}</Typography>
                                    <TextField
                                        data-test-id="emailTextInput"
                                        fullWidth
                                        variant="outlined"
                                        className={this.generateClassName(this.state.emailError, "inputFeild2", "inputFeild2Error2")}
                                        placeholder={this.transRegister(configJSON.emailPlace)}
                                        value={this.state.email}
                                        onChange={this.handleEmailAddress}
                                        error = {this.state.errorsMessage.errorEmail}
                                        helperText={this.generateErrorMessage('emailMessage', this.transRegister(configJSON.defaultemailMessageErrorMessage))}
                                    />
                                    <Typography className={"inputHeadingBox"}>{this.transRegister(configJSON.labelPassword)}</Typography>

                                    <TextField
                                        data-test-id="passwordTextInput"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        value={this.state.password}
                                        placeholder={this.transRegister(configJSON.passPlace)}
                                        onChange={this.handlePassword}
                                        error = {this.state.errorsMessage.errorPassword}
                                        helperText={passErrorMessage}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleTogglePassword}
                                                        edge="end"
                                                    >
                                                        {this.state.showPassword ?<VisibilityOff/>: <Visibility /> }
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        className={this.generateClassName(this.state.passError, "inputFeildPassword", "inputFeildPasswordError")}
                                    />
                                    <Typography className={"inputHeadingBox"}>
                                        {this.transRegister(configJSON.reenterPassword)}
                                    </Typography>

                                    <TextField
                                        data-test-id="re-passwordTextInput"
                                        type={this.state.reshowPassword ? 'text' : 'password'}
                                        value={this.state.rePassword}
                                        placeholder={this.transRegister(configJSON.rePassPlace)}
                                        onChange={this.handleRePassword}
                                        error = {this.state.errorsMessage.errorRePassword}
                                        helperText={this.generateErrorMessage('rePasswordMessage', this.transRegister(configJSON.defaultrePasswordMessageErrorMessage))}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleToggaleRePassword}
                                                        edge="end"
                                                    >
                                                        {this.state.reshowPassword ? <VisibilityOff/>: <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        className={this.generateClassName(this.state.reError, "inputFeild2", "inputFeild2Error2")}
                                    />
                                    <Typography  data-test-id="countryId" className={"inputHeadingBox"}>{this.transRegister(configJSON.phone)}</Typography>
                                    <Box className={"phoneInput"}>
                                        <CustomDropDown>
                                        <CountrySelect
                                            id="phoneCode"
                                            name="phoneCode"
                                            value={this.state.selectedCountry?.numeric_code || "+965"}
                                            placeholder="Select Phone Code"
                                            onChange={this.handleRegistrationChange}
                                            countryCode={this.state.countrySuccess}
                                        />
                                        </CustomDropDown>

                                        <MobileTextField
                                            data-test-id="phoneNumberTextInput"
                                            variant="outlined"
                                            className={this.generateClassName(this.state.phoneError, "inputFeild2", "inputFeild2Error2")}
                                            value={this.state.phoneNumber}
                                            onChange={this.handlePhoneNumberChange}
                                            placeholder={this.transRegister(configJSON.phonePlace)}
                                            error={this.state.errorsMessage.errorPhoneNumber}
                                            helperText={phoneErrorMessage}
                                            inputProps={{ maxLength: 12 }}
                                            InputProps={{
                                                style: {
                                                    gap: "4px",
                                                    border: "none",
                                                    borderRadius: "0px",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <label className={"labelCheckbox"}>
                                    <input
                                        data-test-id="checkBox"
                                        type="checkbox"
                                        className={"checkboxField"}
                                        checked={this.state.isChecked}
                                        onChange={this.handleCheckboxChange}
                                    />
                                    <Box>
                                        <span className={"textName"}>{this.transRegister(configJSON.textAgree)}</span><span className={"condition"} data-test-id="termdataId" onClick={() => {
                                            window.open('/Policies', "_blank");
                                            localStorage.setItem("policyTab", "0")
                                        }}>{this.transRegister(configJSON.termsCondition)}</span><span className={"textName"}>{this.transRegister(configJSON.and)}</span>
                                        <span className={"condition"} data-test-id="privecydataId" onClick={() => {
                                            window.open('/Policies', "_blank");
                                            localStorage.setItem("policyTab", "1")
                                        }} >{this.transRegister(configJSON.privacyStatement)}</span>
                                    </Box>
                                </label>
                                <Button
                                    variant="contained"
                                    className={"createAccountButton"}
                                    disableElevation
                                    type="submit"
                                >
                                    {this.transRegister(configJSON.createAccount)}
                                </Button>
                                <Snackbar
                                    open={this.state.isAlert}
                                    autoHideDuration={3000}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    onClose={this.oncloseAlert}
                                    data-test-id="alertTestId"
                                    dir={i18n.dir()}
                                >
                                    <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                                </Snackbar>
                            </form>
                            <Box className={"termBox"}> 
                            {this.state.showValidationMessage && <p className={"termsCondition"}>{this.transRegister(configJSON.termsCondtion)}</p>}
                            </Box>
                            <Box className={"Login"}>
                                <Typography data-test-id="navigateRegister" onClick={this.openLoginScreen} className={"loginButton"}>{this.transRegister(configJSON.loginHere)}</Typography>
                                <Typography className={"bottomText"}>
                                    {" "}
                                    {this.transRegister(configJSON.aleadyAccount)}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </MainGrid>
                <Footer navigation={this.props.navigation}/>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainGrid = styled(Grid)({
    "& .imageGrid": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
    },
    "& .sideImageBox": {
        width: "100%",
        height: "100%",
    },
    "& .sideImage": {
        width: "100%",
        height: "100%",
    },
    "& .signUpText": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "10px 10px 30px 0px",
        textOverflow: "ellipsis",
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        fontFamily: "Avenir, sans-serif;",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
        alignSelf: "flex-start",
    },
    "& .termBox":{
        width:'100%'
    },
    "& .inputHeadingBox": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "10px 0",
        textOverflow: "ellipsis",
        fontFamily: "Lato, sans-serif;",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "26px",
    },
    "& .inputFeild2Error2":{
        float: "right",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
            border:"none"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiFormHelperText-contained":{
            margin:"0 !important",
            textAlign: i18n.dir() === "ltr" ? "right": "left"
        },
    },
    "& .countryFlag":{
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    "& .listTag":{
        color:'#375280',
        display: "flex",
        alignItems: "center",
        gap: "3px"
    },
    "& .inputFeildPassword": {
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiFormHelperText-contained":{
            margin:"0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        '& .MuiFormHelperText-root':{
            color: "#375280"
        },
        "& .MuiOutlinedInput-root:focus": {
            borderColor: "transparent" 
          }
    },
    "& .inputFeild2": {
        "& .MuiTextField-root":{
            "&:hover":{
                border: "1px solid #F0F0F0 !important",
            },
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiFormHelperText-contained":{
            margin:"0 !important"
        },
           "& .MuiOutlinedInput-notchedOutline":{
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root:hover" :{
            border: "none !important",
          },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
            
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        }
    },
    "& .inputFeildPasswordError": {
        "& .MuiOutlinedInput-adornedEnd": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
        },
        "& .MuiFormHelperText-contained":{
            margin:"0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
    },
    "& .phoneInput": { display: "flex",height:"57px" },
    "& .formGrid": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        padding: "7%"
    },
    "& .formBox": {
        display: "flex",
        width:"100%",
        alignItems: "center",
        flexDirection: "column"
    },
    "& .createAccountButton": {
        textTransform:"capitalize",
        color: "white !important",
        backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
        gap: "8px",
        margin: "25px 10px 10px 0px !important",
        width: "100% !important",
        borderRadius: "0px !important",
        height: "56px !important",
        "& .MuiButton-label":{
            fontWeight:600,
            fontSize:"16px"
        }

    },
    "& .terms": {
        color: "#979797 !important",
        margin: "10px 10px 10px 0px !important",
        width: "100%",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "24px !important",
        textAlign: "left",
        fontFamily: "Lato , sans-serif;",
        fontSize: "16px !important"
    },
    "& .condition": {
        color: "var(--Neutrals-Cool-gray-500, #375280)",
        cursor: "pointer",
        marginLeft:"3px",
        fontFamily: "Avenir, sans-serif",
    },
    "& .Login": {
        padding: "15%",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: "15px",
        display: "flex"
    },
    "& .loginButton": {
        cursor: "pointer",
        fontFamily: "Lato , sans-serif",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 700,
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        margin: "10px 10px 0 0px",
        lineHeight: "24px !important",
    },
    "& .bottomText": {
        color: "#375280 !important",
        fontFamily: "Lato , sans-serif;",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "24px !important",
        fontSize: "16px !important"
    },
    "& .labelCheckbox": {
        width:"100%",
        display: "flex",
        alignItems: "flex-start",
        gap: "5px",
        marginTop:'20px'
    },
    "& .checkboxField": {
        cursor: "pointer"
    },
    "& .termsCondition":{
        color:"red"
    },
    "& .textName":{
        fontFamily: "Avenir, sans-serif",
        fontSize: "15px",
        color: "#94A3B8"
    },
    "@media screen and (min-width: 0px) and (max-width: 1100px)": {
        "& .formBox": { width: "400px", flexWrap: "wrap" }
    },
    "&.arrow-icon": {
        marginLeft: "auto",
        transition: "transform 0.3s ease",
      },
      "&.rotate": {
        transform: "rotate(180deg)"
      }
});

const MobileTextField = styled(TextField)({
    width:"100%",
    marginLeft: "20px",
    gap: "4px",
    border: "none",
    borderRadius: "none",
    "& .MuiOutlinedInput-root":{
        border:"none"
    }
});
const CustomDropDown = styled(Box)({
    position: "relative",
    width: "200px",
    cursor: "pointer",
});
const DropDownHeader = styled(Box)({
    padding: "10px",
    gap: "15px",
    display: "flex",
    alignItems: "center",
    border: "none",
    borderRadius: "2px",
    background: "#F0F0F0",
    height: "36px",
    color: "#375280",
    fontSize: "18px",
    "&.isActive":{
    border: "1px solid red",
    },
    "&:hover": {
        backgroundColor: "#F0F0F0"
    }
});
const SelectedCountry = styled(Box)({
    display: "flex",
    alignItems: "center"
});
const DropDownOptions = styled(Box)({
    left: 0,
    width: "100%",
    border: "1px solid #ccc",
    borderTop: "none",
    borderRadius: "0 0 4px 4px",
    backgroundColor: "#fff",
    position: "absolute",
    zIndex: 1,
    height: "60vh",
    overflow: "auto"
});
const OptionsValue = styled(Box)({
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
        backgroundColor: "#F0F0F0"
    }
});
// Customizable Area End