export const enNotification = {
    "Home": "Home",
    "Notifications": "Notifications",
    "Welcome to your Account": "Welcome to your Account",
    "Buyer": "Buyer",
    "Seller": "Seller",
    "Stylist": "Stylist",
    "All": "All",
    "Orders": "Orders",
    "Inventory": "Inventory",
    "Deals": "Deals",
    "Your Order": "Your Order"
}

export const arNotification = {
    "Home": "الصفحة الرئيسية",
    "Notifications": "إشعارات",
    "Welcome to your Account": "مرحبا بك في حسابك",
    "Buyer": "مشتري",
    "Seller": "بائع",
    "Stylist": "مصمم",
    "All": "الجميع",
    "Orders": "الطلبات",
    "Inventory": "جرد",
    "Deals": "العروض",
    "Your Order": "طلبك"
}