import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { SellerNewOrder, TotalOrder } from "./DashboardController";
import { apiCall } from "../../../components/src/APICall";

// Customizable Area Start
import i18n from "../../../components/src/i18next/i18n";
export interface NewStylistData {
  id: string,
  type: string,
  attributes: {
    buyer_name: string,
    buyer_profile: string,
    colour: string,
    created_at: string,
    detail: string,
    gender: string,
    max_price: string,
    min_price: string,
    status: string,
    stylist_id:string,
    stylist_name: string,
    stylist_profile: string,
    images: {
      url: string
    }[]
  }
};
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  orderCountData: TotalOrder;
  page: number;
  newStylistRequestData: NewStylistData[]; 
  newOrderData: SellerNewOrder[];
  metaDataNewOrder: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  },
  acceptOrderOpen: boolean;
  confirmOrderId: number;
  rejectDialogOpen: boolean;
  rejectId: number;
  rejectReason: string;
  rejectSelectOption: string[];
  reloadTime: number;
  isRequestOpen: boolean;
  popUpData: NewStylistData;
  isSROpen: boolean;
  isAccepted: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StylishDashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getOrderCountApiCallID: string = "";
  getNewStylistApiCallID: string = "";
  getNewOrderListCallId: string = "";
  getRejectNewOrderCallId: string = "";
  getTimeOrderCallId: string = "";
  updateLatestAcceptRejectsId: string = "";

  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
        token: "",
        loading: false,
        newStylistRequestData: [],
        orderCountData: {
          total_orders: 0,
          total_shipped_orders: 0,
          total_delivered_orders: 0,
          total_rejected_orders: 0,
          total_process_orders: 0,
          total_in_process_orders: 0,
          total_new_orders: 0,
          total_return_refund_orders: 0
        },
        rejectDialogOpen: false,
        rejectId: 0,
        page: 1,
        isRequestOpen: false,
        newOrderData: [],
        metaDataNewOrder: {
          total_pages: 0,
          current_page: 0,
          total_record: 0,
          prev_page: 0,
          next_page: 0
        },
      rejectReason: '',
        acceptOrderOpen: false,
        confirmOrderId: 0,
        rejectSelectOption: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
  reloadTime: 45,
  popUpData: {} as NewStylistData,
  isSROpen: false,
  isAccepted: true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getStylistCount();
    this.getNewOrderApi();
    this.getNewStylist();
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
     if(apiRequestCallId === this.getOrderCountApiCallID){
      this.setState({
        orderCountData: responseJson
      })
    }else if(apiRequestCallId === this.getNewOrderListCallId){
      if(responseJson.error){
       this.setState({
         loading: false,
       })
     }else{
       this.setState({
         newOrderData: responseJson?.data,
         metaDataNewOrder: responseJson?.meta,
         loading: false
       });
     }
   }else if(apiRequestCallId === this.getNewStylistApiCallID){
    if(responseJson?.data){
      const filterpending = responseJson.data.filter((element:NewStylistData) => element.attributes.status === "pending");
      this.setState({
        newStylistRequestData: filterpending
      });
    }
   }else if (apiRequestCallId === this.updateLatestAcceptRejectsId) {
    if (responseJson.data) {
      this.setState(
        (prevState) => ({ ...prevState, isSROpen: true, isLoading: true  }),
        () => {
          setTimeout(() => {
            this.handleClose();
            this.getNewStylist();
          }, 2000);
        }
      );
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  handleAcceptDialogClose = () => {
    this.setState({ acceptOrderOpen: false });
  };

  incrementTime = () => {
    this.setState((prevState) => ({ reloadTime: prevState.reloadTime + 1 }));
  };

  handleViewPopup = (element: NewStylistData) => {
    this.setState({
      popUpData: element,
      isRequestOpen: true
  })
  };

  handleClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isSROpen: false,
      isRequestOpen: false,
    }));
  };
  timeOrderData = async () => { 
      const token = await getStorageData("auth-token");
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: token,
      }

    const requestMessageListNewOrderTime = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getTimeOrderCallId = requestMessageListNewOrderTime.messageId;

    requestMessageListNewOrderTime.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListNewOrderTime.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.confirmOrderId}&type=accept&accept_order_upload_time=${this.state.reloadTime}`
    );

    requestMessageListNewOrderTime.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageListNewOrderTime.id, requestMessageListNewOrderTime);
  };

  handleAcceptReject = async (status: string, requestId: number) => {
    this.setState((prevState) => ({
      ...prevState,
      isAccepted: status === 'accepted' ? true: false,
    }));
    const formData = new FormData();
    formData.append("status", status);
    this.updateLatestAcceptRejectsId = await apiCall({
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.customForms}/${requestId}/update_status`,
      token: await getStorageData("auth-token"),
      body: formData,
      isJsonStringify: false,
    });
  };

  handleRequestClose = () => {
    this.setState({
      isRequestOpen: false,
    });
  };
  timeData = () => {
    this.setState({ acceptOrderOpen: false })
    this.timeOrderData() ; this.getNewOrderApi();
  };

  decrementTime = () => {
    this.setState((prevState) =>
       ({ reloadTime: prevState.reloadTime - 1 }));

  };
  getNewOrderApi = async () => {  
     const token = await getStorageData("auth-token");
    this.setState({
      loading: true})
    const header = { "Content-Type": configJSON.dashboarContentType,
      token: token,};
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNewOrderListCallId = requestMessageList.messageId;

    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.listOfOrdersMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getNewOrderApiCallEndPoint}&page=${this.state.page}&per_page=10`
    );
    requestMessageList.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  rejectDataApiCall = () => {
    this.setState({ rejectDialogOpen: false })
    this.rejectOrderData(); this.getNewOrderApi();
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    event.preventDefault()
    this.setState({ page: newPage }, () => this.getNewOrderApi());
};

  rejectOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType, token: token, 
    };
      const requestMessageNewList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );  
    this.getRejectNewOrderCallId = requestMessageNewList.messageId; 
    requestMessageNewList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.addAddressToAddressMethod
    );   
    requestMessageNewList.addData(  
      getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getRejectOrder}?seller_order_id=${this.state.rejectId}&type=reject&reason_of_rejection=${this.state.rejectReason}`
    );
    requestMessageNewList.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageNewList.id, requestMessageNewList);
  }
  getNewStylist = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNewStylistApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customForms
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  getStylistCount = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderCountApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllOrderDetails
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  dateFormetTime = (created_at: string) => {
    if (!created_at) return "";
    const orderDate = new Date(created_at);
    let hoursOrder = orderDate.getHours();
    const minutes = orderDate.getMinutes();
    const ampm = hoursOrder >= 12 ? this.transStylishDashboard('PM') : this.transStylishDashboard('AM');
    hoursOrder = hoursOrder % 12;
    hoursOrder = hoursOrder ? hoursOrder : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hoursOrder}:${minutesStr} ${ampm}`;
  }
  handleAcceptOrder = (confirmid: number) => {
    this.setState({ acceptOrderOpen: true, confirmOrderId: confirmid });
  };

  handleCloseDialog = () => {
    this.setState({ rejectDialogOpen: false })
  };

  handleRejectOpen = (rejectid: number) => {
    this.setState({ rejectDialogOpen: true, rejectId: rejectid })
  };

  handleRejectReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ rejectReason: event.target.value as string });
  };
  headerNavigation = async (pageLink: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  transStylishDashboard = (transKey: string) => {
    return i18n.t( transKey, {ns: "dashboard"});
  }

  // Customizable Area End

}
