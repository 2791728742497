import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { pdf, jpg, doc } from "./assets"
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";
import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import i18n from "../../../components/src/i18next/i18n";
type NullableDate = Date | string | null | undefined;
interface Order {
    id: number;
    account_id: number;
    amount: string | null;
    driver_order_id: number | null;
    status: string;
    created_at: string;
    updated_at: string;
    seller_order_id: number;
    order_number: string;
    catalogue_name: string | null;
}
interface EarningsData {
    [key: string]: {
        total_earnings: number;
    };
}
const weekDaysMap: { [key: string]: string } = {
    "1": "Mon", "2": "Tue", "3": "Wed", 
    "4": "Thu", "5": "Fri", "6": "Sat", "7": "Sun",
  };
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    dataChart: EarningsData;
    nextpage: string,
    numberData: string,
    endDate: NullableDate;
    startDate: NullableDate;
    isPickerOpen: boolean;
    anchorEl: HTMLElement | null,
    downloadOptionsActive: { type: string, label: string }[];
    open: boolean;
    loading: boolean;
    withdrawMoneyOpen: boolean;
    account: string;
    pdfOpen: boolean;
    downloadOptions: { type: string, src: string, label: string }[];
    accountPdf: string;
    accountFilter: string;
    error: string;
    earningDetails: Order[];
    showDatePicker: boolean;
    walletAmount: number;
    selectLable: { label: string }[];
    tempFilter: string;
    lastValidFilter: string;
    roleData:string;
    isChartLoading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EarningsAnalyticsPageWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getproductGetCallId: string = "";
    getsellerEarningsCallId: string = "";
    getfiltersellerEarningsCallId: string = "";
    getearningsDownload: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.RestAPIRequestBodyMessage),
        ];
        this.state = {
            dataChart: {},
            nextpage: "",
            numberData: "all",
            showDatePicker: false,
            open: false,
            anchorEl: null,
            startDate: null,
            isPickerOpen: false,
            endDate: null,
            withdrawMoneyOpen: false,
            account: "",
            loading: true,
            isChartLoading: true,
            error: "",
            pdfOpen: false,
            downloadOptions: [
                { type: 'pdf', src: pdf, label: 'PDF' },
                { type: 'docx', src: doc, label: 'Doc File' },
                { type: 'jpg', src: jpg, label: 'JPG' },
            ],
            accountPdf: "pdf",
            accountFilter: "this_month",
            earningDetails: [],
            walletAmount: 0,
            downloadOptionsActive: [
                { type: 'this_week', label: 'This Week' },
                { type: 'this_month', label: 'This Month' },
                { type: 'this_year', label: 'This Year' },
                { type: 'select_date_range', label: 'Select Date Range' },
            ],
            selectLable: [{ label: 'Week' }, { label: 'Month' }, { label: 'Year' }],
            tempFilter: 'this_month',
            lastValidFilter: 'this_month',
            roleData:""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.orderSellerEarnings(this.state.numberData)
        const userData = await getStorageData("userRole",true)
        this.setState({ roleData: userData.userType })
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getsellerEarningsCallId !== null &&
            this.getsellerEarningsCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonsellerEarnings = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ earningDetails: responseJsonsellerEarnings && responseJsonsellerEarnings.earning_details, loading: false }, () => this.filterCartSellerEarnings())
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getfiltersellerEarningsCallId !== null &&
            this.getfiltersellerEarningsCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonsellerEarnings = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const chartData = this.state.accountFilter === "this_week" ? this.mapKeys(responseJsonsellerEarnings.data, weekDaysMap) : responseJsonsellerEarnings.data;
            this.setState({ dataChart: responseJsonsellerEarnings && chartData, loading: false, isChartLoading: false })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    mapKeys = (
        data: { [key: string]: { total_earnings: number } },
        mapData: { [key: string]: string },
        prefix: boolean = false
      ): { [key: string]: { total_earnings: number } } => {
        const result: { [key: string]: { total_earnings: number } } = {};
      
        Object.entries(data).forEach(([key, value]) => {
          const mappedDay = mapData[key];
          const newKey = prefix ? `${key.padStart(2, '0')} - ${mappedDay}` : mappedDay;
          result[newKey] = value;
        });
      
        return result;
      };

    handleOpenPdf = () => {
        this.setState({ pdfOpen: true });
    };

    handleClosePdf = () => {
        this.setState({ pdfOpen: false });
    }
    handlePopoverClose = () => {
        this.setState({ anchorEl: null ,endDate:null,startDate:null});
    };
    handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handlePdfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        this.setState({ accountPdf: value });
    }
    handlefilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        this.setState({
            accountFilter: value,
        });
    }

    filterSellerEarnings = () => {
        const { startDate, endDate, error, accountFilter } = this.state;
        if(accountFilter==="select_date_range"){
            let errorState = "";
            if(startDate===null){
            errorState = this.transEarnings("*Start date is required for Filter Please select start date.")
            } else if(endDate===null){
            errorState = this.transEarnings("*End date is required for Filter Please select end date.")
            } else if (error !== ""){
            errorState = error;
            }
            if(errorState !==""){
            this.setState({error: errorState});
            return;
            }
            
        }
        this.setState({ tempFilter: this.state.accountFilter, anchorEl: null, isChartLoading: true}, () => {
            this.filterCartSellerEarnings()
        });
    };
    orderSellerEarnings = async (orderLable: string) => {
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getsellerEarningsCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.sellerEarnings}=${orderLable}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }

    filterCartSellerEarnings = async () => {
        const startDateNew = moment(this.state.startDate).format('DD-MM-YYYY');
        const startDateNewEnd = moment(this.state.endDate).format('DD-MM-YYYY');
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getfiltersellerEarningsCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.accountFilter === "select_date_range" ?
            `${configJSON.startEndDate}?&start_date=${startDateNew}&end_date=${startDateNewEnd}` :
            `${configJSON.filterDate}=${this.state.accountFilter}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    handleSingleChangeNumber(event: React.ChangeEvent<{ value: unknown }>) {
        const newValue = event.target.value as string;
        this.setState({ numberData: newValue });
        this.orderSellerEarnings(newValue);
    }
    
    handlesatrtDateChange = (startDate: Date | null) => {
        if (this.state.endDate && startDate && startDate > this.state.endDate) {
          this.setState({ error: this.transEarnings('Start date should be before the end date.') });
        } else {
          this.setState({ error: '', startDate: startDate });
        }
      };
      
      handleEndDateChange = (endDate: Date | null) => {
        if (this.state.startDate && endDate && endDate < this.state.startDate) {
          this.setState({ error: this.transEarnings('End date should be after the start date.') });
        } else {
          this.setState({ error: '', endDate: endDate });
        }
      };

    sellerEarningsDownload = async () => {
        const pageHtml = document.documentElement.outerHTML;
        const httpBody = {
            "text": `<!DOCTYPE html>${pageHtml}`
        };

        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getearningsDownload = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'POST'
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.earningsDownload}=${this.state.accountPdf}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }

    transEarnings = (earKey: string) => {
        return i18n.t(earKey, {ns: "analytics"})
    }

    handleDateShow = (date: NullableDate) => {
        const locale = i18n.dir() === 'rtl' ? 'ar' : 'en';
        const momentDate = date ? moment(date) : moment();
        return momentDate.locale(locale).format('DD MMM YYYY');
    };
    // Customizable Area End
}