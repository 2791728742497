import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  styled,
  Box,
  Typography,
  Tooltip,
  Paper,
  Button,
  Dialog,
  TextField,
} from "@material-ui/core";
import StylistProductSourcingRequestController, {
  Props,
} from "./StylistProductSourcingRequestController";
import StylishSidebar from "../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../components/src/Stylish/StylishHeader";
import { ThemeProvider } from "react-native-elements";
import {
  backBtnIcon,
  crossIcon,
  uploadIcon,
} from "../../../customform/src/assets";
import {
  FormHelperTextContainer,
  PlaceOfferContainer,
  TitleContainer,
} from "./StylishProductSourcing.web";
import { btnRemoveIcon, successCheckedIcon } from "../assets";
import { ListContainer } from "./StylishMyOffer.web";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
import Loader from "../../../../components/src/LoaderContainer";
import i18n from "../../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylistProductSourcingRequest extends StylistProductSourcingRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  editPopUp = () => (
    <PlaceOfferContainer
      data-test-id="edit-place-offer-popup-test-id"
      open={this.state.isPlaceOfferPopup}
      dir={i18n.dir()}
    >
      <Box id="cross-ic" className="cross-icon-container">
        <img
          src={crossIcon}
          data-test-id="cross-icon-test"
          onClick={() => this.handleEditPlaceOfferPopupClose()}
          alt="cross-icon"
        />
      </Box>
      <Box id="main-pd-field" className="main-field">
        <Typography id="product-desc-text" className="label-text">{this.transStylishProductSourcingReq("Product Description")} *</Typography>
        <TextField
          data-test-id="edit-product-description"
          fullWidth
          variant="outlined"
          rows={4}
          className={`inputFeild2 ${
            this.state.errorState.isProductDescError ? "Mui-error" : ""
          }`}
          id="edit-pro-desc-text-field"
          onChange={(event) => this.handleEditProductDesc(event.target.value)}
          placeholder={this.transStylishProductSourcingReq("Write product description here")}
          multiline
          inputProps={{ maxLength: 582 }}
          value={this.state.productDescription}
        />
        {this.state.errorState.isProductDescError && (
          <FormHelperTextContainer id="edit-product-decs-error" data-test-id="edit-product-decs-error">
            {this.state.errorState.productDescErrorMessage}
          </FormHelperTextContainer>
        )}
      </Box>
      <Box id="main-qp-field" className="main-field">
        <Typography id="quote-price-text" className="label-text">{this.transStylishProductSourcingReq("Quote Price")} *</Typography>
        <TextField
          data-test-id="edit-quote-price"
          fullWidth
          onChange={(event) => this.handleEditQuotePrice(event.target.value)}
          variant="outlined"
          value={this.state.quotePrice}
          id="edit-quote-price-text-field"
          className={`inputFeild2 ${
            this.state.errorState.isQuotePriceError ? "Mui-error" : ""
          }`}
          placeholder={`${this.state.currencySign} 0`}
        />
        {this.state.errorState.isQuotePriceError && (
          <FormHelperTextContainer id="edit-quote-price-error" data-test-id="edit-quote-price-error">
            {this.state.errorState.quotePriceErrorMessage}
          </FormHelperTextContainer>
        )}
      </Box>
      <Box id="main-upload-field" className="main-field">
        <Typography className="label-text" id="attachment-text">{this.transStylishProductSourcingReq("Attachments")} *</Typography>
        <Button variant="contained" id="edit-upload-btn" component="label" className="upload-btn">
          <img src={uploadIcon} id="edit-upload-img" alt="upload" />
          <Typography
            id="edit-upload-btn-title-text"
            className={`upload-title ${
              this.state.updatedFiles.length >= 5 ? "disabled" : ""
            }`}
          >
            {this.transStylishProductSourcingReq("Upload png, jpg, jpeg")}
          </Typography>
          <Typography className="upload-csv" id="edit-upload-btn-warning-text">
          {this.transStylishProductSourcingReq("You can attach up to 5 images.")}
          </Typography>
          <input
            type="file"
            id="edit-upload-id-test"
            hidden
            disabled={this.state.updatedFiles.length >= 5}
            data-test-id="edit-upload-id-test"
            multiple
            accept=".png, .jpg, .jpeg"
            onChange={
              this.state.updatedFiles.length <= 5
                ? (event) => this.handleEditUploadChange(event)
                : undefined
            }
          />
        </Button>
        {this.state.errorState.isAttachmentError && (
          <FormHelperTextContainer id="edit-upload-error" data-test-id="edit-upload-error">
            {this.state.errorState.attachmentErrorMessage}
          </FormHelperTextContainer>
        )}
      </Box>
      <Box id="edit-preview-container" className="preview-container">
        {this.state.updatedFiles.map((file, index) => (
          <Box className="file-preview" key={index}>
            <img
              className="preview-image"
              alt={`preview ${index}`}
              src={URL.createObjectURL(file)}
            />
            <img
              id={`edit-remove-test-${index}`}         
              className="remove-btn"
              data-test-id={`edit-remove-test-${index}`}
              src={btnRemoveIcon}
              alt="remove-icon"
              onClick={() => this.handleEditRemoveFile(index)}
            />
          </Box>
        ))}
      </Box>
      <Button
        variant="contained"
        data-test-id="edit-submit-place-offer-test-id"
        className={"place-offer-btn-2"}
        id="edit-submit-place-offer-test-id"
        disableElevation
        onClick={() => this.handleEditPlaceOfferSubmit()}
      >
        {this.transStylishProductSourcingReq("Submit")}
      </Button>
    </PlaceOfferContainer>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container dir={i18n.dir()}>
          <Grid
            item
            xl={2}
            id="stylish-sidebar-my-offer"
            md={2}
            lg={2}
            sm={2}
            xs={2}
          >
            <StylishSidebar
              data-test-id="product-sourcing-request-sidebar"
              navigation={this.props.navigation}
            />
          </Grid>
          <Grid
            item
            xl={10}
            id="stylish-header-product-sourcing-request"
            md={10}
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader
              data-test-id="product-sourcing-request-header"
              navigation={this.props.navigation}
            />
            <StylishProductSouRequestContainer>
              <TitleContainer>
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  onClick={() =>
                    this.handleStylishProductSourcingRequestRedirect(
                      "StylishMyOffer"
                    )
                  }
                  style={{transform: i18n.dir()==="ltr" ? "rotate(0deg)": "rotate(180deg)"}}
                />
                <Typography className="title">
                  {this.transStylishProductSourcingReq("Product Sourcing Requests")}
                </Typography>
              </TitleContainer>
              {this.state.isLoading ? (
                <Box className="loader-container">
                  <Loader
                    id="product-sourcing-request-loader"
                    dataTestId="product-sourcing-request-loader-test"
                  />
                </Box>
              ) : (
                <>
                  {this.state.bidRequestResp?.id && (
                    <>
                      <MainOfferContainer>
                        <ListContainer
                          data-test-id={`list-${this.state.bidRequestResp.id}`}
                        >
                          <Box className="img-pro-sourcing-request-container">
                            <img
                              className="image"
                              src={
                                this.state.bidRequestResp.attributes
                                  .product_sourcing_request.images[0].url
                              }
                              alt={`img-${this.state.bidRequestResp.attributes.product_sourcing_request.images[0].id}`}
                            />
                          </Box>
                          <Box
                            className="list-right-part"
                            style={{ height: 110 }}
                          >
                            <Box className="top-container">
                              <Box>
                                <Tooltip
                                  title={
                                    this.state.bidRequestResp.attributes
                                      .product_sourcing_request.product_name
                                  }
                                >
                                  <Typography
                                    className="text-title"
                                    style={{ fontWeight: 500 }}
                                  >
                                    {
                                      this.state.bidRequestResp.attributes
                                        .product_sourcing_request.product_name
                                    }
                                  </Typography>
                                </Tooltip>
                              </Box>
                              <Box
                                className={`container-${this.state.bidRequestResp.attributes.request_status}`}
                              >
                                <Typography
                                  className={`status-${this.state.bidRequestResp.attributes.request_status}`}
                                  style={{ fontSize: 14, fontWeight: 700 }}
                                >
                                  {
                                    this.transStylishProductSourcingReq(this.state.bidRequestResp.attributes
                                      .request_status)
                                  }
                                </Typography>
                              </Box>
                            </Box>
                            <BottomContainer>
                              <Typography className="price-range">
                                {this.state.currencySign} {
                                  +this.state.bidRequestResp.attributes
                                    .product_sourcing_request.min_price
                                }{" "}
                                - {this.state.currencySign} {+this.state.bidRequestResp.attributes.product_sourcing_request.max_price}
                              </Typography>
                            </BottomContainer>
                          </Box>
                        </ListContainer>
                        <Box className="list-display">
                          <Box className="row-container">
                            <Box className="description-data">
                              <Typography className="description-name">
                              {this.transStylishProductSourcingReq("Sizes")} -{" "}
                              </Typography>
                              <Typography className="description-value">
                                {this.state.bidRequestResp.attributes.product_sourcing_request.sizes.join(
                                  " ,"
                                )}
                              </Typography>
                            </Box>
                            <Box className="description-data">
                              <Typography className="description-name">
                              {this.transStylishProductSourcingReq("Quantity")} -{" "}
                              </Typography>
                              <Typography className="description-value">
                                {
                                  this.state.bidRequestResp.attributes
                                    .product_sourcing_request.quantity
                                }{" "}
                                {this.transStylishProductSourcingReq("Units")}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="row-container">
                            <Box className="description-data">
                              <Typography className="description-name">
                              {this.transStylishProductSourcingReq("Colours")} -{" "}
                              </Typography>
                              <Typography className="description-value">
                                {this.state.bidRequestResp.attributes.product_sourcing_request.colours.join(
                                  " ,"
                                )}
                              </Typography>
                            </Box>
                            <Box className="description-data">
                              <Typography className="description-name">
                              {this.transStylishProductSourcingReq("Gender")} -{" "}
                              </Typography>
                              <Typography className="description-value">
                                {
                                  this.state.bidRequestResp.attributes
                                    .product_sourcing_request.gender
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="row-container">
                            <Box className="description-data">
                              <Typography
                                className="description-name"
                                style={{ marginBottom: 16 }}
                              >
                                {
                                  this.state.bidRequestResp.attributes
                                    .product_sourcing_request.description
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </MainOfferContainer>
                      <Grid container className="requester-detail">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Box className="offer">
                            <Typography
                              className="title-container"
                              style={{ width: "100%" }}
                            >
                              {this.transStylishProductSourcingReq("My Bid")}
                            </Typography>
                            <Box className="preview-container">
                              {this.state.bidRequestResp.attributes.images.map(
                                (file) => (
                                  <Box key={file.id} className="file-preview">
                                    <img
                                      src={file.url}
                                      alt={`preview ${file.id}`}
                                      className="preview-image"
                                    />
                                  </Box>
                                )
                              )}
                            </Box>
                            <Box className="request-price-container">
                              <Typography className="title-container">
                                {this.state.bidRequestResp.attributes.full_name}
                              </Typography>
                              <Typography className="title-container">
                              {this.state.currencySign} {+this.state.bidRequestResp.attributes.quote_price}
                              </Typography>
                            </Box>
                            <Typography className="requestor-description">
                              {
                                this.state.bidRequestResp.attributes
                                  .product_description
                              }
                            </Typography>
                            <ButtonContainer>
                              {this.state.bidRequestResp.attributes
                                .request_status === "waiting" && (
                                <>
                                  <Button
                                    variant="outlined"
                                    className={"delete-outline-btn"}
                                    disableElevation
                                    data-test-id="delete-test-id"
                                    onClick={() => this.handleDeleteOpen()}
                                  >
                                    {this.transStylishProductSourcingReq("Delete")}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className={"confirm-btn"}
                                    disableElevation
                                    data-test-id="edit-offer-test-id"
                                    onClick={()=>this.handleEditBidOpen()}
                                  >
                                    {this.transStylishProductSourcingReq("Edit Bid")}
                                  </Button>
                                </>
                              )}
                              {this.state.bidRequestResp.attributes
                                .request_status === "accepted" && (
                                <Button
                                  variant="contained"
                                  className={"customer-chat-btn"}
                                  disableElevation
                                  data-test-id="customer-chat-btn-test-id"
                                  onClick={() => this.handleCustomerChat()}
                                >
                                  {this.transStylishProductSourcingReq("Chat with Customer")}
                                </Button>
                              )}
                            </ButtonContainer>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
              <DeletePopup
                data-test-id="delete-pop-up-test-id"
                open={this.state.isDeletePopup}
                dir={i18n.dir()}
              >
                <Typography className="delete-title">
                {this.transStylishProductSourcingReq("Delete Your Bid")}
                </Typography>
                <Typography className="delete-description">
                  {this.transStylishProductSourcingReq("Your bid will be deleted permanently.")}
                </Typography>
                <ButtonContainer style={{ height: 54 }}>
                  <Button
                    variant="contained"
                    className={"delete-btn"}
                    disableElevation
                    data-test-id="success-delete-test-id"
                    onClick={() => this.handleDeleteClick()}
                  >
                    {this.transStylishProductSourcingReq("Delete")}
                  </Button>
                  <Button
                    variant="outlined"
                    className={"cancel-btn"}
                    disableElevation
                    data-test-id="cancel-test-id"
                    onClick={() => this.handleDeleteOpen()}
                  >
                    {this.transStylishProductSourcingReq("Cancel")}
                  </Button>
                </ButtonContainer>
              </DeletePopup>
              <DeleteSuccessPopup
                data-test-id="delete-success-pop-up"
                open={this.state.isSuccessDeletePopup}
                dir={i18n.dir()}
              >
                <Box className="img-container">
                  <img alt="success-ic" src={successCheckedIcon} />
                </Box>
                <Typography className="delete-message">
                  {this.transStylishProductSourcingReq("Bid deleted successfully")}!
                </Typography>
              </DeleteSuccessPopup>
              {this.state?.bidRequestResp?.attributes?.request_status ===
                "waiting" && this.editPopUp()}
            </StylishProductSouRequestContainer>
          </Grid>
        </Grid>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertPSRSnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const BottomContainer = styled(Box)({
  width: "100%",
  display: "flex",
  "& .price-range": {
    fontFamily: "Lato, sans-serif",
    width: "100%",
    color: "#375280",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 24,
  },
});
const StylishProductSouRequestContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .loader-container": {
    width: "100%",
    minHeight: 375,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  "& .requester-detail": {
    marginTop: 32,
    "& .offer": {
      display: "flex",
      width: "100%",
      padding: 16,
      flexDirection: "column",
      "& .title-container": {
        fontFamily: "Lato, sans-serif",
        fontSize: 18,
        fontWeight: 700,
        color: "#375280",
      },
      "& .request-price-container": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      },
      "& .requestor-description": {
        fontFamily: "Lato, sans-serif",
        fontSize: 16,
        fontWeight: 500,
        color: "#94A3B8",
        width: "100%",
        marginTop: 10,
      },
    },
  },
  "& .preview-container": {
    display: "flex",
    gap: 12,
    margin: "16px 0px 20px 0px",
    flexWrap: "wrap",
    "& .file-preview": {
      position: "relative",
      "& .preview-image": {
        width: 100,
        height: 100,
        objectFit: "cover",
        borderRadius: 2,
      },
    },
  },
});

const MainOfferContainer = styled(Paper)({
  padding: 16,
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  "& .list-display": {
    display: "flex",
    gap: 12,
    flexDirection: "column",
    paddingTop: 16,
    "& .row-container": {
      display: "flex",
      justifyContent: "space-between",
      "@media(max-width:540px)": {
        flexDirection: "column",
      },
      "& .description-data": {
        display: "flex",
        "& .description-name": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 400,
          fontSize: 16,
          color: "#375280",
        },
        "& .description-value": {
          fontFamily: "Lato, sans-serif",
          fontSize: 16,
          fontWeight: 900,
          color: "#375280",
          paddingLeft: 4,
        },
      },
    },
  },
});

const ButtonContainer = styled(Box)({
  display: "flex",
  gap: 16,
  width: "100%",
  marginTop: 24,
  "& .cancel-btn": {
    textTransform: "capitalize",
    color: "#375280",
    borderColor: "#CCBEB1",
    width: "50%",
    height: "100%",
    fontFamily: "Lato, sans-serif",
    "& .MuiButton-label": {
      fontSize: "20px",
      fontWeight: 800,
    },
  },
  "& .delete-outline-btn": {
    textTransform: "capitalize",
    color: "#F87171",
    borderColor: "#F87171",
    width: "50%",
    height: "100%",
    fontFamily: "Lato, sans-serif",
    "& .MuiButton-label": {
      fontSize: "16px",
      fontWeight: 500,
    },
  },
  "& .confirm-btn": {
    textTransform: "capitalize",
    color: "white",
    fontFamily: "Lato, sans-serif",
    gap: "8px",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
    width: "50%",
    height: "100%",
    borderRadius: "2px",
    "& .MuiButton-label": {
      fontSize: "16px",
      fontWeight: 900,
    },
  },
  "& .customer-chat-btn": {
    textTransform: "capitalize",
    color: "white",
    width: "100%",
    gap: "8px",
    borderRadius: 2,
    fontFamily: "Lato, sans-serif",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
    height: "100%",
    "& .MuiButton-label": {
      fontSize: "18px",
      fontWeight: 700,
    },
  },
  "& .delete-btn": {
    textTransform: "capitalize",
    color: "white",
    fontFamily: "Lato, sans-serif",
    gap: "8px",
    backgroundColor: "#F87171",
    width: "50%",
    height: "100%",
    borderRadius: "2px",
    "& .MuiButton-label": {
      fontSize: "20px",
      fontWeight: 800,
    },
  },
});

const DeletePopup = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      padding: 48,
      width: 616,
      "& .delete-title": {
        fontFamily: "Lato, sans-serif",
        fontSize: 30,
        fontWeight: 500,
        color: "#375280",
        textAlign: "center",
      },
      "& .delete-description": {
        fontFamily: "Lato, sans-serif",
        fontSize: 18,
        fontWeight: 400,
        color: "#375280",
        textAlign: "center",
        margin: "16px 0px",
      },
    },
  },
});

const DeleteSuccessPopup = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      padding: "50px 60px",
      width: 471,
      "& .delete-message": {
        fontFamily: "Lato, sans-serif",
        fontSize: 30,
        fontWeight: 400,
        color: "#375280",
        textAlign: "center",
      },
      "& .img-container": {
        width: "100%",
        marginBottom: 30,
        display: "flex",
        justifyContent: "center",
        "& img": {
          width: 80,
          height: 80,
        },
      },
    },
  },
});
// Customizable Area End
