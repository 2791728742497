import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
interface DayOpenClose {
  open: string;
  close: string;
  is_open: boolean;
}
interface MyStoreList {
  id: string;
  type: string;
  attributes: {
    store_name: string;
    description: string;
    address: string;
    area: string;
    block: string;
    mall_name: string;
    floor: string;
    unit_number: number | null;
    city: string;
    zipcode: string;
    driver_instruction: string;
    average_shipping_time: string;
    payment_mode: string[];
    store_operating_hours: {
      monday: DayOpenClose;
      tuesday: DayOpenClose;
      wednesday: DayOpenClose;
      thursday: DayOpenClose;
      friday: DayOpenClose;
      saturday: DayOpenClose;
      sunday: DayOpenClose;
    };
    status: "Pending" | "Approved" | "Rejected";
    latitude: number;
    longitude: number;
    is_open: boolean;
    available_variants: [];
    image: string;
    email: string;
    contact_number: {
      country_code: string;
      phone_number: string;
    };
    expected_delivery_time: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  adminRequestData: MyStoreList[];
  userRole: string;
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  myStoreApiCallID:string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      adminRequestData: [],
      userRole: "",
      isAlert: false,
      alertType: 'success',
      alertMsg: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.myStoreApiCallID){
      if(responseJson.message){
        this.setState({
          isAlert: true,
          alertType: "error",
          alertMsg: `${responseJson?.message}`
        });
      }else{
        this.setState({
          adminRequestData: responseJson?.data
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start 
  async componentDidMount() {
    this.getSellerStore();
    const userData = await getStorageData('userRole', true);
    this.setState({
      userRole: userData?.userType
    })
    
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false }); 
  };

   getSellerStore = async () => {
   const token = await getStorageData("auth-token");
    const header = {
        "Content-Type": configJSON.applicationJsonContentType,
        token: token,
    }; 
    const requestMessageList = new Message(getName(MessageEnum.RestAPIRequestMessage)); 

    this.myStoreApiCallID = requestMessageList.messageId; 

    requestMessageList.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.getApiMethodType);

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerMyStoreEndPoint
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  transAdmin = (adminKey: string) => {
    return i18n.t(adminKey, {ns: "profile"})
  }
  // Customizable Area End
}
