import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";

interface Props extends RadioProps {
  variant: "solid" | "outlined";
}
// Customizable Area End

const RadioButton: React.FC<Props> = (props) => {
  const BpIcon = styled("span")(() => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    border: "1px solid #CCBEB1",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: props.variant === "outlined" ? "#CCBEB1" : "#FFF",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&::before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        props.variant === "outlined"
          ? "radial-gradient(#fff,#fff 28%,transparent 32%)"
          : "radial-gradient(#CCBEB1, #CCBEB1 40%,transparent 50%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: props.variant === "outlined" ? "#CCBEB1" : "#FFF",
    },
  });

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default RadioButton;
