import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { apiCall } from "../../../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import { StoreOperatingHours } from "../../SellerStoreProfileController";
import { logoutSellerNavigation } from "../../../../../components/src/Seller/logOut";
import i18n from "../../../../../components/src/i18next/i18n";

interface ErrorMessageSellerCatalogues {
  errors: [
    {
      token?: string;
    }
  ];
}

interface ErrorMessage {
  errors?: string;
  message?: string;
}

export interface ResponseData<T> {
  data: ResponseState<T>[];
}

export interface ResponseState<T> {
  id: string;
  type: string;
  attributes: T;
}

export interface CategoryResponse extends SubCategoryResponse {
  status: string;
}

export interface SubCategoryResponse {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  image: string;
}

export interface MetaResponse {
  total_pages: number;
  current_page: number;
  total_record: number;
  prev_page: number | null;
  next_page: number | null;
}

export interface CataloguesDataResp {
  data: CataloguesDataState[];
  meta: MetaResponse;
}

interface CataloguesDataState {
  id: string;
  type: string;
  attributes: CataloguesAttributesResp;
}

interface CataloguesVariants {
  id: string;
  type: string;
  attributes: {
    id: number;
    catalogue_id: number;
    catalogue_variant_color_id: number;
    catalogue_variant_color: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
    };
    catalogue_variant_size_id: number;
    catalogue_variant_size: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
    };
    price: string;
    stock_qty: number;
    on_sale: string | null;
    sale_price: string | null;
    discount_price: string | null;
    length: string | null;
    breadth: string | null;
    height: string | null;
    created_at: string;
    updated_at: string;
    sku: string;
    deactivate: boolean;
    low_stock_threshold: number;
    is_listed: number;
    front_image: string;
    back_image: string;
    side_image: string;
    pair_it_with: [];
  };
}

interface CataloguesVariantsStore {
  id: string;
  type: string;
  attributes: CataloguesVariants;
  store_info: {
    id: string;
    type: string;
    attributes: {
      store_name: string;
      description: string;
      address: string;
      area: string;
      block: string;
      mall_name: string;
      floor: string;
      unit_number: number | null;
      city: string;
      zipcode: string;
      driver_instruction: string;
      average_shipping_time: string;
      payment_mode: string[];
      store_operating_hours: StoreOperatingHours;
      status: string;
      latitude: number;
      longitude: number;
      is_open: boolean;
      available_variants: Variants[];
      image: string;
      email: string;
      contact_number: {
        country_code: string;
        phone_number: string;
      };
      expected_delivery_time: string;
    };
  };
}

interface Variants {
  id: string;
  type: string;
  attributes: {
    id: 466;
    catalogue_id: 298;
    product_name: string;
    product_description: string;
    sku: string;
    stock_qty: 4995;
    low_stock_threshold: 0;
    is_listed: true;
    price: string;
    size: string;
    colour: string;
    gender: string;
    front_image: string;
    brand_name: string;
  };
}

interface CataloguesAttributesResp {
  name: string;
  brand: string | null;
  tags: {
    data: string[];
  };
  reviews: string[];
  sku: string | null;
  description: string;
  manufacture_date: string | null;
  length: string | null;
  breadth: string | null;
  height: string | null;
  stock_qty: number | null;
  availability: string | null;
  weight: string | null;
  price: string | null;
  recommended: string | null;
  on_sale: string | null;
  sale_price: string | null;
  discount: string | null;
  is_wishlist: boolean;
  product_number: string | null;
  primary_image: string;
  primary_price: string;
  gender: string;
  brand_name: string;
  material: string;
  fit: string;
  prodcut_care: string;
  list_the_product: string;
  fit_discription: string | null;
  is_published: boolean;
  category: ResponseState<CategoryResponse>;
  sub_category: ResponseState<SubCategoryResponse>;
  sub_sub_category: ResponseState<SubCategoryResponse>;
  service: string | null;
  average_rating: number;
  catalogue_variants: CataloguesVariants[];
  catalogue_variants_with_store: CataloguesVariantsStore[];
}

interface CataloguesState {
  id: string;
  name: string;
  description: string;
  primary_price: string;
  primary_image: string;
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeTab: number;
  activeStep: number;
  productNameTitle: string;
  store_id: number;
  get_feature: "category" | "sub-category" | "sub-sub-category";
  category_id: number;
  sub_category_id: number;
  isLoading: boolean;
  isAlert: boolean;
  severity: "error" | "warning" | "info" | "success";
  message: string;
  responseCategoryData: ResponseState<CategoryResponse>[];
  responseSubCategoryData: ResponseState<SubCategoryResponse>[];
  responseSubSubCategoryData: ResponseState<SubCategoryResponse>[];
  defaultAccordionOpen: number;
  isLoadingInside: boolean;
  responseSubSubCategoryCataloguesData: CataloguesDataState[];
  cataloguesData: CataloguesState[];
  page: number;
  cataloguesMeta: MetaResponse;
  sub_sub_category_id: number;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerCataloguesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoryAPICallId: string = "";
  getSubCategoryAPICallId: string = "";
  getSubSubCategoryAPICallId: string = "";
  getSubSubCategoryCataloguesAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      activeTab: 0,
      activeStep: 1,
      productNameTitle: "",
      store_id: 0,
      get_feature: "category",
      category_id: 0,
      sub_category_id: 0,
      sub_sub_category_id: 0,
      isLoading: true,
      isAlert: false,
      severity: "success",
      message: "",
      responseCategoryData: [],
      responseSubCategoryData: [],
      responseSubSubCategoryData: [],
      defaultAccordionOpen: 0,
      isLoadingInside: true,
      responseSubSubCategoryCataloguesData: [],
      cataloguesData: [],
      page: 1,
      cataloguesMeta: {} as MetaResponse,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleRedirectToComponent = this.handleRedirectToComponent.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseData && !responseData.errors && !responseData.message) {
        this.apiSuccess(apiRequestCallId, responseData);
      } else if (
        responseData &&
        (responseData.errors || responseData.message)
      ) {
        this.apiFailer(responseData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiSuccess = async (
    apiRequestCallId: string,
    responseJson: ResponseData<CategoryResponse> &
      ResponseData<SubCategoryResponse> &
      CataloguesDataResp
  ) => {
    switch (apiRequestCallId) {
      case this.getCategoryAPICallId:
        if (responseJson.data)
          this.setState((prevState) => ({
            ...prevState,
            responseCategoryData: responseJson.data,
            activeTab: responseJson.data && +responseJson.data[0].id,
            isLoading: false,
          }));
        break;
      case this.getSubCategoryAPICallId:
        if (responseJson.data) this.responseSubCategory(responseJson.data);
        break;
      case this.getSubSubCategoryAPICallId:
        if (responseJson.data)
          this.setState((prevState) => ({
            ...prevState,
            responseSubSubCategoryData: responseJson.data,
            isLoading: false,
            isLoadingInside: false,
          }));
        break;
      case this.getSubSubCategoryCataloguesAPICallId:
        if (responseJson.data) {
          this.setState((prevState) => ({
            ...prevState,
            responseSubSubCategoryCataloguesData: responseJson.data,
            isLoadingInside: false,
            cataloguesMeta: responseJson.meta,
          }));
          this.handleCataloguesData(responseJson.data);
        }
        break;
      default:
        break;
    }
  };

  apiFailer = (responseJson: ErrorMessage & ErrorMessageSellerCatalogues) => {
    if (responseJson.message === "Store has no products.") {
      this.setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
    else if (responseJson.message) {
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true,
        severity: "error",
        isLoading: false,
        message: responseJson.message as string,
      }));
    } else if (responseJson.errors[0].token) {
      this.setState(
        (prevState) => ({
          ...prevState,
          isAlert: true,
          message: responseJson.errors[0].token as string,
          severity: "error",
          isLoading: false,
        }),
        () => {
          setTimeout(() => {
            this.handleRedirectToComponent("Home");
            logoutSellerNavigation();
          }, 2000);
        }
      );
    } else if (responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseJson.errors as string,
        isLoading: false,
        isAlert: true,
        severity: "error",
      }));
    }
  };

  handleCataloguesData = (catalogues: CataloguesDataState[]) => {
    const catalogueState: CataloguesState[] = catalogues.map((item) => {
      const { id, attributes } = item;
      const { name, description, primary_price, primary_image } = attributes;

      return {
        id,
        name,
        description,
        primary_price,
        primary_image,
      };
    });
    this.setState((prevState) => ({
      ...prevState,
      isLoading: false,
      cataloguesData: catalogueState,
    }));
  };

  handleCataloguePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    event.preventDefault();
    this.setState((prevState) => ({
      ...prevState,
      cataloguesMeta: { ...prevState.cataloguesMeta, current_page: newPage },
      isLoading: !prevState.isLoading,
    }));
    this.handleSubSubCategoryCataloguesAPICallId(
      this.state.sub_sub_category_id
    );
  };

  responseSubCategory = (subCategory: ResponseState<SubCategoryResponse>[]) => {
    this.setState((prevState) => ({
      ...prevState,
      responseSubCategoryData: subCategory,
      isLoading: false,
      defaultAccordionOpen:
        subCategory.length !== 0
          ? +subCategory[0].id
          : prevState.defaultAccordionOpen,
      sub_category_id:
        subCategory.length !== 0
          ? +subCategory[0].id
          : prevState.defaultAccordionOpen,
    }));
    this.handleLoading();
    this.getSubSubCategoryAPICall(+subCategory[0].id);
  };

  async componentDidMount() {
    // Customizable Area Start
    const storeId = +(await this.getDataFromStorage("store-id"));
    this.setState((prevState) => ({
      ...prevState,
      store_id: storeId,
    }));
    this.getCategoryAPICall();
    // Customizable Area End
  }

  handleLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
  };

  handleRedirectToComponent = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  handleBack = () => {
    if (this.state.activeStep === 1) {
      this.handleRedirectToComponent("SellerStoreProfile");
    } else {
      this.setState((prevState) => ({
        ...prevState,
        activeStep: prevState.activeStep - 1,
      }));
    }
  };

  handleChange = (value: number) => {
    this.setState((prevState) => ({
      ...prevState,
      activeTab: value,
      activeStep: prevState.activeStep + 1,
      category_id: value,
    }));
    this.handleLoading();
    this.getSubCategoryAPICall(value);
  };

  handleProductClick = (productName: string, productId: number) => {
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep + 1,
      productNameTitle: productName,
      productId: productId,
      isLoading: !prevState.isLoading,
      sub_sub_category_id: productId,
    }));
    this.handleSubSubCategoryCataloguesAPICallId(productId);
  };

  handleTabChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    this.setState((prevState) => ({
      ...prevState,
      activeTab: newValue,
      category_id: newValue,
    }));
    this.handleLoading();
    this.getSubCategoryAPICall(newValue);
  };

  getDataFromStorage = async (value: string) => {
    return await getStorageData(value);
  };

  getCategoryAPICall = async () => {
    const { store_id, get_feature } = this.state;
    const endPoint = `${configJSON.getCategoryEndPoint}store_id=${store_id}&get_feature=${get_feature}`;

    this.getCategoryAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: endPoint,
      token: await this.getDataFromStorage("auth-token"),
    });
  };

  getSubCategoryAPICall = async (category_id: number) => {
    const { store_id } = this.state;
    const endPoint = `${configJSON.getCategoryEndPoint}store_id=${store_id}&get_feature=sub-category&category_id=${category_id}`;

    this.getSubCategoryAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: endPoint,
      token: await this.getDataFromStorage("auth-token"),
    });
  };

  getSubSubCategoryAPICall = async (sub_category_id: number) => {
    const { store_id, category_id } = this.state;
    const endPoint = `${configJSON.getCategoryEndPoint}store_id=${store_id}&get_feature=sub-sub-category&category_id=${category_id}&sub_category_id=${sub_category_id}`;

    this.getSubSubCategoryAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: endPoint,
      token: await this.getDataFromStorage("auth-token"),
    });
  };

  handleSubSubCategoryCataloguesAPICallId = async (
    sub_sub_category_id: number
  ) => {
    const { store_id, page } = this.state;
    this.getSubSubCategoryCataloguesAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getCataloguesByCategoryEndPoint}store_id=${store_id}&sub_sub_category_id=${sub_sub_category_id}&page=${page}`,
      token: await this.getDataFromStorage("auth-token"),
    });
  };

  onAlertBarClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: !prevState.isAlert,
    }));
  };

  handleAccordionToggle = (id: number) => {
    this.setState((prevState) => ({
      ...prevState,
      defaultAccordionOpen: id,
    }));
    this.handleInsideLoading();
    this.getSubSubCategoryAPICall(id);
  };

  handleInsideLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      isLoadingInside: true,
    }));
  };

  tranCatalogue = (transKey: string) => {
    return i18n.t(transKey, {ns: "catalogue"});
  }
  

  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End
