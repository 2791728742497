import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/APICall";
import { getStorageData } from "../../../framework/src/Utilities";
import { Meta } from "./OneToOneChatCotroller.web";
import i18n from "../../../components/src/i18next/i18n";


type AlertSnackType = 'success' | 'error' | 'warning' | 'info';

export interface Attributes {
  id: number | string;
  name: string;
  candidate_name: string;
  unread_message_count: number;
  is_mark_read: boolean;
  last_message_type: string;
  candidate_role: string;
  profile?: string; 
}

interface ChatObject {
  id: string;
  type: string;
  attributes: Attributes;
  relationships: { accounts: {
    data: {
      id: string;
      type: string;
    }[];
  }};
}

interface ClientListResponse {
  data: ChatObject[];
  error?: string;
  errors?: string[];
  meta: Meta
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  selectedClient: Attributes | null,
  anchorElClientsChat: HTMLElement | null;
  query: string;
  chatId: string | number;
  hasmoreClients: boolean;
  clientList: Attributes[];
  stylistFullName: string;
  stylistProfile: string;
  isLoad: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: AlertSnackType;
  meta: Meta;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private debounceTimeout: NodeJS.Timeout | null = null;
  getClientsAPICallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      selectedClient: null,
      anchorElClientsChat: null,
      query: "",
      chatId: "",
      hasmoreClients: false,
      clientList: [],
      stylistFullName: "",
      stylistProfile: "",
      isLoad: false,
      isAlert: false,
      alertMsg: "",
      alertType: 'success' as AlertSnackType,
      meta: {
        current_page: 1,
        next_page: 2,
        prev_page: null,
        total_pages: 0,
        total_count: 0,
      }, 
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(responseJson && !responseJson.errors) {
          if(this.getClientsAPICallId === apiRequestCallId){
            this.getClientListResponse(responseJson)
          }
      } else {
        this.setState({ isLoad: false})
        if(responseJson.errors) {
          const errorMessages = responseJson.errors.map((error: { [key:string]: string}) => Object.values(error)[0]);
          this.setState({
            isAlert: true,
            alertMsg: errorMessages,
            alertType: 'error'
          })
        } else {
          this.setState({
            isAlert: true,
            alertMsg: "Something went wrong",
            alertType: 'error'
          })
        }
      } 
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    this.setState({ isLoad: true})
    this.getClientsList()
    // Customizable Area End
  }

  handleClientClick = async (client: Attributes) => {
    this.setState(
      { selectedClient: client, chatId: client.id }
    );
  };

navigateToClient = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "StylishClients");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}

debounceClientChatSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;

  if (this.debounceTimeout) {
    clearTimeout(this.debounceTimeout);
  }

  this.debounceTimeout = setTimeout(() => {
    this.setState({ query: value }, () => {
      this.getClientsList()
    });
  }, 300);
}

async componentWillUnmount() {
  if (this.debounceTimeout) {
    clearTimeout(this.debounceTimeout);
  }
}

getMoreApiClientList = () => {
  const { meta } = this.state
  if (meta.current_page <= meta.total_pages) {
    this.getClientsList();
  }
};

getClientsList = async () => {
  const {query, meta} = this.state;
  if (!meta.current_page || meta.current_page === null) {
    this.setState({ hasmoreClients: false })
    return
  }

  const clientListToken  = await getStorageData("auth-token")

  const clientListBody = {
    query: query,
    per_page: "10",
    page: meta.current_page
  }

  const endPointOfClientList = `${configJSON.getClientChatsEndPoint}`
  
  this.getClientsAPICallId = await apiCall({
    token: clientListToken,
    contentType: configJSON.apiContentType,
    method: configJSON.postApiMethod,
    endPoint: endPointOfClientList,
    body: clientListBody,
    isJsonStringify: true,
  });
}

getClientListResponse = (resp: ClientListResponse) => {
  if(resp.data && Array.isArray(resp.data)) {
    const listData = resp.data.map((data) => data.attributes);
    const dataArr = this.state.query ? listData : [...this.state.clientList, ...listData];
    this.setState({ clientList: dataArr, isLoad: false });
  }
  if(resp.meta) {
    const { total_pages } = resp.meta
    this.setState((prevState: S) => ({
      meta: { ...resp.meta, current_page: prevState.meta.current_page + 1 },
      hasmoreClients: prevState.meta.current_page < total_pages
    }));
  }
}

oncloseMsgAlert = () => {
  this.setState((prv)=>({...prv, isAlert: false}))
}

transClient = (clientkey: string) => {
  return i18n.t(clientkey, { ns: "chat"})
}

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
