import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import React from "react";
type AlertType = 'success' | 'error' | 'warning' | 'info';
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
interface INavigateTo {
  productId: string | undefined | number;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
type StylistAttributes = {
  activated: boolean;
  country_code: string | null | undefined;  // Allow null and undefined
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  full_name: string;
  phone_number: string;
  type: string | null;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  driver_status: string;
  seller_status: string;
  approve_status: string;
  language: string;
  currency: string;
  bio: string;
  profile_picture: string;
  is_favorite: boolean;
};

type Stylist = {
  id: string;
  type: string;
  attributes: StylistAttributes;
};


// Customizable Area End

export const configJSON = require("./config.js");

// INTERFACES STARTS
export interface IHiredStylist {
  id: string;
  type: string;
  attributes: HiredStylistAttributes;
}
export interface HiredStylistAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  full_name: string;
  phone_number: string;
  type?: null;
  created_at: string;
  updated_at: string;
  device_id?: null;
  unique_auth_id: string;
  driver_status: string;
  seller_status: string;
  approve_status: string;
  language: string;
  currency: string;
  bio: string;
  profile_picture: string;
  is_hired: boolean;
  is_favorite?: boolean
}

export interface IExplorePortfolioList {
  id: string;
  type: string;
  attributes: PortfolioAttributes;
}
export interface PortfolioAttributes {
  images?: (ImagesEntity)[] | null;
}
export interface ImagesEntity {
  portfolio_image_id: number;
  description: string;
  image_id: number;
  url: string;
}

export interface IProductSourcing {
  id: string;
  type: string;
  attributes: IProductSourcingAttributes;
}
export interface IProductSourcingAttributes {
  id: number;
  product_name: string;
  request_status: string;
  max_price: string;
  min_price: string;
  sizes?: (string)[] | null;
  description: string;
  gender: string;
  quantity: number;
  colours?: (string)[] | null;
  images?: (ProductSourcingImages)[] | null;
  total_stylist_offer_requests: number;
  product_sourcing_stylist_prices?: (ProductSourcingStylistPrices)[] | null;
}
export interface ProductSourcingImages {
  id: number;
  url: string;
}
export interface ProductSourcingStylistPrices {
  id: number;
  request_id: number;
  account_id: number;
  quote_price: number;
  product_description: string;
  request_status: string;
  created_at: string;
  updated_at: string;
  full_name: string;
  images?: (ProductSourcingImages)[] | null;
}

export interface IStylistList {
  id: string;
  type: string;
  attributes: StylistListAttributes;
}
export interface StylistListAttributes {
  activated: boolean;
  country_code?: null;
  email: string;
  first_name?: null;
  full_phone_number: string;
  last_name?: null;
  full_name: string;
  phone_number?: null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id?: null;
  unique_auth_id: string;
  driver_status: string;
  seller_status: string;
  approve_status: string;
  language: string;
  currency: string;
  bio?: null;
  profile_picture?: null;
  is_favorite: boolean;
}
export interface IReqStylistList {
  id: string;
  type: string;
  attributes: ReqStylistAttributes;
}
export interface ReqStylistAttributes {
  stylist_id: number;
  gender: string;
  colour: string;
  detail: string;
  min_price: number;
  max_price: number;
  status: string;
  buyer_name: string;
  buyer_profile?: null;
  stylist_name: string;
  stylist_profile: string;
  images?: (ReqStylistImages)[] | null;
  created_at: string;
}
export interface ReqStylistImages {
  url: string;
}

// INTERFACES ENDS


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dropdownOpen: boolean;
  loading:boolean;
  loadingStylist:boolean;
  selected: number,
  openStylistPortfolio: boolean;
  openStylistProfile: boolean;
  token: string;
  hiredStylistList: IHiredStylist[];
  explorePortfolioList: ImagesEntity[];
  visibleCount: number;
  visibleCountStylist:number;
  productSourcingList: IProductSourcing[];
  stylistList: IStylistList[];
  filteredList: IStylistList[];
  searchTerm: string;
  requestedStylistList: IReqStylistList[];
  stylistProfileData?: IStylistList | null;
  itemProfileId:number | string
  alertType: AlertType,
  already:string,
  isAlertOrder:boolean; 
  page: number;
  stylistId: string;
  isModalOpenImg: boolean,
  imgStylist: string,
  imgDescriptions: string,
  totelRecod:number
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  saveChangesAPICallID: string = "";
  fetchHiredStylistDataApi: string = "";
  fetchPortfolioDataApi: string = "";
  fetchProductSourcingApi: string = "";
  fetchDeleteProductSourceApi: string = "";
  fetchStylistProfileApi: string = "";
  favStylistProfileApi: string = "";
  unfavStylistProfileApi: string = "";
  getRequestedStylistApi: string = "";
  deleteRequestedStylistApi: string = "";
  getRequestedStylistProfileApi:string = "";
  fetchStylistProfileApiTotelData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      alertType: 'success' as AlertType,
      isAlertOrder:false,
      already:"",
      loading:true,
      loadingStylist:true,
      dropdownOpen: false,
      itemProfileId:"",
      stylistProfileData: {
        id: "",
        type: "",
        attributes: {
          activated: false,
          country_code: null,
          email: "",
          first_name: null,
          full_phone_number: "",
          last_name: null,
          full_name: "",
          phone_number: null,
          type: "",
          created_at: "",
          updated_at: "",
          device_id: null,
          unique_auth_id: "",
          driver_status: "",
          seller_status: "",
          approve_status: "",
          language: "",
          currency: "",
          bio: null,
          profile_picture: null,
          is_favorite: false,
        }
      },
      selected: 0,
      openStylistPortfolio: false,
      openStylistProfile: false,
      token: "",
      hiredStylistList: [], 
      explorePortfolioList: [], 
      visibleCount: 7,
      visibleCountStylist: 12,
      productSourcingList: [], 
      stylistList: [], 
      filteredList: [], 
      searchTerm: "", 
      requestedStylistList: [], 
      page: 1,
      stylistId: "", 
      isModalOpenImg: false,
      imgStylist: "", 
      imgDescriptions: "",
      totelRecod:0
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const userToken = await getStorageData('auth-token');
    this.setState({
      token: userToken,
    });
    this.getHiredStylistList()
    this.getPortfolioList()
    this.getStylistProfileAllData()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const navigationParams = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const tabValue = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState((prevState) => ({
        ...prevState,
        selected: tabValue.stylistTab,
      }));
    }
    if(typeof navigationParams?.tabIndex === configJSON.numberMsg){
      this.setState({selected:navigationParams.tabIndex},()=>this.getRequestedStylist())
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (responseJson && !responseJson.errors) {
          switch (apiRequestCallId) {
            case this.fetchHiredStylistDataApi:
              this.setState({ hiredStylistList: responseJson.data });
              break;
        
            case this.fetchPortfolioDataApi:
              this.setState({loading:false,
                    explorePortfolioList: responseJson.data
                      .filter((media: IExplorePortfolioList) =>
                        media?.attributes?.images?.some((image: ImagesEntity) =>
                          !image.url.endsWith(".mp4") && !image.url.endsWith(".svg")
                        )
                      ).map((portfolio: IExplorePortfolioList) => portfolio?.attributes?.images).flat()
                  })
              break;
        
            case this.fetchProductSourcingApi:
              this.setState({ productSourcingList: responseJson.data })
              break;
        
            case this.fetchDeleteProductSourceApi:
              this.getProductSourcing();
              break;
        
            case this.fetchStylistProfileApi:
              this.handleFetchStylistProfileApi(responseJson)
              break;
        
            case this.getRequestedStylistApi:
              const updatedStylistList = responseJson.data.map((item: any) => {
                return {...item, attributes: {...item.attributes,
                    status: this.handleStatus(item.attributes.status)
                  }
                };
              });
              this.setState({ requestedStylistList: updatedStylistList,loading:false });
              break;
        
            case this.deleteRequestedStylistApi:
              this.setState({isAlertOrder: true, alertType: 'success', already: responseJson.message},()=>this.getRequestedStylist())
              break;
            case this.getRequestedStylistProfileApi:
              const selectedStylist = this.state.filteredList.find(
                (stylist: { id: string | number }) => stylist.id === this.state.itemProfileId
            );
              this.setState({ openStylistProfile: true, openStylistPortfolio: false ,stylistProfileData:selectedStylist});
              break;
            case this.favStylistProfileApi:
              this.setState({ isAlertOrder: true, alertType: 'success', already: "Stylist added to favourites" })
              break;
            case this.unfavStylistProfileApi:
              this.setState({ isAlertOrder: true, alertType: 'success', already: "Stylist removed from favourites" })
              break;
            case this.fetchStylistProfileApiTotelData:
              this.setState({ totelRecod:responseJson.meta.total_record },()=>this.getStylistProfile())
              break;
            default:
              break;
          }
        }     
      }
    // Customizable Area End
  }
  // Customizable Area Start
  handleStatus = (status: string) =>{
    switch(status){
      case "accepted":
        return "Accepted"
      case "rejected":
        return "Rejected"
      default:
        return "Requested"
    }
  }

  handleFetchStylistProfileApi(responseJson: any) {
    const dataBox = document.getElementById('scrollableDiv') as HTMLElement;
    dataBox?.addEventListener('scroll', this.handleScroll);
    
    if (responseJson.meta.next_page >= this.state.page) {
      this.setState(prev => ({
        stylistList: responseJson.data,
        filteredList: responseJson.data,
        page: this.state.page + 1,
        loadingStylist:false
      }));
    } else {
      this.setState({
        stylistList: responseJson.data,
        filteredList: responseJson.data,
        loadingStylist:false
      });
    }
  }
  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    this.setState({ loading: true, searchTerm: searchValue });
    setTimeout(() => {
      const filtered = this.state.stylistList.filter((item: IStylistList) =>
        item.attributes.full_name?.toLowerCase().includes(searchValue)
      );
      this.setState({ filteredList: filtered, loading: false });
    }, 300);
  };

  toggleFavoriteHiredStylist = (id: string, eventH: React.MouseEvent<HTMLElement>) => {
    eventH?.stopPropagation();
    const updatedHiredList = this.state.hiredStylistList.map((item: IHiredStylist) => {
      if (item.id === id) {
        if (item.attributes.is_favorite) {
          this.unfavStylistProfile(id); 
        } else {
          this.favStylistProfileClick(id);
        }
        return {...item, attributes: { ...item.attributes, is_favorite: !item.attributes.is_favorite },};
      }
      return item;
    });
  
    this.setState({ hiredStylistList: updatedHiredList });
  }

  toggleFavorite = (id: string,event:any) => {
    event?.stopPropagation()
    const updatedList = this.state.filteredList.map((item: IStylistList) => {
      if (item.id === id) {
        if (item.attributes.is_favorite) {
          this.unfavStylistProfile(id); 
        } else {
          this.favStylistProfileClick(id);
        }
        return {...item, attributes: { ...item.attributes, is_favorite: !item.attributes.is_favorite },};
      }
      return item;
    });
  
    this.setState({ filteredList: updatedList });
  };

  headerNavigation = async (pageLink: string, name?: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  navigateTo = ({ productId, props, screenName }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), `editReq${productId}`);
    runEngine.sendMessage(message.id, message);
  }

  handleProductView = (component: string, id: string, idname: string = "id") => {
    this.props.navigation.navigate(component, {[idname]: id} )
  }

  handleBackButton = () => {
    this.setState({ openStylistProfile: false, openStylistPortfolio: false})
  }

  handleViewMoreStylist = () => {
    const { visibleCountStylist } = this.state;
    const newVisibleCount = visibleCountStylist + 12;
    this.setState({
      visibleCountStylist: newVisibleCount,
    });
  };
  handleViewMore = () => {
    const { visibleCount } = this.state;
    const newVisibleCount = visibleCount + 24;
    this.setState({
      visibleCount: newVisibleCount,
    });
  };
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if(newValue === 1) {
      this.getProductSourcing()
    } else if(newValue === 2){
      this.getStylistProfile()
    } else if(newValue === 3){
      this.getRequestedStylist()
    } 
    this.setState({ selected: newValue })
  }

  openStylistPortfolio = (id: string) => {
    this.setState({ openStylistPortfolio: true, stylistId: id })
  }

  closeStylistPortfolio = () => {
    this.setState({ openStylistPortfolio: false })
  }

  hireStylistImg = (imgId: string, descriptions: string) => {
    this.setState({ isModalOpenImg: true, imgStylist: imgId, imgDescriptions: descriptions })
  }

  openStylistProfilePage = () => {
    this.setState({ openStylistProfile: true, openStylistPortfolio: false})
  }

  getHiredStylistList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchHiredStylistDataApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/hire_stylists/list_hired_stylist`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getPortfolioList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchPortfolioDataApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/accounts/portfolio`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getProductSourcing = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchProductSourcingApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_catalogue/product_sourcing_requests`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleScroll = async () => {
    const dataBox = await document.getElementById('scrollableDiv') as HTMLElement;
    if(dataBox.scrollHeight - dataBox.scrollTop === dataBox.clientHeight){
        // this.getStylistProfile();
    };
};

  favStylistProfileClick = async (id:string) => {
    const headers = { 
      "token": this.state.token, 
      "Content-Type":"application/json" 
    };
    const httpBody = {  id: Number(id) };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.favStylistProfileApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/buyer_favourites`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getStylistProfile = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchStylistProfileApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/accounts/list_stylist?page=${this.state.page}&per_page=${this.state.totelRecod}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getStylistProfileAllData = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchStylistProfileApiTotelData = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/accounts/list_stylist`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  unfavStylistProfile = async (id:string) => {
    const headers = { 
      "token": this.state.token, 
      "Content-Type":"application/json" 
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.unfavStylistProfileApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/buyer_favourites/${id}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteHiredStylist = async (id: number) => {
    const headers = { 
      "token": this.state.token, 
      "Content-Type":"application/json" 
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteRequestedStylistApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_custom_form/hire_stylist_custom_forms/${id}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getRequestedStylist = async () => {
    const headers = { 
      "token": this.state.token, 
      "Content-Type":"application/json" 
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRequestedStylistApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_custom_form/hire_stylist_custom_forms`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getRequestedStylistProfile = async (itemProfile:string | number) => {
    this.setState({itemProfileId:itemProfile})
    const headers = { 
      "token": this.state.token, 
      "Content-Type":"application/json" 
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRequestedStylistProfileApi = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts/list_stylist`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteProductSource = (id: string) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchDeleteProductSourceApi = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_catalogue/product_sourcing_requests/${id}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  hiredStylistProfile = ({
    productId,
    props,
    screenName,
  }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
  }
 
  oncloseAlertInProcess = () => {
    this.setState({ isAlertOrder: false });
};

tranHiredStylist = (transKey: string) => {
  return i18n.t(transKey, {ns: "hiredStylist"});
}

hireStylistImgClose = () => {
  this.setState({ isModalOpenImg: false })
}


  // Customizable Area End
}