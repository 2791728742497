Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log In";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.welcomeBack = "Welcome Back";
exports.loginStyle = "Login and see our latest fashion style";
exports.emailAddress = "Email Address";
exports.forgotPassword = "Forgot your Password?";
exports.continueWith = "or continue with";
exports.msgAlert = "Don't have an account or want to become a seller, or stylist?";
exports.registerHere = "Register Here";
exports.validationApiMethodType = "GET";
exports.fullName = "Full Name";
exports.emailAddress="Email Address";
exports.reenterPassword="Re-enter Password";
exports.phone="Phone";
exports.createAccount="Create Account";
exports.byClick="By clicking create account, you agree to our";
exports.termsCondition="Terms & Conditions";
exports.privacyStatement="Privacy Statement.";
exports.loginHere="Login Here";
exports.aleadyAccount="Aleady have an account?";
exports.and=" and ";
exports.phoneNumber="Phone Number";
exports.sendOTP="Send OTP";
exports.emailPlace="Enter your email";
exports.passPlace="Enter your password";
exports.accountNamePlace="Enter Holder Account Name";
exports.accountNumberPlace="Enter Account Number"
exports.phonePlace="Enter phone Number";
exports.emailValidationMsg="*Please enter your email address";
exports.validationPassMsg="*Please enter your password";
exports.min8digitValidationMsg="*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric";
exports.emailValidMsg="*Please enter a valid email address";
exports.confirmPass = "*Password and confirm password don't match";
exports.phoneValidationMsg="*Please enter your phone number";
exports.phoneNumericValues = "*Phone number must contain only numeric values";
exports.alphnewmertic="*Please enter a valid phone number";
exports.passNotmatch="* Please re-enter your password";
exports.mobileRegex=/^\d{10}$/;
exports.neightDigRegex=/^\+?\d{7,}$/;
exports.emailRegex=/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
exports.passRegx=/^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[A-Za-z\d!@#$%^&*]{8,}$/;
exports.loginEndPoint="login/login"
exports.countryCodeEndPoint = "accounts/country_code_and_flags";
exports.countryApiContentType = "application/json";
exports.httpMethodType = "GET";
exports.phoneRegex=/^\d{10}$/;
exports.enterOTP="* Enter 4 digit OTP";
exports.otpVerification="OTP Verification";
exports.sentTo="Please enter the 4 digit code sent to";
exports.emailText = "your email id ";
exports.smsText = "your mobile number id ";
exports.reciveCodeText="Didn't Received Code?";
exports.resendOtpTimerText="Resend OTP In";
exports.verifyAccount="Verify Account";
exports.resendCode="Resend Code";
exports.resentOTP = "Resent OTP Successfully";
exports.failed = "Failed";
exports.emailType = "email_otp";
exports.smsType = "sms_otp";
exports.socialType = "social_otp";
exports.emailToken = "email-token";
exports.smsToken = "sms-token";
exports.postMobileRegistrationEndPoint = "login/login";
exports.postRegistrationContentType = "application/json";
exports.postApimethod = "POST";
exports.mobileMsg="mobile";
exports.LandingPageMsg="LandingPage";

exports.ForgotPasswordMsg="ForgotPassword";
exports.MobileAccountLoginBlockMsg="MobileAccountLoginBlock";
exports.EmailAccountRegistrationMsg="EmailAccountRegistration";
exports.email_accountMsg="email_account";
exports.account_not_foundMsg="Account not found";
exports.errorMsg="error";
exports.Coming_SoonMsg="Coming Soon...";
exports.sellerMsg="seller";
exports.StylishMsg="stylist";
exports.buyerMsg="buyer";
exports.Login_SuccessfullyMsg="Login Successfully";
exports.register_SuccessfullyMsg="Registered Successfully";
exports.SuccessMsg="success";
exports.InternalErrorMsg="Internal Server Error";
exports.sms_accountMsg="sms_account";
exports.EmailAccountLoginBlockMsg="EmailAccountLoginBlock";
exports.EmailOTPVarificationMsg="EmailOTPVarification";
exports.Message_RecivedMsg="Message Recived";

exports.full_name="Full Name ";
exports.socailmediaAccount="Social Media Account";
exports.ibanNumber="IBAN Number";
exports.bankAccountName="Bank Account Holder Name";
exports.bankAccountNumber="Account Number";
exports.AccountActivation="Account Activation";
exports.fullnamePlace="Enter Full Name";
exports.socailmediaAccountPlace="Enter Social Media Account";
exports.bankAccountNumberPlace="Enter Bank Account Name";
exports.ibanplace="Enter IBAN number";
exports.AddPhoneNumber= "Add Phone Number";

exports.postEmailVarificationEndPoint = "account_block/accounts/verify_account_otp";
exports.postEmailVarificationContentType = "application/json";
exports.AddPhoneNumberEndPoint = "account_block/accounts/add_phone_number";

exports.postResendOTPEndPoint = "account_block/accounts/resend_account_otp";
exports.postResendOTPContentType = "application/json";
exports.postPhoneLoginVarificationEndPoint = "accounts/verify_otps";
exports.postPhoneVarificationEndPoint = "account_block/accounts/verify_account_otp";
exports.createProfile = "Create Profile"
exports.createProfileApi = "bx_block_custom_form/stylist_custom_accounts"
exports.uplodeProfileApi = "accounts/portfolio"
exports.customProfileGet = "bx_block_custom_form/stylist_custom_accounts"
exports.customProfilePut = "bx_block_custom_form/stylist_custom_accounts"
exports.customProfilePutApi = "PUT"
exports.uploadDocumentsPost = "bx_block_custom_form/stylist_custom_accounts/upload_documents"
exports.uploadDocumentsApi = "PATCH"
exports.addNumber = /\d/g
exports. typeOptions = [
  { value: "0", label: "buyer" },
  { value: "1", label: "seller" },
  { value: "3", label: "stylist" }
];
exports.googleLoginApiEndPoint="bx_block_login/logins";
exports.httpPostMethod = "POST"
exports.validPhoneNumEndPoint = "/accounts/validate_seller_contact";
exports.validIBANEndPoint = "/accounts/validate_iban";
// Customizable Area End