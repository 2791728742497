export const enOrderManagement = {
  "Welcome to your Account": "Welcome to your Account",
  "Home": "Home",
  "My Account": "My Account",
  "My Order": "My Order",
  "My Orders": "My Orders",
  "Processing": "Processing",
  "Delivered": "Delivered",
  "Returns": "Returns",
  "All Orders": "All Orders",
  "Search for a product": "Search for a product",
  "From": "From",
  "To": "To",
  "Sort by": "Sort by",
  "Most Recent": "Most Recent",
  "Most": "Most",
  "Recent": "Recent",
  "Customer Name":"Customer Name",
  "Address":"Address",
  "Order Status":"Order Status",
  "Order ID":"Order ID",
  "Order Date":"Order Date",
  "Colour":"Colour",
  "Size":"Size",
  "Qty":"Qty",
  "Total":"Total",
  "Product":"Product",
  "Return Status": "Return Status",
  "Return Order": "Return Order",
  "Mode of Return": "Mode of Return",
  "Shipping Address" : "Shipping Address",
  "DD-MM-YY": "DD-MM-YY",
  "Payment Method" : "Payment Method",
  "Debit Card ending" : "Debit Card ending",
  "Store Name": "Store Name",
  "Delivery Partner": "Delivery Partner",
  "No": "No",
  "Yes": "Yes",
  "Order Cancel Popup Text": "Are you sure you want to cancel this order?",
  "Cancel Order": "Cancel Order",
  "Self-Drop Off":"Self-Drop Off",
  "Drop off the product at store by yourself":"Drop off the product at store by yourself",
  "Request a Delivery Partner":"Request a Delivery Partner",
  "Schedule a delivery partner to pick up the product. Delivery charges will be applicable.":"Schedule a delivery partner to pick up the product. Delivery charges will be applicable.",
  "Note - Pickup charges will be deducted from your refund":"Note - Pickup charges will be deducted from your refund",
  "Estimated Pickup Charges":"Estimated Pickup Charges",
  "Total Charges":"Total Charges",
  "Submit":"Submit",
  "Self-Drop Off Confirmed":"Self-Drop Off Confirmed",
  "You must return the items within 24 hours.":"You must return the items within 24 hours.",
  "Cancel Return":"Cancel Return",
  "Driver Requested":"Driver Requested",
  "A delivery partner will be assigned soon":"A delivery partner will be assigned soon",
  "Track Return":"Track Return"
};

export const arOrderManagement = {
  "Track Return":"تتبع العودة",
  "A delivery partner will be assigned soon":"سيتم تعيين شريك التسليم قريبا",
  "Driver Requested":"طلب سائق",
  "Cancel Return":"إلغاء العودة",
  "You must return the items within 24 hours.":"يجب عليك إرجاع العناصر خلال 24 ساعة.",
  "Self-Drop Off Confirmed":"تم تأكيد الإنزال الذاتي",
  "Submit":"يُقدِّم",
  "Total Charges":"إجمالي الرسوم",
  "Estimated Pickup Charges":"رسوم الاستلام المقدرة",
  "Note - Pickup charges will be deducted from your refund":"ملاحظة - سيتم خصم رسوم الاستلام من المبلغ المسترد",
  "Schedule a delivery partner to pick up the product. Delivery charges will be applicable.":"حدد موعدًا مع شريك التوصيل لاستلام المنتج. سيتم تطبيق رسوم التسليم.",
  "Request a Delivery Partner":"اطلب شريك التوصيل",
  "Drop off the product at store by yourself":"قم بإسقاط المنتج في المتجر بنفسك",
  "Self-Drop Off":"الإنزال الذاتي",
  "Welcome to your Account": "مرحبا بك في حسابك",
  "Home": "الصفحة الرئيسية",
  "My Account": "حسابي",
  "My Order": "طلباتي",
  "My Orders": "طلباتي",
  "Processing": "جاري المعالجة",
  "Delivered": "تم التوصيل",
  "Returns": "المردودات",
  "All Orders": "جميع الطلبات",
  "Search for a product": "البحث عن منتج",
  "From": "منتجات",
  "To": "الى",
  "Sort by": "الترتيب حسب",
  "Most Recent": "الأحدث",
  "Most": "معظم",
  "Recent": "مؤخرًا",
  "Customer Name":"اسم العميل",
  "Address":"العنوان",
  "Order Status":"حالة الطلب",
  "Order ID":"معرف الطلب",
  "Order Date":"تاريخ الطلب",
  "Colour":"اللـون",
  "Size":"الحجم",
  "Qty":"الكمية",
  "Total":"الإجمالي",
  "Product":"منتجات",
  "Products": "منتجان",
  "Return Status": "حالة الإرجاع",
  "Return Order": "حالة الطلب",
  "Mode of Return": "طريقة العودة",
  "Shipping Address": "عنوان الشحن",
  "DD-MM-YY": "اليوم:الشهر:السنة",
  "Payment Method" : "طريقة الدفع",
  "Debit Card ending" : "بطاقة الخصم التي نهايتها",
  "Store Name": "اسم المتجر",
  "Delivery Partner": "شريك خدمات التوصيل",
  "No": "لا",
  "Yes": "نعم",
  "Order Cancel Popup Text": "هل أنت متأكد أنك تريد إلغاء هذا الطلب؟",
  "Cancel Order": "إلغاء الطلب",
};
