export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const store = require("../assets/store.svg");
export const step1 = require("../assets/create-store-step-1.svg");
export const step2 = require("../assets/create-store-step-2.svg");
export const step3 = require("../assets/create-store-step-3.svg");
export const step4 = require("../assets/create-store-step-4.svg");
export const backBtnIcon = require("../assets/arrow-back.svg");
export const uploadIcon = require("../assets/upload-icon.svg");
export const editUploadIcon = require("../assets/edit-icon-btn.svg");
export const endLocationIcon = require("../assets/location-end-icon.svg");
export const selectEndIcon = require("../assets/select-end-icon.svg");
export const removeIcon = require("../assets/remove-icon.svg");
export const storeSuccessIcon = require("../assets/success_checked_icon.svg");
export const addIcon = require("../assets/add-icon.svg");
export const crossIcon = require("../assets/cross-icon.svg");
export const editFillUploadIcon = require("../assets/ic-fill-edit-btn.svg");
export const rightArrowIcon = require("../assets/right-arrow-icon.svg");
export const downloadIcon = require("../assets/down-load-icon.svg");
export const filterIcon = require("../assets/filter-icon.svg");
export const product1 = require("../assets/product-1.svg");
export const product2 = require("../assets/product-2.svg");
export const product3 = require("../assets/product-3.svg");
export const product4 = require("../assets/product-4.svg");
export const menCataloguesImg = require("../assets/men_catalogues_img.svg");
export const womenCataloguesImg = require("../assets/women_catalogues_img.svg");
export const kidsCataloguesImg = require("../assets/kids_catalogues_img.svg");
export const inventoryRightIcon = require("../assets/inventory_right_icon.svg");
export const inventoryOutOfStockIcon = require("../assets/out_of_stock_icon.svg");
export const inventoryTotalProductIcon = require("../assets/total_product_icon.svg");
export const inventoryUnlistedIcon = require("../assets/unlisted_icon.svg");
export const inventoryLowOnStockIcon = require("../assets/low_stock_icon.svg");
export const store1Icon = require("../assets/store1.svg");
export const store2Icon = require("../assets/store2.svg");
export const store3Icon = require("../assets/store3.svg");
export const store4Icon = require("../assets/store4.svg");
export const store5Icon = require("../assets/store5.svg");
export const editFillIcon = require("../assets/edit-fill-icon.svg");
export const csvFileIcon = require("../assets/csv-file-icon.svg");
export const rejectIcon = require("../assets/reject-icon.svg");
export const request1 = require("../assets/request-image-1.svg");
export const request2 = require("../assets/request-image-2.svg");
export const request3 = require("../assets/request-image-3.svg");
export const wishlistProduct1 = require("../assets/wishlist-product-1.svg");
export const wishlistProduct2 = require("../assets/wishlist-product-2.svg");
export const wishlistProduct3 = require("../assets/wishlist-product-3.svg");
export const wishlistProduct4 = require("../assets/wishlist-product-4.svg");
export const wishlistProduct5 = require("../assets/wishlist-product-5.svg");
export const wishlistProduct6 = require("../assets/wishlist-product-6.svg");
export const image_background = require("../assets/image_background.jpg")