import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  Checkbox
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { plus, threeDot } from "../../addressmanagement/src/assets";
import storage from '../../../framework/src/StorageProvider';
import CheckoutSteps from "../../productdescription3/src/CheckoutSteps.web";
import {Noimageavailable} from "../../productdescription3/src/assets"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

import Shippingaddressvalidation2Controller, {
  Props,
  configJSON,
} from "./Shippingaddressvalidation2Controller";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";

export default class Shippingaddressvalidation2 extends Shippingaddressvalidation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDropdown = (addressID: {
    id: string,
    attributes: { is_default: boolean }
  }) => {
    if (this.state.openDropdownId === addressID.id) {
      storage.set('addressID', addressID.id)
      return (
        <div style={webStyle.dropdown as React.CSSProperties}>
          {

            !addressID.attributes.is_default && <p style={webStyle.dropdownP as React.CSSProperties} data-test-id="handleMakeDefault" onClick={() => this.handleMakeDefault(addressID)}>{this.transShippingAddress("Make Default")}</p>
          }
          <p style={addressID.attributes.is_default ? webStyle.dropdownDefault as React.CSSProperties : webStyle.dropdownP as React.CSSProperties} data-test-id="getNavigationEditAddress" onClick={() => this.getNavigationEditAddress()}>{this.transShippingAddress("Edit Address")}</p>
          {
            !addressID.attributes.is_default &&
            <p style={webStyle.dropdownP1 as React.CSSProperties} data-test-id="handleOpenDeleteDialog" onClick={() => this.handleOpenDeleteDialog(addressID.id)}>{this.transShippingAddress("Delete")}</p>
          }
        </div>
      );
    }
  };

 
  renderOrderSummary() {
    const { orderSummary } = this.state;
    if (!orderSummary) return null;

    const { order_items, sub_total, total } = orderSummary.data.attributes;

    return (
      <div>
        {order_items.map((item) => (
          <div>
          <div key={item.id} style={{ ...webStyle.shippingOrder, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img src={item.attributes.catalogue_variant_front_image === "" ? Noimageavailable : item.attributes.catalogue_variant_front_image} 
            style={{  height: '63px',width: '63px', borderRadius: '4px' }} />
            <div style={{ ...webStyle.orderDetail, flex: '1', marginLeft: '10px' }}>
              <p>{item.attributes.catalogue_name} <span style={{ color: '#94A3B8' }}>x {item.attributes.quantity}</span></p>
              <p style={{ marginTop: '-18px' }}>{this.transShippingCart("Color")} : <span style={{ color: '#94A3B8' }}>{item.attributes.catalogue_variant_color}</span></p>
            </div>
            <p style={{ color: '#94A3B8',fontSize: '14px', marginLeft: '10px',  }}>{this.state.currencySign} {item.attributes.total_price}</p>
          </div>
          <hr style={{ color: 'rgb(242, 240, 240)' }} />
          </div>
        ))}
        <div style={{ ...webStyle.shippingOrder }}>
          <div style={{ ...webStyle.orderDetail, flex: '1',fontSize: '18px', marginLeft: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>{this.transShippingCart("Subtotal")} <span style={{ color: '#94A3B8' }}>({order_items.length} {this.transShippingCart("items")})</span></p>
              <p style={{ fontSize: '18px' }}>{this.state.currencySign} {parseFloat(sub_total).toFixed(2)}</p>
            </div>
            <div style={{ justifyContent: 'space-between', display: 'flex', fontSize: '18px' }}>
              <p style={{ marginTop: '-5px' }}>{this.transShippingCart("Savings")}</p>
              <p style={{ marginTop: '-5px' }}>- {this.state.currencySign} 0.00</p>
            </div>
          </div>
        </div>
        <hr style={{ color: 'rgb(242, 240, 240)' }} />
        <div style={{ ...webStyle.shippingOrder }}>
          <div style={{ ...webStyle.orderDetail, flex: '1', margin: i18n.dir()==='ltr'?'0px 0px 0px 10px': '0px 10px 0px 0px', fontSize: '18px' }}>
            <div style={{  justifyContent: 'space-between', fontSize: '18px',display: 'flex', }}>
              <p>{this.transShippingCart("Total")}</p>
              <p>{this.state.currencySign} {parseFloat(total).toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div style={{ ...webStyle.button12, ...webStyle.saveButton }} data-test-id="placeOrderNavigation" onClick={() => this.handlePlaceOrder()}>{this.transShippingCart("Place Order")}</div>
      </div>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
      {this.state.stylishBuyer ? <StylishBuyerHeader navigation={this.props.navigation} />: <Header navigation={this.props.navigation} />}
        <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  
                  data-test-id="alertTestId"
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
        <Box style={{ backgroundColor: "rgb(248, 248, 248)", color: "#375280", fontFamily: "Lato" ,padding:"5px"}}>
          <CheckoutSteps activeBread="address" transStepLang={this.transShippingCart} />

        </Box>
        <div style={{ display: "flex", padding: "40px", backgroundColor: "#F8F8F8", }}>
          <Grid style={{ marginBottom: "58px" }} container dir={i18n.dir()}>
            <Grid item xs={12} md={8} style={{ paddingInline: 20 }}>
            
              <div style={webStyle.addAddressContainer as React.CSSProperties} >
                <div  onClick={this.getNavigationMessage} data-test-id="getNavigationMessage" style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: '4px', color: "#375280" }}>
                  <img src={plus} style={{ cursor: "pointer" }} />
                  {this.transShippingAddress("Add Address")}
                </div>
              </div>
              <div style={{ backgroundColor: '#F8F8F8' }} >
                <div >
                  {this.state.addressData && this.state.addressData.map((address) => (
                    <div key={address.id} style={webStyle.addAddressContainer}>
                      <div style={{
                        fontSize: "16px", fontWeight: 700, lineHeight: "26px", color: "#94A3B8",
                        display: 'flex', alignItems: "center", gap: '17px', justifyContent: "space-between", fontFamily: "Lato , sans-serif",
                      }}>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                          <p style={{
                            backgroundColor: '#F6F6F6',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: "5px 10px"
                          }}>{this.transShippingAddress(address.attributes.address_name)}</p>
                          <p style={{
                            backgroundColor: 'white',
                            width: '94px',
                            height: '36px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>{address.attributes.is_default ? this.transShippingAddress("Default") : null}</p>
                        </div>
                        <div style={{display:"flex",alignItems:"center"}}>
                        <GreenCheckbox
                        checked={this.state.selectedAddressId === address.id}
                        onChange={() => this.handleCheckboxChange(address.id)}
                        data-test-id="checkBox"
                      />
                        <div style={{ position: 'relative', cursor: "pointer" }}>
                          <img src={threeDot}data-test-id='clickbyyyy' onClick={() => this.toggleDropdown1(address.id)} />
                          {this.renderDropdown(address)}
                        </div>
                        </div>
                      </div>

                      <div style={{ display: "flex", alignItems: "center", gap: "17px", fontSize: "16px", lineHeight: "26px", fontWeight: 700, color: "#375280" }}>
                        <p>{address.attributes.name}</p>
                        <div style={{display:"flex",gap:"5px"}}>
                        <p>{address.attributes.country_code}</p>
                        <p>{address.attributes.phone_number}</p>
                        </div>
                      </div>
                      <div style={{ fontSize: "14px", fontWeight: 400, color: "#94A3B8" }}>
                        {address.attributes.street}, {address.attributes.block}, {address.attributes.area},<br />
                        {address.attributes.city} - {address.attributes.zipcode}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            
            </Grid>
            <Grid item xs={12} md={4} style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }}>

              <div style={{ margin: 'auto', paddingInline: 20 }}>
                <div>

                  <div style={webStyle.addAddressContainer1 as React.CSSProperties} >
                    <div style={{
                      color: "#375280",
                      gap: '4px',
                      alignItems: "center", 
                      display: "flex",
                      cursor: "pointer",
                      marginBottom: "14px",
                            }}>
                      {this.transShippingCart("Order Summary")}
                    </div>
                    <hr style={{ color: 'rgb(242, 240, 240)' }} />
                   {this.renderOrderSummary()}
                    
                  </div>

                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer navigation={this.props.navigation} />
        <DiologcontentStyledMain
          open={this.state.isDeleteDialogOpen}
          data-test-id="handleCloseDeleteDialog"
          onClose={this.handleCloseDeleteDialog}
        >
          <DiologcontentStyled>
            <Typography style={{ width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" }}>
              {this.transShippingAddress('Are you sure you want to delete this address?')}
            </Typography>
          </DiologcontentStyled>
          <DialogActions style={{display:'flex', gap:'10px', flexDirection: i18n.dir()==="ltr" ? 'row': "row-reverse", marginBottom:'10px'}}>
            <Button onClick={this.handleCloseDeleteDialog} style={{
              alignItems: "center",
              fontSize: "15px",
              justifyContent: "center",
              fontWeight: 500,
              display: "flex",
              color: "#375280",
              backgroundColor: "white",
              border: "1px solid #CCBEB1",
              height: "46px",
              borderRadius: "5px",
              width: "50%",
              padding: "10px 16px 10px 16px",
              cursor: "pointer"
            }}>
              {this.transShippingAddress('No')}
            </Button>
            <Button onClick={this.handleConfirmDelete} data-test-id="handleConfirmDelete" style={{
              alignItems: "center",
              fontSize: "15px",
              justifyContent: "center",
              fontWeight: 500,
              display: "flex",
              color: "white",
              backgroundColor: "#CCBEB1",
              border: "1px solid #CCBEB1",
              height: "46px",
              borderRadius: "5px",
              width: "50%",
              padding: "10px 16px 10px 16px",
              cursor: "pointer"
            }} >
              {this.transShippingAddress('Yes')}
            </Button>
          </DialogActions>
        </DiologcontentStyledMain>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const GreenCheckbox = styled(Checkbox)({
  color: "#CCBEB1",
  '&.Mui-checked': {
    color: "#CCBEB1",
  },
 
  '&.Mui-checked + .MuiIconButton-label': {
    backgroundColor: '#CCBEB1',
  }
});
const DiologcontentStyled = styled(DialogContent)({
  '&.MuiDialogContent-root:first-child': {
    paddingTop: '30px',
    textAlign: 'center',
  },

  // MuiDialog-paperWidthSm
})
const DiologcontentStyledMain = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '450px'
  },
  '& .MuiPaper-rounded':{
    borderRadius:'12px',

  }
  // MuiDialog-paperWidthSm
})
const webStyle = {
  addAddressContainer: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '26px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },
  orderDetail: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 600,
    fontSize: "14px",
  },
  shippingOrder: { marginBottom: "14px", cursor: "pointer", display: "flex", alignItems: "center", gap: '4px', color: "#375280" },
  addAddressContainer1: {
    padding: "16px 12px 16px 16px",
    fontWeight: 700,
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '28.8px',
    backgroundColor: "#FFFFFF",
    fontFamily: 'Lato , sans-serif',
    fontSize: '24px',
  },
  dropdown: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    backgroundColor: 'white',
    width: '120px',
    boxShadow: '0px 2px 8px 0px #00000014',
    top: '100%',
    borderRadius: '4px',
  },
  dropdownP: {
    borderBottom: '1px solid #D5D5D5',
    color: "#375280",
    paddingBottom: '10px',
    paddingLeft: '6px',
    paddingRight: '6px',
    lineHeight: "22px",
    fontSize: "12px",
    fontWeight: "600",
  },
  dropdownP1: {
    paddingLeft: '6px',
    lineHeight: "22px",
    paddingRight: '6px',
    fontWeight: "600",
    color: "#DC2626",
    fontSize: "12px",
  },
  button12: {
    backgroundColor: "white",
    display: "flex",
    height: "64px",
    padding: "10px 16px 10px 16px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    color: "#375280",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    cursor:"pointer",
    marginLeft: "10px",
    marginRight: '5px'
  },
  saveButton: {
    color: "white",
    backgroundColor: "#CCBEB1"
  },
  dropdownDefault: {
    fontSize: "12px",
    color: "#375280",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },

};
// Customizable Area End
