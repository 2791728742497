export const enAddress = {
    "Home": "Home",
    "Welcome to your Account": "Welcome to your Account",
    "Address": "Address",
    "Add Address": "Add Address",
    "Make Default": "Make Default",
    "Edit Address": "Edit Address",
    "Delete": "Delete",
    "Are you sure you want to delete this address?": "Are you sure you want to delete this address?",
    "Yes": "Yes",
    "No": "No",
    "Default": "Default",
    "Use my current location":"Use my current location",
    "Full Name":"Full Name",
    "Phone Number":"Phone Number",
    "Enter Contact Number":"Enter Contact Number",
    "Street Name":"Street Name",
    "House / Building Number":"House / Building Number",
    "Block":"Block",
    "Area":"Area",
    "City":"City",
    "Zip Code":"Zip Code",
    "Address Name":"Address Name",
    "Make it default delivery address":"Make it default delivery address",
    "Cancel":"Cancel",
    "Save":"Save",
    "*Please enter a valid full name":"*Please enter a valid full name",
    "*Please enter a valid Area":"*Please enter a valid Area",
    "*Please enter a valid Block":"*Please enter a valid Block",
    "*Please enter a valid Street":"*Please enter a valid Street",
    "*Please enter a valid House number":"*Please enter a valid House number",
    "*Please enter a valid Zipcode":"*Please enter a valid Zipcode",
    "*Please enter a valid City":"*Please enter a valid City",
    "*Please enter a valid Country code":"*Please enter a valid Country code",
    "*Please enter a valid Phone number":"*Please enter a valid Phone number",
    "*Please enter a valid Latitude":"*Please enter a valid Latitude",
    "*Please enter a valid Longitude":"*Please enter a valid Longitude",
    "*Please enter a valid Address name":"*Please enter a valid Address name",
    "*Please enter a valid numeric Zipcode":"*Please enter a valid numeric Zipcode",
    "Address Created Successfully":"Address Created Successfully",
    "Address Updated successfully":"Address Updated successfully",
    "Address deleted successfully":"Address deleted successfully",
    "Please select a different address as a default":"Please select a different address as a default",
    "Please Enter valid data": "Please Enter valid data",
    "*Please enter a valid numeric building number": "*Please enter a valid numeric building number",
    "*Please enter a valid numeric Phone Number": "*Please enter a valid numeric Phone Number",
    "Please select an address.": "Please select an address.",
    "Office": "Office"
}

export const arAddress = {
    "Home": "الصفحة الرئيسية",
    "Welcome to your Account": "مرحبا بك في حسابك",
    "Address": "العنوان",
    "Add Address": "إضافة عنوان",
    "Make Default": "تعيين كافتراضي",
    "Edit Address": "تعديل العنوان",
    "Delete": "الحذف",
    "Are you sure you want to delete this address?": "هل أنت متأكد أنك تريد حذف هذا العنوان؟",
    "No": "لا",
    "Yes": "نعم",
    "Default": "الافتراضي",
    "Use my current location":"استخدام الموقع الحالي",
    "Full Name":"الاسم الكامل",
    "Phone Number":"رقم الهاتف",
    "Enter Contact Number":"أدخل رقم الاتصال",
    "Street Name":"اسم الشارع",
    "House / Building Number":"رقم المنزل / المبنى",
    "Block":"البلوك",
    "Area":"المنطقة",
    "City":"المدينة",
    "Zip Code":"الرمز البريدي",
    "Address Name":"نوع العنوان",
    "Make it default delivery address":"التعيين كعنوان التوصيل الافتراضي",
    "Cancel":"الإلغاء",
    "Save":"حفظ",
    "*Please enter a valid full name":"*الرجاء إدخال اسم كامل صالح",
    "*Please enter a valid Area":"*الرجاء إدخال منطقة صالحة",
    "*Please enter a valid Block":"*الرجاء إدخال كتلة صالحة",
    "*Please enter a valid Street":"*الرجاء إدخال شارع صالح",
    "*Please enter a valid House number":"*الرجاء إدخال رقم منزل صالح",
    "*Please enter a valid Zipcode":"*الرجاء إدخال رمز بريدي صالح",
    "*Please enter a valid City":"*الرجاء إدخال مدينة صالحة",
    "*Please enter a valid Country code":"*الرجاء إدخال رمز البلد الصحيح",
    "*Please enter a valid Phone number":"*الرجاء إدخال رقم هاتف صالح",
    "*Please enter a valid Latitude":"*الرجاء إدخال خط عرض صالح",
    "*Please enter a valid Longitude":"*الرجاء إدخال خط طول صالح",
    "*Please enter a valid Address name":"*الرجاء إدخال اسم عنوان صالح",
    "*Please enter a valid numeric Zipcode":"*الرجاء إدخال رمز بريدي رقمي صالح",
    "Address Created Successfully":"تم إنشاء العنوان بنجاح",
    "Address Updated successfully":"تم تحديث العنوان بنجاح",
    "Address deleted successfully":"تم حذف العنوان بنجاح",
    "Please select a different address as a default":"الرجاء تحديد عنوان مختلف كعنوان افتراضي",
    "Please Enter valid data": "الرجاء إدخال بيانات صحيحة",
    "*Please enter a valid numeric building number": "*الرجاء إدخال رقم مبنى رقمي صحيح",
    "*Please enter a valid numeric Phone Number": "*الرجاء إدخال رقم هاتف رقمي صالح",
    "Please select an address.": "الرجاء تحديد عنوان.",
    "Office": "الرئيسي"
}