Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "loyaltysystem";
exports.labelBodyText = "loyaltysystem Body";

exports.btnExampleTitle = "CLICK ME";

exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.getLoyaltyPointEndpoint = "bx_block_loyaltysystem/loyalty_points";
exports.getTransactionDetailEndpoint = "bx_block_loyaltysystem/point_transactions";
exports.getAllActivePromoCodeEndpoint = "bx_block_promo_codes/promo_codes";
exports.postReedLoyaltyPointEndpoint = "bx_block_promo_codes/promo_codes/redeem_promo_code?promo_code_id=";
// Customizable Area End