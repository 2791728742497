Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.forgotPassword = "Forgot Password";
exports.emailTextAddress = "Enter the email address associated with your account";
exports.or = "OR";
exports.phoneNumber = "Phone Number";
exports.resetPassword = "Reset Password";
exports.phoneValidationMsg = "*Please enter your phone number";
exports.phoneNumericValues = "*Phone number must contain only numeric values";
exports.alphnewmertic = "*Please enter a valid phone number";
exports.emailValidMsg = "*Please enter a valid email address";
exports.emailValidationMsg = "*Please enter your email address";
exports.emailPlaceholder = "Enter your associated email address";
exports.mobileRegex = /^\d{10}$/;
exports.notFound = "Account not found";
exports.placeName = "Enter Phone Number";
exports.neightDigRegex = /^\+?\d{7,}$/;
exports.emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
exports.neightDigRegex = /^\+?\d{7,}$/;
exports.passRegx = /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[A-Za-z\d!@#$%^&*]{8,}$/;
exports.validationPassMsg = "*Please enter your new password";
exports.min8digitValidationMsg = "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric";
exports.reEnterValidationMsg = "*Please enter new your password";
exports.headnewPass = "Enter New Password";
exports.confirPAss = "Confirm New Password";
exports.placePAss = "Enter your new password";
exports.placeConfirmPass = "Enter your new confirm password";
exports.confirmPass = "*Confirm New Password must be same as New Password";

exports.postForgotPasswordEndPoint = "forgot_password/otp";
exports.postForgotPasswordContentType = "application/json";
exports.postApimethod = "POST";

exports.postResetOTPEndPoint="forgot_password/otp_confirmation";
exports.postResendOTPEndPoint = "account_block/accounts/resend_account_otp";
exports.postResendOTPContentType = "application/json";

exports.successMsg = "Password reset successfully!";
exports.failedMsg = "New Password is already in use, Please enter another new password!";
exports.enterOTP="* Enter 4 digit OTP";
exports.otpVerification="OTP Verification";
exports.sentTo="Please enter the 4 digit code sent to";
exports.emailText="your email id ";
exports.smsText="your mobile number id ";
exports.reciveCodeText="Didn't Received Code?";
exports.resendOtpTimerText="Resend OTP In";
exports.verifyAccount="Verify Account";
exports.resendCode="Resend Code";
exports.Message_Recived="Message Recived";
exports.phoneMsg="phone";
exports.ResetOTPPasswordMsg="ResetOTPPassword";

exports.countryCodeEndPoint = "accounts/country_code_and_flags";
exports.countryApiContentType = "application/json";
exports.httpMethodType = "GET";
// Customizable Area End