import React from "react";
// Customizable Area Start
import {
    Box, Divider, TextField, CircularProgress, withStyles, Grid, Typography, Button, Checkbox, CheckboxProps, styled, Fade, Backdrop, Modal
} from "@material-ui/core";
import BuyerReturnOrderDetailsController, {
    Props,
} from "../ReturnOrder/BuyerReturnOrderDetailsController.web";
import { Noimageavailablepng, backArrowImg, scrollImg, vectorImg } from "../assets"
import Header from "../../../../components/src/Header"
import Footer from "../../../../components/src/Footer"
import Sidebar from "../../../../components/src/Sidebar";
import i18n from "../../../../components/src/i18next/i18n";
// Customizable Area End

export default class BuyerReturnOrderDetailsWebPage extends BuyerReturnOrderDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleImgVectorIcon = ()=>(
        <img src={vectorImg} style={{transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
    )

   
    getDirectionalStyles = (): React.CSSProperties  => {
        return {
          margin: i18n.dir() === "ltr" ? "27px 0px 20px 40px" : "27px 40px 20px 0px",
          flexDirection: i18n.dir() === "ltr" ? "row" : "row-reverse",
        };
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        const day = currentDate.getDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[currentDate.getMonth()];
        const year = currentDate.getFullYear();
        const formattedDate = `${day} ${month} ${year}`;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={this.props.navigation} />
                <div
                    style={{...webStyle.innerContainer11, ...this.getDirectionalStyles()}}>
                    <Typography style={webStyle.innerContainer23}>
                        {this.transReturnDetail("Home")}
                    </Typography>
                    {this.handleImgVectorIcon()}
                    <Typography style={webStyle.innerContainer23}>
                        {this.transReturnDetail("My Account")}
                    </Typography>
                    {this.handleImgVectorIcon()}
                    <Typography style={webStyle.innerContainer23}>
                        {this.transReturnDetail("My Order")}
                    </Typography>
                    {this.handleImgVectorIcon()}
                    <Typography style={{...webStyle.innerContainer23, fontWeight: 700}}>
                        {this.transReturnDetail("Return Order")}
                    </Typography>
                </div>
                <Grid style={{ backgroundColor: "#F8F8F8" }} container dir={i18n.dir()}>
                    <Grid item lg={3} md={3}>
                        <div >
                            <Typography style={{...webStyle.innerContainer3, margin: i18n.dir() === "ltr" ? "0px 0px 0px 40px" : "0px 40px 0px 0px"}}>
                                <img alt="profileName" src={scrollImg} />
                                {this.state.profileName.split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')}
                            </Typography>
                        </div>
                        <Typography style={{...webStyle.innerContainer4, margin: i18n.dir() === "ltr" ? "0px 0px 37px 40px" : "0px 40px 37px 0px"}}>
                        {this.transReturnDetail("Welcome to your Account")}
                        </Typography>
                        <Sidebar navigation={this.props.navigation} />
                    </Grid>
                    <Grid item lg={9} md={9}>
                        <Box style={{ display: 'flex' }}>
                            <Grid container>
                                <Grid item lg={12} md={12}>
                                    {this.state.isLoading ?
                                        <Box
                                            data-testid="loader"
                                            style={{ position: "absolute", top: "56%", left: "56%", transform: "translate(-50%, -50%)" }}
                                        >
                                            <CircularProgress style={{ color: '#375280', }} />
                                        </Box>
                                        :
                                        <Box style={{ margin: '10px 60px 60px 60px' }}>
                                            <Box style={webStyle.headerContainer}>
                                            <img src={backArrowImg} onClick={this.contioneShopping} data-testid="contioneShopping" className="back-arrow" style={{...webStyle.backIcon,transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'}} />
                                                <Typography style={webStyle.returnOrder}>{this.transReturnDetail("Return Order")}</Typography>
                                            </Box>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography style={webStyle.orderId}>{this.transReturnDetail("Order ID")} :{this.state.tableAllOrder.attributes.order_number}</Typography>
                                                <CustomCheckbox
                                                    checked={this.state.fixID.every((number) => this.state.checkID.includes(number))}
                                                    onChange={this.handleAllSelectAllClick}
                                                    inputProps={{ 'data-testid': 'setpricechecked' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                                        'aria-label'?: string;
                                                        'data-testid'?: string;
                                                    }}
                                                />
                                            </Box>
                                            <Divider style={{ margin: '21px 0px' }} />
                                            <Box>
                                                <Box>
                                                    {this.state.tableAllOrder.attributes.order_items.map((orderItem, index) => {
                                                        return (
                                                            <>
                                                                <Box key={index}>
                                                                    <Box style={{ display: 'flex' }}>
                                                                        <img width="112px" height="140px" style={{margin: i18n.dir() === "ltr" ? "0px 16px 0px 0px" : "0px 0px 0px 16px" }} src={orderItem.attributes.catalogue_variant_front_image === null ? Noimageavailablepng : orderItem.attributes.catalogue_variant_front_image} />
                                                                        <Box style={{ width: '100%' }}>
                                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                <Typography style={webStyle.storName}>{this.transReturnDetail("Store")} : {orderItem.attributes.store_name}</Typography>
                                                                                <CustomCheckbox
                                                                                    checked={orderItem.attributes.status_humanize == 'Ready to ship' ? false : this.state.checkID.includes(orderItem.id)}
                                                                                    inputProps={{ 'data-testid': 'pricechecked' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                                                                        'aria-label'?: string;
                                                                                        'data-testid'?: string;
                                                                                    }}
                                                                                    onChange={(event) => { this.handleSelectAllClick(event, orderItem.id) }}

                                                                                />
                                                                            </Box>
                                                                            <Typography style={webStyle.catlogeName}>{orderItem.attributes.catalogue_name}</Typography>
                                                                            <Box style={{ display: 'flex' }}>
                                                                                <Typography style={webStyle.sizeColorName}>{this.transReturnDetail("Size")} : {orderItem.attributes.catalogue_variant_size}</Typography>
                                                                                &nbsp;<Typography style={webStyle.sizeColorName}>{this.transReturnDetail("Color")} : {orderItem.attributes.catalogue_variant_color}</Typography>
                                                                            </Box>
                                                                            <Typography style={webStyle.showPrize}>$ {parseFloat(orderItem.attributes.total_price)}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <Typography style={webStyle.timePickup}>{this.transReturnDetail("Pickup Time")} : {formattedDate}</Typography>
                                                                    <Divider style={{ margin: '21px 0px' }} />
                                                                </Box>

                                                            </>
                                                        )
                                                    })}
                                                </Box>
                                                <Box style={{ marginTop: '40px' }}>
                                                    <Box style={webStyle.boxSadow}>
                                                        <Box style={{ padding: '24px' }}>
                                                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography style={webStyle.totalPickup}>{this.transReturnDetail("Total Refund")}</Typography>
                                                                <Typography style={webStyle.totalMrp}>{this.totalValue()}</Typography>
                                                            </Box>
                                                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography style={webStyle.totalPickup}>{this.transReturnDetail("Pickup Charge")}</Typography>
                                                                <Typography style={webStyle.totalMrp}>$0</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Divider />
                                                        <Box style={{ padding: '24px' }}>
                                                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography style={webStyle.totalRefund}>{this.transReturnDetail("Total Refund")}</Typography>
                                                                <Typography style={webStyle.totalRefund}>{this.totalValue()}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Button style={{ ...webStyle.submitReturnButton, textTransform: 'capitalize' }} data-testid="reasonOpenHandle" onClick={this.reasonOpenHandle}>{this.transReturnDetail("Return Products")}</Button>
                                            </Box>
                                        </Box>}
                                </Grid>
                            </Grid>
                        </Box>
                        <StyledDrawer
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.reasonOpen}
                            onClose={this.reasonOpenHandleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={this.state.reasonOpen} >
                                <div style={{ padding: "40px", background: 'white', width: '300px', borderRadius: '15px' }}>
                                    <Box id="transition-modal-title" style={{ ...webStyle.fadeMode }}>Reason of Return *</Box>
                                    <InputField
                                        id="outlined-multiline-static"
                                        multiline
                                        inputProps={{ 'data-testid': 'changeDisc' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                            'aria-label'?: string;
                                            'data-testid'?: string;
                                        }}
                                        type="text"
                                        rows={3}
                                        variant="outlined"
                                        placeholder="Enter Reason of Return"
                                        style={{ width: '100%' }}
                                        value={this.state.description}
                                        onChange={(event) => {
                                            this.changeDisc(event.target.value)
                                        }}
                                    />

                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                        <StyledButtonModel style={{ fontSize: "14px" }} data-testid="reasonOpenHandleClose" onClick={this.reasonOpenHandleClose}>Cancel</StyledButtonModel>
                                        <StyledButton1Model style={{ fontSize: "14px" }} data-testid="cancelReturnOrderSubmit" onClick={this.cancelReturnOrder}>Submit</StyledButton1Model>
                                    </div>
                                </div>
                            </Fade>
                        </StyledDrawer>
                        <StyledDrawer
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.submittedRequest}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={this.state.submittedRequest} >
                                <div style={{ padding: "40px", background: 'white', width: '400px', borderRadius: '15px' }}>
                                    <Box id="transition-modal-title" style={{ ...webStyle.confirmationMode, textAlign: 'center' }}>Confirmation</Box>
                                    <Typography id="transition-modal-title" style={{ ...webStyle.requestMode, textAlign: 'center' }}>Return request submitted.</Typography>
                                    <Typography id="transition-modal-title" style={{ ...webStyle.notified, textAlign: 'center' }}>You will be notified once the seller accepts the return.</Typography>
                                    <StyledButton1Model style={{ fontSize: "14px", width: '100%' }} data-testid="cancelReturnOrderSubmit" onClick={this.contioneShopping}>Orders</StyledButton1Model>
                                </div>
                            </Fade>
                        </StyledDrawer>
                    </Grid>
                </Grid>

                <Footer navigation={this.props.navigation} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    requestMode: { fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', color: "#375280" },
    confirmationMode: { fontSize: '24px', fontWeight: 800, fontFamily: 'Lato', color: "#375280", marginBottom: '20px' },
    notified: { fontSize: '16px', fontWeight: 500, fontFamily: 'Lato', color: "#375280", marginBottom: '20px' },
    innerContainer4: {
        color: "#807D7E",
        fontWeight: 400,
        lineHeight: "33.5px",
        fontStyle: "normal",
        fontSize: "14px",
        fontFamily: "Lato , sans-serif",
    },
    headerContainer: {
        display: 'flex', 
        marginBottom: '20px',
        alignItems: "center", 
        gap: 40
    },
    innerContainer11: {
        gap: "10px",
        display: "flex",
        fontWeight: 400,
        alignItems: "center",
        lineHeight: "22px",
        color: "#475569",
    },
    innerContainer23: {
        fontSize: "14px",
        fontFamily: "Lato , sans-serif",
        padding: "4px 6px 4px 6px",
        fontStyle: "normal",
        lineHeight: "22px",
        color: "#475569",
        fontWeight: 400
    },
    backIcon: {
        cursor: "pointer",
        padding: "0.5rem",
        background: "#FFF",
        boxShadow: "0px 2px 8px 0px #00000014",
        borderRadius: 100,
    },
    innerContainer3: {
        alignItems: "center",
        display: "flex",
        marginTop: "31px",
        lineHeight: "33.5px",
        color: "#375280",
        fontWeight: 800,
        gap: "15px",
        fontStyle: "normal",
        fontSize: "28px",
        fontFamily: "Lato , sans-serif",
    },
    boxSadow: { backgroundImage: 'rgb(255, 255, 255)', boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 8px 0px" },
    showPrize: { color: '#375280', fontSize: '24px', fontFamily: 'Lato', fontWeight: 700, marginTop: '20px' },
    sizeColorName: { color: '#94A3B8', fontSize: '14px', fontFamily: 'Lato', fontWeight: 700 },
    catlogeName: { color: '#375280', fontSize: '16px', fontFamily: 'Lato', fontWeight: 500 },
    storName: { color: '#375280', fontSize: '18px', fontFamily: 'Lato', fontWeight: 700 },
    orderId: { color: '#94A3B8', fontSize: '16px', fontFamily: 'Lato', fontWeight: 500, marginBottom: '36px' },
    returnOrder: { color: '#375280', fontSize: "20", fontFamily: 'Lato', fontWeight: 800 },
    timePickup: { color: '#375280', fontSize: '16px', fontFamily: 'Lato', fontWeight: 500, marginTop: '10px' },
    totalMrp: { color: '#375280', fontSize: '16px', fontFamily: 'Lato', fontWeight: 900 },
    totalPickup: { color: '#94A3B8', fontSize: '16px', fontFamily: 'Lato', fontWeight: 500 },
    totalRefund: { color: '#375280', fontSize: '24px', fontFamily: 'Lato', fontWeight: 700 },
    fadeMode: { width: '100%', fontSize: '20px', color: "#375280", fontWeight: 500, fontFamily: "Lato", marginBottom: '5px' },
    submitReturnButton: { width: '100%', marginTop: '25px', backgroundColor: '#CCBEB1', padding: '10px 16px', borderRadius: '2px', color: '#FFFFFF', fontSize: '20px', fontFamily: 'Lato', fontWeight: 800 },
    rejectinfoBox: { color: "#375280", fontSize: "14px", fontFamily: 'Lato', fontWeight: 400 },
    rejectBox: { backgroundColor: "#F4F4F4", height: "42px", paddingLeft: '20px', display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '30px' },
    tabsBoxRejected: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    orderDetailsValueRejected: { color: "#94A3B8", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px", marginTop: "10px", textTransform: "capitalize" },
    orderDetailsNameRejected: { color: "#375280", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px" },
    tableBox: { border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "30px", padding: '20px' },
    newOrderIdRejected: { color: "#375280", fontFamily: 'Lato', fontWeight: 700, fontSize: "24px", margin: '0px 16px' },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    tableHaderrowRejected: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    rejectedBox: { backgroundColor: '#FEE2E2', color: '#DC2626', padding: '6px 10px 6px 10px', borderRadius: '5px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
}
const InputField = styled(TextField)({
    "& .MuiInputBase-input": {
        color: "rgb(55, 82, 128) !important"
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#FFFFFF"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#FFFFFF",
            borderWidth: "1px",
        }
    },
    "& .MuiInputBase-root": {
        fontSize: "16px",
        borderRadius: "10px",
        margin: "auto",
        height: '104px',
        width: '100%',
        background: 'rgb(248, 248, 248)',
        "@media only screen and (max-width: 414px)": {
            fontSize: "14px"
        }
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        borderWidth: "1px",
        borderColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-input": {
        color: "#375280",
        "&::placeholder": {
            color: "#375280",
            "@media only screen and (max-width: 414px)": {
                fontSize: "20px"
            }
        },
        "&:not(:placeholder-shown)": {
            color: "#1E293B"
        }
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1"
    }
});
const StyledDrawer = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledButtonModel = styled(Button)(() => ({
    color: "#375280",
    height: "45px",
    fontWeight: 500,
    textTransform: "none",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    width: "130px !important",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1Model = styled(Button)(() => ({
    color: "#fff",
    fontWeight: 500,
    height: "45px",
    width: "130px",
    backgroundColor: '#CCBEB1',
    textTransform: "none",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomCheckbox = withStyles({
    root: {
        color: '#CCBEB1',
        '&$checked': {
            color: '#CCBEB1',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg, hsla(0,0%,100%,.8), hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checked: {
        color: '#CCBEB1',
    },
    checkedIcon: {
        backgroundColor: '#CCBEB1',
    },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
// Customizable Area End