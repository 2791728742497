import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

import {
  styled,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Box,
  TextField,
  Button
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import SellerUpdateInventoryController, {
  Props,
} from "./UpdateInventoryController";
import { AntSwitch } from "../../../SellerStore.web";
import { BtnContainer, NoRecordTypoContainer, WarningDialogContainer } from "../AssignStore/InventoryAssignStore.web";
import Loader from "../../../../../../components/src/LoaderContainer";
import ReusableSnackbar from "../../../../../../components/src/AlertContainer";
import i18n from "../../../../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class SellerUpdateInventory extends SellerUpdateInventoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPagination = () => (
    <>
      {this.state.updateInventoryMeta !== undefined ? (
        <PaginationContainer>
          <Box className="pagination-data-container">
          {`${(this.state.updateInventoryMeta?.current_page - 1) * 10 + 1} - 
         ${Math.min(
            this.state.updateInventoryMeta?.current_page * 10,
            this.state.updateInventoryMeta?.total_record
          )} ${this.tranUpdateInventory("of")} ${this.state.updateInventoryMeta.total_record} ${this.tranUpdateInventory("results")}`}
          </Box>
          <CustomStyledPagination
            page={this.state.page}
            count={this.state.updateInventoryMeta.total_pages}
            shape="rounded"
            color="primary"
            onChange={this.handleUpdateInventoryPageChange}
            dir={i18n.dir()}
            data-test-id="ui-pagination-test-id"
          />
        </PaginationContainer>
      ) : (
        <></>
      )}
    </>
  );

  renderUpdateInventoryBody = () => (
    <>
      {this.state.tableBodyData.length === 0 ? (
        <TableRow>
          <TableCell className="ui-no-data" colSpan={5}>
            <NoRecordTypoContainer>{this.tranUpdateInventory("No Records Found")}</NoRecordTypoContainer>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {this.state.tableBodyData?.map((updateInventoryData) => (
            <TableRow key={updateInventoryData.id} className="hover">
              <TableCell data-test-id="product-name">
                <Box className="product-name-container">
                  <img
                    src={updateInventoryData.front_image}
                    className="img-container"
                    alt={updateInventoryData.product_name}
                  />
                  <Typography className="product-title">
                    {updateInventoryData.product_name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography className="product-sku">
                  {updateInventoryData.sku}
                </Typography>
              </TableCell>
              <TableCell>
                <Box
                  className={`current-stock-container ${
                    updateInventoryData.stock_qty === 0
                      ? "red-container"
                      : "green-container"
                  }`}
                >
                  <EditTextField
                    value={updateInventoryData.stock_qty}
                    data-test-id={`ui-current-stock-${updateInventoryData.id}`}
                    inputProps={{ maxLength: 5 }}
                    onChange={(e) =>
                      this.handleCurrentStockChange(
                        updateInventoryData.id,
                        e.target.value
                      )
                    }
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  className={`current-stock-container ${
                    updateInventoryData.low_stock_threshold === 0
                      ? "red-container"
                      : "green-container"
                  }`}
                >
                  <EditTextField
                    value={updateInventoryData.low_stock_threshold}
                    data-test-id={`ui-low-stock-threshold-stock-${updateInventoryData.id}`}
                    onChange={(e) =>
                      this.handleLowStockThresholdChange(
                        updateInventoryData.id,
                        e.target.value
                      )
                    }
                    inputProps={{ maxLength: 5 }}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box className="switch-box">
                  <AntSwitch
                    checked={updateInventoryData.is_listed}
                    name="checked"
                    data-test-id={`ant-switch-${+updateInventoryData.id}`}
                    onChange={() =>
                      this.handleInventoryListStatusUpdate(
                        updateInventoryData.id,
                        updateInventoryData.is_listed
                      )
                    }
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <CustomStyledTableUpdateInventory>
          <Table stickyHeader size="small" className="custom-table">
            <TableHead>
              <TableRow
                style={{ backgroundColor: "#f0f0f0" }}
                className="ui-table-head-row"
              >
                {this.state.tableHeadName.map(
                  (header: string, index: number) => (
                    <TableHeaderCell key={index} className="ui-table-head-cell">
                      <Typography className="table-head-title">
                        {this.tranUpdateInventory(header)}
                      </Typography>
                    </TableHeaderCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.isLoading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Loader
                      id="step-1-loader"
                      dataTestId="step-1-loader-test"
                    />
                  </TableCell>
                </TableRow>
              ) : (
                this.renderUpdateInventoryBody()
              )}
            </TableBody>
          </Table>
        </CustomStyledTableUpdateInventory>
        {this.state.updateInventoryMeta.total_record > 10 &&
          this.renderPagination()}
          <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertUISnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
        <WarningDialogContainer
          data-test-id="ui-warning-dialog"
          open={this.state.warningOpen}
          dir={i18n.dir()}
        >
          <Box className="content-container">
            <Typography className="text-container">
              {this.tranUpdateInventory("You have unsaved changes.")}
            </Typography>
            <Typography className="text-container">
            {this.tranUpdateInventory("Are you sure you want to leave this page?")}
            </Typography>
            <Typography className="text-container">
              {this.tranUpdateInventory("If")} <span className="success-color">{this.tranUpdateInventory("No")}</span> {this.tranUpdateInventory("Please click on Confirm Button Otherwise")}{" "}
              <span className="error-color">{this.tranUpdateInventory("Cancel")}</span>
            </Typography>
          </Box>
          <BtnContainer className="btn-container">
            <Button
              variant="outlined"
              onClick={() => this.handleCancel()}
              className={"no-btn"}
              disableElevation
              data-test-id="ui-warning-cancel-test-id"
            >
              {this.tranUpdateInventory("Cancel")}
            </Button>
            <Button
              variant="contained"
              className={"yes-btn"}
              disableElevation
              onClick={() => this.handleConfirm()}
              data-test-id="ui-warning-confirm-test-id"
            >
              {this.tranUpdateInventory("Confirm")}
            </Button>
          </BtnContainer>
        </WarningDialogContainer>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const CustomStyledTableUpdateInventory = styled(TableContainer)({
  "& .cell": {
    backgroundColor: "#ffffff",
    borderRadius: "5px 5px 0px 0px",
  },
  "& .MuiTableCell-sizeSmall": {
    padding: "15px 30px 15px 30px",
  },
  "& .check-box-container": {
    display: "flex"
  },
  "& .custom-table": {
    border: "1px solid #E2E8F0",
    borderRadius: "5px 5px 0px 0px",
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root.MuiTableCell-body": {
          "& .product-name-container": {
            display: "flex",
            gap: 5,
            padding: 5,
            alignItems: "center",
            "& .img-container": {
              width: 34,
              height: 34,
            },
            "& .product-title": {
              fontFamily: "Lato, sans-serif",
              fontWeight: 400,
              fontSize: 16,
              color: "#375280",
            },
          },
          "& .product-sku": {
            fontFamily: "Lato, sans-serif",
            fontSize: 16,
            fontWeight: 400,
            color: "#375280",
            display: "flex"
          },
          "& .switch-box": {
            display: "flex"
          },
          "& .current-stock-container": {
            fontFamily: "Lato, sans-serif",
            fontSize: 16,
            fontWeight: 400,
            width: 80,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& .MuiTypography-body1": {
              height: 16,
            },
          },
          "& .current-stock-container.red-container": {
            border: "1px solid #F87171",
            borderRadius: 4,
            color: "#F87171",
          },
          "& .current-stock-container.green-container": {
            border: "1px solid #E2E8F0",
            borderRadius: 4,
            color: "#375280",
          },
        },
      },
    },
  },
});

export const TableHeaderCell = styled(TableCell)({
  "& .table-head-title": {
    color: "#375280",
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    whiteSpace: "nowrap",
    display: "flex"
  },
});

export const PaginationContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "23px 0px",
  "@media(max-width:700px)": {
    flexDirection: "column-reverse",
    gap: 20,
  },
  "& .pagination-data-container": {
    color: "#475569",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Lato, sans-serif",
  },
});

export const CustomStyledPagination = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  "& .MuiPaginationItem-root": {
    color: "#375280",
  },
  "& .MuiPaginationItem-textPrimary.Mui-selected": {
    backgroundColor: "#375280",
  },
  "& .Mui-selected": {
    color: "white",
  },
  "& li:first-of-type .MuiPaginationItem-icon": {
    transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
  },
  "& li:last-of-type .MuiPaginationItem-icon": {
    transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
  },
}));

export const EditTextField = styled(TextField)({
  height: "100%",
  "& .MuiInputBase-root.MuiInput-root": {
    height: "100%",
    color: "currentColor",
    "& .MuiInputBase-input.MuiInput-input": {
      textAlign: "center",
      paddingTop: 12,
    },
    "& .MuiInputBase-input.MuiInput-input:hover": {
      border: "none",
    },
    "& :hover": {
      border: "none",
    },
  },
  "& .MuiInput-underline": {
    "&::before": {
      border: "none",
    },
    "&::after": {
      border: "none",
    },
    "&:hover:not(.Mui-disabled)::before": {
      borderBottom: "none",
    },
  },
  "& :hover": {
    border: "none",
  },
});
// Customizable Area End
