import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid, Card, Snackbar
} from "@material-ui/core";
// Customizable Area End
// Customizable Area Start
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { image_Likepng, Noimageavailable } from "./assets"
import { styled } from "@material-ui/core/styles";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Header from "../../../components/src/Header"
import Footer from "../../../components/src/Footer"
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
// Customizable Area End

import PairitwithWebController, {
    Props,
} from "./PairitwithWebController";

export default class PairitwithWeb extends PairitwithWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    imgFilter2 = () => {
        return this.state.productsData?.attributes.catalogue_variants_with_store.map((filteredValue, index: number) => {
            const isSelected = this.state.selectedItem === index;
            return (
                <Box key={index}>
                    <Box style={{ ...webStyle.styleimg, border: isSelected ? '1px solid #CCBEB1' : '', position: "relative" }} onClick={() => this.handleSelect(index)} data-testid={`handleSelect-${index}`}>
                        <img
                            alt={filteredValue.attributes.front_image}
                            style={{ objectFit: "fill", ...webStyle.stylebox }}
                            src={filteredValue.attributes.front_image === "" ? Noimageavailable : filteredValue.attributes.front_image}
                        />
                    </Box>
                </Box>
            );
        })
    }
    imgFilter = () => {
        return this.state.productsData?.attributes.catalogue_variants_with_store.map((filteredValue, index: number) => (
            <div key={index}>
                <img style={{ objectFit: "cover", width: "511px", height: "560px" }} src={filteredValue.attributes.front_image === "" ? Noimageavailable : filteredValue.attributes.front_image} width="100%" />
            </div>
        ))
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box>
                {this.state.stylishBuyer ?  <StylishBuyerHeader navigation={this.props.navigation} /> : <Header navigation={this.props.navigation} />}
                
                {this.state.lodingProduct ? <Box data-testid="loder" style={{ width: '100%', textAlign: 'center', paddingBottom: '20px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlignLast: 'center' }}><CircularProgress style={{ color: '#375280' }} /></Box>
                    :
                    <Box data-testid="loder12">
                        <Box style={{background: "rgb(248, 248, 248)"}}>
                            <Box style={{ padding: "29px 0px", marginLeft: '40px' }}>
                                <Typography style={{ ...webStyle.text, cursor: 'pointer', alignItems: 'center' }}><Box data-testid="homePageNavigation" onClick={() => this.homePageNavigation(this.props)}>Home</Box> <NavigateNextIcon style={webStyle.navigateIcon} /> <Box data-testid="clothingPageNavigation" onClick={() => this.clothingPageNavigation(this.props)}>{this.state.categoryData}</Box> <NavigateNextIcon style={webStyle.navigateIcon} /> {this.state.sub_categoryData} <NavigateNextIcon style={webStyle.navigateIcon} /><Box style={{ fontFamily: 'Lato', fontSize: "14px", color: "#94A3B8", fontWeight: 700 }}> Pair It With</Box></Typography>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <CustomCarousel>
                                    <div style={{ paddingRight: "43px" }}>
                                        <div>
                                            <Carousel
                                                showStatus={false}
                                                showArrows={false}
                                                onChange={this.handleSelect}
                                                showThumbs={false}
                                                selectedItem={this.state.selectedItem}
                                            >
                                                {this.imgFilter()}
                                            </Carousel>
                                            <div style={webStyle.elementBlock}>
                                                <div
                                                    onClick={this.handleClick}
                                                    data-testid='handleClick'
                                                    style={webStyle.leftIcon}
                                                >
                                                    <ChevronLeftIcon style={{ color: "white" }} />
                                                </div>
                                                <div ref={this.elementRef} style={webStyle.imgBlock}>
                                                    {this.imgFilter2()}
                                                </div>
                                                <div
                                                    data-testid="handleNextClick"
                                                    style={webStyle.next}
                                                    onClick={this.handleNextClick}
                                                >
                                                    <NavigateNextIcon style={{ color: "white" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Box style={{ color: "#375280", fontSize: "34px", fontFamily: "Lato", fontWeight: 800, margin: "36px 0px", textAlign: 'center' }}>Raven Hoodie With Black colored Design</Box>
                                </CustomCarousel>
                            </Box>
                        </Box>
                        <Box style={{ padding: '0px 120px' }}>
                            <Typography style={{ ...webStyle.cart, margin: "40px 0px" }}>You may also like</Typography>
                            <div style={webStyle.cartLine} />
                            <Grid container spacing={4} justifyContent="center">
                                {this.state.productsData?.attributes.catalogue_variants_with_store[0].attributes.pair_it_with.map((product, index: number) => (<>
                                    <Grid item key={index}>
                                        <Box style={{ cursor: 'pointer' }} data-testid={`navigateTo=${product.id}`} onClick={() => this.navigateTo({ productId: product.attributes.catalogue_id, props: this.props, screenName: "Productdescription3" })}>
                                            <Card style={webStyle.productBoxproductd}>
                                                <Box style={{ position: 'relative' }}>
                                                    <img
                                                        style={{ position: 'relative' }}
                                                        width="100%" height="370px"
                                                        src={product.attributes.front_image === "" ? Noimageavailable : product.attributes.front_image}
                                                        alt="Product"
                                                    />
                                                    <Box style={{ position: 'absolute', ...webStyle.productIconproductd }}>
                                                        <Box style={webStyle.productDiscountPairit}>15% Off</Box>
                                                        <Box style={webStyle.productFavoritePairit}><img src={image_Likepng} width="18px" height="18px" /></Box>
                                                    </Box>
                                                </Box>
                                                <Box style={{ backgroundColor: '#F8F8F8', padding: '24px' }} onClick={this.oncloseAlert} data-testid="alertTestId">
                                                    <Box style={{ whiteSpace: 'nowrap', ...webStyle.productDataproductd, flexDirection: 'row' }}>
                                                        <Box style={{ ...webStyle.productValueproductd, whiteSpace: 'nowrap' }}>
                                                            <Typography style={{ ...webStyle.productNameproductd, whiteSpace: 'nowrap' }} >{product.attributes.brand_name}</Typography>
                                                            <Typography style={{ ...webStyle.productDescriptionproductd, whiteSpace: 'nowrap' }} >{product.attributes.product_name}</Typography>
                                                        </Box>
                                                        <Box style={{ ...webStyle.acountMrpproductd, textAlign: 'center' }}>$&nbsp;{`${product.attributes.price}`}</Box>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </Box>
                                    </Grid>
                                </>))}
                            </Grid>
                        </Box>
                        <Snackbar
                            onClose={this.oncloseAlert}
                            autoHideDuration={3000}
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            open={this.state.isAlert}
                        ><Alert variant="filled" severity="warning">{this.state.already}</Alert></Snackbar>
                    </Box>
                }
                <Footer navigation={this.props.navigation} />
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    navigateIcon: { width: "20px" },
    text: { display: 'flex', color: "#375280", fontFamily: "Lato", gap: "8px" },
    leftIcon: { display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: "#CCBEB1", borderRadius: '50%', width: '38px', height: '38px', },
    productBoxproductd: { boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "282px", marginBottom: "20px" },
    next: { display: 'flex', cursor: 'pointer', backgroundColor: '#CCBEB1', borderRadius: '50%', width: '38px', height: '38px', justifyContent: 'center', alignItems: 'center', },
    styleimg: { width: '76px', backgroundColor: "rgb(248, 248, 248)", height: '76px', borderRadius: "10px", },
    imgBlock: { display: 'flex', gap: "20px", backgroundColor: "#F8F8F8", overflow: 'hidden', maxWidth: '462px', borderRadius: '10px' },
    cart: { display: "flex", justifyContent: "center", fontWeight: 700, fontSize: "32px", color: "#375280", fontFamily: "Lato", marginBottom: "15px" },
    elementBlock: { display: "flex", paddingTop: "44px", justifyContent: 'center', gap: "20px", alignItems: "center" },
    productNameproductd: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
    acountMrpproductd: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
    productDataproductd: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' },
    cartLine: { margin: "0 auto", width: "78px", height: "4px", background: "#CCBEB1", marginBottom: "40px" },
    productDiscountPairit: { color: "#375280", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 5px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
    stylebox: { width: '68px', height: '68px', borderRadius: "10px", margin: "4px", },
    color: { fontSize: "18px", color: "#375280", fontWeight: 700, fontFamily: "Lato" },
    productValueproductd: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto' },
    productFavoritePairit: { color: "#375280", marginRight: '20px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
    productDescriptionproductd: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
    productIconproductd: { top: '20px', left: '18px', display: "flex", justifyContent: 'space-between', right: '0' },
};
const CustomCarousel = styled('div')({
    '& .carousel .slide .legend': {
        marginLeft: '-45%',
        width: '90%',
        borderRadius: '10px',
        padding: '10px',
        transition: 'all .5s ease-in-out',
        position: 'absolute',
        fontSize: '12px',
        left: '50%',
        textAlign: 'center',
        opacity: '1',
        bottom: '40px',
    },
    '& .carousel .slide img': {
        width: "100%",
        height: "785px",
    },
    '& .control-dots': {
        justifyContent: 'center',
        display: 'flex',
        bottom: '20px',
        alignItems: 'center'
    },
    '& .carousel .control-dots .dot': {
        width: "8px !important",
        margin: '0 3px !important',
        backgroundColor: "#A4BCE5 !important",
        boxShadow: 'none',
        opacity: 1,
        height: '8px !important',
    },
    '& .carousel .control-dots .dot.selected': {
        backgroundColor: "#375280 !important",
        margin: '0 3px !important',
        height: '12px !important',
        backgroundSize: 'cover',
        width: "12px !important",
    },
    '& .legend': {
        opacity: 1,
        left: 'auto',
    },
    '& .carousel .control-prev .control-arrow': {
        backgroundColor: 'aquamarine',
    },
});
// Customizable Area End
