import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { buttonArrow } from "../src/assets"
// Customizable Area End

import TermsConditionsStylistController, {
    Props,
} from "./TermsConditionsStylistController.web";

export default class TermsConditionsStylist extends TermsConditionsStylistController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <Header navigation={this.props.navigation} />
                <ContainerBox>
                    <Grid container >
                        <Grid item xs={12} md={12} lg={12}>
                            <Box style={{ display: 'flex', alignItems: 'center', margin: "0px 100px", marginBottom: '30px' }}>
                                <Box><img src={buttonArrow} data-testid="navigateToStylist" onClick={() => this.navigateToStylist({ productId: this.state.hiredStylist, props: this.props, screenName: "HiredStylistProfile" })} /></Box>
                                <Box style={{ marginLeft: '30px', color: '#375280', fontSize: '20px', fontWeight: 800, fontFamily: 'Lato' }} data-testid="termsandConditions">Terms and Conditions</Box>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start", margin: "0px 165px" }}>
                                <PolicyContainer >
                                    <div style={webStyles.divContainer}
                                        dangerouslySetInnerHTML={{ __html: this.state.termsConds && this.state.termsConds.description }} />
                                </PolicyContainer>
                            </Box>
                        </Grid>
                    </Grid>
                </ContainerBox>
                <Footer navigation={this.props.navigation} />
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const ContainerBox = styled(Box)({
    fontFamily: "Lato",
    color: "#375280",
    backgroundColor: "rgb(242, 239, 239)",
    padding: "50px 0px"
})
const PolicyContainer = styled(Box)({
    width: "100%",
    wordWrap: "break-word",
    padding: "20px",
    overflowY: "auto",
    border: "1px solid #bfd1e8",
    fontFamily: "Lato",
})

const webStyles = {
    divContainer: {
        color: "#375280",
        fontSize: "17px"
    }
}
// Customizable Area End
