import React, { memo } from "react";
// Customizable Area Start
import {
    Box, TableContainer, Table, TableBody, TableRow, TableCell, Snackbar, TableHead, Typography, Button, Modal, Backdrop, Fade, withStyles, FormControl,
    Dialog,
    Divider,
    FormHelperText,
    TextField,
    Drawer
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { styled } from "@material-ui/core/styles";
import SellersAllOrderController, {
    Props,
} from "./SellersAllOrderController.web";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { group_image, add_circle, button_close, Noimageavailablepng, document, group_Group, message, calls } from "./assets"
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from "@material-ui/icons/Close";
import { statusContainerReturnData, statusContainerTrackDriver } from "../../../components/src/Seller/SellerStatusPopup";
import TrackMap from "../../../components/src/TrackMap";
import { withScriptjs } from "react-google-maps";
import i18n from "../../../components/src/i18next/i18n";
export const configJSON = require("./config");
interface MapLoaderProps {
    destination: {lat: number, lng: number};
    origin: {lat: number, lng: number};
    googleMapURL: string;
    loadingElement: JSX.Element;
}
// Customizable Area End

export default class SellersAllOrderWebPage extends SellersAllOrderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        event.preventDefault()
        this.setState({ page: newPage }, () => this.allOrderData());
    };
    getPriceValueCu =  (currency: string, price: string) => {
        return `${currency} ${price}`;
        };
    renderContent = (startIndex: number, endIndex: number) => {
        if (this.state.isLoading) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }

        if (this.state.tableAllOrder === undefined && this.state.isAlert) {
            return (
                <Box style={{ ...webStyle.tabeLable, position: "absolute", }}>
                    {this.transSellerAllOrder("There are no orders")}
                </Box>
            );
        }
        return (
            <>
                <CustomStyledTable className="container">
                    <Box style={{ position: 'relative', ...webStyle.menBox ,flexDirection: 'column'}}>
                    <Table stickyHeader size="small" className="custom-table">
                        <TableHeadAllOrder>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6" className="tableHeaderTxt">{this.transSellerAllOrder("Order ID")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6" className="tableHeaderTxt">{this.transSellerAllOrder("Order Time")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6" className="tableHeaderTxt">{this.transSellerAllOrder("Products")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6" className="tableHeaderTxt">{this.transSellerAllOrder("Shop")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6" className="tableHeaderTxt">{this.transSellerAllOrder("Total Price")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6" className="tableHeaderTxt">{this.transSellerAllOrder("Status")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6" className="tableHeaderTxt">{this.transSellerAllOrder("Action")}</Typography></TableCell>
                            </TableRow>
                        </TableHeadAllOrder>
                            <TableBody style={{ height: '100px', position: 'relative' }}>
                                {this.state.isLoading1 ?
                                    <Box data-testid="loader" style={{position: 'absolute',...webStyle.loderBox}}>
                                        <CircularProgress style={{ color: '#375280' }} />
                                    </Box> :
                                    this.state.tableAllOrder && this.state.tableAllOrder?.map((orderData, index) => {
                                        const orderNotGetting = orderData && orderData.attributes;
                                        return (
                                            <TableRow key={index} className="hover">
                                                <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-all-order-body-typo">#{orderNotGetting.order_management_order?.attributes.order_number}</Typography></TableCell>
                                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-all-order-body-typo">{this.dateFormetTime(orderNotGetting.order_items[0]?.attributes.placed_at)}</Typography></TableCell>
                                                <TableCell>{orderNotGetting.order_items.map((value) => {
                                                    return (
                                                        <Box style={{ display: 'flex', cursor: 'pointer', gap: 10 }} data-testid="navigateToAllOrder" onClick={() => this.navigateTo({ productId: orderNotGetting.order_management_order?.attributes.order_number, props: this.props, screenName: "SellersAllOrderDetailsWeb" })}>
                                                            <Box><img src={value.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : value.attributes.catalogue_variant_front_image} style={{ width: '34px', height: '34px', borderRadius: '2px', marginBottom: "12px" }} /></Box>
                                                            <Box style={{ ...webStyle.tableHaderrow }}>{value.attributes.catalogue_name} x {value.attributes.quantity}</Box>
                                                        </Box>
                                                    );
                                                })}</TableCell>
                                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-all-order-body-typo">{orderNotGetting.order_items[0]?.attributes.store_name}</Typography></TableCell>
                                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-all-order-body-typo">{this.getPriceValueCu(orderNotGetting.order_items[0].attributes.currency_logo, orderNotGetting.order_items[0]?.attributes.total_price)}</Typography></TableCell>
                                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{display: "flex", whiteSpace: "nowrap"}}>{this.orderLabel(orderNotGetting.status)}</Box></TableCell>
                                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                                    <Box style={{display: "flex", gap: 8}}>{this.orderLabelButton(orderNotGetting.status, orderNotGetting.id)}
                                                    {orderNotGetting.status === "new_order" ? (
                                                        <Box style={{display: "flex", gap: 8}}>
                                                            <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize" }} data-testid={`handleOpen1Reject=${orderNotGetting.id}`} onClick={() => this.handleOpen1(orderData.attributes.id)}>{this.transSellerAllOrder("Reject")}</Button>
                                                            <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize" }} onClick={() => this.handleOk(orderNotGetting.id)} data-testid="handleOkAcceptOrder">{this.transSellerAllOrder("Accept Order")} ({this.formatTime(this.state.timerAllOrders)})</Button>
                                                        </Box>
                                                    ) : null}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>)
                                    })}
                            </TableBody>
                        </Table>
                    </Box>
                </CustomStyledTable>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "23px 0px" }}>
                    <Box style={{ color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: 'Lato' }}>
                        {startIndex} - {endIndex} {this.transSellerAllOrder("of")} {this.state.metaData?.total_record} {this.transSellerAllOrder("results")}
                    </Box>
                    <CustomStyledPagination
                        page={this.state.page}
                        count={this.state.metaData?.total_pages}
                        shape="rounded"
                        color="primary"
                        onChange={this.handlePageChange}
                        dir={i18n.dir()}
                        data-testid="seller-all-order-pagination"
                    />
                </Box>
            </>
        );
    }
    orderLabel = (value: string) => {
        let styles = {}
        let label = ''
        switch (value) {
            case 'shipped':
                styles = webStyle.shippedBox
                label = 'Out for Delivery'
                break;
            case 'delivered':
                styles = webStyle.deliveredBox
                label = 'Delivered'
                break;
            case 'processed':
                styles = webStyle.processedBox
                label = 'Ready for Collection'
                break;
            case 'in_process':
                styles = webStyle.in_processBox
                label = 'In Process'
                break;
            case 'return_request':
                styles = webStyle.return_requestBox
                label = 'Return Request'
                break;
            case 'rejected':
                styles = webStyle.rejectedBox
                label = 'Rejected'
                break;
            case 'new_order':
                styles = webStyle.new_orderBox
                label = 'New Order'
                break;
            case 'return_in_process':
                styles = webStyle.return_process
                label = 'Return In Process'
                break;
            case 'return_under_process':
                styles = webStyle.refund_process
                label = 'Refund In Process'
                break;
            case 'refunded':
                styles = webStyle.refunded
                label = 'Refunded'
        }
        return (<Box style={styles}>{this.transSellerAllOrder(label)}</Box>)
    }
    orderLabelButton = (label: string, orderId: number | string) => {
        switch (label) {
            case 'in_process':
                return <>
                    <Box>
                        <Button style={{ ...webStyle.acceptOrderInProcess, textTransform: "capitalize" }} onClick={() => this.handleInProcess(orderId)} data-testid={`handleInProcess=${orderId}`}>{this.transSellerAllOrder("Ready to Ship")}</Button>
                    </Box>
                </>
            case 'processed':
            case 'shipped':
                return <>
                    <Box>
                        <Button style={{ ...webStyle.orderstatus, textTransform: "capitalize" }} >{this.transSellerAllOrder("Order Status")}</Button>
                    </Box>
                </>
            case 'return_in_process':
                return <>
                    <Box>
                        <Button style={{ ...webStyle.orderstatus, textTransform: "capitalize" }} data-testid="toggleStatusDrawer" onClick={this.toggleStatusDrawer(true, orderId)}>{this.transSellerAllOrder("Order Status")}</Button>
                    </Box>
                </>
            case 'return_under_process':
                return <>
                    <Box style={{display: "flex", gap: 10}}>
                        <Button style={{ ...webStyle.orderstatus, width: '100px', textTransform: "capitalize" }} data-testid={`handleOpenRejectDeleteModal=${orderId}`} onClick={() => this.handleOpenRejectDeleteModal(orderId)}>{this.transSellerAllOrder("Reject")}</Button>
                        <Button style={{ ...webStyle.acceptOrderInProcess, width: '110px', textTransform: "capitalize" }} data-testid={`handleOpenDeleteModal=${orderId}`} onClick={() => this.handleOpenDeleteModal(orderId)}>{this.transSellerAllOrder("Initiate Refund")}</Button>
                    </Box>
                </>
            case 'return_request':
                return <>
                    <Box style={{display: "flex", gap: 10}}>
                        <Button style={{ ...webStyle.orderstatus, width: '100px', textTransform: "capitalize",  }} data-testid='handleOpenReject' onClick={() => this.handleOpenReject(orderId)}>{this.transSellerAllOrder("Reject")}</Button>
                        <Button style={{ ...webStyle.acceptOrderInProcess, width: '110px', textTransform: "capitalize" }} data-testid="handleOpenvisibleReturn" onClick={() => this.handleOpenvisibleReturn(orderId)} >{this.transSellerAllOrder("Accept")}</Button>
                    </Box>
                </>
            default:
                return null;
        }
    }

    handleAllOrderAnchor = () => {
        return i18n.dir()==="ltr" ? "right": "left"
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const MapLoader = withScriptjs(TrackMap);
        const ROWS_PER_PAGE = 10;
        const startIndex = (this.state.metaData?.current_page - 1) * ROWS_PER_PAGE + 1;
        const endIndex = Math.min(this.state.metaData?.current_page * ROWS_PER_PAGE, this.state.metaData?.total_record);
        const { errorMessage } = this.state;
        const MemoizedMapLoader = memo(({ destination, origin, googleMapURL, loadingElement }: MapLoaderProps) => {
            return (
                <MapLoader 
                    loadingElement={loadingElement}
                    googleMapURL={googleMapURL}
                    destination={destination}
                    origin={origin}
                />
            );
        }, (prevProps, nextProps) => {
            return (
                prevProps.destination === nextProps.destination &&
                prevProps.origin === nextProps.origin &&
                prevProps.googleMapURL === nextProps.googleMapURL
            );
        });
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box data-testid="allOrder" style={{ marginTop: '20px' }} dir={i18n.dir()}>
                    {this.renderContent(startIndex, endIndex)}
                </Box>
                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.rejectOpen}
                    onClose={this.handleClose1}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    dir={i18n.dir()}
                >
                    <Fade in={this.state.rejectOpen} style={webStyle.febBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleClose1} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>{this.transSellerAllOrder("Reject Order")}</h2>
                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>{this.transSellerAllOrder("Are you sure you want to reject the order?")}</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>{this.transSellerAllOrder("Reason of Rejection")}</Box>
                                <CustomSelect variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                                    <MuiSelectSelect
                                        style={webStyle.reminderName}
                                        value={this.state.numberData}
                                        onChange={this.handleSingleChangeNumber}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                        data-testid='sub-categories-selection'
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            <Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.transSellerAllOrder("Out of Stock")}</Box>
                                        </MenuItem>
                                        {this.state.short.map((select: string, index: number) => (
                                            <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{this.transSellerAllOrder(select)}</MenuItem>
                                        ))}
                                    </MuiSelectSelect>
                                </CustomSelect >
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleClose1}>{this.transSellerAllOrder("Close")}</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid="rejectData" onClick={this.rejectData}>{this.transSellerAllOrder("Reject")}</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.visible}
                    onClose={this.handleCancel}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    dir={i18n.dir()}
                >
                    <Fade in={this.state.visible} style={webStyle.febBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleCancel} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>{this.transSellerAllOrder("Accept Order")}</h2>
                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>{this.transSellerAllOrder("Are you sure you want to accept the order?")}</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>{this.transSellerAllOrder("Set order reupload time")}</Box>
                                <Box style={{ backgroundColor: "#F8F8F8", padding: "10px", borderRadius: "2px" }}>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Button data-testid="incrementTime" onClick={this.decrementTime} disabled={this.state.timerAllOrders <= 1}><img width="24px" height="24px" src={group_image} /></Button>
                                        <Box style={webStyle.timeBox}>
                                            <Typography variant="body1">{this.state.timerAllOrders}</Typography>
                                            <Typography variant="body1">&nbsp;{this.transSellerAllOrder("mins")}</Typography>
                                        </Box>
                                        <Button data-testid="decrementTime" onClick={this.incrementTime} disabled={this.state.timerAllOrders >= 120}><img width="24px" height="24px" src={add_circle} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleCancel" onClick={this.handleCancel}>{this.transSellerAllOrder("Close")}</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid="timeData" onClick={this.timeData}>{this.transSellerAllOrder("Confirm")}</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawerAllDetail
                    aria-describedby="transition-modal-description"
                    aria-labelledby="transition-modal-title"
                    closeAfterTransition
                    onClose={this.handleCancelInProcess}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    BackdropComponent={Backdrop}
                    open={this.state.visibleAllOrder}
                    dir={i18n.dir()}
                >
                    <Fade in={this.state.visibleAllOrder} style={webStyle.febBoxInProcess}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'end' }}><img onClick={this.handleCancelInProcess} src={button_close} /></Box>
                            <h2 style={{ ...webStyle.rejectNameNewOrderInProcess, textAlign: "center" }}>{this.transSellerAllOrder("Change Status")}</h2>
                            <Box style={{ ...webStyle.modelTextInProcess, textAlign: 'center' }}>
                                <Box style={{ paddingBottom: '20px' }}>{this.transSellerAllOrder("Are you sure you want to change the order status to ready for collection?")}</Box>
                                <Box>{this.transSellerAllOrder("Delivery partner will be assigned soon for the order pickup.")}</Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModelAllDetail style={{ fontSize: "14px" }} data-testid="handleCancelInProcess" onClick={this.handleCancelInProcess}>{this.transSellerAllOrder("Close")}</StyledButtonModelAllDetail>
                                <StyledButton1ModelAllDetail style={{ fontSize: "14px" }} data-testid="timeDataInProcess" onClick={this.timeDataInProcess}>{this.transSellerAllOrder("Confirm")}</StyledButton1ModelAllDetail>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerAllDetail>
                <Dialog
                    onClose={this.handleCloseDeleteModal}
                    open={this.state.isOpenDelete}
                    maxWidth="md"
                    dir={i18n.dir()}
                    PaperProps={{ style: { maxWidth: "600px", height: "444px", width: "100%", borderRadius: "12px" } }}
                >
                    <Box style={webStyle.closeicon}><CloseIcon onClick={this.handleCloseDeleteModal} style={{ cursor: "pointer" }} /></Box>
                    <Box style={{ margin: '0 auto', padding: '0 20px', display: "flex", flexDirection: "column", alignItems:"center" }}>
                        <Typography style={{ ...webStyle.refTitle, textAlign: "center" }}>{this.transSellerAllOrder("Initiate Refund")}</Typography>
                        <Typography style={{ ...webStyle.ListTitle, textAlign: "center", width: 420 }}>{this.transSellerAllOrder("Are you sure you want to initiate a refund for the returned product?")}</Typography>
                        <Box style={{ marginTop: "32px" ,width:'100%'}}>
                            <Typography style={webStyle.refundTitle}> {this.transSellerAllOrder("Refund Amount")}</Typography>
                            <InputField
                                id="outlined-multiline-static"
                                multiline
                                inputProps={{ 'data-testid': 'changeDisc' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                    'aria-label'?: string;
                                    'data-testid'?: string;
                                    type: 'number';
                                    min: '0';
                                }}
                                style={{ border: errorMessage ? '1px solid red' : '#ddd', width: '100%' }}
                                InputProps={{ startAdornment: <InputAdornment position="end" style={{ color: '#375280' }}><span style={{ color: '#375280', fontSize: '18px', marginRight: '0px' }}>$</span></InputAdornment>, }}
                                type="number"
                                variant="outlined"
                                disabled={true}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    const cleanedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                    this.setState({ refundAmount: cleanedValue });
                                }}
                                value={this.state.rejectMrpData}
                            />
                            {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
                        </Box>
                        <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                            <Button variant="contained" style={{ ...webStyle.close, textTransform: 'none' }} data-testid="handleCloseDeleteModal" onClick={this.handleCloseDeleteModal}>{this.transSellerAllOrder("Close")}</Button>
                            <Button variant="contained" color="secondary" style={{ ...webStyle.confirm, textTransform: 'none' }} data-testid="handleConfirm" onClick={() => this.handleConfirmDetalis(this.state.rejectMrpData)}>{this.transSellerAllOrder("Confirm")}</Button>
                        </Box>
                    </Box>
                </Dialog>
                <Dialog
                    onClose={this.handleCloseRejectDeleteModal}
                    open={this.state.isOpenDeleteReject}
                    maxWidth="md"
                    PaperProps={{
                        style: {
                            maxWidth: "600px",
                            height: "731px",
                            width: "100%",
                            borderRadius: "12px",
                        },
                    }}
                    dir={i18n.dir()}
                >
                    <Box style={webStyle.closeicon}><CloseIcon onClick={this.handleCloseRejectDeleteModal} data-testid="handleCloseRejectDeleteModal" style={{ cursor: "pointer" }} /></Box>
                    <Box style={{ margin: "0 auto" }}>
                        <Typography style={{ ...webStyle.rejTitle, textAlign: "center" }}>{this.transSellerAllOrder("Reject Refund")}</Typography>
                        <Typography style={webStyle.ListTitle}>{this.transSellerAllOrder("Reason of Rejection")}</Typography>
                        <CustomSelectNewOrder variant="outlined" style={{ width: '100%', backgroundColor: '#ffffff' }}>
                            <MuiSelectSelectNewOrder
                                value={this.state.numberDataOrder}
                                inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                MenuProps={{
                                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                    getContentAnchorEl: null,
                                }}
                                style={webStyle.reminderName}
                                displayEmpty
                                onChange={this.handlenumberDataOrderChangeNumber}
                                data-testid='sub-categories-selection'
                            >
                                <MenuItem value="" disabled><Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.transSellerAllOrder("Select Reason")}</Box></MenuItem>
                                {this.state.selectReson.map((select: string, index: number) => (
                                    <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{this.transSellerAllOrder(select)}</MenuItem>
                                ))}
                            </MuiSelectSelectNewOrder>
                        </CustomSelectNewOrder >
                        <Box style={{ marginTop: "32px" }}>
                            <Typography style={webStyle.uploadTitle}>
                                {this.transSellerAllOrder("Upload the item image for rejecting the refund")}
                            </Typography>

                            {this.state.combineImg.length == 0 ?
                                <Box style={webStyle.documentBox as React.CSSProperties}>
                                    <Box>
                                        <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                            <img className="fieldImg" alt='civilIdPassport' src={document} data-testid="openPictureUploadUplode" onClick={() => { this.openPictureUpload(0) }} />
                                        </Box>
                                        <Typography style={{ color: "#375280", fontWeight: 700, fontSize: "18px", fontFamily: "Lato" }}>{this.transSellerAllOrder("Upload Image (PNG or JPG)")}</Typography>
                                        <Typography style={{ color: "#94A3B8", fontWeight: 400, fontSize: "14px", fontFamily: "Lato" }}>{this.transSellerAllOrder("Only PNG or JPG files are accepted")}</Typography>
                                    </Box>
                                </Box>
                                :
                                <Box style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
                                    <img className="uploadBoximg" src={this.state.combineImg[0] || undefined} style={{ cursor: "pointer", width: '100%', height: '14.5rem' }} />
                                    <Box style={{ position: 'absolute', top: '75%', right: '20px' }}><img src={group_Group} width="48px" height="48px" data-testid="handleRemove" onClick={this.handleRemove} /></Box>
                                </Box>
                            }
                            <Button style={{ ...webStyle.uploadBtn, textTransform: "none", }} data-testid="openPictureUpload" onClick={() => { this.openPictureUpload(0) }}>
                            {this.transSellerAllOrder("Upload Photo")}
                            </Button>
                        </Box>
                        <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                            <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                                <Button variant="contained" style={{ ...webStyle.close, textTransform: 'none' }} onClick={this.handleCloseRejectDeleteModal}>{this.transSellerAllOrder("Close")}</Button>
                                <Button variant="contained" color="secondary" style={{ ...webStyle.confirm, textTransform: 'none' }} data-testid="timeOrderDataReject" onClick={this.timeOrderDataReject}>{this.transSellerAllOrder("Confirm")}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Dialog>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={this.state.isAlertOrder}
                    style={{ marginTop: '10px' }}
                    autoHideDuration={3000}
                    onClose={this.oncloseAlertInProcess}
                    data-testid="oncloseAlert"
                ><Alert variant="filled" severity={this.state.alertType}>{this.state.already}</Alert></Snackbar>
                <Drawer anchor={this.handleAllOrderAnchor()} id="Drawer" open={this.state.isDrawerStatusOpen} onClose={this.toggleStatusDrawer(false)} dir={i18n.dir()}>
                    <Box style={webStyle.MapComponentContainerTreack} id="">
                        <Box style={webStyle.mapHeaderText} id="mapHeaderText">
                            <Box id="mapHeaderTextText" style={webStyle.mapTitleContainerText}>
                                <Typography id="mapHeaderTextText" style={webStyle.trackDriveTitle}>{this.transSellerAllOrder("Order Status")}</Typography>
                                <CloseIcon role="presentation"
                                    onClick={this.toggleStatusDrawer(false)}
                                    onKeyDown={this.toggleStatusDrawer(false)} />
                            </Box>
                        </Box>
                        <Box style={webStyle.trackContentStatusContainer} id="trackContentStatusContainerTreck">
                            {statusContainerReturnData(this.state.orderData)}
                            <Box id="trackContentStatusContainerTreck" style={{ boxShadow: "0px 5px 10px 0px", backgroundColor: '#FFF', padding: '24px', margin: '24px 0px' }}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                                    <Typography id="driverNameSpaseTreck" style={webStyle.driverNameSpase}>{this.transSellerAllOrder("Order ID")}:</Typography>
                                    <Typography id="orderNumberTreck" style={webStyle.driverNameSpase}>#{this.state.orderNumber}</Typography>
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography  style={webStyle.driverNameSpase} id="Purchasedate">{this.transSellerAllOrder("Purchase date")}:</Typography>
                                    <Typography style={webStyle.driverNameSpase} id="placetAtstate">{this.state.placetAt}</Typography>
                                </Box>
                            </Box>
                            <Button disabled={this.state.orderData.return_pick_at === null} style={{ textTransform: 'capitalize', textAlign: 'center', ...webStyle.treckOrder }} data-testid="toggleStatusDataPick" onClick={this.toggleStatusData(true)}>{this.transSellerAllOrder("Track Order")}</Button>
                        </Box>
                    </Box>
                </Drawer>
                <Drawer anchor={this.handleAllOrderAnchor()} open={this.state.isDrawerStatus} dir={i18n.dir()} onClose={this.toggleStatusData(false)}>
                    <Box style={webStyle.MapComponentContainerTreack}>
                        <Box style={webStyle.mapHeaderText} id="mapHeaderText">
                            <Box id="mapHeaderText" style={webStyle.mapTitleContainerText}>
                                <Typography id="mapHeaderText" style={webStyle.trackDriveTitle}>{this.transSellerAllOrder("Track Driver")}</Typography>
                                <CloseIcon role="presentation"
                                    onClick={this.toggleStatusData(false)}
                                    onKeyDown={this.toggleStatusData(false)} />
                            </Box>
                        </Box>
                        <Box style={{ height: '449px' }}>
                            <div id="mapHeaderText">
                                <MemoizedMapLoader
                                    loadingElement={<div style={{ width: "200px", height: "400px" }} className="MapComponentHeight" />}
                                    googleMapURL={configJSON.mapApk}
                                    destination={this.state.longitudeData}
                                    origin={this.state.latitudeData}
                                />
                            </div>
                            <Box style={{ padding: '25px' }} id="mapHeaderText">
                                <Box style={webStyle.delivedTime}>
                                    <Typography id="driverNameText" style={webStyle.driverName}>{this.state.driverName}</Typography>
                                    {this.state.orderData.estimated_delivery_time === null ? null : <Typography style={webStyle.driverName}>{this.transSellerAllOrder("Delivering in")} : {this.state.orderData.estimated_delivery_time}</Typography>}
                                </Box>
                                <Box style={{ display: 'flex', margin: '24px 0px', justifyContent: 'space-between'}}>
                                    <Box id="driverNameOtp" style={webStyle.otpData}>{this.state.orderData.otp === null ? "****" : this.state.orderData.otp} - {this.transSellerAllOrder("OTP")}</Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', gap: 12 }} id="driverName">
                                        <Box id="driverNameMesseg"><img src={message} /></Box>
                                        <Box id="driverNameCall"><img src={calls} /></Box>
                                    </Box>
                                </Box>
                                <Divider style={{ marginBottom: '20px' }} />
                                {statusContainerTrackDriver(this.state.orderData)}
                            </Box>
                        </Box>
                    </Box>
                </Drawer>

                <StyledDrawer
                    aria-describedby="transition-modal-description"
                    aria-labelledby="transition-modal-title"
                    onClose={this.handleCancelReturn}
                    open={this.state.rejectOpenReturn}
                    BackdropComponent={Backdrop}
                    dir={i18n.dir()}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.rejectOpenReturn} style={webStyle.febBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} data-testid="handleCancelReturn" onClick={this.handleCancelReturn} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>{this.transSellerAllOrder("Accept Return?")}</h2>
                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>{this.transSellerAllOrder("Are you sure you want to accept the return order?")}</h2>

                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleCancelReturn" onClick={this.handleCancelReturn}>{this.transSellerAllOrder("Close")}</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid="acceptRequest" onClick={() => this.acceptRequest(this.state.orderIdReturnId)}>{this.transSellerAllOrder("Confirm")}</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>

                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.visibleReturn}
                    onClose={this.handleCancelvisibleReturn}
                    dir={i18n.dir()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.visibleReturn} style={webStyle.febBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleCancelvisibleReturn} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>{this.transSellerAllOrder("Reject Return Order")}</h2>
                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>{this.transSellerAllOrder("Are you sure you want to reject the return order?")}</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>{this.transSellerAllOrder("Reason of Rejection")}</Box>
                                <CustomSelect variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                                    <MuiSelectSelect
                                        value={this.state.numberDataRetun}
                                        displayEmpty
                                        style={webStyle.reminderName}
                                        onChange={this.handleSingleChangeNumberRetrun}
                                        data-testid='sub-categories-selection-retrnu'
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                        }}
                                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                    >
                                        <MenuItem value="" disabled>
                                            <Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.transSellerAllOrder("Out of Stock")}</Box>
                                        </MenuItem>
                                        {this.state.short.map((select: string, index: number) => (
                                            <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{this.transSellerAllOrder(select)}</MenuItem>
                                        ))}
                                    </MuiSelectSelect>
                                </CustomSelect >
                            </Box>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>{this.transSellerAllOrder("Rejection Details")}</Box>
                                <InputField
                                    id="outlined-multiline-static"
                                    multiline
                                    type="text"
                                    data-testid="enterRejectionDetails"
                                    rows={3}
                                    variant="outlined"
                                    placeholder={this.transSellerAllOrder("Enter Rejection Details")}
                                    style={{ width: '100%' }}
                                    value={this.state.descriptionReson}
                                    onChange={this.handleSingleChangeRetun}
                                />
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleCancelvisibleReturn" onClick={this.handleCancelvisibleReturn}>{this.transSellerAllOrder("Close")}</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid="rejectDataRetrun" onClick={this.rejectDataRetrun}>{this.transSellerAllOrder("Reject")}</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    menBox:{height: 'auto ', display: 'flex'},
    loderBox:{top: '50%',left: '50%',transform: 'translate(-50%, -50%)',display: 'flex',justifyContent: 'center',alignItems: 'center'},
    driverNameSpase: { color: '#375280', fontFamily: 'Lato', fontWeight: 700, fontSize: '16px' },
    trackContentStatusContainer: {
        padding: '10px 25px 80px 25px'
    },

    driverName: { color: '#375280', fontFamily: 'Lato', fontWeight: 700, fontSize: '14px' },
    delivedTime: { display: 'flex', justifyContent: 'space-between', backgroundColor: '#F4F4F4', padding: '6px 16px', borderRadius: '2px' },
    trackDriveTitle: {
        fontSize: "24px",
        fontFamily: "Lato",
        fontWeight: 700,
        color: "#375280",
    },
    uplodeText: { color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 700 },
    refunded: { backgroundColor: "#E1EFE1", color: '#039000', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    refund_process: { backgroundColor: "#FB31DB1A", color: '#FB31DB', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    orderstatus: { width: "220px", border: "1px solid #CCBEB1", borderRadius: "2px", color: "#375280", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    return_process: { backgroundColor: "#B55B521A", color: '#B55B52', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    febBoxInProcess: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    otpData: { backgroundColor: '#CCBEB1', padding: '13px 20px', color: "#FFFFFF", fontWeight: 700, fontSize: '14px', fontFamily: 'Lato', display: 'flex', alignItems: 'center' },
    rejectNameNewOrderInProcess: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '0px 0px 20px 0px' },
    modelTextInProcess: { color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 400, marginBottom: "30px" },
    acceptOrderInProcess: { backgroundColor: "#CCBEB1", width: "220px", borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    treckOrder: { backgroundColor: '#CCBEB1', padding: '15px', width: '100%', color: '#FFFFFF', fontFamily: 'Lato', fontWeight: 800, fontSize: '20px' },
    new_orderBox: { backgroundColor: '#D1FAE5', color: '#059669', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    rejectedBox: { backgroundColor: '#FEE2E2', color: '#DC2626', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    return_requestBox: { backgroundColor: '#e8f1ff', color: '#1a8fea', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    in_processBox: { backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    processedBox: { backgroundColor: '#fff3c9', color: '#d97601', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    deliveredBox: { backgroundColor: '#E2E8F0', color: '#375280', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    shippedBox: { backgroundColor: '#F0E5FF', color: '#6200EA', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    timeBox: { display: 'flex', alignItems: 'center', color: "#375280", fontFamily: "Lato", fontSize: "18px", fontWeight: 400 },
    mapTitleContainerText: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    mapHeaderText: {
        padding: 25
    },
    acceptOrder: { backgroundColor: "#CCBEB1", borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    rejectButton: { color: "#375280", fontFamily: 'Lato', fontWeight: 500, fontSize: "12px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px, 16px, 10px, 16px" },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    modelText: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 700, paddingBottom: '4px' },
    MapComponentContainerTreack: {
        width: 440,
        height: "100%"
    },
    tabeLable: {
        color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', top: "50%",
        left: "50%",
        transform: "translate(-40%, 20%)"
    },
    confomMaseg: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginBottom: '30px' },
    rejectName: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    febBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700,
    },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    replasePhoto: {
        top: "90%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#FFFFFF", width: '80%',
        color: "#375280", fontSize: '18px', fontFamily: 'Lato', padding: '15px 0px', fontWeight: 500
    },
    reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
    ListTitle: {
        fontSize: "18px",
        margin: "10px",
        fontFamily: "Lato",
        color: "#375280",
    },
    refundTitle: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 700,
    },
    inputBase: {
        width: '100%',
        maxWidth: '520px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '10px 12px',
        fontSize: '16px',
        backgroundColor: '#F8F8F8',
        transition: 'border-color 0.3s ease',

        '&:focus-within': {
            borderColor: '#3f51b5',
            boxShadow: `0 0 0 2px rgba(63, 81, 181, 0.3)`,
        },
    },
    close: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 500,
        width: '252px',
        height: "54px",
        border: '1px solid #CCBEB1',
        backgroundColor: 'transparent',
        marginTop: "16px",
        boxShadow: 'none',
    },
    documentBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '14.5rem',
        width: '100%',
        backgroundColor: '#F0F0F0',
        position: "relative",
    },
    confirm: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#FFFFFF",
        fontWeight: 500,
        width: '252px',
        height: "54px",
        backgroundColor: "#CCBEB1",
        marginTop: "16px",
        boxShadow: 'none',
    },
    refTitle: {
        fontSize: "30px",
        marginBottom: "30px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 500,
    },
    closeicon: { display: "flex", justifyContent: "flex-end", margin: "20px 20px 0px 0px", color: "#475569" },
    rejTitle: {
        fontSize: "30px",
        marginBottom: "30px",
        lineHeight: "40px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 500,
    },
    uploadTitle: {
        fontSize: "18px",
        margin: "10px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 400,
        lineHeight: "24px",
    },
    uploadBtn: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 500,
        width: "520px",
        height: "54px",
        border: "1px solid #CCBEB1",
        marginTop: "16px",
    },
    uploadContainer: {
        backgroundColor: "#F8F8F8",
        width: "520px",
        height: "185px",
        borderRadius: "2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        textAlign: "center",
    },
    cancel: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 500,
        lineHeight: "24px",
        width: "252px",
        height: "54px",
        border: "1px solid #CCBEB1",
        textTransform: "none",
        backgroundColor: "transparent",
        marginTop: "16px",

        boxShadow: 'none'
    },
    reject: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#FFFFFF",
        fontWeight: 500,
        lineHeight: "24px",
        width: "252px",
        height: "54px",
        backgroundColor: "#CCBEB1",
        marginTop: "16px",
        boxShadow: 'none'
    },
    selectholder: {
        color: "#9E9E9E",
        fontFamily: "Lato",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
    }
}
const CustomStyledPagination = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) =>({
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    "& li:last-of-type .MuiPaginationItem-icon": {
      transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    '& .Mui-selected': {
        color: 'white',
    },
    "& li:first-of-type .MuiPaginationItem-icon": {
        transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
}));

const StyledDrawerAllDetail = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));
const StyledButtonModelAllDetail = styled(Button)(() => ({
    fontWeight: 500,
    textTransform: "none",
    border: "1px solid #CCBEB1",
    color: "#375280",
    borderRadius: "2px",
    width: "252px !important",
    height: "45px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const InputField = styled(TextField)({
    "& .MuiInputBase-input": {
        color: "rgb(55, 82, 128) !important"
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#FFFFFF"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#FFFFFF",
            borderWidth: "1px",
        }
    },
    "& .MuiInputBase-root": {
        fontSize: "16px",
        margin: "auto",
        borderRadius: "2px",
        width: '100%',
        background: 'rgb(248, 248, 248)',
        "@media only screen and (max-width: 414px)": {
            fontSize: "14px"
        }
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        borderWidth: "1px",
        borderColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-input": {
        color: "#375280",
        "&::placeholder": {
            color: "#375280",
            "@media only screen and (max-width: 414px)": {
                fontSize: "20px"
            }
        },
        "&:not(:placeholder-shown)": {
            color: "#1E293B"
        }
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "none"
    }
});
const StyledButton1ModelAllDetail = styled(Button)(() => ({
    backgroundColor: '#CCBEB1',
    textTransform: "none",
    height: "45px",
    borderRadius: "2px",
    fontWeight: 500,
    color: "#fff",
    width: "252px",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const StyledButtonModel = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "252px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1Model = styled(Button)(() => ({
    fontWeight: 500,
    color: "#fff",
    width: "252px",
    height: "45px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomStyledTable = styled(TableContainer)({
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px",
    },
    "& .table-all-order-body-typo":{
        color: "#375280", fontSize: '16px', display: "flex", fontFamily: "Lato", fontWeight: 400
    },
    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 30px 15px 30px"
    }
})
const StyledDrawer = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));
const CustomSelect = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const TableHeadAllOrder = styled(TableHead)({
    "& .tableHeaderTxt":{
        fontFamily: "Lato",
        display: "flex",
        whiteSpace: "nowrap"
    },
    '& .MuiTableCell-stickyHeader': {
        backgroundColor: 'white !important'
    }
})
const MuiSelectSelect = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
})
const CustomSelectNewOrder = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const MuiSelectSelectNewOrder = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',
    },
})
// Customizable Area End