import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";
export const configJSON = require("../config");
interface DeliveryAddressAttributesRejected {
  phone_number: string;
  name: string;
  contact_number: string;
  country_code: string;
  city: string;
  zip_code: string;
  block: string;
  house_or_building_number: string;
  street: string;
  area: string;
  is_default: boolean;
  longitude: number;
  address_name: string;
  latitude: number;
}

interface DeliveryAddressRejected {
  id: string;
  attributes: DeliveryAddressAttributesRejected;
  type: string;
}

interface PaymentDetailRejected {
  updated_at: string;
  id: number;
  status: string;
  currency: string;
  created_at: string;
  merchant_id: string | null;
  order_id: string;
  charge_id: string;
  reason: string;
  amount: number;
  order_management_order_id: number;
  account_id: number;
  customer_id: string;
  last_four_card_digit: string;
  seller_order_id: string | null;
  refund_amount: string | null;
  refund_reason: string | null;
  payment_type: string;
  refund_id: string | null;
  deleted: boolean;
}
interface INavigateTo {
  productId: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
interface OrderManagementOrderAttributesRejected {
  account: string;
  placed_at: string;
  order_number: string;
  in_transit_at: string | null;
  total: string;
  sub_total: string;
  refunded_at: string | null;
  confirmed_at: string;
  status: string;
  returned_at: string | null;
  order_status_id: number;
  cancelled_at: string | null;
  delivery_addresses: DeliveryAddressRejected;
  order_deliver_date: string | null;
  deliver_by: string | null;
  created_at: string;
  delivered_at: string | null;
  order_deliver_time: string | null;
  order_return_date: string | null;
  updated_at: string;
  order_return_time: string | null;
  payment_detail: PaymentDetailRejected;
}

interface OrderManagementOrderRejected {
  id: string;
  type: string;
  attributes: OrderManagementOrderAttributesRejected;
}

interface OrderItemAttributesRejected {
  in_transit_at: string | null;
  status: string;
  currency_logo: string;
  placed_at: string;
  shipped_at: string | null;
  confirmed_at: string;
  cancelled_at: string | null;
  delivered_at: string | null;
  process_at: string;
  rejected_at: string | null;
  return_pick_at: string | null;
  reason_of_rejection: string | null;
  return_at: string | null;
  quantity: number;
  return_cancel_at: string | null;
  catalogue_variant_color: string;
  catalogue_variant_sku: string;
  catalogue_variant_side_image: string;
  total_price: string;
  unit_price: string;
  brand_name: string;
  driver_name: string | null;
  store_name: string;
  catalogue_name: string;
  catalogue_variant_back_image: string;
  catalogue_variant_front_image: string;
  driver_longitude: number | null;
  catalogue_variant_size: string;
  otp: string | null;
  driver_phone_number: string | null;
  driver_latitude: number | null;
}

interface OrderItemRejected {
  attributes: OrderItemAttributesRejected;
  id: string;
  type: string;
}
interface SellerOrderAttributesRejected {
  order_management_order: OrderManagementOrderRejected;
  order_items: OrderItemRejected[];
  accept_order_upload_time: string | null;
  id: number;
  status: string;
}

interface SellerOrderRejected {
  type: string;
  id: string;
  attributes: SellerOrderAttributesRejected;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  apiRecall: Function;
  searchAllOrder:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tableAllOrderRejected: SellerOrderRejected[];
  metaData: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  },
  isAlert: boolean,
  page: number;
  short: string[],
  isLoadingInProcess: boolean,
  tableHaderName: string[],
  isLoading1:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersRejectedOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllRejectedOrderCallId: string = "";
  getMy_OrdersCallId: string = "";
  getTimeInProcessOrderCallId: string = "";
  getRejectInProcessOrderCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tableHaderName: ["Order ID", "Order Time", "Products", "Shop", "Total Price", "Status"],
      page: 1,
      metaData: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      },
      tableAllOrderRejected: [],
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      isAlert: false,
      isLoadingInProcess: true,
      isLoading1:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllRejectedOrderCallId !== null &&
      this.getAllRejectedOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonRejectedOrderOrder = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ metaData: responseJsonRejectedOrderOrder?.meta, tableAllOrderRejected: responseJsonRejectedOrderOrder?.data, isLoadingInProcess: false,isLoading1:false, isAlert: responseJsonRejectedOrderOrder.error === "No orders found." ? true : false })
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.allOrderDataRejected()
  }
  componentDidUpdate(prevProps: { searchAllOrder: string; }) {
    if (prevProps.searchAllOrder !== this.props.searchAllOrder) {
      this.setState({isLoading1:true},()=>this.allOrderDataRejected());
    }
  }
  dateFormetTime = (created_at: string) => {
    if (!created_at) return "";
    const orderInProcessDate = new Date(created_at);
    let hoursOrder = orderInProcessDate.getHours();
    const minutesInProcess = orderInProcessDate.getMinutes();
    const ampm = hoursOrder >= 12 ? this.tranSellerRejectedOrder('PM') : this.tranSellerRejectedOrder('AM');
    hoursOrder = hoursOrder % 12;
    hoursOrder = hoursOrder ? hoursOrder : 12;
    const minutesStr = minutesInProcess < 10 ? '0' + minutesInProcess : minutesInProcess;
    return `${hoursOrder}:${minutesStr} ${ampm}`;
  }
  
  allOrderDataRejected = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageRejectedOrderList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllRejectedOrderCallId = requestMessageRejectedOrderList.messageId;
    requestMessageRejectedOrderList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageRejectedOrderList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectedAllOrder}&page=${this.state.page}&per_page=10&search=${this.props.searchAllOrder}`
    );
    requestMessageRejectedOrderList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageRejectedOrderList.id, requestMessageRejectedOrderList);
  }
  navigateTo = ({
    productId,
    props,
    screenName,
  }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
  }

  tranSellerRejectedOrder = (transKey: string) => {
    return i18n.t( transKey, {ns: "orderDetail"})
  }
  // Customizable Area End
}
