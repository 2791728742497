import React from "react";
import { CircularProgress, styled, Box } from "@material-ui/core";

interface ILoaderProps {
  id: string;
  dataTestId: string;
}
const Loader: React.FC<ILoaderProps> = ({ id, dataTestId }) => {
  return (
    <LoaderContainer>
      <CircularProgress
        id={id}
        data-test-id={dataTestId}
        className="loader-color"
      />
    </LoaderContainer>
  );
};

const LoaderContainer = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .loader-color": {
    color: "#375280",
  },
});

export default Loader;
