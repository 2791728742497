import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { product1, product2, product3, product4 } from "../../customform/src/assets";

export const orderInsights = [{
    label: "Sold Units",
    value: 70,
    color: "#34D399"
},
{
    label: "Returned/ Refunded",
    value: 20,
    color: "#F87171", 
},
]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  productOverview: {
    id: number,
    image: string,
    productName: string,
    price: string
  }[];
  exportType: string;
  exportDailog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SalesReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      productOverview: [{
        id: 1,
        image: product1,
        productName: "Black Leather Boots",
        price: "$24,000"
      },
      {
        id: 2,
        image: product2,
        productName: "Cream Formal Pencil Skirt",
        price: "$20,000"
      },
      {
        id: 3,
        image: product3,
        productName: "Oversized Tee with Print",
        price: "$4,000"
      },
      {
        id: 4,
        image: product4,
        productName: "Brown Leather Analog Watch",
        price: "$12,000"
      },{
        id: 5,
        image: product1,
        productName: "Black Leather Boots",
        price: "$24,000"
      }, {
        id: 6,
        image: product3,
        productName: "Oversized Tee with Print",
        price: "$4,000"
      }],
      exportType: "PDF",
      exportDailog: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 

  onChangeLanguage = (value: string) => {
    this.setState({ exportType: value });
};

handleExportDialogClose = () => {
  this.setState({
    exportDailog: false
  });
};

handleExportDialogOpen = () => {
  this.setState({
    exportDailog: true
  });
}
  // Customizable Area End
}
