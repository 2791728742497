import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  // Customizable Area Start
  neckbase: string;
  shoulderfront: string;
  shoulderback: string;
  chest: string;
  bicep: string;
  waist: string;
  highhip: string;
  lowhip: string;
  upperthigh: string;
  lowerthigh: string;
  knee: string;
  calf: string;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MeasurementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleInputChange = this.handleInputChange.bind(this);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "A",
      // Customizable Area Start
      neckbase: '',
      shoulderfront: '',
      shoulderback: '',
      chest: '',
      bicep: '',
      waist: '',
      highhip: '',
      lowhip: '',
      upperthigh: '',
      lowerthigh: '',
      knee: '',
      calf: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  handleNavigation = (name: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), name);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;

    if (name in this.state) {
      this.setState({
        [name]: value
      } as unknown as Pick<S, keyof S>);
    }
  };
  handleResetUpperBody = () => {
    this.setState({ neckbase: '', shoulderfront: '', shoulderback: '', chest: '', bicep: '', waist: '', highhip: '' })
  };

  handleResetLowerBody = () => {
    this.setState({ lowhip: '', upperthigh: '', lowerthigh: '', knee: '', calf: '' })
  };

  handleUpperBodyFormSubmit = () => {
    this.handleResetUpperBody();
    this.handleNavigation('LowerBodyMeasurement')
  };

  handleLowerBodyFormSubmit = () => {
    this.handleResetLowerBody();
    this.handleNavigation('TryItOn')
  };

  // Customizable Area End
}
