import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerCataloguesController, {
  Props,
} from "./SellerCataloguesController";
import {
  Grid,
  Box,
  Typography,
  styled,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import SellerSidebar from "../../../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../../../components/src/Seller/SellerHeader";
import { backBtnIcon, selectEndIcon } from "../../assets";
import {
  popularProduct,
  SellerStoreHeader,
} from "../AnalyticsInsights/SellerAnalyticsInsights.web";
import { SellerStoreBodyContainer } from "../Revenue/SellerRevenue.web";
import SnackbarBar from "../../../../../components/src/AlertContainer";
import Loader from "../../../../../components/src/LoaderContainer";
import {
  CustomStyledPagination,
  PaginationContainer,
} from "../InventoryManagement/UpdateInventory/UpdateInventory.web";
import i18n from "../../../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class SellerCatalogues extends SellerCataloguesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleCatalogueBackCSS = () => {
    return i18n.dir()==='ltr'? "rotate(0deg)" : "rotate(180deg)";
  }
  renderStep1 = () => (
    <>
      {this.state.isLoading ? (
        <LoaderDiv><Loader id="step-1-loader" dataTestId="step-1-loader-test" /></LoaderDiv>
      ) : (
        <>{this.state.responseCategoryData.length===0 ? <LoaderDiv>
        <Typography className="no-data-found">{this.tranCatalogue("Store has no products.")}</Typography>
      </LoaderDiv> : <Grid container spacing={4} style={{ margin: "30px 0px 0px 5px" }}>
          {this.state.responseCategoryData.map((item) => (
            <Grid item key={item.id}>
              <CategoryBoxContainer
                onClick={() => {
                  this.handleChange(+item.id);
                }}
                data-test-id={`catalogues-${item.attributes.id}`}
              >
                <img
                  className="catalog-img"
                  src={item.attributes.image}
                  alt={item.attributes.name}
                />
                <Typography className="catalog-title">
                  {item.attributes.name}
                </Typography>
              </CategoryBoxContainer>
            </Grid>
          ))}
        </Grid>
      }</>
      )}
    </>
  );

  renderStep2 = () => (
    <>
      {this.state.isLoading ? (
        <Loader id="step-2-loader" dataTestId="step-2-loader-test" />
      ) : (
        <>
          {this.state.responseSubCategoryData.map((item) => (
            <AccordionContainer
              key={item.id}
              defaultExpanded={
                item.id === this.state.defaultAccordionOpen.toString()
              }
              onChange={() => this.handleAccordionToggle(+item.id)}
            >
              <AccordionSummary
                expandIcon={<img src={selectEndIcon} alt="end-icon" />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-header"
              >
                <Typography className="accordion-title">
                  {item.attributes.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {this.state.isLoadingInside ? (
                    <Loader
                      id="step-2-loader"
                      dataTestId="step-2-loader-test"
                    />
                  ) : (
                    <>
                      {this.state.responseSubSubCategoryData.map((product) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                          <Box
                            data-test-id={`product-${+product.id}`}
                            onClick={() =>
                              this.handleProductClick(
                                product.attributes.name,
                                +product.id
                              )
                            }
                            className="accordion-details"
                          >
                            <img
                              className="product-img"
                              src={product.attributes.image}
                              alt="product1"
                            />
                            <Typography className="product-name">
                              {product.attributes.name}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </AccordionContainer>
          ))}
        </>
      )}
    </>
  );

  renderStep3 = () => (
    <>
      <ProductContainer>
        {this.state.isLoading ? (
          <div style={{ height: 200 }}>
            <Loader id="step-3-loader" dataTestId="step-2-loader-test" />
          </div>
        ) : (
          <>
            {this.state.cataloguesData.map((item) => (
              <Box className="product-container" key={item.id}>
                <Box className="product-detail-container">
                  <img
                    src={item.primary_image}
                    className="img-width"
                    alt={item.name}
                  />
                  <Box className="product-detail">
                    <Typography className="product-name">
                      {item.name}
                    </Typography>
                    <Typography className="product-order">
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
                <Typography className="product-price">
                  $ {item.primary_price}
                </Typography>
              </Box>
            ))}
          </>
        )}
      </ProductContainer>
      {this.state.cataloguesMeta.total_record !== null &&
      this.state.cataloguesMeta.total_record > 10 ? (
        this.renderPagination()
      ) : (
        <></>
      )}
    </>
  );

  renderPagination = () => (
    <>
      {this.state.cataloguesMeta !== undefined ? (
        <PaginationContainer>
          <Box className="pagination-data-container">
            {this.state.cataloguesMeta.current_page !== null
              ? (this.state.cataloguesMeta.current_page - 1) * 10 + 1
              : ""}{" "}
            -{" "}
            {this.state.cataloguesMeta.current_page !== null &&
            this.state.cataloguesMeta.total_record
              ? Math.min(
                  this.state.cataloguesMeta.current_page * 10,
                  this.state.cataloguesMeta?.total_record
                )
              : ""}{" "}
            {this.tranCatalogue("of")} {this.state.cataloguesMeta.total_record} {this.tranCatalogue("results")}
          </Box>
          <CustomStyledPagination
            page={
              this.state.cataloguesMeta.current_page !== null
                ? this.state.cataloguesMeta.current_page
                : 1
            }
            count={
              this.state.cataloguesMeta.total_pages !== null
                ? this.state.cataloguesMeta.total_pages
                : 1
            }
            shape="rounded"
            color="primary"
            onChange={this.handleCataloguePageChange}
            dir={i18n.dir()}
          />
        </PaginationContainer>
      ) : (
        <></>
      )}
    </>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container 
            dir={i18n.dir()} className="grid-seller-store-catalogues">
          <Grid
            lg={2}
            item
            className="sidebar-seller-store-catalogues"
            xl={2}
            md={3}
            xs={3}
            sm={3}
          >
            <SellerSidebar
              navigation={this.props.navigation}
              className="sidebar-seller-store-catalogues"
            />
          </Grid>
          <Grid
            lg={10}
            item
            className="header-seller-store-catalogues"
            xl={10}
            md={9}
            xs={9}
            sm={9}
          >
            <SellerHeader navigation={this.props.navigation} />
            <SellerStoreBodyContainer>
              <SellerStoreHeader>
                <Box className="title-container">
                  <img
                    src={backBtnIcon}
                    data-test-id="back-btn-test-id"
                    alt="backIcon"
                    style={{transform: this.handleCatalogueBackCSS()}}
                    onClick={() => this.handleBack()}
                  />
                  <Typography id="revenue-title" className="title">
                    {this.state.activeStep === 3
                      ? `${this.state.productNameTitle}`
                      : this.tranCatalogue("Catalogues")}
                  </Typography>
                  {this.state.activeStep === 3 && (
                    <Typography id="category-title" className="second-title">
                      {`[Total Products ${
                        this.state.cataloguesMeta.total_record !== undefined
                          ? this.state.cataloguesMeta.total_record
                          : ""
                      }]`}
                    </Typography>
                  )}
                </Box>
              </SellerStoreHeader>
              {this.state.activeStep === 1 && this.renderStep1()}
              {this.state.activeStep === 2 && (
                <>
                  <TabCatalogWrapBox className="newValue">
                    <Tabs
                      value={this.state.activeTab}
                      onChange={(event, newValue) =>
                        this.handleTabChange(event, newValue)
                      }
                      scrollButtons="auto"
                      TabIndicatorProps={{
                        style: { ...webStyle.tabs},
                      }}
                      style={{
                        overflowX: "auto",
                        width: "fit-content",
                      }}
                      data-test-id="tab-test"
                    >
                      {this.state.responseCategoryData.map((item: any) => (
                        <Tab
                          data-test-id={item.attributes.name}
                          label={item.attributes.name}
                          key={item.id}
                          style={{
                            ...webStyle.allTab,
                            textTransform: "none",
                            display: "flex",
                          }}
                          value={item.attributes.id}
                        />
                      ))}
                    </Tabs>
                  </TabCatalogWrapBox>
                  {this.renderStep2()}
                </>
              )}
              {this.state.activeStep === 3 && this.renderStep3()}
            </SellerStoreBodyContainer>
          </Grid>
        </Grid>
        <SnackbarBar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertBarClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  tabs: {
    backgroundColor: "#375280",
    borderBottom: "1px solid #E2E8F0",
    weight: "20px",
  },
  allTab: {
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 700,
    minWidth: "110px",
  },
};
const TabCatalogWrapBox = styled(Box)({
  width: "100%",
  overflowX: "auto",
  "& .MuiTabs-flexContainer": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #e3e8f0",
    width: "max-content",
    marginLeft: 5,
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#375280",
      borderBottom: "3px solid #375280",
      padding: "0px 20px"
    },
    "& .MuiTab-textColorInherit": {
      color: "#94A3B8",
      padding: "0px 20px"
    },
  },
});

const AccordionContainer = styled(Accordion)({
  boxShadow: "none",
  borderRadius: 2,
  border: "1px solid #E2E8F0",
  marginTop: "20px !important",
  "&:before": {
    backgroundColor: "transparent",
  },
  "& .MuiAccordionSummary-root": {
    "& .MuiAccordionSummary-content": {
      "& .accordion-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 20,
        color: "#375280",
      },
    },
  },
  "& .accordion-details": {
    display: "flex",
    gap: 25,
    padding: 5,
    backgroundColor: "#F1F5F9",
    borderRadius: 2,
    alignItems: "center",
    "& .product-img": {
      width: 60,
      aspectRatio: 1,
    },
    "& .product-name": {
      fontFamily: "Lato, sans-serif",
      fontSize: 16,
      color: "#375280",
    },
  },
});

const ProductContainer = styled(Box)({
  borderRadius: 4,
  border: "1px solid #E2E8F0",
  padding: 20,
  marginTop: 20,
  "& .product-container": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
    width: "100%",
    padding: "16px 0px",
    "& .product-price": {
      fontFamily: "Lato, sans-serif",
      color: "#059669",
      fontWeight: 700,
      fontSize: 18,
    },
    "& .product-detail-container": {
      display: "flex",
      gap: 20,
      "& .img-width": {
        width: 80,
        height: 80,
        objectFit: "cover",
      },
      "& .product-detail": {
        display: "flex",
        flexDirection: "column",
        fontSize: 18,
        color: "#375280",
        gap: 15,
        fontFamily: "Lato, sans-serif",
        "& .product-order": {
          fontWeight: 700,
        },
        "& .product-name": {
          fontWeight: 500,
        },
      },
    },
  },
});

const CategoryBoxContainer = styled(Box)({
  width: 260,
  height: 260,
  position: "relative",
  "& .catalog-img": {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  "& .catalog-title": {
    position: "absolute",
    fontFamily: "Lato, sans-serif",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
});

const LoaderDiv = styled(Box)({
  width: "100%",
  height: 460,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .no-data-found": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 500,
    color: "#375280",
    fontSize: "28px",
  },
});
// Customizable Area End
