import React from "react";
// Customizable Area Start
import { Typography, Box } from "@material-ui/core";
import i18n from "../../../components/src/i18next/i18n";

interface Props {
  activeBread: "cart" | "address" | "payment";
  transStepLang: (tkey: string) => string;
}
// Customizable Area End

const CheckoutSteps: React.FC<Props> = ({ activeBread, transStepLang }) => {
  // Customizable Area Start
  const breads = [
    { value: "cart", label: "Your Cart", title: "Your Cart" },
    { value: "address", label: "Address", title: "Save Address" },
    { value: "payment", label: "Payment", title: "Payments" },
  ];
  return (
    <Box style={{ backgroundColor: "#F8F8F8" }} dir={i18n.dir()}>
      <Box style={webStyle.breadDiv}>
        {breads.map((bread, number) => (
          <React.Fragment key={bread.value}>
            <Typography
              style={
                activeBread === bread.value
                  ? webStyle.activeBread
                  : webStyle.breads
              }
            >
              {transStepLang(bread.label)}
            </Typography>
            {number !== breads.length - 1 && <Box style={webStyle.dess} />}
          </React.Fragment>
        ))}
      </Box>
      <Typography style={webStyle.title}>
        {transStepLang(breads.find((bread) => bread.value === activeBread)!.title)}
      </Typography>
      <Box style={webStyle.titleLine} />
    </Box>
  );
  // Customizable Area Start
};

const webStyle = {
  breadDiv: {
    display: "flex",
    justifyContent: "center",
    margin: "1.25rem auto 3rem",
    gap: "2rem",
    alignItems: "center",
  },
  activeBread: {
    borderBottom: "1px solid #375280",
    fontWeight: 700,
    fontSize: "1rem",
    fontFamily: "Lato",
  },
  breads: {
    color: "#94A3B8",
    fontWeight: 700,
    fontSize: "1rem",
    fontFamily: "Lato",
  },
  dess: { borderBottom: "1px dashed #375280", width: "123px" },
  title: {
    display: "flex",
    justifyContent: "center",
    fontWeight: 700,
    fontSize: "2rem",
    fontFamily: "Lato",
    marginBottom: "1.25rem",
  },
  titleLine: {
    margin: "0 auto",
    width: "100px",
    border: "2px solid #CCBEB1",
    marginBottom: "2.25rem",
  },
};

export default CheckoutSteps;
