import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
import {
  ErrorMessage,
  ErrorResp,
  IImgResp,
  IMyOfferDataResp,
  MetaResponse,
} from "./StylishMyOfferController";
import { logoutSellerNavigation } from "../../../../components/src/Seller/logOut";
import { apiCall } from "../../../../components/src/APICall";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";

interface IProductSourcingStylistRequest {
  id: number;
  request_id: number;
  account_id: number;
  quote_price: number;
  product_description: string;
  request_status: string;
  created_at: string;
  updated_at: string;
  full_name: string;
}

interface IListResp {
  id: string;
  type: string;
  attributes: {
    id: number;
    product_name: string;
    currency_logo: string;
    request_status: string;
    max_price: string;
    min_price: string;
    sizes: string[];
    description: string;
    gender: string;
    quantity: string;
    colours: string[];
    images: IImgResp[];
    total_stylist_offer_requests: 1;
    product_sourcing_stylist_prices: IProductSourcingStylistRequest[];
  };
}

interface IStylistProductSourcingResp {
  data: IListResp[];
  meta: MetaResponse;
}

export interface ISuccessBidResp {
  data: IMyOfferDataResp;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  listData: IListResp[];
  popUpData: IListResp;
  isListPopup: boolean;
  isPlaceOfferPopup: boolean;
  selectedFiles: File[];
  productDescription: string;
  quotePrice: string;
  errorState: {
    isProductDescError: boolean;
    productDescErrorMessage: string;
    isQuotePriceError: boolean;
    quotePriceErrorMessage: string;
    isAttachmentError: boolean;
    attachmentErrorMessage: string;
  };
  isAlert: boolean;
  message: string;
  severity: "error" | "success";
  isLoading: boolean;
  page: number;
  metaSPSResp: MetaResponse;
  currencySign: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylishProductSourcingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductSourcingListAPICallId: string = "";
  addProductSourcingBidAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      listData: [],
      popUpData: {} as IListResp,
      isListPopup: false,
      isPlaceOfferPopup: false,
      selectedFiles: [],
      productDescription: "",
      quotePrice: "",
      currencySign: "$",
      errorState: {
        isProductDescError: false,
        productDescErrorMessage: "",
        isQuotePriceError: false,
        quotePriceErrorMessage: "",
        isAttachmentError: false,
        attachmentErrorMessage: "",
      },
      isAlert: false,
      message: "",
      severity: "error",
      isLoading: true,
      page: 1,
      metaSPSResp: {} as MetaResponse,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonPSData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseJsonPSData &&
        !responseJsonPSData.errors &&
        !responseJsonPSData.error
      ) {
        this.apiStylistProductSourcingSuccess(
          apiRequestCallId,
          responseJsonPSData
        );
      } else if (
        responseJsonPSData &&
        (responseJsonPSData.errors || responseJsonPSData.error)
      ) {
        this.apiStylistProductSourcingfailer(responseJsonPSData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  apiStylistProductSourcingSuccess = async (
    apiRequestCallId: string,
    responseJson: IStylistProductSourcingResp & ISuccessBidResp
  ) => {
    if (apiRequestCallId === this.getProductSourcingListAPICallId) {
      if (responseJson)
        this.setState((prevState) => ({
          ...prevState,
          listData: responseJson.data,
          metaSPSResp: responseJson.meta,
          isLoading: !prevState.isLoading,
        }));
        {responseJson?.data?.length > 0 && this.setState({currencySign: responseJson.data[0]?.attributes?.currency_logo})}
    } else if (apiRequestCallId === this.addProductSourcingBidAPICallId) {
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true,
        severity: "success",
        message: this.transStylishProductSourcing("Your Bid Request has been created Successfully."),
      }));
      this.handlePlaceOfferPopupClose();
      await setStorageData("bid-request-id", +responseJson.data.id);
      setTimeout(() => {
        this.handleProductSourcingRedirect("StylishProductSourcingRequest");
      }, 2000);
    }
  };

  apiStylistProductSourcingfailer = async (
    responseJsonPSError: ErrorMessage & ErrorResp
  ) => {
    if (responseJsonPSError.error === "you're not an authorized person!") {
      await this.handleMainPage(responseJsonPSError.error);
    } else if (responseJsonPSError.error) {
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true,
        severity: "error",
        message: responseJsonPSError.error,
        isLoading: false,
      }));
    } else if (responseJsonPSError.errors[0].token) {
      await this.handleMainPage(responseJsonPSError.errors[0].token);
    } else {
      const errors = responseJsonPSError.errors;

      let errorQuotePrice = "",
        errorProductDesp = "",
        errorImages = "";

      let isQPError = false,
        isPDError = false,
        isImgError = false;

      if (errors.some((err) => err.quote_price)) {
        errorQuotePrice = this.transStylishProductSourcing("* Please enter a quote price");
        isQPError = true;
      }

      if (errors.some((err) => err.product_description)) {
        errorProductDesp = this.transStylishProductSourcing("* Please enter your product description");
        isPDError = true;
      }

      if (errors.some((err) => err.images)) {
        errorImages = this.transStylishProductSourcing("* Please select at least one attachment");
        isImgError = true;
      }

      this.setState((prevState) => ({
        ...prevState,
        errorState: {
          ...prevState.errorState,
          isProductDescError: isPDError,
          productDescErrorMessage: errorProductDesp,
          isQuotePriceError: isQPError,
          quotePriceErrorMessage: errorQuotePrice,
          isAttachmentError: isImgError,
          attachmentErrorMessage: errorImages,
        },
      }));
    }
  };

  handleMainPage = async (message: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        isAlert: true,
        severity: "error",
        message: message,
        isLoading: false,
      }),
      () => {
        setTimeout(() => {
          this.handleProductSourcingRedirect("Home");
          logoutSellerNavigation();
        }, 2000);
      }
    );
  };

  async componentDidMount() {
    // Customizable Area Start
    this.getProductSourcingListAPICalled();
    // Customizable Area End
  }

  handleProductSourcingRedirect = (path: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  handleViewClick = (id: string) => {
    const selectedData = this.state.listData.find((item) => item.id === id);
    this.setState((prevState) => ({
      ...prevState,
      popUpData: selectedData || ({} as IListResp),
      isListPopup: !prevState.isListPopup,
    }));
  };

  handleListPopupClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isListPopup: !prevState.isListPopup,
    }));
  };

  handlePlaceOfferPopupClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPlaceOfferPopup: !prevState.isPlaceOfferPopup,
      selectedFiles: [],
      errorState: {
        ...prevState.errorState,
        isProductDescError: false,
        productDescErrorMessage: "",
        isQuotePriceError: false,
        quotePriceErrorMessage: "",
        isAttachmentError: false,
        attachmentErrorMessage: "",
      },
      productDescription: "",
      quotePrice: "",
      isListPopup: false,
    }));
  };

  handlePlaceOfferPopupOpen = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPlaceOfferPopup: true,
    }));
  };

  handleUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      const invalidFiles: File[] = [];

      newFiles.forEach((file) => {
        const isValidType = /\.(jpg|jpeg|png)$/i.test(file.name);
        const isValidSize = file.size <= 10 * 1024 * 1024;

        if (!isValidType || !isValidSize) {
          invalidFiles.push(file);
        }
      });

      if (invalidFiles.length > 0) {
        let errorMessage = "";

        invalidFiles.forEach((file) => {
          const isValidType = /\.(jpg|jpeg|png)$/i.test(file.name);
          const isValidSize = file.size <= 10 * 1024 * 1024;
          if (!isValidType) {
            errorMessage += `${file.name} ${this.transStylishProductSourcing("has an invalid file type.")} `;
          } else if (!isValidSize) {
            errorMessage += `${file.name} ${this.transStylishProductSourcing("exceeds the 10MB size limit.")} `;
          }
        });

        this.setState((prevState) => ({
          ...prevState,
          errorState: {
            ...prevState.errorState,
            isAttachmentError: true,
            attachmentErrorMessage: errorMessage,
          },
        }));
        return;
      }

      if (this.state.selectedFiles.length + newFiles.length > 5) {
        this.setState((prevState) => ({
          ...prevState,
          errorState: {
            ...prevState.errorState,
            isAttachmentError: true,
            attachmentErrorMessage: this.transStylishProductSourcing("You can only upload up to 5 files"),
          },
        }));
        return;
      }
      this.setState((prevState) => ({
        ...prevState,
        selectedFiles: [...prevState.selectedFiles, ...newFiles],
        errorState: {
          ...prevState.errorState,
          isAttachmentError: false,
          attachmentErrorMessage: "",
        },
      }));
    }
  };

  handleRemoveFile = (index: number) => {
    this.setState((prevState) => ({
      ...prevState,
      selectedFiles: prevState.selectedFiles.filter((_, i) => i !== index),
    }));
  };

  validateSubmit = async () => {
    const {
      productDescription,
      quotePrice,
      selectedFiles,
      popUpData,
    } = this.state;
    const updatedQuotePrice = +quotePrice.replace("$", "");
    if (
      ((productDescription === "" ||
        quotePrice === "" ||
        quotePrice === "$" ||
        selectedFiles.length === 0) ||
        updatedQuotePrice > +popUpData.attributes.max_price) ||
      updatedQuotePrice < +popUpData.attributes.min_price
    ) {
      if (productDescription === "") {
        this.validateDescription();
      }
      if (
        quotePrice === "" ||
        quotePrice === "$" ||
        +popUpData.attributes.max_price < updatedQuotePrice ||
        updatedQuotePrice < +popUpData.attributes.min_price
      ) {
        this.validQuotePrice();
      }
      if (selectedFiles.length === 0) {
        this.validFiles();
      }
      return true;
    } else {
      return false;
    }
  };

  validateDescription = () => {
    this.setState((prevState) => ({
      ...prevState,
      errorState: {
        ...prevState.errorState,
        isProductDescError: true,
        productDescErrorMessage: this.transStylishProductSourcing("* Please enter your product description"),
      },
    }));
  };

  validQuotePrice = () => {
    const { popUpData, quotePrice } = this.state;
    const updatedQuotePrice = +quotePrice.replace("$", "");
    if (+popUpData.attributes.max_price < updatedQuotePrice) {
      this.setState((prevState) => ({
        ...prevState,
        errorState: {
          ...prevState.errorState,
          isQuotePriceError: true,
          quotePriceErrorMessage:
            this.transStylishProductSourcing("* Quote price must be less than maximum price"),
        },
      }));
    } else if (updatedQuotePrice!==0 && updatedQuotePrice < +popUpData.attributes.min_price) {
      this.setState((prevState) => ({
        ...prevState,
        errorState: {
          ...prevState.errorState,
          isQuotePriceError: true,
          quotePriceErrorMessage:
            this.transStylishProductSourcing("* Quote price must be greater than minimum price"),
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorState: {
          ...prevState.errorState,
          isQuotePriceError: true,
          quotePriceErrorMessage: this.transStylishProductSourcing("* Please enter a quote price"),
        },
      }));
    }
  };

  validFiles = () => {
    this.setState((prevState) => ({
      ...prevState,
      errorState: {
        ...prevState.errorState,
        isAttachmentError: true,
        attachmentErrorMessage: this.transStylishProductSourcing("* Please select at least one attachment"),
      },
    }));
  };

  handleProductDesc = (value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      productDescription: value,
      errorState: {
        ...prevState.errorState,
        isProductDescError: false,
        productDescErrorMessage: "",
      },
    }));
  };

  handleQuotePrice = (value: string) => {
    const price = this.handlePriceCheckNumber(value);
    this.setState((prevState) => ({
      ...prevState,
      quotePrice: price,
      errorState: {
        ...prevState.errorState,
        isQuotePriceError: false,
        quotePriceErrorMessage: "",
      },
    }));
  };

  handlePriceCheckNumber = (value: string) => {
    let numericValue = value.replace(/[^0-9.]/g, "");

    if (numericValue.includes(".")) {
      let [integerPart, decimalPart] = numericValue.split(".");
      if (integerPart.length > 6) {
        integerPart = integerPart.substring(0, 6);
      }
      decimalPart = decimalPart.substring(0, 2);
      numericValue = `${integerPart}.${decimalPart}`;
    } else {
      if (numericValue.length > 6) {
        numericValue = numericValue.substring(0, 6);
      }
    }

    if (
      numericValue === "" ||
      numericValue === "0" ||
      numericValue === "0." ||
      numericValue === "."
    ) {
      return "$";
    }

    return `$${numericValue}`;
  };

  onAlertPSSnackClose = () => {
    this.setState((prevState) => ({ ...prevState, isAlert: false }));
  };

  handlePlaceOfferSubmit = async () => {
    const isValid = await this.validateSubmit();
    if (isValid) {
      return;
    }
    this.addProductSourcingBidAPICalled();
  };

  addProductSourcingBidAPICalled = async () => {
    const {
      popUpData,
      quotePrice,
      productDescription,
      selectedFiles,
    } = this.state;
    const formData = new FormData();
    formData.append("product_description", productDescription.trim());
    formData.append("quote_price", quotePrice.replace(/[^0-9.]/g, ""));
    if (popUpData?.id) formData.append("request_id", popUpData.id);
    if (selectedFiles.length > 0 && selectedFiles.length <= 5) {
      selectedFiles.forEach((file) => {
        formData.append("images[]", file);
      });
    }
    this.addProductSourcingBidAPICallId = await apiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.addProductSourcingBidAPIEndpoint,
      token: await this.getToken("auth-token"),
      body: formData,
      isJsonStringify: false,
    });
  };

  getProductSourcingListAPICalled = async () => {
    const { page } = this.state;
    this.getProductSourcingListAPICallId = await apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getProductSourcingAPIEndpoint}?page=${page}&per_page=10`,
      token: await this.getToken("auth-token"),
    });
  };

  getToken = async (value: string) => {
    return await getStorageData(value);
  };

  handlePSPageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    event.preventDefault();
    this.setState(
      (prevState) => ({
        ...prevState,
        metaSPSResp: { ...prevState.metaSPSResp, current_page: newPage },
        page: newPage,
        isLoading: !prevState.isLoading,
      }),
      () => this.getProductSourcingListAPICalled()
    );
  };

  transStylishProductSourcing = (transKey: string) => {
    return i18n.t( transKey, {ns: "catalogue"})
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
