import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Tabs,
    Tab,
    Switch,
    IconButton,
    CircularProgress,
    Button, Modal, Backdrop, Fade
} from "@material-ui/core";
import { button_arrowrit, button_arrow } from "./assets";
import { styled } from "@material-ui/core/styles";
import SellerSideBar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import { MoreVert } from '@material-ui/icons';
import ReusableSnackbar from "../../../components/src/AlertContainer";
import SellerOffersDiscountsCreatewebController, {
    Props,
} from "./SellerOffersDiscountsCreatewebController";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export default class SellerOffersDiscountsCreateWebPage extends SellerOffersDiscountsCreatewebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleSODCreateImgCSS = () =>{
        return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
    }

    handleTDImgCSS = () => {
        return i18n.dir()==='ltr' ? {right: 10} : {left: 10 }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Grid container dir={i18n.dir()}>
                    <Grid item md={2} sm={2} xs={2} lg={2} xl={2} data-testid="navigation">
                        <SellerSideBar navigation={this.props.navigation} />
                    </Grid>
                    <Grid data-testid="pointer" item md={10} xl={10} xs={10} sm={10} lg={10}  >
                        <SellerHeader navigation={this.props.navigation} />
                        <Box style={{ margin: "30px" }}>
                            <Box style={{ display: 'flex', cursor: 'pointer' }}><img data-testid="sellerOffersDiscounts" width="32px" height="32px" src={button_arrow} style={{transform: this.handleSODCreateImgCSS()}} onClick={this.sellerOffersDiscounts} />&nbsp;&nbsp;&nbsp;<Typography style={webStyle.lableText}>{this.tranSellerOfferDiscountCreate("Offers and Discounts")}</Typography></Box>
                            <Box>
                                <TabWrapBox className="newValue" >
                                    <Tabs
                                        value={this.state.value}
                                        onChange={(event, newValue) => this.handleTabChange(event, newValue)}
                                        scrollButtons="auto"
                                        TabIndicatorProps={{ style: { ...webStyle.tabs } }}
                                    >
                                        <Tab data-testid="CreateOffer" label={this.tranSellerOfferDiscountCreate("Create Offer")} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                        <Tab data-testid="TrackOffer" label={this.tranSellerOfferDiscountCreate("Track Offer")} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                    </Tabs>
                                </TabWrapBox>
                            </Box>
                            {this.state.value === 0 && (
                                <>
                                    <Box data-testid="CreateOfferContent" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Box style={{ backgroundColor: 'rgb(248, 248, 248)', padding: '20px', marginBottom: '30px' }}>
                                                    <Box style={webStyle.haderBox}>
                                                        <Typography style={webStyle.numberBox}>{this.tranSellerOfferDiscountCreate("Create your offer for stores")}</Typography>
                                                        <img width="32px" data-testid="sellerOffersAccount" onClick={this.sellerOffersAccount} style={{ cursor: 'pointer', transform: this.handleSODCreateImgCSS() }} height="32px" src={button_arrowrit} />
                                                    </Box>
                                                    <Typography style={webStyle.createText}>{this.tranSellerOfferDiscountCreate("Create best offer to get more orders")}</Typography>
                                                    <Button onClick={this.sellerOffersAccount} style={{ ...webStyle.twoButton, textTransform: 'capitalize' }} data-testid="sellerOffersAccount">{this.tranSellerOfferDiscountCreate("Create Offer")}</Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box style={{ backgroundColor: 'rgb(248, 248, 248)', padding: '20px', marginBottom: '30px' }}>
                                                    <Box style={webStyle.haderBox}>
                                                        <Typography style={webStyle.numberBox}>{this.tranSellerOfferDiscountCreate("Create your offer for products")} </Typography>
                                                        <img width="32px" onClick={this.sellerOffersAccount1} data-testid="sellerOffersAccount1" height="32px" style={{transform: this.handleSODCreateImgCSS()}} src={button_arrowrit} />
                                                    </Box>
                                                    <Typography style={webStyle.createText}>{this.tranSellerOfferDiscountCreate("Create best offer to get more orders")}</Typography>
                                                    <Button style={{ ...webStyle.twoButton, textTransform: 'capitalize', cursor: 'pointer' }} data-testid="sellerOffersAccount1" onClick={this.sellerOffersAccount1}>{this.tranSellerOfferDiscountCreate("Create Offer")}</Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </>
                            )}
                            {this.state.value === 1 && (
                                <Box data-testid="TrackOfferContent" style={{ marginTop: '30px' }}>
                                    {!this.state.tabeLoder && (
                                        <Box data-testid="loder" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}>
                                            <CircularProgress style={{ color: '#375280' }} />
                                        </Box>
                                    )}
                                    <Grid container spacing={3}>
                                        {this.state.tabeLoder && this.state.promoCards && this.state.promoCards.map((item, index) => (
                                            <>
                                                <Grid item xs={6}>
                                                    <Box key={index}
                                                        sx={{
                                                            padding: "20px",
                                                            borderRadius: "5px",
                                                            boxShadow: "0px 2px 8px 0px #00000014",
                                                            position: "relative",
                                                            marginBottom: "20px"
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}>
                                                            <IconButton
                                                                aria-label="more options"
                                                                style={{ textAlign: "right", position: "absolute", top: "10px", color: '#375280', ...this.handleTDImgCSS() }}
                                                                data-testid="handleMenuOpen"
                                                                onClick={() => this.handleOpenMenu(item.id)}
                                                            >
                                                                <MoreVert />
                                                            </IconButton>
                                                        </Box>
                                                        {this.state.anchor === item.id &&
                                                            <Box
                                                                style={{ ...webStyle.menuOpen, position: "absolute", ...this.handleTDImgCSS()}}
                                                            >
                                                                <Typography style={{ ...webStyle.menuMack, cursor: 'pointer' }} data-testid={`SellerOffersStoreFormWebPageEdite-${item.attributes.id}`} onClick={() => this.sellerOffersEditeAccount(item.attributes.id)}>
                                                                    {this.tranSellerOfferDiscountCreate("Edit Offer")}
                                                                </Typography>
                                                                <Typography data-testid={`handleMenuOpenCard=${item.attributes.id}`}
                                                                    style={webStyle.menuDelete} onClick={() => this.handleOpen(item.attributes.id)}
                                                                >
                                                                    {this.tranSellerOfferDiscountCreate("Delete Offer")}
                                                                </Typography>
                                                            </Box>}
                                                        <Box style={{ display: 'flex', marginBottom: '11px', gap: 16 }}>
                                                            <Box style={{ fontSize: '20px', fontFamily: 'Lato', fontWeight: 700, color: '#375280', textTransform: 'uppercase' }}>{item.attributes.code}</Box>
                                                            <AntSwitch
                                                                checked={item.attributes.isActive}
                                                                name="checked"
                                                                inputProps={{ 'data-testid': 'handleStoreStatusUpdate' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                                                    'aria-label'?: string;
                                                                    'data-testid'?: string;
                                                                }}
                                                                onChange={() => this.handleStoreStatusUpdate(item.attributes.isActive, item.attributes.id)}
                                                            />
                                                        </Box>
                                                        <Box style={{ display: 'flex', marginBottom: '11px', gap: 10 }}>
                                                            <Typography style={{ fontSize: '16px', fontFamily: 'Lato', fontWeight: 400, color: '#94A3B8' }}>{this.tranSellerOfferDiscountCreate("Minimum Order Value")}</Typography>
                                                            <Typography style={{ fontSize: '16px', fontFamily: 'Lato', fontWeight: 700, color: '#375280' }}>${item.attributes.min_cart_value}</Typography>
                                                        </Box>
                                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Box style={{ display: 'flex', marginBottom: '11px', gap:10}}>
                                                                <Typography style={{ fontSize: '16px', fontFamily: 'Lato', fontWeight: 400, color: '#94A3B8'}}>{this.tranSellerOfferDiscountCreate("Campaign Start Date")}</Typography>
                                                                <Typography style={{ fontSize: '16px', fontFamily: 'Lato', fontWeight: 700, color: '#375280' }}>{this.formatDate(item.attributes.valid_from)}</Typography>
                                                            </Box>
                                                            <Box style={{ fontSize: '20px', fontFamily: 'Lato', fontWeight: 700, color: '#375280' }}>{item.attributes.discount}% OFF</Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </>
                                        ))}
                                    </Grid>
                                </Box>
                            )}
                        </Box>
                        <StyledDrawer
                            open={this.state.visible}
                            aria-describedby="transition-modal-description"
                            closeAfterTransition
                            aria-labelledby="transition-modal-title"
                            data-testid="handleCancel"
                            onClose={this.handleCancel}
                            dir={i18n.dir()}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            BackdropComponent={Backdrop}
                        >
                            <Fade in={this.state.visible} style={webStyle.febBox}>
                                <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                                    <h2 id="transition-modal-title" style={webStyle.modelText}>{this.tranSellerOfferDiscountCreate("Are you sure you want to delete this item?")}</h2>
                                    <h2 id="transition-modal-title" style={webStyle.modelText}>{this.tranSellerOfferDiscountCreate("This action cannot be undone.")}</h2>
                                    <div style={{ marginTop: "40px", display: "flex", gap: "20px" }}>
                                        <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleCancel}>{this.tranSellerOfferDiscountCreate("No")}</StyledButtonModel>
                                        <StyledButton1Model style={{ fontSize: "14px" }} data-testid="cancelOrder" onClick={() => this.toggleDeleteTrackOffer(this.state.acountId)}>{this.tranSellerOfferDiscountCreate("Yes")}</StyledButton1Model>
                                    </div>
                                </div>
                            </Fade>
                        </StyledDrawer>
                        <ReusableSnackbar
                            onClose={this.onCloseAlert}
                            open={this.state.alert.isAlert}
                            severity={this.state.alert.severity}
                            message={this.state.alert.message}
                            data-testid="alertTestId"
                            autoHideDuration={3000}
                        />
                    </Grid>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    modelText: { width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" },
    febBox: { borderRadius: '10px', backgroundColor: "white" },
    rejectNameDetails: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    recommended: { fontSize: "20px", fontFamily: "Lato", fontWeight: 700, color: "#375280", marginBottom: '20px' },
    twoButton: { width: '180px', height: '48px', backgroundColor: '#CCBEB1', padding: '11px 27px', fontSize: '18px', fontFamily: 'Lato', fontWeight: 700, color: '#FFFFFF' },
    haderBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    createText: { fontSize: "20px", fontFamily: "Lato", fontWeight: 500, color: "#375280", marginBottom: '30px' },
    numberBox: { fontSize: "22px", fontFamily: "Lato", fontWeight: 700, color: "#375280", marginBottom: '10px' },
    lableText: { fontSize: "24px", fontFamily: "Lato", fontWeight: 700, color: "#375280", marginBottom: '30px' },
    tabs: { backgroundColor: '#375280', borderBottom: '1px solid #E2E8F0', weight: "20px", height: '4px' },
    allTab: { fontSize: "14px", fontFamily: "Lato", fontWeight: 600, color: "#375280" },
    containerStyle: { color: "#334155", fontSize: '22px', fontFamily: 'Lato', fontWeight: 400, marginBottom: '12px' },
    boxStyle: { color: "#375280", fontSize: '36px', fontFamily: 'Lato', fontWeight: 700 },
    primaryText: {
        width: "68px",
        height: "26px",
        color: "#94A3B8",
        backgroundColor: "#E2E8F0",
        fontWeight: 700,
        fontSize: "12px",
        textAlign: "center" as 'center',
        fontFamily: "Lato" as "Lato",
        padding: '5px 0px 0px 0px'
    },
    menuMack: {
        padding: "10px",
        color: "#375280",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Lato",
        borderBottom: "1px solid #D5D5D5"
    },
    menuOpen: {
        backgroundColor: "#fff",
        width: "110.73px",
        zIndex: 3,
        top: "65px",
        boxShadow: "0px 2px 2px 0px #00000014",
        padding: '20px',
        borderRadius: "5px"
    },
    menuDelete: {
        cursor: "pointer",
        padding: "10px",
        color: "#DC2626",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Lato",
    },
    tabsBox: { width: '90%', height: '50vh', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
};
const StyledButtonModel = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "150px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1Model = styled(Button)(() => ({
    fontWeight: 500,
    color: "#fff",
    width: "150px",
    height: "45px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const StyledDrawer = styled(Modal)((theme) => ({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: "none",
    justifyContent: 'center',
}));
const TabWrapBox = styled(Box)(() => ({
    ' & .MuiTabs-flexContainer': {
        display: 'flex',
        borderBottom: '1px solid #e3e8f0',
        width: '20%'
    }
}))
const AntSwitch = styled(Switch)({
    width: 48,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 1,
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(25px)",
            "& + .MuiSwitch-track": {
                backgroundColor: "#375280",
                opacity: 1,
                border: "none",
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#375280",
            border: "none",
        },
    },
    "& .MuiSwitch-thumb": {
        width: 18,
        height: 18,
        color: "#FFFFFF",
        margin: 2
    },
    "& .MuiSwitch-track": {
        backgroundColor: "#E2E8F0",
        opacity: 1,
        borderRadius: 40,
        height: 24,
        width: 48
    },
});


// Customizable Area End