export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Down = require("../assets/down.png");
export const faceLogo = require("../assets/fashLogo.png");
export const person = require("../assets/person.png");
export const shopping = require("../assets/shopping.svg");
export const  search= require("../assets/Search.png");
export const facewhite = require("../assets/facebookwhite.png");
export const fashwhitelogo = require("../assets/fashwhitelogo.png");
export const instawhite = require("../assets/instawhite.png");
export const tiwiterwhite = require("../assets/tiwiterwhite.png");
export const linkdinwhite = require("../assets/linkdinwhite.png");
export const signUpLogo = require("../assets/signUpLogo.png");
export const PageNotFoundError = require("../assets/PageNotFoundError.svg");
export const flag = require("../assets/flag.png");


