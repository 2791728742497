// Customizable Area Start
import React from "react"
import {
    Box,
    styled,
    Typography,
    Button,
    Grid
} from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { PageNotFoundError } from "../../blocks/email-account-registration/src/assets";
import Header from "./Header";
import Footer from "./Footer";

interface S {
    openMenu: boolean;
    anchorEl: HTMLElement | null;
    open1: boolean,
    numberOrder:number | null;
    auth:string
}
export interface Props {
    navigation?: any;
    id?: string;
}
// Customizable Area End

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class PageNotFound extends BlockComponent<Props, S, SS>{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    headerRedirect = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };
    render() {
        // Customizable Area Start
        return (
            <StyledWrapper>
               <Header navigation={this.props.navigation} />
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className="GridMargin"
                >
                    <Grid item>
                       <img src={PageNotFoundError} alt="" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" className="oopsText">
                            Oops! Page not found
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" className="oopsTextDescription">
                            The page you are looking for might have been removed or
                            temporarily unavailable.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={this.headerRedirect} variant="contained" className="NotFoundBtn">
                            Back To Home Page
                        </Button>
                    </Grid>
                </Grid>
               <Footer />
            </StyledWrapper>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({
    fontFamily: "Lato",
    "& .GridMargin": {
        margin: "8rem 0rem"
    },
    "& .oopsText": {
        fontSize: "34px",
        fontWeight: 700,
        color: "#375280"
    },
    "& .oopsTextDescription": {
        width: "400px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: 400,
        color: "#94A3B8"
    },
    "& .NotFoundBtn": {
        width: "365px",
        padding: "16px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: 600,
        color: "#ffffff",
        backgroundColor: '#CCBEB1',
        borderRadius: "2px",
        textTransform: "inherit",
        marginTop: "30px"
    }
})
// Customizable Area End
