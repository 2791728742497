export const deleteIcon = require("../assets/delete.png");
export const checkedIcon = require("../assets/checked.png");
export const uncheckedIcon = require("../assets/unchecked.png");
export const portfolioImage0 = require("../assets/portfolio.png");
export const portfolioImage1 = require("../assets/portfolio1.png");
export const portfolioImage2 = require("../assets/portfolio2.png");
export const portfolioImage3 = require("../assets/portfolio3.png");
export const portfolioImage4 = require("../assets/portfolio4.png");
export const deleteIconSvg = require("../assets/delete.svg");
export const deleteIconPng = require("../assets/deleteIcon.png");
export const close = require("../assets/close.svg");
export const singleUpload = require("../assets/singleUpload.svg");
export const multipleUpload = require("../assets/multipleUpload.svg");
export const upload = require("../assets/upload.svg");
