import React, { createContext } from 'react';
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export const AppContext = createContext(false);
interface ReusableSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
  autoHideDuration?: number;
  anchorOrigin?: { horizontal: 'left' | 'center' | 'right', vertical: 'top' | 'bottom' };
  dataTestId?: string;
  dir? : 'ltr' | "rtl"
}

const ReusableSnackbar: React.FC<ReusableSnackbarProps> = ({
  open,
  onClose,
  message,
  severity = 'success',
  autoHideDuration = 3000,
  anchorOrigin = { horizontal: 'right', vertical: 'top' },
  dataTestId,
  dir = "ltr"
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      data-testid={dataTestId}
      dir={!!dir ? dir : "ltr"}
    >
      <Alert
        variant="filled"
        severity={severity}
        style={{ width: "300px", marginTop: "10px"}}
      >
        <div style={{marginLeft: dir === "rtl" ? "5px" : 0 }}>{message}</div>
      </Alert>
    </Snackbar>
  );
};

export default ReusableSnackbar;
