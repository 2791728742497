Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.listOfOrdersMethod = "GET";
exports.getNewOrderApiCallEndPoint = "bx_block_order_management/orders/get_all_seller_orders?type=new_orders";
exports.getAllOrderDetails = "bx_block_order_management/orders/get_all_seller_orders_count";
exports.customForms = "bx_block_custom_form/hire_stylist_custom_forms";
exports.httpPutMethod = "PUT";


// Customizable Area End