import i18n from "../../../components/src/i18next/i18n";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type OrderIdType = number | undefined | string;
import { getStorageData } from "../../../framework/src/Utilities";
type AlertType = 'success' | 'error' | 'warning' | 'info';
export interface OrderItemDetailSeller {
  type: "order_item_seller";
  id: string;
  attributes: {
    placed_at: string;
    status: string;
    in_transit_at: string | null;
    confirmed_at: string;
    cancelled_at: string | null;
    delivered_at: string | null;
    process_at: string;
    rejected_at: string | null;
    return_at: string | null;
    shipped_at: string | null;
    return_pick_at: string | null;
    return_cancel_at: string | null;
    unit_price: string;
    quantity: number;
    reason_of_rejection: string | null;
    catalogue_name: string;
    total_price: string;
    catalogue_variant_color: string;
    brand_name: string;
    store_name: string;
    catalogue_variant_sku: string;
    catalogue_variant_front_image: string;
    catalogue_variant_size: string;
    catalogue_variant_side_image: string;
    catalogue_variant_back_image: string;
    driver_latitude: number | null;
    driver_name: string | null;
    otp: string | null;
    driver_phone_number: string | null;
    driver_longitude: number | null;
    reason_of_return:string | null;
  };
}

interface DeliveryDetailAddress {
  type: "delivery_address";
  id: string;
  attributes: {
    country_code: string;
    name: string;
    contact_number: string;
    phone_number: string;
    zip_code: string;
    street: string;
    block: string;
    area: string;
    house_or_building_number: string;
    city: string;
    is_default: boolean;
    address_name: string;
    longitude: number;
    latitude: number;
  };
}

interface PaymentReadyDetail {
  status: string;
  id: number;
  updated_at: string;
  created_at: string;
  merchant_id: string | null;
  charge_id: string;
  amount: number;
  order_id: string;
  customer_id: string;
  currency: string;
  account_id: number;
  reason: string;
  refund_id: string | null;
  order_management_order_id: number;
  refund_reason: string | null;
  refund_amount: string | null;
  last_four_card_digit: string;
  payment_type: string;
  deleted: boolean;
  seller_order_id: string | null;
}

interface OrderSellerSide {
  id: string;
  type: "order_seller_side";
  attributes: {
    refunded_at: string | null;
    cancelled_at: string | null;
    in_transit_at: string | null;
    returned_at: string | null;
    confirmed_at: string;
    deliver_by: string | null;
    delivered_at: string | null;
    order_status_id: number;
    placed_at: string;
    created_at: string;
    updated_at: string;
    order_deliver_date: string | null;
    status: string;
    order_deliver_time: string | null;
    order_return_date: string | null;
    total: string;
    order_return_time: string | null;
    delivery_addresses: DeliveryDetailAddress;
    sub_total: string;
    title:string;
    order_number: string;
    account: string;
    payment_detail:{payment_type:string}
    reason_of_return:string | null;
  };
}

interface ContactNumber {
  phone_number: string;
  country_code: string;
}

interface StoreOperatingHours {
  open: string;
  close: string;
  is_open: boolean;
}

interface StoreInfo {
  id: string;
  type: string;
  attributes: {
    zipcode: string;
    city: string;
    unit_number: number;
    floor: string;
    driver_instruction: string;
    mall_name: string;
    block: string;
    average_shipping_time: string;
    area: string;
    address: string;
    description: string;
    store_name: string;
    payment_mode: string[];
    store_operating_hours: {
      monday: StoreOperatingHours;
      tuesday: StoreOperatingHours;
      wednesday: StoreOperatingHours;
      thursday: StoreOperatingHours;
      friday: StoreOperatingHours;
      saturday: StoreOperatingHours;
      sunday: StoreOperatingHours;
    };
    email: string;
    image: string | null;
    contact_number: ContactNumber;
    is_open: boolean;
    longitude: number;
    latitude: number;
    status: string;
    expected_delivery_time: string;
  };
}

interface CatalogueVariantColor {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface CatalogueVariantSize {
  name: string;
  id: number;
  updated_at: string;
  created_at: string;
}

interface CatalogueVariant {
  id: string;
  type: string;
  attributes: {
    sale_price: string | null;
    on_sale: boolean | null;
    discount_price: string | null;
    length: number | null;
    catalogue_variant_size_id: number;
    breadth: number | null;
    height: number | null;
    stock_qty: number;
    catalogue_variant_color: CatalogueVariantColor;
    created_at: string;
    updated_at: string;
    catalogue_variant_color_id: number;
    sku: string;
    deactivate: boolean;
    catalogue_variant_size: CatalogueVariantSize;
    low_stock_threshold: number;
    is_listed: boolean;
    front_image: string;
    catalogue_id: number;
    price: string;
    back_image: string;
    side_image: string;
    pair_it_with: any[];
    id: number;
  };
  store_info: StoreInfo;
}

interface SubSubCategory {
  id: string;
  type: string;
  attributes: {
    id: number;
    updated_at: string;
    created_at: string;
    name: string;
    image: string;
  };
}

interface SubCategory {
  id: string;
  type: string;
  attributes: {
    image: string;
    created_at: string;
    name: string;
    id: number;
    updated_at: string;
  };
}

interface Category {
  id: string;
  type: string;
  attributes: {
    id: number;
    status: string;
    name: string;
    updated_at: string;
    image: string;
    created_at: string;
  };
}

interface Catalogue {
  id: string;
  type: string;
  attributes: {
    brand_name: string;
    brand: string | null;
    tags: { data: any[] };
    length: number | null;
    manufacture_date: string | null;
    breadth: number | null;
    description: string;
    height: number | null;
    stock_qty: number | null;
    sku: string | null;
    availability: string | null;
    weight: number | null;
    reviews: any[];
    price: string | null;
    recommended: boolean | null;
    on_sale: boolean | null;
    sale_price: string | null;
    name: string;
    discount: string | null;
    sub_sub_category: SubSubCategory;
    is_wishlist: boolean | null;
    product_number: string | null;
    primary_image: string;
    primary_price: string;
    gender: string;
    category: Category;
    material: string;
    average_rating: number;
    fit: string;
    catalogue_variants_with_store: CatalogueVariant[];
    service: any | null;
    prodcut_care: string;
    list_the_product: string;
    fit_discription: string;
    is_published: boolean;
    sub_category: SubCategory;
    catalogue_variants: CatalogueVariant[];
  };
}

export interface OrderItemSeller {
  id: string;
  type: string;
  attributes: {
    brand_name:string;
    driver_name:string;
    catalogue_name:string;
    catalogue_variant_size:string;
    catalogue_variant_color:string;
    currency_logo: string;
    catalogue_variant_sku:string;
    store_name:string;
    catalogue_variant_front_image:string | undefined;
    id: number;
    status: string;
    old_unit_price: string | null;
    catalogue_id: number;
    total_price: string;
    catalogue_variant_id: number;
    unit_price: string;
    order_status_id: number;
    quantity: number;
    variant_availability: string;
    placed_at: string;
    order_management_order_id: number;
    confirmed_at: string;
    created_at: string;
    manage_cancelled_status: boolean;
    updated_at: string;
    manage_placed_status: boolean;
    reason_of_rejection: string | null;
    refunded_at: string | null;
    rejected_at: string | null;
    cancelled_at: string | null;
    accept_order_upload_time: string | null;
    item_return_date: string;
    delivered_at: string;
    item_return_time: string | null;
    order_statuses: any | null;
    catalogue: Catalogue;
    in_transit_at: string;
    catalogue_variant: CatalogueVariant;
    reason_of_return:string | null;
  };
}

export interface SellerOrderSeller {
  order_management_order: any;
  id: string;
  type: "seller_order_seller";
  attributes: {
    id: number;
    customer_name:string;
    order_management_order: OrderSellerSide;
    order_items: OrderItemSeller[];
    accept_order_upload_time: string | null;
    status: string;
  };
}
interface OrderSellerDetailSide {
  id: string;
  type: "order_seller_side";
  attributes: {
    account: string;
    order_number: string;
    total: string;
    sub_total: string;
    placed_at: string;
    status: string;
    in_transit_at: string | null;
    confirmed_at: string;
    cancelled_at: string | null;
    delivered_at: string | null;
    returned_at: string | null;
    deliver_by: string | null;
    refunded_at: string | null;
    created_at: string;
    order_status_id: number;
    order_deliver_date: string | null;
    updated_at: string;
    delivery_addresses: DeliveryDetailAddress;
    order_return_time: string | null;
    order_deliver_time: string | null;
    payment_detail: PaymentReadyDetail;
    order_return_date: string | null;
    reason_of_return:string | null;
  };
}

export interface SellerOrderDetailSeller {
  id: string;
  type: "seller_order_seller";
  attributes: {
    status: string;
    id: number;
    order_items: OrderItemDetailSeller[];
    order_management_order: OrderSellerDetailSide;
    accept_order_upload_time: string | null;
  };
}
interface OrderState {
  return_placed_at: string | null;
  return_cancel_at: string | null;
  return_confirmed_at: string | null;
  return_reject_at: string | null;
  return_pick_at: string | null;
  returned_assign_at: string | null;
  return_at: string | null;
  order_number: string;
  placed_at: string;
  reason: string;
  refunded_cancel_at: string | null;
  reason_refunded_cancel: string | null;
  refunded_at: string | null;
  otp: string | null;
  longitude: number;
  latitude: number;
  estimated_delivery_time: string | null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  numberDataOrder:string;
  selectReson:string[]
  isOpenDelete:boolean;
  orderDeltilsId: string | number,
  rejectMrpData: number | string;
  visible:boolean;
  isLoadingOut: boolean
  productDetailId: string;
  activeTab:string,
  readyForPage: number,
  SellerOrdersProcessedView: SellerOrderSeller[],
  SellerOrdersProcessedViewProcess: SellerOrderSeller[],
  SellerUnderProcess:SellerOrderSeller[],
  SellerRefunded:SellerOrderSeller[],
  isAlertReadyForCollection: boolean
  orderData: OrderState,
  isDrawerStatusOpen:boolean,
  isDrawerStatus:boolean,
  latitudeData: { lat: number, lng: number },
  longitudeData: { lat: number, lng: number },
  orderDataId:string,
  sellerDriverName:string
  rejectOpen: boolean;
  rejectId: number,
  numberData: string;
  short: string[],
  descriptionReson:string,
  confirmId:number,
  refundAmount:string,
  errorMessage:string,
  combineImg: (string | null)[];
  selectedFile: (File | null)[];
  isOpenReturnRequest:boolean;
  alertType: AlertType,
  isAlertOrder:boolean;
  orderIdReject:number | string;
  already:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReturnControllerDetail extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSellerOrdersProcessedApiCallId: string = ""
  getSellerReturnnInProcessedApiCallId: string= "";
  getSellerUnderProcessApiCallId: string= "";
  getSellerRefundedApiCallId: string= "";
  getTRackOrderApiCallId:string = "";
  getRejectOrderCallId:string = "";
  getSellerAcceptApiCallId:string = "";
  getTimeRefundOrderCallId:string = "";
  getTimeAllOrderCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isOpenReturnRequest:false,
      rejectMrpData: 0,
      orderDeltilsId: "",
      refundAmount: "",
      errorMessage: "",
      isOpenDelete:false,
      selectedFile: [],
      combineImg: [],
      selectReson: ["Worn or Used Items", "Damaged Items", "Altered Items", "Sale Items", "Past Return Window", "Hygiene Concerns"],
      numberDataOrder: "",
      descriptionReson:"",
      visible:false,
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      numberData: "",
      rejectOpen: false,
      rejectId: 0,
      confirmId:0,
      SellerOrdersProcessedView: [],
      orderDataId:"",
      isLoadingOut: false,
      productDetailId: "",
      SellerOrdersProcessedViewProcess:[],
      SellerUnderProcess:[],
      SellerRefunded:[],
      activeTab: 'Return in Request',
      isAlertReadyForCollection: false,
      readyForPage: 1,
      orderData: {
        return_placed_at: null,
        return_cancel_at: null,
        return_confirmed_at: null,
        return_reject_at: null,
        return_pick_at: null,
        returned_assign_at: null,
        return_at: null,
        order_number: "",
        placed_at: "",
        reason: "",
        refunded_cancel_at: null,
        reason_refunded_cancel: null,
        refunded_at: null,
        otp: null,
        longitude: 0,
        latitude: 0,
        estimated_delivery_time: null,
      },
      isDrawerStatusOpen:false,
      isDrawerStatus:false,
      latitudeData:{ lat: 0, lng: 0 },
      longitudeData:{lat:0,lng:0},
      sellerDriverName:"",
      orderIdReject:"",
      alertType: 'success' as AlertType,
      already:"",
      isAlertOrder:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getSellerOrdersProcessedApiCallId){
      this.handleSellerOrdersProcessed(responseJson);
    
    }
    if(apiRequestCallId === this.getSellerReturnnInProcessedApiCallId){
      this.handleSellerReturnInProcessed(responseJson);
     
    }

    if(apiRequestCallId === this.getSellerUnderProcessApiCallId){
      this.handleSellerUnderProcess(responseJson);
    }

    if(apiRequestCallId === this.getSellerRefundedApiCallId){
      this.handleSellerRefunded(responseJson);
    
    }
    if (apiRequestCallId === this.getTRackOrderApiCallId) {
      this.setState({orderData:responseJson,longitudeData:{ lat: responseJson.latitude, lng: responseJson.longitude },latitudeData : { lat: responseJson.latitude, lng: responseJson.longitude }})
    }
    
    if (apiRequestCallId === this.getRejectOrderCallId) {
      this.rejectOpenDetail(responseJson)
    }
    if (apiRequestCallId === this.getSellerAcceptApiCallId) {
      if (responseJson) {
        this.setState({ visible: false },() => {this.pageRedirectOrderPage()})
      }
    }

    if (apiRequestCallId === this.getTimeRefundOrderCallId) {
      this.handleSellerOrdersProcessedDetail(responseJson)
    }

    if (apiRequestCallId === this.getTimeAllOrderCallId) {
      this.handleSellerOrdersProcessedRequestDetail(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const productDetailDataId = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ productDetailId: productDetailDataId })
    this.getSellerOrdersReturnApi1();
    this.getSellerOrdersInprocessApi1();
    this.getSellerUnderprocessApi1();
    this.getSellerRefundedApi1();
    // Customizable Area End
  }

  proceedToAllOrderPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SellersAllOrderDetailsWebPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  rejectOpenDetail = (responseJson:any) => {
    if (responseJson) {
      this.setState({ rejectOpen: false },() => this.pageRedirectOrderPage())
    }
  }
  handleSellerOrdersProcessedRequestDetail(responseJson: any) {
    if (responseJson.error) {
      this.setState({ isAlertOrder: true, alertType: 'error', already: responseJson.error === "Payment failed. Please check your details and try again or use a different payment method." ? responseJson.error : responseJson.error[0].message, isOpenDelete: false },() => this.pagenesoinInUnder())
    } else {
      this.setState({ isOpenDelete: false })
    }
  }

  handleSellerOrdersProcessedDetail(responseJson:any) {
    if (responseJson.data) {
      this.setState({ isOpenReturnRequest: false,isAlertOrder: true, alertType: 'success', already: this.tranReturnDetail("Return Reject Successfully.") },() => this.pagenesoinInUnder())
    } else {
      this.setState({ isAlertOrder: true, isOpenReturnRequest: false , alertType: 'error', already: responseJson.error})
    }
  }

  handleSellerOrdersProcessed(responseJson:any) {
    if(responseJson){
      const flterData1 = responseJson?.data && responseJson.data.filter((item: SellerOrderSeller) => {
        return item.attributes.order_management_order.attributes.order_number == this.state.productDetailId;
      });
      this.setState({ 
        SellerOrdersProcessedView: flterData1, 
        isAlertReadyForCollection: responseJson.error === "No orders found." ? true : false 
      });
    }
  }

handleSellerUnderProcess(responseJson:any) {
  if(responseJson){
    const flterData2 = responseJson?.data && responseJson.data.filter((item: SellerOrderSeller) => {
      return item.attributes.order_management_order.attributes.order_number == this.state.productDetailId;
    });
    this.setState({ 
      SellerUnderProcess: flterData2, 
      isAlertReadyForCollection: responseJson.error === "No orders found." ? true : false 
    });
  }
}
handleSellerReturnInProcessed(responseJson:any) {
  if(responseJson){
    const flterData = responseJson?.data && responseJson.data.filter((item: SellerOrderSeller) => {
      return item.attributes.order_management_order.attributes.order_number == this.state.productDetailId;
    });
    const sellerDriver = flterData.map((value: { attributes: { id: number }; })=>{return value.attributes.id})
    const flattenedSellerDriver = sellerDriver?.flat();
    this.setState({ 
      SellerOrdersProcessedViewProcess: flterData, 
      isAlertReadyForCollection: responseJson.error === "No orders found." ? true : false ,
      orderDataId:flattenedSellerDriver[0]
    },()=>this.getTrackOrderDetailApi(flattenedSellerDriver[0]));
  }
}
handleSellerRefunded(responseJson:any) {
  if(responseJson){
    const flterData3 = responseJson?.data && responseJson.data.filter((item: SellerOrderSeller) => {
      return item.attributes.order_management_order.attributes.order_number == this.state.productDetailId;
    });
    this.setState({ 
      SellerRefunded: flterData3, 
      isAlertReadyForCollection: responseJson.error === "No orders found." ? true : false 
    });
  }
}
  getSellerOrdersReturnApi1 = async () => {
    const token = await getStorageData("auth-token");
    
    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerOrdersProcessedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSellerReturnRefund}?type=return_request`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getSellerOrdersInprocessApi1 = async () => {
    const token = await getStorageData("auth-token");
    
    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerReturnnInProcessedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSellerReturnRefund}?type=return_in_process`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSellerUnderprocessApi1 = async () => {
    const token = await getStorageData("auth-token");
    
    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerUnderProcessApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSellerReturnRefund}?type=refund_under_process`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSellerRefundedApi1 = async () => {
    const token = await getStorageData("auth-token");
    
    const headers = {
      "token": token
    };
    const requestMessagerefunded = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerRefundedApiCallId = requestMessagerefunded.messageId;
    requestMessagerefunded.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSellerReturnRefund}?type=refunded`
    );
    requestMessagerefunded.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessagerefunded.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessagerefunded.id, requestMessagerefunded);
    return true;
  }


  getTrackOrderDetailApi = async (orderIdNumber?: OrderIdType) => {
    const getToken = await getStorageData("auth-token")
    const headers = {
      "token": getToken
    };
    const requestMessageOrderIdNumber = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTRackOrderApiCallId = requestMessageOrderIdNumber.messageId;
    requestMessageOrderIdNumber.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersManagementTreack}=${orderIdNumber}`
    );
    requestMessageOrderIdNumber.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageOrderIdNumber.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessageOrderIdNumber.id, requestMessageOrderIdNumber);
    return true;
  }

  toggleStatusDrawer = (open: boolean, orderIdNumber?: number) => (event: React.KeyboardEvent | React.MouseEvent) => {
    this.getTrackOrderDetailApi(orderIdNumber)
    this.setState({
      isDrawerStatusOpen: open,
      isDrawerStatus:false,
    });
  };
  toggleStatusData = (open: boolean, orderIdNumber?: OrderIdType) => (event: React.KeyboardEvent | React.MouseEvent) => {
    const sellerDriver = this.state.SellerOrdersProcessedViewProcess?.map((order1: SellerOrderSeller) => {
      return order1.attributes.order_items.map((orderItem: OrderItemSeller) => {
        return orderItem.attributes.driver_name;
      });
    });
    const flattenedSellerDriver = sellerDriver?.flat();
    this.getTrackOrderDetailApi(orderIdNumber)
    this.setState({
      isDrawerStatusOpen: false,
      isDrawerStatus: open,
      sellerDriverName:flattenedSellerDriver[0],
    });
  };

  toggleStatusDataClose = (orderIdNumber?: OrderIdType) => (event: React.KeyboardEvent | React.MouseEvent) => {
    this.setState({
      isDrawerStatus: false,
    });
    this.getTrackOrderDetailApi(orderIdNumber)
  };

  toggleStatusDrawerClose = (orderIdNumber?: number| undefined | string) => (event: React.KeyboardEvent | React.MouseEvent) => {
    this.setState({
      isDrawerStatusOpen: false,
    });
    this.getTrackOrderDetailApi(orderIdNumber)
  };

  tranReturnDetail = (transKey: string) => {
    return i18n.t( transKey, {ns: "orderDetail"})
  }
  handleOpen1 = (rejectid: number) => {
    this.setState({ rejectOpen: true, rejectId: rejectid })
  };
  handleClose1 = () => {
    this.setState({ rejectOpen: false })
  };
  handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberData: event.target.value as string });
  };
  changeDisc = (value: string) => {
    this.setState({ descriptionReson: value })
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleOkDetail = (confirmid: number) => {
    this.setState({ visible: true, confirmId: confirmid });
  };

  handleOpenDeleteDetailsModal = (orderId: string | number) => {
    const orderDatalsAllDetailsData = this.state.SellerUnderProcess.find((order: { id: number | string }) => {
      return order.id == orderId;
    });
    const rejectMrp = Number(orderDatalsAllDetailsData?.attributes.order_items[0].attributes?.total_price).toFixed(0)
    this.setState({ isOpenDelete: true, orderDeltilsId: orderId, rejectMrpData: rejectMrp ,});
  };
  handleCloseDeleteDetailsModal = () => {
    this.setState({ isOpenDelete: false });
  };

  handleOpenReturnRequestDetailsModal = (orderId:string|number) => {
    this.setState({ isOpenReturnRequest: true ,orderIdReject: orderId});
  };

  handleCloseReturnRequestDetailsModal = () => {
    this.setState({ isOpenReturnRequest: false });
  };

  handlenumberDataOrder = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberDataOrder: event.target.value as string });
  };
  openPictureUploadPhoto = (index: number) => {
    const inputAddPensionPhoto = document.createElement('input');
    inputAddPensionPhoto.type = 'file';
    inputAddPensionPhoto.accept = 'image/png, image/jpeg, image/jpg';
    inputAddPensionPhoto.multiple = false;
    inputAddPensionPhoto.click();
    inputAddPensionPhoto.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
      this.openProfileUploadInSidePhoto(event, index);
    };
  }
  openProfileUploadInSidePhoto = async (event: React.ChangeEvent<HTMLInputElement> | Event, index: number) => {
    const targetData = event.target as HTMLInputElement;
    const files = targetData.files;
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];
    const base64 = URL.createObjectURL(file);
    this.setState({
      selectedFile: [file],
      combineImg: [base64],
    });
  }
  handleRemoveimg = () => {
    this.setState({ selectedFile: [], combineImg: [] });
  };

  rejectData = () => {
    this.setState({ rejectOpen: false })
    this.rejectOrderData()
  }

  rejectOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?seller_order_id=${this.state.rejectId}&type=return_reject&reason_of_rejection=${this.state.numberData}&rejection_detail=${this.state.descriptionReson}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  AcceptRequest = async () => {
    const token = await getStorageData("auth-token");
    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerAcceptApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=return_accept&seller_order_id=${this.state.confirmId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  timeOrderDataReject = async () => {
    const formData = new FormData()
    formData.append("reason_of_refund_rejected", this.state.numberDataOrder);
    this.state.selectedFile.forEach((itemsImg: string | Blob | null) => {
      itemsImg && formData.append("image", itemsImg);
    })
    const token = await getStorageData("auth-token");
    const header = {
      token: token,
    };
    const requestMessageListAllProcess = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeRefundOrderCallId = requestMessageListAllProcess.messageId;
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=refund_rejected&seller_order_id=${this.state.orderIdReject}`
    );
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageListAllProcess.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageListAllProcess.id, requestMessageListAllProcess);
  }

  handleConfirmDetalis = async (refundAmo: string | number) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListAllProcess = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeAllOrderCallId = requestMessageListAllProcess.messageId;
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=initiate_refund&seller_order_id=${this.state.orderDeltilsId}&refund_amount=${refundAmo}`
    );
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListAllProcess.id, requestMessageListAllProcess);
  };

  pageRedirectOrderPage = () => {
    this.getSellerOrdersReturnApi1();
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellersAllOrderDetailsWebPage");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { tabIndex: 6 });
    toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(toNavigate);
  }

  pagenesoinInUnder = () => {
    this.getSellerUnderprocessApi1();
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellersAllOrderDetailsWebPage");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { tabIndex: 6 });
    toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(toNavigate);
  }
  // Customizable Area End
}
