Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.labellastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.fullName = "Full Name";
exports.firstName="First Name";
exports.lastName="Last Name"
exports.emailAddress="Email Address";
exports.reenterPassword="Re-enter Password";
exports.phone="Phone Number";
exports.createAccount="Create Account";
exports.byClick="By clicking create account, you agree to our";
exports.termsCondition="Terms & Conditions";
exports.privacyStatement="Privacy Statement.";
exports.loginHere="Login Here";
exports.aleadyAccount="Aleady have an account?";
exports.and=" and ";
exports.nameValidationMsg="*Please enter a valid full name";
exports.firstNameValidationMsg = "*Please enter a valid first name";
exports.lastNameValidationMsg = "*Please enter a valid last name";
exports.emailValidationMsg="*Please enter your email address";
exports.validationPassMsg="*Please enter your password";
exports.min8digitValidationMsg="*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric";
exports.reEnterValidationMsg="*Please re-enter your password";
exports.phoneValidationMsg="*Please enter your phone number";
exports.termConditionCheckBox="*Please accept Term and Condition and Privacy Statement.";
exports.emailValidMsg="*Please enter a valid email address";
exports.confirmPass = "* Please re-enter your password";
exports.phoneNumericValues = "*Phone number must contain only numeric values";
exports.neightDigRegex=/^\+?\d{7,}$/;
exports.alphnewmertic="*Please enter a valid phone number";
exports.firstNamePlace="Enter your first name";
exports.lastNamePlace="Enter your last name";
exports.emailPlace="Enter your email";
exports.passPlace="Enter your password";
exports.rePassPlace="Re-enter your password";
exports.phonePlace="Enter phone Number";
exports.textAgree="I have read and agree to the";
exports.termsCondtion="Please accept the terms & conditions and Privacy Statment";
exports.eightPass="Your password must contain a minimum of 8 characters";
exports.emailRegex=/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
exports.passRegx=/^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[A-Za-z\d!@#$%^&*]{8,}$/;
exports.phoneRegex=/^\d{10}$/;
exports.nameRegex=/^[^\s]+(\s[^\s]+)?$/;
exports.countryCodeEndPoint = "accounts/country_code_and_flags";
exports.countryApiContentType = "application/json";
exports.httpMethodType = "GET";

exports.postRegistrationEndPoint = "account/accounts";
exports.postRegistrationContentType = "application/json";
exports.postApimethod = "POST";
exports.loginSuccessMsg="Login Successfully";
exports.successMsg="Success";
exports.emailMsg="email";
exports.emailtypeMsg="email_account";
exports.emailAccountLoginBlockmsg="EmailAccountLoginBlock";
exports.EmailOTPVarificationMsg="EmailOTPVarification";
exports.email_otpMsg="email_otp";
exports.booleanMsg="boolean";
exports.TermsConditionsMsg="TermsConditions";
// Customizable Area End
