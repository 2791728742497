import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import AuthenticateUser from "../../../components/src/AuthenticateUser";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { RefObject, createRef } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { v4 as uuidv4 } from 'uuid'
import i18n from "../../../components/src/i18next/i18n";
import { apiCall } from "../../../components/src/APICall";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Product {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    primary_image: string | undefined;
    primary_price: string;
    is_wishlist: boolean;
    primary_main_price: string;
    primary_discounted_percentage: number;
  };
}
interface Size {
  name: string;
  label: string;
}

interface VariantSwithStore {
  id: string,
  type: string,
  attributes: {
    id: number,
    catalogue_id: number,
    catalogue_variant_color_id: number | null,
    catalogue_variant_color: {
      id: number,
      name: string,
      created_at: string,
      updated_at: string
    } | null,
    catalogue_variant_size_id: number | null,
    catalogue_variant_size: {
      id: number,
      name: string,
      created_at: string,
      updated_at: string
    } | null,
    is_listed: boolean,
    front_image: string,
    price: string;
    discounted_price: number,
    discounted_percentage: number,
    stock_qty: number,
    sku: string;
    back_image: string;
    side_image: string;
    pair_it_with: [
      {
        id: string,
        type: string,
        attributes: {
          id: number;
          catalogue_id: number;
          product_name: string;
          product_description: string;
          sku: string;
          stock_qty: number;
          low_stock_threshold: number;
          is_listed: boolean;
          price: string;
          size: string;
          colour: string;
          gender: string;
          front_image: string;
          brand_name: string;
        }
      }
    ],
  },
  store_info: {
    id: string,
    type: string,
    attributes: {
      store_name: string,
      description: string,
      address: string,
      area: string,
      block: string,
      mall_name?: string,
      floor: string,
      unit_number: number,
      city: string,
      zipcode: number | string,
      driver_instruction: string,
      average_shipping_time: string,
      expected_delivery_time: string
    },
  }
}

interface Variant{
  id: string;
  type: string;
  attributes:{
    id: number;
    catalogue_id: number;
    catalogue_variant_color_id: number | null;
    catalogue_variant_color:{
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
    } | null,
    catalogue_variant_size_id: number | null;
    catalogue_variant_size:{
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
    } | null,
    price: string;
    stock_qty: number;
    on_sale: string | null;
    sale_price: string | null;
    discount_price: string | null;
    length: string | null;
    breadth: string | null;
    height: string | null;
    created_at: string;
    updated_at: string;
    sku: string;
    deactivate: boolean;
    low_stock_threshold: number;
    is_listed: boolean;
    discounted_price: string;
    front_image: string;
    back_image: string;
    side_image: string;
    pair_it_with: string[];
  }
}
interface ProductData {
  id: string;
  type: string;
  attributes: {
    id: string;
    catalogue_variants_with_store: VariantSwithStore[];
    name: string;
    description: string;
    primary_image: string | undefined;
    primary_price: string;
    is_wishlist: boolean;
    fit: string;
    prodcut_care: string;
    material: string;
    expected_delivery_time: string;
    brand_name:string;
    catalogue_variants: Variant[];
    fit_discription:string;
    owner_first_name: string,
    owner_last_name: string,
    owner_full_name: string,
    owner_address: {
      address: string,
      area: string,
      block: string,
      mall_name: string,
      floor: string,
      unit_number: string,
      city: string,
      zipcode: string
    }
    primary_main_price: string;
    primary_discounted_percentage: number;
  };
}
interface SummaryData {
  name: string;
  title: string;
  description: string;
}
interface INavigateTo {
  productId: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedItem: number;
  arrowDisable: boolean;
  selectedSize: string,
  selectedSizeLabel: string | null;
  selectedColor: number | null;
  products: Product[],
  productsData: ProductData,
  categoryData: string,
  sub_categoryData: string,
  productsloding: boolean,
  catalogueVariantId: number,
  initialSize: Size[],
  colors: string[],
  filterSize: string[],
  stylishBuyer: boolean;
  filterColor: string[],
  summuryData: SummaryData[],
  lodingProduct: boolean,
  selectColore: string,
  cataegreyId: number | string,
  isAlert: boolean,
  severity: "warning" | "success",
  already: string,
  setProps: React.ReactNode,
  modalOpen:boolean,
  tokens:string,
  renderImage: string[],
  addtoCard:boolean,
  isHaveStore: boolean,
  isInStock: boolean,
  productPrice: string,
  productDiscountedPrice: number,
  productDiscountedPercentage: number,
  favloading: boolean;
  userCurrency: string;
  anchorEl: null | HTMLElement;
  linkCopied: boolean; 
  productUrl: string;
  isBackDrop: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Productdescription3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  elementRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  getcategoriesCallId: string = "";
  getaddCartCallId: string = "";
  getcategoriesId: string = "";
  getproductCallId: string = "";
  getproductLickCallId:string = "";
  getcategoriesCardId:string = "";
  DirectBuyNowAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      productUrl: "",
      anchorEl: null,
      linkCopied: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      modalOpen: false,
      setProps: "",
      isAlert: false,
      cataegreyId: "",
      colors: ['red', 'blue', 'green', 'yellow', 'purple'],
      sub_categoryData: "",
      userCurrency: "$",
      isBackDrop: false,
      productPrice: "",
      productsloding: false,
      categoryData: "",
      selectedColor: 0,
      stylishBuyer: false,
      catalogueVariantId: 0,
      productDiscountedPrice: 0,
      productDiscountedPercentage: 0,
      isInStock: false,
      arrowDisable: false,
      renderImage: [],
      selectedItem: 0,
      selectedSize: '',
      selectedSizeLabel: '',
      productsData: {} as ProductData,
      severity: "success",
      products: [],
      initialSize: [
        { name: 'XS', label: 'ExtraSmall' },
        { name: 'S', label: 'Small' },
        { name: 'M', label: 'Medium' },
        { name: 'L', label: 'Large' },
        { name: 'XL', label: 'ExtraLarge' },
        { name: 'XXL', label: 'XXL Size'}
      ],
      filterSize: [],
      filterColor: [],
      summuryData: [{ name: 'description', title: "Product description", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      { name: 'fit', title: "Size & Fit", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      { name: 'prodcut_care', title: "Product care & Material", description: "Machine Wash 55% Cotton 45% Polyster" },
      { name: '', title: "Expected delivery & total cost ", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      { name: '', title: "Expected Cost", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      { name: '', title: "Store information", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      ],
      lodingProduct: true,
      selectColore: "",
      already: "",
      tokens:"",
      addtoCard : false,
      isHaveStore: false,
      favloading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    await this.getCategoriesCalled(message);
    await this.buyNowAPIResp(message);
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getcategoriesId !== null &&
      this.getcategoriesId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(this.state.tokens && responseJson?.data?.length > 0){
        this.setState({
          products: responseJson?.data,
          userCurrency: responseJson.data[0].attributes.currency_logo, 
          isBackDrop: false
        })
      }
      this.setState({ products: responseJson?.data, isBackDrop: false })
    }
    this.getaddCart(message)
    this.getaddCardData(message)
    this.getaddCardLinkData(message)
    this.addtoCardAlart(message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }
  // Customizable Area End
  // Customizable Area Start
  getCategoriesCalled = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getcategoriesCallId !== null &&
      this.getcategoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonColor = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const catalogueVariants = responseJsonColor?.data?.attributes?.catalogue_variants_with_store;

      let uniqueSizesColor = { uniqueSizes: [] as string[], uniqueColors: [] as string[] };
      if (catalogueVariants) {
        uniqueSizesColor = await this.catalogueVariants(catalogueVariants);
      }
      
      const categoryName = responseJsonColor.data.attributes.category.attributes.name;
      const match = categoryName.match(/^[^\s']+/);
      const firstWord = match ? match[0] : '';
      const renderImage = [responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.front_image, responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.back_image, responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.side_image ]
      const isHaveStore = responseJsonColor.data?.attributes?.catalogue_variants_with_store[0]?.store_info.attributes?.store_name === undefined ? false : true;
      const isInStock = responseJsonColor.data?.attributes?.catalogue_variants_with_store[0]?.attributes?.stock_qty > 0 ? true : false;
      this.setState({ cataegreyId: responseJsonColor.data.attributes.category.attributes.id, productsData: responseJsonColor.data, lodingProduct: false, productsloding: true, filterColor: uniqueSizesColor.uniqueColors, filterSize: uniqueSizesColor.uniqueSizes, selectColore: responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.catalogue_variant_color.name, selectedSize: responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.catalogue_variant_size.name, selectedSizeLabel: responseJsonColor.data.attributes?.catalogue_variants_with_store[0].attributes.catalogue_variant_size.name, categoryData: firstWord, sub_categoryData: responseJsonColor.data.attributes?.sub_category.attributes.name, catalogueVariantId: responseJsonColor.data?.attributes?.catalogue_variants_with_store[0]?.attributes?.id, isHaveStore, isInStock, productPrice: responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.price, productDiscountedPrice: responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.discounted_price,
        productDiscountedPercentage: responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.discounted_percentage, renderImage: renderImage, isBackDrop: false}, () => {
        this.categoreySimilar(responseJsonColor.data.attributes.category.attributes.id)
        this.categoreySimilarData()
        this.setState({favloading: false, isBackDrop: false})
      })
      this.scrollToSection("topId")
    } 
  }

  buyNowAPIResp = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.DirectBuyNowAPICallId !== null &&
      this.DirectBuyNowAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonBuyNow = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      await setStorageData("selectOrderId", responseJsonBuyNow.data.id);
      await setStorageData("payment-details", "Direct-Order");
      const navMessage = new Message(getName(MessageEnum.NavigationMessage));
      navMessage.addData(getName(MessageEnum.NavigationTargetMessage), "Shippingaddressvalidation2");
      navMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navMessage);
    }
  }
  catalogueVariants = async (catalogueVariants: VariantSwithStore[]): Promise<{
    uniqueColors: string[];
    uniqueSizes: string[];
  }> => {
    const colorToSizeMap: { [color: string]: string[] } = {};
  
    const extractVariantInfo = (variant: VariantSwithStore) => {
      const { catalogue_variant_color, catalogue_variant_size } = variant.attributes;
      return {
        size: catalogue_variant_size?.name || "N/A",
        color: catalogue_variant_color?.name || "N/A",
      };
    };
  
    const updateColorSizeMap = (size: string, color: string) => {
      if (color !== "N/A") {
        if (!colorToSizeMap[color]) {
          colorToSizeMap[color] = [];
        }
        if (size !== "N/A" && !colorToSizeMap[color].includes(size)) {
          colorToSizeMap[color].push(size);
        }
      }
    };
  
    const variants = catalogueVariants.map(extractVariantInfo);
    variants.forEach(({ size, color }) => updateColorSizeMap(size, color));
  
    const uniqueColors = Object.keys(colorToSizeMap);
    const firstColorSizes = colorToSizeMap[uniqueColors[0]] || [];
  
    return { uniqueColors, uniqueSizes: firstColorSizes };
  };  
  

  transDesc = (keyTd: string) => {
    return i18n.t(keyTd, { ns: "prodDesc"})
  }

  addtoCardAlart = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getcategoriesCardId !== null &&
      this.getcategoriesCardId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const orderItems = responseJson?.data?.attributes?.order_items.map((value: any) => {
        return value.attributes.catalogue_variant_sku
      }) || []
      this.setState({ isBackDrop: false, addtoCard: orderItems.includes(this.state.productsData?.attributes.catalogue_variants_with_store[0].attributes.sku) })
    }
  }
  getaddCardData = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getproductCallId !== null &&
      this.getproductCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message === "Destroy successfully") {
        this.setState({isAlert: true, already: "The item has been removed from wishlist", severity: "success"}, ()=>{
          this.categorey()
          this.categoreySimilar(this.state.cataegreyId)
        });
      }else if (responseJson.error[0].token === "Invalid token") {
        this.renderDialog()
      }
    }
  }
  getaddCardLinkData = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getproductLickCallId !== null &&
      this.getproductLickCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.meta.message === "Added to wishlist.") {
        this.setState({isAlert: true, already: "item added to wishlist", severity: "success"}, ()=>{this.categorey();
          this.categoreySimilar(this.state.cataegreyId)})
        
      }
    }
  }

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  handleSelect = (index: number) => {
    this.setState({ selectedItem: index });
  };
  handleHorizantalScroll = (element: RefObject<HTMLDivElement>, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (element.current) {
        element.current.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
        if (element.current.scrollLeft === 0) {
          this.setState({ arrowDisable: true });
        } else {
          this.setState({ arrowDisable: false });
        }
      }
    }, speed);
  };

  clothingPageNavigation = async(props: React.ReactNode, name?: string) => {
    await setStorageData("navigationGender", name)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CategoriessubcategoriesWebPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  getSizeValue = (size: string, type: 'label' | 'value') => {
    switch (size) {
      case 'XS':
        return type === 'label' ? 'Extra Small' : 'XS';
      case 'S':
        return type === 'label' ? 'Small' : 'S';
      case 'M':
        return type === 'label' ? 'Medium' : 'M';
      case 'L':
        return type === 'label' ? 'Large' : 'L';
      case 'XL':
        return type === 'label' ? 'Extra Large' : 'XL';
      case 'XXL':
        return type === 'label' ? 'XXL Size' : 'XXL';
      default:
        return type === 'label' ? size.toUpperCase() : null;
    }
  };
  // Customizable Area End
  // Customizable Area Start
  formattedDate = () => {
    const newDate = this.state.productsData?.attributes?.catalogue_variants_with_store[0].store_info.attributes?.expected_delivery_time || "";
    if (!newDate) return ""
    const date = new Date(newDate);
    let dateString = date.toDateString().split(' ')
    return `${dateString[0]}, ${dateString[2]} ${dateString[1]} - ${dateString[3]}`
  }
  getAddress = () => {
    const { mall_name, floor, area, block, city, zipcode } = this.state.productsData?.attributes.catalogue_variants_with_store[0].store_info.attributes || {
      store_name: "",
      description: "",
      address: "",
      area: "",
      block: "",
      mall_name: "",
      floor: "",
      unit_number: "",
      city: "",
      zipcode: "",
      driver_instruction: "",
      average_shipping_time: "",
      expected_delivery_time: ""
    } 
    const addressParts = [mall_name, floor, area, block, city, zipcode].filter(part => part).join(', ');
    return addressParts
  }
  isSelectedSize = (isSelectedSize: boolean, size: { name: string }) => {
    return isSelectedSize ? size.name.toUpperCase() : this.getSizeValue(size.name, "value")
  }
  backgroundColor = (isSelectedSize: boolean) => {
    return isSelectedSize ? '#CCBEB1' : 'white'
  }
  sizeColor = (isSelectedSize: boolean) => {
    return isSelectedSize ? 'white' : '#375280'
  }
  filterColore = (index: number) => {
    return this.state.selectedColor === index ? '1px solid #375280' : "1px solid rgba(55, 82, 128, 0)"
  }
  handleColorClick = async (color: string, index: number) => {
    const sizeArray = await this.getSizesByColor(color);
    const variantId = await this.getVariantIdByColorAndSize(color, sizeArray[0]);

    this.setState({ productPrice: variantId.price, productDiscountedPercentage: variantId.discountedPercentage, productDiscountedPrice: variantId.discountedPrice, selectedItem: 0, selectedColor: index, selectColore: color, catalogueVariantId: +variantId.id, isInStock: variantId.isInStock, renderImage: variantId.renderImages, selectedSize: sizeArray[0], filterSize: sizeArray, selectedSizeLabel: sizeArray[0]});
  };

  getNavigationMessage = (props?: React.ReactNode) => {
      this.setState({ setProps: props, isBackDrop: true});
      this.addtoCart();
  }
  async componentDidMount() {
    // Customizable Area Start
    this.setState({ productUrl: document.URL })
    const token = await getStorageData("auth-token")
    const userDetails = await getStorageData('userRole', true)
    const stylishBuyer = await getStorageData('exploreBuyer', true)
    if(stylishBuyer){
      this.setState({stylishBuyer})
    }
    if(userDetails?.currencyLogo){
      this.setState({
        userCurrency: userDetails.currencyLogo
      })
    }
    this.setState({tokens:token},() => this.categorey())
    
    const unique_token = await getStorageData('unique_token')
    !unique_token && await setStorageData("unique_token", uuidv4())
    this.scrollToSection("topId")
    // Customizable Area End
  }

  navigateTo = ({
    productId,
    props,
    screenName,
  }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
    this.categorey(productId)
  }

  scrollToSection = (sectionTopIdName:string) => {
    const sectionTop = document.getElementById(sectionTopIdName);
    if (sectionTop) {
        sectionTop.scrollIntoView({ behavior: 'smooth' });
    }
};

  categorey = async (productId?: string) => {
    const idData = productId ? productId : this.props.navigation.getParam("navigationBarTitleText");
    const { tokens } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokens ? tokens : "", 
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcategoriesCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      tokens === null ? `${configJSON.cataloge}/${idData}?currency=dinar` : `${configJSON.catalogeToken}=${idData}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  addtoCart = async () => {
    const idData = this.state.productsData?.attributes?.catalogue_variants_with_store[0].attributes.catalogue_id;
    const catalogue_variantID = this.state.catalogueVariantId;
    const catalogue_informationID = this.state.productsData?.attributes?.catalogue_variants_with_store[0].store_info.id;
    const unique_token = await getStorageData("unique_token");
    const token = await getStorageData("auth-token");
    const endPoint = !token ? `${configJSON.product_addCartUrlWithOutToken}=${unique_token}` : configJSON.product_addCartUrlWithToken
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      unique_token: unique_token,
       token: !token ? undefined : token 
    };
    const body = {
      "quantity": 1,
      "catalogue_id": Number(idData),
      "catalogue_variant_id": Number(catalogue_variantID),
      "bussiness_information_id": Number(catalogue_informationID)
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getaddCartCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_addCart
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
      
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  categoreySimilar = async (cataegreyId?: number | string) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcategoriesId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      token === null ? `${configJSON.getCatalogues}=${cataegreyId}&page=1&per_page=5&currency=${this.state.userCurrency === "$" ? "dollar" : "dinar"}` : `${configJSON.getCataloguesToken}=${cataegreyId}&page=1&per_page=5`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  navigateToData = ({
    productId,
    props,
    screenName,
  }: INavigateTo) => {
    
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
  }
  showeErrorParir = () => {
    this.setState({ isAlert: true, already: "No catalogue pairs available!", severity: "warning" })
  }

  wishlistLike = async (productId: number | string) => {
    if (!this.state.tokens) {
      this.setState({
        modalOpen: true
      });
      return false;
    }
    const token = await getStorageData("auth-token");
    const bodyDelete = { "data": { "favouriteable_id": Number(productId) } }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getproductLickCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouritesLink
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyDelete)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  wishlistLikeRemoved = async (productId: number | string) => {
    if (!this.state.tokens) {
      this.setState({
        modalOpen: true
      });
      return false;
    }
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getproductCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiRemoved
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouritesremoved}=${Number(productId)}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  is_wishlistData = (product: Product, event: React.MouseEvent) => {
    event.stopPropagation()
    if (product.attributes.is_wishlist) {
      this.wishlistLikeRemoved(product.id);
    } else {
      this.wishlistLike(product.id);
    }
  }
  handleWishlistToggle = (catalogue_id: number) => {
    this.setState({favloading: true})
    if (this.state.productsData?.attributes?.is_wishlist) {
      this.wishlistLikeRemoved(catalogue_id);
    } else {
      this.wishlistLike(catalogue_id);
    }
  };
  getaddCart = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getaddCartCallId !== null &&
      this.getaddCartCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && !responseJson.error) {
        setStorageData("addToCart", true)
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductdetailsWeb");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.state.setProps);
        this.send(message);
      } else {
        if(responseJson.error === "Store id must be defined"){
          this.setState({ already: "Sorry, This item can't be added as store is not assigned.", isAlert: true, severity: "warning", isBackDrop: false})
        }
        else{
          this.setState({ already: responseJson.errors, isAlert: true, severity: "warning", isBackDrop: false })
        }
      }
    }
  }
  renderDialog = () => {
    return (
      <AuthenticateUser
        auth={this.state.tokens}
        title="Log in to view your Wishlist."
        description="Shop them anything you like."
        btnText="Log In or Sign up"
        navigation={this.props.navigation}
        onClose={this.closeModal}
      />
    )
  }
  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  handleClick = () => {
    this.setState(prevState => ({ selectedItem: prevState.selectedItem - 1 }));
    this.handleHorizantalScroll(this.elementRef, 25, 100, -10);
  };
  handleNextClick = () => {
    this.setState(prevState => ({ selectedItem: prevState.selectedItem + 1 }));
    this.handleHorizantalScroll(this.elementRef, 25, 100, 10);
  };

  getVariantIdByColorAndSize = async(
    color: string,
    size: string
      
    ): Promise<{ id: string; isInStock: boolean; price: string; renderImages: string[]; discountedPrice: number; discountedPercentage: number }> => {
    const variant = this.state.productsData?.attributes?.catalogue_variants_with_store?.find(
      (variant) =>
        variant.attributes.catalogue_variant_color?.name === color &&
        variant.attributes.catalogue_variant_size?.name === size
    );
    const isInStock = variant ? variant.attributes.stock_qty > 0 : false;
    const renderImages = [variant?.attributes.front_image || "", variant?.attributes.back_image || "", variant?.attributes.side_image || ""]
    return {
      id: variant?.id || "",
      isInStock,
      price: variant?.attributes.price || "",
      renderImages: renderImages,
      discountedPrice: variant?.attributes.discounted_price || 0,
      discountedPercentage: variant?.attributes.discounted_percentage || 0
    };
  };

  getSizesByColor = async (color: string): Promise<string[]> => {
    const { productsData } = this.state;
  
    if (!productsData?.attributes?.catalogue_variants_with_store) {
      return [];
    }
  
    const uniqueSizes = productsData.attributes.catalogue_variants_with_store
      .filter(variant => variant.attributes.catalogue_variant_color?.name === color)
      .map(variant => variant.attributes.catalogue_variant_size?.name || "N/A")
      .filter((size, index, self) => size !== "N/A" && self.indexOf(size) === index);
  
    return uniqueSizes;
  };
  

  handleSizeClick = async (size: Size) => {
    const newSelectedSizeLabel = this.getSizeValue(size.name, "label");
    const variantId = await this.getVariantIdByColorAndSize(this.state.selectColore, size.label);
    this.setState({
      selectedSize: size.label,
      selectedSizeLabel: newSelectedSizeLabel,
      catalogueVariantId: +variantId.id, 
      isInStock: variantId.isInStock,
      productPrice: variantId.price,
      productDiscountedPercentage: variantId.discountedPercentage, productDiscountedPrice: variantId.discountedPrice,
      renderImage: variantId.renderImages,
      selectedItem: 0,
    });
  };
  homePageNavigation = (props: React.ReactNode) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    this.send(message);
  }

  getStoreIdByCatalogVariantId = async (catalogVariantId: number): Promise<string> => {
    const { productsData } = this.state;
  
    if (!productsData?.attributes?.catalogue_variants_with_store) {
      return ""
    }
  
    const variant = productsData.attributes.catalogue_variants_with_store.find(
      (variant: VariantSwithStore) => +variant.id === catalogVariantId
    );
  
    return variant?.store_info?.id || "";
  };

  buyNowPageNavigation = async (props: React.ReactNode) => {
    const stylistBuyNow = await getStorageData("stylitOrder")
    this.setState({isBackDrop: true})
    if (!this.state.tokens) {
      this.setState({
        modalOpen: true
      });
      return false;
    }
    else if(!this.state.isHaveStore && stylistBuyNow !== "stylistBuyNow") {
      this.setState({ already: "Sorry, This item can't be Buy store is not assigned to this product.", isAlert: true, severity: "warning"});
    } else if(!this.state.isInStock){
      this.setState({ already: "Sorry, Product is out of stock", isAlert: true, severity: "warning"});
    } else{
      const {catalogueVariantId} = this.state;

      const storeId = await this.getStoreIdByCatalogVariantId(catalogueVariantId);

      const body = {
        "quantity": 1,
        "catalogue_id": this.props.navigation.getParam("navigationBarTitleText"),
        "catalogue_variant_id":catalogueVariantId,
        "bussiness_information_id": storeId,
      }
      const body1 = {
        "quantity": 1,
        "catalogue_id": this.props.navigation.getParam("navigationBarTitleText"),
        "catalogue_variant_id":catalogueVariantId,
      }

      this.DirectBuyNowAPICallId = await apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.directOrderAPIEndPoint,
        token: await getStorageData("auth-token"),
        body: stylistBuyNow !== "stylistBuyNow" ?body:  body1,
        isJsonStringify: true
      });
    }
  }

  categoreySimilarData = async () => {
    const unique_token = await getStorageData("unique_token");
    const token = await getStorageData("auth-token")
    const tokenAuthr = token === null ? `${configJSON.getActiveCartView}=${unique_token}` :
      `${configJSON.getUnique_token}=${unique_token}`
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      unique_token: unique_token,
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcategoriesCardId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      tokenAuthr
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getAddressStylist = () => {
    const { address, area, block, mall_name, floor, unit_number, city, zipcode } = this.state.productsData.attributes && this.state.productsData.attributes.owner_address || {
      address: "",
      area: "",
      block: "",
      mall_name: "",
      floor: "",
      unit_number: "",
      city: "",
      zipcode: ""
    }
    const addressParts = [address, area, block, mall_name, floor, unit_number, city, zipcode].filter(part => part).join(', ');
    return addressParts
  }

  handleShareProduct = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: this.state.anchorEl ? null : event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null, linkCopied: false });
  };

  handleCopyLink = () => {
    navigator.clipboard.writeText(this.state.productUrl);
    this.setState({ linkCopied: true });
  };
  // Customizable Area End
}
