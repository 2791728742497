import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { IMyOfferDataResp } from "./StylishMyOfferController";
import { logoutSellerNavigation } from "../../../../components/src/Seller/logOut";
import { ISuccessBidResp } from "./StylishProductSourcingController";
import { getStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../components/src/APICall";
import { Attributes } from "../../../chat/src/ClientChatController";
import i18n from "../../../../components/src/i18next/i18n";

interface AccountsChat {
  id: string;
  type: string;
  attributes: {
    profile_pic?: string;
    account_id: number;
    role: string;
    account_role: string;
    account_name: string;
    muted: boolean;
    account_phone: string;
  };
}

interface ChatResponse {
    data: {
      attributes: {
        accounts_chats: {
          id: string;
          type: string;
          attributes: {
            account_id: number;
            muted: boolean;
            role: string;
            account_role: string;
            account_name: string;
            account_phone: string;
            profile_pic?: string;
          };
        }[]
      }
    };
    errors?: {[errKey:string]: string}[]
}

interface ErrorDetail{
  quote_price?: string;
  product_description?: string;
  images?: string;
};
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  bidRequestChatResp: IMyOfferDataResp;
  severity: "error" | "success" | "info";
  message: string;
  isLoading: boolean;
  stylistProfile: string;
  isAlert: boolean;
  stylistFullName: string;
  chat_id: string | number;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylistProductSourcingChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductInfoAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      isAlert: false,
      stylistFullName: "",
      bidRequestChatResp: {} as IMyOfferDataResp,
      isLoading: true,
      severity: "error",
      stylistProfile: "",
      message: "",
      chat_id: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getProductSourcingChatAPICalled();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonPSCData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseJsonPSCData &&
        !responseJsonPSCData.errors &&
        !responseJsonPSCData.error
      ) {
        this.apiStylistProductSourcingChatSuccess(
          apiRequestCallId,
          responseJsonPSCData
        );
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  apiStylistProductSourcingChatSuccess = async (
    apiRequestCallId: string,
    responseChatJson: ISuccessBidResp & ChatResponse & { data: { attributes: { [key: string]: string | null}}}
  ) => {
    if (apiRequestCallId === this.getProductInfoAPICallId) {
      if (responseChatJson) {
        this.setState((prevState) => ({
          ...prevState,
          bidRequestChatResp: responseChatJson.data,
          isLoading: false
        }));
      }
    }
  };

  handleMainPageChat = async (message: string, redirect: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        severity: "error",
        message: message,
        isAlert: true,
        isLoading: false,
      }),
      () => {
        setTimeout(() => {
          this.handleStylishProductSourcingChatRedirect(redirect);
          if (redirect === "Home") logoutSellerNavigation();
        }, 2000);
      }
    );
  };

  handleStylishProductSourcingChatRedirect = (redirect: string) => {
    const userNavigateMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavigateMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      redirect
    );
    userNavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavigateMsg);
  };

  getProductSourcingChatAPICalled = async () => {
    const bidRequestId = await this.getDataChatStorage("bid-request-id");
    if (+bidRequestId === 0)
      await this.handleMainPageChat(
        this.transStylishProductSourcingChat("Please Select Bid Request to See Data"),
        "StylishMyOffer"
      );
    this.getProductInfoAPICallId = await apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getMyOfferAPIEndpoint}/${+bidRequestId}`,
      token: await this.getDataChatStorage("auth-token"),
    });
  };

  getDataChatStorage = async (value: string) => {
    return await getStorageData(value);
  };

  transStylishProductSourcingChat = (transKey: string) => {
    return i18n.t( transKey, {ns: "catalogue"})
  }

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
