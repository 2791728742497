import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ChangeEvent } from "react";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { APIPayloadType, ErrorMessage } from "./CreateSellerStoreController";
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import { logoutSellerNavigation } from "../../../components/src/Seller/logOut";

export interface MyStoreList {
  id: string;
  type: string;
  attributes: {
    store_name: string;
    description: string;
    address: string;
    area: string;
    block: string;
    mall_name: string;
    floor: string;
    unit_number: number | null;
    city: string;
    zipcode: string;
    driver_instruction: string;
    average_shipping_time: string;
    payment_mode: string[];
    store_operating_hours: {
      monday: DayOpenClose;
      tuesday: DayOpenClose;
      wednesday: DayOpenClose;
      thursday: DayOpenClose;
      friday: DayOpenClose;
      saturday: DayOpenClose;
      sunday: DayOpenClose;
    };
    status: "Pending" | "Approved" | "Rejected";
    latitude: number;
    longitude: number;
    is_open: boolean;
    available_variants: [];
    image: string;
    email: string;
    contact_number: {
      country_code: string;
      phone_number: string;
    };
    expected_delivery_time: string;
  };
}

export interface DocumentUploadResp {
  data: {
    id: string;
    type: string;
    attributes: {
      id_proof: string;
      license: string;
    };
  };
}

export interface DayOpenClose {
  open: string;
  close: string;
  is_open: boolean;
}

export interface MyStoreAPIResponse {
  data: MyStoreList[];
  meta: {
    all_businesses_open: boolean;
  };
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  myStoreResp: MyStoreList[];
  selectedData: MyStoreList[];
  searchQuery: string;
  errorsMessageStep2: {
    errorIdUpload?: string;
    idUploadError?: boolean;
    errorLicenseUpload?: string;
    licenseUploadError?: boolean;
  };
  step2: {
    idFile: File | null;
    previewId: string;
    licenseFile: File | null;
    previewLicense: string;
  };
  isPopup: boolean;
  isLoader: boolean;
  alertError: {
    message: string;
    severity: "error" | "warning" | "info" | "success";
    isAlert: boolean;
  };
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MySellerStoreController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private debounceTimeout: NodeJS.Timeout | null = null;
  myStoreAPICallId: string = "";
  sellerDocumentAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      activeStep: 0,
      myStoreResp: [],
      selectedData: [],
      isLoader: true,
      searchQuery: "",
      errorsMessageStep2: {
        errorIdUpload: "",
        idUploadError: false,
        errorLicenseUpload: "",
        licenseUploadError: false,
      },
      step2: {
        idFile: null,
        previewId: "",
        licenseFile: null,
        previewLicense: "",
      },
      isPopup: false,
      alertError: {
        message: "",
        severity: "error",
        isAlert: false
      }
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.filterData = this.filterData.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.apiSuccessCalled(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.apiFailureCalled(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    this.getMyStoreApi();
    // this.handleTokenData();
    // Customizable Area End
  }

  getMyStoreApi = async () => {
    this.myStoreAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.sellerMyStoreEndPoint,
      token: await this.getTokenData(),
    });
  };

  getTokenData = async () => {
    return await getStorageData("auth-token");
  };

  handleRedirection = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
      data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiSuccessCalled = async (
    apiRequestCallId: string,
    responseJson: MyStoreAPIResponse & DocumentUploadResp
  ) => {
    if (apiRequestCallId === this.myStoreAPICallId) {
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          myStoreResp: responseJson.data,
          selectedData: responseJson.data,
          isLoader: false
        }));
      }
    } else if (apiRequestCallId === this.sellerDocumentAPICallId) {
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          isPopup: true,
        }));
      }
    }
  };

  apiFailureCalled = (responseJson: ErrorMessage) => {
    if (responseJson.errors[0].token) {
      this.setState((prevState) => ({
        ...prevState,
        alertError: {
          ...prevState.alertError,
          severity: "error",
          message: responseJson.errors[0].token as string,
          isAlert: true,
        },
      }), () => {
        setTimeout(() => {
          this.handleRedirection("Home");
          logoutSellerNavigation();
        }, 2000);
      });
    }
  };

  handleBackward = () => {
    if (this.state.activeStep > 0) {
      this.setState((prevState) => ({
        ...prevState,
        activeStep: prevState.activeStep - 1,
      }));
    } else {
      this.handleRedirection("CreateSellerStore");
    }
  };

  handleNext = () => {
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep + 1,
    }));
  };

  debounce(func: Function, delay: number) {
    return (...args: any[]) => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => func(...args), delay);
    };
  }

  handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const query = event.target.value.toLowerCase();
    this.setState({ searchQuery: query });
    this.debounce(this.filterData, 300)();
  }

  filterData() {
    const { myStoreResp, searchQuery } = this.state;
    if (!searchQuery) {
      this.setState({ selectedData: myStoreResp });
      return;
    }

    const filteredData = myStoreResp.filter(
      (store) =>
        store.attributes.store_name.toLowerCase().includes(searchQuery) ||
        store.attributes.status.toLowerCase().includes(searchQuery)
    );
    this.setState({ selectedData: filteredData });
  }

  handleIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState((prevState) => ({
        ...prevState,
        step2: {
          ...prevState.step2,
          idFile: file,
          previewId: URL.createObjectURL(file),
        },
        errorsMessageStep2: {
          ...prevState.errorsMessageStep2,
          errorIdUpload: "",
          idUploadError: false,
        },
      }));
    }
  };

  handleLicenseChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState((prevState) => ({
        ...prevState,
        step2: {
          ...prevState.step2,
          licenseFile: file,
          previewLicense: URL.createObjectURL(file),
        },
        errorsMessageStep2: {
          ...prevState.errorsMessageStep2,
          errorLicenseUpload: "",
          licenseUploadError: false,
        },
      }));
    }
  };

  validateIdUpload = () => {
    const { idFile } = this.state.step2;
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const maxFileSize = 5 * 1024 * 1024;

    if (!idFile) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep2: {
          ...prevState.errorsMessageStep2,
          errorIdUpload: `${configJSON.idUploadValidMsg}${configJSON.afterReqMessage}`,
          idUploadError: true,
        },
      }));
      return false;
    } else if (idFile.size > maxFileSize) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep2: {
          ...prevState.errorsMessageStep2,
          errorIdUpload: `File size should be less than 5 MB`,
          idUploadError: true,
        },
      }));
      return false;
    }else if (!validFileTypes.includes(idFile.type)) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep2: {
          ...prevState.errorsMessageStep2,
          errorIdUpload: 'Only .png, .jpg, and .jpeg files are allowed',
          idUploadError: true,
        },
      }));
      return false;
    }
    return true;
  };

  validateLicenseUpload = () => {
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const { licenseFile } = this.state.step2;
    const maxFileSize = 5 * 1024 * 1024;

    if (!licenseFile) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep2: {
          ...prevState.errorsMessageStep2,
          errorLicenseUpload: `${configJSON.licenseUploadValidMsg}${configJSON.afterReqMessage}`,
          licenseFileUploadError: true,
        },
      }));
      return false;
    } else if (licenseFile.size > maxFileSize) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep2: {
          ...prevState.errorsMessageStep2,
          errorLicenseUpload: `File size should be less than 5 MB`,
          licenseFileUploadError: true,
        },
      }));
      return false;
    }else if (!validFileTypes.includes(licenseFile.type)) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep2: {
          ...prevState.errorsMessageStep2,
          errorLicenseUpload: 'Only .png, .jpg, and .jpeg files are allowed',
          licenseFileUploadError: true,
        },
      }));
      return false;
    }

    return true;
  };

  handleSubmit2 = (submitEvent: React.FormEvent) => {
    submitEvent.preventDefault();
    if (!this.validateIdUpload()) {
      return;
    }
    if (!this.validateLicenseUpload()) {
      return;
    }

    this.postSellerDocumentApi();
  };

  postSellerDocumentApi = async () => {
    const { step2 } = this.state;
    const formData = new FormData();
    if (step2.idFile) {
      formData.append("id_proof", step2.idFile);
    }
    if (step2.licenseFile) {
      formData.append("license", step2.licenseFile);
    }

    this.sellerDocumentAPICallId = await this.apiCall({
      method: configJSON.httpPostMethod,
      endPoint: configJSON.sellerDocumentEndPoint,
      token: await this.getTokenData(),
      body: formData,
      isJsonStringify: false,
    });
  };

  handleClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPopup: !prevState.isPopup,
    }));
  };

  handleLogout = async () => {
    removeStorage.forEach(async (value) => {
      await removeStorageData(value);
    });
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  onAlert = () => {
    this.setState((prevState) => ({
      ...prevState,
      alertError: {
        ...prevState.alertError,
        isAlert: !prevState?.alertError.isAlert,
      },
    }));
  };
  // Customizable Area End
}
// Customizable Area Start

const removeStorage = ["orderNumber", "auth-token", "sms-token", "fcm_token"];
// Customizable Area End
