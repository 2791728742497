import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  styled,
  TextField,
  Box,
  InputAdornment,
} from "@material-ui/core";
import ClientNewPaymentRequestController, {
  Props,
  validationSchema,
} from "./ClientNewPaymentRequestController";
import { ThemeProvider } from "react-native-elements";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import { backArrow } from "./assets";
import ReusableSnackbar from "../../../components/src/AlertContainer";
import { Formik } from "formik";
import {
  FormError,
  FormErrorTouched,
} from "../../customform/src/CreateSellerStoreController";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ClientNewPaymentRequest extends ClientNewPaymentRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container dir={i18n.dir()}>
          <Grid item xl={2} md={2} lg={2} sm={2} xs={2}>
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid item xl={10} md={10} lg={10} sm={10} xs={10}>
            <StylishHeader navigation={this.props.navigation} />
            <Formik
              initialValues={{
                reasonRequest: "",
                reqAmount: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.handleNewPaymentRequestSubmit(values);
              }}
            >
              {({ errors, touched, handleSubmit, getFieldProps }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <ClientNewRequestContainer>
                      <ClientNewRequestTitleContainer>
                        <img
                          style={{
                            transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none'
                          }}
                          id="paymentBack"
                          data-test-id="navigateToClientChat"
                          onClick={() =>
                            this.navigateToClientChat("ClientChat")
                          }
                          src={backArrow}
                          alt="backIcon"
                        />
                        <Typography className="main-title">
                          {this.transPayment("New Payment Request")}
                        </Typography>
                      </ClientNewRequestTitleContainer>
                      <Box className="requestFormContainer">
                        <Box className="inputBoxContainer">
                          <Typography className="inputNameTxn">
                            * {this.transPayment("Reason for Requesting")}
                          </Typography>
                          <ClientNewRequestCustomInput
                            {...getFieldProps("reasonRequest")}
                            id="outlined-basic"
                            placeholder={this.transPayment("Reason for Requesting")}
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            FormHelperTextProps={{
                              style: {
                                textAlign: i18n.dir() === 'rtl' ? 'right' : 'left'
                              }
                            }}
                            error={
                              this.getErrorAndHelperText(
                                "reasonRequest",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                            inputProps={{ maxLength: 240 }}
                            helperText={
                              this.getErrorAndHelperText(
                                "reasonRequest",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          />
                        </Box>
                        <Box className="inputBoxContainer">
                          <Typography className="inputNameTxn">
                            * {this.transPayment("Requesting Amount")}
                          </Typography>
                          <ClientNewRequestCustomInput
                            {...getFieldProps("reqAmount")}
                            id="standard-adornment-amount"
                            variant="standard"
                            placeholder={this.transPayment("Requesting Amount")}
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography className="start-adornment">
                                    $
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{ maxLength: 6 }}
                            FormHelperTextProps={{
                              style: {
                                textAlign: i18n.dir() === 'rtl' ? 'right' : 'left'
                              }
                            }}
                            error={
                              this.getErrorAndHelperText(
                                "reqAmount",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                            helperText={
                              this.getErrorAndHelperText(
                                "reqAmount",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          />
                        </Box>
                        <Box className="inputBoxContainer">
                          <Button className="submitBtn" type="submit">
                            {this.transPayment("Edit Terms & Conditions")}
                          </Button>
                        </Box>
                      </Box>
                    </ClientNewRequestContainer>
                  </form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertNPBoxClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const ClientNewRequestContainer = styled(Box)({
  minHeight: "calc(100vh - 85px)",
  padding: "36px 30px",
  "& .requestFormContainer": {
    marginTop: 20,
    padding: 25,
    width: "445px",
    boxShadow: "0px 2px 8px 0px #00000014",
  },
  "& .inputBoxContainer": {
    padding: "10px 0",
  },
  "& .submitBtn": {
    width: "100%",
    height: "53px",
    fontWeight: 800,
    fontSize: "20px",
    textTransform: "none",
    background: "#CCBEB1",
    color: "white",
    borderRadius: "4px",
    fontFamily: "Lato, sans-serif"
  },
  "& .inputNameTxn": {
    color: "#375280",
    fontFamily: "Lato, sans-serif",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: "10px",
  },
});
const ClientNewRequestTitleContainer = styled(Box)({
  justifyContent: "flex-start",
  display: "flex",
  gap: 20,
  paddingBottom: 24,
  alignItems: "center",
  "& .InputDesign": {
    width: "97%",
    color: "#375280",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    marginBottom: "5px",
  },
  "& .main-title": {
    color: "#375280",
    fontFamily: "Lato, sans-serif",
    fontSize: 24,
    width: "100%",
    fontWeight: 700,
  },
});
export const ClientNewRequestCustomInput = styled(TextField)({
  background: "#f1f1f1",
  height: "43px",
  border: "1px solid #F8F8F8",
  borderRadius: "4px",
  width: "100%",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  paddingTop: 8,
  marginBottom: 10,
  "& .MuiInputBase-input": {
    padding: "6px 15px 7px",
  },
  "& ::placeholder": {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    opacity: 1,
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  "& .MuiInputBase-root": {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  "& .MuiInputBase-root.MuiInput-root.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedStart":{
    marginLeft: 15,
    "& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart": {
      paddingLeft: 0,
    }
  },
  "& .MuiFormHelperText-root.Mui-error":{
    marginTop: 10,
  },
  "& .MuiInputAdornment-positionStart":{
    "& .MuiTypography-root.start-adornment": {
      color: "#375280",
      fontSize: "16px",
      opacity: 1,
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      fontStyle: "normal !important",
      fontFamily: "Lato , sans-serif",
    }
  }
});
// Customizable Area End
