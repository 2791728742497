import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  TextField,
  withStyles
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import Footer from "../../../components/src/Footer";
import StylistAccountActiveProfileController, {
  Props,
  configJSON
} from "./StylistAccountActiveProfileController.web";
import SellerHeaderActive from "../../../components/src/Seller/SellerHeaderActive";
import CustomizedSteppers from "../../../components/src/Stylish/StylistStepper";
import { backImg, view1, document, buttonDelete, button_arrow } from "./assets";
import i18n from "../../../components/src/i18next/i18n"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylistAccountActiveProfile extends StylistAccountActiveProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleInputChange = this.handleInputChange.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { errors } = this.state;
    return (
      <>
        <SellerHeaderActive navigation={this.props.navigation} />
        <MainGrid dir={i18n.dir()} data-test-id="mainGridContainer1" container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imgGrid">
            <Box className="sideImageBox">
              <img src={backImg} alt="" className="sideImage" />
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
            <Box className={"formBox"} data-testid="profile">
              <form data-test-id="formSubmittedSuccess" style={{ width: "90%" }}>
                <Box width={"100%"}>
                  <Box style={{ display: "flex", alignItems: "center", gap: "25px", marginBottom: "30px" }}>
                    <img src={i18n.dir() === 'rtl' ? button_arrow : view1} alt="" className="view" data-testid="backButtonLgin" onClick={this.backButtonLgin} />
                    <Typography className={"welcomeText"}>{this.transStylist(configJSON.createProfile)}</Typography>
                  </Box>
                  <Box style={{ color: "#375280", fontFamily: "Lato" }}>
                    <CustomizedSteppers
                      dir={i18n.dir()}
                      activeStep={0}
                      steps={[`${this.transStylist('Profile Setup')}`, `${this.transStylist('Contact & Payment')}`, `${this.transStylist('Portfolio')}`]}
                      stepCount={3}
                    />
                  </Box>
                  <Box>
                    <Box style={{ paddingTop: '23px' }}>
                      {this.state.combineImg.length == 0 ?
                        <Box style={webStyle.documentBox as React.CSSProperties}>
                          <Box>
                            <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                              <img className="fieldImg" alt="addimg" src={document} data-testid="openPictureUpload" onClick={() => { this.openPictureUpload(0) }} />
                            </Box>
                            <Typography className="fieldAreaLabel portableNewOne" style={webStyle.uplodeText}>{this.transStylist('Upload Profile Image')}</Typography>
                          </Box>
                        </Box>
                        :
                        <Box style={{
                          position: 'relative',
                          textAlign: 'center',
                          color: 'white'
                        }}>
                          <img className="uploadBoximg" src={this.state.combineImg[0] || undefined} style={{ cursor: "pointer", width: "100%", height: '516px' }} />
                          <Box style={{
                            position: 'absolute',
                            top: '28px',
                            right: '28px',
                          }}><img src={buttonDelete} width="48px" height="48px" data-testid="handleRemove" onClick={this.handleRemove} /></Box>
                          <Box onClick={() => { this.openPictureUpload(0) }} data-testid="replaseUplodePhoto" style={{ position: 'absolute', ...webStyle.replasePhoto }}><div style={{ display: 'flex', justifyContent: 'center' }}> {this.transStylist('Replace Photo')}</div></Box>
                        </Box>
                      }
                    </Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Profile Bio')}*</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      inputProps={{ 'data-testid': 'profileBio', maxLength: 420, } as React.InputHTMLAttributes<HTMLInputElement> & {
                        'aria-label'?: string;
                        'data-testid'?: string;
                      }}
                      name="profileBio"
                      value={this.state.formData.profileBio}
                      onChange={this.handleInputChange}
                      className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    />
                    {errors.profileBio && <span style={webStyle.allErrors}>{errors.profileBio}</span>}
                    <Typography className={"inputHeading"}>{this.transStylist('Area of Expertise')}*</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      inputProps={{ 'data-testid': 'areaofExpertise', maxLength: 420, } as React.InputHTMLAttributes<HTMLInputElement> & {
                        'aria-label'?: string;
                        'data-testid'?: string;
                      }}
                      data-test-id="emailTextInput"
                      type="text"
                      name="areaofExpertise"
                      value={this.state.formData.areaofExpertise}
                      onChange={this.handleInputChange}
                      className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    />
                    {errors.areaofExpertise && <span style={webStyle.allErrors}>{errors.areaofExpertise}</span>}
                    <Typography className={"inputHeading"}>{this.transStylist('Years of Experience')}*</Typography>
                    <TextField
                      variant="outlined"
                      data-test-id="socailmediaAccount"
                      type="text"
                      placeholder={this.transStylist("0 Years")}
                      inputProps={{ 'data-testid': 'yearsofExperience', maxLength: 2, } as React.InputHTMLAttributes<HTMLInputElement> & {
                        'aria-label'?: string;
                        'data-testid'?: string;
                      }}
                      fullWidth
                      name="yearsofExperience"
                      value={this.state.formData.yearsofExperience}
                      onChange={this.handleInputChange}
                      className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    />
                    {errors.yearsofExperience && <span style={webStyle.allErrors}>{errors.yearsofExperience}</span>}
                    <Typography className={"inputHeading"}>{this.transStylist('Social Media Links')}</Typography>
                    <TextField
                      fullWidth
                      style={{ marginBottom: '26px' }}
                      variant="outlined"
                      data-test-id="ibanNumber"
                      placeholder="www.instagram.com"
                      inputProps={{ 'data-testid': 'instagram' } as React.InputHTMLAttributes<HTMLInputElement> & {
                        'aria-label'?: string;
                        'data-testid'?: string;
                      }}
                      type="text"
                      name="instagram"
                      value={this.state.formData.instagram}
                      onChange={this.handleInputChange}
                      className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    />
                    <TextField
                      style={{ marginBottom: '26px' }}
                      fullWidth
                      variant="outlined"
                      data-test-id="ibanNumber"
                      placeholder="www.facebook.com"
                      name="facebook"
                      value={this.state.formData.facebook}
                      onChange={this.handleInputChange}
                      inputProps={{ maxLength: 2048, }}
                      className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    />
                    <TextField
                      style={{ marginBottom: '26px' }}
                      fullWidth
                      variant="outlined"
                      data-test-id="ibanNumber"
                      placeholder="www.tiktok.com"
                      name="tiktok"
                      inputProps={{ maxLength: 2048, }}
                      value={this.state.formData.tiktok}
                      onChange={this.handleInputChange}
                      className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    />
                    <TextField
                      style={{ marginBottom: '26px' }}
                      fullWidth
                      variant="outlined"
                      data-test-id="ibanNumber"
                      placeholder="www.pinterest.com"
                      name="pinterest"
                      inputProps={{ maxLength: 2048, }}
                      value={this.state.formData.pinterest}
                      onChange={this.handleInputChange}
                      className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    />
                    <Typography className={"inputHeading"}>{this.transStylist('Preferred Language')}*</Typography>
                    <Box>
                      <CustomSelect variant="outlined" style={{ width: '100%', height: '56px', backgroundColor: '#F0F0F0' }}>
                        <MuiSelectSelect
                          style={webStyle.reminderName}
                          value={this.state.numberData === null ? "Select Language" : this.state.numberData}
                          onChange={this.handleSingleChangeNumber}
                          displayEmpty
                          name="numberData"
                          inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                          data-testid='sub-categories-selection'
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                          }}
                        >
                          <MenuItem value="Select Language" aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>{this.transStylist('Select Language')}</Box></MenuItem>
                          <MenuItem value="arabic" aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>{this.transStylist('Arabic')}</Box></MenuItem>
                          <MenuItem value="english" aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>{this.transStylist('English')}</Box></MenuItem>
                        </MuiSelectSelect>
                      </CustomSelect >
                      {this.state.errorsSelectLanguage && <Typography variant="caption" style={webStyle.allErrors}>*{this.transStylist('Please choose a language')}</Typography>}
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", gap: "16px", marginTop: '24px' }}>
                    <Button
                      variant="contained"
                      className={"backButton"}
                      data-testid="backButtonLgin" onClick={this.backButtonLgin}
                      disableElevation
                      type="submit"
                    >{this.transStylist('Back')}
                    </Button>
                    <Button
                      variant="contained"
                      className={"nextButton"}
                      data-testid="getNavigationActiveAddressPro"
                      onClick={(event) => this.handleSubmit(event)}
                    >
                      {this.transStylist('Next')}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  replasePhoto: {
    top: "90%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF", width: '80%',
    color: "#375280", fontSize: '18px', fontFamily: 'Lato', padding: '15px 0px', fontWeight: 500
  },
  allErrors: { color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", },
  uplodeText: { color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 700 },
  reminderName: { backgroundColor: '#F0F0F0', height: '56px' },
  newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
  documentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '14.5rem',
    width: '100%',
    backgroundColor: '#F0F0F0',
    position: "relative",
  },
}
const CustomSelect = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff',
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent"
      }
    },
  },
}))(FormControl);
const MuiSelectSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#e9eef4',
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderColor: "#e9eef4",
  },

})
const MainGrid = styled(Grid)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  display: "flex",
  justifyContent: "center",
  "& .sideImageBox": {
    width: "100%",
    height: "100%",
  },
  "& .sideImage": {
    objectFit: 'cover',
    width: "100%",
    height: "100%",
  },
  "& .imgGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .welcomeSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px",
    alignSelf: "flex-start",
    margin: "10px 0px 0px 0px"
  },
  "& .inputFeild": {
    gap: "4px",
    border: "none",
    borderRadius: "0px"
  },
  "& .phoneInput": { display: "flex", width: "520px" },
  "& .formGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "7%"
  },
  "& .formBox": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    marginTop: "30px"
  },
  "& .welcomeText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: 'Lato , sans-serif',
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    alignSelf: "flex-start",

  },
  "& .inputHeading": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "20px 10px 10px 0px",
    textOverflow: "ellipsis",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },
  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px"
  },
  "& .backButton": {
    textTransform: "capitalize",
    width: "50% !important",
    borderRadius: "0px !important",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    gap: "8px",
    border: "1px solid #CCBEB1",
    backgroundColor: "white",
    height: "56px !important",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
  "& .nextButton": {
    textTransform: "capitalize",
    width: "50% !important",
    borderRadius: "0px !important",
    color: "white !important",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    gap: "8px",
    height: "56px !important",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
  "& .condition": {
    color: "var(--Neutrals-Cool-gray-500, #375280)",
    cursor: "pointer",
  },
  "& .terms": {
    color: "#979797 !important",
    margin: "10px 10px 10px 10px !important",
    width: "520px !important",

    fontFamily: "Lato ,sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },

  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },

  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    }
  },
  "& .Register": {
    padding: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .socialLogin": {
    padding: "20px",
    width: "100%",
    display: "flex",
    gap: "35px",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .registerButton": {
    cursor: "pointer",
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    margin: "10px 10px 0 10px",

    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 700,
    lineHeight: "24px !important",
  },
  "& .bottomText": {
    color: "#375280 !important",

    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },
  "& .continueText": {
    width: "100%",
    color: "#375280 !important",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    display: "flex",
    marginTop: "15px",
    flexDirection: "row",
    "&:before, &:after": {
      content: "''",
      flex: "1 1",
      borderBottom: "1px solid",
      margin: "auto"
    },
    "&:before": {
      marginRight: "25px"
    },
    "&:after": {
      marginLeft: "25px"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px", flexWrap: "wrap" },
    "& .Register": {
      width: "350px !important",
    },
    "& .socialLogin": {
      width: "350px !important",
    },
    "& .terms": {
      width: "350px !important",
    },
    "& .backButton": {
      width: "100% !important",
      "& .MuiButton-label": {
        fontWeight: 600,
        fontSize: "16px"
      }
    },
    "& .phoneInput": { width: "370px" },
    "& .continueText": {
      width: "100%",
      color: "#375280 !important",
      fontFamily: "Lato, sans-serif",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      fontWeight: 400,
      lineHeight: "24px !important",
      display: "flex",
      marginTop: "15px",
      flexDirection: "row",
      "&:before, &:after": {
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto"
      },
      "&:before": {
        marginRight: "25px"
      },
      "&:after": {
        marginLeft: "25px"
      }
    },
  },
}));
// Customizable Area End
