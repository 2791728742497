import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start

import { getStorageData, setStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showLanguage?:boolean;
  succesfullyLanguageUpdate?:() => void;
  // Customizable Area End
}

// Customizable Area Start
export interface AddressDataType {
  latitude: number;
  longitude: number;
  address: string;
  address_type: string;
}

export interface AddressType {
  id: string;
  type: string;
  attributes: AddressDataType;
}
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
}
export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}
export interface AddressValueType {
  value: string;
}

export interface CountryLanguageType {
  data:{
  id:	string;
type:	string;
attributes:{
  id:number;
  language:	string;
  currency:	string;
}
}}

export interface Address {
  id: string;
  attributes: {
    address_name: string;
    is_default: boolean;
    name: string;
    phone_number: string;
    street: string;
    block: string;
    area: string;
    city: string;
    zipcode: string;
  };
}
interface AddressAttributes {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zipcode: string;
  area: string;
  block: string;
  city: string;
  house_or_building_number: string;
  floor: string | null;
  address_name: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}

interface AddressData {
  id: string;
  type: string;
  attributes: AddressAttributes;
}

interface UpdateAddressResponse {
  data: AddressData;
  meta: {
      message: string;
  };
}
interface DeleteAddressResponse {
  message: string;
}
type AlertType = 'success' | 'error' | 'warning' | 'info';

export const AdressTypeData = [
  {
    value: "Home", label: 'Home'
  },
  {
    value: "Work", label: 'Work'
  },
  {
    value: "Other", label: 'Other'
  },
];
// Customizable Area End

interface S {
  // Customizable Area Start
  countrySuccess:Array<CountrySuccess>;
  txtInputAddressValue: string;
  txtInputLatValue: string;
  txtInputLngValue: string;
  token: string;
  addressTypeValue: string;
  addressList: Array<AddressType> | null;
  isChecked: boolean;
  addressData: Address[];
  openDropdownId: string | null;
  deletedResponce:string;
  isAlert: boolean;
  alertMsg: string;
  alertType: AlertType;
  selectedCountry:CountrySuccess | null;
  dropdownOpen:boolean;
  isCountryData:string;
  updateAddresss:string;
  language: string;
  currency: string;
  fullName: string;
  stylishBuyer: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CurrencylanguageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiAddAddressCallId: string = "";
  apiGetAllAddressCallId: string = "";

  // Customizable Area Start
  showAddressCallId: string = "";
  getAddressCallId: string = "";
  deleteAddressCallID: string = "";
  countryCodeAPIEditCallId:string='';
  updateApiCallId:string = "";
  getLanguageCurrencyApiCallid:string = "";
  updateLanguageCurrency:string = "";
  updateLanguageApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
          ];

    this.state = {
      txtInputAddressValue: "",
      txtInputLatValue: "",
      txtInputLngValue: "",
      addressTypeValue: AdressTypeData[0].value,
      addressList: null,
      token: "",
      addressData:[],
      isChecked: false,
      openDropdownId: null,
      deletedResponce:"",
      isAlert: false,
      alertMsg: '',
      alertType: 'success' as AlertType,
      countrySuccess:[{
        numeric_code:"",
        country_full_name:"",
        country_code:"",
        country_flag:""
    }],
    updateAddresss:"",
    selectedCountry:null,
    dropdownOpen:false,
    isCountryData:"+965",
    language: `${this.tranLanguageCurrency("English")}`,
    currency: `${this.tranLanguageCurrency("Dollar")}`,
    fullName: "", 
    stylishBuyer: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const name = await getStorageData('user_data', true);
    const stylishBuyer = await getStorageData('exploreBuyer', true);
    if(stylishBuyer) this.setState({stylishBuyer})
    this.setState({
      fullName: `${name?.first_name} ${name?.last_name}`
    });
      this.getLanguageCurrency()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

            if (responseJson && !responseJson.errors) {
              this.apiSucessCall(apiRequestCallId, responseJson);
         } 
         if (apiRequestCallId === this.updateLanguageApiCallId && responseJson?.data) {
          const userRole = await getStorageData("userRole", true);
          const lang = responseJson.data.attributes.language === this.tranLanguageCurrency("English") ? "0" : "1"
          const languageValue =   responseJson.data.attributes.language  ===  this.tranLanguageCurrency("English") ?  "0" :  "1" 
          this.i18nUpdate(lang)
          const currency = responseJson.data.attributes.currency === this.tranLanguageCurrency("Dollar") ? "0" : "1"
          const currencyLogo =  (responseJson.data.attributes.currency ===  "Dollar" || responseJson.data.attributes.currency === "الدولار") ? "$" : "KWD"
          this.props.succesfullyLanguageUpdate?.();
           setStorageData("userRole", JSON.stringify({
            userType: userRole.userType,
            language: lang,
            currency: currency,
            currencyLogo: currencyLogo,
            languageValue: languageValue
          }));
            window.location.reload();
        }
    } 
    
    // Customizable Area End
  }

   // Customizable Area Start

   i18nUpdate = async (lang: "0" | "1") => {
    const updatedLang = lang === "0" ? "en" : "ar"
    i18n.changeLanguage(updatedLang);
    await setStorageData("lang", updatedLang);
   }

   navigationTo = async (pageLink: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
};
handleCondition = (condition: any, trueSta: any, falseSta: any) => {
  return condition ? trueSta : falseSta
}
 
   apiSucessCall = async (apiRequestCallId: string, responseJson: CountryLanguageType) => {
    let language: "0" | "1" = "0"
    if (apiRequestCallId === this.getLanguageCurrencyApiCallid && responseJson?.data) {
        this.setState({
            language: this.tranLanguageCurrency(responseJson.data.attributes.language),
            currency: `${this.tranLanguageCurrency(responseJson.data.attributes.currency)}`
        });
    }
    if (apiRequestCallId === this.updateLanguageCurrency && responseJson?.data) {
    const userRole = await getStorageData("userRole", true);
    language = this.handleCondition( responseJson.data.attributes.language === this.tranLanguageCurrency("English") , "0" , "1")
    const languageValue =   this.handleCondition(responseJson.data.attributes.language  ===  this.tranLanguageCurrency("English") , "0" , "1")
    const currency = this.handleCondition(responseJson.data.attributes.currency ===  "Dollar"  , "0" , "1");
    const currencyLogo =  (responseJson.data.attributes.currency ===  "Dollar" || responseJson.data.attributes.currency === "الدولار") ? "$" : "KWD"
    this.i18nUpdate(language)
    this.setState({
      isAlert: true,
      alertMsg: `${this.tranLanguageCurrency("your language and currency has been updated successfully")}`,
      language: responseJson.data.attributes.language,
      currency: `${this.tranLanguageCurrency(responseJson.data.attributes.currency)}`
    })
    const tempRole = {
      userType: userRole.userType,
      language: language,
      currency: currency,
      currencyLogo: currencyLogo,
      languageValue: languageValue
    };
    await setStorageData("userRole", JSON.stringify(tempRole));
  } 
}

getLanguageCurrency = async () => {
    const token = await getStorageData("auth-token");
    const header = {
        "Content-Type": configJSON.applicationJsonContentType,
        token: token,
    }; 
    const requestMessageList = new Message(getName(MessageEnum.RestAPIRequestMessage)); 

    this.getLanguageCurrencyApiCallid = requestMessageList.messageId; 

    requestMessageList.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.getApiMethodType);

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCurrencyLanguageApiEndPoint
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };


  editLanguageCurrency = async () => {
    const token = await getStorageData("auth-token");
    const language = this.state.language === this.tranLanguageCurrency("English") ? "0" : "1"
    const currency = this.state.currency === this.tranLanguageCurrency("Dollar") ? "0" : "1"
      const header = {
        "Content-Type": configJSON.applicationJsonContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateLanguageCurrency = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addLanguageCurrency + `language=${language}&currency=${currency}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editLanguageCurrencyUpdate = async () => {
    const token = await getStorageData("auth-token");
    const language = this.state.language === this.tranLanguageCurrency("English") ? "0" : "1"
    const currency = this.state.currency === this.tranLanguageCurrency("Dollar") ? "0" : "1"
      const header = {
        "Content-Type": configJSON.applicationJsonContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateLanguageApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addLanguageCurrency + `language=${language}&currency=${currency}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addLanguageCurrency = () => {
    this.editLanguageCurrency()
  }

  onChangeLanguage = (value: string) => {
    this.setState({ language: value, currency: this.tranLanguageCurrency(this.state.currency) });
    this.editLanguageCurrencyUpdate();
  };

  onChangeHandler = (stateKey: keyof S, value: string) => {
    if (stateKey === 'currency') {
      this.setState({ currency: this.tranLanguageCurrency(value) });
    } else {
      this.setState({ language: value, currency: this.tranLanguageCurrency(this.state.currency) });
    }
  };

  tranLanguageCurrency = (transKey: string) => {
    return i18n.t( transKey, {ns: "languageCurrency"});
  }

    // Customizable Area End
}
