import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  styled,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import StylishClientsController, { Props } from "./StylishClientsController";
import { ThemeProvider } from "react-native-elements";
import StylishSidebar from "../../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../../components/src/Stylish/StylishHeader";
import {
  StylishClientContainer,
  StylishClientTitleContainer,
} from "./StylingClientWishlist.web";
import ReusableSnackbar from "../../../../../components/src/AlertContainer";
import { rejectIcon, rightArrowIcon, storeSuccessIcon } from "../../assets";
import { CustomStyledTableUpdateInventory } from "../../Seller/InventoryManagement/UpdateInventory/UpdateInventory.web";
import {
  CustomStyledStylishPagination,
  StylishPaginationContainer,
} from "../../../../catalogue/src/Stylish/StylishProductSourcing.web";
import {
  DialogSuccessRejectContainer,
  PreviewRequestImg,
  RequestBtnContainer,
  RequestDetailContainer,
  ViewRequestDialog,
  ViewRequestDialogContainer
} from "./StylingRequests.web";
import i18n from "../../../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylishClients extends StylishClientsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  chatCustomer = () => {
    return this.state.requestDataLatest && this.state.requestDataLatest.attributes.status === "accepted" ?
      <ViewRequestContainer >
        <Box
          data-test-id="view-accept-testid"
          className="accept-div"
        >
          {this.tranStylishClients("Chat with Customer")}
        </Box>
      </ViewRequestContainer>
      : null
  }
  formatRequestData() {
    const { requestDataLatest } = this.state;
    if (!requestDataLatest || !requestDataLatest.attributes) return '';
    const stylistId = requestDataLatest.attributes.stylist_id || '';
    const requestId = requestDataLatest.id || '';
    const createdAt = requestDataLatest.attributes.created_at || '';
    return `${stylistId}-${requestId} | ${createdAt}`;
  }
  getRequestDetail() {
    const { requestDataLatest } = this.state;
    return requestDataLatest?.attributes?.detail || '';
  }
  getRequestColour() {
    const { requestDataLatest } = this.state;
    return requestDataLatest?.attributes?.colour || '';
  }
  getRequestPriceRange() {
    const { requestDataLatest } = this.state;
    const minPrice = requestDataLatest?.attributes?.min_price || '';
    const maxPrice = requestDataLatest?.attributes?.max_price || '';
    return `${minPrice} - ${maxPrice}`;
  }
  getGender() {
    const { requestDataLatest } = this.state;
    return requestDataLatest?.attributes?.gender || '';
  }
  getStylistName() {
    const { requestDataLatest } = this.state;
    return requestDataLatest?.attributes?.stylist_name || '';
  }
  
  orderLabel = (value?: string) => {
    let styles = {}
    let label = ''
    switch (value) {
      case 'accepted':
        styles = webStyle.shippedBox
        label = value
        break;
      case 'rejected':
        styles = webStyle.shippedRejectBox
        label = value
        break;
      case 'pending':
        styles = webStyle.shippedpendingBox
        label = 'Requested'
    }
    return (<Box style={styles}>{label}</Box>)
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid dir={i18n.dir()} container>
          <Grid
            item
            xl={2}
            md={2}
            id="stylish-clients-sidebar-catalogue"
            lg={2}
            sm={2}
            xs={2}
          >
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            md={10}
            id="stylish-clients-header-catalogue"
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader navigation={this.props.navigation} />
            <StylishClientContainer>
              <StylishClientTitleContainer>
                <Typography className="main-title">{this.tranStylishClients("Clients")}</Typography>
              </StylishClientTitleContainer>
              <ChatClientContainer>
                <Box
                  className="chat-box"
                  data-test-id="chat-test-id"
                  onClick={() => this.handleStylishClientsRedirect("ClientChat")}
                >
                  {this.tranStylishClients("Chats")}
                </Box>
                <Box className="client-box">{this.tranStylishClients("Clients")}</Box>
              </ChatClientContainer>
              <Grid container spacing={3}>
                {this.state.stylishClientBtnData.map((btn) => (
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <StylishClientBtnContainer
                      data-test-id={`stylish-clients-btn-test-id-${btn.id}`}
                      onClick={() =>
                        btn.isRedirect
                          ? this.handleStylishClientsRedirect(
                              btn.redirectComponentName
                            )
                          : this.handleClientChat()
                      }
                      className={`btn-container`}
                    >
                      <Typography className={`btn-name-title`}>
                        {this.tranStylishClients(btn.btnTitle)}
                      </Typography>
                      <img src={rightArrowIcon} style={{ transform: i18n.dir() === "ltr" ? "rotate(0deg)" : "rotate(180deg)" }} alt="right-icon" />
                    </StylishClientBtnContainer>
                  </Grid>
                ))}
              </Grid>
              <TableHeaderContainer>
                <Typography className="latest-req-title">
                {this.tranStylishClients("Latest Requests")}
                </Typography>
                <Typography className="view-all" data-test-id="view-test-id" onClick={()=> this.handleStylishClientsRedirect("StylingRequests")}>{this.tranStylishClients("VIEW ALL")}</Typography>
              </TableHeaderContainer>
              <CustomStyledTableUpdateInventory data-test-id="as-table">
                <Table stickyHeader size="small" className="custom-table">
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: "#f0f0f0" }}
                      className="as-table-head-row"
                    >
                      <TableHeaderCell className="as-table-head-cell">
                        <Typography className="table-head-title first-typo">
                        {this.tranStylishClients("Request ID")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="as-table-head-cell">
                        <Typography className="table-head-title">
                        {this.tranStylishClients("Request Time")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="as-table-head-cell"
                        style={{ width: "330px" }}
                      >
                        <Typography className="table-head-title">
                        {this.tranStylishClients("Name & Gender")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="as-table-head-cell">
                        <Typography className="table-head-title">
                        {this.tranStylishClients("Budget")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="as-table-head-cell">
                        <Typography className="table-head-title">
                        {this.tranStylishClients("Status")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="as-table-head-cell"
                        style={{ width: "241px" }}
                      >
                        <Typography className="table-head-title">
                        {this.tranStylishClients("Action")}
                        </Typography>
                      </TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.latestRequestsData && this.state.latestRequestsData.length > 0 ? 
                      this.state.latestRequestsData.map((requestData) => (
                        <TableRow key={requestData.id} className="hover">
                          <TableBodyCell data-test-id="product-name">
                            <TableTypographyContainer style={{ paddingLeft: "20px" }}>
                              #{requestData.attributes.stylist_id}-{requestData.id}
                            </TableTypographyContainer>
                          </TableBodyCell>
                          <TableBodyCell>
                            <TableTypographyContainer style={{textTransform: "uppercase"}}>
                              {requestData.attributes.created_at}
                            </TableTypographyContainer>
                          </TableBodyCell>
                          <TableBodyCell>
                            <RequesterNGContainer>
                              <Typography className="request-name">
                                {requestData.attributes.buyer_name}
                              </Typography>
                              <Typography className="request-gender">
                                {requestData.attributes.gender}
                              </Typography>
                            </RequesterNGContainer>
                          </TableBodyCell>
                          <TableBodyCell>
                            <TableTypographyContainer>
                              ${requestData.attributes.min_price} - ${requestData.attributes.max_price}
                            </TableTypographyContainer>
                          </TableBodyCell>
                          <TableBodyCell>
                            <Box>
                              {this.orderLabel(requestData.attributes.status)}
                            </Box>
                          </TableBodyCell>
                          <TableBodyCell>
                            <ViewRequestContainer
                              onClick={() =>
                                this.handleRequestClose(requestData.attributes.stylist_id)
                              }
                              data-test-id={`view-req-test-id-${requestData.id}`}
                            >
                              {this.tranStylishClients("View Request")}
                            </ViewRequestContainer>
                          </TableBodyCell>
                        </TableRow>
                      ))
                     : 
                      <TableRow>
                        <TableBodyCell colSpan={6} style={{ textAlign: "center",color:'#375280',fontFamily:'Lato',fontWeight:700,fontSize:'16px' }}>
                        {this.tranStylishClients("No Styling Requests Available")}
                        </TableBodyCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </CustomStyledTableUpdateInventory>
            </StylishClientContainer>
            <StylishPaginationContainer>
              <Box className="pagination-data-container">
                {1} - of 54 results
              </Box>
              <CustomStyledStylishPagination
                page={1}
                count={5}
                shape="rounded"
                color="primary"
                data-test-id="my-offer-page-test-id"
                dir={i18n.dir()}
              />
            </StylishPaginationContainer>
          </Grid>
        </Grid>
        <ViewRequestDialog
          open={this.state.isRequestOpen}
          data-test-id="request-view-dialog"
          onClose={() => this.handleRequestClose()}
        >
          <ViewRequestDialogContainer dir={i18n.dir()}>
            <Box
              data-test-id="request-view-title"
              className="main-request-title"
            >
              <Typography
                className="request-number"
                data-test-id="request-view-number"
              >
                #{this.formatRequestData()}
              </Typography>
              <Box
                style={{ height: "20px" }}
                data-test-id="request-view-status"
              >
                {this.orderLabel(this.state.requestDataLatest && this.state.requestDataLatest?.attributes?.status)}
              </Box>
            </Box>
            <PreviewRequestImg data-test-id="request-view-preview-img-container">
              {this.state.requestDataLatest?.attributes.images.map((requestFile, index) => (
                <Box
                  key={index}
                  className="file-preview"
                  data-test-id="request-view-preview-img"
                >
                  <img
                    src={requestFile.url}
                    alt={`preview ${index}`}
                    className="preview-image"
                  />
                </Box>
              ))}
            </PreviewRequestImg>
            <RequestDetailContainer data-test-id="request-view-detail-container">
              <Box
                data-test-id="request-view-row-container"
                className="row-container"
              >
                <Box className="description-data">
                  <Typography
                    className="requester-name"
                    data-test-id="request-view-req-name"
                  >
                    {this.getStylistName()}
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container" data-test-id="request-view-gb">
                <Box
                  className="description-data"
                  data-test-id="request-view-gender"
                >
                  <Typography className="description-name">
                    {this.tranStylishClients("Gender")} -{" "}
                  </Typography>
                  <Typography className="description-value">{this.getGender()}</Typography>
                </Box>
                <Box
                  className="description-data"
                  data-test-id="request-view-budget"
                >
                  <Typography className="description-name">
                    {this.tranStylishClients("Budget")} -{" "}
                  </Typography>
                  <Typography className="description-value">
                    ${this.getRequestPriceRange()}
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container" data-test-id="request-view-color">
                <Box className="description-data">
                  <Typography className="description-name">
                    {this.tranStylishClients("Colours")} -{" "}
                  </Typography>
                  <Typography className="description-value">
                    {this.getRequestColour()}
                  </Typography>
                </Box>
              </Box>
              <Box
                className="row-container"
                data-test-id="request-view-description"
              >
                <Box className="description-data">
                  <Typography className="description-content">
                    {this.getRequestDetail()}
                  </Typography>
                </Box>
              </Box>
            </RequestDetailContainer>
            {this.state.requestDataLatest && this.state.requestDataLatest.attributes.status === "pending" ? 
              <RequestBtnContainer data-test-id="request-view-btn-container">
                <Box
                  onClick={() => this.latestCustomForms("rejected", this.state.requestDataLatest && this.state.requestDataLatest.id)}
                  data-test-id="view-reject-test-id"
                  className="reject-div"
                >
                  Reject
                </Box>
                <Box
                  onClick={() => this.latestCustomForms("accepted", this.state.requestDataLatest && this.state.requestDataLatest.id)}
                  data-test-id="view-accept-test-id"
                  className="accept-div"
                >
                  Accept
                </Box>
              </RequestBtnContainer>
             : this.chatCustomer()}
          </ViewRequestDialogContainer>
        </ViewRequestDialog>
        <DialogSuccessRejectContainer
          data-test-id="request-accept-reject-dialog"
          id="stylish-client-request-accept-reject"
          open={this.state.isSROpen}
        >
          <Box
            className="content-container"
            id="stylish-client-request-accept-reject-container"
          >
            <img
              src={this.state.isAccepted ? storeSuccessIcon : rejectIcon}
              alt="icon"
            />
            <Typography
              className="text-container"
              id="stylish-client-request-accept-reject-text"
            >
              {`You have ${
                this.state.isAccepted ? "accepted" : "rejected"
              } the styling request of Scarlet.`}
            </Typography>
          </Box>
        </DialogSuccessRejectContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertSCSnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const webStyle = {
  shippedBox: {
    backgroundColor: "#FFE7D0",
    color: "#BE5B00",
    padding: "6px 10px 6px 10px",
    borderRadius: "2px",
    textAlign: "center",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    height: "27px",
    whiteSpace: "nowrap",
    textTransform: "capitalize"
  },
  shippedRejectBox: {
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    padding: "6px 10px 6px 10px",
    borderRadius: "2px",
    textAlign: "center",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    height: "27px",
    whiteSpace: "nowrap",
    textTransform: "capitalize"
  },
  shippedpendingBox:{
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "6px 10px 6px 10px",
    borderRadius: "2px",
    textAlign: "center",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    height: "27px",
    whiteSpace: "nowrap",
  }
}
const ChatClientContainer = styled(Box)({
  width: "100%",
  display: "flex",
  height: 64,
  gap: 24,
  marginBottom: 48,
  "& .chat-box": {
    borderRadius: 2,
    boxShadow: "0px 2px 8px 0px #00000014",
    padding: "11px 20px 11px 20px",
    backgroundColor: "#FFF",
    color: "#E2E8F0",
    display: "flex",
    border: "1px solid #E2E8F0",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    width: "50%",
    fontSize: 18,
  },
  "& .client-box": {
    borderRadius: 2,
    width: "50%",
    padding: "11px 20px 11px 20px",
    backgroundColor: "#375280",
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 18,
  },
});

const TableHeaderContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "48px 0px 24px 0px",
  "& .latest-req-title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 24,
    color: "#375280",
  },
  "& .view-all": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 900,
    fontSize: 16,
    color: "#375280",
  },
});

export const TableHeaderCell = styled(TableCell)({
  padding: "16px 20px 16px 0px !important",
  "& .table-head-title": {
    color: "#375280",
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    overflow: "none",
    display: "flex",
    whiteSpace: "nowrap",
  },
  "& .first-typo": {
    paddingLeft: "20px !important",
  },
});

export const TableBodyCell = styled(TableCell)({
  padding: "16px 20px 16px 0px !important",
  width: 105,
});

export const ViewRequestContainer = styled(Box)({
  backgroundColor: "#CCBEB1",
  marginBottom: "4px",
  borderRadius: "2px",
  color: "#FFFFFF",
  fontSize: "14px",
  fontFamily: "Lato",
  fontWeight: 700,
  height: "36px",
  textTransform: "capitalize",
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px 10px",
});

export const TableTypographyContainer = styled(Typography)({
  fontFamily: "Lato, sans-serif",
  fontWeight: 400,
  fontSize: 16,
  display: "flex",
  color: "#375280",
  whiteSpace: "nowrap",
});

export const RequesterNGContainer = styled(Box)({
  fontFamily: "Lato, sans-serif",
  fontSize: 14,
  display: "flex",
  flexDirection: "column",
  gap: 2,
  color: "#375280",
  "& .request-name": {
    fontWeight: 700,
    display: "flex"
  },
  "& .request-gender": {
    fontWeight: 400,
    display: "flex"
  },
});

const StylishClientBtnContainer = styled(Box)({
  border: "1px solid #E2E8F0",
  borderRadius: 2,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 8px 0px #00000014",
  padding: "38px 26px",
  "& .btn-name-title": {
    fontFamily: "Lato, sans-serif",
    color: "#375280",
    fontSize: 22,
    fontWeight: 700,
  },
  "&:hover": {
    backgroundColor: "#F1F5F9",
    "& .btn-name-title": {
      fontSize: 24,
    },
  },
});
// Customizable Area End
