export const enWishlist = {
    "Empty Wishlist Text": "Your wishlist is empty.",
    "Continue Shopping": "Continue Shopping",
    "Empty Wishlist Message": "You don’t have any products in the wishlist yet. You will find a lot of interesting products on our Shop page.",
    "Home": "Home",
    "Wishlist": "Wishlist",
    "Welcome to your Account": "Welcome to your Account",
    "You May Also Like": "You May Also Like",
    "Color": "Color",
    "Quantity": "Quantity",
    "Move To Cart": "Move To Cart",
}

export const arWishlist = {
    "Empty Wishlist Text": "قائمة رغباتك فارغة.",
    "Continue Shopping": "متابعة التسوق",
    "Empty Wishlist Message": "لا توجد لديك أي منتجات في قائمة الرغبات حاليا. ستجد كثيرا منالمنتجات المثيرة على صفحتنا الخاصة بالتسوق.",
    "Home": "الصفحة الرئيسية",
    "Wishlist": "قائمة الرغبات",
    "Welcome to your Account": "مرحبا بك في حسابك",
    "You May Also Like": "قد يعجبك أيضاً",
    "Color": "اللون",
    "Quantity": "العدد",
    "Move To Cart": "الإضافة إلى سلة التسوق",
}