import React from "react";
// Customizable Area Start
import {
  Grid,
  Typography,
  Box,FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import RadioButton from "../../../components/src/RadioButton";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { Vector, scroll } from "./assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import i18n from "../../../components/src/i18next/i18n";
interface OptionType {
  value: string;
  label: React.ReactNode;
  selectedValue?: string;
};

interface CurrencyOptionType{
  value: string;
  label: string;
}
// Customizable Area End

import CurrencylanguageController,{
    Props,
    configJSON,
    // Customizable Area Start
    // Customizable Area End
  } from "./CurrencylanguageController";

export default class CurrencyLanguage extends CurrencylanguageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  navHeaderCLMargin = () =>{
    return i18n.dir() === "ltr" ? "27px 0px 20px 40px" : "27px 40px 20px 0px"
  }

  nameCLCSS = () => {
    return i18n.dir() === "ltr" ? "31px 0px 20px 40px" : "31px 40px 20px 0px"
  }

  welcomeCLCSS = () => {
    return i18n.dir() === "ltr" ? "0px 0px 37px 40px" : "0px 40px 37px 0px"
  }

  render() {
    const languageOptions = (): OptionType[] => {
      return [{ value: "0", label: "English",selectedValue:"English" },
      {
        value: "1",
        label: (
          i18n.dir()==="ltr" ? <Typography style={{ fontFamily: "Lato, sans-serif" }}>
            Arabic - <b>عربي</b>
          </Typography> :
          <Typography style={{ fontFamily: "Lato, sans-serif" }}>العربية</Typography>
        ),selectedValue:"Arabic"
      },
    ]
  };
  
    const currencyOptions = (): CurrencyOptionType[] => {
      return[
      { value: "0", label: `${this.tranLanguageCurrency("Dollar")}`},
      { value: "1", label: `${this.tranLanguageCurrency("Dinar")}` }
    ];
  }
    return (
   <>
   {this.state.stylishBuyer ? <StylishBuyerHeader navigation={this.props.navigation}/> : <Header navigation={this.props.navigation}/>}
   
        <Snackbar 
           autoHideDuration={3000} data-test-id="alertTestId"
           open={this.state.isAlert} onClose={this.oncloseAlert}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }} >
           <Alert severity={this.state.alertType}>
            {this.state.alertMsg}</Alert> 
        </Snackbar> 
        <Grid style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }} container dir={i18n.dir()}>
          <Grid item >
            <div style={{...webStyle.innerContainer11, margin: this.navHeaderCLMargin()}}>
              <Typography style={webStyle.innerContainer12} data-test-id="handleNavigate" onClick={()=> this.navigationTo("LandingPage")}>
              {this.tranLanguageCurrency("Home")}
              </Typography>
              <img src={Vector} style={{transform: i18n.dir()==="ltr"?"rotate(0deg)": "rotate(180deg)"}} />
              <Typography style={{...webStyle.innerContainer23, fontWeight: 700, cursor: "pointer"}}>
              {this.tranLanguageCurrency("Language and Currency")}
              </Typography>
            </div>
            <div >
              <Typography style={{...webStyle.innerContainer3, margin: this.nameCLCSS()}}>
                <img src={scroll} />
                {this.state.fullName}
              </Typography>
            </div>
            <Typography style={{...webStyle.innerContainer4, margin: this.welcomeCLCSS()}}>
            {this.tranLanguageCurrency("Welcome to your Account")}
            </Typography>
            <Sidebar navigation={this.props.navigation}/>
          </Grid>
          <MainGrid item xs={12} md={6} style={{ padding: i18n.dir()==='ltr' ? "0px 0px 0px 110px" : "0px 110px 0px 0px"}}>
          <Typography className={"welcomeText"}>
          {this.tranLanguageCurrency("Language and Currency")}
                  </Typography>
          <Box className={"formBox"}>
              <form
                data-test-id="formSubmit"
                className="formBlock"
              >
                <Box width={"430px"}>
                  <FormControl className="formComp">
                    <FormLabel
                      id="language-selection"
                      className={"inputHeading"}
                    >
                      {this.tranLanguageCurrency("Select Language")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="language-selection"
                      name="language-selection"
                      style={{ justifyContent: "space-between" ,marginBottom:'15px' }}
                      value={this.state.language}
                      data-test-id="group-language"
                      onChange={(event, value) =>
                        this.onChangeHandler("language", value)
                      }
                    >
                      {languageOptions().map(lang => (
                        <FormControlLabel
                          key={lang.value}
                          value={lang.selectedValue}
                          control={<RadioButton variant="outlined" />}
                          label={lang.label}
                          data-test-id={`radio-${lang.value}`}
                          className={`radioButton ${
                            lang.label === this.state.language
                              ? "activeRadioButton"
                              : ""
                          }`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormControl className="formComp">
                    <FormLabel
                      id="currency-selection"
                      className={"inputHeading"}
                    >
                      {this.tranLanguageCurrency("Select Currency")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="currency-selection"
                      name="currency-selection"
                      style={{ justifyContent: "space-between" ,marginBottom:'15px'}}
                      value={this.state.currency}
                      data-test-id="group-currency"
                      onChange={(event, value) =>
                        this.onChangeHandler("currency", value)
                      }
                    >
                      {currencyOptions().map((curr) => (
                        <FormControlLabel
                          key={curr.value}
                          value={curr.label}
                          control={<RadioButton variant="outlined" />}
                          label={curr.label}
                          data-test-id={`radio-${curr.value}`}
                          className={`radioButton ${
                            curr.label === this.state.currency
                              ? "activeRadioButton"
                              : ""
                          }`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <Button
                    variant="contained"
                    className="continueButton"
                    disableElevation
                onClick={this.addLanguageCurrency}
                    type="button"
                    data-test-id="submit-btn"
                  >
                    {this.tranLanguageCurrency("Save")}
                  </Button>
                </Box>
              </form>
            </Box>
          </MainGrid>
        </Grid>
      <Footer navigation={this.props.navigation}/>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const MainGrid = styled(Grid)({
    marginTop:"100px",
    "& .formComp": {
      width: "100%"
    },
    "& .sideImageBox": {
      width: "100%",
      height: "100%"
    },
    "& .sideImage": {
      width: "100%",
      height: "100%"
    },
    "& .imageGrid": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center"
    },
    "& .welcomeSubText": {
      color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
      textOverflow: "ellipsis",
      src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
      fontFamily: "Lato",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "26px",
      marginTop: "10px"
    },
    "& .formBlock": {
      marginBottom: "0px"
    },
    "& .radioButton": {
      width: "180px",
      marginRight: "0px",
      marginLeft: "0px",
      backgroundColor: "#F8F8F8",
      border: "1px solid #F8F8F8",
      boxShadow: "0px 2px 8px 0px #00000014",
      padding: "10px 0px 15px 15px",
      borderRadius: "2px",
      marginBottom: "20px",
      color: "#375280",
      "&	.MuiFormControlLabel-label": {
        fontFamily: "Lato",
fontWeight:500,
fontSize:"16px",
    }
    },
    "& .activeRadioButton": {
      backgroundColor: "#fff",
      border: "1px solid #CCBEB1",
      borderCollapse: "collapse"
    },
    "& .formGrid": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: "7%"
    },
    "& .formBox": {
      alignItems: "center",
      paddingTop:'15px'
    },
    "& .welcomeText": {
      color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
      textOverflow: "ellipsis",
      src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
      fontFamily: "Lato",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 800,
      lineHeight: "26px",
      alignSelf: "flex-start"
    },
    "& .inputHeading": {
      color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
      margin: "20px 10px 10px 0px",
      textOverflow: "ellipsis",
      src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
      fontFamily: "Lato",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "26px"
    },
    "& .continueButton": {
      textTransform: "capitalize",
      backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
      gap: "8px",
      margin: "20px 0",
      width: "100% !important",
      borderRadius: "2px",
      height: "56px !important",
      "& .MuiButton-label": {
        fontWeight: 800,
        fontSize: "20px",
        fontFamily: "Lato",
        color: "white"
      }
    },
    "& .loginButton": {
      cursor: "pointer",
      fontFamily: "Lato",
      fontWeight: 700,
      color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
      textAlign: "center"
    },
    "& .login": {
      width: "100%",
      marginTop: "10px"
    },
    "& .bottomText": {
      color: "#375280",
      fontFamily: "Lato",
      textAlign: "center",
      marginTop: "30px"
    }
  });

const webStyle = {
  addAddressContainer: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: '20px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '26px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },

  dropdown: {
    borderRadius: '4px',
    boxShadow: '0px 2px 8px 0px #00000014',
    backgroundColor: 'white',
    zIndex: 1,
    left: 0,
    width: '120px',
    top: '100%',
    position: 'absolute',
  },
  dropdownP: {
    fontWeight: "600",
    fontSize: "12px",
    color: "#375280",
    paddingRight: '6px',
    paddingLeft: '6px',
    lineHeight: "22px",
    paddingBottom: '10px',
    borderBottom: '3px solid #D5D5D5',
  },
  dropdownP1: {
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },
  innerContainer11: {
    gap: "10px",
    display: "flex",
    fontWeight: 400,
    alignItems: "center",
    color: "#475569",
  },
  innerContainer12: {
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato",
    padding: "4px 6px 4px 6px", cursor: "pointer"
  },
  innerContainer23: {
    fontSize: "14px",
    fontFamily: "Lato",
    padding: "4px 6px 4px 6px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#475569"
  },
  innerContainer3: {
    alignItems: "center",
    display: "flex",
    color: "#375280",
    fontWeight: 800,
    gap: "15px",
    fontStyle: "normal",
    fontSize: "28px",
    fontFamily: "Lato",
    textTransform: "capitalize"
} as React.CSSProperties,
  innerContainer4: {
    marginBottom: "37px",
    marginLeft: "40px",
    color: "#807D7E",
    fontWeight: 400,
    lineHeight: "33.5px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato",

  },
};
// Customizable Area End
