import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
type AlertType = 'success' | 'error' | 'warning' | 'info';
import { getStorageData } from "../../../../framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";
export const configJSON = require("../config");
interface DeliveryAddressAttributesAllDetails {
  country_code: string;
  name: string;
  contact_number: string;
  street: string;
  zip_code: string;
  area: string;
  phone_number: string;
  longitude: number;
  city: string;
  block: string;
  is_default: boolean;
  latitude: number;
  address_name: string;
  house_or_building_number: string;
}

interface DeliveryAddressAllDetails {
  type: string;
  attributes: DeliveryAddressAttributesAllDetails;
  id: string;
}

interface PaymentDetailAllDetails {
  id: number;
  charge_id: string;
  status: string;
  created_at: string;
  currency: string;
  updated_at: string;
  refund_id: string | null;
  amount: number;
  customer_id: string;
  refund_reason: string | null;
  reason: string;
  last_four_card_digit: string;
  account_id: number;
  deleted: boolean;
  seller_order_id: string | null;
  refund_amount: string | null;
  merchant_id: string | null;
  payment_type: string;
  order_id: string;
  order_management_order_id: number;
}

interface OrderManagementOrderAttributesAllDetails {
  delivery_addresses: DeliveryAddressAllDetails;
  account: string;
  placed_at: string;
  total: string;
  order_number: string;
  status: string;
  refunded_at: string | null;
  in_transit_at: string | null;
  sub_total: string;
  confirmed_at: string;
  deliver_by: string | null;
  order_return_time: string | null;
  cancelled_at: string | null;
  order_deliver_time: string | null;
  delivered_at: string | null;
  returned_at: string | null;
  updated_at: string;
  order_status_id: number;
  payment_detail: PaymentDetailAllDetails;
  order_deliver_date: string | null;
  order_return_date: string | null;
  created_at: string;
}

interface OrderManagementOrderAllDetails {
  attributes: OrderManagementOrderAttributesAllDetails;
  type: string;
  id: string;
}

interface OrderItemAttributesAllDetails {
  status: string;
  placed_at: string;
  in_transit_at: string | null;
  currency_logo: string;
  delivered_at: string | null;
  cancelled_at: string | null;
  rejected_at: string | null;
  confirmed_at: string;
  return_cancel_at: string | null;
  return_at: string | null;
  return_pick_at: string | null;
  process_at: string;
  shipped_at: string | null;
  quantity: number;
  catalogue_name: string;
  driver_latitude: number | null;
  brand_name: string;
  reason_of_rejection: string | null;
  unit_price: string;
  catalogue_variant_color: string;
  store_name: string;
  catalogue_variant_sku: string;
  driver_name: string | null;
  otp: string | null;
  total_price: string;
  catalogue_variant_front_image: string;
  catalogue_variant_back_image: string;
  driver_longitude: number | null;
  catalogue_variant_size: string;
  driver_phone_number: string | null;
  catalogue_variant_side_image: string;
}

interface OrderItemAllDetails {
  id: string;
  type: string;
  attributes: OrderItemAttributesAllDetails;
}
interface SellerOrderAttributesAllDetails {
  id: number;
  order_items: OrderItemAllDetails[];
  status: string;
  accept_order_upload_time: string | null;
  order_management_order: OrderManagementOrderAllDetails;
}
interface OrderState {
  return_placed_at: string | null;
  return_cancel_at: string | null;
  return_confirmed_at: string | null;
  return_reject_at: string | null;
  return_pick_at: string | null;
  returned_assign_at: string | null;
  return_at: string | null;
  order_number: string;
  placed_at: string;
  reason: string;
  refunded_cancel_at: string | null;
  reason_refunded_cancel: string | null;
  refunded_at: string | null;
  otp: string | null;
  longitude: number;
  latitude: number;
  estimated_delivery_time: string | null;
}
interface SellerOrderInProcessDetails {
  id: string;
  type: string;
  attributes: SellerOrderAttributesAllDetails;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderIdReject: number | string;
  alertType: AlertType,
  orderDeltilsId: string | number;
  tableAllOrder: SellerOrderInProcessDetails[];
  rejectOpen: boolean;
  numberData: string;
  visible: boolean,
  orderId: number,
  isAlert: boolean,
  confirmId: number,
  isLoading: boolean,
  isAlertOrder: boolean,
  rejectId: number,
  already: string,
  tableLable: string[],
  visibleAllOrder: boolean,
  orderIdNumber: number | string,
  isOpenDelete: boolean,
  refundAmount: string,
  saleAmount: number,
  errorMessage: string,
  isOpenDeleteRejectDetalis: boolean,
  rejectionReason: string,
  selectReson: string[],
  selectedFile: (File | null)[];
  combineImgDetails: (string | null)[];
  numberDataOrder: string;
  isDrawerStatusOpen: boolean,
  roleData: string;
  isDrawerStatus: boolean,
  sellerDriverName: string | null | undefined;
  orderData: OrderState,
  latitudeData: { lat: number, lng: number },
  longitudeData: { lat: number, lng: number },
  orderDatalsId: string;
  visibleReturn: boolean;
  rejectOpenReturn: boolean;
  orderIdReturnId: string | number;
  descriptionReson: string;
  numberDataRetun: string;
  short: string[],
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersInProcessOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMy_OrdersCallId: string = "";
  getTimeInProcessDetailsCallId: string = "";
  getRejectOrderDetailCallId: string = "";
  getInProcessDetailsCallId: string = "";
  getTimeInProcessOrderCallId: string = "";
  getTimeAllOrderCallId: string = "";
  getTRackOrderApiCallId: string = "";
  getTimeRefundOrderCallId: string = "";
  getSellerAcceptApiCallId: string = "";
  getRejectReturnOrderCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      numberDataRetun: "",
      orderIdReturnId: "",
      descriptionReson: "",
      rejectOpenReturn: false,
      visibleReturn: false,
      isDrawerStatusOpen: false,
      isDrawerStatus: false,
      visibleAllOrder: false,
      orderDatalsId: "",
      sellerDriverName: "",
      roleData: "",
      orderIdReject: 0,
      alertType: 'success' as AlertType,
      numberDataOrder: "",
      tableLable: ["Product", "Brand", "Size", "Color", "Product Code", "Price"],
      selectReson: ["Worn or Used Items", "Damaged Items", "Altered Items", "Sale Items", "Past Return Window", "Hygiene Concerns"],
      tableAllOrder: [],
      orderId: 0,
      orderDeltilsId: 0,
      visible: false,
      numberData: "",
      rejectOpen: false,
      already: "",
      rejectId: 0,
      isAlert: false,
      isLoading: true,
      isAlertOrder: false,
      confirmId: 0,
      orderIdNumber: 0,
      isOpenDelete: false,
      refundAmount: '',
      saleAmount: 100,
      errorMessage: '',
      isOpenDeleteRejectDetalis: false,
      rejectionReason: '',
      selectedFile: [],
      combineImgDetails: [],
      orderData: {
        return_placed_at: null,
        return_cancel_at: null,
        return_confirmed_at: null,
        return_reject_at: null,
        return_pick_at: null,
        returned_assign_at: null,
        return_at: null,
        order_number: "",
        placed_at: "",
        reason: "",
        refunded_cancel_at: null,
        reason_refunded_cancel: null,
        refunded_at: null,
        otp: null,
        longitude: 0,
        latitude: 0,
        estimated_delivery_time: null,
      },
      latitudeData: { lat: 0, lng: 0 },
      longitudeData: { lat: 0, lng: 0 },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getInProcessDetailsCallId !== null &&
      this.getInProcessDetailsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonAllOrderData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const orderDatalsAllDetails = responseJsonAllOrderData.orders && responseJsonAllOrderData.orders.data.find(
        (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
          order.attributes.order_management_order.attributes.order_number === this.state.orderId
      );
      this.setState({ tableAllOrder: responseJsonAllOrderData.orders && responseJsonAllOrderData.orders.data, isLoading: false, isAlert: true, visibleAllOrder: false, orderDatalsId: orderDatalsAllDetails.attributes.id }, () => this.getTrackOrderDetailApi(orderDatalsAllDetails.attributes.id))
    }
    this.allOrderAcceoted(message)
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeAllOrderCallId !== null &&
      this.getTimeAllOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonAllOrderData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonAllOrderData.data) {
        this.setState({ isLoading: false, visibleAllOrder: false, isOpenDelete: false }, () => this.allOrderDataAllDetails())
      } else {
        this.setState({ isLoading: false, visibleAllOrder: false, isAlertOrder: true, alertType: 'error', already: responseJsonAllOrderData.error === "Payment failed. Please check your details and try again or use a different payment method." ? responseJsonAllOrderData.error : responseJsonAllOrderData.error[0].message, isOpenDelete: false })
      }
    }
    this.timeRefundData(message)
    this.tRackOrder(message)
    this.returnSellerAcceptDetils(message)
    this.rejectReturnOrderDetils(message)
    // Customizable Area End
  }

  // Customizable Area Start
  timeRefundData = (message:Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeRefundOrderCallId !== null &&
      this.getTimeRefundOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonAllOrderData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonAllOrderData.data) {
        this.setState({ isLoading: false, visibleAllOrder: false, isOpenDeleteRejectDetalis: false, alertType: 'success', already: this.tranSellerAllOrderDetails("Return Reject Successfully.") }, () => this.allOrderDataAllDetails())
      } else {
        this.setState({ isLoading: false, visibleAllOrder: false, isAlertOrder: true, isOpenDeleteRejectDetalis: false })
      }
    }
  }
  returnSellerAcceptDetils = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSellerAcceptApiCallId !== null &&
      this.getSellerAcceptApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory) {
        this.setState({ isLoading: true, rejectOpenReturn: false },() => this.allOrderDataAllDetails())
      }
    }
  }
  rejectReturnOrderDetils = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRejectReturnOrderCallId !== null &&
      this.getRejectReturnOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory) {
        this.setState({ isLoading: true },() => this.allOrderDataAllDetails())
      }
    }
  }
  tRackOrder = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTRackOrderApiCallId !== null &&
      this.getTRackOrderApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ orderData: responseJson, longitudeData: { lat: responseJson.latitude, lng: responseJson.longitude }, latitudeData: { lat: responseJson.latitude, lng: responseJson.longitude } })
    }
  }
  oncloseAlertInProcess = () => {
    this.setState({ isAlertOrder: false });
  };

  contioneShoppingAllDetails = () => {
    const toNavigateAllDetails = new Message(getName(MessageEnum.NavigationMessage));
    toNavigateAllDetails.addData(getName(MessageEnum.NavigationTargetMessage), "SellersAllOrderDetailsWebPage");
    toNavigateAllDetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { tabIndex: 0 });
    toNavigateAllDetails.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(toNavigateAllDetails);
  }

  async componentDidMount() {
    const userData = await getStorageData("userRole", true)
    this.setState({ roleData: userData.userType })
    const idDataAllDetails = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ orderId: idDataAllDetails })
    this.allOrderDataAllDetails()
  }

  allOrderDataAllDetails = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListAllDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInProcessDetailsCallId = requestMessageListAllDetails.messageId;
    requestMessageListAllDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageListAllDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTableAllOrder
    );
    requestMessageListAllDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListAllDetails.id, requestMessageListAllDetails);
  }


  getAddressAllDetails = (delivery_addresses: {
    type: string;
    id: string;
    attributes: {
      name: string;
      country_code: string;
      contact_number: string;
      street: string;
      phone_number: string;
      area: string;
      block: string;
      zip_code: string;
      address_name: string;
      house_or_building_number: string;
      latitude: number;
      city: string;
      is_default: boolean;
      longitude: number;
    };
  }) => {
    if (delivery_addresses) {
      const { house_or_building_number, block, area, street, city, zip_code } = delivery_addresses.attributes
      return `${house_or_building_number}, ${street}, ${block} ${area}, ${city} - ${zip_code}`
    } else {
      return ""
    }
  }

  allOrderAcceoted = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeInProcessOrderCallId !== null &&
      this.getTimeInProcessOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonInProcess = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonInProcess) {
        this.setState({ already: "Order Accepted", isAlertOrder: true })
        setTimeout(() => {
          this.allOrderDataAllDetails()
        }, 3000)
      }
    }
  }
  timeOrderDataInProcess = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListAllProcessOrder = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeInProcessOrderCallId = requestMessageListAllProcessOrder.messageId;
    requestMessageListAllProcessOrder.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListAllProcessOrder.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.orderIdNumber}&type=ready_to_ship`
    );
    requestMessageListAllProcessOrder.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListAllProcessOrder.id, requestMessageListAllProcessOrder);
  }
  handleInProcess = (orderIdData: number | string) => {
    this.setState({ visibleAllOrder: true, orderIdNumber: orderIdData });
  };
  handleCancelInProcess = () => {
    this.setState({ visibleAllOrder: false });
  };

  handleOpenDeleteData = (orderId: string | number) => {
    this.setState({ isOpenDelete: true, orderDeltilsId: orderId });
  };

  handleCloseDeleteModal = () => {
    this.setState({ isOpenDelete: false });
  };

  handleConfirmDetalis = async (refundAmo: string | number) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListAllProcessOrder = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeAllOrderCallId = requestMessageListAllProcessOrder.messageId;
    requestMessageListAllProcessOrder.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListAllProcessOrder.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=initiate_refund&seller_order_id=${this.state.orderDeltilsId}&refund_amount=${refundAmo}`
    );
    requestMessageListAllProcessOrder.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListAllProcessOrder.id, requestMessageListAllProcessOrder);
  };

  handleOpenRejectDelete = (orderId: string | number) => {
    this.setState({ isOpenDeleteRejectDetalis: true, orderIdReject: orderId });
  };

  handleCloseRejectDelete = () => {
    this.setState({ isOpenDeleteRejectDetalis: false });
  };

  handleRemoveImg = () => {
    this.setState({ selectedFile: [], combineImgDetails: [] });
  };

  openPictureUploadAllOrder = (index: number) => {
    const inputAddPensionTwoData = document.createElement('input');
    inputAddPensionTwoData.type = 'file';
    inputAddPensionTwoData.accept = 'image/png, image/jpeg, image/jpg';
    inputAddPensionTwoData.multiple = false;
    inputAddPensionTwoData.click();
    inputAddPensionTwoData.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
      this.openProfileUploadInSideMainAllOrder(event, index);
    };
  }
  openProfileUploadInSideMainAllOrder = async (event: React.ChangeEvent<HTMLInputElement> | Event, index: number) => {
    const targetAllOrder = event.target as HTMLInputElement;
    const files = targetAllOrder.files;
    if (!files || files.length === 0) {
      return;
    }
    const fileOrder = files[0];
    const base64 = URL.createObjectURL(fileOrder);
    this.setState({
      combineImgDetails: [base64],
      selectedFile: [fileOrder],
    });
  }
  handlenumberDataOrderChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberDataOrder: event.target.value as string });
  };

  timeOrderDataReject = async () => {
    const formData = new FormData()
    formData.append("reason_of_refund_rejected", this.state.numberDataOrder);
    this.state.selectedFile.forEach((itemsImg: string | Blob | null) => {
      itemsImg && formData.append("image", itemsImg);
    })
    const token = await getStorageData("auth-token");
    const header = {
      token: token,
    };
    const requestMessageListAllProcessOrder = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeRefundOrderCallId = requestMessageListAllProcessOrder.messageId;
    requestMessageListAllProcessOrder.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListAllProcessOrder.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=refund_rejected&seller_order_id=${this.state.orderIdReject}`
    );
    requestMessageListAllProcessOrder.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageListAllProcessOrder.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageListAllProcessOrder.id, requestMessageListAllProcessOrder);
  }


  toggleStatusDrawer = (open: boolean, orderIdNumber?: string | number) => (event: React.KeyboardEvent | React.MouseEvent) => {
    this.getTrackOrderDetailApi(orderIdNumber)
    this.setState({
      isDrawerStatusOpen: open,
      isDrawerStatus: false,
    });
  };

  getTrackOrderDetailApi = async (orderIdNumber?: number | undefined | string) => {
    const getToken = await getStorageData("auth-token")
    const headers = {
      "token": getToken
    };
    const requestMessageTreack = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTRackOrderApiCallId = requestMessageTreack.messageId;
    requestMessageTreack.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersManagementTreack}=${orderIdNumber}`
    );
    requestMessageTreack.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageTreack.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessageTreack.id, requestMessageTreack);
    return true;
  }

  toggleStatusData = (open: boolean, orderIdNumber?: number | string) => (event: React.KeyboardEvent | React.MouseEvent) => {
    const orderDatalsAllDetails = this.state.tableAllOrder.find(
      (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
        order.attributes.order_management_order.attributes.order_number === this.state.orderId
    );
    this.getTrackOrderDetailApi(orderIdNumber)
    this.setState({
      isDrawerStatusOpen: false,
      isDrawerStatus: open,
      sellerDriverName: orderDatalsAllDetails?.attributes.order_items[0].attributes.driver_name,
    });
  };
  toggleStatusDatadf = (orderIdNumber?: number | string) => {
    this.setState({
      isDrawerStatusOpen: false,
    });
    this.getTrackOrderDetailApi(orderIdNumber)
  }
  toggleStatusDataClose = (orderIdNumber?: number | string) => {
    this.setState({
      isDrawerStatus: false,
    });
    this.getTrackOrderDetailApi(orderIdNumber)
  }

  handleOpenRejectDetils = (orderIdReturn: number | string) => {
    this.setState({ rejectOpenReturn: true, orderIdReturnId: orderIdReturn })
  };
  handleCancelReturnDetils = () => {
    this.setState({ rejectOpenReturn: false })
  }

  acceptRequestDetils = async (sellerId: string | number) => {
    const token = await getStorageData("auth-token");
    const headers = {
      "token": token
    };
    const requestMessageDetils = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerAcceptApiCallId = requestMessageDetils.messageId;
    requestMessageDetils.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=return_accept&seller_order_id=${sellerId}`
    );
    requestMessageDetils.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageDetils.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessageDetils.id, requestMessageDetils);
    return true;
  }

  handleCancelvisibleReturnDelils = () => {
    this.setState({ visibleReturn: false })
  }
  handleOpenvisibleReturnDetils = (orderIdReturn: number | string) => {
    this.setState({ visibleReturn: true, orderIdReturnId: orderIdReturn })
  };

  handleSingleChangeRetunDetils = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ descriptionReson: event.target.value as string });
  };

  handleSingleChangeNumberRetrunDetils = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberDataRetun: event.target.value as string });
  };

  rejectDataRetrunDetils = () => {
    this.setState({ visibleReturn: false })
    this.rejectOrderDataReturnDetils()
  }

  rejectOrderDataReturnDetils = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListDetils = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectReturnOrderCallId = requestMessageListDetils.messageId;
    requestMessageListDetils.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListDetils.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?seller_order_id=${this.state.orderIdReturnId}&type=return_reject&reason_of_rejection=${this.state.numberDataRetun}&rejection_detail=${this.state.descriptionReson}`
    );
    requestMessageListDetils.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListDetils.id, requestMessageListDetils);
  }

  tranSellerAllOrderDetails = (transKey: string) => {
    return i18n.t( transKey, {ns: "orderDetail"})
  }
  // Customizable Area End
}
