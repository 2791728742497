import React from "react";
import {
  ArcElement as ChartJSArcElement,
  ArcOptions as ChartJSArcOptions,
  ArcProps as ChartJSArcProps,
  Color as ChartJSColor,
  Chart as ChartJS,
  ChartData as ChartJSData,
  DoughnutController as ChartJSDoughnutController,
  Element as ChartJSElement,
  Plugin as ChartJSPlugin,
} from "chart.js";
import { AnyObject as ChartJSAnyObject } from "chart.js/types/basic";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ChartJSArcElement);

// Define Round and CustomElement types
interface Round {
  x: number;
  y: number;
  radius: number;
  arcColor: ChartJSColor;
}

type CustomElement =
  | (ChartJSElement<
      ChartJSAnyObject | ChartJSArcProps,
      ChartJSAnyObject | ChartJSArcOptions
    > & {
      round?: Round;
    })
  | (ChartJSArcElement<ChartJSArcProps, ChartJSArcOptions> & {
      round?: Round;
    });

// Define plugins
const plugins: ChartJSPlugin<"doughnut">[] = [
  {
    id: "arcCaps",
    afterUpdate: function(chart) {
      const { data, controller } = chart.getDatasetMeta(0);
      const {
        outerRadius,
        innerRadius,
      } = controller as ChartJSDoughnutController;

      for (let i = data.length - 1; i >= 0; --i) {
        const arc: CustomElement = data[i];

        // determine total radius by diffing outer values
        const radiusLength = outerRadius - innerRadius;

        arc.round = {
          // chart's x/y lengths
          x: (chart.chartArea.left + chart.chartArea.right) / 2,
          y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
          // radius of a single arc
          radius: innerRadius + radiusLength / 2,
          arcColor: arc.options.backgroundColor as ChartJSColor,
        };
      }
    },

    afterDraw: function(chart) {
      const { ctx } = chart;
      const { data } = chart.getDatasetMeta(0);

      for (let i = data.length - 1; i >= 0; --i) {
        const arc: CustomElement = data[i];
        const round = arc.round as Round;
        const props = (arc as ChartJSArcElement<
          ChartJSArcProps,
          ChartJSArcOptions
        >).getProps([
          "startAngle",
          "endAngle",
          "innerRadius",
          "outerRadius",
          "circumference",
        ]);

        const endAngle = Math.PI / 2 - props.endAngle;
        const startAngle = Math.PI / 2 - props.startAngle;

        ctx.save();
        ctx.translate(round.x, round.y);

        // Padding arc
        ctx.fillStyle = "white";
        ctx.beginPath();
        ctx.arc(
          round.radius * Math.sin(endAngle),
          round.radius * Math.cos(endAngle),
          props.outerRadius - props.innerRadius,
          0 + props.endAngle,
          Math.PI + props.endAngle
        );
        ctx.closePath();

        // Cap arc
        ctx.fillStyle = round.arcColor;
        ctx.beginPath();
        const capRadius =
          i === 1 || i === 3 ? 0 : (props.outerRadius - props.innerRadius) / 2;
        ctx.arc(
          round.radius * Math.sin(endAngle),
          round.radius * Math.cos(endAngle),
          capRadius,
          0,
          Math.PI * 2
        );
        ctx.closePath();
        ctx.fill();

        // Tail curve effect
        if (i === 0 || i === 2) {
          const tailRadius = capRadius;
          ctx.beginPath();
          ctx.arc(
            round.radius * Math.sin(startAngle),
            round.radius * Math.cos(startAngle),
            tailRadius,
            0,
            Math.PI * 2
          );
          ctx.fill();
          ctx.closePath();
        }

        ctx.restore();
      }
    },
  },
];

interface IPieChartProps {
  labels: ChartJSColor[];
  dataSetsVal: number[];
  backgroundColor: string[];
}

const PieChart: React.FC<IPieChartProps> = ({
  labels,
  dataSetsVal,
  backgroundColor,
}) => {
  // Define data and plugins
  const data: ChartJSData<"doughnut"> = {
    labels: labels,
    datasets: [
      {
        data: dataSetsVal,
        backgroundColor: backgroundColor,
        borderWidth: 0,
      },
    ],
  };
  return (
    <Doughnut
      width={300}
      height={300}
      data={data}
      plugins={plugins}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        cutout: "80%",
        rotation: -25,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              title: (context) => {
                const index = context[0].dataIndex;
                return index === 0 ? '         ' : '';
              },
              label: function(tooltipItem) {
                const dataIndex = tooltipItem.dataIndex;
                const dataset = data.datasets[0];
                const value = dataset.data[dataIndex];
                const label = data.labels ? data.labels[dataIndex] : "";
                return `${label}: ${value}`;
              },
            },
          },
          legend: { display: false },
        },
        elements: {
          arc: { borderWidth: 0 },
        },
      }}
    />
  );
};

export default PieChart;
