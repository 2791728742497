import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Stepper,
  Step,
  StepConnector,
  StepLabel,
  Card, CardContent, Divider
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { vectorImg, scrollImg, backArrowImg } from "./assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import ReturnStatusController, {
  ReturnSeller,
  Props,
} from "./ReturnStatusController.web";
import { ColorlibStepIcon } from "../../../components/src/CommonStepper";
import { Noimageavailable } from "../../categoriessubcategories/src/assets";
import i18n from "../../../components/src/i18next/i18n";
import { ConnectorProps } from "./OrderDetails.web";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";

const ReturnColorlibConnector = withStyles({
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#CBD5E1",
    borderRadius: 1,
  },
  alternativeLabel: {
    left: (props: ConnectorProps) => (props.direction === 'ltr' ? "calc(-50% + 6px)" : "calc(50% + 6px)"),
    right: (props: ConnectorProps) => (props.direction === 'ltr' ? "calc(50% + 6px)" : "calc(-50% + 6px)"),
  },
  completed: {
    "& $line": {
      backgroundColor:"#CCBEB1"
    },
  },
  active: {
    "& $line": {
      backgroundColor:"#CCBEB1"
    },
  },
})(StepConnector);

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      color: "#375280",
      fontFamily: "Lato, sans-serif",
    },
  },
});

export default class ReturnStatus extends ReturnStatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleRightImgIcon = ()=>(
    <img src={vectorImg} style={{transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    // istanbul ignore next
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {this.state.stylishBuyer ? <StylishBuyerHeader navigation={this.props.navigation}/> : <Header navigation={this.props.navigation} />}
        <MainBox data-test-id="mainGrid" dir={i18n.dir()}>
          <Box className="nav-header" style={{margin: i18n.dir() === "ltr" ? "0px 0px 0px 40px" : "0px 40px 0px 0px"}}>
            <Typography onClick={this.proceedToReturnHomePage} className="nav-steps" data-test-id="proceedToReturnHomePage">{this.transReturnOrderDetail("Home")}</Typography>
            {this.handleRightImgIcon()}
            <Typography className="nav-steps">
              {this.transReturnOrderDetail("My Account")}
            </Typography>
            {this.handleRightImgIcon()}
            <Typography className="nav-steps" data-test-id="proceedToReturnPage" onClick={this.proceedToReturnPage}>{this.transReturnOrderDetail("My Order")}</Typography>
            {this.handleRightImgIcon()}
            <Typography className="nav-steps-active">
            {this.transReturnOrderDetail("Return Status")}
            </Typography>
          </Box>
          <Grid className="content-block" container>
            <Grid item md={3} lg={3}>
              <Box>
                <Typography className="user-name" style={{margin: i18n.dir() === "ltr" ? "0px 0px 0px 40px" : "0px 40px 0px 0px"}}>
                  <img src={scrollImg} />

                  {this.state.profileName.split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}

                </Typography>
              </Box>
              <Typography className="welcome-text" style={{margin: i18n.dir() === "ltr" ? "0px 0px 37px 40px" : "0px 40px 37px 0px"}}>{this.transReturnOrderDetail("Welcome to your Account")}</Typography> 
              <Sidebar navigation={this.props.navigation}/>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              xl={8}
              lg={8}
              md={8}
            >
              <Box className="details-header" >
                 <Box className="header-left-block">
                  <img src={backArrowImg} className="back-arrow" style={{transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => this.navigateToReturn({ id: (this.state.returnDetailsList as ReturnSeller).id, props: this.props, screenName: "OrderManagementPage" })} />
                  <Typography className="section-name">{this.transReturnOrderDetail("Return Status")}</Typography>
                </Box>
              </Box>
              <Box className="return-data">
                <Box>
                  <Typography className="return-id">
                    {this.transReturnOrderDetail("Order ID")} : #{(this.state.returnDetailsList as ReturnSeller)?.attributes?.order_number} 
                  </Typography>
                  <Typography className="purchase-date-weight">
                  {this.transReturnOrderDetail("Purchase date")}:{" "} 
                    <span className="purchase-date" >{this.getReturnFormatDate((this.state.returnDetailsList as ReturnSeller)?.attributes?.created_at)}</span>
                  </Typography>
                </Box>
                <Box> 
                  <Typography className="total-string ">
                  {this.transReturnOrderDetail("Total")}: <span className="total-sum" >{this.state.currencySign} {(this.state.returnDetailsList as ReturnSeller)?.attributes?.total}</span>
                  </Typography> 
                </Box> 
              </Box>
              <Stepper
                alternativeLabel
                connector={<ReturnColorlibConnector direction={i18n.dir()} />}
                activeStep={this.state.activeStepRetun}
                data-test-id="Stepper"
                className="stepperContainer"
              >
                {[{ orderStatus: `${this.transReturnOrderDetail("Return placed")}`, description: `${this.transReturnOrderDetail("Return placed Text")}${this.state.updatedAttributesData.returnPlacedAt ? this.getReturnFormatMonth(this.state.updatedAttributesData.returnPlacedAt) : ' '}`}, { orderStatus: `${this.transReturnOrderDetail("Return confirmed")}`, description: `${this.transReturnOrderDetail("Return confirmed Text")}${this.state.updatedAttributesData.returnConfirmedAt ? this.getReturnFormatMonth(this.state.updatedAttributesData.returnConfirmedAt) : ' '}` }, { orderStatus: `${this.transReturnOrderDetail("Out For Pickup")}`, description: `${this.transReturnOrderDetail("Out For Pickup Text")}${this.state.updatedAttributesData.returnedAssignAt ? this.getReturnFormatMonth(this.state.updatedAttributesData.returnedAssignAt) : ' '}` }, { orderStatus: `${this.transReturnOrderDetail("Order Picked Up")}`, description: `${this.transReturnOrderDetail("Order Picked Up Text")}${this.state.updatedAttributesData.returnPickAt ? this.getReturnFormatMonth(this.state.updatedAttributesData.returnPickAt) : ' '}` }, { orderStatus: `${this.transReturnOrderDetail("Return Completed")}`, description: `${this.transReturnOrderDetail("Return Completed Text")}${this.state.updatedAttributesData.returnAt ? this.getReturnFormatMonth(this.state.updatedAttributesData.returnAt) : ' '}` }].map((label: { orderStatus: string, description: string }) => (
                  <Step> 
                    <StepLabel StepIconComponent={ColorlibStepIcon} >
                      <Box> 
                        <Box className="stepTitle">{label?.orderStatus}
                        </Box> 
                        <Box className="stepDescription">
                          {label?.description}</Box> 
                      </Box> 
                    </StepLabel> 
                  </Step>
                ))} 
              </Stepper> 
              <Box className="confirmedBoxContainer"> 
                <Box className="containerPopup"> 
                  <Typography className="titlePopup" variant="body2">
                    {this.getReturnFormatMonthDayTime(this.state.updatedAttributesData.returnConfirmedAt)} 
                  </Typography> 
                  <Typography className="titlePopup2" variant="body2" style={{margin: i18n.dir() === "ltr" ? "0px 0px 0px 30px" :"0px 20px 0px 0px",}}>
                  {this.transReturnOrderDetail("Order confirmed Text")} {this.getReturnFormatMonth(this.state.updatedAttributesData.returnConfirmedAt)} 
                  </Typography> 
                </Box>
                <Box className="confirmedBox" style={{left: i18n.dir() === "ltr" ? "25%" : "45%"}}></Box> 
              </Box> 
              <Card className="cardContainer"> 
                <CardContent> 
                  {(this.state.returnDetailsList as ReturnSeller)?.attributes?.order_items.map((item, index, array) => ( 
                    <> 
                      <Grid key={index} alignItems="center" container spacing={2}>
                        <Grid item> 
                          <img className="returnCardTitleImg" src={item.attributes.catalogue_variant_front_image ? item.attributes.catalogue_variant_front_image : Noimageavailable} alt={item.attributes.catalogue_name} />
                        </Grid> 
                        <Grid item xs={6} >
                          <Typography className="returnCardTitle" variant="h6">{item.attributes.catalogue_name}</Typography>
                          <Typography className="returnCardTitle" variant="body2">{this.transReturnOrderDetail("Color")}: <span className="returnCardTitleValue"> {item.attributes.catalogue_variant_color}</span></Typography>
                          <Typography variant="body2" className="returnCardTitle" >{this.transReturnOrderDetail("Size")}: <span className="returnCardTitleValue">{item.attributes.catalogue_variant_size}</span></Typography>
                        </Grid> 
                        <Grid item xs={2}> 
                          <Typography variant="body2" align="right" className="returnCardTitle">{this.transReturnOrderDetail("Qty")}: <span className="returnCardTitleValue">{item.attributes.quantity}</span></Typography>
                        </Grid> 
                        <Grid item xs={2}> 
                          <Typography variant="body2" align="right" className="returnCardTitle">{this.state.currencySign} {item.attributes.total_price}</Typography>
                        </Grid> 
                      </Grid> 
                      {index < array.length - 1 && <Divider className="DividerMargin"/>}
                    </> 
                  ))} 
                </CardContent> 
              </Card> 
            </Grid> 
          </Grid> 
        </MainBox> 
        <Footer /> 
      </ThemeProvider> 
    ); 
    // Customizable Area End
  }
}
// Customizable Area Start
const MainBox = styled(Box)({
  background: "#F8F8F8",
  paddingTop: "27px",
  "& .containerPopup": {
    alignItems: "center",
    display: "flex",
    backgroundColor: "#F6F6F6",
    padding: "20px",
    zIndex: 2,
    borderRadius: "8px",
    position: 'relative',
  },
  "& .stepperContainer": {
    backgroundColor: "#F6F6F6"
  },
  "& .titlePopup": {
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#94A3B8",
    textAlign: "left",
  },
  "& .confirmedBoxContainer": {
    position:"relative",
    borderRadius: "8px",
    maxWidth: "60%",
    marginLeft: 130,
    border: "1px solid rgb(226, 232, 240)",
    marginTop: 20
  },
  "& .confirmedBox": {
    height: 50,
    width: 50,
    backgroundColor: "#F6F6F6",
    position: "absolute",
    transform: "rotate(45deg)",
    top: -10,
    zIndex: 1,
    border: "1px solid rgb(226, 232, 240)",
  },
  "& .returnCardTitle": {
    fontSize: "22px",
    fontFamily: "Lato",
    color: "#375280",
    fontWeight: 700,
  },
  "& .titlePopup2": {
    fontSize: "16px",
    fontFamily: "Lato",
    color: "#375280",
    textAlign: "left",
    fontWeight: 700,
  },
  "& .cardContainer": {
    marginBottom: '100px',
    boxShadow: "none",
    marginTop: '50px',
  },
  "& .MuiCardContent-root": {
    padding: "40px"
  },
  "& .returnCardTitleImg": {
    height: '102px',
    width: '102px',
    marginRight: "15px",
    borderRadius: '3px',
  },
  "& .returnCardTitleValue": {
    fontSize: "22px",
    fontFamily: "Lato",
    color: "#94A3B8",
    fontWeight: 700,
  },
  "& .DividerMargin": {
    margin: '24px 0',
  },
  "& .stepTitle": {
    fontSize: "15px",
    fontFamily: "Lato",
    fontWeight: 1000,
    color: "#375280",
    textAlign: "center",
  },
  "& .stepTitleDate": {
    fontSize: "14px",
    fontFamily: "Lato",
    textAlign: "center",
    fontWeight: 1000,
    color: "#979797",
  },
  "& .nav-header": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  "& .stepDescription": {
    fontSize: "14px",
    fontFamily: "Lato",
    textAlign: "center",
    fontWeight: 400,
    color: "#94A3B8",
  },
  "& .nav-steps": {
    fontStyle: "normal",
    lineHeight: "22px",
    fontSize: "14px",
    color: "#475569",
    padding: "4px 6px 4px 6px",
  },
  "& .nav-steps-active": {
    fontStyle: "normal",
    fontSize: "14px",
    padding: "4px 6px 4px 6px",
    lineHeight: "22px",
    color: "#475569",
    fontWeight: 1000,
  },
  "& .user-name": {
    alignItems: "center",
    lineHeight: "33.5px",
    display: "flex",
    gap: "15px",
    fontWeight: 800,
    fontSize: "28px",
    fontStyle: "normal",
  },
  "& .welcome-text": {
    color: "#807D7E",
    lineHeight: "33.5px",
    fontStyle: "normal",
    fontSize: "14px",
  },
  "& .content-block": {
    marginTop: "2rem",
  },
  "& .details-header": {
    justifyContent: "space-between",
    display: "flex",
  },
  "& .header-left-block": {
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
  },
  "& .back-arrow": {
    borderRadius: "100px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px #00000014",
    cursor: "pointer",
    padding: "0.5rem",
  },
  "& .section-name": {
    fontWeight: 800,
    fontSize: "1.25rem",
  },
  "& .return-data": {
    padding: "1.5rem 2.5rem",
    margin: "2.5rem 0 3rem",
    backgroundColor: "#FFF",
    display: "flex",
    borderRadius: "2px",
    justifyContent: "space-between",
  },
  "& .return-id": {
    fontWeight: 800,
    fontSize: "1.25rem",
    marginBottom: "0.75rem",
    lineHeight: "1.5rem",
  },
  "& .purchase-date-weight":{
    fontWeight: 800
  },
  "& .purchase-date": {
    color: "#807D7E",
    fontWeight: 400,
  },
  "& .total-sum": {
    fontWeight: 700,
    color: "#375280",
  },
  "& .total-string": {
    lineHeight: "1.5rem",
    color: "#94A3B8", 
    fontSize: "1.25rem",
  },
});

// Customizable Area End
