import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ChartData, ChartOptions } from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface TwoLineChartProps {
  labels: string[];
  datasets: {
    data: number[];
    borderColor: string;
    backgroundColor: string;
    fill: boolean;
    borderWidth: number;
    pointRadius?: number;
    pointHoverRadius?: number;
  }[];
  xTickColor: string;
  yTickColor: string;
  highlightLastTick?: boolean;
  isPercentage?: boolean;
  isRtl?: boolean
}

const TwoLineChart: React.FC<TwoLineChartProps> = ({
  labels,
  datasets,
  xTickColor,
  yTickColor,
  highlightLastTick,
  isPercentage,
  isRtl = false
}) => {
  const data: ChartData<"line"> = {
    labels: labels,
    datasets: datasets,
  };

  const isRTL = !!isRtl;

  const shouldRotateLabels = labels.length > 12;

  const options: ChartOptions<"line"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "Two Line Chart",
      },
    },
    scales: {
      x: {
        grid: {
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: (context) => {
            if (context.tick.value === 0) {
              return "transparent";
            }
            if (highlightLastTick && context.tick.value === 11) {
              return "lightgray";
            }
            return "transparent";
          },
        },
        ticks: {
          color: xTickColor,
          font: {
            size: 13,
            weight: "bold",
          },
          autoSkipPadding: 100,
          maxTicksLimit: 12,
          maxRotation: shouldRotateLabels ? 45 : 0,
          minRotation: shouldRotateLabels ? 45 : 0,
          autoSkip: shouldRotateLabels,
        },
        reverse: isRTL, 
      },
      y: {
        beginAtZero: true,
        position: isRTL ? "right": "left",
        ticks: {
          color: yTickColor,
          font: {
            size: 13,
            weight: "bold",
          },
          callback: function(value: number | string) {
            return isPercentage ? value+ '%' : value;
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        overflowX: "auto",
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default TwoLineChart;
