import React from "react";

import {
  Box,
  Button,
  Typography,
  IconButton,
  // Customizable Area Start
  Grid, Modal, Fade, CircularProgress, Snackbar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Alert from '@material-ui/lab/Alert';
import { styled } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { MoreVert } from '@material-ui/icons';
import { GoSellElements } from '@tap-payments/gosell';
import i18n from "../../../components/src/i18next/i18n";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
// Customizable Area End
import SavedcardsController, {
  Props,
  configJSON,
} from "./SavedcardsController";
import { Vector, scroll } from "../../addressmanagement/src/assets";
import { cardImage } from "./assets";

export default class Savedcards extends SavedcardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleThreeDotCSS = () =>{
    return i18n.dir()==='ltr' ? {right: 10} : {left: 10}
  }

  handleSaveCardImgCSS = () =>{
    return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  navHeaderSaveCardMargin = () =>{
    return i18n.dir() === "ltr" ? "27px 0px 20px 40px" : "27px 40px 20px 0px"
  }

  nameSaveCardCSS = () => {
    return i18n.dir() === "ltr" ? "31px 0px 20px 40px" : "31px 40px 20px 0px"
  }

  welcomeSaveCardCSS = () => {
    return i18n.dir() === "ltr" ? "0px 0px 37px 40px" : "0px 40px 37px 0px"
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      {this.state.stylishbuyer ? <StylishBuyerHeader navigation={this.props.navigation} />: <Header navigation={this.props.navigation} />}
        <Grid container style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }} dir={i18n.dir()}>
          <Grid item xs={12} md={3} style={{ paddingInline: 20 }}>
            <div style={{...webStyle.innerContainer123, margin: this.navHeaderSaveCardMargin()}}>
              <Typography style={webStyle.innerContainer124} data-test-id="home-test-id" onClick={()=>this.handleNavigationSaveCard("LandingPage")}>
                {this.transSavedCards("Home")}
              </Typography>
              <img src={Vector} alt="Vector Icon" style={{transform: this.handleSaveCardImgCSS()}} />
              <Typography style={webStyle.innerContainer234}>
                {this.transSavedCards("Saved Cards")}
              </Typography>
            </div>
            <div>
              <Typography style={{ ...webStyle.innerContainer33, textTransform: 'capitalize', margin: this.nameSaveCardCSS() }}>
                <img src={scroll} alt="Scroll Icon" />
                {this.state.profileName}
              </Typography>
            </div>
            <Typography style={{...webStyle.innerContainer44, margin: this.welcomeSaveCardCSS()}}>
            {this.transSavedCards("Welcome to your Account")}
            </Typography>
            <Sidebar navigation={this.props.navigation} />
          </Grid>
          <Grid item xs={12} md={9} style={{ paddingInline: 20 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: { xs: "50px", md: "100px" },
                flexDirection: { xs: "column", sm: "row" },
                marginBottom:'70px'
              }}
            >
              <Typography style={webStyle.headerTypography}>
              {this.transSavedCards("Saved Cards")}
              </Typography>
              <Button style={{ ...webStyle.addNewCard, textTransform: 'capitalize' }} data-testid="handleOpenCard" onClick={this.handleOpen}>
              {this.transSavedCards("Add New Card")}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap:'Wrap',
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              {this.state.tabeLoder && (
                <Box data-testid="loder" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}>
                  <CircularProgress style={{ color: '#375280' }} />
                </Box>
              )}
              {!this.state.tabeLoder && this.state.autheAllCard.length === 0 && this.state.isAlert && (
                <Box style={{ ...webStyle.tabeLable, textAlign: 'center', textAlignLast: 'center' }}>
                  {this.transSavedCards("No Cards added to this account")}
                </Box>
              )}
              {!this.state.tabeLoder && this.state.autheAllCard.map((item, index) => (
                <>
                  <Box key={index}
                    sx={{
                      width: { xs: "100%", sm: "382px" },
                      height: { xs: "auto", sm: "282px" },
                      marginRight: { xs: "0", md: i18n.dir()==="ltr"? "40px": 0 },
                      marginLeft: {xs: "0", md: i18n.dir()==="ltr" ? 0 : "40px"},
                      padding: "20px",
                      borderRadius: "5px",
                      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                      marginBottom:"20px"
                    }}
                  >
                    <Box sx={{
                      marginBottom: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                      <IconButton
                        aria-label="more options"
                        style={{ textAlign: "right", position: "absolute", top: "10px", color: '#375280', ...this.handleThreeDotCSS()}}
                        data-testid="handleMenuOpen"
                        onClick={() => this.handleOpenMenu(item.id)}
                      >
                        <MoreVert />
                      </IconButton>
                    </Box>
                    {this.state.anchor === item.id &&
                      <Box
                        style={{ ...webStyle.menuOpen, position: "absolute", ...this.handleThreeDotCSS()}}
                      >
                        <Typography data-testid="handleMenuOpenCard" onClick={() => this.handleMenuOpenCard()}
                          style={webStyle.menuDelete}
                        >
                          {this.transSavedCards("Delete")}
                        </Typography>
                      </Box>}
                    <Box style={webStyle.cardStyle}>
                      <Box style={webStyle.logoStyle}>
                        <Box style={webStyle.mastercardLogoStyle}></Box>
                        <Box style={webStyle.mastercardLogoRightStyle}></Box>
                      </Box>
                      <Typography style={webStyle.cardnumber1}>
                        {this.formatCardNumber(item.first_eight + item.first_six + item.last_four)}
                      </Typography>
                      <Box>
                        <Typography style={webStyle.cardtext1}>
                        {this.transSavedCards("Card Holder")}
                        </Typography>
                        <Typography style={{ ...webStyle.cardtext2, textTransform: 'capitalize' }}>
                          {item.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ))}
            </Box>
          </Grid>
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={this.state.open}
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Box style={{ background: '#ffff' }}>
              <Box style={{ width: '100%', background: '#ffff' }}>
                <Box style={{...webStyle.cardWitdh, position: "absolute" }}>
                  <GoSellElements
                    transaction={{
                      mode: 'token',
                      charge: {
                        saveCard: true,
                        threeDSecure: true,
                        description: "Test Description",
                        statement_descriptor: "Sample",
                        reference: {
                          transaction: "txn_0001",
                          order: "ord_0001"
                        },
                        metadata: {},
                        receipt: {
                          email: false,
                          sms: true
                        },
                        redirect: "REDIRECT_URL",
                        post: null,
                      }
                    }}
                    gateway={{
                      publicKey: configJSON.publicKey,
                      language: i18n.dir()==='ltr'? "en" : "ar",
                      supportedCurrencies: "all",
                      supportedPaymentMethods: "all",
                      notifications: 'msg',
                      callback: this.callbackFunc,
                      labels: {
                        cardNumber: this.transSavedCards("Card Number"),
                        expirationDate: this.transSavedCards("MM/YY"),
                        cvv: "CVV",
                        cardHolder: this.transSavedCards("Name on Card"),
                        actionButton: "Pay"
                      },
                      style: {
                        base: {
                          color: '#535353',
                          lineHeight: '18px',
                          fontFamily: 'sans-serif',
                          fontSmoothing: 'antialiased',
                          fontSize: '16px',
                          '::placeholder': {
                            color: 'rgba(0, 0, 0, 0.26)',
                            fontSize: '15px'
                          }
                        },
                        invalid: {
                          color: 'red',
                          iconColor: '#fa755a '
                        }
                      }
                    }}
                  />
                  <p id="msg"></p>
                  <Box style={{display:'flex',justifyContent: "space-between"}}>
                    <Button style={{ ...webStyle.closeCard, textTransform: 'capitalize', }} data-testid="saveCardhandleClose" onClick={this.handleClose}>{this.transSavedCards("Close")}</Button>
                    <Button style={{ ...webStyle.savedCard, textTransform: 'capitalize', }} data-testid="saveCardSubmit" onClick={this.submitCard}>{this.transSavedCards("Save Card")}</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open1}
            closeAfterTransition
            style={{ ...webStyle.menuDrawer }}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.open1} style={{ borderRadius: '10px', maxWidth: "320px", backgroundColor: "white" }}>
              <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                <h2 id="transition-modal-title" style={{ width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" }}>{this.transSavedCards("Are you sure you want to remove the card ?")}</h2>
                <div style={{ marginTop: "40px", display: "flex", gap: "20px", justifyContent: "space-between", flexDirection: i18n.dir()==='ltr' ? 'row':'row-reverse' }}>
                  <Button style={{ ...webStyle.styleButtonCancel, textTransform: "none" }} data-testid="handleClose1" onClick={this.handleMenuCloseCard}>{this.transSavedCards("Cancel")}</Button>
                  <Button style={{ ...webStyle.styleButtonSave, textTransform: "none" }} data-testid="cancelOrder" onClick={this.deleteCard}>{this.transSavedCards("Yes")}</Button>
                </div>
              </div>
            </Fade>
          </Modal>
        </Grid>
        <Snackbar
          open={this.state.tostAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-testid="alertTestId"
        ><Alert variant="filled" severity="success">{this.transSavedCards("Your card has been removed")}</Alert></Snackbar>
        <Footer navigation={this.props.navigation} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  cardWitdh:{
    width:"30%",
    top: "50%", 
    left: "50%", 
    transform: "translate(-50%, -50%)", 
    background: '#ffff', 
    padding: '30px', 
    borderRadius: "5px"
  },
  addNewCard: {
    letterSpacing: '1px',
    height: "54px",
    backgroundColor: "#CCBEB1",
    padding: "10px 16px",
    borderRadius: "2px",
    color: "#ffffff",
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato",
    '&:hover': {
      backgroundColor: "#CCBEB1"
    }
  },
  styleButtonCancel: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "18px",
    width: "150px",
    fontFamily: "Lato",
    height: "45",
    border: "1px solid #CCBEB1",
    backgroundColor: '#ffff',
    '&:hover': {
      backgroundColor: "#fff"
    },
  },
  styleButtonSave: {
    fontWeight: 700,
    color: "#fff",
    fontSize: "18px",
    width: "150px",
    height: "45",
    fontFamily: "Lato",
    backgroundColor: '#CCBEB1',
    '&:hover': {
      backgroundColor: "#CCBEB1"
    },
  },
  styleButtonModel: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "150px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
      backgroundColor: "white",
    }
  },
  menuDelete: {
    cursor: "pointer",
    padding: "10px",
    color: "#FF0000",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Lato",
    height: "12px",
  },
  menuMack: {
    padding: "10px",
    color: "#375280",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Lato",
    height: "12px",
    width: "inherit",
    borderBottom: "1px solid #D5D5D5"
  },
  menuOpen: {
    backgroundColor: "#fff",
    height: "40px",
    zIndex: 3,
    top: "65px",
    boxShadow: "0px 2px 2px 0px #00000014"
  },
  addAddressContainer: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '26px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },
  dropdown: {
    borderRadius: '4px',
    boxShadow: '0px 2px 8px 0px #00000014',
    backgroundColor: 'white',
    zIndex: 1,
    left: 0,
    width: '120px',
    top: '100%',
    position: 'absolute',
  },
  dropdownP: {
    fontWeight: "600",
    fontSize: "12px",
    color: "#375280",
    paddingRight: '6px',
    paddingLeft: '6px',
    lineHeight: "22px",
    paddingBottom: '10px',
    borderBottom: '1px solid #D5D5D5',
  },
  dropdownP1: {
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },
  dropdownDefault: {
    fontSize: "12px",
    color: "#375280",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },
  innerContainer123: {
    gap: "10px",
    display: "flex",
    fontWeight: 400,
    alignItems: "center",
    color: "#475569",
  },
  innerContainer124: {
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px",
    cursor: "pointer"
  },
  innerContainer234: {
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#475569",
    fontWeight: 700,
    cursor: "pointer"
  },
  innerContainer33: {
    alignItems: "center",
    display: "flex",
    color: "#375280",
    fontWeight: 800,
    gap: "15px",
    fontStyle: "normal",
    fontSize: "28px",
    fontFamily: "Lato , sans-serif",
  },
  innerContainer44: {
    color: "#807D7E",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
  },
  headerTypography: {
    color: "#375280",
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato",
  },
  primaryText: {
    width: "68px",
    height: "26px",
    color: "#94A3B8",
    backgroundColor: "#E2E8F0",
    fontWeight: 700,
    fontSize: "12px",
    textAlign: "center" as 'center',
    fontFamily: "Lato" as "Lato",
    padding: '5px 0px 0px 0px'
  },
  cardStyle: {
    background: `url(${cardImage}) no-repeat center/cover`,
    borderRadius: '2px',
    width: '344px',
    height: '186px',
    padding: '20px',
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as "column",
    justifyContent: 'space-between' as 'space-between',
  },
  logoStyle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  mastercardLogoStyle: {
    backgroundColor: '#EA001B',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginRight: '-10px',
    zIndex: 2,
  },
  mastercardLogoRightStyle: {
    backgroundColor: '#F79F1A',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginLeft: '7px',
    zIndex: 1,
  },
  cardnumber1: {
    fontFamily: 'Lato',
    fontWeight: 700,
    marginBottom: '50px',
    color: '#fff',
    fontSize: '22px',
  },
  cardtext1: {
    fontFamily: 'Lato', marginBottom: '4px', color: '#fff', fontWeight: 500, fontSize: '14px'
  },
  cardtext2: {
    fontFamily: 'Lato', color: '#fff', fontSize: '16px', fontWeight: 500,
  },
  menuIcon: {
    textAlign: 'end' as "end",
    cursor: 'pointer' as "pointer",
    color: '#375280'
  },
  tabsBox: { width: '90%', height: '50vh', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  tabeLable: { color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '90%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  closeCard:{
    height: "50px",
    borderRadius: "4px",
    width: "45%",
    padding: "10px 16px",
    fontSize: "20px",
    color: "#375280",
    fontFamily: "Lato",
    fontWeight: 800,
    border:'1px solid #CCBEB1',
    '&:hover': {
      backgroundColor: "#ffff"
    },
  },
  savedCard: {
    backgroundColor: "#CCBEB1",
    height: "50px",
    borderRadius: "4px",
    width: "45%",
    padding: "10px 16px",
    fontSize: "20px",
    color: "#ffffff",
    fontFamily: "Lato",
    fontWeight: 800,
    '&:hover': {
      backgroundColor: "#CCBEB1"
    },
  },
  menuDrawer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
  }
};
// Customizable Area End
