import React from "react";
// Customizable Area Start
import { TextField, styled, Box, Button, Typography, Paper } from "@material-ui/core";
import { back_button, } from "./assets";
// Customizable Area End

import Cfvirtualmannequin3Controller, {
    Props,
    configJSON,
} from "./Cfvirtualmannequin3Controller";

export default class BasicDetails extends Cfvirtualmannequin3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { selectedAvatar, avatarName, age, height } = this.state;
        const buttonDisabled = !avatarName || !age || !height;
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainWrapper >
                <Box className="mainBox">
                    <HeadingWrapper>
                        <Paper data-test-id="navigateBack" onClick={() => this.handleNavigation('VirtualMannequin')} elevation={1} className="back_icon_Paper">
                            <img src={back_button} alt="back_button" />
                        </Paper>
                        <Typography className="headingText">{configJSON.basicDetailsHeading}</Typography>
                        <Button data-test-id="nextButton" onClick={this.handleBasicFormSubmit} disabled={buttonDisabled} className={!buttonDisabled ? "headingButtonEnable" : "headingButton"}>Next</Button>
                    </HeadingWrapper>
                    <ContentWrapper>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Avatar Name</Typography>
                            <TextField
                                data-test-id="avatarName"
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Avatar Name"
                                name="avatarName"
                                value={avatarName}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Your Age (In Years)</Typography>
                            <TextField
                                data-test-id="age"
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Age"
                                name="age"
                                value={age}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                        <Box className="contentBox">
                            <Typography className="inputHeading">Your Height (In cms.)</Typography>
                            <TextField
                                data-test-id="height"
                                fullWidth
                                variant="outlined"
                                className="inputFeild"
                                placeholder="Enter Height"
                                name='height'
                                value={height}
                                onChange={this.handleInputChange}
                            />
                        </Box>
                    </ContentWrapper>
                </Box>
            </MainWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    "& .mainBox": {
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        maxWidth: '400px',
        flexDirection: 'column',
        paddingInline: '48px',
        gap: '32px'
    },
    "& .avatar_heading": {
        marginBlock: "30px 14px",
        color: '#375280',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '26px',

    },
});

const HeadingWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '48px',
    "& .back_icon_Paper": {
        borderRadius: '50%',
        padding: '8px'
    },
    "& .headingText": {
        fontFamily: 'Avenir,sans-serif',
        color: '#375280',
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: '26px',

    },
    "& .headingButton": {
        fontFamily: 'Lato,sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '26px',
        textTransform: 'none',
        textDecoration: 'underline',
        color: '#94A3B8',
        minWidth: '0px',
        padding: '0px',

        "&.MuiButton-root:hover": {
            backgroundColor: '#fff'
        }


    },
    "& .headingButtonEnable": {
        fontFamily: 'Lato,sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '26px',
        textTransform: 'none',
        textDecoration: 'underline',
        color: '#375280',
        minWidth: '0px',
        padding: '0px',

        "&.MuiButton-root:hover": {
            backgroundColor: '#fff'
        }


    },
});

const ContentWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    flexDirection: 'column',
    alignItems: 'center',

    "& .contentBox": {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '6px',
    },

    "& .inputHeading": {
        color: "#375280",
        textOverflow: "ellipsis",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "24px",
    },

    "& .inputFeild": {
        "& .MuiTextField-root": {
            "&:hover": {
                border: "1px solid #F0F0F0 !important",
            },
        },
        "& .MuiOutlinedInput-root:hover": {
            border: "none !important",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",

        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        }
    },
});

// Customizable Area End
