import React, { Component } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { getFileType } from './chat.web';

const close = require('../assets/close.svg');

interface FileInputPreviewProps {
  file: File;
  handleCloseAttachment: () => void;
  loading: boolean;
}

interface FileInputPreviewState {}

const styles = {
  previewWrapper: {
    padding: '16px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    width: '100%',
    maxWidth: '400px',
    margin: '10px 18px 0 auto',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
    position: 'relative' as 'relative',
  },
  closeIcon: {
    position: 'absolute' as 'absolute',
    top: '16px',
    right: '8px',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  previewTitle: {
    fontSize: '1.25rem',
    color: "#375280",
    fontFamily: "Lato, sans-serif",
    fontWeight: 'bold' as 'bold',
    marginTop: 0,
    marginBottom: '10px',
  },
  fileName: {
    fontSize: '0.9rem',
    fontFamily: "Lato, sans-serif",
    color: "#375280",
    display: "flex",
    marginBottom: '8px',
    whiteSpace: 'nowrap' as 'nowrap',
    overflow: 'hidden' as 'hidden',
    textOverflow: 'ellipsis' as 'ellipsis',
    maxWidth: '100%',
  },
  loaderContainer: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
  },
  previewContainer: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '8px 0 4px',
    height: 'auto',
    width: '100%',
    maxWidth: '542px',
    backgroundColor: 'white',
    overflow: 'hidden' as 'hidden',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  previewMedia: {
    maxHeight: '200px',
    maxWidth: '100%',
    objectFit: 'contain' as 'contain',
  },
  unsupportedMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center' as 'center',
    fontSize: '1rem',
    fontFamily: "Lato, sans-serif",
    color: "#8A91A8",
  },
  dotLoader: {
    display: 'flex',
    alignItems: 'center',
    height: "120px",
    width: "auto",
    justifyContent: 'center',
  },
};

class FileInputPreview extends Component<FileInputPreviewProps, FileInputPreviewState> {

  getFileNameFromUrl = (fileName: string): string => {
    const maxLength = 30;

    if (fileName.length <= maxLength) return fileName;

    const ext = fileName.split(".").pop() || "";
    const nameWithoutext = fileName.slice(0, -ext.length - 1);
    const truncatedName = nameWithoutext.slice(0, maxLength - ext.length - 3);

    return `${truncatedName}...${ext}`;
  };

  renderPreview = () => {
    const { file } = this.props;
    const fileType = getFileType(file);
    const fileURL = URL.createObjectURL(file);
    

    switch (fileType) {
      case 'image':
        return (
          <Box style={styles.previewContainer}>
            <img src={fileURL} alt="Image preview" style={styles.previewMedia} />
          </Box>
        );
      case 'audio':
        return (
          <Box style={styles.previewContainer}>
            <audio src={fileURL} controls style={{ width: '100%' }} />
          </Box>
        );
      case 'video':
        return (
          <Box style={styles.previewContainer}>
            <video src={fileURL} controls style={{ width: '100%' }} />
          </Box>
        );
      case 'pdf':
      case 'text':
      case 'excel':
      case 'powerpoint':
        return (
          <Box style={styles.previewContainer}>
            
          </Box>
        );
      default:
        return (
          <Box style={styles.previewContainer}>
            <Box style={styles.unsupportedMessage}>
              <p>No preview available for this file type</p>
            </Box>
          </Box>
        );
    }
  };

  renderLoader = () => (
    <Box style={styles.dotLoader}>
      <CircularProgress size={20}/>
    </Box>
  );

  render() {
    const { file, loading } = this.props;
    const fileURL = URL.createObjectURL(file);
    return (
      <Box style={styles.previewWrapper}>
        <img src={close} alt="close" onClick={this.props.handleCloseAttachment} style={styles.closeIcon} />
        <h3 style={styles.previewTitle}>File Preview</h3>
        <Box style={styles.fileName} title={file.name}>
          {this.getFileNameFromUrl(file.name)}
        </Box>
        { loading ? this.renderLoader() : this.renderPreview() }
      </Box>
    );
  }
}

export default FileInputPreview;
