import React from 'react';
import { Backdrop, CircularProgress, Box, styled} from '@material-ui/core';

interface CommonBackdropProps {
    open: boolean;
}

const CommonBackdrop: React.FC<CommonBackdropProps> = ({ open }) => {
    return (
        <CustomBackDrop open={open} invisible={false}>
            <Box className='backdrop-container'><CircularProgress className="circular-progressbar" /></Box>
        </CustomBackDrop>
    );
};

export default CommonBackdrop;

const CustomBackDrop = styled(Backdrop)({
    zIndex: 1000, 
    backgroundColor: "transparent",
    "& .backdrop-container": {
        display:"flex",
        alignItems: "center", 
        borderRadius: 20, 
        backgroundColor: "#375280", 
        width: 70, height: 70, 
        justifyContent: "center",
        "& .circular-progressbar": {
            color: "#FFF"
        }
    }
})
