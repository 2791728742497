import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import i18n from "../../../components/src/i18next/i18n";
export const configJSON = require("./config");
type ToggleStatusId = number | string | undefined;
type AlertType = 'success' | 'error' | 'warning' | 'info';
interface INavigateToInProcess {
  props: unknown;
  productId: string | undefined;
  raiseMessage?: Message;
  screenName: string;
}
interface DeliveryAddressAttributes {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zip_code: string;
  area: string;
  block: string;
  city: string;
  house_or_building_number: string;
  address_name: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}

interface DeliveryAddress {
  id: string;
  type: string;
  attributes: DeliveryAddressAttributes;
}

interface PaymentDetail {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
  charge_id: string;
  merchant_id: string | null;
  order_id: string;
  amount: number;
  currency: string;
  customer_id: string;
  reason: string;
  account_id: number;
  order_management_order_id: number;
  refund_id: string | null;
  refund_amount: string | null;
  refund_reason: string | null;
  seller_order_id: string | null;
  last_four_card_digit: string;
  payment_type: string;
  deleted: boolean;
}

interface OrderManagementOrderAttributes {
  order_number: string;
  account: string;
  sub_total: string;
  total: string;
  status: string;
  placed_at: string;
  confirmed_at: string;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  returned_at: string | null;
  deliver_by: string | null;
  order_status_id: number;
  created_at: string;
  updated_at: string;
  order_deliver_date: string | null;
  order_deliver_time: string | null;
  delivery_addresses: DeliveryAddress;
  order_return_date: string | null;
  order_return_time: string | null;
  payment_detail: PaymentDetail;
}

interface OrderManagementOrder {
  id: string;
  type: string;
  attributes: OrderManagementOrderAttributes;
}

interface OrderItemAttributes {
  status: string;
  placed_at: string;
  confirmed_at: string;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  rejected_at: string | null;
  process_at: string;
  shipped_at: string | null;
  return_at: string | null;
  return_cancel_at: string | null;
  return_pick_at: string | null;
  quantity: number;  currency_logo: string
  unit_price: string;
  total_price: string;
  reason_of_rejection: string | null;
  catalogue_name: string;
  brand_name: string;
  catalogue_variant_color: string;
  catalogue_variant_sku: string;
  store_name: string;
  catalogue_variant_size: string;
  catalogue_variant_front_image: string;
  catalogue_variant_back_image: string;
  catalogue_variant_side_image: string;
  driver_name: string | null;
  driver_latitude: number | null;
  driver_longitude: number | null;
  driver_phone_number: string | null;
  otp: string | null;
}
interface OrderState {
  return_cancel_at: string | null;
  returned_assign_at: string | null;
  return_reject_at: string | null;
  return_placed_at: string | null;
  return_confirmed_at: string | null;
  return_at: string | null;
  return_pick_at: string | null;
  order_number: string;
  reason_refunded_cancel: string | null;
  reason: string;
  placed_at: string;
  refunded_at: string | null;
  refunded_cancel_at: string | null;
  otp: string | null;
  estimated_delivery_time: string | null;
  latitude: number;
  longitude: number;
}
interface OrderItem {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}
interface SellerOrderAttributes {
  id: number;
  status: string;
  accept_order_upload_time: string | null;
  order_items: OrderItem[];
  order_management_order: OrderManagementOrder;
}

interface SellerOrder {
  id: string;
  type: string;
  attributes: SellerOrderAttributes;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  apiRecall: Function
  searchAllOrder:string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rejectMrpData: number | string;
  orderIdReject: string | number;
  selectedFile: (File | null)[];
  combineImg: (string | null)[];
  txtInputValue: string;
  page: number;
  tableAllOrder: SellerOrder[];
  rejectOpen: boolean;
  rejectOpenReturn: boolean;
  numberDataRetun: string;
  visibleReturn: boolean,
  numberData: string;
  numberDataOrder: string;
  short: string[],
  visible: boolean,
  reloadTime: number,
  isLoading: boolean,
  isLoading1:boolean,
  isAlert: boolean,
  rejectId: number,
  confirmId: number,
  metaData: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  },
  activeTab: string,
  timerFlag: boolean,
  timerAllOrders: number,
  visibleAllOrder: boolean,
  isAlertOrder: boolean,
  already: string,
  orderIdNumber: number | string,
  isOpenDelete: boolean,
  refundAmount: string,
  saleAmount: number,
  errorMessage: string,
  isOpenDeleteReject: boolean,
  rejectionReason: string,
  selectReson: string[],
  alertType: AlertType,
  orderDeltilsId: string | number,
  isDrawerStatusOpen: boolean,
  orderNumber: string | undefined,
  placetAt: string | undefined,
  driverName: string | null | undefined,
  isDrawerStatus: boolean,
  toggleStatusDataId: ToggleStatusId,
  orderData: OrderState,
  latitudeData: { lat: number, lng: number },
  descriptionReson: string,
  orderIdReturnId: string | number,
  longitudeData: { lat: number, lng: number },
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersAllOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTimeInProcessOrderCallId: string = "";
  getMy_OrdersCallId: string = "";
  getAllOrderCallId: string = "";
  getRejectOrderCallId: string = "";
  getTimeOrderCallId: string = "";
  timerAllOrdersId: NodeJS.Timeout | null = null;
  getTimeAllOrderCallId: string = "";
  getTimeRefundOrderCallId: string = "";
  getTRackOrderApiCallId: string = "";
  getSellerAcceptApiCallId: string = "";
  getRejectReturnOrderCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      numberDataRetun: "",
      descriptionReson: "",
      orderIdReturnId: "",
      isDrawerStatusOpen: false,
      latitudeData: { lat: 0, lng: 0 },
      longitudeData: { lat: 0, lng: 0 },
      isDrawerStatus: false,
      toggleStatusDataId: "",
      isAlertOrder: false,
      rejectMrpData: 0,
      txtInputValue: "",
      orderIdReject: 0,
      alertType: 'success' as AlertType,
      page: 1,
      tableAllOrder: [],
      rejectOpen: false,
      numberData: "",
      numberDataOrder: "",
      selectReson: ["Worn or Used Items", "Damaged Items", "Altered Items", "Sale Items", "Past Return Window", "Hygiene Concerns"],
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      visible: false,
      rejectOpenReturn: false,
      visibleReturn: false,
      reloadTime: 45,
      isLoading1:false,
      isLoading: true,
      isAlert: false,
      rejectId: 0,
      confirmId: 0,
      metaData: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      }
      ,
      activeTab: 'Return Request',
      timerFlag: true,
      timerAllOrders: 30,
      visibleAllOrder: false,
      already: "",
      orderIdNumber: 0,
      isOpenDelete: false,
      refundAmount: '',
      saleAmount: 100,
      errorMessage: '',
      isOpenDeleteReject: false,
      rejectionReason: '',
      selectedFile: [],
      combineImg: [],
      orderDeltilsId: 0,
      orderData: {
        return_placed_at: null,
        return_cancel_at: null,
        return_confirmed_at: null,
        return_reject_at: null,
        return_pick_at: null,
        returned_assign_at: null,
        return_at: null,
        order_number: "",
        placed_at: "",
        reason: "",
        refunded_cancel_at: null,
        reason_refunded_cancel: null,
        refunded_at: null,
        otp: null,
        longitude: 0,
        latitude: 0,
        estimated_delivery_time: null,
      },
      orderNumber: "",
      placetAt: "",
      driverName: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllOrderCallId !== null &&
      this.getAllOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ metaData: responseJsonCategory?.meta, isAlert: responseJsonCategory.data === undefined ? true : false, tableAllOrder: responseJsonCategory?.data, isLoading: false ,isLoading1:false})
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRejectOrderCallId !== null &&
      this.getRejectOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory) {
        this.setState({ isLoading: true })
        this.props.apiRecall()
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeOrderCallId !== null &&
      this.getTimeOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory) {
        this.setState({ isLoading: true })
        this.props.apiRecall()
      }
    }
    this.allOrderAcceoted(message)
    this.orderDetailsData(message)
    this.trackOrderDetilse(message)
    this.rejectReturnOrder(message)
    this.returnSellerAccept(message)
    // Customizable Area End
  }

  // Customizable Area Start
  returnSellerAccept = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSellerAcceptApiCallId !== null &&
      this.getSellerAcceptApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory) {
        this.setState({ isLoading: true, rejectOpenReturn: false })
        this.props.apiRecall()
      }
    }
  }
  rejectReturnOrder = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRejectReturnOrderCallId !== null &&
      this.getRejectReturnOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory) {
        this.setState({ isLoading: true })
        this.props.apiRecall()
      }
    }
  }

  trackOrderDetilse = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTRackOrderApiCallId !== null &&
      this.getTRackOrderApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonAllOrderData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ orderData: responseJsonAllOrderData , latitudeData: { lat: responseJsonAllOrderData.latitude, lng: responseJsonAllOrderData.longitude, },
        longitudeData: { lat: responseJsonAllOrderData.latitude, lng: responseJsonAllOrderData.longitude }})
    }
  }
  orderDetailsData = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeAllOrderCallId !== null &&
      this.getTimeAllOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonAllOrderData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonAllOrderData.error) {
        this.setState({ isLoading: false, visibleAllOrder: false, isAlertOrder: true, alertType: 'error', already: responseJsonAllOrderData.error === "Payment failed. Please check your details and try again or use a different payment method." ? responseJsonAllOrderData.error : responseJsonAllOrderData.error[0].message, isOpenDelete: false })
      } else {
        this.setState({ isLoading: false, visibleAllOrder: false, isOpenDelete: false }, () => this.allOrderData())
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeRefundOrderCallId !== null &&
      this.getTimeRefundOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonAllOrderData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonAllOrderData.data) {
        this.setState({ isLoading: false, visibleAllOrder: false, isOpenDeleteReject: false, alertType: 'success', already: this.transSellerAllOrder("Return Reject Successfully.") }, () => this.allOrderData())
      } else {
        this.setState({ isLoading: false, visibleAllOrder: false, isAlertOrder: true, isOpenDeleteReject: false })
      }
    }
  }
  allOrderAcceoted = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeInProcessOrderCallId !== null &&
      this.getTimeInProcessOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonInProcess = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonInProcess) {
        this.setState({ already: this.transSellerAllOrder("Order Accepted"), isAlertOrder: true,alertType:"success" })
        this.props.apiRecall()
        setTimeout(() => {
          this.allOrderData()
        }, 3000)
      }
    }
  }
  handleCancel = () => {
    this.setState({ visible: false });
  };

  incrementTime = () => {
    this.setState((prevState) => ({ timerAllOrders: prevState.timerAllOrders + 5 }));
  };

  decrementTime = () => {
    this.setState((prevState) => ({ timerAllOrders: prevState.timerAllOrders - 5 }));
  };
  handleOk = (confirmid: number) => {
    this.setState({ visible: true, confirmId: confirmid });
  };
  handlenumberDataOrderChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberDataOrder: event.target.value as string });
  };
  handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberData: event.target.value as string });
  };
  handleOpen1 = (rejectid: number) => {
    this.setState({ rejectOpen: true, rejectId: rejectid })
  };

  handleClose1 = () => {
    this.setState({ rejectOpen: false })
  };
  dateFormetTime = (created_at: string) => {
    if (!created_at) return "";
    const date = new Date(created_at);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? this.transSellerAllOrder('PM') : this.transSellerAllOrder('AM');
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  }
  async componentDidMount() {
    this.allOrderData()
  }
  componentDidUpdate(prevProps: { searchAllOrder: string; }) {
    if (prevProps.searchAllOrder !== this.props.searchAllOrder) {
      this.setState({isLoading1:true},()=>this.allOrderData())
    }
  }

  rejectData = () => {
    this.setState({ rejectOpen: false })
    this.rejectOrderData()
  }
  timeData = () => {
    this.setState({ visible: false })
    this.timeOrderData()
  }
  allOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAllOrder}&page=${this.state.page}&per_page=10&search=${this.props.searchAllOrder}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  rejectOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.rejectId}&type=reject&reason_of_rejection=${this.state.numberData}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  formatTime = (seconds: number) => {
    const minutesAllOrders = Math.floor(seconds / 60);
    const secsAllOrders = seconds % 60;
    return `${minutesAllOrders.toString().padStart(2, '0')}:${secsAllOrders.toString().padStart(2, '0')}`;
  };

  async componentWillUnmount() {
    if (this.timerAllOrdersId) {
      clearInterval(this.timerAllOrdersId);
    }
  }

  timeOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.confirmId}&type=accept&accept_order_upload_time=${this.state.timerAllOrders}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  handleInProcess = (orderIdData: number | string) => {
    this.setState({ visibleAllOrder: true, orderIdNumber: orderIdData });
  };
  handleCancelInProcess = () => {
    this.setState({ visibleAllOrder: false });
  };
  timeDataInProcess = () => {
    this.timeOrderDataInProcess()
    this.setState({ visibleAllOrder: false, isLoading: true })
    this.allOrderData()
  }
  timeOrderDataInProcess = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListInProcessOrderTime = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeInProcessOrderCallId = requestMessageListInProcessOrderTime.messageId;
    requestMessageListInProcessOrderTime.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListInProcessOrderTime.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.orderIdNumber}&type=ready_to_ship`
    );
    requestMessageListInProcessOrderTime.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListInProcessOrderTime.id, requestMessageListInProcessOrderTime);
  }
  oncloseAlertInProcess = () => {
    this.setState({ isAlertOrder: false });
  };
  navigateTo = ({
    productId,
    props,
    screenName,
  }: INavigateToInProcess) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
  }

  handleOpenDeleteModal = (orderId: string | number) => {
    const orderDatalsAllDetails = this.state.tableAllOrder.find((order: { id: number | string }) => {
      return order.id == orderId;
    });
    const rejectMrp = Number(orderDatalsAllDetails?.attributes.order_items[0].attributes?.total_price).toFixed(0)
    this.setState({ isOpenDelete: true, orderDeltilsId: orderId, rejectMrpData: rejectMrp ,});
  };

  handleCloseDeleteModal = () => {
    this.setState({ isOpenDelete: false });
  };

  handleOpenRejectDeleteModal = (orderId: string | number) => {
    this.setState({ isOpenDeleteReject: true, orderIdReject: orderId });
  };

  handleCloseRejectDeleteModal = () => {
    this.setState({ isOpenDeleteReject: false });
  };

  handleRemove = () => {
    this.setState({ selectedFile: [], combineImg: [] });
  };

  openPictureUpload = (index: number) => {
    const inputAddPensionTwo = document.createElement('input');
    inputAddPensionTwo.type = 'file';
    inputAddPensionTwo.accept = 'image/png, image/jpeg, image/jpg';
    inputAddPensionTwo.multiple = false;
    inputAddPensionTwo.click();
    inputAddPensionTwo.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
      this.openProfileUploadInSideMain(event, index);
    };
  }
  openProfileUploadInSideMain = async (event: React.ChangeEvent<HTMLInputElement> | Event, index: number) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];
    const base64 = URL.createObjectURL(file);
    this.setState({
      combineImg: [base64],
      selectedFile: [file],
    });
  }

  timeOrderDataReject = async () => {
    const formData = new FormData()
    formData.append("reason_of_refund_rejected", this.state.numberDataOrder);
    this.state.selectedFile.forEach((itemsImg: string | Blob | null) => {
      itemsImg && formData.append("image", itemsImg);
    })
    const token = await getStorageData("auth-token");
    const header = {
      token: token,
    };
    const requestMessageListAllProcess = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeRefundOrderCallId = requestMessageListAllProcess.messageId;
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=refund_rejected&seller_order_id=${this.state.orderIdReject}`
    );
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageListAllProcess.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageListAllProcess.id, requestMessageListAllProcess);
  }

  handleConfirmDetalis = async (refundAmo: string | number) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListAllProcess = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeAllOrderCallId = requestMessageListAllProcess.messageId;
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=initiate_refund&seller_order_id=${this.state.orderDeltilsId}&refund_amount=${refundAmo}`
    );
    requestMessageListAllProcess.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListAllProcess.id, requestMessageListAllProcess);
  };

  toggleStatusDrawer = (open: boolean, orderIdNumber?: number | string) => (event: React.KeyboardEvent | React.MouseEvent) => {
    const data = this.state.tableAllOrder && this.state.tableAllOrder?.find((index) => Number(index.id) === orderIdNumber);
    this.setState({
      toggleStatusDataId: orderIdNumber,
      orderNumber: data?.attributes.order_management_order.attributes.order_number,
      isDrawerStatusOpen: open,
      driverName: data?.attributes.order_items[0].attributes.driver_name,
      placetAt: moment(data?.attributes.order_management_order.attributes.placed_at).format("DD MMM, YYYY"),
    }, () => {this.getTrackOrderDetailApi(orderIdNumber)});
  };

  getTrackOrderDetailApi = async (orderIdNumber?: ToggleStatusId) => {
    const getToken = await getStorageData("auth-token")
    const headers = {
      "token": getToken
    };
    const requestMessageTrack = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTRackOrderApiCallId = requestMessageTrack.messageId;
    requestMessageTrack.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersManagementTreack}=${orderIdNumber}`
    );
    requestMessageTrack.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageTrack.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessageTrack.id, requestMessageTrack);
    return true;
  }

  toggleStatusData = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    this.setState({
      isDrawerStatusOpen: false,
      isDrawerStatus: open,
    });
  };

  handleOpenReject = (orderIdReturn: number | string) => {
    this.setState({ rejectOpenReturn: true, orderIdReturnId: orderIdReturn })
  };
  handleCancelReturn = () => {
    this.setState({ rejectOpenReturn: false })
  }

  acceptRequest = async (sellerId: string | number) => {
    const token = await getStorageData("auth-token");
    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerAcceptApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=return_accept&seller_order_id=${sellerId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCancelvisibleReturn = () => {
    this.setState({ visibleReturn: false })
  }
  handleOpenvisibleReturn = (orderIdReturn: number | string) => {
    this.setState({ visibleReturn: true, orderIdReturnId: orderIdReturn })
  };

  handleSingleChangeRetun = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ descriptionReson: event.target.value as string });
  };

  handleSingleChangeNumberRetrun = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberDataRetun: event.target.value as string });
  };

  rejectDataRetrun = () => {
    this.setState({ visibleReturn: false })
    this.rejectOrderDataReturn()
  }

  rejectOrderDataReturn = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectReturnOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?seller_order_id=${this.state.orderIdReturnId}&type=return_reject&reason_of_rejection=${this.state.numberDataRetun}&rejection_detail=${this.state.descriptionReson}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  transSellerAllOrder = (transKey: string) => {
    return i18n.t( transKey, {ns: "orderDetail"})
  }
  // Customizable Area End
}
