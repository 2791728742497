export const enContactUs = {
    "Home": "Home",
    "Contact Us": "Contact Us",
    "Admin-Related Concerns": "Admin-Related Concerns",
    "Full Name": "Full Name",
    "Email Address": "Email Address",
    "Subject": "Subject",
    "Description": "Description",
    "Submit": "Submit",
    "*Full Name is required":"*Full Name is required",
    "*Email is required":"*Email is required",
    "*Subject is required":"*Subject is required",
    "*Description is required":"*Description is required",
    "*Email address is invalid":"*Email address is invalid",
    "Contact request submitted successfully.":"Contact request submitted successfully.",
    "Failed to submit contact request.":"Failed to submit contact request.",
    "Contact Support": "Contact Support",
    "Help Center":"Help Center",
    "Please get in touch and we will be happy to help you.":"Please get in touch and we will be happy to help you.",
    "More Queries related to  your experience":"More Queries related to  your experience",
    "Trending Articles":"Trending Articles",
    "Tap to view most helpful articles":"Tap to view most helpful articles",
    "Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.":"Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "Name":"Name",
    "Enter Name":"Enter Name",
    "Email":"Email",
    "Enter Email":"Enter Email",
    "Enter Subject":"Enter Subject",
    "Enter Description":"Enter Description",
    "Close":"Close",
    "Add": "Add",
    "Others":"Others",
    "Refund or payment complete":"Refund or payment complete",
    "Offers, Discounts, Coupons":"Offers, Discounts, Coupons",
    "If someone refunds your money, they return it to you, for example because you have too much or because you have returned goods to the shop.":"If someone refunds your money, they return it to you, for example because you have too much or because you have returned goods to the shop.",
    "A limited-time offer is any type of special deals such as free ship shipping, discount coupon, free gift, or reward which is available for a certain time period.":"A limited-time offer is any type of special deals such as free ship shipping, discount coupon, free gift, or reward which is available for a certain time period.",
    "A return policy indicates rules and conditions about if, when, and for how long your customers can return a purchase and receive reimburesement.":"A return policy indicates rules and conditions about if, when, and for how long your customers can return a purchase and receive reimburesement.",
    "Get Help": "Get Help",
    "FAQs": "FAQs",
    "Send":"Send"
}

export const arContactUs = {
    "Home": "الصفحة الرئيسية",
    "Contact Us": "تواصل معنا",
    "Admin-Related Concerns": "الأمور المتعلقة بالإدارة",
    "Full Name": "الاسم الكامل",
    "Email Address": "عنوان البريد الإلكتروني",
    "Subject": "الموضوع",
    "Description": "الوصف",
    "Submit": "إرسال",
    "*Full Name is required":"*الاسم الكامل مطلوب",
    "*Email is required":"*البريد الإلكتروني مطلوب",
    "*Subject is required":"*الموضوع مطلوب",
    "*Description is required":"*الوصف مطلوب",
    "*Email address is invalid":"*عنوان البريد الإلكتروني غير صالح",
    "Contact request submitted successfully.":"تم إرسال طلب الاتصال بنجاح.",
    "Failed to submit contact request.":"فشل في تقديم طلب الاتصال.",
    "Contact Support": "اتصل بالدعم",
    "Help Center":"مركز المساعدة",
    "Please get in touch and we will be happy to help you.":"من فضلك تواصل معنا وسنكون سعداء بمساعدتك.",
    "More Queries related to  your experience":"المزيد من الاستفسارات المتعلقة بتجربتك",
    "Trending Articles":"تتجه المقالات",
    "Tap to view most helpful articles":"انقر لعرض المقالات الأكثر فائدة",
    "Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.":"لقد كان لوريم إيبسوم هو النص الوهمي القياسي في الصناعة منذ القرن السادس عشر، عندما أخذت طابعة غير معروفة لوحًا من النوع وخلطته لصنع نموذج كتاب.",
    "Name":"اسم",
    "Enter Name":"أدخل الاسم",
    "Email":"بريد إلكتروني",
    "Enter Email":"أدخل البريد الإلكتروني",
    "Enter Subject":"أدخل الموضوع",
    "Enter Description":"أدخل الوصف",
    "Close":"يغلق",
    "Add":"يضيف",
    "Others":"آحرون",
    "Refund or payment complete":"اكتمل الاسترداد أو الدفع",
    "Offers, Discounts, Coupons":"عروض، خصومات، كوبونات",
    "If someone refunds your money, they return it to you, for example because you have too much or because you have returned goods to the shop.":"إذا قام شخص ما بإعادة أموالك، فإنه يعيدها إليك، على سبيل المثال لأنه لديك الكثير أو لأنك قمت بإرجاع البضائع إلى المتجر.",
    "A limited-time offer is any type of special deals such as free ship shipping, discount coupon, free gift, or reward which is available for a certain time period.":"العرض لفترة محدودة هو أي نوع من العروض الخاصة مثل الشحن المجاني أو قسيمة الخصم أو الهدية المجانية أو المكافأة المتوفرة لفترة زمنية معينة.",
    "A return policy indicates rules and conditions about if, when, and for how long your customers can return a purchase and receive reimburesement.":"تشير سياسة الإرجاع إلى القواعد والشروط المتعلقة بما إذا كان بإمكان عملائك إرجاع الشراء والحصول على تعويض، ومتى، وإلى متى.",
    "Get Help": "احصل على المساعدة",
    "FAQs": "الأسئلة الشائعة",
    "Send":"يرسل"
}