// Customizable Area Start
import React from "react";
import { Box, styled, Grid, Switch } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
// Customizable Area End

// Customizable Area Start
import NotificationsettingsController, {
  Props,
  configJSON
} from "./NotificationsettingsController";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
// Customizable Area End



export default class NotificationSettingsWeb extends NotificationsettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 

  // Customizable Area Start
  notificatonHeader = () => {
    const { roleData } = this.state;
    switch (roleData) {
      case "seller":
        return <SellerHeader navigation={this.props.navigation} data-testid="sellerRejectedHeader" />;
      case "stylist":
        return <StylishHeader navigation={this.props.navigation} data-testid="sellerRejectedHeader" />;
      default:
        return <></>;
    }
  };
  notificatonSidebar = () => {
    const { roleData } = this.state;
    switch (roleData) {
      case "seller":
        return <SellerSidebar className="sellerRejectedHeader" navigation={this.props.navigation}/>;
      case "stylist":
        return <StylishSidebar className="sellerRejectedHeader" navigation={this.props.navigation}/>;
      default:
        return <></>;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const getListOfSettings = (notification: { order_invoices: boolean; order_confirmations: boolean; delivery_confirmation: boolean; reviews_and_feedback_requests: boolean; refund_or_payment_complete: boolean; marketing_emails: boolean; product_stock_updates: boolean; },type:string) => {
      return (
        <div>
          <CustomBox>
            <h3 style={webStyle.btnHeder}>Order invoice</h3>
            <div style={webStyle.btnStyle}>
              {type==="push_notification"?(<CustomSwitch checked={this.state.orderInvoicePush} data-test-id="orderInvoicePush" onClick={()=>this.putNotificationSettingUpdate(type,"order_invoices",!this.state.orderInvoicePush)} {...label} />):(
                <CustomSwitch checked={this.state.orderInvoice} data-test-id="orderInvoice" onClick={()=>this.putNotificationSettingUpdate(type,"order_invoices",!this.state.orderInvoice)} {...label} />
              )}
            </div>
          </CustomBox>
          <hr style={webStyle.hrTag} />
          <CustomBox>
            <h3 style={webStyle.btnHeder}>Order confirmations</h3>
            <div style={webStyle.btnStyle}>
            {type==="push_notification"?(<CustomSwitch checked={this.state.orderConfirmPush} data-test-id="orderConfirmPush" onClick={()=>this.putNotificationSettingUpdate(type,"order_confirmations",!this.state.orderConfirmPush)} {...label} />):(
                <CustomSwitch checked={this.state.orderConfirm} data-test-id="orderConfirm" onClick={()=>this.putNotificationSettingUpdate(type,"order_confirmations",!this.state.orderConfirm)} {...label} />
              )}
              
            </div>
          </CustomBox>
          <hr style={webStyle.hrTag} />
          <CustomBox>
            <h3 style={webStyle.btnHeder}>Delivery confirmation</h3>
            <div style={webStyle.btnStyle}>
            {type==="push_notification"?(<CustomSwitch checked={this.state.deliveryPush} data-test-id="orderDeliverPush" onClick={()=>this.putNotificationSettingUpdate(type,"delivery_confirmation",!this.state.deliveryPush)} {...label} />):(
                <CustomSwitch checked={this.state.delivery} data-test-id="orderDeliver" onClick={()=>this.putNotificationSettingUpdate(type,"delivery_confirmation",!this.state.delivery)} {...label} />
              )}
              
            </div>
          </CustomBox>
          <hr style={webStyle.hrTag} />
          <CustomBox>
            <h3 style={webStyle.btnHeder}>Reviews and feedback requests </h3>
            <div style={webStyle.btnStyle}>
            {type==="push_notification"?( <CustomSwitch  checked={this.state.reviewPush} data-test-id="orderReviewPush" onClick={()=>this.putNotificationSettingUpdate(type,"reviews_and_feedback_requests",!this.state.reviewPush)} {...label} />):(
                 <CustomSwitch  checked={this.state.review} data-test-id="orderReview" onClick={()=>this.putNotificationSettingUpdate(type,"reviews_and_feedback_requests",!this.state.review)} {...label} />
              )}
             
            </div>
          </CustomBox>
          <hr style={webStyle.hrTag} />
          <CustomBox>
            <h3 style={webStyle.btnHeder}>Refund or payment complete</h3>
            <div style={webStyle.btnStyle}>
            {type==="push_notification"?(<CustomSwitch checked={this.state.refundPush} data-test-id="orderRefundPush" onClick={()=>this.putNotificationSettingUpdate(type,"refund_or_payment_complete",!this.state.refundPush)} {...label} />):(
                 <CustomSwitch checked={this.state.refund} data-test-id="orderRefund" onClick={()=>this.putNotificationSettingUpdate(type,"refund_or_payment_complete",!this.state.refund)} {...label} />
              )}
              
            </div>
          </CustomBox>
          <hr style={webStyle.hrTag} />
    
          <CustomBox>
            <h3 style={webStyle.btnHeder}>Product stock updates</h3>
            <div style={webStyle.btnStyle}>
            {type==="push_notification"?(<CustomSwitch checked={this.state.productPush} data-test-id="productPush" onClick={()=>this.putNotificationSettingUpdate(type,"product_stock_updates",!this.state.productPush)} {...label} />):(
                 <CustomSwitch checked={this.state.product}  data-test-id="product" onClick={()=>this.putNotificationSettingUpdate(type,"product_stock_updates",!this.state.product)} {...label} />
              )}
            </div>
          </CustomBox>
          <hr style={webStyle.hrTag} />
          <CustomBox>
            <h3 style={webStyle.btnHeder}>Marketing emails</h3>
            <div style={webStyle.btnStyle}>
            {type==="push_notification"?(<CustomSwitch checked={this.state.marketPush} data-test-id="marketPush" onClick={()=>this.putNotificationSettingUpdate(type,"marketing_emails",!this.state.marketPush)} {...label} />):(
                 <CustomSwitch checked={this.state.market} data-test-id="market" onClick={()=>this.putNotificationSettingUpdate(type,"marketing_emails",!this.state.market)} {...label} />
              )}
              
            </div>
          </CustomBox>
        </div>
      );
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xl={2} md={2} lg={2} sm={2} xs={2}>
            {this.notificatonSidebar()}
          </Grid>
          <Grid item xl={10} md={10} lg={10} sm={10} xs={10}>
            {this.notificatonHeader()}
            <div style={webStyle.mainDiv}>
              <div style={webStyle.headerDiv}>
                <h1 style={webStyle.titleNotification}>
                  {configJSON.titleMessage}
                </h1>
              </div>
              <br />
              <div style={webStyle.headerPushDiv}>
                <h1 style={webStyle.titlepushNotification}>
                  {configJSON.titleMessagePush}
                </h1>
                {getListOfSettings(this.state.notificationList?.notification?.push_notification,"push_notification")}
              </div>
              <br />
              <div style={{ marginTop: 10 }} />
              <div style={webStyle.headerPushDiv}>
                <h1 style={webStyle.titlepushNotification}>
                  {configJSON.titleMessageEmail}
                </h1>
                {getListOfSettings(this.state.notificationList?.notification?.email_notification,"email_notification")}
              </div>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
const label = { inputProps: { "aria-label": "Switch demo" } };
const webStyle = {
  headerDiv: {
    display: "flex",
    marginBottom: "-5px"
  },
  hrTag: {
    color: "#E2E8F0"
  },
  titleNotification: {
    color: "#375280",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  titlepushNotification: {
    color: "#375280",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  btnHeder: {
    color: "#375280",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  headerPushDiv: {
    border: "1px solid #E2E8F0",
    paddingLeft: "22px",
    paddingTop: "8px",
    paddingRight: "22px",
    borderRadius: "6px"
  },
  btnStyle: {
    marginLeft: "auto"
  },
  mainDiv: {
    margin: "30px",
    marginTop: "20px"
  }
};
const CustomBox = styled(Box)({
  display: "flex",
  flexWrap: "wrap"
});
const CustomSwitch = styled(Switch)({
  width: "70px !important",
  "& .MuiSwitch-thumb": {
    margin: "5px !important",
    color: "#FFFFFF !important"
  },
  "& .MuiSwitch-track": {
    height: "24px !important",
    borderRadius: "40px !important",
    backgroundColor: "#E2E8F0 !important",
    opacity: 1
  },
  // '& .Mui-checked':{
  //   backgroundColor:'#375280 !important',
  //   opacity:1
  // },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "primary.main",
    "& + .MuiSwitch-track": {
      backgroundColor: "#375280 !important",
      opacity: 1
    }
  }
});
// Customizable Area End
