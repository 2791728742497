import React from "react";
// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Grid, Dialog, Avatar, styled, Box,Snackbar, Typography, Divider, Tabs, Tab, Card, CardMedia, CardContent, IconButton, TextField, Button } from "@material-ui/core";
import HiredStylistController, {
    Props,
    ImagesEntity,
    IHiredStylist,
    IProductSourcing, 
    IStylistList,
} from "./HiredStylistController.web";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { message as messageIcon, search ,Noimageavailable} from "./assets"
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import PersonIcon from '@material-ui/icons/Person';
import i18n from "../../../components/src/i18next/i18n";
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

export default class HiredStylist extends HiredStylistController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderImageRows() {
        const { visibleCount, explorePortfolioList } = this.state;
        const rows: JSX.Element[] = [];
        let index = 0;
        while (index < visibleCount && index < explorePortfolioList.length) {
            if (index + 7 <= explorePortfolioList.length) {
                rows.push(this.generateRow(index, index + 1, index + 7));  
                index += 7;  
            } else if (index < explorePortfolioList.length) {
                rows.push(this.generateRow(index, index + 1, index + 7)); 
                index = explorePortfolioList.length;
            }
    
            if (index + 5 <= explorePortfolioList.length) {
                rows.push(this.reverseGenerateRow(index)); 
                index += 5;  
            } else if (index < explorePortfolioList.length) {
                rows.push(this.reverseGenerateRow(index));  
                index = explorePortfolioList.length;
            }
        }
        return rows;
    }
    

    generateRow = (largeIndex: number, smallStartIndex: number, smallLastIndex: number) => {
        const { explorePortfolioList } = this.state;
        const largeImage = explorePortfolioList[largeIndex];
        const lastSmallImgIndex = Math.min(explorePortfolioList.length, smallLastIndex)
        return (
            <Grid container spacing={2} key={`row-${largeIndex}`}>
                <Grid item xs={12} sm={6}>
                    <img src={explorePortfolioList[largeIndex]?.url} alt={explorePortfolioList[largeIndex]?.description} className="largeImage" 
                    onClick={() => this.hireStylistImg(largeImage && largeImage.url, largeImage.description)} data-testid="hireStylistImg"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                        {explorePortfolioList?.slice(smallStartIndex, lastSmallImgIndex).map((item:ImagesEntity, index:number) => (
                            <Grid item xs={6} sm={4} key={`small-${index}`}>
                                <img src={item.url} alt={item.description} className="smallImage" data-testid="hireStylistImg2" onClick={() => this.hireStylistImg(item.url, item.description)}/>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    
    reverseGenerateRow = (startIndex: number) => {
        const { explorePortfolioList } = this.state;
        const totalRemaining = this.state.explorePortfolioList.length - startIndex;
        const smallImageCount = Math.min(totalRemaining, 4);  
    
        return (
            <Grid container spacing={2} key={`reverse-row-${startIndex}`}>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                        {explorePortfolioList?.slice(startIndex, startIndex + smallImageCount).map((item:ImagesEntity, index:number) => (
                                <Grid item xs={6} sm={6} key={`reverse-small-${index}`}>
                                    <img src={item?.url} alt={item?.description} className="smallImage" data-testid="hireStylistImg3" onClick={() => this.hireStylistImg(item.url, item.description)}/>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
    
                {totalRemaining >= 5 && (
                    <Grid item xs={12} sm={6}>
                        <img src={explorePortfolioList[startIndex + 4]?.url} alt={explorePortfolioList[startIndex + 4]?.description} className="largeImage"
                        data-testid="hireStylistImg4" onClick={() => this.hireStylistImg(explorePortfolioList[startIndex + 4].url, explorePortfolioList[startIndex + 4].description)} />
                    </Grid>
                )}
            </Grid>
        )
    };

    openStylistPortfolioDialog = () => (
        this.state.hiredStylistList && this.state.hiredStylistList.length > 0 && this.state.hiredStylistList
            .filter(stylist => stylist.id === this.state.stylistId)
            .map((stylist, index) => (
                <DialogStylistPortfolio
                dir={i18n.dir()}
                    key={index}
                    onClose={this.closeStylistPortfolio}
                    open={this.state.openStylistPortfolio}
                    className="dialog"
                    data-testid="dialog"
                >
                    <CloseOutlinedIcon className="closeIcon" onClick={this.closeStylistPortfolio} data-testid="closeIcon"/>
                    <StylistRow>
                        <div className="imageWrapper">
                        {stylist.attributes.profile_picture ? (
                            <img src={`${baseURL}${stylist.attributes.profile_picture}`} alt="img" />
                        ) : (
                            <Avatar>
                                {stylist.attributes.full_name ? stylist.attributes.full_name.charAt(0) : 'S'}
                            </Avatar>
                        )}
                            <div className={i18n.dir() === "ltr" ? "nameInfo" : "nameInfo2"}>
                                <Typography className="stylistName">{stylist.attributes.full_name}</Typography>
                                <Typography className="stylistPosition">{!stylist.attributes.is_hired ? "Available to Hire" : this.tranHiredStylist("Hired")}</Typography>
                            </div>
                        </div>
                        <div>
                            <IconButton data-testid="favBtnHired" onClick={(event) => this.toggleFavoriteHiredStylist(stylist.id, event)} className="favBtn">
                                {this.isFavoriteData(stylist)}
                            </IconButton>
                            <Button variant="contained" className="sourceButton" data-testid="sourceButton" style={{ marginBottom: 2, backgroundColor: '#c6b4a6' }} 
                            onClick={() => this.hiredStylistProfile({ productId: stylist.id, props: this.props, screenName: "HiredStylistProfile" })}
                            >
                                {this.tranHiredStylist("Stylist's Profile")}
                            </Button>
                        </div>
                    </StylistRow>
                    <Typography variant="h5" component="h2" className="title">
                    {this.tranHiredStylist("Explore Portfolios")}
                    </Typography>
                    <Typography variant="body1" className="description">
                       {stylist.attributes.bio}
                    </Typography>
                    <img src={stylist.attributes.profile_picture === null ? Noimageavailable :`${baseURL}${stylist.attributes.profile_picture}`} alt="portfolio image" className="portfolioImage" />
                </DialogStylistPortfolio>
            ))
    )
   

    renderExplorePortfolio = () => {
        const { loading, visibleCount } = this.state;
        if (loading) {return <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%"><CircularProgress /></Box>}
        return (
            <>
                <ImageTitle>{this.tranHiredStylist("Explore Portfolio")}</ImageTitle>
                <ImageCollage>
                    {this.state.explorePortfolioList.length ? this.renderImageRows() : <StylistRow><div className="nodata">{this.tranHiredStylist("No data available")}</div></StylistRow>}
                </ImageCollage>
                {(visibleCount < this.state.explorePortfolioList.length) && 
                <Box className="viewMore">
                    <Button variant="outlined" className="viewMoreButton" onClick={this.handleViewMore} data-testid="viewMoreButton">
                    {this.tranHiredStylist("View More")}
                    </Button>
                </Box>
            }
            </>
        )
    }

    renderProductSourcing = () => {
        const { productSourcingList } = this.state;
        return (
            <>
                <ProductSource>
                    <BtnTitle>{this.tranHiredStylist("Product Sourcing")}
                        <Button 
                            variant="contained" 
                            className="sourceButton" 
                            style={{ marginBottom: 2, backgroundColor: '#c6b4a6' }}
                            onClick={() => this.headerNavigation("SourceProduct")} 
                            data-testid="sourceButton"
                        >
                            {this.tranHiredStylist("Source a Product")}
                        </Button>
                    </BtnTitle>
                    {productSourcingList.length ? productSourcingList.map((item: IProductSourcing) => {
                        return  <ProductCard className="card" key={item.attributes.id}>
                            <Box display="flex" flexGrow={1}>
                                <img
                                    src={item?.attributes?.images?.[0]?.url}
                                    alt={item.attributes.product_name}
                                    className="image"
                                />
                                <CardContent className="content">
                                    <Typography component="div" variant="h6" className="title">
                                        {item.attributes.product_name}
                                    </Typography>
                                    <Typography variant="subtitle1" className="quotes" component="div">
                                    {this.tranHiredStylist("Quotes")} - {item.attributes?.product_sourcing_stylist_prices?.length}
                                    </Typography>
                                    <Typography component="div" variant="h6" className="fees">
                                        {"$" + item.attributes.min_price +" - "+ "$" +item.attributes.max_price}
                                    </Typography>
                                </CardContent>
                                <Box className="actions">
                                    <Button variant="outlined" className={i18n.dir() === "ltr" ? "deleteBtn" : "deleteBtn2"} data-testid="deleteBtn" color="secondary" onClick={() => this.deleteProductSource(item.id)}>
                                    {this.tranHiredStylist("Delete")}
                                    </Button>
                                    <Button variant="contained" className="viewButton" data-testid="viewButton" onClick={() => this.handleProductView("StylistQuote", item.id)}>
                                    {this.tranHiredStylist("View")}
                                    </Button>
                                </Box>
                            </Box>
                        </ProductCard>
                    }) : <StylistRow><div className="nodata">{this.tranHiredStylist("There are no bids on your product quote yet")}</div></StylistRow>}
                </ProductSource>
            </>
        )
    }

    profilePictureData = (item: IStylistList) => {
        return item.attributes.profile_picture ? <CardMedia component="img" height="200" image={item.attributes.profile_picture} alt={item.attributes.full_name} className="cardMedia" /> : <Box display="flex" justifyContent="center" alignItems="center" height="200" bgcolor={"#fff"} ><PersonIcon style={{ fontSize: 80, color: "#999999" }} /></Box>
    }
    isFavoriteData = (item:IStylistList | IHiredStylist) => {
        return item.attributes?.is_favorite ? <FavoriteIcon fill="#FF0000" /> : <FavoriteBorderIcon fill="#375280" />
    }
    renderStylistUI = () => {
        const { searchTerm, filteredList ,loadingStylist} = this.state;
        return (
            <Box pt={3}>
                <TextField
                    variant="outlined"
                    placeholder={this.tranHiredStylist("Search Stylist")}
                    fullWidth
                    InputProps={{ startAdornment: <img src={search} className="searchIcon" /> }}
                    className="searchBox"
                    inputProps={{ "data-test-id": "Search Stylist" }}
                    value={searchTerm}
                    data-testid="Search Stylist"
                    onChange={this.handleSearch}
                />
                <Box  >
                    {loadingStylist && <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%"><CircularProgress /></Box>}
                    <Grid container spacing={3} style={{
                        maxHeight: "680px",
                        overflowY: "scroll"
                    }} data-test-id="handleScroll" id="scrollableDiv">
                        {filteredList.length === 0 && !loadingStylist ? <StylistRow>
                            <div className="nodata">{this.tranHiredStylist("No data available")}</div>
                        </StylistRow> : filteredList.slice(0, this.state.visibleCountStylist).map((item: IStylistList) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                                <StylishCard onClick={() => this.hiredStylistProfile({ productId: item.id, props: this.props, screenName: "HiredStylistProfile" })} data-testid="getRequestedStylistProfile">
                                    {this.profilePictureData(item)}
                                    <CardContent className="cardContent">
                                        <Box className="content">
                                            <Typography variant="h6" component="div" className="title">
                                                {item.attributes.full_name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" className="description">
                                                {item.attributes.bio}
                                            </Typography>
                                        </Box>
                                        <IconButton className="favBtn" data-testid="favBtn" onClick={(event) => this.toggleFavorite(item.id, event)}>
                                            {this.isFavoriteData(item)}
                                        </IconButton>
                                    </CardContent>
                                </StylishCard>
                            </Grid>
                        ))}
                    </Grid>
                    {(this.state.visibleCountStylist < this.state.filteredList.length) &&
                        <Box className="viewMore">
                            <Button variant="outlined" className="viewMoreButton" onClick={this.handleViewMoreStylist} data-testid="handleViewMoreStylist">
                                {this.tranHiredStylist("View More")}
                            </Button>
                        </Box>
                    }
                </Box>
            </Box>
        )
    }

    handleStatusColor = (status: string) =>{
        switch(status){
            case "Accepted":
                return "#059669";
            case "Rejected":
                return "#DC2626";
            default: 
                return "#FF9800"
        }
    }

    renderRequestedStylist = () => {
        const { loading, requestedStylistList } = this.state;
        if (loading) {return <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%"><CircularProgress /></Box>}
      
        return (
            <>
                <ImageTitle>{this.tranHiredStylist("Stylists")}</ImageTitle>
                {requestedStylistList.length ? requestedStylistList.map((item:any) => <RequestedStylist>
                    <StylistRow>
                        <div className="imageWrapper">
                            <img src={item.attributes.images?.[0].url} alt="img" />
                            <div className={i18n.dir() === "ltr" ? "nameInfo" : "nameInfo2"}>
                                <Typography className="stylistName">{item.attributes.stylist_name}</Typography>
                                <Typography className="stylistPosition" style={{ color: this.handleStatusColor(item.attributes.status), fontWeight: 400}}>{item.attributes.status}</Typography>
                            </div>
                        </div>
                    </StylistRow>
                    <Box className="actions">
                        <img src={messageIcon} data-testid="requestedChatView" onClick={() => this.handleProductView("HiredStylistProfile", `chat${item?.attributes.stylist_id}`, "navigationBarTitleText")} style={{ color: '#375280', marginRight: '16px' }} />
                        {item.attributes.status === "Requested" && <><Button variant="outlined" className={i18n.dir() === "ltr" ? "deleteBtn" : "deleteBtn2"}  data-testid="deleteHireBtn" onClick={() => this.deleteHiredStylist(item.id)}>
                        {this.tranHiredStylist("Delete")}
                        </Button>
                        <Button variant="contained" className="viewButton" data-testid="editBtn" onClick={() => this.navigateTo({ productId: item.id, props: this.props, screenName: "HireStylistFormWeb" })}>
                        {this.tranHiredStylist("Edit Request")}
                        </Button></>}
                    </Box>
                </RequestedStylist>) :  <StylistRow><div className="nodata">{this.tranHiredStylist("No data available")}</div></StylistRow>}
            </>
        )
    }

    openPortfolioImg = () => {
        return <StyledDialog
        dir={i18n.dir()}
            onClose={this.hireStylistImgClose}
            open={this.state.isModalOpenImg}
            fullWidth
        >
            <Box className="ContainerBox">
                <img src={this.state.imgStylist} className="imgPortfolio" width="400px" height="350px" />
                <Typography className="StyledTypography">{this.tranHiredStylist("Explore Portfolios")}</Typography>
                <Box className="InnerBox">
                    <Typography className="DescriptionTypography">{this.state.imgDescriptions}</Typography>
                </Box>
            </Box>
        </StyledDialog>
    }

    // Customizable Area End
    render() {
        // Customizable Area Start 
        const { hiredStylistList, selected } = this.state;
        return (
            <>
            <PageWrapper>
                <Header navigation={this.props.navigation} />
                <Grid container dir={i18n.dir()} style={{ maxWidth: "1440px", margin: "40px auto", width: "100%" }}>
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} >
                                <LeftBox>
                                    <ImageTitle>{this.tranHiredStylist("Hired Stylists")}</ImageTitle>
                                    {hiredStylistList?.length ? hiredStylistList.map((item:IHiredStylist) => <><Divider /><StylistRow>
                                        <div className="imageWrapper" onClick={() => this.openStylistPortfolio(item.id)} data-testid="stylistRow">
                                            {item.attributes.profile_picture ? (
                                                <img src={`${baseURL}${item.attributes.profile_picture}`} alt="img" />
                                            ) : (
                                                <Avatar>
                                                    {item.attributes.full_name ? item.attributes.full_name.charAt(0) : 'S'}
                                                </Avatar>
                                            )}
                                            <div className={i18n.dir() === "ltr" ? "nameInfo" : "nameInfo2"}>
                                                <Typography className="stylistName">{item.attributes.full_name}</Typography>
                                                <Typography className="stylistPosition">{this.tranHiredStylist("Hired")}</Typography>
                                            </div>
                                        </div>
                                        <img src={messageIcon} data-testid="hiredChatMsg" onClick={() => this.handleProductView("HiredStylistProfile", `chat${item.id}`, "navigationBarTitleText")} className="messageIcon" />
                                    </StylistRow>
                                  </>) : <><Divider /><StylistRow><div className="nodata">No Stylist Hired</div></StylistRow></>}
                                </LeftBox>
                            </Grid>
                            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                 <WrapperBox>
                                    <CustomTabs value={selected} data-testid="explore" onChange={this.handleChange}>
                                        <CustomTab selected={selected === 0} label={this.tranHiredStylist("Explore Portfolio")} />
                                        <CustomTab selected={selected === 1} label={this.tranHiredStylist("Product Sourcing")} />
                                        <CustomTab selected={selected === 2} label={this.tranHiredStylist("Stylists")} />
                                        <CustomTab selected={selected === 3} label={this.tranHiredStylist("Requested Stylists")} />
                                    </CustomTabs>
                                    {selected === 0 && this.renderExplorePortfolio()}
                                    {selected === 1 && this.renderProductSourcing()}
                                    {selected === 2 && this.renderStylistUI()}
                                    {selected === 3 && this.renderRequestedStylist()}
                                </WrapperBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer navigation={this.props.navigation} />
            </PageWrapper>
            {this.openStylistPortfolioDialog()}
            {this.openPortfolioImg()}
            <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={this.state.isAlertOrder}
                    style={{ marginTop: '10px' }}
                    autoHideDuration={3000}

                    onClose={this.oncloseAlertInProcess}
                    data-testid="oncloseAlert"
                ><Alert variant="filled" severity={this.state.alertType}>{this.state.already}</Alert></Snackbar>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const StyledDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '500px',
    },
    '& .ContainerBox': {
        background: "#fff",
        margin: "auto",
        borderRadius: "2px",
        padding: "45px",
    }, 
    '& .InnerBox': {
        backgroundColor: '#f8f8f8',
    },
    '& .StyledTypography': {
        color: '#375280',
        fontSize: '20px',
        fontFamily: 'Lato',
        fontWeight: 700,
    },
    '& .DescriptionTypography': {
        color: '#94A3B8',
        fontSize: '16px',
        fontFamily: 'Lato',
        fontWeight: 500,
    }, 
    '& .imgPortfolio': {
        objectFit: "fill"
    }
});
const BreadcrumbStyle = styled(Box)({
    margin: "20px",
    "& .pageTitle": {
        display: "flex",
        alignItems: "center",
        margin: "20px 0"
    },
    "& .iconButton": {
        backgroundColor: '#fff',
        borderRadius: '50%',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)', 
        padding: '8px', 
        marginRight: '8px',
    },
    "& .text": {
        fontFamily: 'Lato',
        fontSize: '18px',
        fontWeight: 600, 
        color: '#375280',
        marginLeft: "5px"
    },
    "& .breadcrumb": {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textDecoration: 'none',
        color: '#475569', 
    }
})
const DialogStylistPortfolio = styled(Dialog)({ 
    "& .MuiDialog-paper": {
        padding: "40px 25px 25px", 
        width: "800px"
    }, 
    "& .sourceButton": {
        color: "#fff", 
        textTransform: "none",
        fontSize: "20px", 
        boxShadow: "none", 
        fontWeight: "600", 
        padding: "10px 35px"
    }, 
    "& .title": {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: "#375280",
    }, 
    "& .description": {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        color: '#94A3B8',
        padding: '8px 0px',
    }, 
    "& .portfolioImage": {
        height: "300px", 
        objectFit: "fill",
        marginTop: "20px"
    },
    '& .favBtn svg': {
        fill: "#375280", 
        height: "30px", 
        width: "30px"
   },
   '& .closeIcon': {
        position: 'absolute',
        right: '20px',
        top: '20px',
        height: "16px",
        width: "16px",
        borderRadius: '50%',
        padding: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        cursor: 'pointer'
   },
   "& .imageWrapper": {
        "& img": {
            height: "70px !important",
            width: "70px !important",
            borderRadius: "50% !important",
            border: 'none !important'
        },
        "& .MuiAvatar-root": {
            height: "70px !important",
            width: "70px !important",
            borderRadius: "50% !important",
            border: 'none !important'
        }
    },
    "& .nameInfo": {
        "& .stylistName": {
            fontSize: '20px !important',
        },
        "& .stylistPosition": {
            paddingTop: "0px !important"
        }
    },
    "& .nameInfo2": {
        "& .stylistName": {
            fontSize: '20px !important',
        },
        "& .stylistPosition": {
            paddingTop: "0px !important"
        }
    },
})
const CustomTabs = styled(Tabs)({
    borderRadius: '2px',
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

const WrapperBox = styled(Box)({
    margin: '20px', 
    '@media(min-width: 821px)': {
        margin: '0px',
    },
    "& .viewMore": {
        display: "flex", 
        justifyContent: "center"
    },
    "& .viewMoreButton": {
        borderColor: '#CCBEB1', 
        color: '#375280', 
        textTransform: 'none', 
        fontWeight: 700,
        fontSize: '16px',
        padding: '8px 24px',
        borderRadius: '2px',
        marginTop: '20px',
        cursor: "pointer",
        width: "156px",
        background: "#fff",
        '&:hover': {
          backgroundColor: '#f0f0f0', 
        },
    },
    "& .searchBox": {
        marginBottom: '24px',  
        width: '380px',      
        borderRadius: '2px', 
        background: "white",
        border: '2px solid #CBD5E1', 
        float: "right" , 
        "& fieldset": {
            borderColor: "white", 
            '&:focus': {
                outline: 'none',
                border: 'none',
            }
        }, 
        "& .searchIcon": {
            marginRight: "15px", 
            height: "20px"
        },
        '& .MuiInputBase-input::placeholder': {
            fontFamily: 'Lato',
            fontSize: '16px',
            fontWeight: 400,
            color: '#94A3B8', 
        },
        "& .Mui-focused": {
            borderColor: "white", 
            outline: "none", 
            border: 'none',
        }, 
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white', 
            },
            '&:hover fieldset': {
              borderColor: 'white', 
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
              borderWidth: 0, 
            },
          },
          '& .MuiInputBase-input': {
            '&:focus': {
              outline: 'none',
            },
        },
    },
})

const CustomTab = styled(Tab)({
    height: '40px',
    borderRadius: '2px',
    padding: '11px 20px',
    color: '#375280',
    backgroundColor: '#fff',
    fontWeight: 500,
    fontFamily: "Lato",
    border: "none",
    textTransform: "none",
    marginRight: "8px",
    cursor: "pointer",
    '&:hover': {
        backgroundColor: '#ddd',
        color: '#375280',
    },
    "&.Mui-selected": {
        backgroundColor: '#375280',
        color: '#fff',
        fontWeight: 700,
        '&:hover': {
            backgroundColor: '#375280'
        },
    }
});

const StylistRow = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "18px 0",
    width: "100%",
    "& .imageWrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "-5px",
        "& img": {
            height: "80px",
            width: "80px",
            borderRadius: "50%",
            objectFit: "fill",
            border: '4px solid #375280'
        },
        "& .MuiAvatar-root": {
            height: "80px",
            width: "80px",
            borderRadius: "50%",
            objectFit: "fill",
            border: '4px solid #375280'
        }
    },
    "& .nameInfo": {
        display: "block",
        marginLeft: "20px",
        "& .stylistName": {
            fontFamily: 'Lato',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '28px',
            color: '#375280',
            textTransform:'capitalize'
        },
        "& .stylistPosition": {
            fontFamily: 'Lato',
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
            color: '#059669',
            paddingTop: "10px"
        }
    },
    "& .nameInfo2": {
        display: "block",
        marginRight: "20px",
        "& .stylistName": {
            fontFamily: 'Lato',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '28px',
            color: '#375280',
            textTransform:'capitalize'
        },
        "& .stylistPosition": {
            fontFamily: 'Lato',
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
            color: '#059669',
            paddingTop: "10px"
        }
    },
    "& .messageIcon": {
        fill: "#375280",
        height: "32px",
        width: "32px",
        cursor: "pointer", 
        marginRight: "10px"
    }, 
    "& .nodata": {
        fontFamily: 'Lato',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'center',
        color: "#94A3B8",
        paddingTop: "10px",
        width: "100%"
    }
});

const ImageTitle = styled(Box)({
    fontWeight: 700,
    fontSize: "24px",
    color: "#375280",
    fontFamily: "Lato",
    padding: "25px 0",
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center"
});

const BtnTitle = styled(Box)({
    fontWeight: 700,
    fontSize: "24px",
    color: "#375280",
    fontFamily: "Lato",
    padding: "15px 0",
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center"
});

const LeftBox = styled(Box)({
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    padding: "20px 30px",
    margin: "0 20px"
})

const PageWrapper = styled(Box)({
    background: "#F8F8F8"
})
const ImageCollage = styled(Box)({
    "& .largeImage": {
        width: "100%",
        height: "296px",
        objectFit: "fill",
        padding: "0px 16px 8px 0px", 
    },
    "& .smallImage": {
        width: "100%",
        height: "140px",
        objectFit: "fill",
    },
});

const StylishCard = styled(Card)({
    position: "relative",
    height: "207px",
    "& .favBtn": {
        right: '15px', 
        top: '15px', 
        padding: '5px', 
        position: 'absolute', 
        background: 'white', 
        "& svg": {
            fill:"#375280",
        }
    },
    "& .cardMedia": {
        height: "100%",
        objectFit: "fill"
    },
    "& .cardContent": {
        padding: 0,
    },
    "& .content": {
        fontFamily: 'Lato', 
        color: 'white' , 
        padding: "10px",
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '64px',
        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
        "& .title": {
            fontWeight: 700, 
            fontSize: '16px', 
            lineHeight: '26px', 
            marginBottom: '4px',
        }, 
        "& .description": {
            fontWeight: 400, 
            fontSize: '12px', 
            lineHeight: '14.4px' ,
            color: "white"
        }
    }
})

const RequestedStylist = styled(Box)({
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 45px',
    backgroundColor: '#fff',
    borderRadius: '2px',
    marginBottom: "16px",
    "& .actions": {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '8px',
        width: "50%",
        paddingRight: '16px',
    },
    "& .deleteBtn": {
        fontFamily: "Lato",
        width: "150px", 
        color: "#F87171", 
        border: "1px solid #F87171", 
        padding: "8px",
        fontSize: "16px", 
        textTransform: "none", 
        marginRight: "16px",
        cursor: "pointer"
    },
    "& .deleteBtn2": {
        fontFamily: "Lato",
        width: "150px", 
        color: "#F87171", 
        border: "1px solid #F87171", 
        padding: "8px",
        fontSize: "16px", 
        textTransform: "none", 
        marginLeft: "16px",
        marginRight: "16px",
        cursor: "pointer"
    },
    "& .viewButton": {
        fontFamily: "Lato",
        color: "white",
        backgroundColor: '#c6b4a6',
        width: "150px",
        padding: "8px",
        fontSize: "16px",
        textTransform: "none",
        boxShadow: "none",
        cursor: "pointer",
        '&:hover': {
            boxShadow: "none",
            backgroundColor: '#b39a89',
        },
    },
})
const ProductCard = styled(Card)({
    boxShadow: "none",
    marginBottom: "20px",
    "& .card": {
        display: 'flex',
        marginBottom: '16px',
    },
    "& .image": {
        height: "150px",
        width: "200px", 
        objectFit: "fill"
    },
    "& .media": {
        width: 150,
    },
    "& .content": {
        display: "flex",
        width: "45%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 45px",
        "& .title": {
            fontFamily: 'Lato',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '24px',
            color: '#375280',
            marginBottom: '12px',
            maxWidth: "145px"
        },
        "& .quotes": {
            borderRadius: '2px',
            padding: '6px 10px',
            gap: '6px',
            background: '#E2E8F0',
            fontFamily: 'Lato',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '18px',
            marginBottom: '8px',
            color: '#375280',
        }, 
        "& .fees": {
            fontFamily: 'Lato',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '26px',
            marginBottom: '12px',
            color: '#375280',
        }
    },
    "& .actions": {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '16px',
        paddingBottom: '8px',
        width: "30%"
    },
    "& .deleteBtn": {
        color: "#F87171", 
        border: "1px solid #F87171", 
        width: "45%", 
        padding: "8px",
        textTransform: "none", 
        marginRight: "12px",
        fontSize: "16px", 
        cursor: "pointer", 
        fontFamily: "Lato"
    },
    "& .deleteBtn2": {
        color: "#F87171", 
        border: "1px solid #F87171", 
        width: "45%", 
        padding: "8px",
        textTransform: "none", 
        marginLeft: "12px",
        fontSize: "16px", 
        cursor: "pointer", 
        fontFamily: "Lato"
    },
    "& .viewButton": {
        backgroundColor: '#c6b4a6',
        width: "55%",
        color: "white",
        padding: "8px",
        textTransform: "none",
        boxShadow: "none",
        fontSize: "16px",
        cursor: "pointer",
        fontFamily: "Lato",
        '&:hover': {
            backgroundColor: '#b39a89',
            boxShadow: "none",
        },
    },
})

const ProductSource = styled(Box)({
    "& .sourceButton": {
        marginBottom: '16px',
        backgroundColor: '#c6b4a6',
        color: "white",
        padding: "8px 12px",
        textTransform: "none",
        boxShadow: "none",
        fontWeight: "700", 
        fontSize: "16px",
        cursor: "pointer",
        fontFamily: "Lato",
        '&:hover': {
            backgroundColor: '#b39a89',
            boxShadow: "none",
        },
    },
})

const StylistCardContainer = styled(Card)({
    borderRadius: '0px',
    padding: '0px',
    boxShadow: "none",
    '& .avatar-box': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px',
    },
    '& .avatar': {
      width: 56,
      height: 56,
      marginRight: '12px',
    },
    '& .name-text': {
      fontWeight: 600,
      textTransform:'capitalize'
    },
    '& .available-text': {
      color: '#059669',
      fontSize: '14px',
    },
    '& .description': {
      marginTop: '16px',
      color: '#6B7280',
      fontFamily: "Lato"
    },
    '& .action-box': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 0 0',

    },
    '& .action-button': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: "column", 
      justifyContent: "space-between", 
      width: "50%", 
      "&:first-child": {
        borderRight: "1px solid #E2E8F0"
      },
      "& p": {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'center',
        color: '#375280',
      }
    },
    '& .icon': {
      marginRight: '8px',
      fill: "#375280", 
      height: '25px',
      marginBottom: '10px',  
    },
    '& .hire-button': {
      backgroundColor: '#D1BEA8',
      textTransform: "none",
      fontSize: "20px",
      color: 'white',
      fontWeight: 'bold',
      height: '56px',   
      padding: '10px 16px', 
      borderRadius: '2px 0px 0px 0px',
      "& span": {
        borderRadius: '2px 0px 0px 0px',
      },
      '&:hover': {
        backgroundColor: '#b59f8d',
      },
    },
    '& .favorite-icon':{
        marginLeft: 'auto',
    '& svg': {
      fill: "#375280"
    }},
  });

const ProductCardWrapper = styled(Box)({
    position: "relative",
    width: "100%",
    borderRadius: "2px",
    backgroundColor: "#fff",
    transition: "0.3s ease-in-out",
    cursor: "pointer",
  
    "& .discountBadge": {
      position: "absolute",
      top: "15px",
      left: "15px",
      backgroundColor: "#fff",
      color: "#375280",
      fontSize: "12px",
      fontWeight: "700",
      padding: "4px 8px",
      borderRadius: "2px",
      fontFamily: "Lato"
    },
  
    "& .favoriteIcon": {
      position: "absolute",
      right: "15px",
      fontSize: "24px",
      color: "#375280",
      width: "30px",
      height: "30px",
      top: "15px",
      padding: "8px",
      background: "white"
    },
  
    "& .productImage": {
      width: "100%",
      height: "350px",
      objectFit: "fill"
    },
  
    "& .productInfo": {
      padding: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .productName": {
        color: "#375280",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "19.2px",
        textAlign: "left",
        margin: "10px 0 5px"
      },
      "& .brandName": {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500, 
        lineHeight: "16.8px",
        textAlign: "left",
        margin: "5px 0 10px"
      },
      "& .price": {
        backgroundColor: '#F8F8F8',
        color: '#375280',
        fontFamily: 'Lato',
        padding: '10px',
        fontWeight: "700", 
        borderRadius: "8px"
      },
    },
})

const CardWrapper = styled(Card)({
    maxWidth: '400px',
    borderRadius: '2px',
    padding: '20px',
    border: "1px solid #D5D5D5",
    boxShadow: "none",
    "& .content": {
        padding: "0px"
    },
    "& .titleRow": {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },
    "& .title": {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'left',
        color: "#375280"
    },
    "& .price": {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: '24px',
        textAlign: 'right',
        color: '#375280',
    },
    "& .divider": {
      height: '1px',
      backgroundColor: '#e0e0e0',
      margin: '16px 0',
    },
    "& .featureList": {
      padding: '0 20px',
      margin: '0',
      listStyle: 'disc',
      color: '#6b6b6b',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      textAlign: 'left',
    },
    "& .featureItem": {
      padding: '2px 0',
      fontSize: '16px',
      color: '#375280',
    },
    "& .planButton": {
        backgroundColor: '#cbb9a8',
        color: 'white',
        marginTop: '70px',
        borderRadius: '2px',
        textTransform: "none",
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: '26px',
        letterSpacing: '0.01em',
        padding: "10px",
        '&:hover': {
            backgroundColor: '#bda893',
        },
    },
});  
// Customizable Area End
