import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ChangeEvent } from "react";
import { StoreByIdAPIResponse } from "./SellerStoreProfileController";
import {
  CountrySuccess,
  ErrorMessage,
  FormError,
  FormErrorTouched,
  Istep2,
  step2Schema,
} from "./CreateSellerStoreController";
import { getStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../components/src/APICall";
import { MyStoreList } from "./MySellerStoreController";
import * as Yup from "yup";
import { logoutSellerNavigation } from "../../../components/src/Seller/logOut";
// Customizable Area End

export const configJSON = require("./config.js");

export interface IEditInitial extends Istep2 {
  storeName: string;
  storeDescription: string;
}
export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  storeIdResp: MyStoreList;
  errorsMessage: {
    errorStoreUpload?: string;
    storeUploadError?: boolean;
  };
  storeProfile: {
    selectedFile: File | null;
    previewUrl: string;
  };
  isImageDialog: boolean;
  countryCode: CountrySuccess[];
  intialValue: IEditInitial;
  payMentMode: string[];
  isAlert: boolean;
  location:{
     lat: number; lng: number 
  };
  address: string;
  isLoader: boolean;
  severity: 'error' | 'warning' | 'info' | 'success';
  isPhoneNoError: boolean;
  isStoreNameError: boolean;
  storeNameErrorMessage: string;
  phoneNoErrorMessage: string;
  message: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditSellerStoreController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  storeByIdAPICallId: string = "";
  countryCodeAPICallId: string = "";
  updateStoreAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      storeIdResp: {} as MyStoreList,
      errorsMessage: {
        errorStoreUpload: "",
        storeUploadError: false,
      },
      storeProfile: {
        selectedFile: null,
        previewUrl: "",
      },
      isImageDialog: false,
      countryCode: [],
      intialValue: {
        storeName: "",
        storeDescription: "",
        address: "",
        area: "",
        block: "",
        mallName: "",
        floor: "",
        unitNumber: "0",
        city: "",
        zipCode: "",
        paymentMode: [],
        instruction: "",
        phoneCode: "+965",
        phoneNumber: "",
      },
      isPhoneNoError: false,
      isStoreNameError: false,
      storeNameErrorMessage: "",
      phoneNoErrorMessage: "",
      payMentMode: [],
      isAlert: false,
      location: {
        lat: 0,
        lng: 0,
      },
      address: "",
      isLoader: true,
      severity: "success",
      message: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseData && !responseData.errors) {
        this.apiSuccesser(apiRequestCallId, responseData);
      } else if (responseData && responseData.errors) {
        this.apiFailurer(responseData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    const storeId = +(await this.getStoreId());
    this.getStoreByIdApi(storeId);
    this.getCountryCodeApi();
    // Customizable Area End
  }

  getTokenData = async () => {
    return await getStorageData("auth-token");
  };

  getStoreId = async () => {
    return await getStorageData("store-id");
  };

  getStoreByIdApi = async (storeId: number) => {
    this.storeByIdAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.createStoreEndPoint}/${storeId}`,
      token: await this.getTokenData(),
    });
  };

  getCountryCodeApi = async () => {
    this.countryCodeAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.countryCodeEndPoint,
    });
  };

  apiSuccesser = async (
    apiRequestCallId: string,
    responseJson: StoreByIdAPIResponse & CountrySuccess[]
  ) => {
    if (apiRequestCallId === this.storeByIdAPICallId) {
      if (responseJson) {
        this.handleStoreIdResponse(responseJson.data);
      }
    } else if (apiRequestCallId === this.countryCodeAPICallId) {
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          countryCode: responseJson,
          isLoader: false,
        }));
      }
    } else if (apiRequestCallId === this.updateStoreAPICallId) {
      if (responseJson.data) {
        this.handleUpdateStoreResponse(responseJson.data);
      }
    }
  };

  apiFailurer = (responseJson: ErrorMessage) => {
    responseJson.errors.forEach((error) => {
      if (error.token) {
        this.setState(
          (prevState) => ({
            ...prevState,
            isAlert: true,
            message: error.token as string,
            severity: "error",
          }),
          () => {
            setTimeout(() => {
              this.handleRedirectPage("Home");
              logoutSellerNavigation();
            }, 2000);
          }
        );
      }
      if (error.contact_number) {
        this.setState((prevState) => ({
          ...prevState,
          phoneNoErrorMessage: error.contact_number as string,
          isPhoneNoError: true,
        }));
      }
      if (error.store_name) {
        this.setState((prevState) => ({
          ...prevState,
          storeNameErrorMessage: error.store_name as string,
          isStoreNameError: true,
        }));
      }
    });
  };

  handleStoreIdResponse = (value: MyStoreList) => {
    const initialVal = value.attributes;
    this.setState((prevState) => ({
      ...prevState,
      storeIdResp: value,
      storeProfile: {
        ...prevState.storeProfile,
        previewUrl: initialVal.image,
      },
      intialValue: {
        ...prevState.intialValue,
        storeName: initialVal.store_name,
        storeDescription: initialVal.description,
        address: initialVal.address,
        area: initialVal.area,
        block: initialVal.block,
        unitNumber:
          initialVal.unit_number === null
            ? "0"
            : initialVal.unit_number.toString(),
        mallName: initialVal.mall_name,
        floor: initialVal.floor,
        city: initialVal.city,
        zipCode: initialVal.zipcode,
        paymentMode: initialVal.payment_mode,
        instruction: initialVal.driver_instruction,
        phoneCode: initialVal.contact_number.country_code,
        phoneNumber: initialVal.contact_number.phone_number,
      },
      payMentMode: initialVal.payment_mode[0]
        .split(",")
        .map((item) => item.trim()),
      isLoader: false,
    }));
  };

  handleUpdateStoreResponse = (value: MyStoreList) => {
    const updateVal = value.attributes;
    this.setState(
      (prevState) => ({
        ...prevState,
        storeIdResp: value,
        storeProfile: {
          ...prevState.storeProfile,
          previewUrl: updateVal.image,
        },
        payMentMode: updateVal.payment_mode
          .toString()
          .replace(/[^\w,]/g, "")
          .split(",")
          .map((item) => item.trim()),
        intialValue: {
          ...prevState.intialValue,
          storeDescription: updateVal.description,
          storeName: updateVal.store_name,
          unitNumber:
            updateVal.unit_number === null
              ? "0"
              : updateVal.unit_number.toString(),
          address: updateVal.address,
          area: updateVal.area,
          phoneNumber: updateVal.contact_number.phone_number,
          block: updateVal.block,
          paymentMode: updateVal.payment_mode,
          mallName: updateVal.mall_name,
          phoneCode: updateVal.contact_number.country_code,
          floor: updateVal.floor,
          city: updateVal.city,
          zipCode: updateVal.zipcode,
          instruction: updateVal.driver_instruction,
        },
        isAlert: true,
        message: "Your Store has been Updated Successfully",
        severity: "success",
      }),
      () => {
        setTimeout(() => {
          this.handleRedirectPage("SellerStoreProfile");
        }, 1000);
      }
    );
  };

  handlePhoneErrorState = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPhoneNoError: false,
      phoneNoErrorMessage: ""
    }));
  }

  handleStoreNameErrorState = () => {
    console.log("data")
    this.setState((prevState) => ({
      ...prevState,
      isStoreNameError: false,
      storeNameErrorMessage: ""
    }));
  }

  handleRedirectPage = (path: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  handleEditClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isImageDialog: !prevState.isImageDialog,
    }));
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState((prevState) => ({
        ...prevState,
        storeProfile: {
          ...prevState.storeProfile,
          selectedFile: file,
          previewUrl: URL.createObjectURL(file),
        },
        errorsMessage: {
          ...prevState.errorsMessage,
          errorStoreUpload: "",
          storeUploadError: false,
        },
      }));
    }
  };

  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const helperText: string = isError ? errors[field] ?? "" : "";
    return { isError, helperText };
  };

  validateStoreUpload = () => {
    const { selectedFile } = this.state.storeProfile;
    const maxFileSize = 5 * 1024 * 1024;
    const validFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!selectedFile) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessage: {
          ...prevState.errorsMessage,
          errorStoreUpload: "You can't Update Same Image",
          storeUploadError: true,
        },
      }));
      return false;
    } else if (selectedFile.size > maxFileSize) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessage: {
          ...prevState.errorsMessage,
          errorStoreUpload: `File size should be less than 5 MB`,
          storeUploadError: true,
        },
      }));
      return false;
    } else if (!validFileTypes.includes(selectedFile.type)) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessage: {
          ...prevState.errorsMessage,
          errorStoreUpload: "Only .png, .jpg, and .jpeg files are allowed",
          storeUploadError: true,
        },
      }));
      return false;
    }

    return true;
  };

  handleSubmit = (submitEvent: React.FormEvent) => {
    submitEvent.preventDefault();

    if (!this.validateStoreUpload()) {
      return;
    }

    this.handleEditClose();
  };

  handleEditSubmit = async (values: IEditInitial) => {
    const formData = new FormData();
    formData.append("business[store_name]", values.storeName);
    formData.append("business[description]", values.storeDescription);
    if (this.state.storeProfile.selectedFile) {
      formData.append("business[image]", this.state.storeProfile.selectedFile);
    }
    formData.append("business[address]", values.address);
    formData.append("business[area]", values.area);
    formData.append("business[block]", values.block);
    formData.append("business[mall_name]", values.mallName);
    formData.append("business[floor]", values.floor);
    formData.append("business[unit_number]", values.unitNumber);
    formData.append("business[city]", values.city);
    formData.append("business[zipcode]", values.zipCode);
    formData.append("business[payment_mode][]", values.paymentMode.toString());
    formData.append("business[driver_instruction]", values.instruction);
    formData.append(
      "business[contact_number]",
      `${values.phoneCode}${values.phoneNumber}`
    );
    formData.append("business[latitude]", "44.968046");
    formData.append("business[longitude]", "-94.420307");
    const storeId = +(await this.getStoreId());
    this.updateStoreAPICallId = await apiCall({
      method: configJSON.httpPatchMethod,
      endPoint: `${configJSON.createStoreEndPoint}/${storeId}`,
      token: await this.getTokenData(),
      body: formData,
      isJsonStringify: false,
    });
  };

  oncloseAlert = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: false,
    }));
  };
  // Customizable Area End
}
// Customizable Area Start
export const editSellerStoreSchema = step2Schema.concat(
  Yup.object().shape({
    storeName: Yup.string()
      .required(`*${configJSON.storeNameValidMsg}`)
      .matches(
        configJSON.alphaRegx,
        `*${configJSON.storeName} ${configJSON.alphaErr}`
      )
      .trim(),
    storeDescription: Yup.string()
      .required(`*${configJSON.storeDescriptionValidMsg}`)
      .trim(),
  })
);
// Customizable Area End
