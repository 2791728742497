import React from "react";
// Customizable Area Start
import { Grid, Typography, TextField, Box } from "@material-ui/core";

import { Vector, scroll, button_, location } from "./assets";
import Switch from "@material-ui/core/Switch";
import Header from "../../../components/src/Header";
import Sidebar from "../../../components/src/Sidebar";
import Footer from "../../../components/src/Footer";
import { styled, withStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import i18n from "../../../components/src/i18next/i18n";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
import { GoogleMap, Marker } from "@react-google-maps/api";

// Customizable Area End
import AddressManagementController, {
  Props,
  CountrySuccess
} from "./AddressManagementController";
import CountrySelect from "../../../components/src/CountryDropDown.web";

export default class EditAddress extends AddressManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleToggle = this.handleToggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    // Customizable Area End
  }

  // Customizable Area Start
  renderLocationButton() {
    return (
      <div
        className="address_child"
        style={{
          fontSize: "16px",
          fontWeight: 700,
          color: "#FFFFFF",
          backgroundColor: "#CCBEB1",
          padding: "10px 16px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          width: "254px",
          height: "64px",
          marginBottom: "22px",
          borderRadius: "2px",
          fontFamily: 'Lato, sans-serif',
          marginLeft: "10px",
        }}
        onClick={this.handleUseCurrentLocation}
      >
        <img src={location} alt="location icon" />
        Use my current location
      </div>
    );
  }
  
  renderSearchInput() {
    return (
      this.state.mapsLoaded && (
        <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
          <input
            id="autocomplete"
            type="text"
            placeholder="Search for a place"
            style={{
              width: "254px",
              padding: "10px",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              display: this.state.mapVisible ? "block" : "none",
            }}
          />
        </div>
      )
    );
  }
  
  renderGoogleMap() {
    return (
      this.state.mapsLoaded &&
      this.state.mapVisible &&
      this.state.currentLocation && (
        <GoogleMap
          zoom={14}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={this.state.currentLocation || { lat: -34.397, lng: 150.644 }}
        >
          {this.state.selectedLocation && (
            <Marker position={this.state.selectedLocation} />
          )}
        </GoogleMap>
      )
    );
  }


  handleUseCurrentLocation = () => {
    this.setState({
      currentLocation: { lat:  19.0759837, lng: 72.8776559 },
      selectedLocation: { lat:  19.0759837, lng: 72.8776559 },
      mapVisible: true,
      showaddressData: {
        ...this.state.showaddressData,
        latitude: 19.0759837,
        longitude:72.8776559,
      },
    });
   
  };

  handleEditAddressImgCSS = () =>{
    return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  navHeaderEditAddressMargin = () =>{
    return i18n.dir() === "ltr" ? "27px 0px 20px 40px" : "27px 40px 20px 0px"
  }

  nameEditAddressCSS = () => {
    return i18n.dir() === "rtl" ? "31px 40px 20px 0px" : "31px 0px 20px 40px"
  }

  welcomeEditAddressCSS = () => {
    return i18n.dir() === "rtl" ?  "0px 40px 37px 0px" : "0px 0px 37px 40px"
  }
  render() {
    const { errors } = this.state;

    const GreenSwitch = withStyles({
      switchBase: {
        color: "#fff",
        "&$checked": {
          transform: "translateX(22px)",
          color: "#fff",
          "& + $track": {
            height: 17,
            border: "none",
            opacity: 1,
            backgroundColor: "#CCBEB1",
          }
        },
        "&$focusVisible $thumb": {
          border: "6px solid #fff",
          color: "#4cd964",
        }
      },
      thumb: {
        width: 19,
        height: 21
      },
      track: {
        borderRadius: 16 / 2,
        border: "1px solid #E5E5EA",
        opacity: 1,
        backgroundColor: "#E5E5EA",
        transition: "background-color 0.3s"
      },
      checked: {},
      focusVisible: {}
    })(Switch);

    return (
      //Merge Engine DefaultContainer
      <>
       {!this.state.stylishbuyer ? <Header navigation={this.props.navigation} /> : <StylishBuyerHeader navigation={this.props.navigation} />}
        <Grid
          style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }}
          container
          dir={i18n.dir()}
        >
          <Grid item>
            <div style={{...webStyle.innerContainerEdit,margin: this.navHeaderEditAddressMargin()}}>
              <Typography style={webStyle.innerContainerEdit13}>{this.tranAddress("Home")}</Typography>
              <img src={Vector} style={{transform: this.handleEditAddressImgCSS()}}/>
              <Typography style={webStyle.innerContainerEdit23}>
                {this.tranAddress("Address")}
              </Typography>
            </div>
            <div>
              <Typography style={{...webStyle.innerContainerEdit33,margin: this.nameEditAddressCSS()}}>
                <img src={scroll} />
                {this.state.fullName}
              </Typography>
            </div>
            <Typography style={{...webStyle.innerContainerEdit43,margin: this.welcomeEditAddressCSS()}}>
            {this.tranAddress("Welcome to your Account")}
            </Typography>
            <Sidebar navigation={this.props.navigation} />
          </Grid>

          <Grid item xs={12} md={6} style={{ margin: "0px auto" }}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                color: "#375280",
                gap: 8,
                marginBottom: "29px",
                marginTop: "93px",
                lineHeight: "33.5px",
                fontFamily: 'Lato , sans-serif',
                fontWeight: 800,
                fontSize: "20px",
              }}
            >
              <img
                src={button_}
                style={{ cursor: "pointer", transform: this.handleEditAddressImgCSS() }}
                onClick={this.getNavigationAddAddress}
                data-test-id="handleAddDetails"
              />
              {this.tranAddress("Edit Address")}
            </div>
            <div style={{ backgroundColor: "#F8F8F8" }}>
              <div>
                <div style={webStyle.addAddressContainer as React.CSSProperties}>

                  <div
                    className="address_child"
                    style={{
                      fontFamily: 'Lato , sans-serif',
                      marginLeft: "10px",
                      marginBottom: "22px",
                      color: "#375280",
                      fontSize: "20px",
                    }}
                  >
                    {this.tranAddress("Edit Address")}
                  </div>
                  <div style={{ position: "relative" }}>
      {this.renderLocationButton()}
      {this.renderSearchInput()}
      {this.renderGoogleMap()}
    </div>
                  <Grid
                    container
                    spacing={2}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontFamily: "Lato , sans-serif",
                          color: "#375280",
                          lineHeight: "24px",
                          fontWeight: 700,
                          fontSize: "16px",

                        }}

                      >
                        {this.tranAddress("Full Name")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        fullWidth
                        name="name"
                        variant="outlined"
                        value={this.state.showaddressData.name}
                        onChange={this.handleInputChange}
                        data-test-id="memberId"

                      />
                      {errors.name && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.name}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MainGridContainer container>
                        <Grid item style={{ width: "100%" }}>
                          <Box>
                            <Typography
                              data-test-id="countryId"
                              className={"inputHeading"}
                            >
                              {this.tranAddress("Phone Number")}
                              <span style={{ color: '#375280' }}>*</span>
                            </Typography>
                            <Box
                              className={"phoneInput"}
                              style={{ width: "100%" }}
                            >
                              <CustomDropDownBox>
                                <CountrySelect
                                  id="phoneCode"
                                  name="phoneCode"
                                  value={this.state.selectedCountry?.numeric_code || "+965"}
                                  placeholder="Select Phone Code"
                                  onChange={this.handleEditAddressChange}
                                  countryCode={this.state.countrySuccess}
                                  isBorder
                                />
                              </CustomDropDownBox>
                              <TextField
                                name="phone_number"
                                value={this.state.showaddressData.phone_number}
                                onChange={this.handleInputChange}
                                variant="outlined"
                                placeholder={this.tranAddress("Enter Contact Number")}
                                inputProps={{ maxLength: 12 }}
                                className="inputFeild2"
                                style={{ width: "100%" }}
                                data-test-id="phoneNumberTextFiled"
                                InputProps={{
                                  style: {
                                    gap: "4px",
                                    border: "0px",
                                    borderRadius: "8px",
                                    width: "100%",
                                  }
                                }}
                              />
                            </Box>
                            {errors.phone_number && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.phone_number}</span>}

                          </Box>
                        </Grid>
                      </MainGridContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontFamily: "Lato , sans-serif",
                          color: "#375280",
                          lineHeight: "24px",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                      >
                        {this.tranAddress("Street Name")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        name="street"
                        variant="outlined"
                        value={this.state.showaddressData.street}
                        onClick={this.handleUseCurrentLocation}
                        data-test-id="memberId"
                        fullWidth

                      />
                      {errors.street && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.street}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.tranAddress("House / Building Number")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="house_or_building_number"
                        value={this.state.showaddressData.house_or_building_number}
                        onChange={this.handleInputChange}

                      />
                      {errors.house_or_building_number && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.house_or_building_number}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.tranAddress("Block")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="block"
                        value={this.state.showaddressData.block}
                        onChange={this.handleInputChange}

                      />
                      {errors.block && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.block}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.tranAddress("Area")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="area"
                        value={this.state.showaddressData.area}
                        onChange={this.handleInputChange}

                      />
                      {errors.area && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.area}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.tranAddress("City")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="city"
                        value={this.state.showaddressData.city}
                        onChange={this.handleInputChange}

                      />
                      {errors.city && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.city}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.tranAddress("Zip Code")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="zipcode"
                        value={this.state.showaddressData.zipcode}
                        onChange={this.handleInputChange}

                      />
                      {errors.zipcode && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.zipcode}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.tranAddress("Address Name")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="address_name"
                        value={this.state.showaddressData.address_name}
                        onChange={this.handleInputChange}

                      />
                      {errors.address_name && <span style={{ color: '#f44336', fontSize: "16px", fontFamily: "Lato , sans-serif", fontWeight: 100 }}>{errors.address_name}</span>}

                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#375280",
                      fontSize: "16px",
                      fontWeight: 500
                    }}
                  >
                    <GreenSwitch
                      checked={this.state.showaddressData.is_default}
                      onChange={this.handleToggle}
                      data-test-id="handleToggle"
                      inputProps={{ "aria-label": "toggle switch" }}
                    />
                    {/* <p>Toggle is {this.state.isChecked ? 'ON' : 'OFF'}</p> */}
                    <p>{this.tranAddress("Make it default delivery address")}</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 22,
                      paddingInline: 10
                    }}
                  >
                    <div style={webStyle.button12} onClick={this.getNavigationAddAddress}>{this.tranAddress("Cancel")}</div>
                    <div
                      style={{ ...webStyle.button12, ...webStyle.saveButton }}
                      onClick={this.handleSubmit}
                      data-test-id="updateSubmit"
                    >
                      {this.tranAddress("Save")}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Snackbar
          data-test-id="alertTestId"
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        <Footer />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  addAddressContainer: {
    fontFamily: "Lato , sans-serif",
    fontWeight: 700,
    fontSize: "20px",
    boxShadow: "0px 2px 8px 0px #00000014",
    lineHeight: "26px",
    marginBottom: "24px",
    backgroundColor: "#FFFFFF",
    width: "100%",
    padding: "20px"
  },
  button12: {
    alignItems: "center",
    fontSize: "18px",
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    color: "#375280",
    backgroundColor: "white",
    border: "1px solid #CCBEB1",
    height: "64px",
    borderRadius: "2px",
    width: "50%",
    padding: "10px 16px 10px 16px",
    cursor: "pointer"
  },
  saveButton: {
    color: "white",
    backgroundColor: "#CCBEB1"
  },

  innerContainerEdit: {
    lineHeight: "22px",
    marginTop: "27px",
    marginLeft: "40px",
    gap: "10px",
    fontWeight: 400,
    display: "flex",
    color: "#475569",
    alignItems: "center",
  },
  innerContainerEdit13: {
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px",
    fontStyle: "normal",
    lineHeight: "22px",
    fontSize: "14px",
  },
  innerContainerEdit23: {
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    lineHeight: "22px",
    color: "#475569",
    padding: "4px 6px 4px 6px",
    fontStyle: "normal",
    fontWeight: 700
  },
  innerContainerEdit33: {
    gap: "15px",
    marginLeft: "40px",
    color: "#375280",
    lineHeight: "33.5px",
    marginTop: "31px",
    display: "flex",
    fontWeight: 800,
    fontFamily: "Lato , sans-serif",
    fontSize: "28px",
    fontStyle: "normal",
    alignItems: "center",
  },
  innerContainerEdit43: {
    fontStyle: "normal",
    marginBottom: "37px",
    color: "#807D7E",
    fontSize: "14px",
    lineHeight: "33.5px",
    fontFamily: "Lato , sans-serif",
    fontWeight: 400,
    marginLeft: "40px",
  }
};
const InputField = styled(TextField)({
  "& .MuiInputBase-input": {
    color: "#375280 !important" // Change text color here
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF",
      borderWidth: "1px",
    }
  },
  "& .MuiInputBase-root": {
    fontSize: "16px",
    borderRadius: "8px",
    margin: "auto",
    height: "56px",
    backgroundColor: "#F0F0F0",
    "@media only screen and (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-input": {
    color: "#1E293B",
    padding: "10px 16px 10px 16px",
    "&::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
      "@media only screen and (max-width: 414px)": {
        fontSize: "14px"
      }
    },
    "&:not(:placeholder-shown)": {
      color: "#1E293B"
    }
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1"
  }
});
const MainGridContainer = styled(Grid)({
  justifyContent: "center",
  display: "flex",

  "& .mainCotainer": {
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "26px",
    fontFamily: "Avenir,sans-serif",
    alignSelf: "flex-start",
    textOverflow: "ellipsis",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    marginBottom: "40px",
  },

  "& .inputHeading": {
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "16px",
    marginTop: "20px",
    fontStyle: "normal",
    marginBottom: "10px",
    color: "#375280",
    fontFamily: "Lato, sans-serif",
  },
  "& .inputFeild": {
    margin: "0px 10px 0px 10px !important"
  },
  "& .phoneInput": {
    width: "100%px",
    height: "56px",
    gap: "15px",
    display: "flex",
  },

  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    fontFamily: "Lato, sans-serif",
    cursor: "pointer",
    margin: "10px",
    textOverflow: "ellipsis",
  },

  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      border: "none",
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    }
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important"
      }
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "1px solid #F0F0F0 !important"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .phoneInput": { width: "100%", height: "56px" },
    "& .inputFeild2": {
      "& .MuiTextField-root": {
        "&:hover": {
          border: "1px solid #F0F0F0 !important"
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        border: "1px solid #F0F0F0 !important"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "2px",
        backgroundColor: "#F0F0F0",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiInputBase-input": {
        color: "#375280"
      },
      "& .MuiFormHelperText-contained": {
        margin: "0 !important"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      width: "300px"
    }
  }
});
const CustomDropDownBox = styled(Box)({
  width: "180px",
  cursor: "pointer",
  position: "relative",
});
const DropDownHeaderMenu = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  gap: "15px",
  background: "#F0F0F0",
  fontSize: "18px",
  color: "#375280",
  height: "36px",
  padding: "10px",
  "&.isActive": {
    border: "1px solid red",
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const SelectedCountryList = styled(Box)({
  display: "flex",
  alignItems: "center",
});
const DropDownOptionsList = styled(Box)({
  backgroundColor: "#fff",
  color: "#375280",
  overflowX: "hidden",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  height: "60vh",
  zIndex: 1,
  position: "absolute",
  border: "1px solid #ccc",
  left: 0,
  width: "100%",
});
const OptionsValueList = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "10px",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});
// Customizable Area End
