import React from "react";

// Customizable Area Start
import {
  Box, styled
} from "@material-ui/core";

interface RenderSVGProps {
    type: 'active' | 'complete' | 'default' | 'default-track';
    className: string;
  }

  const activeStep = 2
  const activeStepTrack = 0

export const dataStatus = [
    {
      status: "Order Placed By Buyer",
      des: "We have received your order on 19 July",
      date: "19 July"
    },
    {
      status: "Order Confirmed",
      des: "We have been confirmed on 12:12 July",
      date: "19 July"
    },
    {
      status: "Order Processed",
      des: "Delivery partner is on the way to collect order",
      date: "19 July"
    },
    {
      status: "Order Shipped",
      des: "Delivery partner has collected the order",
      date: ""
    },
    {
      status: "Out for Delivery",
      des: "Order is out for delivery",
      date: ""
    },
    {
      status: "Delivered",
      des: "Order is delivered by Delivery partner",
      date: ""
    },
  ]
  export const dataStatusReturn = [
    {
      status: "Return Placed",
      des: "We have received your order on",
      date: ""
    },
    {
      status: "Return Confirmed",
      des: "We have been confirmed on",
      date: ""
    },
    {
      status: "Out for Pickup",
      des: "Delivery partner is on the way to collect order",
      date: ""
    },
    {
      status: "Order Picked Up",
      des: "Delivery partner has picked up the return on",
      date: ""
    },
    {
      status: "Return Completed",
      des: "Delivery partner has returned the order on",
      date: ""
    },
    {
      status: "Refund Initiated",
      des: "Refund amount will be credited to buyer in 3-5 days.",
      date: ""
    },
    {
      status: "Refund Completed",
      des: "Amount refunded to buyer",
      date: ""
    },
  ]
  export  const dataTrack = [
    {
      status: "Order Processed",
      des: "Your Delivery partner is on the way to collect order and is expected to arrive at 12:04 AM",
      date: `09:00 AM 19 July`
    },
    {
      status: "Delivery Partner",
      des: "Delivery Partner has arrived at the store",
      date: ""
    }
  ]
  export  const ReturndataTrack = [
    {
      status: "Order picked up",
      des: "Delivery partner has picked up the Return",
      date: ""
    },
    {
      status: "Return Completed",
      des: "Delivery Partner has arrived at the Order",
      date: ""
    }
  ]

export const RenderSVG: React.FC<RenderSVGProps> = ({ type, className }) => {
    switch (type) {
      case 'active':
        return (
          <Box className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <circle cx="10" cy="10" r="9.5" fill="white" stroke="#CCBEB1" />
              <circle cx="10" cy="10" r="7" fill="#B2A69B" />
            </svg>
          </Box>
        );
      case 'complete':
        return (
          <Box className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <circle cx="10" cy="10" r="10" fill="#CCBEB1" />
            </svg>
          </Box>
        );
      case 'default-track':
        return (
          <Box className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#CBD5E1" />
            </svg>
          </Box>
        );
      case 'default':
      default:
        return (
          <Box className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <circle cx="8" cy="8" r="8" fill="#CBD5E1" />
            </svg>
          </Box>
        );
    }
  };
  export const RenderImageFunction = (index: number, activeStep: number) => {
    let type;
    if (index === activeStep) {
      type = 'active';
    } else if (index < activeStep) {
      type = 'complete';
    } else {
      type = 'default';
    }
    return <RenderSVG type={type as RenderSVGProps["type"]} className={`dot-style${type === 'active' ? '-active' : type === 'complete' ? '-complete' : ''}`} />;
  };

export const RenderTrackImageFunction = (index: number, activeStepTrack: number) => {
    let type;
    if (index === activeStepTrack) {
      type = 'active';
    } else if (index < activeStepTrack) {
      type = 'complete';
    } else {
      type = 'default-track';
    }
    return <RenderSVG type={type as RenderSVGProps["type"]} className={`dot-style${type === 'active' ? '-active' : type === 'complete' ? '-complete' : ''}`} />;
  };

export  function trackContainerData() {
    return <MainConatiner>
      {dataTrack.map((item, index) => {
        return (
          <Box key={index} className='main-sttaper'>
            <Box style={webStyle.stepDate} className='date-style2'>
              {item.date}
            </Box>
            <Box className='main-divider'>
              {RenderTrackImageFunction(index, activeStepTrack)}
              {dataTrack.length !== (index + 1) && <Box className={index < activeStepTrack ? 'divider-border-active' : 'divider-border'}></Box>}
            </Box>
            <Box style={webStyle.trackStatusTitleMB}>
              <Box style={index > activeStepTrack ? webStyle.stepOpacityTitle : webStyle.stepTitle as React.CSSProperties}>
                {item.status}
              </Box>
              <Box style={index > activeStepTrack ? webStyle.stepOpacityDescription : webStyle.stepDescription as React.CSSProperties}>
                {item.des}
              </Box>
            </Box>
          </Box>
        )
      })}
    </MainConatiner>
  }

export  function statusContainerData() {
    return <MainConatiner>
    {dataStatus.map((item, index) => {
      return (
        <Box key={index} className='main-sttaper'>
          <Box style={webStyle.stepDate} className='date-style'>
            {item.date}
          </Box>
          <Box className='main-divider'>
            {RenderImageFunction(index, activeStep)}
            {dataStatus.length !== (index + 1) && <Box className={index < activeStep ? 'divider-border-active' : 'divider-border'}></Box>}
          </Box>
          <Box style={webStyle.trackStatusTitleMB}>
            <Box style={index > activeStep ? webStyle.stepOpacityTitle : webStyle.stepTitle as React.CSSProperties}>
              {item.status}
            </Box>
            <Box style={index > activeStep ? webStyle.stepOpacityDescription : webStyle.stepDescription as React.CSSProperties}>
              {item.des}
            </Box>
          </Box>
        </Box>
      )
    })}
  </MainConatiner>
  }

  
export function statusContainerReturnData(orderStatus: {
  return_placed_at: string | null;
  return_confirmed_at: string | null;
  returned_assign_at: string | null;
  return_pick_at: string | null;
  return_at: string | null;
  refunded_at: string | null;
  refunded_cancel_at: string | null;
} | undefined) {
  
  if (!orderStatus) {
    return -1;
  }
  type Status = {
    return_placed_at: string | null;
    return_confirmed_at: string | null;
    returned_assign_at: string | null;
    return_pick_at: string | null;
    return_at: string | null;
    refunded_at: string | null;
    refunded_cancel_at: string | null;
  };

  const statusFields: Array<keyof Status> = [
    'return_placed_at',
    'return_confirmed_at',
    'returned_assign_at',
    'return_pick_at',
    'return_at',
    'refunded_at',
    'refunded_cancel_at'
  ];

  let latestTimestamp: Date | null = null;
  let latestStatus: keyof Status | undefined;

  for (const field of statusFields) {
    const timestamp = orderStatus[field as keyof Status];
    if (timestamp) {
      const date = new Date(timestamp);
      latestTimestamp = date;
      latestStatus = field;
    }
  }
  let formattedDate = "";
  if (latestTimestamp) {
    formattedDate = formatDate(latestTimestamp);
  }
  function formatDate(date: Date): string {
    const days = date.getDate();
    const options: Intl.DateTimeFormatOptions = { month: 'short' };
    const month = new Intl.DateTimeFormat('en-US', options).format(date);
    return `${days} ${month}`;
  }
  function getActiveStep(orderStatus: string | undefined) {
    switch (orderStatus) {
      case "return_placed_at":
        return 0;
      case "return_confirmed_at":
        return 1;
      case "returned_assign_at":
        return 2;
      case "return_pick_at":
        return 3;
      case "return_at":
        return 4;
      case "refunded_at":
        return 5;
      case "refunded_cancel_at":
        return 6;
      default:
        return -1;
    }
  }

  const activeStep = getActiveStep(latestStatus);
  return <MainConatiner>
    {dataStatusReturn.map((item, index) => {
      const shouldDisplayDate = index <= activeStep;
      const stepTimestamp = orderStatus[statusFields[index]];
      return (
        <Box key={index} className='main-sttaper'>
          <Box style={webStyle.stepDate} className='date-style'>
            {shouldDisplayDate && stepTimestamp ? formatDate(new Date(stepTimestamp)) : ''}
          </Box>
          <Box className='main-divider'>
            {RenderImageFunction(index, activeStep)}
            {dataStatusReturn.length !== (index + 1) && (
              <Box className={index < activeStep ? 'divider-border-active' : 'divider-border'}></Box>
            )}
          </Box>
          <Box style={webStyle.trackStatusTitleMB}>
            <Box style={index > activeStep ? webStyle.stepOpacityTitle : webStyle.stepTitle as React.CSSProperties}>
              {item.status}
            </Box>
            <Box style={index > activeStep ? webStyle.stepOpacityDescription : webStyle.stepDescription as React.CSSProperties}>
              {item.des} {shouldDisplayDate && stepTimestamp ? formatDate(new Date(stepTimestamp)) : ''}
            </Box>
          </Box>
        </Box>
      );
    })}

  </MainConatiner>
}

export function statusContainerTrackDriver(orderStatus: {
  return_placed_at: string | null;
  return_confirmed_at: string | null;
  returned_assign_at: string | null;
  return_pick_at: string | null;
  return_at: string | null;
  refunded_at: string | null;
  refunded_cancel_at: string | null;
} | undefined) {

  if (!orderStatus) {
    return -1;
  }

  // Define Status Type
  type Status = {
    return_placed_at: string | null;
    return_confirmed_at: string | null;
    returned_assign_at: string | null;
    return_pick_at: string | null;
    return_at: string | null;
    refunded_at: string | null;
    refunded_cancel_at: string | null;
  };

  const statusFields: Array<keyof Status> = [
    'return_placed_at',
    'return_confirmed_at',
    'returned_assign_at',
    'return_pick_at',
    'return_at',
    'refunded_at',
    'refunded_cancel_at'
  ];
  let latestTimestamp: Date | null = null;
  let latestStatus: keyof Status | undefined;

  for (const field of statusFields) {
    const timestamp = orderStatus[field as keyof Status];
    if (timestamp) {
      const date = new Date(timestamp);
      latestTimestamp = date;
      latestStatus = field;
    }
  }
  let formattedDate = '';
  if (latestTimestamp) {
    formattedDate = formatDate(latestTimestamp);
  }
  function formatDate(date: Date): string {
    const days = date.getDate();
    const options: Intl.DateTimeFormatOptions = { month: 'short' };
    const month = new Intl.DateTimeFormat('en-US', options).format(date);
    return `${days} ${month}`;
  }

  function getActiveStep(orderStatus: string | undefined) {
    switch (orderStatus) {
      case 'return_pick_at':
        return 0;
      case 'return_at':
        return 1;
      default:
        return -1;
    }
  }

  function convertUtcToIst(utcTimeStr: string | number | Date) {
    const utcDate = new Date(utcTimeStr);
    const istOffset = 5.5 * 60 * 60 * 1000;
    const istDate = new Date(utcDate.getTime() + istOffset);
    let hours = istDate.getHours();
    const minutes = istDate.getMinutes();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedTime = `0${hours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
    return formattedTime;
  }
  const activeStep = getActiveStep(latestStatus);
  return <MainConatiner>
    {ReturndataTrack.map((item, index) => {
      const shouldDisplayDate = index <= activeStep;
      const stepTimestamp = orderStatus[statusFields[index]];
      return (
        <Box key={index} className='main-sttaper'>
          <Box style={webStyle.stepDate} className='date-style'>
            <Box style={{ width: "80px", marginBottom: '10px' }}>{index === activeStep && stepTimestamp ? convertUtcToIst(stepTimestamp) : ''}</Box>
            <Box style={{ width: "80px", marginLeft: '25px' }}>{shouldDisplayDate && stepTimestamp ? formatDate(new Date(stepTimestamp)) : ''}</Box>
          </Box>
          <Box className='main-divider' style={{ minWidth: '70px' }}>
            {RenderImageFunction(index, activeStep)}
            {index < ReturndataTrack.length - 1 && dataStatusReturn.length !== (index + 1) && (
              <Box className={index < activeStep ? 'divider-border-active' : 'divider-border'}></Box>
            )}
          </Box>
          <Box style={webStyle.trackStatusTitleMB}>
            <Box style={index > activeStep ? webStyle.stepOpacityTitle : webStyle.stepTitle as React.CSSProperties}>
              {item.status}
            </Box>
            <Box style={index > activeStep ? webStyle.stepOpacityDescription : webStyle.stepDescription as React.CSSProperties}>
              {item.des} {shouldDisplayDate && stepTimestamp ? formatDate(new Date(stepTimestamp)) : ''}
            </Box>
          </Box>
        </Box>
      );
    })}

  </MainConatiner>
}
  const MainConatiner = styled(Box)({
    "& .date-style": {
      minWidth: 65
    },
    "& .date-style2": {
      minWidth: 80
    },
    "& .main-sttaper": {
      display: "flex",
      gap: "25px"
    },
    "& .divider-border": {
      background: "#CBD5E1",
      width: "2px",
      height: "100%"
    },
    "& .divider-border-active": {
      background: "#CCBEB1",
      width: "2px",
      height: "100%"
    },
    "& .dot-style": {
      height: 16
    },
    "& .dot-style-complate": {
      height: 20
    },
    "& .dot-style-active": {
      height: 20
    },
    "& .main-divider": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 30
    }
  })

  const webStyle = {
    fontFamily: "Lato",
    stepTitle: {
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#375280",
    },
    stepOpacityTitle: {
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#94A3B8",
    },
    stepDate: {
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 400,
      color: "#B2A69B",
    },
    stepDescription: {
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 400,
      color: "#375280",
      marginTop: "10px"
    },
    stepOpacityDescription: {
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 400,
      marginTop: "10px",
      color: "#CBD5E1"
    },
    stepStatusDescription: {
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 400,
      color: "#808080",
      marginTop: "10px"
    },
    trackStatusTitleMB: {
      marginBottom: 30
    },
  }