import React from "react";
import {
 Menu,
 MenuItem,
 IconButton,
 Tooltip,
 Typography,
} from "@material-ui/core";
import {
 Twitter,
 Facebook,
 LinkedIn,
 Email,
 Link as LinkIcon,
} from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import i18n from "../i18next/i18n";

interface ShareMenuProps {
 anchorEl: null | HTMLElement;
 onClose: () => void;
 onCopyLink: () => void;
 linkCopied: boolean;
 productUrl: string;
 dir?: 'ltr' | 'rtl'
}

const StyledMenu = styled(Menu)({
 borderRadius: "8px",
 boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
 "& .MuiListItem-button:hover":{
    backgroundColor: "rgba(0, 0, 0, 0.04) !important"
 },
 "& .MuiListItem-root.Mui-focusVisible" : {
    backgroundColor: "white"
 }
});

const StyledMenuItem = styled(MenuItem)({
 padding: "7px 10px",
 display: "flex",
 justifyContent: "flex-start",
 alignItems: "center",
 '& .order-button': {
    fontSize: "14px",
    backgroundColor: '#375280',
    color: 'white',
    borderRadius: '4px',
    fontWeight: 800,
    fontFamily: 'lato',
    "& span": {
      borderRadius: '4px',
    },
    textTransform: "none",
    padding: '10px 16px', 
  },
  "& .icon": {
   border: "1px solid #BEBCBD",
   marginRight: "10px",
   padding: "5px"
  },
  "& .title": {
   fontSize: "13px",
   fontFamily: "lato",
   color: "#375280",
   '&:hover': {
      textDecoration: "none"
    },
  }
});



const ShareProductMenu: React.FC<ShareMenuProps> = ({
 anchorEl,
 onClose,
 onCopyLink,
 linkCopied,
 productUrl,
 dir="ltr"
}) => {
 const menuRef = React.useRef<HTMLDivElement | null>(null);
 const shareOptions = [
  {
   href: `https://twitter.com/intent/tweet?url=${productUrl}&text=Check out this product!`,
   title: "Twitter",
   icon: <Twitter style={{ color: "#375280" }}/>,
  },
  {
   href: `https://www.facebook.com/sharer/sharer.php?u=${productUrl}`,
   title: "Facebook",
   icon: <Facebook style={{ color: "#375280" }}/>,
  },
  {
   href: `https://www.linkedin.com/shareArticle?url=${productUrl}`,
   title: "LinkedIn",
   icon: <LinkedIn style={{ color: "#375280" }}/>,
  },
  {
   href: `mailto:?subject=Check this out!&body=I thought you might find this interesting: ${productUrl}`,
   title: "Email",
   icon: <Email style={{ color: "#375280" }}/>,
  },
 ];

 const transMenu = ( tMenu: string) => {
  return i18n.t(tMenu, { ns: "prodDesc"})
 }


  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        anchorEl &&
        !anchorEl.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (anchorEl) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl, onClose]);


 return (
  <StyledMenu ref={menuRef} anchorEl={anchorEl} dir={dir} open={Boolean(anchorEl)} onClose={onClose}
  disableScrollLock
   anchorOrigin={{
     vertical: "top",
     horizontal: "center",
   }}
   transformOrigin={{
     vertical: "top",
     horizontal: "center",
   }}
   PaperProps={{
     style: { minWidth: "153px", marginTop: "40px" },
   }}
   disablePortal
  >
   {shareOptions.map((option, index) => (
    <Typography component="a" href={option.href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" , margin: "0", padding: 0 }}>
    <StyledMenuItem dir={dir} key={index} onClick={onClose}>
     <Tooltip title={transMenu(option.title)}>
      <IconButton 
       className="icon"
       style={{ 
        marginRight: dir === 'rtl' ? 0 : '15px',
        marginLeft: dir === 'rtl' ? "15px" : 0
       }}
      >
       {option.icon}
      </IconButton>
     </Tooltip>
     <Typography className="title">{transMenu(option.title)}</Typography>
    </StyledMenuItem>
    </Typography>
   ))}
   <StyledMenuItem dir={dir} key={100} onClick={onCopyLink}>
     <Tooltip title={transMenu("Copy")}>
      <IconButton className="icon" 
       style={{ 
        marginRight: dir === 'rtl' ? 0 : '15px',
        marginLeft: dir === 'rtl' ? "15px" : 0
       }}
      >
       <LinkIcon style={{ color: "#375280" }} />
      </IconButton>
     </Tooltip>
     <Typography className="title">
      {linkCopied ? transMenu("Link Copied!") : transMenu("Copy Link")}
     </Typography>
    </StyledMenuItem>
  </StyledMenu>
 );
};

export default ShareProductMenu;
