import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Button,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { styled, createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import OrderPaymentsController, {
  Props,
  configJSON,
} from "./OrderPaymentsController.web";
import {
  imgVisa,
  imgMasterCard,
  imgApplePay,
  imgGooglePay,
  imgPlusBtn,
} from "./assets";
import OrderSections from "./OrderSections.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontWeight: 500,
      fontFamily: "Lato, sans-serif",
      color: "#375280",
    },
  },
});
// Customizable Area End

export default class OrderPayments extends OrderPaymentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  paymentMethods = [
    { value: "masterCard", icon: imgMasterCard },
    { value: "visa", icon: imgVisa },
    { value: "googlePay", icon: imgGooglePay },
    { value: "applePay", icon: imgApplePay },
  ];

  showPassword = false;

  yearOptions = () => {
    let years = [];

    for (
      let i = new Date().getFullYear();
      i <= new Date().getFullYear() + 20;
      i++
    ) {
      years.push(i);
    }
    return years;
  };

  newAddressFields = () => (
    <Box className="new-address-block">
      <Box className="input-row">
        <Box className="input-div" style={{ flexBasis: "20%" }}>
          <Typography className="input-label address-label">
            {configJSON.title}
          </Typography>
          <FormControl fullWidth>
            <Select variant="filled" disableUnderline>
              <MenuItem value="mister">{configJSON.mister}</MenuItem>
              <MenuItem value="misses">{configJSON.misses}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className="input-div">
          <Typography className="input-label address-label">
            {configJSON.firstName}
          </Typography>
          <TextField variant="filled" InputProps={{ disableUnderline: true }} />
        </Box>
        <Box className="input-div">
          <Typography className="input-label address-label">
            {configJSON.lastName}
          </Typography>
          <TextField variant="filled" InputProps={{ disableUnderline: true }} />
        </Box>
      </Box>
      <Box className="input-row">
        <Box className="input-div" style={{ flexBasis: "100%" }}>
          <Typography className="input-label address-label">
            {configJSON.enterAddress}
          </Typography>
          <TextField variant="filled" InputProps={{ disableUnderline: true }} />
        </Box>
      </Box>
      <Box className="input-row">
        <Box className="input-div">
          <Typography className="input-label address-label">
            {configJSON.enterMobile}
          </Typography>
          <TextField
            variant="filled"
            InputProps={{ disableUnderline: true }}
            type="tel"
          />
        </Box>
        <Box className="input-div">
          <Typography className="input-label address-label">
            {configJSON.enterZip}
          </Typography>
          <TextField
            variant="filled"
            data-test-id="zip-input"
            InputProps={{ disableUnderline: true }}
            value={this.state.zip}
            onChange={(e) => this.digitsValidation(e.target.value, false)}
          />
        </Box>
      </Box>
    </Box>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Header navigation={this.props.navigation}/>
        <OrderSections activeTab="payments" />
        <MainGrid data-test-id="mainGrid" container>
          <Box className="flex grid-gap">
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
              <Divider />
              <Box className="section-title">
                <Typography className="title-text">
                  {configJSON.paymentInformation}
                </Typography>
              </Box>
              <Divider />
              <Box className="payment-method">
                {this.paymentMethods.map((item) => (
                  <Box
                    className={`method-item ${
                      this.state.selectedPaymentMethod === item.value
                        ? "selected-method-item"
                        : ""
                    }`}
                    data-test-id={`${item.value}-method`}
                    onClick={() => this.setSelectedPaymentMethod(item.value)}
                    key={item.value}
                  >
                    <img src={item.icon} />
                  </Box>
                ))}
              </Box>
              <Box>
                <Box className="input-row">
                  <Box className="input-div">
                    <Typography className="input-label">
                      {configJSON.cardHolder}
                    </Typography>
                    <TextField
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />
                  </Box>
                  <Box className="input-div">
                    <Typography className="input-label">
                      {configJSON.cardNumber}
                    </Typography>
                    <TextField
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={this.changeShowCard}
                              onMouseDown={(e) => e.preventDefault()}
                              data-test-id="card-visibility-btn"
                            >
                              {this.state.showCard ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="0000-0000-0000-0000"
                      value={this.state.cardNumber}
                      onChange={this.creditCardInputOnChange}
                      type={this.state.showCard ? "text" : "password"}
                      data-test-id="card-input"
                    />
                  </Box>
                </Box>
                <Box className="input-row">
                  <Box className="input-div">
                    <Typography className="input-label">
                      {configJSON.expiryDate}
                    </Typography>
                    <Box className="select-div">
                      <FormControl fullWidth>
                        <Select
                          variant="filled"
                          data-test-id="month-select"
                          displayEmpty
                          renderValue={
                            this.state.month !== 0
                              ? () => (
                                  <Typography>{this.state.month}</Typography>
                                )
                              : () => (
                                  <Typography>
                                    {configJSON.monthPlaceholder}
                                  </Typography>
                                )
                          }
                          disableUnderline
                          onChange={(e) =>
                            this.monthOnChange(e.target.value as number)
                          }
                        >
                          {[...Array(12).keys()].map((month) => (
                            <MenuItem value={month + 1} key={month}>
                              {month + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <Select
                          variant="filled"
                          data-test-id="year-select"
                          displayEmpty
                          renderValue={
                            this.state.year !== 0
                              ? () => <Typography>{this.state.year}</Typography>
                              : () => (
                                  <Typography>
                                    {configJSON.yearPlaceholder}
                                  </Typography>
                                )
                          }
                          onChange={(e) =>
                            this.yearOnChange(e.target.value as number)
                          }
                          disableUnderline
                        >
                          {this.yearOptions().map((year) => (
                            <MenuItem value={year} key={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="input-div">
                    <Typography className="input-label">
                      {configJSON.cvv}
                    </Typography>
                    <TextField
                      variant="filled"
                      data-test-id="cvv-input"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ maxLength: 3 }}
                      placeholder={configJSON.cvv}
                      value={this.state.cvv}
                      onChange={(e) =>
                        this.digitsValidation(e.target.value, true)
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box className="online-payment">
                <Box />
                <Box>
                  <Typography className="method-name">
                    {configJSON.gPay}
                  </Typography>
                  <Typography className="method-data">
                    xxxx xxxx xxxx
                  </Typography>
                </Box>
                <Box>
                  <img src={imgPlusBtn} className="pointer" />
                </Box>
              </Box>
              <Divider />
              <Box className="online-payment">
                <Box />
                <Box>
                  <Typography className="method-name">
                    {configJSON.applePay}
                  </Typography>
                  <Typography className="method-data">
                    xxxx xxxx xxxx
                  </Typography>
                </Box>
                <Box>
                  <img src={imgPlusBtn} className="pointer" />
                </Box>
              </Box>
              <Divider style={{ marginBottom: "2rem" }} />
              <Divider />
              <Box className="section-title">
                <Typography className="promo-code">
                  {configJSON.promoCode} 945GHJF
                </Typography>
              </Box>
              <Divider />
              <Box className="section-title">
                <Typography className="shipping-title">
                  {configJSON.shippingAddress}
                </Typography>
              </Box>
              <Box>
                <Typography className="shipping-head">
                  Main Address string
                </Typography>
                <Typography>Full Address string</Typography>
              </Box>
              <Divider style={{ marginTop: "1rem" }} />
              <Box
                className="add-address-block pointer"
                onClick={this.onNewAddressClick}
                data-test-id="add-address-btn"
              >
                <IconButton style={{ padding: 0 }}>
                  <KeyboardArrowLeft />
                </IconButton>
                <Typography>{configJSON.addAddress}</Typography>
              </Box>
              {this.state.isNewAddressActive && this.newAddressFields()}
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className="orders">
              <Box textAlign="center" mb="1.5rem">
                <Typography className="title-text">
                  {configJSON.orders}
                </Typography>
              </Box>
              <Divider />
              <Box className="details-block">
                <Box flexBasis="20%">
                  <img src="" />
                </Box>
                <Box flexBasis="65%" className="flex-column">
                  <Box>
                    <Typography className="brand-name">BRAND</Typography>
                    <Typography className="item-name">Item Name</Typography>
                    <Typography className="item-specs">
                      {configJSON.color}: Orange
                    </Typography>
                    <Typography className="item-specs">Size: M</Typography>
                  </Box>
                  <Box className="flex pointer">
                    <Typography className="brand-name">
                      {configJSON.quantity} 1
                    </Typography>
                    <KeyboardArrowDown />
                  </Box>
                </Box>
                <Box className="price-block flex-column">
                  <Box>
                    <Typography className="full-price">$24</Typography>
                    <Typography className="total-price">$22</Typography>
                  </Box>
                  <Typography className="remove-btn">
                    {configJSON.remove}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box className="price-details">
                <Box className="price-item" mt="2.5rem">
                  <Typography>{configJSON.itemTotal}</Typography>
                  <Typography>$44</Typography>
                </Box>
                <Divider />
                <Box className="price-item">
                  <Typography>{configJSON.shippingFee}:</Typography>
                  <Typography>$05</Typography>
                </Box>
                <Divider />
                <Box className="price-item">
                  <Typography>{configJSON.voucher}</Typography>
                  <Typography>-10</Typography>
                </Box>
                <Box className="price-item price-total">
                  <Typography>{configJSON.totalText}</Typography>
                  <Typography>$35</Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
          <Divider />
          <Box className="submit-block">
            <Button className="submit-button">{configJSON.placeOrder}</Button>
          </Box>
        </MainGrid>
        <Footer navigation={this.props.navigation}/>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const MainGrid = styled(Grid)({
  flexDirection: "column",
  padding: "0 4.5rem",
  "& .flex": {
    display: "flex",
  },
  "& .grid-gap": {
    gap: "1.75rem",
  },
  "& .flex-column": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  "& .pointer": {
    cursor: "pointer",
  },
  "& .section-title": {
    borderBottom: "1px solid #375280",
    width: "fit-content",
    lineHeight: "2rem",
    margin: "0.75rem 0",
  },
  "& .title-text": {
    fontSize: "1.5rem",
  },
  "& .MuiDivider-root": {
    backgroundColor: "rgba(55, 82, 128, 0.5)",
  },
  "& .payment-method": {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem 0 3rem",
  },
  "& .method-item": {
    boxSizing: "border-box",
    width: "9rem",
    height: "5rem",
    backgroundColor: "#f8f8f8",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      alignSelf: "center",
      cursor: "pointer",
    },
  },
  "& .selected-method-item": {
    border: "1px solid #0F172A",
  },
  "& .input-row": {
    display: "flex",
    justifyContent: "space-between",
    gap: "1.5rem",
    marginBottom: "2.5rem",
  },
  "& .input-div": {
    flexBasis: "50%",
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#F8F8F8",
    },
    "& .MuiInputBase-input": {
      padding: "1rem",
      fontSize: "20px",
      color: "#375280",
      fontWeight: 700,
      fontFamily: "Lato, sans-serif",
    },
  },
  "& .select-div": {
    display: "flex",
    gap: "0.5rem",
    justifyContent: "space-between",
    "& .MuiFormControl-root": {
      flexBasis: "50%",
    },
    "& .MuiFormLabel-root": {
      zIndex: 1,
    },
  },
  "& .input-label": {
    fontWeight: 400,
    fontSize: "20px",
    marginBottom: "0.75rem",
  },
  "& .online-payment": {
    display: "grid",
    gridTemplateColumns: "1fr 8fr 1fr",
    margin: "1rem 0",
    alignItems: "center",
    "& div:first-child": {
      background: "#F5F5F5",
      width: "80%",
      height: "100%",
    },
    "& .method-name": {
      fontSize: "1.25rem",
      color: "#070707",
    },
    "& .method-data": {
      fontWeight: 700,
      color: "#8E8F94",
    },
  },
  "& .promo-code": {
    fontWeight: 700,
    fontSize: "1.5rem",
  },
  "& .shipping-title": {
    fontSize: "1.5rem",
  },
  "& .shipping-head": {
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  "& .add-address-block": {
    margin: "1.5rem 0",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "& p": {
      fontWeight: 700,
    },
  },
  "& .new-address-block": {
    "& .address-label": {
      fontWeight: 700,
    },
    "& .input-div .MuiInputBase-input": {
      fontSize: "18px",
      fontWeight: 400,
    },
  },
  "& .orders": {
    backgroundColor: "#F8F8F8",
    borderRadius: "2px",
    padding: "1.5rem 1rem 0",
    height: "fit-content",
    "& .MuiDivider-root": {
      backgroundColor: "rgba(184, 191, 203, 0.5)",
    },
    "& .details-block": {
      display: "flex",
      gap: "0.875rem",
      margin: "1rem 0 1.5rem",
      "& img": {
        width: "6rem",
        height: "7.5rem",
        borderRadius: "8px",
        border: "none",
      },
      "& .brand-name": {
        fontSize: "0.875rem",
        fontWeight: 700,
        color: "#0F172A",
      },
      "& .item-name": {
        fontWeight: 400,
        color: "#0F172A",
      },
      "& .item-specs": {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
      "& .price-block": {
        textAlign: "center",
        flexBasis: "15%",
        "& .full-price": {
          fontSize: "0.75rem",
          fontWeight: 400,
          textDecoration: "line-through",
          marginBottom: "0.5rem",
        },
        "& .total-price": {
          fontSize: "0.875rem",
          fontWeight: 700,
          color: "#0F172A",
        },
        "& .remove-btn": {
          display: "inline-block",
          cursor: "pointer",
          fontSize: "0.75rem",
          fontWeight: 400,
        },
      },
    },
    "& .price-details": {
      marginTop: "2.5rem",
    },
    "& .price-item": {
      display: "flex",
      justifyContent: "space-between",
      paddingRight: "1rem",
      margin: "0.75rem 0",
      "& p": {
        display: "inline-block",
        fontSize: "1.125rem",
      },
    },
    "& .price-total": {
      padding: "1rem",
      margin: "1rem -1rem 0",
      backgroundColor: "#B3B1BA",
      borderRadius: "2px",
      "& p": {
        color: "#FFF",
        fontSize: "1.5rem !important",
        fontWeight: 700,
      },
    },
  },
  "& .submit-block": {
    display: "flex",
    justifyContent: "center",
    margin: "2rem 0 4rem",
  },
  "& .submit-button": {
    width: "40vw",
    color: "#FFF",
    backgroundColor: "#CCBEB1",
    border: "none",
    borderRadius: "2px",
    padding: "1rem",
    fontFamily: "Lato, sans-serif",
    fontSize: "1rem",
    fontWeight: 700,
  },
});
// Customizable Area End
