import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerAnalyticsInsightsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }


  handleRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }

  // Customizable Area End
}
// Customizable Area Start
export const saleCount = [
    {label:"Total Sales",value:"$ 24,00"},
    {label:"Impression",value:"300"},
    {label:"Total Orders",value:"350"},
    {label:"Visitors",value:"3000"}
]

export const pieChartData = [{
    label: "New",
    value: 50,
    color: "#375280"
},
{
    label: "Completed",
    value: 20,
    color: "#F59E0B", 
},
{
    label: "Pending",
    value: 10,
    color: "#CBD5E1", 
},
{
    label: "Cancelled",
    value: 20,
    color: "#F87171", 
},
]

export const lineChartData = [{
    label: "Sold Units",
    color: "#F59E0B", 
},
{
    label: "Appeared in Search",
    color: "#34D399", 
}
]

export const monthLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
// Customizable Area End
