import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  MenuItem,
  Select,
  IconButton,
  styled,
  TextField,
  Box,
  FormHelperText,
  InputAdornment
} from "@material-ui/core";
import ClientNewOrderRequestController, {
  Props,
  validationSchema,
} from "./ClientNewOrderRequestController";
import { ThemeProvider } from "react-native-elements";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import { backArrow, deleteIcon, uploadIcon } from "./assets";
import ReusableSnackbar from "../../../components/src/AlertContainer";
import { Formik } from "formik";
import { selectEndIcon } from "../../customform/src/assets";
import {
  FormError,
  FormErrorTouched,
} from "../../customform/src/CreateSellerStoreController";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ClientNewOrderRequest extends ClientNewOrderRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  selectIconRender = () => (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{
        paddingRight: 10,
        position: "absolute",
        zIndex: 1,
        right: 0,
        pointerEvents: "none",
      }}
      data-test-id="select-drop-down-img"
    />
  );

  renderError = (touched?: boolean, error?: string) => {
    return touched && error ? (
      <FormHelperTextContainer style={{ textAlign: i18n.dir() === "rtl" ? "right" : "left" }}>{this.transOrder(error)}</FormHelperTextContainer>
    ) : (
      <></>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container dir={i18n.dir()}>
          <Grid item xl={2} md={2} lg={2} sm={2} xs={2}>
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid item xl={10} md={10} lg={10} sm={10} xs={10}>
            <StylishHeader navigation={this.props.navigation} />
            <Formik
              initialValues={{
                productName: "",
                gender: "",
                size: "",
                color: "",
                productQuantity: "",
                pricePerUnit: "",
                shippingCost: "",
                image: null,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.handleNewOrderRequestSubmit(values);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                getFieldProps,
                setFieldValue,
                setFieldError,
                setFieldTouched,
                values,
              }) => {
                const onChange = (
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setFieldTouched("image", true);

                  const files = event.currentTarget.files;

                  if (files && files.length > 0) {
                    const file = files[0];

                    const validTypes = ["image/png", "image/jpeg", "image/jpg"];

                    if (
                      !validTypes.includes(file.type) ||
                      file.size > 5 * 1024 * 1024
                    ) {
                      this.handleRemoveImage();
                    } else {
                      setFieldError("image", "");
                      this.handleFileAdd(file);
                    }
                    setFieldValue("image", file);
                  }
                };

                return (
                  <form onSubmit={handleSubmit}>
                    <ClientNewOrderRequestContainer>
                      <ClientNewOrderRequestTitleContainer>
                        <img
                          data-test-id="back-test-id"
                          onClick={() =>
                            this.navigateToOrderClientChat("ClientChat")
                          }
                          style={{
                            transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none'
                          }}
                          src={backArrow}
                          alt="backIcon"
                        />
                        <Typography className="main-title">
                          {this.transOrder("New Order Request")}
                        </Typography>
                      </ClientNewOrderRequestTitleContainer>
                      <Box className="requestFormContainer">
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Typography className="inputNameTxnOrder">
                              * {this.transOrder("Product Name")}
                            </Typography>
                            <ClientNewRequestCustomInput
                              {...getFieldProps("productName")}
                              id="outlined-basic"
                              placeholder={this.transOrder("Product name here")}
                              variant="standard"
                              InputProps={{ disableUnderline: true }}
                              error={
                                this.getErrorAndHelperText(
                                  "productName",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).isError
                              }
                              FormHelperTextProps={{
                                style: {
                                  textAlign: i18n.dir() === 'rtl' ? 'right' : 'left'
                                }
                              }}
                              inputProps={{ maxLength: 120 }}
                              helperText={
                                this.getErrorAndHelperText(
                                  "productName",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).helperText
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className="inputNameTxnOrder">
                            * {this.transOrder("Gender")}
                            </Typography>
                            <OrderReqSelectCustom
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="gender"
                              label="Age"
                              disableUnderline={true}
                              IconComponent={this.selectIconRender}
                              data-test-id="gender-test-id"
                              value={values.gender}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                this.getErrorAndHelperText(
                                  "gender",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).isError
                              }
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              <MenuItem value="" disabled>
                                {this.transOrder("Select a Gender")}
                              </MenuItem>
                              <MenuItem value={"male"}>{this.transOrder("Male")}</MenuItem>
                              <MenuItem value={"female"}>{this.transOrder("Female")}</MenuItem>
                              <MenuItem value={"kids"}>{this.transOrder("Kids")}</MenuItem>
                            </OrderReqSelectCustom>
                            {this.renderError(touched.gender, errors.gender)}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className="inputNameTxnOrder">
                            * {this.transOrder("Size")}
                            </Typography>
                            <OrderReqSelectCustom
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="size"
                              label="Size"
                              disableUnderline={true}
                              IconComponent={this.selectIconRender}
                              value={values.size}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              MenuProps={{
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              error={
                                this.getErrorAndHelperText(
                                  "size",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).isError
                              }
                            >
                              <MenuItem value="" disabled>
                                {this.transOrder("Select a Product Size")}
                              </MenuItem>
                              {this.state.sizes.map((size) => (
                                <MenuItem value={size.attributes.name}>
                                  {size.attributes.name}
                                </MenuItem>
                              ))}
                            </OrderReqSelectCustom>
                            {this.renderError(touched.size, errors.size)}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className="inputNameTxnOrder">
                            * {this.transOrder("Colour")}
                            </Typography>
                            <OrderReqSelectCustom
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="color"
                              label="color"
                              disableUnderline={true}
                              IconComponent={this.selectIconRender}
                              value={values.color}
                              MenuProps={{
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                              }}
                              onChange={handleChange}
                              error={
                                this.getErrorAndHelperText(
                                  "color",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).isError
                              }
                              onBlur={handleBlur}
                            >
                              <MenuItem value="" disabled>
                                {this.transOrder("Select a Product Colour")}
                              </MenuItem>
                              {this.state.colors.map((color) => (
                                <MenuItem value={color.attributes.name}>
                                  {color.attributes.name}
                                </MenuItem>
                              ))}
                            </OrderReqSelectCustom>
                            {this.renderError(touched.color, errors.color)}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className="inputNameTxnOrder">
                            * {this.transOrder("Product Quantity")}
                            </Typography>
                            <ClientNewRequestCustomInput
                              {...getFieldProps("productQuantity")}
                              id="outlined-basic"
                              variant="standard"
                              placeholder={this.transOrder("In units")}
                              InputProps={{ disableUnderline: true }}
                              FormHelperTextProps={{
                                style: {
                                  textAlign: i18n.dir() === 'rtl' ? 'right' : 'left'
                                }
                              }}
                              error={
                                this.getErrorAndHelperText(
                                  "productQuantity",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).isError
                              }
                              helperText={
                                this.getErrorAndHelperText(
                                  "productQuantity",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).helperText
                              }
                              inputProps={{ maxLength: 6 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className="inputNameTxnOrder">
                            * {this.transOrder("Price Per Unit")}
                            </Typography>
                            <ClientNewRequestCustomInput
                              {...getFieldProps("pricePerUnit")}
                              id="standard-adornment-amount"
                              variant="standard"
                              FormHelperTextProps={{
                                style: {
                                  textAlign: i18n.dir() === 'rtl' ? 'right' : 'left'
                                }
                              }}
                              placeholder={this.transOrder("Amount")}
                              InputProps={{ 
                                disableUnderline: true,  
                                startAdornment: (
                                <InputAdornment position="start">
                                  <Typography className="start-adornment">$</Typography>
                                </InputAdornment>
                              )}}
                              inputProps={{ maxLength: 6 }}
                              error={
                                this.getErrorAndHelperText(
                                  "pricePerUnit",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).isError
                              }
                              helperText={
                                this.getErrorAndHelperText(
                                  "pricePerUnit",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).helperText
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className="inputNameTxnOrder">
                            * {this.transOrder("Shipping Cost")}
                            </Typography>
                            <ClientNewRequestCustomInput
                              {...getFieldProps("shippingCost")}
                              id="outlined-basic"
                              variant="standard"
                              placeholder={this.transOrder("Amount")}
                              InputProps={{ 
                                disableUnderline: true,  
                                startAdornment: (
                                <InputAdornment position="start">
                                  <Typography className="start-adornment">$</Typography>
                                </InputAdornment>
                              )}}
                              error={
                                this.getErrorAndHelperText(
                                  "shippingCost",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).isError
                              }
                              inputProps={{ maxLength: 6 }}
                              helperText={
                                this.getErrorAndHelperText(
                                  "shippingCost",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).helperText
                              }
                              FormHelperTextProps={{
                                style: {
                                  textAlign: i18n.dir() === 'rtl' ? 'right' : 'left'
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className="inputNameTxnOrder">
                            * {this.transOrder("Product Display Image")}
                            </Typography>
                            <Box className="uploadAfterContainer">
                              <Box>
                                {this.state.image ? (
                                  <Box className="uploadAfterBoxContainer">
                                    <Box>
                                      <img
                                        src={
                                          this.state.image
                                            ? URL.createObjectURL(
                                                this.state.image
                                              )
                                            : undefined
                                        }
                                        alt={this.transOrder("Uploaded Preview")}
                                        className="uploadAfterImgContainer"
                                      />
                                      <Button
                                        variant="outlined"
                                        className="uploadAfterReplaceBtn"
                                        component="label"
                                      >
                                        {this.transOrder("Replace Image")}
                                        <input
                                          type="file"
                                          hidden
                                          name="image"
                                          data-test-id="replace-test-id"
                                          accept="image/png, image/jpeg, image/jpg"
                                          onChange={onChange}
                                        />
                                      </Button>
                                    </Box>
                                    <IconButton
                                      onClick={() => {
                                        setFieldValue("image", null);
                                        this.handleRemoveImage();
                                      }}
                                      data-test-id="remove-test-id"
                                      className="uploadAfterDeleteBtn"
                                    >
                                      <img src={deleteIcon} />
                                    </IconButton>
                                  </Box>
                                ) : (
                                  <Button
                                    variant="contained"
                                    component="label"
                                    className="upload-btn"
                                  >
                                    <img src={uploadIcon} alt="upload" />
                                    <Typography className="upload-title">
                                      {this.transOrder("Upload png, jpg, jpeg")}
                                    </Typography>
                                    <input
                                      type="file"
                                      hidden
                                      data-test-id="upload-test-id"
                                      onChange={onChange}
                                      name="image"
                                      accept="image/png, image/jpeg, image/jpg"
                                    />
                                  </Button>
                                )}
                              </Box>
                            </Box>
                            {this.renderError(touched.image, errors.image)}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box className="inputCancelContainer">
                              <Button
                                onClick={() =>
                                  this.navigateToOrderClientChat("ClientChat")
                                }
                                className="cancelBtn"
                                data-test-id="cancel-test-id"
                              >
                                {this.transOrder("Cancel")}
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box className="inputSendContainer">
                              <Button type="submit" className="sendBtn">
                                {this.transOrder("Send Request")}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </ClientNewOrderRequestContainer>
                  </form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertNOBoxClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const ClientNewOrderRequestContainer = styled(Box)({
  minHeight: "calc(100vh - 85px)",
  padding: "36px 30px",
  "& .requestFormContainer": {
    marginTop: 20,
    padding: 20,
    boxShadow: "0px 2px 8px 0px #00000014",
  },
  "& .uploadAfterContainer": {
    width: "100%",
    textAlign: "center",
    padding: 4,
    height: "184px",
  },
  "& .uploadAfterBoxContainer": {
    position: "relative",
    borderRadius: 2,
    height: "184px",
    padding: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f1f1f1",
  },
  "& .uploadAfterImgContainer": {
    width: "100%",
    height: "184px",
  },
  "& .uploadAfterReplaceBtn": {
    color: "#3f51b5",
    width: "480px",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    height: "54px",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "inherit",
    backgroundColor: "#fff",
    position: "absolute",
    bottom: "6%",
    left: "15%",
    textTransform: "none",
  },
  "& .uploadAfterDeleteBtn": {
    position: "absolute",
    top: 16,
    right: 16,
    backgroundColor: "#ffffff",
    color: "#f44336",
    boxShadow: "0px 2px 8px 0px #00000014",
  },
  "& .upload-btn": {
    boxShadow: "none",
    backgroundColor: "#F8F8F8 !important",
    width: "100%",
    height: "100%",
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      gap: 16,
    },
    "& .upload-title": {
      fontFamily: "Lato, sans-serif",
      fontSize: 18,
      fontWeight: 700,
      color: "#375280",
      textTransform: "capitalize",
    },
  },
  "& .uploadBeforeUploadIcon": {
    width: "40px",
    height: "40px",
  },
  "& .inputCancelContainer": {
    padding: "10px 0",
    display: "flex",
    justifyContent: "end",
  },
  "& .inputSendContainer": {
    padding: "10px 0",
    display: "flex",
    justifyContent: "start",
  },
  "& .submitBtn": {
    width: "100%",
    height: "53px",
    fontWeight: 800,
    fontSize: "20px",
    textTransform: "none",
    background: "#CCBEB1",
    color: "white",
    borderRadius: "4px",
  },
  "& .cancelBtn": {
    width: "50%",
    height: "64px",
    fontWeight: 500,
    color: "#375280",
    fontSize: "20px",
    textTransform: "none",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
  },
  "& .sendBtn": {
    width: "50%",
    height: "64px",
    fontWeight: 800,
    fontSize: "20px",
    textTransform: "none",
    background: "#CCBEB1",
    color: "white",
    borderRadius: "2px",
  },
  "& .inputNameTxnOrder": {
    color: "#375280",
    fontFamily: "Lato, sans-serif",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: "10px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    marginTop: 10,
  },
});

export const OrderReqSelectCustom = styled(Select)({
  paddingLeft: "20px",
  width: "100%",
  paddingRight: "15px",
  background: "#f1f1f1",
  border: "1px solid #F8F8F8",
  borderRadius: "4px",
  height: "53px",
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: 400,
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  color: "#375280",
  "& .MuiSelect-select.MuiSelect-select": {
    background: "#f1f1f1",
  },
});
const ClientNewOrderRequestTitleContainer = styled(Box)({
  justifyContent: "flex-start",
  display: "flex",
  gap: 20,
  paddingBottom: 24,
  alignItems: "center",
  "& .InputDesign": {
    width: "97%",
    color: "#375280",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    marginBottom: "5px",
  },
  "& .main-title": {
    color: "#375280",
    fontFamily: "Lato, sans-serif",
    fontSize: 24,
    width: "100%",
    fontWeight: 700,
  },
});
const ClientNewRequestCustomInput = styled(TextField)({
  background: "#f1f1f1",
  height: "43px",
  border: "1px solid #F8F8F8",
  borderRadius: "4px",
  width: "100%",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  paddingTop: 8,
  marginBottom: 10,
  "& .MuiInputBase-input": {
    padding: "6px 15px 7px",
  },
  "& ::placeholder": {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    opacity: 1,
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  "& .MuiInputBase-root": {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  "& .MuiInputBase-root.MuiInput-root.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedStart":{
    marginLeft: 15,
    "& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart": {
      paddingLeft: 0,
    }
  },
  "& .MuiInputAdornment-positionStart":{
    "& .MuiTypography-root.start-adornment": {
      color: "#375280",
      fontSize: "16px",
      opacity: 1,
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      fontStyle: "normal !important",
      fontFamily: "Lato , sans-serif",
    }
  }
});

export const FormHelperTextContainer = styled(FormHelperText)({
  color: "#f44336",
});
// Customizable Area End
