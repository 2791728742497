Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.product_cataloge = "GET"
exports.getCatalogues = "bx_block_catalogue/catalogues/catalogue_buyer"
exports.sub_sub_categories = "bx_block_categories/sub_sub_categories?sub_category_id"
exports.sub_categories = "bx_block_categories/sub_categories?category_id="
exports.categories = "bx_block_categories/categories"
exports.catalogues_variants_sizes = "bx_block_catalogue/catalogues_variants_sizes"
exports.catalogues_variants_colors = "bx_block_catalogue/catalogues_variants_colors"
exports.favouritesLink = "bx_block_favourites/favourites"
exports.cataloguesToken = "bx_block_catalogue/catalogues"
exports.favouriteableTokenDelete = "bx_block_favourites/favourites/destroy_by_favouritable?favouriteable_id"
exports.enCatFilter = {
  "Home": "Home"
}
// Customizable Area End